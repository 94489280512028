import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getProduct = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/product/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createProduct = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/product",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editProduct = async ({ pathParams, body }) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/product/" + pathParams,
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteProduct = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/product/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getProductCategory = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/product_cat/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createProductCategory = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/product_cat",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editProductCategory = async ({ pathParams, body }) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/product_cat/" + pathParams,
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteProductCategory = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/product_cat/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getProductStock = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/product_stock/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createProductStock = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/product_stock",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const updateProductStock = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/product_stock/update_stock",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editProductStock = async ({ pathParams, body }) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/product_stock/" + pathParams,
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteProductStock = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/product_stock/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getProductOrder = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/product_order/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockProductOrder = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/get_order_management/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createProductOrder = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/product_order",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editProductOrder = async ({ pathParams, body }) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/product_order/" + pathParams,
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteProductOrder = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/product_order/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const saveProductOrderDetail = async ({ pathParams, body }) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/save_product_order/" + pathParams,
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getProductShipping = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/get_shipping_management/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getProductOrderDetail = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/product_order_detail/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockProduct = async ({ page, pageSize, keyword, status }) => {
  let records = [];
  let total = 104;
  if (keyword) {
    total = Math.round(total / 2);
  }
  if (status === "enable") {
    total = Math.round(total / 3);
  }
  if (status === "disable") {
    total = Math.round(total / 2);
  }
  const startId = page * pageSize - pageSize + 1;
  const endId = page * pageSize;
  for (let i = startId; i <= endId && i <= total; i++) {
    records.push({
      id: i,
      productCode: "06HYDT2",
      productName: "Taraxin (bot/ 1000tab Hydroxyzine10mg)",
      productCategory: "ยาแก้แพ้ แก้คัน",
      productBrand: "Yaraxin",
      productOrderNumber: 1,
      productStatus: "Disable",
    });
  }
  return {
    success: true,
    data: {
      total: total,
      currentPage: page,
      pageSize: pageSize,
      records: records,
    },
    error: null,
  };
};

export const getMockProductCategory = async ({
  page,
  pageSize,
  keyword,
  status,
}) => {
  let records = [];
  let total = 104;
  if (keyword) {
    total = Math.round(total / 2);
  }
  if (status === "enable") {
    total = Math.round(total / 3);
  }
  if (status === "disable") {
    total = Math.round(total / 2);
  }
  const startId = page * pageSize - pageSize + 1;
  const endId = page * pageSize;
  for (let i = startId; i <= endId && i <= total; i++) {
    records.push({
      id: i,
      productCategory: "ยกแก้ แก้คัน",
      productBrand: "Taraxin",
      transactionDatetime: new Date().toLocaleString(),
      productCategoryStatus: "Draft",
    });
  }
  return {
    success: true,
    data: {
      total: total,
      currentPage: page,
      pageSize: pageSize,
      records: records,
    },
    error: null,
  };
};

export const getMockProductStock = async ({
  page,
  pageSize,
  keyword,
  status,
}) => {
  let records = [];
  let total = 104;
  if (keyword) {
    total = Math.round(total / 2);
  }
  if (status === "enable") {
    total = Math.round(total / 3);
  }
  if (status === "disable") {
    total = Math.round(total / 2);
  }
  const startId = page * pageSize - pageSize + 1;
  const endId = page * pageSize;
  for (let i = startId; i <= endId && i <= total; i++) {
    records.push({
      id: i,
      productCode: "06HYDT2",
      productName: "Taraxin (bot/ 1000tab Hydroxyzine10mg)",
      transactionDatetime: new Date().toLocaleString(),
      createBy: "Mongol",
      newAdd: "200",
      total: "210",
      remark: "---",
    });
  }
  return {
    success: true,
    data: {
      total: total,
      currentPage: page,
      pageSize: pageSize,
      records: records,
    },
    error: null,
  };
};

export const getMockOrder = async ({
  page,
  pageSize,
  keyword,
  adviseCallStatus,
  shippingStatus,
}) => {
  let records = [];
  let total = 104;
  if (keyword) {
    total = Math.round(total / 2);
  }
  if (adviseCallStatus !== "all") {
    total = Math.round(total / 3);
  }
  if (shippingStatus !== "all") {
    total = Math.round(total / 3);
  }
  const startId = page * pageSize - pageSize + 1;
  const endId = page * pageSize;
  for (let i = startId; i <= endId && i <= total; i++) {
    records.push({
      id: i,
      productName: "ยาแก้แพ้ แก้คัน",
      purchaseId: "115200004D",
      shipTo:
        "นาย มานพ เทส 123/4 งามวงศ์วาน 3 บางเขน เมืองนนทบุรี นนทบุรี 11000 090-xxx-xxx",
      productCode: "PT2:3|DRG05:1",
      value: "720 ฿",
      shippingFee: "50 ฿",
      purchaseStatus: "Pending",
    });
  }
  return {
    success: true,
    data: {
      total: total,
      currentPage: page,
      pageSize: pageSize,
      records: records,
    },
    error: null,
  };
};

export const getMockShipping = async ({ page, pageSize, keyword, status }) => {
  let records = [];
  let total = 104;
  if (keyword) {
    total = Math.round(total / 2);
  }
  if (status === "enable") {
    total = Math.round(total / 3);
  }
  if (status === "disable") {
    total = Math.round(total / 2);
  }
  const startId = page * pageSize - pageSize + 1;
  const endId = page * pageSize;
  for (let i = startId; i <= endId && i <= total; i++) {
    records.push({
      id: i,
      purchaseId: "11520004D",
      shipTo:
        "นาย มานพ เทส 123/4 งามวงศ์วาน 3 บางเขน เมืองนนทบุรี นนทบุรี 11000 090-xxx-xxx",
      productCode: "PT2:3|DRG05:1",
      carrier: "Kerry",
      pickupDate: new Date().toLocaleString(),
      trackingCode: "K214E52889TH",
      shippingStatus: i % 8,
      adviseCall: "โทรแล้ว",
      advise: "นาย ปู ขจร",
      remark: "",
    });
  }
  return {
    success: true,
    data: {
      total: total,
      currentPage: page,
      pageSize: pageSize,
      records: records,
    },
    error: null,
  };
};

export const createMockProduct = async () => {
  return {
    success: true,
    data: {},
    error: null,
  };
};

export const editMockProduct = async () => {
  return {
    success: true,
    data: {},
    error: null,
  };
};

export const getUnitProduct = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/m_unit/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getPetTypeProduct = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/m_pet_type/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getPhotoSetProduct = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/product_picture/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const uploadPhotoSetProduct = async ({ bodyFormData }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/ext/product_picture/upload_new",
      bodyFormData,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteImage = async ({ data }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/upload/image",
      {
        headers: await getDefaultRequestHeaders(true),
        data: data,
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deletePhotoSetProduct = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/product_picture/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getCustomerInfo = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/member_info/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getPaymentImage = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/payment_picture_slip",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const uploadPaymentImage = async ({ bodyFormData }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/ext/payment_picture/upload_new",
      bodyFormData,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deletePaymentImage = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/payment_picture/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getAddressInfo = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/address/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};