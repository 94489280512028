import React, { useRef } from "react";
import { CSVLink } from "react-csv";
import { ExpenseTransactionColumn } from "../data/Columns";
import { ExpenseTransactionCSVHeader } from "../data/CSVHeader";

import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import Header from "../layouts/Header";
import GenericPagination from "../components/GenericPagination";
import { Button, Card, Col, Container, Modal, Row, Form } from "react-bootstrap";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import {
  getMockExpenseTransactionDatatable,
  getRecord,
} from "../services/ExpenseTransactionServices";

import {
  ExpenseTypeOptions,
  TransactionStatusOptions,
  filterExpenseTypeOptions,
  filterTransactionStatusOptions,
} from "../data/SelectOptions";
import SelectFilter from "../components/SelectFilter";

import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";

import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";

import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";

import CreateEditForm from "../features/Form/CreateEditExpenseTransactionForm";

import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import GenericButton from "../components/Button/GenericButton";
import SaveButton from "../components/Button/SaveButton";
import CancelButton from "../components/Button/CancelButton";
import GenericSelectInput from "../components/Input/GenericSelectInput";
import ExportCSVButton from "../components/Button/ExportCSVButton";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";
import CreateEditExpenseTransactionPaidForm from "../features/Form/CreateEditExpenseTransactionPaidForm";
import setPlaceholder, { textAlignRight } from "../utils/Help";
import EditExpenseTransactionStatusForm from "../features/Form/EditExpenseTransactionStatusForm";

export default function ExpenseTransactionReport() {
  const columnsData = ExpenseTransactionColumn;
  const csvHeader = ExpenseTransactionCSVHeader;
  const csvFilename = "ExpenseTransaction.csv";

  const [isExport, setIsExport] = React.useState(false);

  const [tableData, setTableData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [total, setTotal] = React.useState(1);

  const [keyword, setKeyword] = React.useState("");
  const [dateStart, setDateStart] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();

  const [selectIds, setSelectIds] = React.useState([]);
  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isChangeStatus, setIsChangeStatus] = React.useState(false);

  const [isCreate, setIsCreate] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const [editData, setEditData] = React.useState();
  const [csvData, setCsvData] = React.useState([]);

  const [isEditPaid, setIsEditPaid] = React.useState(false);
  const [editDataPaid, setEditDataPaid] = React.useState();

  const loadInfo = React.useCallback(
    async (currentPage, pageSize, keyword, dateStart, dateEnd) => {
      const { success, data, error } = await getRecord({
        page: currentPage,
        pageSize: pageSize,
        search: keyword,
        dateStart,
        dateEnd,
      });
      setTotal(0);
      setTableData([]);
      if (success) {
        // console.log(data.records);
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records, 
            (currentPage - 1) * pageSize + 1,
            handleOpenPaid, 
            handleOpenEdit)
        );
        setCsvData(prepareCSVData(data.records));
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  React.useEffect(() => {
    loadInfo(1, 10);
  }, [loadInfo]);

  const openOptionForm = () => {
    let tempSelectIds = [];
    for (let row = 0; row < tableData.length; row++) {
      const selectId = document.getElementById(
        `checkbox-select-id-${row}`
      )?.checked;
      if (selectId) {
        tempSelectIds.push(
          +document.getElementById(`checkbox-select-id-${row}`)?.value
        );
      }
    }
    setSelectIds(tempSelectIds);
    setIsChangeStatus(true);
    setIsOpenForm(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setIsChangeStatus(false);
    setCurrentPage(1);
    setKeyword("");

    setSelectIds([]);
    loadInfo(1, 10);
  };

  const handleExportCSV = () => {
    setIsExport(true);
  };
  const handleExportComplete = () => {
    setIsExport(false); // Reset the trigger to false
  };

  const handleOpenEdit = (data) => {
    setIsEdit(true);
    setEditData(data);
  };
  const handleCloseEdit = () => {
    setIsEdit(false);
    setEditData(null);
  };
  const handleOpenPaid = (data) => {
    setIsEditPaid(true);
    setEditDataPaid(data);
  };
  const handleClosePaid = () => {
    setIsEditPaid(false);
    setEditDataPaid(null);
  };

  const handleEdit = React.useCallback(async () => {
    handleClosePaid();
    loadInfo(1, 10);
  }, [loadInfo]);

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadInfo(1, pageSize, keyword, dateStart, dateEnd);
    },
    [loadInfo, pageSize]
  );

  const handleSelectedDate = React.useCallback(
    async (sDate, eDate) => {
      setDateStart(sDate);
      setDateEnd(eDate);
      setCurrentPage(1);
      await loadInfo(1, pageSize, keyword, sDate, eDate);
    },
    [loadInfo, pageSize, keyword]
  );

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadInfo(page, pageSize, keyword, dateStart, dateEnd);
    },
    [loadInfo, pageSize, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadInfo(1, pageSize, keyword, dateStart, dateEnd);
    },
    [loadInfo, keyword]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <ExportCSVButton
            csvData={csvData}
            csvHeader={csvHeader}
            filename={csvFilename}
            enclose=""
            showBtn={false}
            isExport={isExport}
            onExportComplete={handleExportComplete}
          />
          <PageHeaderCard
            title="Expense Transaction"
            buttonEnable={!isOpenForm}
            buttonMessage="Change Status"
            onButtonOnClick={openOptionForm}
            buttonEnable2={!isEdit && !isCreate}
            buttonMessage2="Export CSV"
            onButtonOnClick2={handleExportCSV}
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {
                isOpenForm && isChangeStatus ? (
                  <>
                    <Row className="mt-3">
                      <EditExpenseTransactionStatusForm
                        onCancel={() => {
                          refreshPage();
                        }}
                        onSubmitSuccess={() => {
                          refreshPage();
                        }}
                        ids={selectIds}
                      />
                    </Row>
                  </>
                ):
                isEdit ? (
                  <>
                    <Modal
                      show={isEdit}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header>Expense Transaction Detail</Modal.Header>
                      <Modal.Body>
                        <CreateEditForm
                          onCancel={handleCloseEdit}
                          id={editData?.expense_transaction_id}
                          code={editData?.code}
                          action_datetime={new Date(editData?.action_datetime)}
                          recipient={editData?.recipient}
                          category={editData?.category}
                          remark={editData?.remark}
                          status={editData?.status}
                          editData={editData}
                        />
                      </Modal.Body>
                    </Modal>
                  </>
                ) : isEditPaid ? (
                  <>
                    <Modal
                      show={isEditPaid}
                      size="sm"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header>Expense Transaction Paid</Modal.Header>
                      <Modal.Body>
                        <CreateEditExpenseTransactionPaidForm
                          onCancel={handleClosePaid}
                          onSubmitSuccess={handleEdit}
                          editData={editDataPaid}
                        />
                      </Modal.Body>
                    </Modal>
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder={setPlaceholder([
                            "รหัสรายการ",
                            "วันที่และเวลาทำรายการ",
                            "กลุ่มรายการ",
                            "ชื่อผู้รับเงิน",
                          ])}
                          // placeholder="Filter : รหัสรายการ , วันที่และเวลาทำรายการ , กลุ่มรายการ , ชื่อผู้รับเงิน"
                          onSearch={handleSearch}
                          defaultValue={keyword}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={dateStart ? new Date(dateStart) : null}
                          defaultEnd={dateEnd ? new Date(dateEnd) : null}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, startNo, onPaid, onEdit) => {
  let tableData = [];
  // console.log(datas);
  for (const [idx, data] of datas.entries()) {
    const tempTableData = {
      checkbox: (
        <Form.Check
          id={`checkbox-select-id-${idx}`}
          type="checkbox"
          value={data.expense_transaction_id}
        />
      ),
      no: startNo + idx,
      expense_transaction_id: data.expense_transaction_id,
      code: data.code,
      action_datetime: data.action_datetime,
      recipient: data.recipient,
      category: data.category,
      remark: data.remark,
      status: data.status,
      amt: textAlignRight(data.amt),
      vat: textAlignRight(data.vat),
    };
    tableData.push({
      ...tempTableData,
      editDeleteButton: (
        <>
          <Button
            variant="danger"
            onClick={() => {
              onPaid(data);
            }}
          >
            Paid
          </Button>{" "}
          <Button
            variant="warning"
            onClick={() => {
              onEdit(data);
            }}
          >
            View
          </Button>
        </>
      ),
    });
  }
  //console.log(tableData);
  return tableData;
};

const prepareCSVData = (datas) => {
  let csvData = [];
  for (const data of datas) {
    csvData.push({
      expense_transaction_id: data.expense_transaction_id,
      code: data.code,
      action_datetime: data.action_datetime,
      recipient: data.recipient,
      category: data.category,
      remark: data.remark,
      status: data.status,
      amt: data.amt,
      vat: data.vat,
    });
  }
  return csvData;
};
