import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { editMockUser } from "../../services/UserServices";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import { createSpecializedMasterData, editSpecializedMasterData } from "../../services/SettingServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";

const schema = yup.object().shape({
  specialized: yup.string().required("ห้ามว่าง"),
  isEnable: yup.bool().required("ห้ามว่าง"),
});

export default function CreateEditSpecializedMasterData({
  isCreate,
  onCancel,
  onSubmitSuccess,
  specializedDefaultValue,
  isEnableDefaultValue,
  createAtDefaultValue,
  id,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (inputData) => {
    if (isCreate) {
      const { success, error } = await createSpecializedMasterData({
        body: {
          name: inputData.specialized,
          status: +inputData.isEnable,
        },
      });
      if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data created successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Failed to create data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    } else {
      const { success, error } = await editSpecializedMasterData({
        pathParams: id,
        body: {
          name: inputData.specialized,
          status: +inputData.isEnable,
        },
       });
      if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data updated successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4>การตั้งค่าความถนัดเฉพาะทาง</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="specialized"
              label="ความถนัดเฉพาะทาง"
              placeholder="ความถนัดเฉพาะทาง"
              defaultValue={specializedDefaultValue}
              required
              error={errors.specialized}
            />
          </Form.Group>
        </Row>
        <Form.Group as={Row} className="mb-3" controlId="formBasicName">
          <GenericRadioInput
            register={register}
            name="isEnable"
            label="Is Enable"
            placeholder="Is Enable"
            defaultChecked={isEnableDefaultValue}
            error={errors.isEnable}
            radios={[{ id: 1, label: "", value: true }]}
            type="switch"
            viewFormat="inline"
            labelColSize={1}
          />
        </Form.Group>
        {!isCreate && (
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                name="createAt"
                label="Create Date"
                placeholder="-"
                defaultValue={createAtDefaultValue}
                readOnly
                plaintext
              />
            </Form.Group>
          </Row>
        )}
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
