import React from "react";
import { Button } from "react-bootstrap";
import { checkPermissionButton } from "../../utils/Help";

export default function DeleteButton(props) {
  if (checkPermissionButton(props?.screenId, "f_del")) {
    return (
      <Button
        {...props}
        variant="danger"
        active
        style={{ color: "white", marginLeft: "10px" }}
      >
        Delete
      </Button>
    );
  }
  return;
}
