import React from "react";
import Header from "../layouts/Header";
import OrderManagementDataTable from "../features/DataTable/OrderManagementDataTable";

export default function OrderManagement() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [openFormId, setOpenFormId] = React.useState();

  React.useEffect(() => {
    setIsLoading(true);
    const searchParams = new URLSearchParams(window.location.search);
    const editId = searchParams.get("id");
    if (editId) {
      setOpenFormId(editId);
    }
    setIsLoading(false);
  }, []);

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          {!isLoading && <OrderManagementDataTable openFormId={openFormId} />}
        </div>
      </div>
    </>
  );
}
