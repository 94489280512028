import React from "react";
import { orderColumn } from "../../data/Columns";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import PageHeaderCard from "../../components/Card/PageHeaderCard";
import Searching from "../../components/Searching";
import ReactTable from "../../components/ReactTable";
import GenericPagination from "../../components/GenericPagination";
import SelectFilter from "../../components/SelectFilter";
import { filterPurchaseStatusOptions } from "../../data/SelectOptions";
import {
  deleteProductOrder,
  getMockProductOrder,
  getProductOrder,
} from "../../services/ProductManagenentServices";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";
import EditButton from "../../components/Button/EditButton";
import DeleteButton from "../../components/Button/DeleteButton";
import CreateEditOrderForm from "../../features/Form/CreateEditOrderForm";
import ReactDateTimeRangePicker2 from "../../components/Input/ReactDateTimeRangePicker2";
import { PurchaseStatus } from "../../data/Enumerator";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import EditOrderStatusForm from "../../features/Form/EditOrderStatusForm";
import { textAlignRight } from "../../utils/Help";

const allOrderStatus = null;

export default function OrderManagementDataTable({ openFormId, memberId }) {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [productCategoryStatus, setProductCategoryStatus] =
    React.useState(allOrderStatus);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [tableData, setTableData] = React.useState([]);
  const columnsData = orderColumn;

  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(false);
  const [isChangeStatus, setIsChangeStatus] = React.useState(false);
  const [selectIds, setSelectIds] = React.useState([]);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();

  const handleOpenEditForm = async (recordData) => {
    const { success, data, error } = await getProductOrder({
      params: {
        "filter._relations": "rel_product_order_detail_ids",
      },
      pathParams: recordData.id,
    });
    if (success) {
      const tempEditData = {
        id: data.records?.id,
        purchaseStatus: data.records?.status,
        shippingFee: data.records?.shipping_free,
        productCode: data.records?.code,
        paymentId: data.records?.payment_id,
        customerId: data.records?.member_info_id,
        name: data.records?.rname,
        phone: data.records?.phone,
        address: data.records?.addressno,
        street: data.records?.street,
        subDistrict: data.records?.m_subdistrict_id,
        district: data.records?.m_district_id,
        province: data.records?.m_province_id,
        postcode: data.records?.postcode,
        remark: data.records?.remark,
        purchaseDate: data.records?.purchase_date,
      };
      setIsCreate(false);
      setIsOpenForm(true);
      setIsChangeStatus(false);
      setEditData(tempEditData);
    } else {
      ShowMessageAndExpire(
        "Load Detail Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  };

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const loadOrder = React.useCallback(
    async (
      currentPage,
      pageSize,
      productCategoryStatus,
      keyword,
      startDate,
      endDate
    ) => {
      setTotal(0);
      setTableData([]);
      let dateFilter = null;
      if (startDate && endDate) {
        dateFilter = `$btw:${startDate} 00:00:00, ${endDate} 23:59:59`;
      }
      const { success, data, error } = await getMockProductOrder({
        params: {
          page: currentPage,
          limit: pageSize,
          search: keyword,
          "filter.status": productCategoryStatus,
          "filter.purchase_date": dateFilter,
          "filter.member_info_id": memberId,
        },
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        ShowMessageAndExpire(
          "Load Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    []
  );

  const handleDirectToEditForm = React.useCallback(async (openFormId) => {
    // load data and open edit form
    const { success, data, error } = await getMockProductOrder({
      params: {
        // TODO: this for temp in mock api
        "filter.order_id": openFormId,
        "filter.member_info_id": memberId,
      },
      // TODO: it should use this param to get data by openFormId in real api
      // pathParams: openFormId,
    });
    if (success && data?.records[0]) {
      const editRecord = data.records[0];
      handleOpenEditForm({
        id: editRecord.order_id,
        productName: editRecord?.product_name,
        purchaseId: editRecord?.code,
        shipTo: editRecord?.ship_to,
        productCode: editRecord.product_code,
        customerId: editRecord.member_info_id,
        value: editRecord.value,
        shippingFee: editRecord.shipping_free,
        purchaseStatus: editRecord.status,
        remark: editRecord.remark,
      });
    } else {
      ShowMessageAndExpire(
        "Load Form Data Failed",
        JSON.stringify(error?.message) ||
          `Cannot load form by this id (${openFormId})`,
        "error"
      );
      loadOrder(1, 10, allOrderStatus);
    }
  }, []);

  React.useEffect(() => {
    if (openFormId) {
      handleDirectToEditForm(openFormId);
    } else {
      loadOrder(1, 10, allOrderStatus);
    }
  }, [handleDirectToEditForm, loadOrder]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadOrder(
        page,
        pageSize,
        productCategoryStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadOrder, pageSize, productCategoryStatus, keyword, startDate, endDate]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadOrder(
        1,
        pageSize,
        productCategoryStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadOrder, productCategoryStatus, keyword, startDate, endDate]
  );

  const loadPageOnUserStatusChange = React.useCallback(
    async (productCategoryStatus) => {
      setCurrentPage(1);
      setProductCategoryStatus(productCategoryStatus);
      await loadOrder(
        1,
        pageSize,
        productCategoryStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadOrder, pageSize, keyword, startDate, endDate]
  );

  const openOptionForm = () => {
    let tempSelectIds = [];
    for (let row = 0; row < tableData.length; row++) {
      const selectId = document.getElementById(
        `checkbox-select-id-${row}`
      )?.checked;
      if (selectId) {
        tempSelectIds.push(
          +document.getElementById(`checkbox-select-id-${row}`)?.value
        );
      }
    }
    setSelectIds(tempSelectIds);
    setIsOpenForm(true);
    setIsChangeStatus(true);
  };

  const openCreateForm = () => {
    setIsCreate(true);
    setIsOpenForm(true);
    setIsChangeStatus(false);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setIsChangeStatus(false);
    setEditData(null);
    setIsDelete(false);
    setDeleteData(null);
    setCurrentPage(1);
    setKeyword("");
    setStartDate(null);
    setStartDate(null);
    setSelectIds([]);
    setProductCategoryStatus(allOrderStatus);
    loadOrder(1, pageSize, allOrderStatus, "");
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadOrder(
        1,
        pageSize,
        productCategoryStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadOrder, pageSize, productCategoryStatus, startDate, endDate]
  );

  const handleSelectedDate = React.useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate);
      setEndDate(endDate);
      setCurrentPage(1);
      await loadOrder(
        1,
        pageSize,
        productCategoryStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadOrder, pageSize, productCategoryStatus, keyword]
  );

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const { success, error } = await deleteProductOrder({
        pathParams: id,
      });
      if (success) {
        ShowMessageAndExpire("Data deleted successfully.", "", "success");
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadOrder(
          tempCurrentPage,
          pageSize,
          productCategoryStatus,
          keyword,
          startDate,
          endDate
        );
      } else {
        handleCloseDeleteConfirm();
        ShowMessageAndExpire(
          "Failed to delete data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [
      loadOrder,
      total,
      currentPage,
      pageSize,
      productCategoryStatus,
      keyword,
      startDate,
      endDate,
    ]
  );

  return (
    <>
      {memberId ? (
        <PageHeaderCard
          title={
            !isOpenForm
              ? "Order Management"
              : isChangeStatus
              ? "Change Status"
              : "Order Detail"
          }
        />
      ) : (
        <PageHeaderCard
          title={
            !isOpenForm
              ? "Order Management"
              : isChangeStatus
              ? "Change Status"
              : "Order Detail"
          }
          buttonEnable={!isOpenForm}
          buttonIcon="plus"
          buttonMessage="Options"
          onButtonOnClick={openOptionForm}
          buttonEnable2={!isOpenForm}
          buttonIcon2="plus"
          buttonMessage2="Create Order"
          onButtonOnClick2={openCreateForm}
        />
      )}

      <DeleteConfirmModal
        show={isDelete}
        onClose={handleCloseDeleteConfirm}
        onComfirm={handleDelete}
        deleteRecord={deleteData?.id}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these record?"}</p>
            <p>
              {`id = ${deleteData?.id}, ชื่อสินค้า= ${deleteData?.productName}, Purchase ID= ${deleteData?.purchaseId} `}
            </p>
          </>
        }
      />
      <Card>
        <Card.Body>
          <Container fluid style={{ minHeight: "30rem" }}>
            {isOpenForm && isChangeStatus ? (
              <>
                <Row className="mt-3">
                  <EditOrderStatusForm
                    onCancel={() => {
                      refreshPage();
                    }}
                    onSubmitSuccess={() => {
                      refreshPage();
                    }}
                    ids={selectIds}
                  />
                </Row>
              </>
            ) : isOpenForm && !isChangeStatus ? (
              <>
                <Row className="mt-3">
                  <CreateEditOrderForm
                    isCreate={isCreate}
                    showDeliveryInfo={false}
                    showAddProduct={true}
                    customerIdReadOnly={false}
                    paymentMethodReadOnly={false}
                    onCancel={refreshPage}
                    onSubmitSuccess={refreshPage}
                    purchaseStatusDefaultValue={editData?.purchaseStatus}
                    shippingFeeDefaultValue={editData?.shippingFee}
                    productCodeDefaultValue={editData?.productCode}
                    paymentIdDefaultValue={editData?.paymentId}
                    customerIdDefaultValue={editData?.customerId}
                    nameDefaultValue={editData?.name}
                    phoneDefaultValue={editData?.phone}
                    addressDefaultValue={editData?.address}
                    streetDefaultValue={editData?.street}
                    subDistrictDefaultValue={editData?.subDistrict}
                    districtDefaultValue={editData?.district}
                    provinceDefaultValue={editData?.province}
                    postCodeDefaultValue={editData?.postCode}
                    remarkDefaultValue={editData?.remark}
                    id={editData?.id}
                    purchaseDateDefaultValue={editData?.purchaseDate}
                  />
                </Row>
              </>
            ) : (
              <>
                <Row className="mt-3">
                  <Col xs={6} style={{ display: "flex" }}>
                    <Searching
                      placeholder="Filter : ชื่อสินค้า, หมายเหตุ"
                      onSearch={handleSearch}
                    />
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <SelectFilter
                      label="Status"
                      placeholder={"Status"}
                      defaultValue={filterPurchaseStatusOptions.find(
                        (e) => e.value === allOrderStatus
                      )}
                      value={filterPurchaseStatusOptions.find(
                        (e) => e.value === productCategoryStatus
                      )}
                      options={filterPurchaseStatusOptions}
                      onChange={loadPageOnUserStatusChange}
                    />
                    <ReactDateTimeRangePicker2
                      onSelected={handleSelectedDate}
                      defaultStart={startDate ? new Date(startDate) : null}
                      defaultEnd={endDate ? new Date(endDate) : null}
                      placeholder="เลือกวันที่จัดซื้อ"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <ReactTable columns={columnsData} data={tableData} />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <GenericPagination
                      totalPage={Math.ceil(total / pageSize)}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      onPageChange={loadPageOnPageChange}
                      onPageSizeChange={loadPageOnPageSizeChange}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </Card.Body>
      </Card>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = [];
  for (const [idx, data] of datas.entries()) {
    let tempPurchaseStatus;
    if (
      data.status === PurchaseStatus.Inactive ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "INACTIVE")
    ) {
      tempPurchaseStatus = <span class="badge bg-secondary">Inactive</span>;
    } else if (
      data.status === PurchaseStatus.Active ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "ACTIVE")
    ) {
      tempPurchaseStatus = <span class="badge bg-primary">Active</span>;
    } else if (
      data.status === PurchaseStatus.Pending ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "PENDING")
    ) {
      tempPurchaseStatus = <span class="badge bg-danger">Pending</span>;
    } else if (
      data.status === PurchaseStatus.Paid ||
      (typeof data.status === "string" && data.status.toUpperCase() === "PAID")
    ) {
      tempPurchaseStatus = (
        <span
          class="badge"
          style={{
            backgroundColor: "DarkGreen",
          }}
        >
          Paid
        </span>
      );
    } else if (
      data.status === PurchaseStatus.Shipping ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "SHIPPED")
    ) {
      tempPurchaseStatus = (
        <span
          class="badge"
          style={{
            backgroundColor: "purple",
          }}
        >
          Shipping
        </span>
      );
    } else if (
      data.status === PurchaseStatus.Complete ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "COMPLETE")
    ) {
      tempPurchaseStatus = <span class="badge bg-success">Complete</span>;
    } else if (
      data.status === PurchaseStatus.Cancel ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "CANCEL")
    ) {
      tempPurchaseStatus = <span class="badge bg-danger">Cancel</span>;
    } else {
      tempPurchaseStatus = (
        <span class="badge bg-secondary">{data.status || "-"}</span>
      );
    }
    const tempTableData = {
      checkbox: (
        <Form.Check
          id={`checkbox-select-id-${idx}`}
          type="checkbox"
          value={data.order_id}
        />
      ),
      no: startNo + idx,
      id: data.order_id,
      productName: data?.product_name,
      purchaseId: data?.code,
      shipTo: data?.ship_to,
      purchaseDate: data?.purchase_date,
      productCode: data.product_code,
      value: textAlignRight(data.value),
      shippingFee: textAlignRight(data.shipping_free),
      purchaseStatus: tempPurchaseStatus,
      remark: data.remark,
    };
    const buttonData = {
      no: startNo + idx,
      id: data.order_id,
      productName: data?.product_name,
      purchaseId: data?.code,
      shipTo: data?.ship_to,
      purchaseDate: data?.purchase_date,
      productCode: data.product_code,
      customerId: data.member_info_id,
      value: textAlignRight(data.value),
      shippingFee: textAlignRight(data.shipping_free),
      purchaseStatus: data.status,
      remark: data.remark,
    };
    tableData.push({
      ...tempTableData,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(buttonData);
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(buttonData);
            }}
          />
        </>
      ),
    });
  }
  return tableData;
};
