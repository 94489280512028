import React from "react";
import {
  Modal,
  ButtonToolbar,
  ToggleButtonGroup,
  Button,
} from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import CancelButton from "../components/Button/CancelButton";
import CreateButton from "../components/Button/CreateButton";
import GenericButton from "../components/Button/GenericButton";
import RadioButton from "../components/Button/RadioButton";
import { createCommonAPI, getCommonAPI } from "../services/commonAPIServices";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";

export default function DoctorTimeTablePending({
  listdays,
  doctorId,
  onSuccess,
}) {
  const listcount = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  return (
    <>
      <ButtonGroup type="checkbox">
        <Button variant="outline-secondary" style={{ width: "28%" , whiteSpace: "nowrap"}} disabled className="ttbtn">
          <font color="black">DAY / TIME</font>
        </Button>
        <Button variant="outline-secondary" style={{ width: "36%" , whiteSpace: "nowrap"}} disabled className="ttbtn">
          <font color="black">AM</font>
        </Button>
        <Button variant="outline-secondary" style={{ width: "36%" , whiteSpace: "nowrap"}} disabled className="ttbtn">
          <font color="black">PM</font>
        </Button>
      </ButtonGroup>
      {listdays.map((listdays, idx) => (
        <ButtonGroup type="checkbox" defaultValue={listdays.defaultValue}>
          <Button
            style={{ width: "28%" , whiteSpace: "nowrap"}}
            id={`${listdays.name}-Main-${idx}`}
            value={listdays.name}
            variant="outline-secondary"
            disabled
            className="ttbtn"
          >
            <font color="black">{listdays.name}</font>
          </Button>
          {listcount.map((listcount, idx) => (
            <Button
              style={{
                width: "3%",
                whiteSpace: "nowrap",
                backgroundColor:
                  listdays.defaultValue[idx] === 0
                    ? "#28a745"
                    : listdays.defaultValue[idx] === 1
                    ? "red"
                    : "",
              }}
              id={`${listdays.name}-checkbox-${idx}`}
              value={listcount}
              variant="outline-secondary"
              //onClick={() => testclick(listdays.name, idx)}
              disabled
              className="ttbtn"
            >
              <font color="black">{listcount}</font>
            </Button>
          ))}
        </ButtonGroup>
      ))}
    </>
  );
}
