import axios from "axios"
import { getDefaultRequestHeaders } from "../utils/Authen"
import moment from "moment"

export const getAuditLog = async ({
  page,
  limit,
  search,
  dateStart,
  dateEnd,
}) => {
  try {
    let datefilter
    if (dateStart && dateEnd) {
      datefilter = "$btw:" + dateStart + " 00:00:00," + dateEnd + " 23:59:59"
    }
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/report/audit_log",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit,
          search: search,
          "filter.action_datetime": datefilter,
        },
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getRevenueReport = async ({
  page,
  limit,
  search,
  dateStart,
  dateEnd,
}) => {
  try {
    let datefilter
    if (dateStart && dateEnd) {
      datefilter = "$btw:" + dateStart + " 00:00:00," + dateEnd + " 23:59:59"
    }
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/report/revenue",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit,
          search: search,
          "filter.action_datetime": datefilter,
        },
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getMockRevenueReport = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 3,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          id: "1",
          running: "INC0001",
          dateTime: "2023-01-01 13.01",
          role: "ค่ารักษานอกสถานที่",
          cus_name: "คุณxxxx yyyy",
          remark: "-",
          refer: "100001",
          status: "Success",
          view: "",
        },
        {
          id: "2",
          running: "INC0002",
          dateTime: "2023-01-01 14.00",
          role: "ค่าปรึกษา",
          cus_name: "คุณทดสอบ1 ทดสอบ",
          remark: "-",
          refer: "100001",
          status: "Pedding",
          view: "",
        },
        {
          id: "3",
          running: "INC0003",
          dateTime: "2023-01-01 15.00",
          role: "ค่าสินค้า",
          cus_name: "คุณทดสอบ2 ทดสอบ",
          remark: "-",
          refer: "100001",
          status: "Cancelled",
          view: "",
        },
      ],
    },
    error: null,
  }
}

export const getInventoryStockReport = async ({
  page,
  limit,
  search,
  dateStart,
  dateEnd,
}) => {
  try {
    let datefilter
    if (dateStart && dateEnd) {
      datefilter = "$btw:" + dateStart + " 00:00:00," + dateEnd + " 23:59:59"
    }
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/report/inventory_stock",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit,
          search: search,
          "filter.action_datetime": datefilter,
        },
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getMockInventoryStockReport = async ({
  page,
  pageSize,
  keyword,
}) => {
  return {
    success: true,
    data: {
      total: 3,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          id: "1",
          runningProduct: "06HDYDT2",
          nameProduct: "Taraxin (bot/1000tabHydroxyzine10MG)",
          groupProduct: "ยาแก้แพ้ ยาแก้คัน",
          dateTime: "2023-01-01 12.000",
          typeProduct: "Deduct",
          qty: "50",
          balance: "0",
          refer: "refer",
          remark: "-",
        },
        {
          id: "2",
          runningProduct: "06HDYDT2",
          nameProduct: "Taraxin (bot/1000tabHydroxyzine10MG)",
          groupProduct: "ยาแก้แพ้ ยาแก้คัน",
          dateTime: "2023-01-01 13.01",
          typeProduct: "Deduct",
          qty: "50",
          balance: "0",
          refer: "111111",
          remark: "-",
        },
        {
          id: "3",
          runningProduct: "06HDYDT2",
          nameProduct: "Taraxin (bot/1000tabHydroxyzine10MG)",
          groupProduct: "ยาแก้แพ้ ยาแก้คัน",
          dateTime: "2023-01-05 14.01",
          typeProduct: "Add",
          qty: "10",
          balance: "0",
          refer: "2222222",
          remark: "-",
        },
      ],
    },
    error: null,
  }
}

export const getExpenseReport = async ({
  page,
  limit,
  search,
  dateStart,
  dateEnd,
}) => {
  try {
    let datefilter
    if (dateStart && dateEnd) {
      datefilter = "$btw:" + dateStart + " 00:00:00," + dateEnd + " 23:59:59"
    }
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/report/expense",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit,
          search: search,
          "filter.action_datetime": datefilter,
        },
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getMockExpenseReport = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 3,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          id: "1",
          running: "INC0001",
          dateTime: "2023-01-01 13.01",
          role: "ค่ารักษานอกสถานที่",
          pay_name: "คุณxxxx yyyy",
          vals: "125.0",
          remark: "-",
          status: "Success",
          view: "",
        },
        {
          id: "2",
          running: "INC0002",
          dateTime: "2023-01-01 14.00",
          role: "ค่าปรึกษา",
          pay_name: "คุณxxxx yyyy",
          vals: "10.0",
          remark: "-",
          status: "Pedding",
          view: "",
        },
        {
          id: "3",
          running: "INC0003",
          dateTime: "2023-01-01 15.00",
          role: "ค่าสินค้า",
          pay_name: "คุณxxxx yyyy",
          vals: "100.0",
          remark: "-",
          status: "Cancelled",
          view: "",
        },
      ],
    },
    error: null,
  }
}

export const getBookingCancellationReport = async ({
  page,
  limit,
  search,
  dateStart,
  dateEnd,
}) => {
  try {
    let datefilter
    if (dateStart && dateEnd) {
      datefilter = "$btw:" + dateStart + " 00:00:00," + dateEnd + " 23:59:59"
    }
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/report/booking_cancellation",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit,
          search: search,
          "filter.booking_date": datefilter,
        },
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getMockฺBookingCancellationReport = async ({
  page,
  pageSize,
  keyword,
}) => {
  return {
    success: true,
    data: {
      total: 3,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          id: "1",
          caseID: "23030093",
          caseName: "ชิโร่",
          bookingDatetime: "2023-01-01 12.00",
          appointmentDatetime: "2023-01-15 12.00",
          bookingType: "รักษานอกสถานที่",
          caseOwner: "น.สพ.ชระ รักดี 10253",
          caseStatus: "Booking",
          remark: "ผ่าตัดด่วน",
          view: "",
        },
        {
          id: "1",
          caseID: "23030093",
          caseName: "ชิโร่",
          bookingDatetime: "2023-01-01 12.00",
          appointmentDatetime: "2023-01-15 12.00",
          bookingType: "รักษานอกสถานที่",
          caseOwner: "น.สพ.ชระ รักดี 10253",
          caseStatus: "Booking",
          remark: "ผ่าตัดด่วน",
          view: "",
        },
        {
          id: "3",
          caseID: "23030093",
          caseName: "ชิโร่",
          bookingDatetime: "2023-01-01 12.00",
          appointmentDatetime: "2023-01-15 12.00",
          bookingType: "รักษานอกสถานที่",
          caseOwner: "น.สพ.ชระ รักดี 10253",
          caseStatus: "Booking",
          remark: "ผ่าตัดด่วน",
          view: "",
        },
      ],
    },
    error: null,
  }
}
export const getDoctorWorkLogReport = async ({
  page,
  limit,
  search,
  selectedDate,
}) => {
  try {
    let Monthfilter
    let Yearfilter
    if (selectedDate) {
      Monthfilter = moment(selectedDate).format("MM")
      Yearfilter = moment(selectedDate).format("YYYY")
    }
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/report/doctor_worklog",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit,
          search: search,
          "filter.mm": Monthfilter,
          "filter.yyyy": Yearfilter,
        },
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getMockDoctorWorkLogReportReport = async ({
  page,
  pageSize,
  keyword,
}) => {
  return {
    success: true,
    data: {
      total: 2,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          id: "1",
          transectionID: "LOG00001",
          doctorName: "น.สพ.ชระ รักดี 10253",
          transectionDatetime: "2023-01-01 12.00",
          cusName: "คุณ xxxx xxxxx",
          time: "1",
          workValue: "500",
          expensesPlatform: "150",
          expensesHospital: "0",
          expensesDoctor: "350",
          transectionGroup: "รักษานอกสถานที่",
          status: "Success",
        },
        {
          id: "2",
          transectionID: "LOG00002",
          doctorName: "น.สพ.ชระ รักดี 10253",
          transectionDatetime: "2023-01-01 14.00",
          cusName: "คุณ xxxx xxxxx",
          time: "1",
          workValue: "500",
          expensesPlatform: "150",
          expensesHospital: "0",
          expensesDoctor: "350",
          transectionGroup: "รักษานอกสถานที่",
          status: "Cancelled",
        },
      ],
    },
    error: null,
  }
}
export const getEmsVanWorkLogReport = async ({
  page,
  limit,
  search,
  selectedDate,
}) => {
  try {
    let Monthfilter
    let Yearfilter
    if (selectedDate) {
      Monthfilter = moment(selectedDate).format("MM")
      Yearfilter = moment(selectedDate).format("YYYY")
    }
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/report/ems_van_worklog",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit,
          search: search,
          "filter.mm": Monthfilter,
          "filter.yyyy": Yearfilter,
        },
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getMockEmsVanWorkLogReport = async ({
  page,
  pageSize,
  keyword,
}) => {
  return {
    success: true,
    data: {
      total: 2,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          id: "1",
          transectionID: "LOG00001",
          doctorName: "น.สพ.ชระ รักดี 10253",
          transectionDatetime: "2023-01-01 12.00",
          cusName: "คุณ xxxx xxxxx",
          time: "1",
          workValue: "500",
          origin: "ลาดพร้าว",
          destination: "คลินิก xxxxx",
          transectionGroup: "รักษานอกสถานที่",
          status: "Success",
        },
        {
          id: "2",
          transectionID: "LOG00002",
          doctorName: "น.สพ.ชระ รักดี 10253",
          transectionDatetime: "2023-01-01 14.00",
          cusName: "คุณ xxxx xxxxx",
          time: "1",
          workValue: "500",
          origin: "บางเขน",
          destination: "คลินิก bbbbbb",
          transectionGroup: "รักษานอกสถานที่",
          status: "Cancelled",
        },
      ],
    },
    error: null,
  }
}
