import React from "react";
import { Button, Card, Container, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Script from "react-load-script";

import { getPaidInfo, paidPayment } from "../services/PaymentServices";

let OmiseCard;

export default function Payment() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [titleResultMessage, setTitleResultMessage] = React.useState("");
  const [subtitleResultMessage, setSubtitleResultMessage] = React.useState("");

  const [ref, setRef] = React.useState(null);
  const [key, setKey] = React.useState(null);
  const [amount, setAmount] = React.useState(null);
  const [paymentMethod, setPaymentMethod] = React.useState(null);

  const loadPaidInfo = React.useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const tempRef = searchParams.get("ref");
    const tempKey = searchParams.get("key");

    if (tempRef && tempKey) {
      setRef(tempRef);
      setKey(tempKey);
      const { success, data, error } = await getPaidInfo({
        authorizationHeader: `Bearer ${tempKey}`,
        params: {
          trn_no: tempRef,
        },
      });
      if (success) {
        setAmount(data?.amt);
        setPaymentMethod(data?.omise_payment_type);
      } else {
        setTitleResultMessage(`Payment failed`);
        setSubtitleResultMessage(`${error?.message}`);
      }
    } else {
      setTitleResultMessage(`Payment failed`);
      setSubtitleResultMessage(`Cannot get ref and key`);
    }
  }, []);

  const handleLoadScript = () => {
    OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      publicKey: "pkey_test_5wg1osid50kpmbew9hd",
      currency: "THB",
      location: "no",
      image:
        "https://pdapp.bbss.support/static/media/bg1.f6ee949762945f732541.jpg",
      buttonLabel: "จ่ายเงินให้พี่ป๊อป",
      frameLabel: "Pet Dreams",
      submitLabel: "ชำระเงินจำนวน",
      onCreateTokenSuccess: async (nonce) => {
        let body;
        if (nonce.startsWith("tokn_")) {
          body = { omiseToken: nonce, omiseSource: null, amount: amount };
        } else {
          body = { omiseSource: nonce, omiseToken: null, amount: amount };
        }
        const { success, data, error } = await paidPayment({
          authorizationHeader: `Bearer ${key}`,
          params: {
            trn_no: ref,
          },
          body: body,
        });
        // TODO: redirect ..
        if (success) {
          console.log("redirect to result page or qr code page");
        } else {
          setTitleResultMessage(`Payment failed`);
          setSubtitleResultMessage(`${error?.message}`);
        }
      },
    });
  };

  React.useEffect(() => {
    loadPaidInfo();
    setIsLoading(false);
  }, [loadPaidInfo]);

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo">
            Pet Dreams
          </Link>
        </Container>
      </div>

      {isLoading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner
              animation="border"
              variant="info"
              size="lg"
              className="me-3"
            />
            <br/><h3>Loading...</h3>
          </div>
        </>
      ) : (
        <div className="content">
          <Container>
            <Card className="card-auth">
              <Card.Body className="text-center">
                {titleResultMessage ? (
                  <>
                    <Card.Title>{titleResultMessage}</Card.Title>
                    <Card.Text>{subtitleResultMessage}</Card.Text>
                  </>
                ) : (
                  <>
                    <Script
                      url="https://cdn.omise.co/omise.js"
                      onLoad={handleLoadScript}
                    />
                    <form>
                      <Button
                        id="payment"
                        onClick={(e) => {
                          e.preventDefault();
                          OmiseCard?.configure({
                            amount: amount,
                            defaultPaymentMethod: paymentMethod,
                            otherPaymentMethods: [],
                          });
                          OmiseCard?.configureButton("#payment");
                          OmiseCard?.attach();
                        }}
                      >
                        ชำระเงิน
                      </Button>
                      <input type="hidden" name="omiseToken" />
                      <input type="hidden" name="omiseSource" />
                    </form>
                  </>
                )}
              </Card.Body>
            </Card>
          </Container>
        </div>
      )}
    </div>
  );
}
