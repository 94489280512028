import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import GenericButton from "../Button/GenericButton";
import feather from "feather-icons/dist/feather";
import { checkPermissionButton } from "../../utils/Help";

export default function PageHeaderCard({
  title,
  buttonEnable,
  buttonIcon,
  buttonMessage,
  onButtonOnClick,
  buttonEnable2,
  buttonIcon2,
  buttonMessage2,
  onButtonOnClick2,
  screenId = "",
}) {
  React.useEffect(() => {
    feather.replace();
  }, []);

  if (checkPermissionButton(screenId, "f_add")) {
    return (
      <>
        <Card body className="mb-2">
          <Container fluid>
            <Row style={{ alignItems: "center", minHeight: "3rem" }}>
              <Col
                style={{
                  fontSize: "21px",
                }}
              >
                {title}
              </Col>
              <Col style={{ display: "flex", justifyContent: "end" }}>
                {buttonEnable && (
                  <GenericButton
                    onClick={() => onButtonOnClick()}
                    variant="success"
                  >
                    <i data-feather={buttonIcon} />
                    {buttonMessage}
                  </GenericButton>
                )}
                {buttonEnable2 && (
                  <GenericButton
                    onClick={() => onButtonOnClick2()}
                    variant="success"
                    className="ms-2"
                  >
                    <i data-feather={buttonIcon2} />
                    {buttonMessage2}
                  </GenericButton>
                )}
              </Col>
            </Row>
          </Container>
        </Card>
      </>
    );
  }

  return (
    <>
      <Card body className="mb-2">
        <Container fluid>
          <Row style={{ alignItems: "center", minHeight: "3rem" }}>
            <Col
              style={{
                fontSize: "21px",
              }}
            >
              {title}
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  );
}
