import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import {
  adviseCallStatusOptions,
  carrierOptions,
  purchaseStatusOptions,
} from "../../data/SelectOptions";
import {
  createProductOrder,
  deletePaymentImage,
  editProductOrder,
  getAddressInfo,
  getCustomerInfo,
  getPaymentImage,
  getProductOrderDetail,
  saveProductOrderDetail,
} from "../../services/ProductManagenentServices";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import {
  getDistrictInfo,
  getProvinceInfo,
  getSubDistrictInfo,
} from "../../services/OnlineBookingServices";
import { phoneInvalidMessage, phoneRegExp } from "../../data/Config";
import {
  PaymentProductOrderColumn,
  PaymentProductOrderNoDeleteColumn,
  ProductOrderDetailColumn,
  ProductOrderDetailNoEditColumn,
} from "../../data/Columns";
import ReactTable from "../../components/ReactTable";
import { uploadImage } from "../../services/SettingServices";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import AddProductModal from "../Modal/AddProductModal";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";
import EditButton from "../../components/Button/EditButton";
import DeleteButton from "../../components/Button/DeleteButton";
import AddProductPhotoSetModal from "../Modal/AddProductPhotoSetModal";
import AddPaymentPhotoModal from "../Modal/AddPaymentPhotoModal";
import { getAdminUser } from "../../services/UserServices";
import moment from "moment/moment";

const defaultSchema = {
  // productCode: yup.string().required("ห้ามว่าง"),
  customerId: yup.object().required("ห้ามว่าง"),
  name: yup.string().required("ห้ามว่าง"),
  phone: yup
    .string()
    .matches(phoneRegExp, phoneInvalidMessage)
    .required("ห้ามว่าง"),
  address: yup.string().required("ห้ามว่าง"),
  street: yup.string().required("ห้ามว่าง"),
  subDistrict: yup.object().required("ห้ามว่าง"),
  district: yup.object().required("ห้ามว่าง"),
  province: yup.object().required("ห้ามว่าง"),
  postcode: yup.object().required("ห้ามว่าง"),
  remark: yup.string().required("ห้ามว่าง"),
  products: yup.array().min(1, "ห้ามว่าง").required("ห้ามว่าง"),
};

export default function CreateEditOrderForm({
  showDeliveryInfo,
  showAddProduct,
  customerIdReadOnly,
  paymentMethodReadOnly,
  isCreate,
  onCancel,
  onSubmitSuccess,
  productCodeDefaultValue,
  purchaseStatusDefaultValue = 20,
  shippingFeeDefaultValue,
  paymentIdDefaultValue,
  customerIdDefaultValue,
  nameDefaultValue,
  phoneDefaultValue,
  addressDefaultValue,
  streetDefaultValue,
  subDistrictDefaultValue,
  districtDefaultValue,
  provinceDefaultValue,
  postcodeDefaultValue,
  carrierDefaultValue,
  trackingCodeDefaultValue,
  datePickupDefaultValue,
  adviceeDefaultValue,
  advicerDefaultValue,
  remarkDefaultValue,
  id,
  purchaseDateDefaultValue,
}) {
  const [yubSchema, setYubSchema] = React.useState(yup.object().shape());
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [provinceSelect, setProvinceSelect] = React.useState([]);
  const [districtSelect, setDistrictSelect] = React.useState([]);
  const [subDistrictSelect, setSubDistrictSelect] = React.useState([]);
  const [postcodeSelect, setPostcodeSelect] = React.useState([]);
  const [customerInfoSelect, setCustomerInfoSelect] = React.useState([]);
  const [advicerInfoSelect, setAdvicerInfoSelect] = React.useState([]);
  const [paymentTableData, setPaymentTableData] = React.useState([]);
  const [totalValue, setTotalValue] = React.useState(0);

  const [showProductModal, setShowProductModal] = React.useState(false);
  const [showPaymentModal, setShowPaymentModal] = React.useState(false);
  const [showPaymentImageViewModal, setShowPaymentImageViewModal] =
    React.useState(false);
  const [paymentImage, setPaymentImage] = React.useState();

  const [isDeleteProduct, setIsDeleteProduct] = React.useState(false);
  const [isDeletePayment, setIsDeletePayment] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [editNo, setEditNo] = React.useState();
  const [deleteData, setDeleteData] = React.useState();
  const [isCreateProduct, setIsCreateProduct] = React.useState(false);

  const paymentColumnsData = PaymentProductOrderColumn;
  const paymentNoDeleteColumnsData = PaymentProductOrderNoDeleteColumn;
  const productColumnsData = showAddProduct
    ? ProductOrderDetailColumn
    : ProductOrderDetailNoEditColumn;

  const {
    register,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yubSchema),
  });

  const setSchema = React.useCallback(() => {
    let tempYupObj = defaultSchema;
    if (!isCreate) {
      tempYupObj["purchaseStatus"] = yup.object().required("ห้ามว่าง");
      tempYupObj["shippingFee"] = yup.number().required("ห้ามว่าง");
    } else {
      tempYupObj["purchaseStatus"] = yup.object();
      tempYupObj["shippingFee"] = yup.number();
    }
    if (showDeliveryInfo) {
      tempYupObj["carrier"] = yup.object().required("ห้ามว่าง");
      tempYupObj["trackingCode"] = yup.string().required("ห้ามว่าง");
      tempYupObj["datePickup"] = yup.string().required("ห้ามว่าง");
      tempYupObj["advicee"] = yup.object().required("ห้ามว่าง");
      tempYupObj["advicer"] = yup.object().required("ห้ามว่าง");
    }
    const schema = yup.object().shape(tempYupObj);
    setYubSchema(schema);
  }, [isCreate, showDeliveryInfo]);

  const prepareProvinceListForSelect = React.useCallback(
    (datas, provinceDefault) => {
      let listsProvince = [];
      for (const [idx, data] of datas.entries()) {
        let tempProvince = {
          id: idx,
          label: data.name,
          value: data.id,
        };
        listsProvince.push(tempProvince);
        if (
          (firstLoad && data.id === provinceDefaultValue) ||
          data.id === provinceDefault
        ) {
          setValue("province", tempProvince, { shouldValidate: true });
        }
      }
      return listsProvince;
    },
    [firstLoad, provinceDefaultValue, setValue]
  );

  const prepareDistrictListForSelect = React.useCallback(
    (datas, districtDefault) => {
      let listsDistrict = [];
      for (const [idx, data] of datas.entries()) {
        let tempDistrict = {
          id: idx,
          label: data.name,
          value: data.id,
        };
        listsDistrict.push(tempDistrict);
        if (
          (firstLoad && data.id === districtDefaultValue) ||
          data.id === districtDefault
        ) {
          setValue("district", tempDistrict, { shouldValidate: true });
        }
      }
      return listsDistrict;
    },
    [firstLoad, districtDefaultValue, setValue]
  );

  const prepareSubDistrictListForSelect = React.useCallback(
    (datas, subDistrictDefault) => {
      let listsSubDistrict = [];
      for (const [idx, data] of datas.entries()) {
        let tempSubDistrict = {
          id: idx,
          label: data.name,
          value: { id: data.id, postcode: data.postcode },
        };
        listsSubDistrict.push(tempSubDistrict);
        if (
          (firstLoad && data.id === subDistrictDefaultValue) ||
          data.id === subDistrictDefault
        ) {
          setValue("subDistrict", tempSubDistrict, { shouldValidate: true });
          setValue(
            "postcode",
            {
              id: 1,
              label: data.postcode,
              value: data.postcode,
            },
            { shouldValidate: true }
          );
          setPostcodeSelect([
            {
              id: 1,
              label: data.postcode,
              value: data.postcode,
            },
          ]);
        }
      }
      return listsSubDistrict;
    },
    [firstLoad, subDistrictDefaultValue, setValue]
  );

  const loadProvince = React.useCallback(
    async (provinceDefault) => {
      const { success, data, error } = await getProvinceInfo();
      if (success) {
        setProvinceSelect(
          prepareProvinceListForSelect(data.records, provinceDefault)
        );
      } else {
        ShowMessageAndExpire(
          "Load Province Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [prepareProvinceListForSelect]
  );

  const loadDistrictSelect = React.useCallback(
    async (provinceId, districtDefault) => {
      const { success, data, error } = await getDistrictInfo("", {
        "filter.m_province_id": provinceId,
      });
      if (success) {
        setDistrictSelect(
          prepareDistrictListForSelect(data.records, districtDefault)
        );
      } else {
        ShowMessageAndExpire(
          "Load District Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [prepareDistrictListForSelect]
  );

  const loadSubDistrictSelect = React.useCallback(
    async (districtId, subDistrictDefault) => {
      const { success, data, error } = await getSubDistrictInfo("", {
        "filter.m_district_id": districtId,
      });
      if (success) {
        setSubDistrictSelect(
          prepareSubDistrictListForSelect(data.records, subDistrictDefault)
        );
      } else {
        ShowMessageAndExpire(
          "Load Sub-District Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [prepareSubDistrictListForSelect]
  );

  const setValueAddressForm = React.useCallback(
    async (memberId) => {
      const { success, data, error } = await getAddressInfo({
        pathParams: "datatable",
        params: {
          "filter.any_id": memberId,
          "filter.user_type": 0,
          page: 1,
          "filter._relations": "rel_m_subdistrict_id",
        },
      });
      if (success && data.records[0]) {
        let address = data.records[0];
        setValue("address", address.addressno, { shouldValidate: true });
        setValue("street", address.street, { shouldValidate: true });
        loadProvince(address.m_province_id);
        loadDistrictSelect(address.m_province_id, address.m_district_id);
        loadSubDistrictSelect(address.m_district_id, address.m_subdistrict_id);
      } else if (success) {
        setValue("address", null, { shouldValidate: true });
        setValue("street", null, { shouldValidate: true });
        setValue("province", null, { shouldValidate: true });
        setValue("district", null, { shouldValidate: true });
        setValue("subDistrict", null, { shouldValidate: true });
        setValue("postcode", null, { shouldValidate: true });
      } else {
        ShowMessageAndExpire(
          "Load Member Address Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [setValue]
  );

  const prepareCumtomerInfoForSelect = React.useCallback(
    (datas) => {
      let listsCustomerInfo = [];
      for (const [idx, data] of datas.entries()) {
        let tempCustomerInfo = {
          id: idx,
          label: `${data.name} ${data.lname}`,
          value: data.id,
          phone: data.phone,
        };
        listsCustomerInfo.push(tempCustomerInfo);
        if (firstLoad && data.id === customerIdDefaultValue) {
          setValue("customerId", tempCustomerInfo, { shouldValidate: true });
          setValue("name", tempCustomerInfo.label, { shouldValidate: true });
          setValue("phone", tempCustomerInfo.phone, { shouldValidate: true });
          setValueAddressForm(tempCustomerInfo.value);
        }
      }
      return listsCustomerInfo;
    },
    [firstLoad, customerIdDefaultValue, setValue]
  );

  const loadCustomerInfoSelect = React.useCallback(async () => {
    const { success, data, error } = await getCustomerInfo({
      params: {
        select: "id,name,lname,phone",
      },
    });
    if (success) {
      setCustomerInfoSelect(prepareCumtomerInfoForSelect(data.records));
    } else {
      ShowMessageAndExpire(
        "Load Customer Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [prepareCumtomerInfoForSelect]);

  const prepareAdvicerInfoForSelect = React.useCallback(
    (datas) => {
      let listAdvicerInfo = [];
      for (const [idx, data] of datas.entries()) {
        let tempAdvicerInfo = {
          id: idx,
          label: `${data.name} ${data.lname}`,
          value: data.id,
        };
        listAdvicerInfo.push(tempAdvicerInfo);
        if (firstLoad && data.id === advicerDefaultValue) {
          setValue("advicer", tempAdvicerInfo, { shouldValidate: true });
        }
      }
      return listAdvicerInfo;
    },
    [firstLoad, customerIdDefaultValue, setValue]
  );

  const loadAdvicerSelect = React.useCallback(async () => {
    const { success, data, error } = await getAdminUser({});
    if (success) {
      setAdvicerInfoSelect(prepareAdvicerInfoForSelect(data.records));
    } else {
      ShowMessageAndExpire(
        "Load Advicer Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [prepareCumtomerInfoForSelect]);

  const prepareProductTableData = React.useCallback(
    (datas, onEdit, onDelete) => {
      let items = [];
      let tempTotalValue = 0;
      for (const [idx, data] of datas.entries()) {
        let totalPrice = data.price_unit * data.qty;
        items.push({
          no: idx + 1,
          productId: data.product_id,
          item: data.rel_product_id?.product_name,
          price: data.price_unit,
          quantity: data.qty,
          totalPrice: totalPrice,
          editDeleteButton: (
            <>
              <EditButton
                onClick={() => {
                  onEdit({
                    no: idx + 1,
                    productId: data.product_id,
                    price: data.price_unit,
                    quantity: data.qty,
                  });
                }}
              />{" "}
              <DeleteButton
                onClick={() => {
                  onDelete({ productId: data.product_id });
                }}
              />
            </>
          ),
        });
        tempTotalValue += totalPrice;
      }
      setTotalValue(tempTotalValue);
      return items;
    },
    []
  );

  const handleOpenEditProductForm = (data) => {
    setShowProductModal(true);
    setEditNo(data.no);
    setEditData(data);
    setIsCreateProduct(false);
  };

  const handleOpenDeleteProductConfirm = (data) => {
    setIsDeleteProduct(true);
    setDeleteData(data);
  };

  const handleOpenDeletePaymentConfirm = (data) => {
    setIsDeletePayment(true);
    setDeleteData(data);
  };

  const loadOrderDetail = React.useCallback(async () => {
    setValue("purchaseDate", new Date(purchaseDateDefaultValue));
    const { success, data, error } = await getProductOrderDetail({
      params: {
        "filter.product_order_id": id,
        "filter._relations": "rel_product_id",
      },
    });
    if (success) {
      setValue(
        "products",
        prepareProductTableData(
          data.records,
          handleOpenEditProductForm,
          handleOpenDeleteProductConfirm
        )
      );
    } else {
      ShowMessageAndExpire(
        "Load Order Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [prepareProductTableData, id, setValue]);

  const preparePaymentImageForTable = React.useCallback((datas, onDelete) => {
    let paymentImages = [];
    for (const [idx, data] of datas.entries()) {
      paymentImages.push({
        id: idx + 1,
        item: (
          <img
            src={data.picture_path}
            alt="Preview"
            style={{
              width: "150px",
            }}
            onClick={() => {
              setShowPaymentImageViewModal(data.picture_path);
              setPaymentImage(data.picture_path);
            }}
          />
        ),
        deleteButton: (
          <DeleteButton
            onClick={() => {
              onDelete({ id: data.id });
            }}
          />
        ),
      });
    }
    return paymentImages;
  }, []);

  const loadPaymentImage = React.useCallback(
    async (paymentId) => {
      const { success, data, error } = await getPaymentImage({
        params: {
          payment_id: paymentId,
        },
      });
      if (success) {
        setPaymentTableData(
          preparePaymentImageForTable(
            data.records,
            handleOpenDeletePaymentConfirm
          )
        );
      } else {
        ShowMessageAndExpire(
          "Load Payment Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [prepareProductTableData, id, setValue]
  );

  React.useEffect(() => {
    setSchema();
    loadProvince();
    loadCustomerInfoSelect();
    if (showDeliveryInfo) {
      loadAdvicerSelect(advicerDefaultValue);
    }
    if (!isCreate) {
      loadDistrictSelect(provinceDefaultValue);
      loadSubDistrictSelect(districtDefaultValue);
      loadOrderDetail();
      if (paymentIdDefaultValue) {
        loadPaymentImage(paymentIdDefaultValue);
      }
    }
    setFirstLoad(false);
  }, [
    provinceDefaultValue,
    districtDefaultValue,
    paymentIdDefaultValue,
    isCreate,
    setSchema,
    loadProvince,
    loadDistrictSelect,
    loadSubDistrictSelect,
    loadCustomerInfoSelect,
    loadOrderDetail,
    loadPaymentImage,
  ]);

  const refreshPage = React.useCallback(() => {
    setShowProductModal(false);
    setEditData(null);
    setIsCreateProduct(false);
  }, []);

  const handleAddProduct = React.useCallback(
    (data) => {
      let tempTotalValue = 0;
      let newProductTableData = [];
      if (isCreateProduct) {
        let currentProductTableData = getValues("products") || [];
        let newData = [
          {
            no: currentProductTableData.length + 1,
            productId: data.product.value?.id,
            item: data.product?.label,
            price: data.product?.value?.price,
            quantity: data.quantity,
            totalPrice:
              (+data.product?.value?.price &&
                +data.quantity &&
                data.product?.value?.price * data.quantity) ||
              0,
            editDeleteButton: (
              <>
                <EditButton
                  onClick={() => {
                    handleOpenEditProductForm({
                      no: currentProductTableData.length + 1,
                      productId: data.product.value?.id,
                      price: data.product?.value?.price,
                      quantity: data.qty,
                    });
                  }}
                />{" "}
                <DeleteButton
                  onClick={() => {
                    handleOpenDeleteProductConfirm({
                      productId: data.product.value?.id,
                    });
                  }}
                />
              </>
            ),
          },
        ];
        newProductTableData = currentProductTableData.concat(newData);
        for (const product of newProductTableData) {
          tempTotalValue += product?.totalPrice;
        }
      } else {
        let currentProductTableData = getValues("products");
        const editItem = currentProductTableData.filter(
          (v) => v?.no === editNo
        )[0];
        const editIdx = currentProductTableData.indexOf(editItem);
        const newTotalPrice =
          (+data.product?.value?.price &&
            +data.quantity &&
            data.product?.value?.price * data.quantity) ||
          0;
        tempTotalValue =
          totalValue -
          currentProductTableData[editIdx]?.totalPrice +
          newTotalPrice;
        currentProductTableData[editIdx] = {
          no: editNo,
          productId: data.product.value?.id,
          item: data.product?.label,
          price: data.product?.value?.price,
          quantity: data.quantity,
          totalPrice: newTotalPrice,
          editDeleteButton: (
            <>
              <EditButton
                onClick={() => {
                  handleOpenEditProductForm({
                    no: editNo,
                    productId: data.product.value?.id,
                    price: data.product?.value?.price,
                    quantity: data.quantity,
                  });
                }}
              />{" "}
              <DeleteButton
                onClick={() => {
                  handleOpenDeleteProductConfirm({
                    productId: data.product.value?.id,
                  });
                }}
              />
            </>
          ),
        };
        newProductTableData = currentProductTableData;
      }
      setTotalValue(tempTotalValue);
      setValue("products", newProductTableData, { shouldValidate: true });
      refreshPage();
    },
    [getValues, setValue, refreshPage, isCreateProduct, totalValue, editNo]
  );

  const handleDeleteProduct = React.useCallback(
    (data) => {
      let tempTotalValue = totalValue;
      let currentProductTableData = getValues("products");
      let deleteItem = currentProductTableData.filter(
        (v) => v.productId === data
      )[0];
      setTotalValue(tempTotalValue - deleteItem?.totalPrice);
      let removeIdx = currentProductTableData.indexOf(deleteItem);
      currentProductTableData.splice(removeIdx, 1);
      setValue("products", currentProductTableData, { shouldValidate: true });
      setIsDeleteProduct(false);
    },
    [getValues, setValue, totalValue]
  );

  const handleAddPayment = React.useCallback(async () => {
    setShowPaymentModal(false);
    await loadPaymentImage(paymentIdDefaultValue);
  }, []);

  const handleDeletePayment = React.useCallback(async (id) => {
    const { success, error } = await deletePaymentImage({
      pathParams: id,
    });
    if (success) {
      ShowMessageAndExpire("Data deleted successfully.", "", "success");
      setShowPaymentModal(false);
      await loadPaymentImage(paymentIdDefaultValue);
    } else {
      ShowMessageAndExpire(
        "Failed to delete data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, []);

  const onSubmit = async (inputData) => {
    let isSaveProductOrder = false;
    let tempOrderId = id;
    let tempBody = {
      status: inputData.purchaseStatus?.value,
      shipping_free: inputData.shippingFee,
      member_info_id: inputData.customerId?.value,
      rname: inputData.name,
      phone: inputData.phone,
      addressno: inputData.address,
      street: inputData.street,
      m_subdistrict_id: inputData.subDistrict?.value?.id,
      remark: inputData.remark,
    };
    if (isCreate) {
      let currentDatetime = new Date();
      tempBody.code = `ST${moment(currentDatetime).format("YYYYMMDDHHmmss")}`;
    }
    if (showDeliveryInfo) {
      tempBody.delivery_type = inputData?.carrier?.value;
      tempBody.tracking_code = inputData?.trackingCode;
      tempBody.shipping_date = inputData?.datePickup;
      tempBody.advicee = inputData.advicee?.value;
      tempBody.user_admin_id = inputData.advicer?.value;
    }
    if (isCreate) {
      const { success, data, error } = await createProductOrder({
        body: tempBody,
      });
      if (success) {
        isSaveProductOrder = true;
        tempOrderId = data.id;
      } else {
        ShowMessageAndExpire(
          "Failed to create data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    } else {
      const { success, error } = await editProductOrder({
        pathParams: id,
        body: tempBody,
      });
      if (success) {
        isSaveProductOrder = true;
      } else {
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    }
    if (isSaveProductOrder) {
      let tempDetailBody = {
        member_info_id: inputData.customerId?.value,
        remark: inputData.remark,
        status: inputData.purchaseStatus?.value,
        product_order_items: inputData.products.map((data) => ({
          product_id: data?.productId,
          qty: data?.quantity,
        })),
      };
      const { success, error } = await saveProductOrderDetail({
        pathParams: tempOrderId,
        body: tempDetailBody,
      });
      if (success) {
        isSaveProductOrder = true;
        onSubmitSuccess();
        if (isCreate) {
          ShowMessageAndExpire("Data created successfully.", "", "success");
        } else {
          ShowMessageAndExpire("Data updated successfully.", "", "success");
        }
      } else {
        if (isCreate) {
          ShowMessageAndExpire("Create Order Detail Failed", "", "error");
        } else {
          ShowMessageAndExpire("Update Order Detail Failed", "", "error");
        }
      }
    }
  };

  return (
    <>
      {showProductModal && (
        <AddProductModal
          show={showProductModal}
          setShow={setShowProductModal}
          onSave={handleAddProduct}
          onCancel={refreshPage}
          productDefaultValue={editData?.productId}
          quantityDefaultValue={editData?.quantity}
        />
      )}
      <DeleteConfirmModal
        show={isDeleteProduct}
        onClose={() => {
          setIsDeleteProduct(false);
          setDeleteData(null);
        }}
        onComfirm={handleDeleteProduct}
        deleteRecord={deleteData?.productId}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these record?"}</p>
            <p>{`id = ${deleteData?.productId}`}</p>
          </>
        }
      />
      {showPaymentModal && (
        <AddPaymentPhotoModal
          show={showPaymentModal}
          setShow={() => {
            setShowPaymentModal();
          }}
          onSave={handleAddPayment}
          orderId={id}
          paymentId={paymentIdDefaultValue}
          Model="product_order"
        />
      )}
      <DeleteConfirmModal
        show={isDeletePayment}
        onClose={() => {
          setIsDeletePayment(false);
          setDeleteData(null);
        }}
        onComfirm={handleDeletePayment}
        deleteRecord={deleteData?.id}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these record?"}</p>
            <p>{`id = ${deleteData?.id}`}</p>
          </>
        }
      />
      {showPaymentImageViewModal && (
        <Modal
          show={showPaymentImageViewModal}
          onHide={() => {
            setShowPaymentImageViewModal(false);
            setPaymentImage(null);
          }}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img
              src={paymentImage}
              alt="Preview"
              style={{
                width: "450px",
              }}
            />
          </Modal.Body>
        </Modal>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4>ข้อมูลสินค้า</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="purchaseDate"
              label="Purchase Date"
              placeholder="Purchase Date"
              type="dateTime"
              defaultValue={""}
              disabled
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              name="productCode"
              label="Purchase ID"
              placeholder="Purchase ID"
              defaultValue={productCodeDefaultValue}
              disabled
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="purchaseStatus"
              label="Status"
              placeholder="Status"
              defaultValue={purchaseStatusDefaultValue}
              options={purchaseStatusOptions}
              required={!isCreate}
              disabled={isCreate}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              name="purchaseValue"
              label="Value"
              placeholder="Value"
              defaultValue={null}
              value={totalValue}
              disabled
              readOnly
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="shippingFee"
              label="Shipping Fee"
              placeholder="Shipping Fee"
              defaultValue={shippingFeeDefaultValue}
              type="number"
              error={errors.shippingFee}
              required={!isCreate}
              disabled={isCreate}
            />
          </Form.Group>
        </Row>
        <h4>ข้อมูลผู้รับ</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="customerId"
              label="Customer ID"
              placeholder="Customer ID"
              defaultValue={null}
              options={customerInfoSelect}
              required
              onChange={(select) => {
                setValue("name", select.label, { shouldValidate: true });
                setValue("phone", select.phone, { shouldValidate: true });
                setValueAddressForm(select.value);
              }}
              disabled={customerIdReadOnly}
              readOnly={customerIdReadOnly}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="name"
              label="ชื่อ-นามสกุล ผู้รับ"
              placeholder="ชื่อ-นามสกุล ผู้รับ"
              defaultValue={nameDefaultValue}
              error={errors.name}
              required
              disabled
              readOnly
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="phone"
              label="หมายเลขโทรศัพท์"
              placeholder="หมายเลขโทรศัพท์"
              defaultValue={phoneDefaultValue}
              error={errors.phone}
              required
              disabled
              readOnly
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="address"
              label="Address"
              placeholder="Address"
              // defaultValue={addressDefaultValue}
              error={errors.address}
              required
              disabled
              readOnly
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="street"
              label="Street"
              placeholder="Street"
              // defaultValue={streetDefaultValue}
              error={errors.street}
              required
              disabled
              readOnly
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="province"
              label="Province"
              placeholder="Province"
              defaultValue={null}
              required
              options={provinceSelect}
              onChange={(e) => {
                if (e?.value) {
                  loadDistrictSelect(e.value);
                  setValue("postcode", null);
                } else {
                  setDistrictSelect([]);
                  setValue("district", null);
                  setSubDistrictSelect([]);
                  setValue("subDistrict", null);
                  setPostcodeSelect([]);
                  setValue("postcode", null);
                }
              }}
              disabled
              readOnly
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="district"
              label="District"
              placeholder="District"
              defaultValue={null}
              required
              // disabled={districtSelect.length === 0}
              disabled
              readOnly
              options={districtSelect}
              onChange={(e) => {
                if (e?.value) {
                  loadSubDistrictSelect(e.value);
                  setPostcodeSelect([]);
                  setValue("postcode", null);
                } else {
                  setSubDistrictSelect([]);
                  setValue("subDistrict", null);
                  setPostcodeSelect([]);
                  setValue("postcode", null);
                }
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="subDistrict"
              label="Sub-District"
              placeholder="Sub-District"
              defaultValue={null}
              required
              disabled
              readOnly
              // disabled={subDistrictSelect.length === 0}
              options={subDistrictSelect}
              onChange={(e) => {
                if (e?.value) {
                  setValue(
                    "postcode",
                    {
                      id: 1,
                      label: e.value?.postcode,
                      value: e.value?.postcode,
                    },
                    { shouldValidate: true }
                  );
                  setPostcodeSelect([
                    {
                      id: 1,
                      label: e.value?.postcode,
                      value: e.value?.postcode,
                    },
                  ]);
                } else {
                  setPostcodeSelect([]);
                  setValue("postcode", null);
                }
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="postcode"
              label="Postcode"
              placeholder="Postcode"
              required
              // disabled={postcodeSelect.length === 0}
              disabled
              readOnly
              options={postcodeSelect}
            />
          </Form.Group>
        </Row>
        {showDeliveryInfo && (
          <>
            <h4>ข้อมูลการจัดส่ง</h4>
            <hr />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="carrier"
                  label="Carrier"
                  placeholder="Carrier"
                  defaultValue={carrierDefaultValue}
                  options={carrierOptions}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="trackingCode"
                  label="Tracking Code"
                  placeholder="Tracking Code"
                  defaultValue={trackingCodeDefaultValue}
                  error={errors.trackingCode}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericDateTimePickerInput2
                  control={control}
                  name="datePickup"
                  label="Date Pickup"
                  placeholder="Date Pickup"
                  type="dateTime"
                  defaultValue={datePickupDefaultValue}
                  required
                  error={errors.datePickup}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="advicee"
                  label="Advicee"
                  placeholder="Advicee"
                  defaultValue={adviceeDefaultValue}
                  options={adviseCallStatusOptions}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="advicer"
                  label="Advisor"
                  placeholder="Advicer"
                  defaultValue={advicerDefaultValue}
                  options={advicerInfoSelect}
                  required
                />
              </Form.Group>
              <Col />
            </Row>
          </>
        )}
        <h4>หมายเหตุ</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="remark"
              label="Remark"
              placeholder="Remark"
              as="textarea"
              defaultValue={remarkDefaultValue}
              error={errors.remark}
              required
            />
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <h5>
              {`Product Code ${productCodeDefaultValue || ""}`}
              <p style={{ color: "red", display: "inline" }}>{"*"}</p>
              <p
                style={{
                  color: "#dc3545",
                  display: "inline",
                  fontSize: "0.875em",
                  marginTop: "0.25rem",
                }}
              >
                {errors.products?.message}
              </p>
            </h5>
          </Col>
          {showAddProduct && (
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="success"
                onClick={() => {
                  setIsCreateProduct(true);
                  setShowProductModal(true);
                }}
                className="ms-3"
              >
                {" "}
                Add
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col className="mx-3">
            <ReactTable
              columns={productColumnsData}
              data={watch("products") || []}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: "20rem",
            }}
          >{`ยอดการสั่งซื้อ ${totalValue} ฿`}</Col>
        </Row>
        {!isCreate && (
          <>
            <Row>
              <Col>
                Payment Method
                {!paymentMethodReadOnly && (
                  <Button
                    variant="success"
                    onClick={() => setShowPaymentModal(true)}
                    className="ms-3"
                    disabled={!paymentIdDefaultValue}
                  >
                    {" "}
                    Upload
                  </Button>
                )}
              </Col>
            </Row>
            {paymentIdDefaultValue && (
              <Row>
                <Col className="mx-3">
                  {paymentMethodReadOnly ? (
                    <ReactTable
                      columns={paymentNoDeleteColumnsData}
                      data={paymentTableData}
                    />
                  ) : (
                    <ReactTable
                      columns={paymentColumnsData}
                      data={paymentTableData}
                    />
                  )}
                </Col>
              </Row>
            )}
          </>
        )}

        <Row className="my-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
