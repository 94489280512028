// import { store } from "../global_store/store";
// import {
//   setBodyMessage,
//   setMessageType,
//   setShowMessageModal,
//   setTitleMessage,
// } from "../global_store/features/MessageSlice";

// export default function ShowMessageAndExpire(
//   titleMessage,
//   bodyMessage,
//   messageType
// ) {
//   if (store.getState().authen.showSessionExpiredModal === false) {
//     store.dispatch(setShowMessageModal(true));
//     store.dispatch(setTitleMessage(titleMessage));
//     store.dispatch(setBodyMessage(bodyMessage));
//     store.dispatch(setMessageType(messageType));
//   }
//   return true;
// }
import { store } from "../global_store/store";
import Swal from "sweetalert2";

export default function ShowMessageAndExpire(
  titleMessage,
  bodyMessage,
  messageType
) {
    if(messageType === 'error'){
        bodyMessage = bodyMessage + '<br/><br/>Please try again later.'
    }
  if (store.getState().authen.showSessionExpiredModal === false) {
    Swal.fire({
      icon: messageType, // Use messageType to determine the alert icon (success, error, warning)
      title: titleMessage,
      html: bodyMessage,
      customClass: {
        confirmButton: "btn btn-primary", // Apply custom CSS class to the OK button
      },
      buttonsStyling: false, // Disable default button styling
    });
  }
  return true;
}