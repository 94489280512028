import React from "react";
import { Button, Modal } from "react-bootstrap";
import {
  selectShowSessionExpiredModal,
  setShowSessionExpiredModal,
  setUser,
} from "../../global_store/features/AuthenSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../global_store/hooks";
import 'sweetalert2/dist/sweetalert2.min.css';
import Swal from 'sweetalert2';

// export default function SessionExpiredModal() {
//   const dispatch = useAppDispatch();
//   const navigate = useNavigate();
//   const show = useAppSelector(selectShowSessionExpiredModal);

//   const handleClose = () => {
//     dispatch(setShowSessionExpiredModal(false));
//     dispatch(setUser(null));
//     navigate("/signin");
//   };

//   return (
//     <>
//       <Modal
//         show={show}
//         onHide={handleClose}
//         backdrop="static"
//         keyboard={false}
//         size="lg"
//         centered
//       >
//         <Modal.Body style={{ textAlign: "center" }}>
//           <br/>
//           <h3 className="text-danger">
//             {"Session Expired: Please log in again."}
//           </h3>
//           <br />
//           <Button variant="secondary" onClick={handleClose}>
//             Login
//           </Button>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// }



const SessionExpiredModal = () => {
      
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const show = useAppSelector(selectShowSessionExpiredModal);

    const handleClose = () => {
        dispatch(setShowSessionExpiredModal(false));
        dispatch(setUser(null));
        navigate("/signin");
    };

    const showSessionExpiredModal = async () => {
      const result = await Swal.fire({
        icon: 'error',
        title: 'Session Expired',
        text: 'Your session has expired. Please log in again.',
        confirmButtonText: 'Login',
        showCancelButton: false,
      });

      if (result.isConfirmed) {
        // Redirect to the login page or perform the login action
        window.location.href = '/signin'; // Replace with your login page URL
      }
    };

    // Check if the session expired and show the modal
    if (show) {
      showSessionExpiredModal();
    }

  return null;
};

export default SessionExpiredModal;