import React from "react";
import * as yup from "yup";
import { Col, Form, Row } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import {
  createMockNotification,
  createNotification,
} from "../../services/NotificationServices";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";

const schema = yup.object().shape({
  userGroup: yup.string().required("ห้ามว่าง"),
  sendDatetime: yup.string().required("ห้ามว่าง"),
  notiContent: yup.string().required("ห้ามว่าง"),
});

export default function CreateEditSpecificNotificationForm({
  isCreate,
  onCancel,
  onSubmitSuccess,
  userGroupDefaultValue,
  sendDatetimeDefaultValue,
  notiContentDefaultValue,
  recordDateDefaultValue,
}) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (inputData) => {
    let noticeUser;
    if (inputData.userGroup === "all") {
      noticeUser = 0;
    } else if (inputData.userGroup === "lastActiveUser") {
      noticeUser = 1;
    }
    const { success, data, error } = await createNotification({
      body: {
        notice_user: noticeUser,
        schtime: new Date(inputData?.sendDatetime).toLocaleString("sv"),
        message: inputData?.notiContent,
      },
    });
    if (success) {
      onSubmitSuccess();
      ShowMessageAndExpire("Data created successfully.", "", "success");
    } else {
      ShowMessageAndExpire(
        "Failed to create data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
  };

  return (
    <>
      <h4>Broadcast</h4>
      <hr />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericRadioInput
              register={register}
              name="userGroup"
              defaultChecked={userGroupDefaultValue}
              error={errors.userGroup}
              radios={[
                { id: 1, label: "ทั้งหมด", value: "all" },
                {
                  id: 2,
                  label: "เฉพาะผู้ที่ใช้งานอยู่ (90 วันล่าสุด)",
                  value: "lastActiveUser",
                },
              ]}
              type="radio"
              disabled={!isCreate}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="sendDatetime"
              label="กำหนดเวลาส่ง"
              placeholder="กำหนดเวลาส่ง"
              type="dateTime"
              defaultValue={sendDatetimeDefaultValue}
              disabled={!isCreate}
              required
              error={errors.sendDatetime}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextAreaInput
              register={register}
              name="notiContent"
              label="Message"
              placeholder="Message"
              defaultValue={notiContentDefaultValue}
              required
              error={errors.notiContent}
              disabled={!isCreate}
            />
          </Form.Group>
        </Row>
        {!isCreate && (
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                name="recordDate"
                label="Record Date"
                placeholder="-"
                defaultValue={recordDateDefaultValue}
                readOnly
                plaintext
              />
            </Form.Group>
          </Row>
        )}
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          {isCreate && (
            <Col style={{ display: "flex", justifyContent: "end" }}>
              <SaveButton />
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
}
