import React from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { checkPaymentStatus, getQrCodeImg } from "../services/PaymentServices";
import { sleep } from "../utils/Help";

export default function PaymentQrCode() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [titleResultMessage, setTitleResultMessage] = React.useState("");
  const [subtitleResultMessage, setSubtitleResultMessage] = React.useState("");

  const [qrCodeImg, setQrCodeImg] = React.useState(null);

  const loadQrCode = React.useCallback(async (ref, key) => {
    const { success, data, error } = await getQrCodeImg({
      authorizationHeader: `Bearer ${key}`,
      params: {
        trn_no: ref,
      },
    });
    if (success) {
      setQrCodeImg(data?.qr_url);
    } else {
      setTitleResultMessage(`Load QR Code failed`);
      setSubtitleResultMessage(`${error?.message}`);
    }
  }, []);

  const checkStatus = async (ref, key) => {
    let paymentCheckingComplete = false;
    let sleepTimeMs = 1500;
    let countSleepTimeMs = 0;
    const limitSleepTimeMs = 300000;
    while (!paymentCheckingComplete) {
      if (countSleepTimeMs > limitSleepTimeMs) {
        paymentCheckingComplete = true;
        setTitleResultMessage(`Payment timeout`);
      } else {
        const { success, data, error } = await checkPaymentStatus({
          authorizationHeader: `Bearer ${key}`,
          params: {
            trn_no: ref,
          },
        });
        if (success) {
          if (data.status === "Payment Success") {
            paymentCheckingComplete = true;
            setTitleResultMessage(`Payment successful`);
          } else {
            await sleep(sleepTimeMs);
            countSleepTimeMs += 1500;
            continue;
          }
        } else {
          paymentCheckingComplete = true;
          setTitleResultMessage(`Check payment status failed`);
          setSubtitleResultMessage(`${error?.message}`);
        }
      }
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    const searchParams = new URLSearchParams(window.location.search);
    const tempRef = searchParams.get("ref");
    const tempKey = searchParams.get("key");
    if (tempRef && tempKey) {
      loadQrCode(tempRef, tempKey);
      setIsLoading(false);
      checkStatus(tempRef, tempKey);
    } else {
      setTitleResultMessage(`Load QR Code failed`);
      setSubtitleResultMessage(`Cannot get ref and key`);
      setIsLoading(false);
    }
  }, [loadQrCode]);

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo">
            Pet Dreams
          </Link>
        </Container>
      </div>

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner
            animation="border"
            variant="info"
            size="lg"
            className="me-3"
          />
          <br/><h3>Loading...</h3>
        </div>
      ) : (
        <>
          <div className="content">
            <Container>
              <Card className="card-auth">
                <Card.Body className="text-center">
                  {titleResultMessage ? (
                    <>
                      <Card.Title>{titleResultMessage}</Card.Title>
                      <Card.Text>{subtitleResultMessage}</Card.Text>
                    </>
                  ) : (
                    <>
                      <img
                        src={qrCodeImg}
                        alt="QR Code"
                        style={{
                          width: "500px",
                        }}
                      />
                    </>
                  )}
                </Card.Body>
              </Card>
            </Container>
          </div>
        </>
      )}
    </div>
  );
}
