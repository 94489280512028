import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import { Form, Row, Col } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import {
  createAdminUser,
  editAdminUser,
  getRoleGroup,
  getRolePermission,
} from "../../services/UserServices";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import CreateButton from "../../components/Button/CreateButton";
import { userRoleOptions, userStatusOptions } from "../../data/SelectOptions";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import {
  emailInvalidMessage,
  emailRegExp,
  passwordInvalidMessage,
  passwordRegExp,
  phoneInvalidMessage,
  phoneRegExp,
} from "../../data/Config";
import SaveButton from "../../components/Button/SaveButton";

export default function CreateEditNewUserForm({
  isCreate,
  onCancel,
  onSubmitSuccess,
  firstnameDefaultValue,
  lastnameDefaultValue,
  emailDefaultValue,
  telephoneDefaultValue,
  userRoleDefaultValue,
  userStatusDefaultValue,
  id,
  roleGroupOptionsData,
}) {
  const [yubSchema, setYubSchema] = React.useState(yup.object().shape());

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yubSchema),
  });

  const onSubmit = async (inputData) => {
    if (isCreate) {
      const { success, error } = await createAdminUser({
        body: {
          name: inputData.firstname,
          lname: inputData.lastname,
          email: inputData.email,
          phone: inputData.telephone,
          role_group_id: inputData.userRole.value,
          status: inputData.userStatus.value,
          password: inputData.password,
        },
      });
      if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data created successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Failed to create data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    } else {
      let body = {
        email: inputData.email,
        name: inputData.firstname,
        lname: inputData.lastname,
        phone: inputData.telephone,
        role_group_id: inputData.userRole.value,
        status: inputData.userStatus.value,
      };
      if (inputData.password) {
        body["password"] = inputData.password;
      }
      const { success, error } = await editAdminUser({
        pathParams: id,
        body: body,
      });
      if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data updated successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    }
  };

  const setDynamicYup = () => {
    let tempYupObj = {
      firstname: yup.string().required("ห้ามว่าง"),
      lastname: yup.string().required("ห้ามว่าง"),
      email: yup
        .string()
        .matches(emailRegExp, emailInvalidMessage)
        .required("ห้ามว่าง"),
      telephone: yup
        .string()
        .matches(phoneRegExp, phoneInvalidMessage)
        .required("ห้ามว่าง"),
      userRole: yup.object().required("ห้ามว่าง"),
      userStatus: yup.object().required("ห้ามว่าง"),
    };
    if (isCreate) {
      tempYupObj["password"] = yup
        .string()
        .matches(passwordRegExp, passwordInvalidMessage)
        .required("ห้ามว่าง");
    } else {
      tempYupObj["password"] = yup
        .string()
        .notRequired()
        .test("password", passwordInvalidMessage, (value) => {
          if (!!value) {
            const schema = yup
              .string()
              .matches(passwordRegExp, passwordInvalidMessage);
            return schema.isValidSync(value);
          }
          return true;
        });
    }
    const schema = yup.object().shape(tempYupObj);
    setYubSchema(schema);
  };

  React.useEffect(() => {
    setDynamicYup();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="firstname"
              label="ชื่อ"
              placeholder="ชื่อ"
              defaultValue={firstnameDefaultValue}
              required
              error={errors.firstname}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="lastname"
              label="นามสกุล"
              placeholder="นามสกุล"
              defaultValue={lastnameDefaultValue}
              required
              error={errors.lastname}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="email"
              label="อีเมล"
              placeholder="อีเมล"
              defaultValue={emailDefaultValue}
              required
              error={errors.email}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="telephone"
              label="เบอร์โทรศัพท์"
              placeholder="เบอร์โทรศัพท์"
              defaultValue={telephoneDefaultValue}
              required
              error={errors.telephone}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="userRole"
              label="กลุ่มผู้ใช้งาน"
              placeholder="กลุ่มผู้ใช้งาน"
              defaultValue={userRoleDefaultValue}
              options={roleGroupOptionsData}
              required
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="userStatus"
              label="สถานะ"
              placeholder="สถานะ"
              defaultValue={userStatusDefaultValue}
              options={userStatusOptions}
              required
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="password"
              label={isCreate ? "รหัสผ่าน" : "รีเซ็ตรหัสผ่าน"}
              placeholder={isCreate ? "รหัสผ่าน" : "รีเซ็ตรหัสผ่าน"}
              required={isCreate}
              error={errors.password}
              type="password"
            />
          </Form.Group>
          <Col />
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
