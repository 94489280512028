import React from "react"
import { Col, Form, Modal, Row } from "react-bootstrap"
import Searching from "../../components/Searching"
import GenericButton from "../../components/Button/GenericButton"
import ReactTable from "../../components/ReactTable"
import GenericPagination from "../../components/GenericPagination"
import ReactDateTimePicker from "../../components/Input/ReactDateTimePicker"
import { membersCredit } from "../../data/Columns"
import { getCredit } from "../../services/MemberServices"
import { PaymentType } from "../../data/Enumerator"
import GenericTextInput from "../../components/Input/GenericTextInput"
import CancelButton from "../../components/Button/CancelButton"
import { textAlignRight, textFloatFormat } from "../../utils/Help"

export default function MemberEditCreditForm({ memberId }) {
  const [total, setTotal] = React.useState(1)
  const [tableDataCredit, setTableDataCredit] = React.useState([])
  const [keyword, setKeyword] = React.useState("")
  const [pageSize, setPageSize] = React.useState(10)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [selectedDate, setSelectedDate] = React.useState()
  const columnsDataCredit = membersCredit

  const [show, setShow] = React.useState(false)
  const [detailData, setDetailData] = React.useState()

  const loadMemberCredit = React.useCallback(
    async (currentPage, pageSize, keyword, selectedDate) => {
      let filterDate
      if (selectedDate) {
        filterDate = `$ilike:${selectedDate.toLocaleDateString("sv")}`
      } else {
        filterDate = null
      }
      const { success, data, error } = await getCredit({
        params: {
          page: currentPage,
          limit: pageSize,
          search: keyword,
          "filter.create_date": filterDate,
          "filter.member_info_id": memberId,
          "filter._relations": "rel_payment_id",
          sortBy: "create_date:ASC",
        },
      })
      if (success) {
        setTotal(data.total)
        setTableDataCredit(prepareTableData(data.records, handleOpenEditForm))
      } else {
        console.log(error)
      }
    },
    []
  )

  React.useEffect(() => {
    loadMemberCredit(1, 10)
  }, [loadMemberCredit])

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page)
      await loadMemberCredit(page, pageSize, keyword, selectedDate)
    },
    [loadMemberCredit, pageSize, keyword, selectedDate]
  )

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1)
      setPageSize(pageSize)
      await loadMemberCredit(1, pageSize, keyword, selectedDate)
    },
    [loadMemberCredit, keyword, selectedDate]
  )

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1)
      setKeyword(keyword)
      await loadMemberCredit(1, pageSize, keyword, selectedDate)
    },
    [loadMemberCredit, pageSize, selectedDate]
  )

  const handleOpenEditForm = (data) => {
    setShow(true)
    // data.balance = textFloatFormat(data.balance);
    // data.value = textFloatFormat(data.value);
    setDetailData(data)
  }

  const loadPageOnDatetimeChange = React.useCallback(
    async (datetime) => {
      setCurrentPage(1)
      setSelectedDate(datetime)
      await loadMemberCredit(1, pageSize, keyword, datetime)
    },
    [loadMemberCredit, pageSize, keyword]
  )

  return (
    <>
      {/* Tab content for Credit */}
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Credit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="type"
                  label="Type"
                  placeholder="Type"
                  defaultValue={detailData?.type}
                  disabled
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="transactionData"
                  label="Transaction Data"
                  placeholder="Transaction Data"
                  defaultValue={detailData?.transactionDate}
                  disabled
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="value"
                  label="Value"
                  placeholder="Value"
                  defaultValue={textFloatFormat(detailData?.value)}
                  disabled
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="balance"
                  label="Total Balance"
                  placeholder="Total Balance"
                  defaultValue={textFloatFormat(detailData?.balance)}
                  disabled
                  readOnly
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="ref"
                  label="Ref"
                  placeholder="Ref"
                  defaultValue={detailData?.ref}
                  disabled
                  readOnly
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="createBy"
                  label="Create By"
                  placeholder="Create By"
                  defaultValue={detailData?.createBy}
                  disabled
                  readOnly
                />
              </Form.Group>
            </Row>
          </Form>
          <Row className="mb-3">
            <Col>
              <CancelButton onClick={() => setShow(false)} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Row className="mt-3">
        <Col xs={6} style={{ display: "flex" }}>
          <Searching
            placeholder="ค้นหาเลขอ้างอิง"
            onSearch={handleSearch}
            defaultValue={keyword}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <ReactDateTimePicker
            selectedDate={selectedDate}
            setSelectedDate={loadPageOnDatetimeChange}
            placeholder="Transaction Date"
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <ReactTable columns={columnsDataCredit} data={tableDataCredit} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <GenericPagination
            totalPage={Math.ceil(total / pageSize)}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={loadPageOnPageChange}
            onPageSizeChange={loadPageOnPageSizeChange}
          />
        </Col>
      </Row>
    </>
  )
}

const prepareTableData = (datas, onView) => {
  let tableDataCredit = []

  for (const data of datas) {
    let tempPaymentType
    if (data.rel_payment_id?.payment_type === PaymentType.ProductOrder) {
      tempPaymentType = "Product Order"
    } else if (data.rel_payment_id?.payment_type === PaymentType.Booking) {
      tempPaymentType = "Booking"
    } else if (
      data.rel_payment_id?.payment_type === PaymentType.CreditAppTopup
    ) {
      tempPaymentType = "Credit App Topup"
    } else {
      tempPaymentType = "-"
    }
    const tempTableData = {
      no: data.id,
      transactionDate: data.create_date,
      type: data.type,
      value: textAlignRight(data.value),
      balance: textAlignRight(data.amt_balance),
      ref: data.rel_payment_id?.trn_no || "-",
      createBy: data.create_by,
    }
    const dataTableView = {
      no: data.id,
      transactionDate: data.create_date,
      type: tempPaymentType,
      value: data.value,
      balance: data.amt_balance,
      ref: data.rel_payment_id?.trn_no || "-",
      createBy: data.create_by,
    }
    tableDataCredit.push({
      ...tempTableData,
      editDeleteButton: (
        <>
          <GenericButton
            variant="warning"
            onClick={() => {
              onView(dataTableView)
            }}
          >
            View
          </GenericButton>
        </>
      ),
    })
  }
  return tableDataCredit
}
