import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getVanInfo = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/van_info/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createVanInfo = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/van_info",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editVanInfo = async ({ pathParams, body }) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/van_info/" + pathParams,
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteVanInfo = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/van_info/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createMockEmsVan = async () => {
  return {
    success: true,
    data: {},
    error: null,
  };
};

export const editMockEmsVan = async () => {
  return {
    success: true,
    data: {},
    error: null,
  };
};

export const deleteMockEmsVan = async () => {
  return {
    success: true,
    data: {},
    error: null,
  };
};

export const getMockEmsVan = async ({ page, pageSize, keyword }) => {
  let records = [];
  let total = 104;
  if (keyword) {
    total = Math.round(total / 2);
  }
  const startId = page * pageSize - pageSize + 1;
  const endId = page * pageSize;
  for (let i = startId; i <= endId && i <= total; i++) {
    records.push({
      id: i,
      carType: "specialized",
      licensePlate: "licensePlate",
      status: "status",
    });
  }
  return {
    success: true,
    data: {
      total: total,
      currentPage: page,
      pageSize: pageSize,
      records: records,
    },
    error: null,
  };
};

export const getPhotoSetEmsVan = async ({
  params,
  pathParams = "datatable",
}) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/van_picture/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const uploadPhotoSetEmsVan = async ({ bodyFormData }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/ext/van_picture/upload_new",
      bodyFormData,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editPhotoSetEmsVanDetail = async ({ pathParams, body }) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/van_picture/" + pathParams,
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deletePhotoSetEmsVan = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/van_picture/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getHospitalInfo = async ({ params, pathParams = "datatable" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/hospital_info/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};
