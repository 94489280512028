import React from "react";
import SelectFilter from "../../components/SelectFilter";
import {
  getDoctorInfo,
  getHospitalInfo,
} from "../../services/DoctorTimeScheduleServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import {
  bookingStatusOptions,
  filterBookingStatusOptions,
} from "../../data/SelectOptions";
import ReactDateTimeRangePicker2 from "../../components/Input/ReactDateTimeRangePicker2";
import Searching from "../../components/Searching";
import { Col, Row } from "react-bootstrap";

export default function DoctorTimeScheduleFilter({
  onSearch,
  onSelectHospital,
  onSelectDoctor,
  onSelectBookingStatus,
  onSelectDateTimeRange,
  disableBookingStatusFilter,
  bookingStatusSelect,
  hideDateTimeRange,
}) {
  const [hospitalListFilter, setHospitalListFilter] = React.useState([]);
  const [hospitalListSelect, setHospitalListSelect] = React.useState([]);
  const [doctorListFilter, setDoctorListFilter] = React.useState([]);
  const [doctorListSelect, setDoctorListSelect] = React.useState([]);

  const prepareDoctorListForSelect = React.useCallback((datas) => {
    let selectData = [];
    for (const [idx, data] of datas.entries()) {
      selectData.push({
        id: idx,
        label:
          data.name || data.lname ? `${data.name} ${data.lname}` : "ไม่มีชื่อ",
        value: data.id,
      });
    }
    return selectData;
  }, []);

  const loadDoctorListSelect = React.useCallback(async () => {
    const { success, data, error } = await getDoctorInfo({
      params: { select: "id,name,lname" },
    });
    if (success) {
      setDoctorListSelect(prepareDoctorListForSelect(data.records));
    } else {
      ShowMessageAndExpire(
        "Load Doctor List Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [prepareDoctorListForSelect]);

  const prepareHospitalListForSelect = React.useCallback((datas) => {
    let selectData = [];
    for (const [idx, data] of datas.entries()) {
      selectData.push({
        id: idx,
        label: `${data.name}` || "ไม่มีชื่อ",
        value: data.id,
      });
    }
    return selectData;
  }, []);

  const loadHospitalListSelect = React.useCallback(async () => {
    const { success, data, error } = await getHospitalInfo({
      params: { select: "id,name" },
    });
    if (success) {
      setHospitalListSelect(prepareHospitalListForSelect(data.records));
    } else {
      ShowMessageAndExpire(
        "Load Doctor List Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [prepareHospitalListForSelect]);

  React.useEffect(() => {
    loadDoctorListSelect();
    loadHospitalListSelect();
  }, [loadDoctorListSelect, loadHospitalListSelect]);

  return (
    <>
      <Row className="mt-3">
        <Col xs={6} style={{ display: "flex" }}>
          <Searching
            placeholder="ค้นหา Case ID ชื่อหมอ ชื่อลูกค้า"
            onSearch={onSearch}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <SelectFilter
            placeholder={"โรงพยาบาล"}
            defaultValue={null}
            value={hospitalListFilter}
            options={hospitalListSelect}
            onChange={(value) => {
              setHospitalListFilter(value);
              onSelectHospital(value);
            }}
            isMulti
            width={20}
          />
          <SelectFilter
            placeholder={"หมอ"}
            defaultValue={null}
            value={doctorListFilter}
            options={doctorListSelect}
            onChange={(value) => {
              setDoctorListFilter(value);
              onSelectDoctor(value);
            }}
            isMulti
            width={20}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <SelectFilter
            label="Booking status"
            placeholder={"Booking status"}
            defaultValue={filterBookingStatusOptions[0]}
            value={filterBookingStatusOptions.find(
              (e) => e.value === bookingStatusSelect
            )}
            options={filterBookingStatusOptions}
            onChange={(value) => {
              // setBookingStatusSelect(value);
              onSelectBookingStatus(value);
            }}
            width={12}
            disabled={disableBookingStatusFilter}
          />
          {!hideDateTimeRange && (
            <ReactDateTimeRangePicker2 onSelected={onSelectDateTimeRange} />
          )}
        </Col>
      </Row>
    </>
  );
}
