import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import bg1 from "../assets/img/bg1.jpg";
import SigninForm from "../features/Form/SigninForm";

export default function Signin() {
  const navigate = useNavigate();
  const handleSigninSuccess = () => {
    navigate("/");
  };
  return (
    <div className="page-sign d-block py-0">
      <Row className="gx-6">
        <Col
          lg="6"
          className="d-flex flex-column align-items-center col-wrapper"
        >
          <Card className="card-sign">
            <Card.Header className="text-center">
              <Card.Title className="mb-3">Pet Dreams - Back Office</Card.Title>
              <div className="header-logo mb-5">Sign In</div>
            </Card.Header>
            <Card.Body>
              <SigninForm onSigninSuccess={handleSigninSuccess} />
            </Card.Body>
          </Card>
        </Col>
        <Col xs="6" lg="6" className="mb-5 mb-lg-0 col-wrapper">
          <img src={bg1} className="auth-img" alt="" />
        </Col>
      </Row>
    </div>
  );
}
