import React from "react";
import ReactDatePicker from "react-datepicker";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { dateFormat, dateTimeFormat } from "../../data/Config";
import ReactDateTimePicker from "./ReactDateTimePicker";

export default function GenericDateTimePickerInput2({
  control,
  name,
  label,
  type = "date",
  disabled,
  defaultValue,
  required,
  error,
  selectedDate,
  setSelectedDate,
  startYear = 1980,
  endYear,
  placeholder,
  readOnly,
  onChange,
}) {
  // const ExampleCustomInput = React.forwardRef(
  //   ({ value, onClick, defaultValue }, ref) => (
  //     <>
  //       <Form.Label>
  //         {label}
  //         <p style={{ color: "red", display: "inline" }}>{required && "*"}</p>
  //       </Form.Label>
  //       <Form.Control
  //         onClick={onClick}
  //         type="text"
  //         ref={ref}
  //         value={value}
  //         defaultValue={value}
  //         readOnly
  //         placeholder={placeholder}
  //         disabled={disabled}
  //         isInvalid={error}
  //         className="GenericDateTimePickerInput"
  //       />
  //       <Form.Control.Feedback type="invalid">
  //         {error?.message}
  //       </Form.Control.Feedback>
  //     </>
  //   )
  // );
  return (
    <div>
      <Form.Label>
        {label}
        <p style={{ color: "red", display: "inline" }}>{required && "*"}</p>
      </Form.Label>
      {control ? (
        <>
          <Controller
            name={name}
            control={control}
            rules={{ required: required }}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => (
              <>
                <ReactDateTimePicker
                  selectedDate={field.value}
                  setSelectedDate={(value) => {
                    field.onChange(value);
                    if (onChange) {
                      onChange(value);
                    }
                  }}
                  type={type}
                  startYear={startYear}
                  endYear={endYear}
                  placeholder={placeholder}
                  disabled={disabled}
                  error={error}
                  readOnly={readOnly}
                />
              </>
            )}
          />
        </>
      ) : (
        <ReactDateTimePicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          type={type}
          startYear={startYear}
          endYear={endYear}
          placeholder={placeholder}
          disabled={disabled}
          error={error}
          readOnly={readOnly}
        />
      )}
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </div>
  );
}
