import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CancelButton from "../../components/Button/CancelButton";
import GenericDateTimePickerInput from "../../components/Input/GenericDateTimePickerInput";
import GenericTextInput from "../../components/Input/GenericTextInput";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import { getCommonAPI } from "../../services/commonAPIServices";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import { VoucherRadio } from "../../data/SelectOptions";
import { editMockUserDoctor } from "../../services/DoctorServices";
import SaveButton from "../../components/Button/SaveButton";

const schema = yup.object().shape({
    lot_id: yup.string().required("Lot ID ห้ามว่าง"),
    exp_date: yup.date().required("วันหมดอายุห้ามว่าง"),
    qty: yup.number().required("จำนวนห้ามว่าง"),
    each_amt: yup.number().required("มูลค่าห้ามว่าง"),
    remark: yup.string().required("รายละเอียดห้ามว่าง"),
    for_new_register: yup.number(),
});

export default function VoucherLotForm({ onCancel, id , onSubmitSuccess  }) {
    const {
      register,
      control,
      DiscountCodeDefaultValue,
      isCreate,
      setValue,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });

    const loadVoucherlotDetail = React.useCallback(async () => {

        let url_api = "/voucher_lot/" + id;
        let p_data = {};
        let { success, data, error } = await getCommonAPI({
          url_api,
          p_data,
        });
        if (success) {
          
          setValue("lot_id", data.records?.lot_id);
          setValue("create_date", new Date(data.records?.create_date));
          setValue("exp_date", new Date(data.records?.exp_date));
          setValue("qty", data.records?.qty);
          setValue("each_amt", data.records?.each_amt);
          setValue("remark", data.records?.remark);
          setValue("for_new_register", data.records?.for_new_register);
          setValue("usefor", data.records?.usefor==1 ? "ปรึกษาหมอ" : data.records?.usefor==2 ? "ซื้อของ" : "");
          setValue("minimum", data.records?.minimum);
    
        } else {
          ShowMessageAndExpire("Failed to load data.", error?.message, "error");
        }
      }, );
    
      React.useEffect(() => {
        loadVoucherlotDetail();
      }, [loadVoucherlotDetail]);
    
    
      const onSubmit = async (buttonData) => {
        console.log(buttonData);
        const { success, data, error } = await editMockUserDoctor({
          id: id,
        });
        if (success) {
          onSubmitSuccess();
          ShowMessageAndExpire("Data updated successfully.", "", "success");
        } else {
          ShowMessageAndExpire(
            "Failed to update data.",
            JSON.stringify(error?.message),
            "error"
          );
        }
      };
  

  return (
    <>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="lot_id"
              label="Lot id"
              placeholder="Lot id"
              defaultValue=""
              //required
              error={errors.lot_id}
              disabled={!isCreate}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="create_date"
              label="Create date"
              type="dateTime"
              placeholder="Create date"
              disabled={!isCreate}
              //defaultValue=""
              //required
              //error={errors.create_date}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="exp_date"
              label="Lot Expire"
              type="dateTime"
              error={errors.qty}
              defaultValue=""
              required
              disabled={!isCreate}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="qty"
              label="Quantity"
              placeholder="Quantity"
              defaultValue=""
              required
              error={errors.qty}
              disabled={!isCreate}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="each_amt"
              label="Value Each"
              placeholder="Value Each"
              defaultValue=""
              required
              error={errors.each_amt}
              disabled={!isCreate}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="usefor"
              label="Use For"
              placeholder="ใช้สำหรับ"
              defaultValue=""
              required
              error={errors.qty}
              disabled={!isCreate}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="minimum"
              label="Minimum"
              placeholder="Minimum"
              defaultValue=""
              required
              error={errors.each_amt}
              disabled={!isCreate}
            />
          </Form.Group>
        </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="remark"
              label="Admin Remark"
              placeholder="รายละเอียด"
              as="textarea"
              defaultValue=""
              required
              disabled={!isCreate}
            />
        </Form.Group> 
        <Form.Group as={Col} controlId="formBasicName">
          <GenericRadioInput
            register={register}
            name="for_new_register"
            label="For New Register"
            defaultValue={DiscountCodeDefaultValue}
            error={errors.for_new_register}
            radios={VoucherRadio}
            type="switch"
            viewFormat="inline"
            disabled={!isCreate}
          />
        </Form.Group>
      </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
        </Row>
      </Form>
    </>
  );
}
