import React from "react";
import { membersPetsInfo, userHospitalInfo } from "../data/Columns";
import { Card, Col, Container, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import { deleteMockUserDoctor } from "../services/DoctorServices";
import { getMockPetsInfo } from "../services/PetServices";
import CreateNewPetForm from "../features/Form/CreateNewPetForm";
import PetDetailEditForm from "../features/Form/PetDetailEditForm";
import { getMockHospitalInfo } from "../services/HospitalServices";
import HospitalDetailEditForm from "../features/Form/HospitalDetailEditForm";
import SelectFilter from "../components/SelectFilter";
import { filterUserStatusOptions, userStatusOptionsAll } from "../data/SelectOptions";
import { deleteCommonAPI, getCommonAPI } from "../services/commonAPIServices";
import ReactDateTimeRangePicker from "../components/Input/ReactDateTimeRangePicker";
import CreateEditHospitalForm from "../features/Form/CreateEditHospitalForm";
import setPlaceholder from "../utils/Help";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";

const allUserStatus = "all";

export default function HospitalInfo() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [tableData, setTableData] = React.useState([]);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const [dateStart, setDateStart] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();
  const columnsData = userHospitalInfo;

  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isOpenCraeateForm, setIsOpenCraeateForm] = React.useState(false);

  const loadHospitalInfo = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, dateStart, dateEnd) => {
      let datefilter;
      if (dateStart && dateEnd) {
        datefilter = "$btw:" + dateStart + " 00:00:00," + dateEnd + " 23:59:59";
      }
      if (userStatus === "all") {
        userStatus = null;
      }
      const page = currentPage;
      const limit = pageSize;
      const search = keyword;
      const url_api = "/hospital_info/datatable";
      const p_data = {
        page,
        limit,
        search,
        "filter.update_date": datefilter,
        "filter.status": userStatus,
      };
      const { success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      });
      if (success) {
        //console.log(data.records);
        setTotal(data.total);
        setTableData(
          editButton(
            prepareTableData(
              data.records,
              (currentPage - 1) * pageSize + 1,
              handleOpenEditForm,
              handleOpenDeleteConfirm
            )
          )
        );
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  const loadTimeRangePicker = React.useCallback(
    async (sDate, eDate) => {
      setDateStart(sDate);
      setDateEnd(eDate);
      setCurrentPage(1);
      await loadHospitalInfo(1, pageSize, userStatus, keyword, sDate, eDate);
    },
    [loadHospitalInfo, pageSize, userStatus, keyword]
  );

  React.useEffect(() => {
    loadHospitalInfo(1, 10, allUserStatus);
  }, [loadHospitalInfo]);

  const editButton = (data) => {
    for (var i = 0; i < data.length; i++) {
      switch (data[i].status) {
        // status
        case 0:
          data[i].status = <>InActive</>;
          break;
        case 1:
          data[i].status = <>Active</>;
          break;
        case 2:
          data[i].status = <>Waiting Verify</>;
          break;
        case 3:
          data[i].status = <>Expired</>;
          break;
      }
    }
    return data;
  };

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadHospitalInfo(page, pageSize, userStatus, keyword);
    },
    [loadHospitalInfo, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadHospitalInfo(1, pageSize, userStatus, keyword);
    },
    [loadHospitalInfo, userStatus, keyword]
  );

  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadHospitalInfo(1, pageSize, userStatus, keyword);
    },
    [loadHospitalInfo, pageSize, keyword]
  );

  const openCreateForm = () => {
    setIsOpenCraeateForm(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setCurrentPage(1);
    setKeyword("");
    setUserStatus(allUserStatus);
    loadHospitalInfo(1, pageSize, allUserStatus, "");
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadHospitalInfo(1, pageSize, userStatus, keyword);
    },
    [loadHospitalInfo, pageSize, userStatus]
  );

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleEdit = React.useCallback(async () => {
    handleCloseEdit();
    loadHospitalInfo(currentPage, pageSize, userStatus, keyword);
  }, [loadHospitalInfo, currentPage, pageSize, userStatus, keyword]);

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setEditData(data);
  };

  const handleCloseEdit = () => {
    setIsOpenForm(false);
    setIsOpenCraeateForm(false);
    setEditData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const url_api = "/hospital_info/" + id;
      const { success, data, error } = await deleteCommonAPI({
        url_api,
      });
      if (success) {
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadHospitalInfo(tempCurrentPage, pageSize, userStatus, keyword);
      } else {
        console.log(error);
      }
    },
    [loadHospitalInfo, total, currentPage, pageSize, userStatus, keyword]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              editData
                ? `Hospital Info : ${editData?.name}`
                : "Hospital Info Management"
            }
            buttonEnable={!isOpenForm && !isOpenCraeateForm}
            buttonIcon="plus"
            buttonMessage="New Hospital"
            onButtonOnClick={openCreateForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`Hospital ID = ${deleteData?.id}, ชื่อ = ${deleteData?.name}`}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenCraeateForm ? (
                  <>
                    <CreateEditHospitalForm
                      onCancel={handleCloseEdit}
                      onCreateSuccess={handleEdit}
                    />
                  </>
                ) : isOpenForm ? (
                  <>
                    <HospitalDetailEditForm
                      onCancel={handleCloseEdit}
                      onSubmitSuccess={handleEdit}
                      editData={editData}
                    />
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder={setPlaceholder(["ชื่อโรงพยาบาล"])}
                          onSearch={handleSearch}
                          defaultValue={keyword}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="สถานะ"
                          placeholder={"สถานะ"}
                          defaultValue={userStatusOptionsAll.find(
                            (e) => e.value == allUserStatus
                          )}
                          value={userStatusOptionsAll.find(
                            (e) => e.value == userStatus
                          )}
                          options={userStatusOptionsAll}
                          onChange={loadPageOnUserStatusChange}
                        />
                        <ReactDateTimeRangePicker2
                          onSelected={loadTimeRangePicker}
                          defaultStart={dateStart ? new Date(dateStart) : null}
                          defaultEnd={dateEnd ? new Date(dateEnd) : null}
                          placeholder="วันที่ปรับปรุง"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = [];
  for (const data of datas) {
    tableData.push({
      no: startNo + tableData.length,
      hospitalName: data.name,
      subdistrict: data?.subdistrict_name,
      // subdistrict: "-",
      update_date: data?.update_date,
      status: data.status,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(data);
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(data);
            }}
          />
        </>
      ),
    });
  }
  // console.log(tableData);
  return tableData;
};
