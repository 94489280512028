import React from "react";
import { NavLink } from "react-router-dom";
import { homeMenu, reportsMenu, settingsMenu } from "../data/Menu";
import { selectUser } from "../global_store/features/AuthenSlice";
import { useAppSelector } from "../global_store/hooks";

export default function SidebarPermission(props) {
  const user = useAppSelector(selectUser);

  const populateMenu = (m, permissions = []) => {
    const menu = m.map((m, key) => {
      for (const permission of permissions) {
        if (permission?.label === m.label) {
          let sm;
          if (m.submenu && permission.submenu) {
            sm = permission.submenu.map((submenu_permission, idx) => {
              for (const sm of m.submenu) {
                if (submenu_permission.label === sm.label) {
                  return (
                    <NavLink to={sm.link} className="nav-sub-link" key={key}>
                      {sm.label}
                    </NavLink>
                  );
                }
              }
            });
          }

          return (
            <li key={key} className="nav-item">
              {!sm ? (
                <NavLink to={m.link} className="nav-link">
                  <i className={m.icon}></i> <span>{m.label}</span>
                </NavLink>
              ) : (
                <div onClick={toggleSubMenu} className="nav-link has-sub">
                  <i className={m.icon}></i> <span>{m.label}</span>
                </div>
              )}
              {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
            </li>
          );
        }
      }
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };

  // Toggle menu group
  const toggleMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-group");
    parent.classList.toggle("show");

    props.onUpdateSize();
  };

  // Toggle submenu while closing siblings' submenu
  const toggleSubMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-item");
    let node = parent.parentNode.firstChild;

    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("show");
      node = node.nextElementSibling || node.nextSibling;
    }

    parent.classList.toggle("show");

    props.onUpdateSize();
  };

  return (
    <React.Fragment>
      {(typeof user?.menuViewPermission?.menu_home_json !== "undefined" &&
        user?.menuViewPermission?.menu_home_json.length && (
          <div className="nav-group show">
            <div className="nav-label" onClick={toggleMenu}>
              Home
            </div>
            {populateMenu(
              homeMenu,
              user?.menuViewPermission?.menu_home_json || []
            )}
          </div>
        )) || <></>}
      {(typeof user?.menuViewPermission?.menu_setting_json !== "undefined" &&
        user?.menuViewPermission?.menu_setting_json.length && (
          <div className="nav-group show">
            <div className="nav-label" onClick={toggleMenu}>
              Setting
            </div>
            {populateMenu(
              settingsMenu,
              user?.menuViewPermission?.menu_setting_json || []
            )}
          </div>
        )) || <></>}

      {(typeof user?.menuViewPermission?.menu_report_json !== "undefined" &&
        user?.menuViewPermission?.menu_report_json.length && (
          <div className="nav-group show">
            <div className="nav-label" onClick={toggleMenu}>
              Report
            </div>
            {populateMenu(
              reportsMenu,
              user?.menuViewPermission?.menu_report_json
            ) || []}
          </div>
        )) || <></>}
    </React.Fragment>
  );
}
