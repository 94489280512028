import React from "react";
import * as yup from "yup";
import { passwordInvalidMessage, passwordRegExp } from "../../data/Config";
import { resetPassword } from "../../services/AuthServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import GenericButton from "../../components/Button/GenericButton";

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .matches(passwordRegExp, passwordInvalidMessage)
    .required("ห้ามว่าง"),
  confirmNewPassword: yup
    .string()
    .required("ห้ามว่าง")
    .oneOf([yup.ref("newPassword"), null], "รหัสผ่านไม่ตรงกัน"),
});

export default function ResetPasswordForm({
  onSubmitSuccess = () => {},
  token,
  email,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async (inputData) => {
    setIsLoading(true);
    const { success, error } = await resetPassword({
      body: {
        password: inputData.newPassword,
        confirm_password: inputData.confirmNewPassword,
        email: email,
        token: token,
      },
    });
    if (success) {
      ShowMessageAndExpire("Success", "Reset Password success", "success");
      onSubmitSuccess();
    } else {
      ShowMessageAndExpire(
        "Reset Password Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
    setIsLoading(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="newPassword"
              label="รหัสผ่านใหม่"
              placeholder="รหัสผ่านใหม่"
              defaultValue=""
              error={errors.newPassword}
              type="password"
              disabled={isLoading}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="confirmNewPassword"
              label="ยืนยันรหัสผ่านใหม่"
              placeholder="ยืนยันรหัสผ่านใหม่"
              defaultValue=""
              error={errors.confirmNewPassword}
              type="password"
              disabled={isLoading}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <GenericButton type="submit" disabled={isLoading}>
              {"Submit"}
              {isLoading && (
                <>
                  &nbsp; &nbsp;
                  <Spinner
                    animation="border"
                    variant="info"
                    size="sm"
                    className="me-3"
                  />
                </>
              )}
            </GenericButton>
          </Col>
        </Row>
      </Form>
    </>
  );
}
