import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./layouts/Main";
import NotFound from "./pages/NotFound";

import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
import {
  selectBodyMessage,
  selectMessageType,
  selectShowMessageModal,
  selectTitleMessage,
  setBodyMessage,
  setMessageType,
  setShowMessageModal,
  setTitleMessage,
} from "./global_store/features/MessageSlice";
import { useAppDispatch, useAppSelector } from "./global_store/hooks";
import MessageModal from "./components/Modal/MessageModal";

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  const dispatch = useAppDispatch();
  const showMessageModal = useAppSelector(selectShowMessageModal);
  const titleMessage = useAppSelector(selectTitleMessage);
  const bodyMessage = useAppSelector(selectBodyMessage);
  const messageType = useAppSelector(selectMessageType);

  const handleCloseMessageModal = () => {
    dispatch(setShowMessageModal(false));
    dispatch(setTitleMessage(""));
    dispatch(setBodyMessage(""));
    dispatch(setMessageType(""));
  };

  return (
  
    <React.Fragment>
      <MessageModal
        show={showMessageModal}
        onClose={handleCloseMessageModal}
        titleMessage={titleMessage}
        bodyMessage={bodyMessage}
        type={messageType}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            {protectedRoutes.map((route, index) => {
              return (
                <Route path={route.path} element={route.element} key={index} />
              );
            })}
          </Route>
          {publicRoutes.map((route, index) => {
            return (
              <Route path={route.path} element={route.element} key={index} />
            );
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}
