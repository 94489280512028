import React from "react";
import * as yup from "yup";
import { Col, Form, Row } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { editBillingInfo, uploadImage } from "../../services/SettingServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import {
  getDistrictInfo,
  getProvinceInfo,
  getSubDistrictInfo,
} from "../../services/OnlineBookingServices";

const schema = yup.object().shape({
  companyName: yup.string().required("ห้ามว่าง"),
  address: yup.string().required("ห้ามว่าง"),
  street: yup.string().required("ห้ามว่าง"),
  subDistrict: yup.object().required("ห้ามว่าง"),
  district: yup.object().required("ห้ามว่าง"),
  province: yup.object().required("ห้ามว่าง"),
  postcode: yup.object().required("ห้ามว่าง"),
  companyId: yup.string().required("ห้ามว่าง"),
});

export default function EditBillingForm({
  onCancel,
  onSubmitSuccess,
  companyNameDefaultValue,
  addressDefaultValue,
  streetDefaultValue,
  subDistrictDefaultValue,
  districtDefaultValue,
  provinceDefaultValue,
  postcodeDefaultValue,
  companyIdDefaultValue,
  companyLogoDefaultValue,
  id,
}) {
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [provinceSelect, setProvinceSelect] = React.useState([]);
  const [districtSelect, setDistrictSelect] = React.useState([]);
  const [subDistrictSelect, setSubDistrictSelect] = React.useState([]);
  const [postcodeSelect, setPostcodeSelect] = React.useState([]);

  const [previewImage, setPreviewImage] = React.useState(
    companyLogoDefaultValue
  );

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const prepareProvinceListForSelect = React.useCallback(
    (datas) => {
      let listsProvince = [];
      for (const [idx, data] of datas.entries()) {
        let tempProvince = {
          id: idx,
          label: data.name,
          value: data.id,
        };
        listsProvince.push(tempProvince);
        if (firstLoad && data.id === provinceDefaultValue) {
          setValue("province", tempProvince, { shouldValidate: true });
        }
      }
      return listsProvince;
    },
    [firstLoad, provinceDefaultValue, setValue]
  );

  const prepareDistrictListForSelect = React.useCallback(
    (datas) => {
      let listsDistrict = [];
      for (const [idx, data] of datas.entries()) {
        let tempDistrict = {
          id: idx,
          label: data.name,
          value: data.id,
        };
        listsDistrict.push(tempDistrict);
        if (firstLoad && data.id === districtDefaultValue) {
          setValue("district", tempDistrict, { shouldValidate: true });
        }
      }
      return listsDistrict;
    },
    [firstLoad, districtDefaultValue, setValue]
  );

  const prepareSubDistrictListForSelect = React.useCallback(
    (datas) => {
      let listsSubDistrict = [];
      for (const [idx, data] of datas.entries()) {
        let tempSubDistrict = {
          id: idx,
          label: data.name,
          value: { id: data.id, postcode: data.postcode },
        };
        listsSubDistrict.push(tempSubDistrict);
        if (firstLoad && data.id === subDistrictDefaultValue) {
          setValue("subDistrict", tempSubDistrict, { shouldValidate: true });
          setValue(
            "postcode",
            {
              id: 1,
              label: data.postcode,
              value: data.postcode,
            },
            { shouldValidate: true }
          );
          setPostcodeSelect([
            {
              id: 1,
              label: data.postcode,
              value: data.postcode,
            },
          ]);
        }
      }
      return listsSubDistrict;
    },
    [firstLoad, subDistrictDefaultValue, setValue]
  );

  const loadProvince = React.useCallback(async () => {
    const { success, data, error } = await getProvinceInfo();
    if (success) {
      setProvinceSelect(prepareProvinceListForSelect(data.records));
    } else {
      ShowMessageAndExpire(
        "Load Province Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [prepareProvinceListForSelect]);

  const loadDistrictSelect = React.useCallback(
    async (provinceId) => {
      const { success, data, error } = await getDistrictInfo("", {
        "filter.m_province_id": provinceId,
      });
      if (success) {
        setDistrictSelect(prepareDistrictListForSelect(data.records));
      } else {
        ShowMessageAndExpire(
          "Load District Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [prepareDistrictListForSelect]
  );

  const loadSubDistrictSelect = React.useCallback(
    async (districtId) => {
      const { success, data, error } = await getSubDistrictInfo("", {
        "filter.m_district_id": districtId,
      });
      if (success) {
        setSubDistrictSelect(prepareSubDistrictListForSelect(data.records));
      } else {
        ShowMessageAndExpire(
          "Load Sub-District Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [prepareSubDistrictListForSelect]
  );

  React.useEffect(() => {
    setPreviewImage(companyLogoDefaultValue);
    loadProvince();
    loadDistrictSelect(provinceDefaultValue);
    loadSubDistrictSelect(districtDefaultValue);
    setFirstLoad(false);
  }, [
    companyLogoDefaultValue,
    provinceDefaultValue,
    districtDefaultValue,
    loadProvince,
    loadDistrictSelect,
    loadSubDistrictSelect,
  ]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = async (inputData) => {
    let success;
    let error;
    const companyLogoFile = inputData.companyLogo[0];
    ({ success, error } = await editBillingInfo({
      pathParams: 1,
      body: {
        name: inputData.companyName,
        addressno: inputData.address,
        street: inputData.street,
        m_subdistrict_id: inputData.subDistrict?.value?.id,
        tax_id: inputData.companyId,
      },
    }));
    if (success && companyLogoFile) {
      let bodyFormData = new FormData();
      bodyFormData.append("image", companyLogoFile, companyLogoFile?.name);
      bodyFormData.append("res_model", "m_billing");
      bodyFormData.append("res_id", id);
      bodyFormData.append("res_field", "logo");
      ({ success, error } = await uploadImage({
        bodyFormData: bodyFormData,
      }));
      if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data updated successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Update Logo Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    } else if (success) {
      onSubmitSuccess();
      ShowMessageAndExpire("Data updated successfully.", "", "success");
    } else {
      ShowMessageAndExpire(
        "Failed to update data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4>การตั้งค่าการเรียกเก็บเงิน และวิธีชำระเงิน</h4>
        <hr />
        <Form.Group as={Row} className="mb-3" controlId="formBasicName">
          <GenericTextInput
            register={register}
            name="companyName"
            label="ชื่อบริษัทที่ใช้ออกเอกสารรับชำระเงิน"
            placeholder="ชื่อบริษัทที่ใช้ออกเอกสารรับชำระเงิน"
            defaultValue={companyNameDefaultValue}
            required
            error={errors.companyName}
            viewFormat="inline"
          />
        </Form.Group>
        <Row className="mb-3">
          <Col sm={2}>ที่อยู่บริษัทที่ใช้ออกเอกสารรับชำระเงิน</Col>
          <Col sm={10}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="address"
                  label="Address"
                  placeholder="Address"
                  defaultValue={addressDefaultValue}
                  required
                  error={errors.address}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="street"
                  label="Street"
                  placeholder="Street"
                  defaultValue={streetDefaultValue}
                  required
                  error={errors.street}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="province"
                  label="Province"
                  placeholder="Province"
                  defaultValue={null}
                  required
                  options={provinceSelect}
                  onChange={(e) => {
                    if (e?.value) {
                      loadDistrictSelect(e.value);
                      setValue("postcode", null);
                    } else {
                      setDistrictSelect([]);
                      setValue("district", null);
                      setSubDistrictSelect([]);
                      setValue("subDistrict", null);
                      setPostcodeSelect([]);
                      setValue("postcode", null);
                    }
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="district"
                  label="District"
                  placeholder="District"
                  defaultValue={null}
                  required
                  disabled={districtSelect.length === 0}
                  options={districtSelect}
                  onChange={(e) => {
                    if (e?.value) {
                      loadSubDistrictSelect(e.value);
                      setPostcodeSelect([]);
                      setValue("postcode", null);
                    } else {
                      setSubDistrictSelect([]);
                      setValue("subDistrict", null);
                      setPostcodeSelect([]);
                      setValue("postcode", null);
                    }
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="subDistrict"
                  label="Sub-District"
                  placeholder="Sub-District"
                  defaultValue={null}
                  required
                  disabled={subDistrictSelect.length === 0}
                  options={subDistrictSelect}
                  onChange={(e) => {
                    if (e?.value) {
                      setValue(
                        "postcode",
                        {
                          id: 1,
                          label: e.value?.postcode,
                          value: e.value?.postcode,
                        },
                        { shouldValidate: true }
                      );
                      setPostcodeSelect([
                        {
                          id: 1,
                          label: e.value?.postcode,
                          value: e.value?.postcode,
                        },
                      ]);
                    } else {
                      setPostcodeSelect([]);
                      setValue("postcode", null);
                    }
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="postcode"
                  label="Postcode"
                  placeholder="Postcode"
                  required
                  disabled={postcodeSelect.length === 0}
                  options={postcodeSelect}
                />
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="companyId"
              label="เลขประจำตัวผู้เสียภาษีของบริษัทที่ใช้ออกเอกสารรับชำระเงิน"
              placeholder="เลขประจำตัวผู้เสียภาษีของบริษัทที่ใช้ออกเอกสารรับชำระเงิน"
              defaultValue={companyIdDefaultValue}
              required
              error={errors.companyId}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="companyLogo"
              label="รูปภาพ Logo"
              placeholder="รูปภาพ Logo"
              defaultValue={null}
              error={errors.companyLogo}
              type="file"
              onChange={handleImageChange}
            />
            {previewImage && (
              <img
                src={previewImage}
                alt="Preview"
                style={{ width: "300px" }}
              />
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
