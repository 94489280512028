import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Searching from "../../components/Searching";
import GenericButton from "../../components/Button/GenericButton";
import ReactTable from "../../components/ReactTable";
import EditButton from "../../components/Button/EditButton";
import DeleteButton from "../../components/Button/DeleteButton";
import GenericPagination from "../../components/GenericPagination";
import { filterUserStatusOptions } from "../../data/SelectOptions";
import SelectFilter from "../../components/SelectFilter";
import ReactDateTimePicker from "../../components/Input/ReactDateTimePicker";
import { membersPets, membersPetsInfo } from "../../data/Columns";
import { getMockPetsInfo, getPetsInfo } from "../../services/MemberServices";
import {
  deleteCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices";
import ReactDateTimeRangePicker2 from "../../components/Input/ReactDateTimeRangePicker2";
import setPlaceholder from "../../utils/Help";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";
import AddPetsInfoSetModal from "../Modal/AddPetsInfoSetModal";
import EditPetsInfoSetModal from "../Modal/EditPetsInfoSetModal";

const allUserStatus = "all";
const schema = yup.object().shape({
  firstname: yup.string().required("ชื่อห้ามว่าง"),
  lastname: yup.string().required("นามสกุลห้ามว่าง"),
  email: yup.string().email("อีเมลไม่ถูกต้อง").required("อีเมลห้ามว่าง"),
  telephone: yup.string().required("เบอร์โทรศัพท์ห้ามว่าง"),
});

export default function MemberEditPetsInfoForm({ memberId, editData }) {
  const [total, setTotal] = React.useState(1);
  const [tableDataPets, setTableDataPets] = React.useState([]);
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [editDataPets, setEditDataPets] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const [keyword, setKeyword] = React.useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedDate, setSelectedDate] = React.useState();
  const [isOpenCraeateForm, setIsOpenCraeateForm] = React.useState(false);
  const [editDataModal, setEditDataModal] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalEdit, setShowModalEdit] = React.useState(false);
  const columnsDataPetsInfo = membersPetsInfo;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loadMemberPetsInfo = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, selectedDate) => {
      let filterDate;
      if (selectedDate) {
        filterDate = `$ilike:${selectedDate.toLocaleDateString("sv")}`;
      } else {
        filterDate = null;
      }
      const page = currentPage;
      const limit = pageSize;
      const search = keyword;
      const url_api = "/pet_info/datatable";
      const p_data = {
        "filter.member_info_id": memberId,
        "filter._relations": "rel_m_pet_type_id",
        "filter.create_date": filterDate,
        page,
        search,
        limit,
      };
      const { success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      });
      if (success) {
        setTotal(data.total);
        setTableDataPets(
          prepareTableData(
            data.records,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        console.log(error);
      }
    },
    []
  );

  React.useEffect(() => {
    loadMemberPetsInfo(1, 10, allUserStatus);
  }, [loadMemberPetsInfo]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadMemberPetsInfo(page, pageSize, userStatus, keyword);
    },
    [loadMemberPetsInfo, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadMemberPetsInfo(1, pageSize, userStatus, keyword);
    },
    [loadMemberPetsInfo, userStatus, keyword]
  );

  const handleOpenEditForm = (data) => {
    setShowModalEdit(true);
    setEditDataModal(data);
  };

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadMemberPetsInfo(1, pageSize, userStatus, keyword);
    },
    [loadMemberPetsInfo, pageSize, userStatus]
  );

  const loadPageOnDatetimeChange = React.useCallback(
    async (datetime) => {
      setCurrentPage(1);
      setSelectedDate(datetime);
      await loadMemberPetsInfo(1, pageSize, userStatus, keyword, datetime);
    },
    [loadMemberPetsInfo, pageSize, keyword]
  );

  const handleAddModalSet = React.useCallback(() => {
    setShowModal(false);
    setShowModalEdit(false);
    loadMemberPetsInfo(1, 10);
  }, [loadMemberPetsInfo]);

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const url_api = "/pet_info/" + id;
      const { success, data, error } = await deleteCommonAPI({
        url_api,
      });
      if (success) {
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadMemberPetsInfo(tempCurrentPage, pageSize, userStatus, keyword);
      } else {
        console.log(error);
      }
    },
    [loadMemberPetsInfo, total, currentPage, pageSize, userStatus, keyword]
  );

  return (
    <>
      {showModal && (
        <AddPetsInfoSetModal
          show={showModal}
          setShow={() => {
            setShowModal();
          }}
          onSave={handleAddModalSet}
          editData={editData}
        />
      )}
      {showModalEdit && (
        <EditPetsInfoSetModal
          show={showModalEdit}
          setShow={() => {
            setShowModalEdit();
          }}
          onSave={handleAddModalSet}
          editData={editDataModal}
        />
      )}
      <DeleteConfirmModal
        show={isDelete}
        onClose={handleCloseDeleteConfirm}
        onComfirm={handleDelete}
        deleteRecord={deleteData?.id}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these record?"}</p>
            <p>
              {`PetsInfo ID = ${deleteData?.id}, ชื่อ = ${deleteData?.name}`}
            </p>
          </>
        }
      />
      {/* Tab content for Pets */}
      <Row className="mt-3">
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="success"
            onClick={() => setShowModal(true)}
            className="ms-3"
          >
            {" "}
            New Pet
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={6} style={{ display: "flex" }}>
          <Searching
            placeholder={setPlaceholder([
              "ชื่อสัตว์เลี้ยง",
              "ประเภทสัตว์เลี้ยง",
            ])}
            onSearch={handleSearch}
            defaultValue={keyword}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <ReactDateTimePicker
            selectedDate={selectedDate}
            setSelectedDate={loadPageOnDatetimeChange}
            placeholder="Register Date"
          />
        </Col>
      </Row>
      <Container fluid style={{ minHeight: "30rem" }}>
        <Row className="mt-3">
          <Col>
            <ReactTable columns={columnsDataPetsInfo} data={tableDataPets} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <GenericPagination
              totalPage={Math.ceil(total / pageSize)}
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={loadPageOnPageChange}
              onPageSizeChange={loadPageOnPageSizeChange}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

const prepareTableData = (datas, onEdit, onDelete) => {
  let tableDataPets = [];
  for (const data of datas) {
    tableDataPets.push({
      no: data.id,
      pet_name: data.name,
      owner_name: data.ownname,
      owner_phone: data.phone,
      pet_category: data.rel_m_pet_type_id?.name,
      date_of_birth: data.brithday,
      last_service_date: data.last_service,
      register_date: data.create_date,
      remark: data.remark,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(data);
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(data);
            }}
          />
        </>
      ),
    });
  }
  return tableDataPets;
};
