import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { Form, Row, Col } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import {
  approveLeaveInfo,
  notApproveLeaveInfo,
} from "../../services/LeaveManagement";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import { DoctorLeaveApproveStatus } from "../../data/Enumerator";
import { DoctorLeaveApproveOptions } from "../../data/SelectOptions";

const schema = yup.object().shape({
  approveStatus: yup.string().required("ห้ามว่าง"),
});

export default function LeaveForm({
  onCancel,
  onSubmitSuccess,
  approveStatusDefaultValue,
  doctorNameDefaultValue,
  leaveDateDefaultValue,
  remarkDefaultValue,
  id,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (inputData) => {
    let success;
    let error;
    if (+inputData.approveStatus === DoctorLeaveApproveStatus.Approve) {
      ({ success, error } = await approveLeaveInfo({
        pathParams: id,
      }));
    } else if (
      +inputData.approveStatus === DoctorLeaveApproveStatus.NotApprove
    ) {
      ({ success, error } = await notApproveLeaveInfo({
        pathParams: id,
      }));
    }
    if (success) {
      onSubmitSuccess();
      ShowMessageAndExpire("Data updated successfully.", "", "success");
    } else if (error) {
      ShowMessageAndExpire(
        "Failed to update data.",
        JSON.stringify(error?.message),
        "error"
      );
    } else {
      ShowMessageAndExpire("Failed to update data.", "No error message", "error");
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <h4>ข้อมูลการลา</h4>
          <hr />
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              name="doctorName"
              label="ชื่อ-นามสกุลหมอ"
              placeholder="ชื่อ-นามสกุลหมอ"
              defaultValue={doctorNameDefaultValue}
              disabled
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              name="leaveDate"
              label="วันที่ลา"
              placeholder="วันที่ลา"
              defaultValue={leaveDateDefaultValue}
              disabled
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <h4>หมายเหตุ</h4>
          <hr />
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextAreaInput
              name="remark"
              label="Remark"
              placeholder="Remark"
              defaultValue={remarkDefaultValue}
              disabled
            />
          </Form.Group>
          <Col />
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericRadioInput
              register={register}
              label={"การอนุมัติการลา"}
              name="approveStatus"
              defaultChecked={approveStatusDefaultValue}
              error={errors.approveStatus}
              radios={DoctorLeaveApproveOptions}
              type="radio"
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
