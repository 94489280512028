export const ScheduleTypeFilters = {
  Booking: "booking",
  Leave: "leave",
  Schedules: "schedules",
};

export const ScheduleTimePeriodFilters = {
  All: "all",
  Today: "today",
  ThisWeek: "week",
  NextWeek: "next_week",
  ThisMonth: "month",
};

export const NotificationTypeFilter = {
  Broadcast: "broadcast",
  Specific: "specific",
};
