import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import { TransactionStatusOptions, IncomeTypeOptions } from "../../data/SelectOptions";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";

export default function CreateEditWorkLogTimeSheetForm({ onCancel, onSubmitSuccess, id, code, action_datetime, category, status, doctor_name, member_name, working_time_hrs, work_value, platform_cost, hospital_expenses, doctor_expenses }) {

  return (
    <>
      <Container fluid style={{ minHeight: "20rem" }}>
        <>
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="code"
                  label="รหัสรายการ"
                  defaultValue={code}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericDateTimePickerInput2
                  name="action_datetime"
                  label="วันที่และเวลาทำการ"
                  type="dateTime"
                  defaultValue={action_datetime}
                  disabled={true}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="category"
                  label="รหัสรายการ"
                  defaultValue={category}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="status"
                  label="สถานะ"
                  defaultValue={status}
                  disabled={true}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="doctor_name"
                  label="ชื่อแพทย์"
                  defaultValue={doctor_name}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="member_name"
                  label="ชื่อและรหัสลูกค้า"
                  defaultValue={member_name}
                  disabled={true}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3" />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="working_time_hrs"
                  label="ระยะเวลาทำงาน (ชม.)"
                  defaultValue={working_time_hrs}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="work_value"
                  label="มูลค่างาน"
                  defaultValue={work_value}
                  disabled={true}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="platform_cost"
                  label="ค่าใช้จ่าย Platform"
                  defaultValue={platform_cost}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="hospital_expenses"
                  label="ค่าใช้จ่าย รพ."
                  defaultValue={hospital_expenses}
                  disabled={true}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  name="doctor_expenses"
                  label="ค่าใช้จ่ายแพทย์"
                  defaultValue={doctor_expenses}
                  disabled={true}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
            </Row>
            <Row className="mb-3">
            </Row>
            <Row className="mb-3">
              <Col style={{ display: "flex", justifyContent: "end" }}>
                {/* <SaveButton /> */}
                <CancelButton onClick={onCancel} />
              </Col>
            </Row>
          {/* </form> */}
        </>
      </Container>
    </>
  );
}

