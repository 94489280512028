import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import {
  createUserMember,
  getMockUserMember,
} from "../../services/MemberServices";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import CreateButton from "../../components/Button/CreateButton";
import GenericDateTimePickerInput from "../../components/Input/GenericDateTimePickerInput";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import {
  CustomerTypeRadio,
  DoctorOnsiteRadio,
  DoctorStatusRadio,
} from "../../data/SelectOptions";
import {
  createCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices";

const schema = yup.object().shape({
  firstname: yup.string().required("ชื่อห้ามว่าง"),
  lastname: yup.string().required("นามสกุลห้ามว่าง"),
  email: yup.string().email("อีเมลไม่ถูกต้อง").required("อีเมลห้ามว่าง"),
  telephone: yup.string().required("เบอร์โทรศัพท์ห้ามว่าง"),
  date_birth: yup.string().required("วันเกิดห้ามว่าง"),
  hospital_title: yup.string().required("ชื่อโรงพยาบาลห้ามว่าง"),
  // partnership_hospital: yup.string().required("ชื่อโรงพยาบาลห้ามว่าง"),
  DoctorStatusRadio: yup.string().required("สถานะห้ามว่าง"),
  DoctorOnsiteRadio: yup.string().required("สถานะห้ามว่าง"),
});

export default function CreateNewDoctorsForm({ onCancel, onCreateSuccess }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [partHospital, setPartHospital] = React.useState([]);

  const onSubmit = async (inputData) => {
    console.log("inputData", inputData);
    const url_api = "/doctor_info";
    const p_data = {
      name: inputData.firstname,
      lname: inputData.lastname,
      phone: inputData.telephone,
      email: inputData.email,
      brithday: new Date(inputData.date_birth).toLocaleString("sv"),
      on_site: parseInt(inputData.DoctorOnsiteRadio),
      status: parseInt(inputData.DoctorStatusRadio),
      // status: inputData.DoctorStatusRadio,
      hospital_name: inputData.hospital_title,
      partner: inputData?.partnership_hospital?.id,
    };
    console.log(p_data);
    const { success, data, error } = await createCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      onCreateSuccess();
      ShowMessageAndExpire("Data created successfully.", "Data Success", "success");
    } else {
      console.log(error);
      var errorMessages = [];
      if (typeof error.message === "string") {
        errorMessages.push(error.message);
      } else {
        for (var propertyName in error.message) {
          var errorMessage = error.message[propertyName];
          errorMessages.push(errorMessage);
        }
      }
      var concatenatedErrors = errorMessages.join("\n");

      ShowMessageAndExpire("Failed to create data.", concatenatedErrors, "error");
    }
  };

  const partnershipHospital = React.useCallback(async () => {
    const url_api = "/hospital_info";
    const p_data = {
      select: ["id", "name"],
    };
    const { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      console.log("hospital_info", data.records);
      setPartHospital(preparePartHospital(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  React.useEffect(() => {
    partnershipHospital();
  }, [partnershipHospital]);

  const preparePartHospital = (datas) => {
    let listsPartner = [];
    for (const data of datas) {
      const id = data.id;
      const name = data.name;
      let partner = {
        id: id,
        label: name,
        value: {
          id: id,
          name: name,
        },
      };
      listsPartner.push(partner);
    }
    return listsPartner;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="firstname"
              label="Name"
              placeholder="Name"
              defaultValue=""
              required
              error={errors.firstname}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="lastname"
              label="Lastname"
              placeholder="Lastname"
              defaultValue=""
              required
              error={errors.lastname}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="telephone"
              label="Phone"
              placeholder="Phone"
              defaultValue=""
              required
              error={errors.telephone}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="email"
              label="Email Address"
              placeholder="Email Address"
              defaultValue=""
              required
              error={errors.email}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="date_birth"
              label="Date of birth"
              placeholder="Date of birth"
              type="date"
              defaultValue={""}
              required
              error={errors.date_birth}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericRadioInput
              register={register}
              name="DoctorStatusRadio"
              radios={DoctorStatusRadio}
              label={"Status"}
              error={errors.DoctorStatusRadio}
              defaultChecked={[10]}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="hospital_title"
              label="Hospital Title"
              placeholder="Hospital Title"
              defaultValue=""
              required
              error={errors.hospital_title}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericRadioInput
              register={register}
              name="DoctorOnsiteRadio"
              radios={DoctorOnsiteRadio}
              label={"On site Complie"}
              error={errors.DoctorOnsiteRadio}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="partnership_hospital"
              label="Partnership Hospital (ใช้ตั้งค่าเพื่อแบ่ง % รายได้)"
              placeholder="Partnership Hospital"
              options={partHospital}
              // required
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <CreateButton />
          </Col>
        </Row>
      </form>
    </>
  );
}
