import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import {Form, Row, Col} from "react-bootstrap";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";

import CreateButton from "../../components/Button/CreateButton";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import { VoucherRadio } from "../../data/SelectOptions";
import { createCommonAPI } from "../../services/commonAPIServices";
import GenericDateTimePickerInput from "../../components/Input/GenericDateTimePickerInput";
import GenericSelectInput from "../../components/Input/GenericSelectInput";

const options = [
  { value: '1', label: 'ปรึกษาหมอ' },
  { value: '2', label: 'ซื้อของ' },
]
const schema = yup.object().shape({
  lot_id: yup.string().required("Lot ID ห้ามว่าง"),
  exp_date: yup.date().required("วันหมดอายุห้ามว่าง"),
  qty: yup.number().required("จำนวนห้ามว่าง"),
  each_amt: yup.number().required("มูลค่าห้ามว่าง"),
  remark: yup.string().required("รายละเอียดห้ามว่าง"),
  //for_new_register: yup.number(),
  minimum: yup.number().required("จำนวนห้ามว่าง"),
});

export default function CreateNewVoucherForm({ onCancel, onCreateSuccess }) {
  const {
    register,
    control,
    handleSubmit,
    DiscountCodeDefaultValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (inputData) => {
    console.log(inputData);
    const url_api = "/voucher_lot";
    const p_data = {
      lot_id: inputData.lot_id,
      exp_date: inputData.exp_date,
      qty: inputData.qty,
      each_amt: inputData.each_amt,
      remark: inputData.remark,
      for_new_register: inputData.for_new_register?1:0,
      usefor: inputData.usefor.value,
      minimum: inputData.minimum,
    };
    const { success, data, error } = await createCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      onCreateSuccess();
      ShowMessageAndExpire("Data created successfully.", "Data Success", "success");
    } else {
      console.log(error);
    }
  };

  
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="lot_id"
              label="Lot id"
              placeholder="Lot id"
              defaultValue=""
              //required
              error={errors.lot_id}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput
              register={register}
              name="create_date"
              label="Create date"
              type="dateTime"
              placeholder="Create date"
              //defaultValue=""
              //required
              //error={errors.create_date}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="exp_date"
              label="Lot Expire"
              type="dateTime"
              error={errors.qty}
              defaultValue=""
              required
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="qty"
              label="Quantity"
              placeholder="Quantity"
              defaultValue=""
              required
              error={errors.qty}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="each_amt"
              label="Value Each"
              placeholder="Value Each"
              defaultValue=""
              required
              error={errors.each_amt}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="usefor"
              label="Use For"
              placeholder="ใช้สำหรับ"
              options={options}
              required
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="minimum"
              label="Minimum"
              placeholder="Minimum"
              defaultValue=""
              required
              error={errors.minimum}
            />
          </Form.Group>
        </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="remark"
              label="Admin Remark"
              placeholder="รายละเอียด"
              as="textarea"
              defaultValue=""
              required
            />
        </Form.Group> 
        <Form.Group as={Col} controlId="formBasicName">
        <GenericRadioInput
              register={register}
              name="for_new_register"
              label="For New Register"
              defaultValue={DiscountCodeDefaultValue}
              error={errors.for_new_register}
              radios={[{ id: 1, label: "", value: true }]}
              type="switch"
            />
        </Form.Group>
      </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <CreateButton />
          </Col>
        </Row>
      </form>
    </>
  );
}
