import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import VoucherLotForm from "./VoucherLotForm";
import VoucherInfo from "./VoucherInfo";

const schema = yup.object().shape({});

export default function VoucherForm({
  onCancel,
  onSubmitSuccess,
  id,
  voucher_lot_id,
  keyTabs,
}) {
  const [keyTab, setKeyTab] = useState(keyTabs);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  
  

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={keyTab}
        onSelect={(k) => setKeyTab(k)}
        className="mb-3"
        justify
      >
        <Tab eventKey="General" title="General">
          {/* Tab content for General */}
          <VoucherLotForm
            onCancel={onCancel}
            onSubmitSuccess={onSubmitSuccess}
            id={id}
            voucher_lot_id={voucher_lot_id}
          />
        </Tab>
        <Tab eventKey="Vouchers" title="Vouchers">
          {/* Tab content for Vouchers. */}
          <VoucherInfo
            onCancel={onCancel}
            onSubmitSuccess={onSubmitSuccess}
            id={id}
            voucher_lot_id={voucher_lot_id}
          />
        </Tab>
      </Tabs>
    </>
  );
}
