export const dateTimeFormat = "yyyy-MM-dd HH:mm:ss";
export const dateFormat = "yyyy-MM-dd";

export const emailRegExp =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const emailInvalidMessage = "อีเมลไม่ถูกต้อง";
export const phoneRegExp = /^\d{10}$/;
export const phoneInvalidMessage = "ต้องเป็นตัวเลข 10 หลัก";
export const passwordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d-฿@#!$%^&*()_+|~=`{}\[\]:\";'<>?,.\/\\]{8,20}$/;
export const passwordInvalidMessage =
  "ต้องประกอบด้วยตัวอักษพิมพ์ใหญ่, พิมพ์เล็ก, และ ตัวเลข ความยาว 8-20 ตัวอักษร";
