import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import { isPromaryOptions } from "../../data/SelectOptions";
import {
  createCommonAPI,
  editCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";

const schema = yup.object().shape({
  // document_number: yup.string().required("ห้ามว่าง"),
  // date_issue: yup.string().required("ห้ามว่าง"),
  // document_exp_date: yup.string().required("ห้ามว่าง"),
  // document_type: yup.object().required("ห้ามว่าง"),
});

export default function EditShippingSetModal({
  show,
  setShow,
  onSave,
  editData,
}) {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log("EditShippingSetModal: ", editData);

  const [provinceList, setProvinceList] = React.useState([]);
  const [districtList, setDistrictList] = React.useState([]);
  const [subdistrict, setSubdistrict] = React.useState([]);

  const loadShippingAddress = React.useCallback(async () => {
    let url_api = "/address/" + editData?.id;
    let p_data = {};
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      console.log("loadShippingAddress: ", data.records);
      console.log("loadShippingAddress.default: ", data.records?.default);
      setValue("address", data.records?.addressno);
      setValue("street", data.records?.street);
      setValue("m_province_id", data.records?.m_province_id);
      setValue("m_district_id", data.records?.m_district_id);
      setValue("m_subdistrict_id", data.records?.m_subdistrict_id);
      setValue("address_id", data.records?.id);
      setValue("setPrimaryAddress", data.records?.default);
      provinceInfoList();
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const provinceInfoList = React.useCallback(async () => {
    let url_api = "/m_province";
    let p_data = {};
    const { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setProvinceList(prepareProvinceList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareProvinceList = (datas) => {
    let listsProvince = [];
    for (const data of datas) {
      const id = data.id;
      const name = data.name;
      const province = {
        id: id,
        label: name,
        value: {
          id: id,
          name: name,
        },
      };
      listsProvince.push(province);
      if (data.id === getValues("m_province_id")) {
        setValue("province", province, { shouldValidate: true });
        districtInfoList(getValues("m_province_id"));
      }
    }

    return listsProvince;
  };

  const districtInfoList = React.useCallback(async (provinceId) => {
    let url_api = "/m_district";
    let p_data = {
      "filter.m_province_id": provinceId,
    };
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setDistrictList(prepareDistrictList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareDistrictList = (datas) => {
    let listsDistrict = [];
    if (Array.isArray(datas)) {
      for (const data of datas) {
        const id = data.id;
        const name = data.name;
        const province = data.m_province_id;
        const district = {
          id: id,
          label: name,
          value: {
            id: id,
            name: name,
            province: province,
          },
        };
        listsDistrict.push(district);
        if (data.id === getValues("m_district_id")) {
          setValue("district", district, { shouldValidate: true });
          loadSubdistrict(getValues("m_district_id"));
        }
      }
    } else {
      console.log("datas is not iterable.");
    }

    return listsDistrict;
  };

  const loadSubdistrict = React.useCallback(async (districtId) => {
    let url_api = "/m_subdistrict";
    let p_data = {
      "filter.m_district_id": districtId,
    };
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setSubdistrict(prepareSubdistrict(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareSubdistrict = (datas) => {
    let listSubdistrict = [];
    let m_subdistrict_id = getValues("m_subdistrict_id");
    for (const data of datas) {
      const id = data.id;
      const name = data.name;
      const postcode = data.postcode;
      const district = data.m_district_id;
      let subdistrict = {
        id: id,
        label: name,
        value: {
          id: id,
          name: name,
          postcode: postcode,
          district: district,
        },
      };
      listSubdistrict.push(subdistrict);
      if (data.id === m_subdistrict_id) {
        setValue("sub_district", subdistrict, { shouldValidate: true });
        setValue("post_code", subdistrict.value.postcode, {
          shouldValidate: true,
        });
      }
    }
    return listSubdistrict;
  };

  const handleChangeProvince = (e) => {
    if (e?.value) {
      districtInfoList(e.value.id);
      setValue("district", null);
      setSubdistrict([]);
      setValue("sub_district", null);
      setValue("post_code", null);
    } else {
      setDistrictList([]);
      setValue("district", null);
      setSubdistrict([]);
      setValue("sub_district", null);
      setValue("post_code", null);
    }
  };

  const handleChangeDisList = (e) => {
    if (e?.value) {
      loadSubdistrict(e.value.id);
      setSubdistrict([]);
      setValue("sub_district", null);
      setValue("post_code", null);
    } else {
      setSubdistrict([]);
      setValue("sub_district", null);
      setValue("post_code", null);
    }
  };

  const handleChangeSubDisList = (e) => {
    if (e?.value) {
      setValue("post_code", e.value?.postcode, { shouldValidate: true });
    } else {
      setValue("post_code", null);
    }
  };

  const refresh = () => {
    reset();
  };

  const onCancel = async () => {
    setShow(false);
    refresh();
  };

  const onSubmit = async (inputData) => {
    let setPrimaryAddress = 0;
    if (inputData.setPrimaryAddress == "1") {
      setPrimaryAddress = 1;
    }
    console.log("inputData: ", inputData);
    let url_api = "/Address/" + editData.id;
    let p_data = {
      any_id: editData.any_id,
      rname: inputData.recipient_name,
      phone: inputData.phone,
      addressno: inputData.address,
      street: inputData.street,
      m_subdistrict_id: inputData.sub_district?.id,
      postcode_id: inputData.post_code,
      default: setPrimaryAddress,
      address_type: 1,
    };
    let { success, data, error } = await editCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      onSave();
      refresh();
      ShowMessageAndExpire("Data created successfully.", "", "success");
    } else {
      var errorMessages = [];
      if (typeof error.message === "string") {
        errorMessages.push(error.message);
      } else {
        for (var propertyName in error.message) {
          var errorMessage = error.message[propertyName];
          errorMessages.push(errorMessage);
        }
      }
      var concatenatedErrors = errorMessages.join("\n");

      ShowMessageAndExpire("Failed to create data.", concatenatedErrors, "error");
    }
  };

  React.useEffect(() => {
    loadShippingAddress();
  }, [loadShippingAddress]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => onCancel()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>เพิ่มที่อยู่ผู้รับ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="recipient_name"
                label="ชื่อ-นามสกุล ผู้รับ"
                placeholder="ชื่อ-นามสกุล ผู้รับ"
                defaultValue={editData ? editData?.rname : null}
                required
                error={errors.recipient_name}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="phone"
                label="หมายเลขโทรศัพท์ "
                placeholder="หมายเลขโทรศัพท์ "
                defaultValue={editData ? editData?.phone : null}
                required
                error={errors.phone}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="address"
                label="Address"
                placeholder="Address"
                defaultValue={editData ? editData?.addressno : null}
                required
                error={errors.Address}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="street"
                label="Street "
                placeholder="Street "
                defaultValue={editData ? editData?.street : null}
                required
                error={errors.street}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericSelectInput
                control={control}
                name="province"
                label="จังหวัด"
                placeholder="จังหวัด"
                options={provinceList}
                required
                onChange={handleChangeProvince}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericSelectInput
                control={control}
                name="district"
                label="อำเภอ / เขต"
                placeholder="อำเภอ / เขต"
                options={districtList}
                required
                onChange={handleChangeDisList}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericSelectInput
                control={control}
                name="sub_district"
                label="ตำบล / แขวง"
                placeholder="ตำบล / แขวง"
                options={subdistrict}
                required
                onChange={handleChangeSubDisList}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="post_code"
                label="รหัสไปรษณีย์"
                placeholder="รหัสไปรษณีย์"
                // defaultValue=""
                required
                error={errors.post_code}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericRadioInput
                register={register}
                name="setPrimaryAddress"
                label="Set as Primary Address"
                placeholder="Set as Primary Address"
                // defaultChecked={[editData ? editData?.default : 1]}
                error={errors.setPrimaryAddress}
                radios={isPromaryOptions}
                type="switch"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName"></Form.Group>
          </Row>
          <Row className="mb-3">
            <Col>
              <CancelButton onClick={() => onCancel()} />
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }}>
              <SaveButton />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
