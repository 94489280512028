import React from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Header from "../../layouts/Header";
import PageHeaderCard from "../../components/Card/PageHeaderCard";
import Searching from "../../components/Searching";
import ReactTable from "../../components/ReactTable";
import GenericPagination from "../../components/GenericPagination";
import { Vouchercolumn } from "../../data/Columns";
import { editCommonAPI, getCommonAPI } from "../../services/commonAPIServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import VoidButton from "../../components/Button/VoidButton";
import { VoucherStatusOptions } from "../../data/SelectOptions";
import ReactDateTimePicker from "../../components/Input/ReactDateTimePicker";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Schema } from "yup";
import ChangeConfirmModal from "../../components/Modal/ChangeConfirmModal";

export default function VoucherInfo({onCancel, onSubmitSuccess, id, voucher_lot_id}) {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
  });

  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [Search, setSearch] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [editData, setEditData] = React.useState();
  const columnsData = Vouchercolumn;
  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isOpenCraeateForm, setIsOpenCraeateForm] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();

  const loadVoucherInfo = React.useCallback(
    async(currentPage, pageSize, search) => {
      const page = currentPage;
      const limit = pageSize;
      const url_api =
        "/voucher/datatable?filter._relations=rel_member_info_id,rel_voucher_lot_id&filter.voucher_lot_id=" + voucher_lot_id;
      const p_data = {
        page,
        limit,
        search,
      };
      const { success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  React.useEffect(() => {
    loadVoucherInfo(1, 10);
  }, [loadVoucherInfo]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadVoucherInfo(page, pageSize, Search);
    },
    [loadVoucherInfo, pageSize, Search]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadVoucherInfo(1, pageSize, Search);
    },
    [loadVoucherInfo, Search]
  );

  const openCreateForm = () => {
    setIsOpenCraeateForm(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setCurrentPage(1);
    setSearch("");
    loadVoucherInfo(1, pageSize, "");
  };

  const handleSearch = React.useCallback(
    async (Search) => {
      setCurrentPage(1);
      setSearch(Search);
      await loadVoucherInfo(1, pageSize, Search);
    },
    [loadVoucherInfo, pageSize]
  );

  const handleEdit = React.useCallback(async () => {
    handleCloseEdit();
    loadVoucherInfo(currentPage, pageSize, Search);
  }, [loadVoucherInfo, currentPage, pageSize, Search]);

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setEditData(data);
  };

  const handleCloseEdit = () => {
    setIsOpenForm(false);
    setIsOpenCraeateForm(false);
    setEditData(null);
  };

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const url_api = "/voucher/" + id;
      const p_data = {
        status: 33,
      };
      const { success, data, error } = await editCommonAPI({
        url_api,
        p_data,
      });
      if (success) {
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadVoucherInfo(tempCurrentPage, pageSize, Search);
      } else {
        console.log(error);
      }
    },
    [loadVoucherInfo, total, currentPage, pageSize, Search]
  );

  return (
    <>
      <Header />
      <div className="">
        <div className="">
          {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
            {isDelete && (
              <ChangeConfirmModal
                show={isDelete}
                onClose={handleCloseDeleteConfirm}
                onComfirm={handleDelete}
                changeredcord={deleteData?.id}
                confirmMessage={
                  <>
                    <p>{"Do you really want to change these record?"}</p>
                    <p>{`Status = Redeem`}</p>
                  </>
                }
              />
            )}
          {/* </Form> */}
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                <Row className="mt-3">
                  <Col xs={6} style={{ display: "flex" }}>
                    <Searching placeholder="Search " onSearch={handleSearch} />
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  ></Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <ReactDateTimePicker
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <ReactTable columns={columnsData} data={tableData} />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <GenericPagination
                      totalPage={Math.ceil(total / pageSize)}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      onPageChange={loadPageOnPageChange}
                      onPageSizeChange={loadPageOnPageSizeChange}
                    />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, onEdit, onDelete) => {
  let tableData = [];
  for (const data of datas) {
    let button = (<></>);
    const tempTableData = {
      id: data.id,
      code: data.code,
      used: `${data.rel_member_info_id?.phone} (${data.rel_member_info_id?.name})`,
      each_amt: data.rel_voucher_lot_id?.each_amt,
      create_date: data.create_date,
      create_by: data.create_by,
      status: VoucherStatusOptions.find((e) => e.value == data.status).label,
      remark: data.rel_voucher_lot_id?.remark,
      exp_date: data.rel_voucher_lot_id?.exp_date,
    };
    const buttonData = tempTableData;
    if(data.status != 33){
        button =  
        (<>
        <VoidButton
          onClick={() => {
            onDelete(data);
          }}
        />{" "}
      </>)
    }
    tableData.push({
      ...tempTableData,
      VoidButton: button,
    });
  }
  // console.log(tableData);
  return tableData;
};
