import React from "react";
import ReactDatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import { CaretDownFill } from "react-bootstrap-icons";
import { dateFormat, dateTimeFormat } from "../../data/Config";
import { Form, Col } from "react-bootstrap";
import moment from 'moment';

export default function ReactDateTimeRangePicker2({
  onSelected,
  defaultStart,
  defaultEnd,
  placeholder="เลือกวันที่ทำรายการ"
}) {

  const [startDate,setStartDate] = React.useState(defaultStart);
  const [endDate,setEndDate] = React.useState(defaultEnd);

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <>
      <Form.Control onClick={onClick} defaultValue={value} className="reactDateTimeRangePicker" placeholder={placeholder}/>
    </>
  ));

  const handleDateChange = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);

    onSelected(
      date[0] && moment(date[0]).format('YYYY-MM-DD'),
      date[1] && moment(date[1]).format('YYYY-MM-DD')
    );

  };

  return (
    <div>
      <ReactDatePicker
        customInput={<ExampleCustomInput />}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={handleDateChange}
        isClearable={true}
        dateFormat={dateFormat}
      />
    </div>
  );
}
