import React from "react";
import { membersPetsInfo } from "../data/Columns";
import { Card, Col, Container, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import { deleteMockUserDoctor } from "../services/DoctorServices";
import { getMockPetsInfo } from "../services/PetServices";
import CreateNewPetForm from "../features/Form/CreateNewPetForm";
import PetDetailEditForm from "../features/Form/PetDetailEditForm";
import { deleteCommonAPI, getCommonAPI } from "../services/commonAPIServices";
import ReactDateTimeRangePicker from "../components/Input/ReactDateTimeRangePicker";
import setPlaceholder from "../utils/Help";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";

const allUserStatus = "all";

export default function PetInfo() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [tableData, setTableData] = React.useState([]);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const [dateStart, setDateStart] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();
  const columnsData = membersPetsInfo;

  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isOpenCraeateForm, setIsOpenCraeateForm] = React.useState(false);

  const loadPetInfo = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, dateStart, dateEnd) => {
      let datefilter;
      if (dateStart && dateEnd) {
        datefilter = "$btw:" + dateStart + " 00:00:00," + dateEnd + " 23:59:59";
      }
      const page = currentPage;
      const limit = pageSize;
      const search = keyword;
      const url_api = "/pet_info/datatable";
      const p_data = {
        "filter._relations": "rel_m_pet_type_id",
        "filter.last_service": datefilter,
        page,
        search,
        limit,
      };
      console.log("p_data ", p_data);
      const { success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      });
      if (success) {
        console.log("data.records ", data.records);
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  React.useEffect(() => {
    loadPetInfo(1, 10, allUserStatus);
  }, [loadPetInfo]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadPetInfo(page, pageSize, userStatus, keyword);
    },
    [loadPetInfo, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadPetInfo(1, pageSize, userStatus, keyword);
    },
    [loadPetInfo, userStatus, keyword]
  );

  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadPetInfo(1, pageSize, userStatus, keyword);
    },
    [loadPetInfo, pageSize, keyword]
  );

  const openCreateForm = () => {
    setIsOpenCraeateForm(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setCurrentPage(1);
    setKeyword("");
    setUserStatus(allUserStatus);
    loadPetInfo(1, pageSize, allUserStatus, "");
  };

  const loadTimeRangePicker = React.useCallback(
    async (sDate, eDate) => {
      setDateStart(sDate);
      setDateEnd(eDate);
      setCurrentPage(1);
      await loadPetInfo(1, pageSize, userStatus, keyword, sDate, eDate);
    },
    [loadPetInfo, pageSize, userStatus, keyword]
  );

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadPetInfo(1, pageSize, userStatus, keyword);
    },
    [loadPetInfo, pageSize, userStatus]
  );

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleEdit = React.useCallback(async () => {
    handleCloseEdit();
    loadPetInfo(currentPage, pageSize, userStatus, keyword);
  }, [loadPetInfo, currentPage, pageSize, userStatus, keyword]);

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setEditData(data);
  };

  const handleCloseEdit = () => {
    setIsOpenForm(false);
    setIsOpenCraeateForm(false);
    setEditData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const url_api = "/pet_info/" + id;
      const { success, data, error } = await deleteCommonAPI({
        url_api,
      });
      if (success) {
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadPetInfo(tempCurrentPage, pageSize, userStatus, keyword);
      } else {
        console.log(error);
      }
    },
    [loadPetInfo, total, currentPage, pageSize, userStatus, keyword]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              editData ? `Pet Info : ${editData?.name}` : "Pet Info Management"
            }
            buttonEnable={!isOpenForm && !isOpenCraeateForm}
            buttonIcon="plus"
            buttonMessage="New Pet"
            onButtonOnClick={openCreateForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`Pets ID = ${deleteData?.id}, ชื่อ Pet = ${deleteData?.name}`}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenCraeateForm ? (
                  <>
                    <CreateNewPetForm
                      onCancel={handleCloseEdit}
                      onCreateSuccess={handleEdit}
                    />
                  </>
                ) : isOpenForm ? (
                  <>
                    <PetDetailEditForm
                      onCancel={handleCloseEdit}
                      onSubmitSuccess={handleEdit}
                      id={editData?.id}
                    />
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder={setPlaceholder([
                            "ชื่อสัตว์เลี้ยง",
                            "ประเภทสัตว์เลี้ยง",
                          ])}
                          onSearch={handleSearch}
                          defaultValue={keyword}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactDateTimeRangePicker2
                          onSelected={loadTimeRangePicker}
                          defaultStart={dateStart ? new Date(dateStart) : null}
                          defaultEnd={dateEnd ? new Date(dateEnd) : null}
                          placeholder="Last Service"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = [];
  for (const data of datas) {
    tableData.push({
      no: startNo + tableData.length,
      pet_name: data.name,
      owner_name: data.ownname,
      owner_phone: data.phone,
      pet_category: data.rel_m_pet_type_id?.name,
      date_of_birth: data.brithday,
      last_service_date: data.last_service,
      register_date: data.create_date,
      remark: data.remark,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(data);
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(data);
            }}
          />
        </>
      ),
    });
  }
  console.log(tableData);
  return tableData;
};
