import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getCommonAPI = async ({ url_api, p_data }) => {
  try {
    const res = await axios.get(process.env.REACT_APP_API_URL + url_api, {
      headers: await getDefaultRequestHeaders(true),
      params: p_data,
    });
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createCommonAPI = async ({
  url_api, p_data,
}) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + url_api,
      p_data,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editCommonAPI = async ({
  url_api, p_data,
}) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + url_api,
      p_data,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteCommonAPI = async ({ url_api }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + url_api,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};
