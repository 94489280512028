import React from "react"
import { DoctorWorkLogColumn, ReceiptColumn } from "../data/Columns"
import { Modal, Button, Card, Col, Container, Row, Form } from "react-bootstrap"
import Header from "../layouts/Header"
import PageHeaderCard from "../components/Card/PageHeaderCard"
import FilterDropdown from "../components/Input/Dropdown/GenericDropdown"
import Searching from "../components/Searching"
import ReactTable from "../components/ReactTable"
import GenericPagination from "../components/GenericPagination"
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker"
import {
  getDoctorWorkLogReport,
  getMockDoctorWorkLogReportReport,
} from "../services/LogServices"
import GenericButton from "../components/Button/GenericButton"
import ReactDateTimeRangePicker from "../components/Input/ReactDateTimeRangePicker"
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2"
import { receiptReportCSVHeader } from "../data/CSVHeader"
import ExportCSVButton from "../components/Button/ExportCSVButton"
import CancelButton from "../components/Button/CancelButton"
import html2pdf from "html2pdf.js"
import { renderToStaticMarkup } from "react-dom/server"
import "../assets/css/style-receipt.css"
import logo from "../assets/img/logo.svg"
import { useNavigate } from "react-router-dom"
import ReceiptDeatil from "./ReceiptDeatil"
import { getCommonAPI } from "../services/commonAPIServices"
import { ArabicNumberToText, textAlignRight } from "../utils/Help"

export default function Receipt() {
  const reportTemplateRef = React.useRef(null)

  const csvHeader = receiptReportCSVHeader
  const csvFilename = "Receipt.csv"
  const [isExport, setIsExport] = React.useState(false)

  const [total, setTotal] = React.useState(1)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(10)
  const [keyword, setKeyword] = React.useState("")

  const [dateStart, setDateStart] = React.useState()
  const [dateEnd, setDateEnd] = React.useState()
  const allUserStatus = ""
  const [userStatus, setUserStatus] = React.useState(allUserStatus)
  const [tableReport, setTableReport] = React.useState([])
  const [isOpenForm, setIsOpenForm] = React.useState(false)
  const [editData, setEditData] = React.useState()
  const [modalShow, setModalShow] = React.useState(false)
  const [modalDesc, setModalDesc] = React.useState(false)
  const [csvData, setCsvData] = React.useState([])
  const [isOpenCraeateForm, setIsOpenCraeateForm] = React.useState(false)
  const [receiptNo, setReceiptNo] = React.useState([])

  const loadReport = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, dateStart, dateEnd) => {
      let datefilter
      if (dateStart && dateEnd) {
        datefilter = "$btw:" + dateStart + " 00:00:00," + dateEnd + " 23:59:59"
      }
      const { success, data, error } = await getCommonAPI({
        url_api: "/get_receipt_list",
        p_data: {
          currentPage,
          size: pageSize,
          search: keyword,
          "filter.create_date": datefilter,
        },
      })
      if (success) {
        setTableReport(
          prepareTable(data.records, handleShowDetail, handleGeneratePDF)
        )
        setCsvData(prepareCSVData(data.records))
      } else {
        console.log(error)
      }
    },
    []
  )
  React.useEffect(() => {
    loadReport(1, 10, allUserStatus)
  }, [loadReport])

  const columnsReport = ReceiptColumn
  const prepareTable = (datas, onShowDetail, handleGeneratePDF) => {
    let tableData = []
    for (const data of datas) {
      tableData.push({
        id: data.rownum,
        receipt_no: data.receipt_no,
        action_datetime: data.rcp_receipt_date,
        group: data.trn_type_name,
        cusname: data.uname,
        value: textAlignRight(data.amt),
        ref: data.ref_no,
        remark: data.remark,
        status: data.status_name,
        print: (
          <Button variant="success" onClick={() => handleGeneratePDF(data)}>
            PDF
          </Button>
        ),
        view: (
          <Button variant="warning" onClick={() => onShowDetail(data)}>
            View
          </Button>
        ),
      })
    }
    return tableData
  }

  const prepareCSVData = (datas) => {
    let csvData = []
    for (const data of datas) {
      csvData.push({
        id: data.rownum,
        receipt_no: data.receipt_no,
        action_datetime: data.rcp_receipt_date,
        group: data.trn_type_name,
        cusname: data.uname,
        value: data.amt,
        ref: data.ref_no,
        remark: data.remark,
        status: data.status,
      })
    }
    return csvData
  }

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page)
      await loadReport(page, pageSize, userStatus, keyword)
    },
    [loadReport, pageSize, userStatus, keyword]
  )

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1)
      setPageSize(pageSize)
      await loadReport(1, pageSize, userStatus, keyword)
    },
    [loadReport, userStatus, keyword]
  )
  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1)
      setUserStatus(userStatus)
      await loadReport(1, pageSize, userStatus, keyword)
    },
    [loadReport, pageSize, keyword]
  )

  const refreshPage = () => {
    setIsOpenForm(false)
    setCurrentPage(1)
    setKeyword("")
    setUserStatus(allUserStatus)
    loadReport(1, pageSize, allUserStatus, "")
  }

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1)
      setKeyword(keyword)
      await loadReport(1, pageSize, userStatus, keyword, dateStart, dateEnd)
    },
    [loadReport, pageSize, userStatus, keyword, dateStart, dateEnd]
  )

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true)
    setEditData(data)
  }
  const navigate = useNavigate()
  const handleShowDetail = (data) => {
    //navigate(`/pages/receipt-detail`);
    // const txt = ''
    // setModalDesc(txt);
    // setModalShow(true);
    setIsOpenCraeateForm(true)
    setReceiptNo(data.receipt_no)
  }
  const handleDetailModalClose = (data) => {
    setModalShow(false)
    setModalDesc("")
  }
  const handleSelectedDate = React.useCallback(
    async (sDate, eDate) => {
      setDateStart(sDate)
      setDateEnd(eDate)
      setCurrentPage(1)
      await loadReport(1, pageSize, userStatus, keyword, sDate, eDate)
    },
    [loadReport, pageSize, userStatus, keyword]
  )
  const handleExportCSV = () => {
    setIsExport(true)
  }
  const handleExportComplete = () => {
    setIsExport(false) // Reset the trigger to false
  }

  const handleGeneratePDF = async (d) => {
    let detail = null
    const { success, data, error } = await getCommonAPI({
      url_api: "/get_receipt_detail",
      p_data: {
        "receipt_no ": d.receipt_no,
      },
    })
    if (success) {
      detail = data.records
    } else {
      console.log(error)
    }

    const pdfContent = (
      <div id="example">
        <div className="box wide hidden-on-narrow">
          <div className="box-col" />

          <div className="box-col" />
        </div>

        <div className="page-container hidden-on-narrow">
          <div className="pdf-page size-a4-landscape">
            <div className="inner-page">
              <div className="pdf-header">
                <img src={logo} width="180px" />
                <span className="company-logo">
                  <h2 className="margin-top">
                    {/* {detail[0]["company_name"]} */}
                    {detail[0] && detail[0].company_name}
                  </h2>
                  <span>
                    {detail[0] && detail[0].addressno}{" "}
                    {detail[0] && detail[0].street}{" "}
                    {detail[0] && detail[0].subdistrict}{" "}
                    {detail[0] && detail[0].district}{" "}
                    {detail[0] && detail[0].province}{" "}
                    {detail[0] && detail[0].postcode}
                  </span>
                  <span>
                    โทร : {detail[0] && detail[0].phone} แฟกซ์ :{" "}
                    {detail[0] && detail[0].fax}
                  </span>
                </span>

                <span className="invoice-number">
                  <h3 className="margin-top">
                    ต้นฉบับใบเสร็จรับเงิน/ใบกำกับภาษี
                  </h3>
                  <span>Original Receipe/Tax Invoice</span>
                  <span>Tax ID : {detail[0] && detail[0].tax_id}</span>
                </span>
              </div>

              <div className="addresses">
                <div className="for">
                  <p>
                    ได้รับเงินจาก/Receive From : คุณ
                    {detail[0] && detail[0].uname}(
                    {detail[0] && detail[0].userid})
                    <br /> <br />
                    ที่อยู่/Address : {detail[0] && detail[0].uaddressno}{" "}
                    {detail[0] && detail[0].ustreet}{" "}
                    {detail[0] && detail[0].usubdistrict}{" "}
                    {detail[0] && detail[0].udistrict}{" "}
                    {detail[0] && detail[0].uprovince}{" "}
                    {detail[0] && detail[0].upostcode}
                    <br /> <br />
                    Tax ID : xxxxxxxxxxxxxxx
                  </p>
                </div>

                <div className="from">
                  <p>
                    วันที่/Date : {detail[0] && detail[0].create_date}
                    <br /> <br />
                    เลขที่/No. : {detail[0] && detail[0].receipt_no}
                    <br /> <br />
                    ใบแจ้งหนี้เลขที่/Invoice No. :{" "}
                    {detail[0] && detail[0].inv_no}
                  </p>
                </div>
              </div>

              <table class="table table-striped t">
                <thead>
                  <tr>
                    <th scope="col-6">
                      รายการ <br />
                      Description
                    </th>
                    <th scope="col-2" class="text-end">
                      จำนวนเงิน <br />
                      Amount
                    </th>
                    <th scope="col-2" class="text-end">
                      ภาษีมูลค่าเพิ่ม <br />
                      VAT
                    </th>
                    <th scope="col-2" class="text-end">
                      จำนวนเงินรวม (บาท) <br />
                      Total (Bath)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detail.map((item, index) => (
                    <>
                      <tr>
                        <td>{item.items}</td>
                        <td className="text-end">{item.amt}</td>
                        <td className="text-end">{item.vat}</td>
                        <td className="text-end">{item.amt}</td>
                      </tr>
                    </>
                  ))}

                  <tr>
                    <td className="text-end">ยอดรวมทั้งสิ้น/GRAND TOTAL</td>
                    <td className="text-end">
                      {detail[0] && detail[0].total_amt}
                    </td>
                    <td className="text-end">
                      {detail[0] && detail[0].total_vat}
                    </td>
                    <td className="text-end">
                      {detail[0] && detail[0].grand_total}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" className="text-end">
                      {detail[0] && ArabicNumberToText(detail[0].grand_total)}
                    </td>
                    <td className="text-end">บาท/Bath</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="pdf-footer">
              <div className="row-footer">
                <div className="pdf-body">
                  <div id="grid" />
                  <p className="signature">
                    ชำระโดย/Paid By
                    <br />
                    <br />
                    <br />
                    <br />
                    __________________________ <br />
                    <br />
                    ผู้รับเงิน/Collector
                  </p>
                </div>

                <div className="pdf-body-end">
                  <div id="grid" />
                  <p className="signature">
                    <br />
                    <br />
                    <br />
                    <br />
                    __________________________ <br />
                    <br />
                    ผู้มีอำนาจลงนาม/Authorized Signature
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

    const htmlContent = renderToStaticMarkup(pdfContent)
    // Convert the HTML content to a PDF
    const options = {
      margin: [1, 1, 1, 1],
      filename: "receipt.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
    }

    try {
      html2pdf().from(htmlContent).set(options).save()
    } catch (error) {
      console.error("Error generating PDF:", error)
    }
  }

  return (
    <>
      {!isOpenCraeateForm ? (
        <>
          <Modal
            show={modalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <b>Doctor Work Log Report Detail</b>
            </Modal.Header>
            <Modal.Body>{modalDesc}</Modal.Body>
            <Modal.Footer>
              <CancelButton onClick={handleDetailModalClose} />
            </Modal.Footer>
          </Modal>
          <Header />

          <div className="main main-app p-3 p-lg-4">
            <div className="p-3 p-lg-4">
              <ExportCSVButton
                csvData={csvData}
                csvHeader={csvHeader}
                filename={csvFilename}
                enclose=""
                showBtn={false}
                isExport={isExport}
                onExportComplete={handleExportComplete}
              />
              <PageHeaderCard
                title="Receipt"
                buttonEnable={true}
                buttonMessage="Export CSV"
                onButtonOnClick={handleExportCSV}
              />

              <Card>
                <Card.Body>
                  <Container fluid style={{ minHeight: "30rem" }}>
                    <>
                      <Row className="mt-3">
                        <Col xs={6} style={{ display: "flex" }}>
                          <Searching
                            placeholder="Filter :"
                            onSearch={handleSearch}
                            defaultValue={keyword}
                          />
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          {/* {" โรงพยาบาล : "}&nbsp;&nbsp;
                      <FilterDropdown currentValue="Enable" />
                      &nbsp;&nbsp;{" สถานะ : "}&nbsp;&nbsp;
                      <FilterDropdown currentValue="Enable" />
                      &nbsp;&nbsp;{" กลุ่มรายการ : "}&nbsp;&nbsp;
                      <FilterDropdown currentValue="Enable" /> */}
                          {/* <ReactDateTimePicker
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                      /> */}
                          <ReactDateTimeRangePicker2
                            onSelected={handleSelectedDate}
                            defaultStart={
                              dateStart ? new Date(dateStart) : null
                            }
                            defaultEnd={dateEnd ? new Date(dateEnd) : null}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <ReactTable
                            columns={columnsReport}
                            data={tableReport}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col>
                          <GenericPagination
                            totalPage={Math.ceil(total / pageSize)}
                            currentPage={currentPage}
                            pageSize={pageSize}
                            onPageChange={loadPageOnPageChange}
                            onPageSizeChange={loadPageOnPageSizeChange}
                          />
                        </Col>
                      </Row>
                    </>
                  </Container>
                </Card.Body>
              </Card>
            </div>
          </div>
        </>
      ) : (
        <>
          <ReceiptDeatil receiptno={receiptNo} />
        </>
      )}
    </>
  )
}
