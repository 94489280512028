import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getRecord = async ({ page, pageSize, search, status, dateStart, dateEnd }) => {
  try {
    let datefilter;
    if (dateStart && dateEnd) {
      datefilter = '$btw:' + dateStart + ' 00:00:00,' + dateEnd + ' 23:59:59';
    }
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/banner/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit: pageSize,
          search,
          'filter.status': status,
          'filter.update_date': datefilter,
          'filter._relations':'rel_m_banner_type_id'
        },
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};



export const createRecord = async ({
  m_banner_type_id,
  status,
  subject,
  dptime,
}) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/banner",
      { m_banner_type_id, status, subject, dptime },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }

}

export const editRecord = async ({
  id,
  m_banner_type_id,
  status,
  subject,
  dptime,
}) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/banner/" + id,
      { m_banner_type_id, status, subject, dptime },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteRecord = async ({ id }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/banner/" + id,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};



export const getRecordDetail = async ({ id, page, pageSize, keyword }) => {
  try {
    // console.log(id);
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/banner_picture/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          "filter.banner_id": id,
          limit: pageSize,
          keyword,
        },
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createDetailRecordOnlyData = async ({
  banner_id,
  banner_order,
  status,
  exp_date,
  url,
}) => {
  try {
    // console.log(exp_date);
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/banner_picture",
      { banner_id, banner_order, status, exp_date, url },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
}

export const createDetailRecord = async ({
  banner_id,
  banner_order,
  status,
  exp_date,
  url,
  image
}) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("banner_id", banner_id);
    bodyFormData.append("image", image, image?.name);
    bodyFormData.append("banner_order", banner_order);
    bodyFormData.append("status", status);
    bodyFormData.append("exp_date", exp_date);
    bodyFormData.append("url", url);

    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/ext/banner_picture/upload_new",
      bodyFormData,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
}

export const editDetailRecord = async ({
  id,
  banner_order,
  status,
  exp_date,
  url,
}) => {

  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/banner_picture/" + id,
      { banner_order, status, exp_date, url },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteDetailRecord = async ({ id }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/banner_picture/" + id,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const uploadImage = async ({ id,image }) => {
  try {
    let bodyFormData = new FormData();
    bodyFormData.append("image", image, image?.name);
    bodyFormData.append("res_model", "banner_picture");
    bodyFormData.append("res_id", id);
    bodyFormData.append("res_field", "picture_path");
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/upload/image",
      bodyFormData,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteImage = async ({ data }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/upload/image",
      {
        headers: await getDefaultRequestHeaders(true),
        data: data
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};
