import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import DoctorEditGeneralForm from "./DoctorEditGeneralForm";
import DoctorEditProfessionForm from "./DoctorEditProfessionForm";
import DoctorEditTimeTableForm from "./DoctorEditTimeTableForm";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import { getCommonAPI } from "../../services/commonAPIServices";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { editMockUserDoctor } from "../../services/DoctorServices";

const schema = yup.object().shape({
  permissionStatus: yup.string().required("การอนุมัติการลาห้ามว่าง"),
});

export default function DoctorsDetailEditForm({
  onCancel,
  onSubmitSuccess,
  id,
  editData,
  documentType,
}) {
  const [keyTab, setKeyTab] = useState("General");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={keyTab}
        onSelect={(k) => setKeyTab(k)}
        className="mb-3"
        justify
      >
        <Tab eventKey="General" title="General">
          {/* Tab content for General */}
          <DoctorEditGeneralForm
            onCancel={onCancel}
            onSubmitSuccess={onSubmitSuccess}
            id={id}
            editData={editData}
          />
        </Tab>
        <Tab eventKey="profession" title="Document Profile">
          {/* Tab content for Shipping Addr. */}
          <DoctorEditProfessionForm
            onCancel={onCancel}
            onSubmitSuccess={onSubmitSuccess}
            id={id}
            editData={editData}
            documentType={documentType}
          />
        </Tab>
        <Tab eventKey="time_table" title="Time table">
          {/* Tab content for Booking */}
          <DoctorEditTimeTableForm
            onCancel={onCancel}
            onSubmitSuccess={onSubmitSuccess}
            editData={editData}
          />
        </Tab>
      </Tabs>
    </>
  );
}
