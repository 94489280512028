import React from "react";
import * as yup from "yup";
import { Col, Form, Row } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editCommonAPI, getCommonAPI } from "../../services/commonAPIServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import SaveButton from "../../components/Button/SaveButton";
import CancelButton from "../../components/Button/CancelButton";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import {createUserAppeal, getMemberInfo } from "../../services/AppealServices";
import GenericDateTimePickerInput from "../../components/Input/GenericDateTimePickerInput";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import {StatusTypeOptions } from "../../data/SelectOptions";
const schema = yup.object().shape({
    subject: yup.string().required("ชื่อเรื่องห้ามว่าง"),
});

export default function AppealDetailEditGeneralForm({
    onCancel,
    onSubmitSuccess,
    id,
    m_appeal_cat_id,
    status
    }) {
        const {
        register,
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
        } = useForm({
        resolver: yupResolver(schema),
        });

        const [memberList, setMemberList] = React.useState([]);

        const memberInfoList = React.useCallback(async () => {
        const { success, data, error } = await getMemberInfo({});
            if (success) {
                setMemberList(prepareMemberInfoList(data.records));
            } else {
                ShowMessageAndExpire("Failed to load data.", error?.message, "error");
            }
            }, []);
        
        React.useEffect(() => {
            memberInfoList();
        }, [memberInfoList]);

        const prepareMemberInfoList = (datas) => {
            let listsMember = [];
            for (const data of datas) {
                let memberListData = {
                id: data.id,
                label:data.name,
                value: {
                    firstName: data.name,
                },
                };
                if (
                    m_appeal_cat_id === data.id
                ) {
                setValue("m_appeal_cat_id", memberListData, { shouldValidate: true });
                }
                listsMember.push(memberListData);
            }
            return listsMember;
            };

        // console.log(id);
        const loadMemberDetail = React.useCallback(async () => {
            let url_api = "/appeal/" + id;
            let p_data = {};
            let { success, data, error } = await getCommonAPI({
                url_api,
                p_data,
            });
            if (success) {
                setValue("subject", data.records.subject);
                setValue("details", data.records.description);
                setValue("edit_details", data.records.description_fixed);
                // setValue("status", data.records.status);
            } else {
                console.log(error);
            }
            }, [id, setValue]);

            React.useEffect(() => {
                loadMemberDetail();
            }, [loadMemberDetail]);
    
        const onSubmit = async (inputData) => {
            const url_api = "/appeal/" + id;
            console.log(inputData);
            const p_data = {
                subject: inputData.subject,
                description: inputData.details,
                description_fixed: inputData.edit_details,
                m_appeal_cat_id: parseInt(inputData.m_appeal_cat_id?.id),
                status: parseInt(inputData.status),
            };
            const { success, data, error } = await editCommonAPI({
                url_api,
                p_data,
            });
            if (success) {
                onSubmitSuccess();
                ShowMessageAndExpire("Data updated successfully.", "", "success");
            } else {
                console.log(error);
                var errorMessages = [];
                if (typeof error.message === "string") {
                errorMessages.push(error.message);
                } else {
                for (var propertyName in error.message) {
                    var errorMessage = error.message[propertyName];
                    errorMessages.push(errorMessage);
                }
                }
                var concatenatedErrors = errorMessages.join("\n");
        
                ShowMessageAndExpire("Failed to update data.", concatenatedErrors, "error");
            }
            };
            return (
                <>
                <Form onSubmit={handleSubmit(onSubmit)}>
                <h5>เรื่องที่รับแจ้ง</h5>
        <hr/>
        <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                    control={control}
                    name="m_appeal_cat_id"
                    label="หมวดหมู่"
                    placeholder=""
                    defaultValue={null}
                    options={memberList}
                    error={errors.m_appeal_cat_id}
                    required
                />
            </Form.Group>
            <Col>
            </Col>
            <Form.Group as={Col} controlId="formBasicName">
                <GenericDateTimePickerInput
                register={register}
                name="notification_date"
                label="วันที่แจ้ง"
                placeholder=""
                defaultValue=""
                required
                error={errors.notification_date}
                />
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                register={register}
                name="subject"
                label="ชื่อเรื่อง"
                placeholder="Text....."
                defaultValue=""
                required
                error={errors.subject}
                />
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
                <GenericTextAreaInput
                register={register}
                name="details"
                label="รายละเอียด"
                placeholder=""
                defaultValue=""
                required
                error={errors.details}
                />
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
                <GenericRadioInput
                    register={register}
                    name="status"
                    radios={StatusTypeOptions}
                    label={"สถานะ"}
                    defaultChecked={[status]}
                />
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
                <GenericTextAreaInput
                register={register}
                name="edit_details"
                label="รายละเอียดการแก้ไข"
                placeholder=""
                defaultValue=""
                required
                error={errors.edit_details}
                />
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
                <GenericDateTimePickerInput
                register={register}
                name="edited_by"
                label="แก้ไขเมื่อ"
                placeholder=""
                defaultValue=""
                required
                error={errors.edited_by}
                />
            </Form.Group>
            <Col>
            </Col>
            <Col>
            </Col>
            </Row>
                <Row className="mb-3">
                    <Col>
                        <CancelButton onClick={onCancel} />
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "end" }}>
                        <SaveButton />
                    </Col>
                </Row>
                </Form>
            </>
            );
    }