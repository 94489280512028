import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  createMockProduct,
  createProductCategory,
  editMockProduct,
  editProductCategory,
} from "../../services/ProductManagenentServices";
import { Col, Form, Row } from "react-bootstrap";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import GenericTextInput from "../../components/Input/GenericTextInput";
import SaveButton from "../../components/Button/SaveButton";
import CancelButton from "../../components/Button/CancelButton";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import { languageOptions, userStatusOptions, userStatusOptionsAct } from "../../data/SelectOptions";
import {
  createCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices";

import DeleteButton from "../../components/Button/DeleteButton";

import {
  uploadHospitalInfoImage,
  deleteHospitalInfoImage
} from "../../services/HospitalServices";

const schema = yup.object().shape({
  hospital_code: yup.string().required("ห้ามว่าง"),
  hospital_name: yup.string().required("ห้ามว่าง"),
  status: yup.object().required("ห้ามว่าง"),
  number: yup.string().required("ห้ามว่าง"),
  street: yup.string().required("ห้ามว่าง"),
  province: yup.object().required("ห้ามว่าง"),
  district: yup.object().required("ห้ามว่าง"),
  sub_district: yup.object().required("ห้ามว่าง"),
  post_code: yup.string().required("ห้ามว่าง"),
  phone: yup.string().required("ห้ามว่าง"),
  email: yup.string().email("อีเมลไม่ถูกต้อง").required("อีเมลห้ามว่าง"),
  line: yup.string().required("ห้ามว่าง"),
  contact_name: yup.string().default('').nullable(),
  contact_phone: yup.string().default('').nullable(),
  remark: yup.string().default('').nullable()
});

export default function CreateEditHospitalForm({ onCancel, onCreateSuccess }) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const hospitalId = null;
  const [previewImage, setPreviewImage] = React.useState('');
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const [isDirtyData, setisDirtyData] = React.useState(false);

  const [provinceList, setProvinceList] = React.useState([]);
  const [districtList, setDistrictList] = React.useState([]);
  const [subdistrict, setSubdistrict] = React.useState([]);

  const provinceInfoList = React.useCallback(async () => {
    let url_api = "/m_province";
    let p_data = {};
    const { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setProvinceList(prepareProvinceList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareProvinceList = (datas) => {
    let listsProvince = [];
    for (const data of datas) {
      const id = data.id;
      const name = data.name;
      const province = {
        id: id,
        label: name,
        value: {
          id: id,
          name: name,
        },
      };
      listsProvince.push(province);
    }

    return listsProvince;
  };

  const districtInfoList = React.useCallback(async (provinceId) => {
    let url_api = "/m_district";
    let p_data = {
      "filter.m_province_id": provinceId,
    };
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setDistrictList(prepareDistrictList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareDistrictList = (datas) => {
    let listsDistrict = [];
    if (Array.isArray(datas)) {
      for (const data of datas) {
        const id = data.id;
        const name = data.name;
        const province = data.m_province_id;
        const district = {
          id: id,
          label: name,
          value: {
            id: id,
            name: name,
            province: province,
          },
        };
        listsDistrict.push(district);
      }
    } else {
      console.log("datas is not iterable.");
    }

    return listsDistrict;
  };

  const loadSubdistrict = React.useCallback(async (districtId) => {
    let url_api = "/m_subdistrict";
    let p_data = {
      "filter.m_district_id": districtId,
    };
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setSubdistrict(prepareSubdistrict(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareSubdistrict = (datas) => {
    let listSubdistrict = [];
    for (const data of datas) {
      const id = data.id;
      const name = data.name;
      const postcode = data.postcode;
      const district = data.m_district_id;
      let subdistrict = {
        id: id,
        label: name,
        value: {
          id: id,
          name: name,
          postcode: postcode,
          district: district,
        },
      };
      listSubdistrict.push(subdistrict);
    }
    return listSubdistrict;
  };

  const handleChangeProvince = (e) => {
    if (e?.value) {
      districtInfoList(e.value.id);
      setValue("district", null);
      setSubdistrict([]);
      setValue("sub_district", null);
      setValue("post_code", null);
    } else {
      setDistrictList([]);
      setValue("district", null);
      setSubdistrict([]);
      setValue("sub_district", null);
      setValue("post_code", null);
    }
  };

  const handleChangeDisList = (e) => {
    if (e?.value) {
      loadSubdistrict(e.value.id);
      setSubdistrict([]);
      setValue("sub_district", null);
      setValue("post_code", null);
    } else {
      setSubdistrict([]);
      setValue("sub_district", null);
      setValue("post_code", null);
    }
  };

  const handleChangeSubDisList = (e) => {
    if (e?.value) {
      setValue("post_code", e.value?.postcode, { shouldValidate: true });
    } else {
      setValue("post_code", null);
    }
  };

  const onSubmit = async (inputData) => {
    console.log("inputData", inputData);
    let url_api = "/hospital_info";
    let p_data = {
      code: inputData.hospital_code,
      name: inputData.hospital_name,
      status: inputData.status?.id,
      phone: inputData.phone,
      email: inputData.email,
      acc_line: inputData.line,
      partner: 0,
      contact_name: inputData.contact_name,
      remark: inputData.remark,
      contact_phone: inputData.contact_phone
    };
    console.log(p_data);
    let { success, data, error } = await createCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      console.log('hospitalid=' + data.id)
      // add image to db
      if (inputData.image[0] && data.id) {
        let errorMessage = await submitImage(inputData.image[0], data.id);
        if (!errorMessage) {
          //ShowMessageAndExpire("Photo updated successfully.", "", "success");
        } else {
          ShowMessageAndExpire(
            "Update Photo Failed",
            JSON.stringify(errorMessage?.message),
            "error"
          );
        }
      }

      url_api = "/address";
      p_data = {
        addressno: inputData.number,
        street: inputData.street,
        phone: inputData.phone,
        m_subdistrict_id: inputData.sub_district?.id,
        postcode_id: inputData.post_code,
        user_type: 2,
        any_id: data.id,
        address_type: 0,
        default: 1,
      };
      ({ success, data, error } = await createCommonAPI({
        url_api,
        p_data,
      }));
      if (success) {
        console.log("add address success");
      } else {
        console.log(error);
      }
      onCreateSuccess();
      ShowMessageAndExpire("Data created successfully.", "Data Success", "success");
    } else {
      console.log(error);
      var errorMessages = [];
      if (typeof error.message === "string") {
        errorMessages.push(error.message);
      } else {
        for (var propertyName in error.message) {
          var errorMessage = error.message[propertyName];
          errorMessages.push(errorMessage);
        }
      }
      var concatenatedErrors = errorMessages.join("\n");

      ShowMessageAndExpire("Failed to create data.", concatenatedErrors, "error");
    }
  };

  const handleImageChange = async (event) => {
    console.log("handleImageChange");
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    await reader.readAsDataURL(file);
    // if (hospitalId && file) {
    //   let errorMessage = await submitImage(file, hospitalId);
    //   if (!errorMessage) {
    //     ShowMessageAndExpire("Photo updated successfully.", "", "success");
    //     setisDirtyData(true);
    //   } else {
    //     ShowMessageAndExpire(
    //       "Update Photo Failed",
    //       JSON.stringify(errorMessage?.message),
    //       "error"
    //     );
    //   }
    // }
  };

  const submitImage = async (imageFile, id) => {
    const { success, error } = await uploadHospitalInfoImage({
      id,
      imageFile
    });
    if (success) {
      return;
    } else {
      return error;
    }
  };



  React.useEffect(() => {
    provinceInfoList();
  }, [provinceInfoList]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4>ข้อมูลทั่วไป</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="hospital_code"
              label="รหัสโรงพยาบาล"
              placeholder="รหัสโรงพยาบาล"
              defaultValue=""
              required
              error={errors.hospital_code}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="hospital_name"
              label="ชื่อโรงพยาบาล"
              placeholder="ชื่อโรงพยาบาล"
              defaultValue=""
              required
              error={errors.hospital_name}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="status"
              label="สถานะ"
              placeholder="สถานะ"
              defaultValue={""}
              required
              options={userStatusOptionsAct}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="image"
              label="รูปภาพ"
              placeholder="รูปภาพ"
              defaultValue={null}
              type="file"
              error={errors.image}
              onChange={handleImageChange}
            />
            {previewImage
              && (
              <>
                <img src={previewImage} alt="Preview" style={{ marginTop: '10px', width: '400px'}} />
              </>
              )
            }
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <h4>ข้อมูลที่อยู่</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="number"
              label="เลขที่"
              placeholder="เลขที่"
              defaultValue=""
              required
              error={errors.number}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="street"
              label="ถนน"
              placeholder="ถนน"
              defaultValue=""
              required
              error={errors.street}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="province"
              label="จังหวัด"
              placeholder="จังหวัด"
              options={provinceList}
              required
              onChange={handleChangeProvince}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="district"
              label="อำเภอ / เขต"
              placeholder="อำเภอ / เขต"
              options={districtList}
              required
              onChange={handleChangeDisList}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="sub_district"
              label="ตำบล / แขวง"
              placeholder="ตำบล / แขวง"
              options={subdistrict}
              required
              onChange={handleChangeSubDisList}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="post_code"
              label="รหัสไปรษณีย์"
              placeholder="รหัสไปรษณีย์"
              defaultValue=""
              required
              error={errors.post_code}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <h4>ข้อมูลติดต่อ</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="email"
              label="อีเมล"
              placeholder="อีเมล"
              defaultValue=""
              required
              error={errors.email}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="phone"
              label="เบอร์ติดต่อ"
              placeholder="เบอร์ติดต่อ"
              defaultValue=""
              required
              error={errors.phone}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="line"
              label="Line"
              placeholder="Line"
              defaultValue=""
              required
              error={errors.line}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="contact_name"
              label="ชื่อผู้ติดต่อ"
              placeholder="ชื่อผู้ติดต่อ"
              defaultValue=""
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="contact_phone"
              label="เบอร์ผู้ติดต่อ"
              placeholder="เบอร์ผู้ติดต่อ"
              defaultValue=""
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
          <GenericTextInput
              register={register}
              name="remark"
              label="หมายเหตุ"
              placeholder="หมายเหตุ"
              defaultValue=""
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
