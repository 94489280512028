import React from "react";
import { LeaveManagementInfoColumn } from "../data/Columns";
import { Card, Col, Container, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import SelectFilter from "../components/SelectFilter";
import { filterPermissionStatusOptions } from "../data/SelectOptions";
import { deleteLeaveInfo, getLeaveInfo } from "../services/LeaveManagement";
import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import LeaveForm from "../features/Form/LeaveForm";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import { DoctorLeaveApproveStatus } from "../data/Enumerator";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";

const allApproveStatus = null;

export default function LeaveManagement() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [approveStatus, setApproveStatus] = React.useState(allApproveStatus);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [tableData, setTableData] = React.useState([]);
  const columnsData = LeaveManagementInfoColumn;

  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();

  const loadLeaveManagementInfo = React.useCallback(
    async (
      currentPage,
      pageSize,
      approveStatus,
      keyword,
      startDate,
      endDate
    ) => {
      let dateFilter = null;
      if (startDate && endDate) {
        dateFilter = `$btw:${startDate} 00:00:00, ${endDate} 23:59:59`;
      }
      const { success, data, error } = await getLeaveInfo({
        params: {
          page: currentPage,
          limit: pageSize,
          search: keyword,
          "filter.status": approveStatus,
          "filter.date_from": dateFilter,
          "filter._relations": "rel_doctor_info_id",
        },
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        ShowMessageAndExpire(
          "Failed to load data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    []
  );

  React.useEffect(() => {
    loadLeaveManagementInfo(1, 10, allApproveStatus);
  }, [loadLeaveManagementInfo]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadLeaveManagementInfo(
        page,
        pageSize,
        approveStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [
      loadLeaveManagementInfo,
      pageSize,
      approveStatus,
      keyword,
      startDate,
      endDate,
    ]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadLeaveManagementInfo(
        1,
        pageSize,
        approveStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadLeaveManagementInfo, approveStatus, keyword, startDate, endDate]
  );

  const loadPageOnUserStatusChange = React.useCallback(
    async (approveStatus) => {
      setCurrentPage(1);
      setApproveStatus(approveStatus);
      await loadLeaveManagementInfo(
        1,
        pageSize,
        approveStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadLeaveManagementInfo, pageSize, keyword, startDate, endDate]
  );

  const handleSelectedDate = React.useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate);
      setEndDate(endDate);
      setCurrentPage(1);
      await loadLeaveManagementInfo(
        1,
        pageSize,
        approveStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadLeaveManagementInfo, pageSize, approveStatus, keyword]
  );

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadLeaveManagementInfo(
        1,
        pageSize,
        approveStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadLeaveManagementInfo, pageSize, approveStatus]
  );

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setEditData(data);
  };

  const handleCloseEdit = () => {
    setIsOpenForm(false);
    setEditData(null);
  };

  const handleEdit = React.useCallback(async () => {
    handleCloseEdit();
    loadLeaveManagementInfo(
      currentPage,
      pageSize,
      approveStatus,
      keyword,
      startDate,
      endDate
    );
  }, [
    loadLeaveManagementInfo,
    currentPage,
    pageSize,
    approveStatus,
    keyword,
    startDate,
    endDate,
  ]);

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const { success, data, error } = await deleteLeaveInfo({
        pathParams: id,
      });
      if (success) {
        ShowMessageAndExpire("Data deleted successfully.", "", "success");
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadLeaveManagementInfo(
          tempCurrentPage,
          pageSize,
          approveStatus,
          keyword
        );
      } else {
        handleCloseDeleteConfirm();
        ShowMessageAndExpire(
          "Failed to delete data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [
      loadLeaveManagementInfo,
      total,
      currentPage,
      pageSize,
      approveStatus,
      keyword,
    ]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              editData
                ? `ขออนุมัติการลา ${editData?.doctorName} (${editData.requestDate})`
                : "Leave Mangement"
            }
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`leave id = ${deleteData?.id}, ชื่อหมอ= ${deleteData?.doctorName}, สถานะการอนุมัติ= ${deleteData?.approveStatus} `}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenForm ? (
                  <>
                    <LeaveForm
                      onCancel={handleCloseEdit}
                      onSubmitSuccess={handleEdit}
                      approveStatusDefaultValue={editData?.approveStatus}
                      doctorNameDefaultValue={editData?.doctorName}
                      leaveDateDefaultValue={editData?.leaveDate}
                      remarkDefaultValue={editData?.remark}
                      id={editData?.id}
                    />
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Filter : ชื่อหมอ"
                          onSearch={handleSearch}
                          defaultValue={keyword}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="สถานะการอนุมัติ"
                          placeholder={"สถานะการอนุมัติ"}
                          defaultValue={filterPermissionStatusOptions.find(
                            (e) => e.value === allApproveStatus
                          )}
                          value={filterPermissionStatusOptions.find(
                            (e) => e.value === approveStatus
                          )}
                          options={filterPermissionStatusOptions}
                          onChange={loadPageOnUserStatusChange}
                        />

                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={startDate ? new Date(startDate) : null}
                          defaultEnd={endDate ? new Date(endDate) : null}
                          placeholder="เลือกวันที่ลา"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = [];
  for (const [idx, data] of datas.entries()) {
    let tempApproveStatus;
    if (data.status === DoctorLeaveApproveStatus.Waiting) {
      tempApproveStatus = "รอดำเนินการ";
    } else if (data.status === DoctorLeaveApproveStatus.Inactive) {
      tempApproveStatus = "Inactive";
    } else if (data.status === DoctorLeaveApproveStatus.NotApprove) {
      tempApproveStatus = "ไม่อนุมัติ";
    } else if (data.status === DoctorLeaveApproveStatus.Approve) {
      tempApproveStatus = "อนุมัติ";
    } else {
      tempApproveStatus = "-";
    }
    const tempTableData = {
      no: startNo + idx,
      id: data.id,
      requestDate: data.create_date,
      doctorName: `${data.rel_doctor_info_id?.name} ${data.rel_doctor_info_id?.lname}`,
      leaveDate: `${data.date_from} to ${data.date_to}`,
      approveStatus: tempApproveStatus,
      approveBy: data.approve_by,
      remark: data.remark,
    };
    const buttonData = {
      id: data.id,
      requestDate: data.create_date,
      doctorName: `${data.rel_doctor_info_id?.name} ${data.rel_doctor_info_id?.lname}`,
      leaveDate: `${data.date_from} to ${data.date_to}`,
      approveStatus: [data.status],
      approveBy: data.approve_by,
      remark: data.remark,
    };
    tableData.push({
      ...tempTableData,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(buttonData);
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(buttonData);
            }}
          />
        </>
      ),
    });
  }
  return tableData;
};
