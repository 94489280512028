import React from "react";
import ReactDatePicker from "react-datepicker";
import { dateFormat, dateTimeFormat } from "../../data/Config";
import { Form, Button } from "react-bootstrap";

export default function ReactDateTimePicker({
  selectedDate,
  setSelectedDate,
  type = "date",
  startYear = 1980,
  endYear,
  placeholder = "เลือกวันที่",
  error,
  disabled,
  readOnly,
}) {
  const refCustomInput = React.useRef();
  const [years, setYears] = React.useState([]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const setYearsSelect = () => {
    let years = [];
    if (!endYear) {
      endYear = new Date().getFullYear();
    }
    let tempStartYear = startYear;
    while (tempStartYear <= endYear) {
      years.push(tempStartYear++);
    }
    setYears(years);
  };

  React.useEffect(() => {
    setYearsSelect();
  }, []);

  return (
    <div>
      <ReactDatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </Button>
            <Form.Select
              value={date.getFullYear()}
              onChange={({ target: { value } }) => {
                changeYear(value);
              }}
            >
              (
              <>
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </>
              )
            </Form.Select>

            <Form.Select
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) => {
                changeMonth(months.indexOf(value));
              }}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>

            <Button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </Button>
          </div>
        )}
        customInput={
          <CustomInput error={error} disabled={disabled} ref={refCustomInput} />
        }
        selected={selectedDate}
        onChange={(date) => {
          setSelectedDate(date);
        }}
        showTimeInput={type === "dateTime"}
        dateFormat={type === "dateTime" ? dateTimeFormat : dateFormat}
        shouldCloseOnSelect={type !== "dateTime"}
        isClearable={!(disabled || error)}
        clearButtonClassName="customClearButton"
        todayButton="Today"
        placeholderText={placeholder}
        disabled={disabled}
        readOnly={readOnly}
      />
    </div>
  );
}

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <>
      <Form.Control
        isInvalid={props.error}
        {...props}
        className="reactDateTimePicker"
        ref={ref}
      />
      <Form.Control.Feedback type="invalid">
        {props.error?.message}
      </Form.Control.Feedback>
    </>
  );
});
