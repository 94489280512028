import React from "react";
import * as yup from "yup";
import { Col, Form, Modal, Row } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import CancelButton from "../../components/Button/CancelButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SaveButton from "../../components/Button/SaveButton";
import { getProduct } from "../../services/ProductManagenentServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import GenericSelectInput from "../../components/Input/GenericSelectInput";

const schema = yup.object().shape({
  product: yup.object().required("ห้ามว่าง"),
  quantity: yup.number().required("ห้ามว่าง").typeError("โปรดระบุเป็นตัวเลข"),
});

export default function AddProductModal({
  show,
  setShow,
  onSave,
  onCancel,
  productDefaultValue,
  quantityDefaultValue,
}) {
  const [productSelect, setProductSelect] = React.useState([]);
  const [firstLoad, setFirstLoad] = React.useState(true);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const refresh = () => {
    reset();
  };

  const onClose = async () => {
    setShow(false);
    onCancel();
    refresh();
  };

  const onSubmit = async (inputData) => {
    setShow(false);
    onSave(inputData);
    refresh();
  };

  const prepareProductForSelect = React.useCallback(
    (datas) => {
      let listsProduct = [];
      for (const [idx, data] of datas.entries()) {
        let tempSelect = {
          id: idx,
          label: data.product_name || "-",
          value: { id: data.id, price: +data.price1 },
        };
        listsProduct.push(tempSelect);
        if (firstLoad && data.id === productDefaultValue) {
          setValue("product", tempSelect, { shouldValidate: true });
        }
      }
      return listsProduct;
    },
    [firstLoad, productDefaultValue, setValue]
  );

  const loadProduct = React.useCallback(async () => {
    const { success, data, error } = await getProduct({
      params: { select: "id,product_name,price1" },
    });
    if (success) {
      setProductSelect(prepareProductForSelect(data.records));
    } else {
      ShowMessageAndExpire(
        "Load Product Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [prepareProductForSelect]);

  React.useEffect(() => {
    loadProduct();
    setFirstLoad(false);
  }, [loadProduct]);

  return (
    <Modal
      show={show}
      onHide={() => onClose()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>เพิ่มรูปภาพ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericSelectInput
                control={control}
                name="product"
                label="Product"
                placeholder="Product"
                defaultValue={null}
                required
                options={productSelect}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="quantity"
                label="จำนวน"
                placeholder="จำนวน"
                defaultValue={quantityDefaultValue || 1}
                required
                type="number"
                min="1"
                error={errors.quantity}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                name="price"
                label="ราคา"
                placeholder="0"
                defaultValue={null}
                value={watch("product")?.value?.price || 0}
                readOnly
                disabled
                plaintext
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                name="total"
                label="สรุปราคา"
                placeholder="0"
                defaultValue={null}
                value={
                  (+watch("product")?.value?.price &&
                    +watch("quantity") &&
                    watch("product")?.value?.price * watch("quantity")) ||
                  0
                }
                readOnly
                disabled
                plaintext
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Col>
              <CancelButton onClick={() => onClose()} />
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }}>
              <SaveButton />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
