import React from "react";
import { Button, Form } from "react-bootstrap";
import feather from "feather-icons/dist/feather";
import GenericButton from "./Button/GenericButton";

export default function Searching({
  placeholder = "ค้นหา",
  onSearch,
  defaultValue,
}) {
  React.useEffect(() => {
    feather.replace();
  }, []);

  const [localKeyword, SetLocalkeyword] = React.useState(defaultValue);

  return (
    <>
      <Form.Control
        placeholder={placeholder}
        value={localKeyword}
        onChange={(e) => {
          SetLocalkeyword(e.target.value);
          if (e.key) {
            onSearch(localKeyword);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSearch(localKeyword);
          }
        }}
        style={{ width: "30rem" }}
      />
      <GenericButton
        className="searching"
        variant="primary"
        onClick={() => {
          onSearch(localKeyword);
        }}
      >
        <i data-feather="search" />
        {"Search"}
      </GenericButton>
    </>
  );
}
