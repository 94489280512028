import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getRecord = async ({ page, pageSize, search,dateStart, dateEnd  }) => {
  try {
    let datefilter;
    if( dateStart && dateEnd ){
      datefilter ='$btw:'+dateStart+' 00:00:00,'+dateEnd+' 23:59:59';
    }
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/report/worklog_transaction",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit: pageSize,
          search,
          'filter.action_datetime': datefilter
        },
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};


export const getMockWorkLogTimeSheetDatatable = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 2,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          id: "1",
          transectionID: "LOG001",
          docName: "น.สพ.ชนะ รักดี(รพ.เอ)",
          transectionDatetime: "2023-04-03 12:00:00",
          workHr:1,
          workVal:500,
          incomeType: 0,
          status: 1,
          cusName:"คุณอมรรัตน์ มั่นคง",
          expensePlatform:150,
          expenseHospital:0,
          expenseDoc:350
        },
        {
          id: "2",
          transectionID: "LOG002",
          docName: "น.สพ.สมศักดิ์ รักดี(รพ.บี)",
          transectionDatetime: "2023-04-03 12:00:00",
          workHr:30,
          workVal:250,
          incomeType: 1,
          status: 2,
          cusName:"คุณอมรรัตน์ มั่นคง",
          expensePlatform:150,
          expenseHospital:100,
          expenseDoc:0
        },
        {
          id: "3",
          transectionID: "LOG003",
          docName: "น.สพ.มานะ คงดี(รพ.ซี)",
          transectionDatetime: "2023-04-03 12:00:00",
          workHr:15,
          workVal:150,
          incomeType: 1,
          cusName:"คุณอมรรัตน์ มั่นคง",
          status: 0,
          expensePlatform:150,
          expenseHospital:0,
          expenseDoc:0
        },
        
      ],
    },
    error: null,
  };
};
