import React from "react";
import * as yup from "yup";
import { Col, Form, Modal, Row } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import CancelButton from "../../components/Button/CancelButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SaveButton from "../../components/Button/SaveButton";
import { uploadPhotoSetProduct } from "../../services/ProductManagenentServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import CreateButton from "../../components/Button/CreateButton";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import {
  createCommonAPI,
  editCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices";

const schema = yup.object().shape({
  pet_name: yup.string().required("Pet Name is required"),
  owner_name: yup.object().required("Owner Name is required"),
  // owner_name: yup.string().when("owner_name", {
  //   is: "0",
  //   then: () => yup.object().required("Owner Name is required"),
  // }),
  owner_phone: yup.string().required("Owner Phone is required"),
  pet_category: yup.object().required("Pet Category is required"),
  date_birth: yup.string().required("Date of birth is required"),
  // last_service_date: yup.string().required("Last Service Date is required"),
  // remark: yup.string().required("Remark is required"),
});

export default function EditPetsInfoSetModal({
  show,
  setShow,
  onSave,
  editData,
}) {
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log("editData PetsInfoSetModal ", editData);

  const [dataPetDetail, setDataPetDetail] = React.useState([]);
  const [petCategoryList, setPetCategoryList] = React.useState([]);
  const [petCategory, setPetCategory] = React.useState(null);
  const [memberList, setMemberList] = React.useState([]);

  const loadPetDetail = React.useCallback(async () => {
    let url_api = "/pet_info/" + editData.id;
    let p_data = {};
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setDataPetDetail(data.records);
      setValue("pet_name", data.records?.name);
      setValue("member_info_id", data.records?.member_info_id);
      setValue("mem_phone", data.records?.phone);
      setValue("pet_category_id", data.records?.m_pet_type_id);
      setValue("date_birth", new Date(data.records?.brithday));
      setValue("last_service_date", new Date(data.records?.last_service));
      setValue("remark", data.records?.remark);
      // get pet category
      url_api = "/m_pet_type/datatable";
      p_data = {
        // "filter.id": data.records?.m_pet_type_id,
        "filter.status": '$not:0',
      };
      ({ success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      }));
      if (success) {
        setPetCategoryList(preparePetCategoryList(data.records));
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
      // get memberInfoList
      url_api = "/member_info";
      p_data = {};
      ({ success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      }));
      if (success) {
        setMemberList(prepareMemberInfoList(data.records));
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  React.useEffect(() => {
    loadPetDetail();
  }, [loadPetDetail]);

  const prepareMemberInfoList = (datas) => {
    let listsMember = [];
    for (const data of datas) {
      const id = data.id;
      const name = data.name + " " + data.lname;
      const phone = data.phone;
      const email = data.email;
      let member = {
        id: id,
        label: id + " - " + name,
        value: {
          id: id,
          firstName: data.name,
          lastName: data.lname,
          phone: phone,
          email: email,
        },
      };
      listsMember.push(member);
      const memberInfoId = getValues("member_info_id");
      const memberPhone = getValues("mem_phone");
      if (memberInfoId === data.id) {
        console.log("member ", member);
        setValue("owner_name", member, { shouldValidate: true });
        setValue("owner_phone", memberPhone, { shouldValidate: true });
      }
    }
    return listsMember;
  };

  const handleChangeCustomerList = (event) => {
    setValue("owner_name", event, { shouldValidate: true });
    setValue("owner_phone", event.value.phone, { shouldValidate: true });
  };

  const preparePetCategoryList = (datas) => {
    let categoryList = [];
    for (const data of datas) {
      const id = data.id;
      const category = data.name;
      let pet_cat = {
        id: id,
        label: id + " - " + category,
        value: {
          id: id,
          name: category,
        },
      };
      categoryList.push(pet_cat);
      const petCategoryId = getValues("pet_category_id");
      if (petCategoryId === data.id) {
        setValue("pet_category", pet_cat, { shouldValidate: true });
      }
    }
    return categoryList;
  };

  console.log("dataPetDetail ", dataPetDetail);

  const onSubmit = async (inputData) => {
    console.log("inputData ", inputData);
    const url_api = "/pet_info/" + editData.id;
    const p_data = {
      name: inputData.pet_name,
      ownname:
        inputData.owner_name.value.firstName +
        " " +
        inputData.owner_name.value.lastName,
      phone: inputData.owner_phone,
      m_pet_type_id: inputData.pet_category.id,
      member_info_id: inputData.owner_name.id,
      brithday: new Date(inputData.date_birth).toLocaleString("sv"),
      last_service: new Date(inputData.last_service_date).toLocaleString("sv"),
      remark: inputData.remark,
      status: dataPetDetail.status,
      pet_type_etc: dataPetDetail.pet_type_etc
        ? dataPetDetail.pet_type_etc
        : "",
      sex: dataPetDetail.sex ? dataPetDetail.sex : 2, // 2 = ไม่มีเพศ
      weight: dataPetDetail.weight ? dataPetDetail.weight : 0,
    };
    console.log("p_data ", p_data);
    const { success, data, error } = await editCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      onSave();
      refresh();
      ShowMessageAndExpire("Data updated successfully.", "", "success");
    } else {
      console.log(error);
      var errorMessages = [];
      if (typeof error.message === "string") {
        errorMessages.push(error.message);
      } else {
        for (var propertyName in error.message) {
          var errorMessage = error.message[propertyName];
          errorMessages.push(errorMessage);
        }
      }
      var concatenatedErrors = errorMessages.join("\n");

      ShowMessageAndExpire("Failed to update data.", concatenatedErrors, "error");
    }
  };

  const refresh = () => {
    reset();
  };

  const onCancel = async () => {
    setShow(false);
    refresh();
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => onCancel()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>แก้ไขสัตว์เลี้ยง</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="pet_name"
                label="Pet Name"
                placeholder="Pet Name"
                defaultValue=""
                required
                error={errors.pet_name}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericSelectInput
                control={control}
                name="pet_category"
                label="Pet Category"
                placeholder="Pet Category"
                options={petCategoryList}
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericSelectInput
                control={control}
                name="owner_name"
                label="Owner Name"
                placeholder="Owner Name"
                options={memberList}
                onChange={handleChangeCustomerList}
                required
                error={errors.owner_name}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="owner_phone"
                label="Owner Phone"
                placeholder="Owner Phone"
                defaultValue=""
                required
                readOnly={true}
                error={errors.owner_phone}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericDateTimePickerInput2
                control={control}
                name="date_birth"
                label="Date of birth"
                placeholder="Date of birth"
                type="date"
                required
                error={errors.date_birth}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericDateTimePickerInput2
                control={control}
                name="last_service_date"
                label="Last Service Date"
                placeholder="Last Service Date"
                type="date"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextAreaInput
                register={register}
                name="remark"
                label="Remark"
                placeholder="Remark"
                defaultValue={""}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName"></Form.Group>
          </Row>
          <Row className="mb-3">
            <Col>
              <CancelButton onClick={onCancel} />
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }}>
              <SaveButton />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
