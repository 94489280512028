import {
  BookingStatus,
  CallStatus,
  DoctorLeaveApproveStatus,
  EmsVanTypeDbValue,
  EnableStatusDbValue,
  LanguageForm,
  NotificationStatus,
  NotificationType,
  PurchaseStatus,
  RevenueStatus,
  StatusDbValue,
  UserStatus,
  carrierType,
} from "./Enumerator"

export const userRoleOptions = [
  { id: 1, label: "Admin", value: "admin" },
  { id: 2, label: "User", value: "user" },
]

export const userStatusOptions = [
  //   { id: 1, label: "Enable", value: EnableStatusDbValue.Enable },
  //   { id: 2, label: "Disable", value: EnableStatusDbValue.Disable },
  { id: 0, label: "Inactive", value: UserStatus.Inactive },
  { id: 1, label: "Active", value: UserStatus.Active },
  { id: 2, label: "Waiting Verify", value: UserStatus.Waiting },
  { id: 3, label: "Expired", value: UserStatus.Expired },
]

export const userStatusOptionsAll = [
  { label: "All", value: null },
  { id: 0, label: "Inactive", value: UserStatus.Inactive },
  { id: 1, label: "Active", value: UserStatus.Active },
]

export const userStatusOptionsAct = [
  { id: 0, label: "Inactive", value: UserStatus.Inactive },
  { id: 1, label: "Active", value: UserStatus.Active },
]

export const filterUserStatusOptions = [
  { label: "All", value: null },
  ...userStatusOptions,
]

export const StatusTypeOptions = [
  { id: 1, label: "รับแจ้ง", value: 1 },
  { id: 2, label: "ระหว่างดำเนินการ", value: 2 },
  { id: 3, label: "แก้ไขแล้ว", value: 3 },
]

export const filterStatusTypeOptions = [
  { label: "All", value: null },
  ...StatusTypeOptions,
]

export const enableDisableStatusColumnOptions = [
  { id: 1, label: "Enable", value: EnableStatusDbValue.Enable },
  { id: 2, label: "Disable", value: EnableStatusDbValue.Disable },
]

export const rolePermissionStatusColumnOptions = [
  { id: 1, label: "Enable", value: EnableStatusDbValue.Enable },
  { id: 2, label: "Disable", value: EnableStatusDbValue.Disable },
]

export const filterRolePermissionStatusColumnOptions = [
  { label: "All", value: null },
  ...rolePermissionStatusColumnOptions,
]

export const rolePermissionOptions = [
  { id: 0, label: "All", value: "all" },
  { id: 1, label: "Add", value: "add" },
  { id: 2, label: "Edit", value: "edit" },
  { id: 3, label: "Delete", value: "delete" },
  { id: 4, label: "Print", value: "print" },
  { id: 5, label: "View", value: "view" },
]

export const pageSizeOptions = [
  { id: 1, label: "10 Item / Page", value: 10 },
  { id: 2, label: "20 Item / Page", value: 20 },
  { id: 2, label: "50 Item / Page", value: 50 },
]

export const CustomerTypeRadio = [
  { id: 1, label: "ลูกเค้าเก่า", value: "0" },
  { id: 2, label: "ลูกค้าใหม่", value: "1" },
]

export const testaa = [
  { name: "0", value: "0" },
  { name: "1", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
  { name: "5", value: "5" },
  { name: "6", value: "6" },
  { name: "7", value: "7" },
  { name: "8", value: "8" },
  { name: "9", value: "9" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
  { name: "13", value: "13" },
  { name: "14", value: "14" },
  { name: "15", value: "15" },
  { name: "16", value: "16" },
  { name: "17", value: "17" },
  { name: "18", value: "18" },
  { name: "19", value: "19" },
  { name: "20", value: "20" },
  { name: "21", value: "21" },
  { name: "22", value: "22" },
  { name: "23", value: "23" },
]

export const CarTypeRadio = [
  { id: 1, label: "รถตู้รับส่ง", value: 1 },
  { id: 2, label: "รถตู้พร้อมอุปกรณ์รักษา", value: 0 },
]

export const ServiceTypeRadioOnline = [
  { id: 1, label: "ปรึกษาแพทย์", value: 0 },
]
export const ServiceTypeRadioOnsite = [
  { id: 2, label: "รถรับส่ง", value: 1 },
  { id: 3, label: "รักษานอกสถานที่", value: 2 },
]
export const fixMinCallRadio = [
  { id: 1, label: "20 นาที", value: 20 },
  { id: 2, label: "50 นาที", value: 50 },
]
export const chatRadio = [
  { id: 1, label: "Voice Call", value: 0 },
  { id: 2, label: "Video Cal", value: 1 },
]

export const CaseStatusOptions = [
  // { id: 0, label: "inActive", value: 0 },
  // { id: 1, label: "Active", value: 1 },
  { id: 70, label: "Booking", value: 70 },
  { id: 71, label: "Finished", value: 71 },
  { id: 72, label: "Canceled", value: 72 },
]

export const OwnerOptions = [
  { id: 1, label: "xxxxxxxxxxxxx", value: "xxxxxxxxxxxxx" },
  { id: 2, label: "yyyyyyyyyyyyy", value: "yyyyyyyyyyyyy" },
]

export const bookingStatusOptions = [
  { id: 1, label: "Booking", value: BookingStatus.Booking },
  { id: 2, label: "Finished", value: BookingStatus.Complete },
  { id: 3, label: "Canceled", value: BookingStatus.Canceled },
]

export const filterBookingStatusOptions = [
  { label: "All", value: null },
  ...bookingStatusOptions,
]

export const DoctorLeaveApproveStatusOptions = [
  { id: 1, label: "อนุมัติ", value: DoctorLeaveApproveStatus.Approve },
  { id: 2, label: "ไม่อนุมัติ", value: DoctorLeaveApproveStatus.NotApprove },
  { id: 3, label: "รอดำเนินการ", value: DoctorLeaveApproveStatus.Waiting },
  { id: 3, label: "Inactive", value: DoctorLeaveApproveStatus.Inactive },
]

export const filterPermissionStatusOptions = [
  { label: "All", value: null },
  ...DoctorLeaveApproveStatusOptions,
]

export const DoctorLeaveApproveOptions = [
  { id: 1, label: "อนุมัติ", value: DoctorLeaveApproveStatus.Approve },
  { id: 2, label: "ไม่อนุมัติ", value: DoctorLeaveApproveStatus.NotApprove },
]

export const calltypeOptions = [
  { id: 1, label: "voice call", value: "voice call" },
  { id: 2, label: "video call", value: "video call" },
]

export const productStatusOptions = [
  { id: 1, label: "Active", value: StatusDbValue.Active },
  { id: 2, label: "Inactive", value: StatusDbValue.Inactive },
]

export const filterProductStatusOptions = [
  { label: "All", value: null },
  ...productStatusOptions,
]

export const productCategoryStatusOptions = [
  { id: 1, label: "Active", value: StatusDbValue.Active },
  { id: 2, label: "Inactive", value: StatusDbValue.Inactive },
]

export const filterProductCategoryStatusOptions = [
  { label: "All", value: null },
  ...productCategoryStatusOptions,
]

export const purchaseStatusOptions = [
  { id: 1, label: "Pending", value: PurchaseStatus.Pending },
  { id: 2, label: "Paid", value: PurchaseStatus.Paid },
  { id: 3, label: "Cancelled", value: PurchaseStatus.Cancel },
  { id: 4, label: "Shipping", value: PurchaseStatus.Shipping },
  { id: 5, label: "Complete", value: PurchaseStatus.Complete },
  { id: 6, label: "Active", value: PurchaseStatus.Active },
  { id: 7, label: "Inactive", value: PurchaseStatus.Inactive },
]

export const revenueStatusOptions = [
  { id: 1, label: "Pending", value: RevenueStatus.Pending },
  { id: 2, label: "Paid", value: RevenueStatus.Paid },
  { id: 3, label: "Cancelled", value: RevenueStatus.Cancel },
]

export const filterPurchaseStatusOptions = [
  { label: "All", value: null },
  { id: 1, label: "Pending", value: "PENDING" },
  { id: 2, label: "Paid", value: "PAID" },
  { id: 3, label: "Cancelled", value: "CANCEL" },
  { id: 4, label: "Shipping", value: "SHIPPED" },
  { id: 5, label: "Complete", value: "COMPLETE" },
  { id: 5, label: "Delivered", value: "DELIVERED" },
  { id: 6, label: "Active", value: "ACTIVE" },
  { id: 7, label: "Inactive", value: "INACTIVE" },
  // filter is mock data
  // ...purchaseStatusOptions,
]

export const shippingStatusOptions = [
  { id: 1, label: "Pending", value: PurchaseStatus.Pending },
  { id: 2, label: "Paid", value: PurchaseStatus.Paid },
  { id: 3, label: "Cancelled", value: PurchaseStatus.Cancel },
  { id: 4, label: "Shipping", value: PurchaseStatus.Shipping },
  { id: 5, label: "Complete", value: PurchaseStatus.Complete },
  { id: 6, label: "Active", value: PurchaseStatus.Active },
  { id: 7, label: "Inactive", value: PurchaseStatus.Inactive },
]

export const filterShippingStatusOptions = [
  { label: "All", value: null },
  { id: 1, label: "Pending", value: "PENDING" },
  { id: 2, label: "Paid", value: "PAID" },
  { id: 3, label: "Cancelled", value: "CANCEL" },
  { id: 4, label: "Shipping", value: "SHIPPED" },
  { id: 5, label: "Complete", value: "COMPLETE" },
  { id: 5, label: "Delivered", value: "DELIVERED" },
  { id: 6, label: "Active", value: "ACTIVE" },
  { id: 7, label: "Inactive", value: "INACTIVE" },
  // filter is mock data
  // ...shippingStatusOptions,
]

export const carrierOptions = [
  { id: 1, label: "เบิกจ่ายยาที่จุดรักษา", value: carrierType.OnVisit },
  { id: 2, label: "จัดส่งยาทางไปรษณีย์", value: carrierType.OnDelivery },
]

export const adviseCallStatusOptions = [
  { id: 1, label: "CALLED", value: CallStatus.Called },
  { id: 2, label: "WAITING", value: CallStatus.Waiting },
]

export const filterAdviseCallStatusOptions = [
  { label: "All", value: null },
  { id: 1, label: "CALLED", value: "CALLED" },
  { id: 2, label: "WAITING", value: "WAITING" },
  // filter is mock data
  // ...adviseCallStatusOptions,
]

export const notificationStatusOptions = [
  { id: 1, label: "Pending", value: NotificationStatus.Pending },
  { id: 2, label: "Send Complete", value: NotificationStatus.Complete },
  { id: 3, label: "Cancel", value: NotificationStatus.Cancel },
  { id: 4, label: "Active", value: NotificationStatus.Active },
  { id: 5, label: "Inactive", value: NotificationStatus.Inactive },
]

export const CallTypeOptions = [
  { id: 1, label: "voice call", value: "voice call" },
  { id: 2, label: "video call", value: "video call" },
]

export const filteCalltypeOptions = [
  { label: "All", value: "all" },
  ...CallTypeOptions,
]

export const filterNotificationStatusOptions = [
  { label: "All", value: null },
  ...notificationStatusOptions,
]

export const notificationTypeStatusOptions = [
  { id: 1, label: "ส่งทุกคน", value: NotificationType.AllUser },
  {
    id: 2,
    label: "เฉพาะผู้ใช้งานอยู่ (90 วันล่าสุด)",
    value: NotificationType.LastActiveUser,
  },
  { id: 3, label: "เลือกส่งรายบุคคล", value: NotificationType.SpecificUser },
]

export const filterNotificationTypeStatusOptions = [
  { label: "All", value: null },
  ...notificationTypeStatusOptions,
]

export const notiTypeRadio = [
  { id: 1, label: "Broadcast", value: "broadcast" },
  { id: 2, label: "Specific", value: "specific" },
]

export const DoctorStatusRadio = [
  { id: 1, label: "Autherized", value: 11 },
  { id: 2, label: "Unautherized", value: 10 },
  { id: 3, label: "Ban", value: 12 },
]

export const filterDoctorStatusRadio = [
  { label: "All", value: "all" },
  ...DoctorStatusRadio,
]

export const DoctorOnsiteRadio = [
  { id: 1, label: "ได้", value: 0 },
  { id: 2, label: "ไม่ได้", value: 1 },
]
export const activeStatusOptions = [
  { id: 1, label: "Active", value: StatusDbValue.Active },
  { id: 2, label: "Inactive", value: StatusDbValue.Inactive },
]

export const filterActiveStatusOptions = [
  { label: "All", value: null },
  ...activeStatusOptions,
]

export const emsVanTypeOptions = [
  { id: 1, label: "EMS", value: EmsVanTypeDbValue.Ems },
  { id: 2, label: "VAN", value: EmsVanTypeDbValue.Van },
]

export const filterEmsVanTypeOptions = [
  { label: "All", value: null },
  ...emsVanTypeOptions,
]

export const BannerTypeOptions = [
  { id: 1, label: "Main Banner", value: 1 },
  { id: 2, label: "Banner Set", value: 2 },
]

export const BannerDetailStatusOptions = [{ id: 1, label: "", value: 1 }]

export const StatusOptions = [
  { id: 1, label: "Draft", value: 2 },
  { id: 2, label: "Active", value: 1 },
  { id: 3, label: "Inactive", value: 0 },
]

export const filterStatusOptions = [
  { label: "All", value: undefined },
  ...StatusOptions,
]

export const LanguageOptions = [
  { id: 1, label: "ENG", value: 1 },
  { id: 2, label: "THA", value: 2 },
]

export const TransactionStatusOptions = [
  { id: 1, label: "Pending Approve", value: 2 },
  { id: 2, label: "Success", value: 1 },
  { id: 3, label: "Cancelled", value: 0 },
]
export const filterTransactionStatusOptions = [
  { label: "All", value: "all" },
  ...TransactionStatusOptions,
]
export const IncomeTypeOptions = [
  { id: 1, label: "ค่ารักษานอกสถานที่", value: 0 },
  { id: 2, label: "ค่าปรึกษา", value: 1 },
  { id: 3, label: "ค่าสินค้า", value: 2 },
]
export const filterIncomeTypeOptions = [
  { label: "All", value: "all" },
  ...IncomeTypeOptions,
]
export const ExpenseTypeOptions = [
  { id: 1, label: "ค่าแพทย์", value: 0 },
  { id: 2, label: "ส่วนแบ่ง รพ.", value: 1 },
  { id: 3, label: "ค่าบริการขนส่ง", value: 2 },
  { id: 4, label: "อื่นๆ", value: 3 },
]
export const filterExpenseTypeOptions = [
  { label: "All", value: "all" },
  ...ExpenseTypeOptions,
]
export const VoucherStatusOptions = [
  { id: 1, label: "InActive", value: 30 },
  { id: 2, label: "Active", value: 31 },
  { id: 3, label: "Expired", value: 32 },
  { id: 4, label: "Redeem", value: 33 },
  { id: 5, label: "", value: 0 },
]

export const PaymentStatusOptions = [
  { id: 1, label: "inActive", value: 0 },
  { id: 2, label: "Active", value: 1 },
  { id: 3, label: "Payment Success", value: 80 },
  { id: 4, label: "Payment Fail", value: 81 },
  { id: 4, label: "Payment Cancel", value: 82 },
]

export const PaymentChannelOptions = [
  { id: 1, label: "Credit App", value: 0 },
  { id: 2, label: "QR Code", value: 1 },
  { id: 3, label: "Mobile Banking", value: 2 },
]

export const languageOptions = [
  { id: 1, label: "ENG", value: LanguageForm.ENG },
  { id: 2, label: "THA", value: LanguageForm.THA },
]
export const CmsLanguageOptions = [
  { id: 1, label: "THA", value: 0 },
  { id: 2, label: "ENG", value: 1 },
]
export const isPromaryOptions = [{ id: 1, label: "ที่อยู่หลัก", value: 1 }]

export const VoucherRadio = [
  { id: 1, label: " For New Register Member", value: 0 },
]
