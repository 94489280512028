import React from "react";
import * as yup from "yup";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import { Col, Form, Row } from "react-bootstrap";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createRoleGroup,
  createRolePermission,
  editRoleGroup,
  editRolePermission,
  getRolePermission,
  getScreen,
} from "../../services/UserServices";
import GenericTextInput from "../../components/Input/GenericTextInput";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import {
  enableDisableStatusColumnOptions,
  rolePermissionOptions,
} from "../../data/SelectOptions";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";

export default function CreateEditRolePermissionForm({
  isCreate,
  onCancel,
  onSubmitSuccess,
  roleNameDefaultValue,
  rolsStatueDefaultValue,
  id,
}) {
  const [screenData, setScreenData] = React.useState([]);
  const [screenPermissionIds, setScreenPermissionIds] = React.useState();
  const [yubSchema, setYubSchema] = React.useState(yup.object().shape());
  const [checkMenu, setCheckMenu] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yubSchema),
  });

  const onSubmit = async (inputData) => {
    setIsLoading(true);
    let success;
    let data;
    let error;
    if (isCreate) {
      ({ success, data, error } = await createRoleGroup({
        body: {
          name: inputData.roleName,
          status: inputData.rolsStatue?.value,
        },
      }));
      if (success) {
        const role_group_id = data.id;
        let isError = false;
        for (const screen of screenData) {
          ({ success, data, error } = await createRolePermission({
            body: {
              role_group_id: role_group_id,
              m_screen_id: +screen?.name,
              f_add: +inputData[screen.name].includes("add"),
              f_view: +inputData[screen.name].includes("view"),
              f_edit: +inputData[screen.name].includes("edit"),
              f_del: +inputData[screen.name].includes("delete"),
              f_print: +inputData[screen.name].includes("print"),
            },
          }));
          if (error) {
            isError = true;
          }
        }
        if (isError) {
          ShowMessageAndExpire(
            "Create Role Permission Data Failed",
            JSON.stringify(error?.message),
            "error"
          );
        } else {
          onSubmitSuccess();
          ShowMessageAndExpire("Data created successfully.", "", "success");
        }
      } else {
        ShowMessageAndExpire(
          "Create Role Group Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    } else {
      ({ success, data, error } = await editRoleGroup({
        pathParams: id,
        body: {
          name: inputData.roleName,
          status: inputData.rolsStatue?.value,
        },
      }));
      if (success) {
        let isError = false;
        for (const screen of screenData) {
          if (!screenPermissionIds[+screen?.name]) {
            ({ success, data, error } = await createRolePermission({
              body: {
                role_group_id: id,
                m_screen_id: +screen?.name,
                f_add: +inputData[screen.name].includes("add"),
                f_view: +inputData[screen.name].includes("view"),
                f_edit: +inputData[screen.name].includes("edit"),
                f_del: +inputData[screen.name].includes("delete"),
                f_print: +inputData[screen.name].includes("print"),
              },
            }));
          } else {
            let current_permission =
              screenPermissionIds[+screen?.name]?.permissions;
            let new_permission = `${+inputData[screen.name].includes(
              "add"
            )}${+inputData[screen.name].includes("edit")}${+inputData[
              screen.name
            ].includes("view")}${+inputData[screen.name].includes(
              "delete"
            )}${+inputData[screen.name].includes("print")}`;
            if (current_permission !== new_permission) {
              ({ success, data, error } = await editRolePermission({
                pathParams: screenPermissionIds[+screen?.name]?.id,
                body: {
                  role_group_id: id,
                  m_screen_id: +screen?.name,
                  f_add: +inputData[screen.name].includes("add"),
                  f_view: +inputData[screen.name].includes("view"),
                  f_edit: +inputData[screen.name].includes("edit"),
                  f_del: +inputData[screen.name].includes("delete"),
                  f_print: +inputData[screen.name].includes("print"),
                },
              }));
            }
          }

          if (error) {
            isError = true;
          }
        }
        if (isError) {
          ShowMessageAndExpire(
            "Create Role Permission Data Failed",
            JSON.stringify(error?.message),
            "error"
          );
        } else {
          onSubmitSuccess();
          ShowMessageAndExpire("Data created successfully.", "", "success");
        }
        onSubmitSuccess();
        ShowMessageAndExpire("Data updated successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    }
    setIsLoading(false);
  };

  const loadScreen = React.useCallback(async () => {
    let success;
    let data;
    let error;
    ({ success, data, error } = await getScreen({
      params: { limit: 100 },
    }));
    if (success) {
      let screens = [];
      let tempYupObj = {
        roleName: yup.string().required("ห้ามว่าง"),
        rolsStatue: yup.object().required("ห้ามว่าง"),
      };
      for (const [idx, screen] of data.records.entries()) {
        screens.push({
          id: idx,
          name: screen?.id.toString(),
          label: screen?.description,
          placeholder: screen?.description,
        });
        tempYupObj[screen?.id.toString()] = yup.array();
      }
      const schema = yup.object().shape(tempYupObj);
      setYubSchema(schema);
      setScreenData(screens);
      for (const screen of screens) {
        setValue(screen.name, []);
      }
      if (id) {
        ({ success, data, error } = await getRolePermission({
          params: { limit: 100, "filter.role_group_id": `$in:${id}` },
        }));
        if (success) {
          let tempPermissionIds = {};
          for (const screenPermission of data.records) {
            let tempScreenPermission = [];
            if (screenPermission.f_add) {
              tempScreenPermission.push("add");
            }
            if (screenPermission.f_edit) {
              tempScreenPermission.push("edit");
            }
            if (screenPermission.f_view) {
              tempScreenPermission.push("view");
            }
            if (screenPermission.f_del) {
              tempScreenPermission.push("delete");
            }
            if (screenPermission.f_print) {
              tempScreenPermission.push("print");
            }
            setValue(
              screenPermission.m_screen_id.toString(),
              tempScreenPermission
            );
            tempPermissionIds[screenPermission.m_screen_id.toString()] = {
              id: screenPermission.id,
              permissions: `${screenPermission.f_add}${screenPermission.f_edit}${screenPermission.f_view}${screenPermission.f_del}${screenPermission.f_print}`,
            };
          }
          setScreenPermissionIds(tempPermissionIds);
        }
      }
    } else {
      ShowMessageAndExpire(
        "Load Role Permission Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, []);

  React.useEffect(() => {
    loadScreen();
  }, [loadScreen]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="roleName"
              label="กลุ่มผู้ใช้"
              placeholder="กลุ่มผู้ใช้"
              defaultValue={roleNameDefaultValue}
              required
              error={errors.roleName}
              disabled={isLoading}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="rolsStatue"
              label="สถานะ"
              placeholder="สถานะ"
              defaultValue={rolsStatueDefaultValue}
              options={enableDisableStatusColumnOptions}
              required
              disabled={isLoading}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Row} controlId="formBasicName">
            <GenericRadioInput
              name="menu"
              label="เมนู"
              placeholder="เมนู"
              radios={rolePermissionOptions}
              type="checkbox"
              viewFormat="inline"
              checked={checkMenu}
              disabled={isLoading}
              onClick={(e) => {
                const value = e.target.value;
                const nameValues = screenData.map((value) => {
                  return value.name.toString();
                });
                let currentValues = getValues(nameValues);
                if (e.target.checked) {
                  for (const [idx, currentValue] of currentValues.entries()) {
                    if (e.target.value === "all") {
                      setValue(nameValues[idx], [
                        "all",
                        "add",
                        "edit",
                        "delete",
                        "print",
                        "view",
                      ]);
                      if (idx === 0) {
                        setCheckMenu([
                          "all",
                          "add",
                          "edit",
                          "delete",
                          "print",
                          "view",
                        ]);
                      }
                    } else if (Array.isArray(currentValue)) {
                      if (!currentValue.includes(value)) {
                        setValue(
                          nameValues[idx],
                          currentValue.concat([value]),
                          {
                            shouldValidate: true,
                          }
                        );
                      }
                      if (idx === 0) {
                        let tempCheckMenu = checkMenu.concat(e.target.value);
                        setCheckMenu(tempCheckMenu);
                      }
                    } else {
                      setValue(nameValues[idx], [value], {
                        shouldValidate: true,
                      });
                      if (idx === 0) {
                        let tempCheckMenu = checkMenu.concat(e.target.value);
                        setCheckMenu(tempCheckMenu);
                      }
                    }
                  }
                } else {
                  for (const [idx, currentValue] of currentValues.entries()) {
                    if (e.target.value === "all") {
                      setValue(nameValues[idx], []);
                      if (idx === 0) {
                        setCheckMenu([]);
                      }
                    } else if (Array.isArray(currentValue)) {
                      if (currentValue.includes(value)) {
                        let removeIdx = currentValue.indexOf(value);
                        currentValue.splice(removeIdx, 1);
                        setValue(nameValues[idx], currentValue, {
                          shouldValidate: true,
                        });
                      }
                      if (idx === 0) {
                        let tempCheckMenu = checkMenu;
                        let removeCheck = tempCheckMenu.indexOf(e.target.value);
                        tempCheckMenu.splice(removeCheck, 1);
                        // let tempCheckMenu = checkMenu.splice(removeIdx, 1);
                        setCheckMenu(tempCheckMenu);
                      }
                    } else {
                      setValue(nameValues[idx], [], {
                        shouldValidate: true,
                      });
                      if (idx === 0) {
                        setCheckMenu([]);
                      }
                    }
                  }
                }
              }}
            />
          </Form.Group>
          {screenData.map((value) => {
            if (!value || !register) {
              return;
            }
            return (
              <>
                <Form.Group as={Row} controlId="formBasicName">
                  <GenericRadioInput
                    register={register}
                    name={value?.name}
                    label={value?.label}
                    placeholder={value?.placeholder}
                    error={errors[value?.name]}
                    radios={rolePermissionOptions}
                    type="checkbox"
                    viewFormat="inline"
                    disabled={isLoading}
                    onClick={(e) => {
                      const value = e.target.value;
                      if (value === "all") {
                        if (e.target.checked) {
                          setValue(e.target.name, [
                            "all",
                            "add",
                            "edit",
                            "delete",
                            "print",
                            "view",
                          ]);
                        } else {
                          setValue(e.target.name, []);
                        }
                      }
                    }}
                  />
                </Form.Group>
              </>
            );
          })}
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} disabled={isLoading} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton disabled={isLoading} />
          </Col>
        </Row>
      </Form>
    </>
  );
}
