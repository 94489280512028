import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createMockUser, editMockUser } from "../../services/UserServices";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import { editSettingData } from "../../services/SettingServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";

const schema = yup.object().shape({
  headOfficeLocation: yup.string().required("ห้ามว่าง"),
  latitude: yup.string().required("ห้ามว่าง"),
  longitude: yup.string().required("ห้ามว่าง"),
  serviceRadius: yup.string().required("ห้ามว่าง"),
});

export default function EditServiceForm({
  onCancel,
  onSubmitSuccess,
  headOfficeLocationDefaultValue,
  latitudeDefaultValue,
  longitudeDefaultValue,
  serviceRadiusDefaultValue,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (inputData) => {
    const { success, data, error } = await editSettingData({
      pathParams: 1,
      body: {
        hoffice_addr: inputData.headOfficeLocation,
        latitude: inputData.latitude,
        longtitude: inputData.longtitude,
        services_radius: inputData.serviceRadius,
      },
    });
    if (success) {
      onSubmitSuccess();
      ShowMessageAndExpire("Data updated successfully.", "", "success");
    } else {
      ShowMessageAndExpire(
        "Failed to update data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4>การตั้งค่าบริการภายในระบบ</h4>
        <hr />
        <Form.Group as={Row} className="mb-3" controlId="formBasicName">
          <GenericTextInput
            register={register}
            name="headOfficeLocation"
            label="ที่ตั้งสำนักงานใหญ่ในการให้บริการ"
            as="textarea"
            placeholder="ที่ตั้งสำนักงานใหญ่ในการให้บริการ"
            defaultValue={headOfficeLocationDefaultValue}
            required
            error={errors.headOfficeLocation}
            viewFormat="inline"
          />
        </Form.Group>
        <Row className="mb-3">
          <Col sm={2}>Location</Col>
          <Col>
            <Form.Group as={Row} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="latitude"
                label="Latitude"
                placeholder="Latitude"
                defaultValue={latitudeDefaultValue}
                required
                error={errors.latitude}
                viewFormat="inline"
                labelColSize={3}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="longitude"
                label="Longitude"
                placeholder="Longitude"
                defaultValue={longitudeDefaultValue}
                required
                error={errors.longitude}
                viewFormat="inline"
                labelColSize={3}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group as={Row} className="mb-3" controlId="formBasicName">
          <GenericTextInput
            register={register}
            name="serviceRadius"
            label="รัศมีการให้บริการ (กม.)"
            placeholder="รัศมีการให้บริการ (กม.)"
            defaultValue={serviceRadiusDefaultValue}
            required
            error={errors.serviceRadius}
            viewFormat="inline"
          />
        </Form.Group>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
