import React from "react";
import { Button } from "react-bootstrap";

export default function ExpireLotButton(props) {
  return (
    <Button {...props}
      variant="danger"
      active
      style={{ color: "white", marginLeft: "10px" }}
    >
      Void
    </Button>
  );
}
