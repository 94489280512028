import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";
import Cookies from "js-cookie";

axios.defaults.withCredentials = true;

export const signin = async ({ phone, password }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/authentication/user_login",
      { phone, password },
      {
        headers: await getDefaultRequestHeaders(false),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const mockSignin = async ({ phone, password }) => {
  return {
    success: true,
    data: {
      id: 6,
      create_date: "2023-05-15 15:51:00",
      create_by: 0,
      update_date: "2023-06-03 08:14:52",
      update_by: null,
      status: 0,
      name: "Admin",
      lname: "Brown",
      email: "admin@example.com",
      phone: "0000000001",
      role_group_id: null,
      user_type: 0,
      user_id: null,
      access_token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjYsInVzZXJUeXBlIjowLCJpYXQiOjE2ODU3ODA4MjcsImV4cCI6MTY4NjM4NTYyN30.OwHI9C5d04x-blF-0Lj7_YTE_NhCOTYrSaKyq-TEDh8",
      refresh_token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjYsInVzZXJUeXBlIjowLCJpYXQiOjE2ODU3ODA4MjcsImV4cCI6MTY4NjM4NTYyN30.CWvA1v09-H4ZihUwnapoA4xu_fYdPWGCe5a2xvqoncE",
    },
    meta: {
      _lang: "en",
      _length: 1,
    },
  };
};

export const refreshToken = async () => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/authentication/refresh",
      {
        headers: {
          ...(await getDefaultRequestHeaders(false)),
          Authorization: `Bearer ${Cookies.get("Refresh-token")}`,
        },
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const logout = async () => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/authentication/logout",
      {
        headers: await getDefaultRequestHeaders(false),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const userInfo = async () => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/authentication/me",
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};


export const permissionInfo = async () => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/get_menu_permission",
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const verifyEmailUser = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/member_info/token_verify",
      body,
      {
        headers: await getDefaultRequestHeaders(false),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const sendResetPasswordEmail = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/authentication/forgot_password",
      body,
      {
        headers: await getDefaultRequestHeaders(false),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const validateResetPasswordToken = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL +
        "/authentication/forgot_password_token_verify",
      body,
      {
        headers: await getDefaultRequestHeaders(false),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const resetPassword = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/authentication/reset_password_admin",
      body,
      {
        headers: await getDefaultRequestHeaders(false),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};
