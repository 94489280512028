import React from "react";
import { deleteRoleGroup, getRoleGroup } from "../services/UserServices";
import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";
import GenericPagination from "../components/GenericPagination";
import ReactTable from "../components/ReactTable";
import { Card, Col, Container, Row } from "react-bootstrap";
import SelectFilter from "../components/SelectFilter";
import Searching from "../components/Searching";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import Header from "../layouts/Header";
import { filterRolePermissionStatusColumnOptions } from "../data/SelectOptions";
import { rolePermissionColumn } from "../data/Columns";
import CreateEditRolePermissionForm from "../features/Form/CreateEditRolePermissionForm";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";

const allRoleStatus = null;

export default function RolePermissionManagement() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [roleStatus, setRoleStatus] = React.useState(allRoleStatus);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [tableData, setTableData] = React.useState([]);
  const columnsData = rolePermissionColumn;

  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();

  const loadRoleGroup = React.useCallback(
    async (currentPage, pageSize, roleStatus, keyword, startDate, endDate) => {
      let dateFilter = null;
      if (startDate && endDate) {
        dateFilter = `$btw:${startDate} 00:00:00, ${endDate} 23:59:59`;
      }
      const { success, data, error } = await getRoleGroup({
        params: {
          page: currentPage,
          limit: pageSize,
          search: keyword,
          "filter.status": roleStatus,
          "filter.update_date": dateFilter,
        },
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        ShowMessageAndExpire(
          "Failed to load data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    []
  );

  React.useEffect(() => {
    loadRoleGroup(1, 10, allRoleStatus);
  }, [loadRoleGroup]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadRoleGroup(
        page,
        pageSize,
        roleStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadRoleGroup, pageSize, roleStatus, keyword, startDate, endDate]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadRoleGroup(1, pageSize, roleStatus, keyword, startDate, endDate);
    },
    [loadRoleGroup, roleStatus, keyword, startDate, endDate]
  );

  const loadPageOnUserStatusChange = React.useCallback(
    async (roleStatus) => {
      setCurrentPage(1);
      setRoleStatus(roleStatus);
      await loadRoleGroup(1, pageSize, roleStatus, keyword, startDate, endDate);
    },
    [loadRoleGroup, pageSize, keyword, startDate, endDate]
  );

  const openForm = () => {
    setIsOpenForm(true);
    setIsCreate(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setIsCreate(false);
    setEditData(null);
    setIsDelete(false);
    setDeleteData(null);
    setCurrentPage(1);
    setKeyword("");
    setRoleStatus(allRoleStatus);
    setStartDate(null);
    setStartDate(null);
    loadRoleGroup(1, pageSize, allRoleStatus, "", null, null);
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadRoleGroup(1, pageSize, roleStatus, keyword, startDate, endDate);
    },
    [loadRoleGroup, pageSize, roleStatus, startDate, endDate]
  );

  const handleSelectedDate = React.useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate);
      setEndDate(endDate);
      setCurrentPage(1);
      await loadRoleGroup(1, pageSize, roleStatus, keyword, startDate, endDate);
    },
    [loadRoleGroup, pageSize, roleStatus, keyword]
  );

  const handleOpenEditForm = async (data) => {
    setIsOpenForm(true);
    setIsCreate(false);
    setEditData(data);
  };

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const { success, error } = await deleteRoleGroup({
        pathParams: id,
      });
      if (success) {
        ShowMessageAndExpire("Data deleted successfully.", "", "success");
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadRoleGroup(
          tempCurrentPage,
          pageSize,
          roleStatus,
          keyword,
          startDate,
          endDate
        );
      } else {
        handleCloseDeleteConfirm();
        ShowMessageAndExpire(
          "Failed to delete data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [
      loadRoleGroup,
      total,
      currentPage,
      pageSize,
      roleStatus,
      keyword,
      startDate,
      endDate,
    ]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              !isOpenForm
                ? "Role & Permission Management"
                : isCreate
                ? "Create Role & Permission"
                : "Role & Permission Detail"
            }
            buttonEnable={!isOpenForm}
            buttonIcon="plus"
            buttonMessage="Create new records"
            onButtonOnClick={openForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`ชื่อกลุ่มผู้ใช้ = ${deleteData?.roleName}, วันที่สร้างรายการ= ${deleteData?.createAt}`}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenForm ? (
                  <>
                    <Row className="mt-3">
                      <CreateEditRolePermissionForm
                        isCreate={isCreate}
                        onCancel={refreshPage}
                        onSubmitSuccess={refreshPage}
                        roleNameDefaultValue={editData?.roleName}
                        rolsStatueDefaultValue={editData?.roleStatus}
                        id={editData?.id}
                      />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Filter : ชื่อกลุ่มผู้ใช้"
                          onSearch={handleSearch}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="สถานะ"
                          placeholder={"สถานะ"}
                          defaultValue={filterRolePermissionStatusColumnOptions.find(
                            (e) => e.value === allRoleStatus
                          )}
                          value={filterRolePermissionStatusColumnOptions.find(
                            (e) => e.value === roleStatus
                          )}
                          options={filterRolePermissionStatusColumnOptions}
                          onChange={loadPageOnUserStatusChange}
                        />
                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={startDate ? new Date(startDate) : null}
                          defaultEnd={endDate ? new Date(endDate) : null}
                          placeholder="เลือกวันที่อัพเดท"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = [];
  for (const [idx, data] of datas.entries()) {
    const tempTableData = {
      no: startNo + idx,
      roleName: data.name,
      createAt: data.create_date,
      roleStatus:
        data.status === 1 ? "Enable" : data.status === 0 ? "Diable" : "-",
    };
    const buttonData = {
      id: data.id,
      no: startNo + idx,
      roleName: data.name,
      createAt: data.create_date,
      roleStatus: data.status,
    };
    tableData.push({
      ...tempTableData,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(buttonData);
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(buttonData);
            }}
          />
        </>
      ),
    });
  }
  return tableData;
};
