import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getUser = async ({ page, pageSize, search, status, dateStart, dateEnd}) => {
    try {
        let datefilter;
        if (dateStart && dateEnd) {
            datefilter = '$btw:' + dateStart + ' 00:00:00,' + dateEnd + ' 23:59:59';
            }
        const res = await axios.get(
            process.env.REACT_APP_API_URL + "/appeal/datatable",
            {
            headers: await getDefaultRequestHeaders(true),
            params: {
                page,
                limit: pageSize,
                search,
                'filter.status': status,
                'filter.update_date': datefilter,
                'filter._relations':'rel_member_info_id,rel_m_appeal_cat_id'
            },
            }
        );
        return {
            success: true,
            data: {
            total: res.data.meta?.total_items,
            currentPage: res.data.meta?.current_page,
            pageSize: res.data.meta?.items_per_page,
            records: res.data.data,
            },
            error: null,
        };
        } catch (err) {
        return {
            success: false,
            data: null,
            error: err.response?.data?.error || err,
        };
        }
    };

    export const getRecordDetail = async ({ id, page, pageSize, keyword }) => {
        try {
          // console.log(id);
            const res = await axios.get(
            process.env.REACT_APP_API_URL + "/appeal/datatable",
            {
                headers: await getDefaultRequestHeaders(true),
                params: {
                page,
                "filter.banner_id": id,
                limit: pageSize,
                keyword,
                },
            }
            );
            return {
            success: true,
            data: {
                total: res.data.meta?.total_items,
                currentPage: res.data.meta?.current_page,
                pageSize: res.data.meta?.items_per_page,
                records: res.data.data,
            },
            error: null,
            };
        } catch (err) {
            return {
            success: false,
            data: null,
            error: err.response?.data?.error || err,
            };
        }
        };

export const deleteRecord = async ({ id }) => {
    try {
        const res = await axios.delete(
            process.env.REACT_APP_API_URL + "/appeal/" + id,
            {
            headers: await getDefaultRequestHeaders(true),
            }
        );
        return {
            success: true,
            data: res.data.data,
            error: null,
        };
        } catch (err) {
        return {
            success: false,
            data: null,
            error: err.response?.data?.error || err,
        };
        }
    };

export const createUserAppeal = async ({
    member_info_id,
    subject,
    m_appeal_cat_id,
    description,
    description_fixed,
    status
    }) => {
        try {
        const res = await axios.post(
            process.env.REACT_APP_API_URL + "/appeal",
            { member_info_id,subject,m_appeal_cat_id,description,description_fixed,status},
            {
            headers: await getDefaultRequestHeaders(true),
            }
        );
        return {
            success: true,
            data: res.data.data,
            error: null,
        };
        } catch (err) {
        return {
            success: false,
            data: null,
            error: err.response?.data?.error || err,
        };
        }
    };
    export const editRecord = async ({
        member_info_id,
        subject,
        m_appeal_cat_id,
        description,
        description_fixed,
        status
        }) => {
            try {
            const res = await axios.put(
                process.env.REACT_APP_API_URL + "/appeal",
                { member_info_id,subject,m_appeal_cat_id,description,description_fixed,status},
                {
                headers: await getDefaultRequestHeaders(true),
                }
            );
            return {
                success: true,
                data: res.data.data,
                error: null,
            };
            } catch (err) {
            return {
                success: false,
                data: null,
                error: err.response?.data?.error || err,
            };
            }
        };

    export const getMemberInfo = async ({}) => {
        try {
            const res = await axios.get(
                process.env.REACT_APP_API_URL + "/m_appeal_cat",
                {
                headers: await getDefaultRequestHeaders(true),
                params: {},
                }
            );
            return {
                success: true,
                data: {
                total: res.data.meta?.total_items,
                currentPage: res.data.meta?.current_page,
                pageSize: res.data.meta?.items_per_page,
                records: res.data.data,
                },
                error: null,
            };
            } catch (err) {
            return {
                success: false,
                data: null,
                error: err.response?.data?.error || err,
            };
            }
        };

        export const createDetailRecord = async ({
            m_appeal_cat_id,
            subject,
            description,
            description_fixed,
            status,
            member_info_id,
            }) => {
            try {
                let bodyFormData = new FormData();
                bodyFormData.append("m_appeal_cat_id", m_appeal_cat_id);
                bodyFormData.append("subject", subject);
                bodyFormData.append("description", description);
                bodyFormData.append("description_fixed", description_fixed);
                bodyFormData.append("status", status);
                bodyFormData.append("member_info_id", member_info_id);
            
                const res = await axios.post(
                process.env.REACT_APP_API_URL + "/ext/appeal/upload_new",
                bodyFormData,
                {
                    headers: await getDefaultRequestHeaders(true),
                }
                );
                return {
                success: true,
                data: res.data.data,
                error: null,
                };
            } catch (err) {
                return {
                success: false,
                data: null,
                error: err.response?.data?.error || err,
                };
            }
            }