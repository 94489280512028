import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { mockSignin, signin } from "../../services/AuthServices";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import GenericButton from "../../components/Button/GenericButton";
import {
  setAccessToken,
  setUser,
} from "../../global_store/features/AuthenSlice";
import { useAppDispatch } from "../../global_store/hooks";
import {
  setBodyMessage,
  setMessageType,
  setShowMessageModal,
  setTitleMessage,
} from "../../global_store/features/MessageSlice";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const schema = yup.object().shape({
  phoneNumber: yup.string().required("โปรดระบุเบอร์โทรศัพท์"),
  password: yup.string().required("โปรดระบุรหัสผ่าน"),
});

export default function SigninForm({ onSigninSuccess }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (inputData) => {
    setIsLoading(true);
    const { success, data, error } = await signin({
      phone: inputData.phoneNumber,
      password: inputData.password,
    });
    if (success) {
      dispatch(setAccessToken(data.access_token));
      Cookies.set("Refresh-token", data.refresh_token);
      Cookies.set("permission", JSON.stringify(data.permissions));
      dispatch(setUser(data.user_id));
      onSigninSuccess();
    } else {
    //   dispatch(setShowMessageModal(true));
    //   dispatch(setTitleMessage("Login failed!"));
    //   dispatch(setBodyMessage(error?.message));
    //   dispatch(setMessageType("error"));

        // Display the error message using SweetAlert2
        console.log(error?.message);
        await Swal.fire({
            icon: 'error',
            title: 'Authentication failed.',
            html: 'Please check your credentials and try again.',
            confirmButtonText: 'OK',
        });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-4">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="phoneNumber"
              label="Phone number"
              placeholder="Phone number"
              defaultValue=""
              error={errors.phone}
              disabled={isLoading}
            />
          </Form.Group>
        </Row>
        <Row className="mb-1">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="password"
              label="Password"
              placeholder="Password"
              defaultValue=""
              error={errors.password}
              type="password"
              disabled={isLoading}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Link
            to={!isLoading && "/forgot"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            Forgot Password
          </Link>
        </Row>
        <Col>
          <GenericButton type="submit" disabled={isLoading}>
            {"Sign in"}
            {isLoading && (
              <>
                &nbsp; &nbsp;
                <Spinner
                  animation="border"
                  variant="info"
                  size="sm"
                  className="me-3"
                />
              </>
            )}
          </GenericButton>
        </Col>
      </Form>
    </>
  );
}
