import React from "react"
import { bookingLogsChange } from "../data/Columns"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import Header from "../layouts/Header"
import PageHeaderCard from "../components/Card/PageHeaderCard"
import ReactTable from "../components/ReactTable"
import GenericPagination from "../components/GenericPagination"
import { getCommonAPI } from "../services/commonAPIServices"

export default function OnsiteBookingCancelationLog() {
  const [total, setTotal] = React.useState(1)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(10)
  const [keyword, setKeyword] = React.useState("")

  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const allUserStatus = "all"
  const [userStatus, setUserStatus] = React.useState(allUserStatus)
  const [tableBookingLogsChange, setTableBookingLogsChange] = React.useState([])

  const loadBookingLogsChange = React.useCallback(
    async (currentPage, pageSize) => {
      const { success, data, error } = await getCommonAPI({
        url_api: "/track_change/datatable",
        p_data: {
          page: currentPage,
          limit: pageSize,
          "filter._relations": "rel_booking_id",
          "filter.rel_booking_id.booking_type": 1,
          "filter.change_type": "$ilike:cancel",
        },
      })
      if (success) {
        setTotal(data.total)
        setTableBookingLogsChange(prepareBookingLogsChange(data.records))
      } else {
        console.log(error)
      }
    },
    []
  )

  const prepareBookingLogsChange = (datas) => {
    let listsLogs = []
    for (const data of datas) {
      let lists = {
        changeDatetime: data.create_date,
        ChangeType: data.change_type,
        ChangeFrom: data.change_from,
        ChangeTo: data.change_to,
        remark: data.remark,
        UpdateBy: data.update_by,
      }
      listsLogs.push(lists)
    }

    return listsLogs
  }

  React.useEffect(() => {
    loadBookingLogsChange(1, 10, allUserStatus)
  }, [loadBookingLogsChange])

  const columnsBookingLogsChange = bookingLogsChange

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page)
      await loadBookingLogsChange(page, pageSize, userStatus, keyword)
    },
    [loadBookingLogsChange, pageSize, userStatus, keyword]
  )

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1)
      setPageSize(pageSize)
      await loadBookingLogsChange(1, pageSize, userStatus, keyword)
    },
    [loadBookingLogsChange, userStatus, keyword]
  )
  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1)
      setUserStatus(userStatus)
      await loadBookingLogsChange(1, pageSize, userStatus, keyword)
    },
    [loadBookingLogsChange, pageSize, keyword]
  )

  const refreshPage = () => {
    setCurrentPage(1)
    setKeyword("")
    setUserStatus(allUserStatus)
    loadBookingLogsChange(1, pageSize, allUserStatus, "")
  }

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1)
      setKeyword(keyword)
      await loadBookingLogsChange(1, pageSize, userStatus, keyword)
    },
    [loadBookingLogsChange, pageSize, userStatus]
  )

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard title="Onsite Booking Cancelation Log" />

          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                <>
                  <Row className="mt-3">
                    <Col>
                      <ReactTable
                        columns={columnsBookingLogsChange}
                        data={tableBookingLogsChange}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <GenericPagination
                        totalPage={Math.ceil(total / pageSize)}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={loadPageOnPageChange}
                        onPageSizeChange={loadPageOnPageSizeChange}
                      />
                    </Col>
                  </Row>
                </>
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}
