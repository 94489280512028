import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import SessionExpiredModal from "../components/Modal/SessionExpiredModal";
import { useAppDispatch, useAppSelector } from "../global_store/hooks";
import { permissionInfo, userInfo } from "../services/AuthServices";
import { selectUser, setUser } from "../global_store/features/AuthenSlice";
import Cookies from "js-cookie";
import { Spinner } from "react-bootstrap";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";

export default function Main() {
  const offsets = ["/apps/file-manager", "/apps/email", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;

  // set sidebar to offset
  offsets.includes(pathname)
    ? bc.add("sidebar-offset")
    : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");

  // scroll to top when switching pages
  window.scrollTo(0, 0);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const initApp = React.useCallback(async () => {
    const refreshToken = Cookies.get("Refresh-token");
    if (!refreshToken) {
      navigate("/signin");
      return;
    }
    let success;
    let data;
    let error;
    ({ success, data, error } = await userInfo());
    if (success) {
      let tempUser = {
        id: data.id,
        userId: data.user_id,
        name: data.name,
        lname: data.lname,
        email: data.email,
        phone: data.phone,
      };
      ({ success, data, error } = await permissionInfo());
      if (success) {
        tempUser["menuViewPermission"] = data;
        dispatch(setUser(tempUser));
      } else {
        tempUser["menuViewPermission"] = [];
        dispatch(setUser(tempUser));
        ShowMessageAndExpire(
          "Load Menu Permission Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    } else {
      ShowMessageAndExpire(
        "Load User Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, []);

  React.useEffect(() => {
    initApp();
  }, [initApp]);

  return (
    <React.Fragment>
      <SessionExpiredModal />
      {user ? (
        <>
          <Sidebar />
          <Outlet />
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner
              animation="border"
              variant="info"
              size="lg"
              className="me-3"
            />
            <br/><h3>Loading...</h3>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
