import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showMessageModal: false,
  titleMessage: "",
  bodyMessage: "",
  messageType: "",
};

export const MessageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setShowMessageModal: (state, action) => {
      state.showMessageModal = action.payload;
    },
    setTitleMessage: (state, action) => {
      state.titleMessage = action.payload;
    },
    setBodyMessage: (state, action) => {
      state.bodyMessage = action.payload;
    },
    setMessageType: (state, action) => {
      state.messageType = action.payload;
    },
  },
});

export const {
  setShowMessageModal,
  setTitleMessage,
  setBodyMessage,
  setMessageType,
} = MessageSlice.actions;

export const selectShowMessageModal = (state) => state.message.showMessageModal;
export const selectTitleMessage = (state) => state.message.titleMessage;
export const selectBodyMessage = (state) => state.message.bodyMessage;
export const selectMessageType = (state) => state.message.messageType;

export default MessageSlice.reducer;
