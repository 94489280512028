import React from "react";
import Forbidden from "../pages/Forbidden";
import ForgotPassword from "../pages/ForgotPassword";
import InternalServerError from "../pages/InternalServerError";
import LockScreen from "../pages/LockScreen";
import NotFound from "../pages/NotFound";
import ServiceUnavailable from "../pages/ServiceUnavailable";
import Signin from "../pages/Signin";
import SigninTheme from "../pages/SigninTheme";
import SignupTheme from "../pages/SignupTheme";
import Signup from "../pages/Signup";
import VerifyAccount from "../pages/VerifyAccount";
import VerifyEmail from "../pages/VerifyEmail";
import Payment from "../pages/Payment";
import PaymentQrCode from "../pages/PaymentQrCode";
import PaymentResult from "../pages/PaymentResult";
import ResetPassword from "../pages/ResetPassword";

const publicRoutes = [
  // { path: "pages/signinTheme", element: <SigninTheme /> },
  { path: "signin", element: <Signin /> },
  // { path: "pages/signupTheme", element: <SignupTheme /> },
  // { path: "pages/signup", element: <Signup /> },
  { path: "pages/verify", element: <VerifyAccount /> },
  { path: "verify-email", element: <VerifyEmail /> },
  { path: "forgot", element: <ForgotPassword /> },
  { path: "forget-password-verify-email", element: <ResetPassword /> },
  // { path: "pages/lock", element: <LockScreen /> },
  { path: "pages/error-404", element: <NotFound /> },
  { path: "pages/error-500", element: <InternalServerError /> },
  { path: "pages/error-503", element: <ServiceUnavailable /> },
  { path: "pages/error-505", element: <Forbidden /> },
  { path: "payment-gateway/paid", element: <Payment /> },
  { path: "payment-gateway/paid-qrcode", element: <PaymentQrCode /> },
  { path: "payment-gateway/paid-result", element: <PaymentResult /> },
];

export default publicRoutes;
