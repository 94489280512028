import React from "react";
import * as yup from "yup";
import {
  createProduct,
  deleteImage,
  deletePhotoSetProduct,
  editProduct,
  getPetTypeProduct,
  getPhotoSetProduct,
  getUnitProduct,
} from "../../services/ProductManagenentServices";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import ReactTable from "../../components/ReactTable";
import { photoSetColumn } from "../../data/Columns";
import DeleteButton from "../../components/Button/DeleteButton";

import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import {
  languageOptions,
  productStatusOptions,
} from "../../data/SelectOptions";
import { uploadImage } from "../../services/SettingServices";
import AddProductPhotoSetModal from "../Modal/AddProductPhotoSetModal";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";
import { LanguageForm } from "../../data/Enumerator";

const schema = yup.object().shape({
  language: yup.object().required("ห้ามว่าง"),
  productBrand: yup.string().required("ห้ามว่าง"),
  productCategory: yup.object().required("ห้ามว่าง"),
  productName: yup.string().required("ห้ามว่าง"),
  productCode: yup.string().required("ห้ามว่าง"),
  productDoctorName: yup.string().required("ห้ามว่าง"),
  productOrderNumber: yup.string().required("ห้ามว่าง"),
  productStatus: yup.object().required("ห้ามว่าง"),
  unitCost: yup.number().required("ห้ามว่าง").typeError("ต้องเป็นตัวเลข"),
  unit: yup.object().required("ห้ามว่าง"),
  sellingPrice: yup.number().required("ห้ามว่าง").typeError("ต้องเป็นตัวเลข"),
  wholesalePrice: yup.number().required("ห้ามว่าง").typeError("ต้องเป็นตัวเลข"),
  recommendedDosage: yup.string().required("ห้ามว่าง"),
  petType: yup.array().min(1, "ห้ามว่าง").required("ห้ามว่าง"),
  productInfo: yup.string().required("ห้ามว่าง"),
  // thumbnailPhoto: yup
  //   .mixed()
  //   .test("fileType", "ห้ามว่าง", (value) => {
  //     return value[0] ? true : false;
  //   })
  //   .test("fileType", "ไฟล์ผิดประเภท", (value) => {
  //     if (!value) {
  //       return false;
  //     }
  //     const supportedFormats = ["image/jpeg", "image/png"];
  //     return supportedFormats.includes(value[0]?.type);
  //   }),
  // photoSet: yup.mixed().test("fileType", "ไฟล์ผิดประเภท", (value) => {
  //   if (!value) {
  //     return false;
  //   }
  //   const supportedFormats = ["image/jpeg", "image/png"];
  //   return supportedFormats.includes(value[0]?.type);
  // }),
  isSellOnline: yup.bool().required("ห้ามว่าง"),
  isAlertStockEmpty: yup.bool().required("ห้ามว่าง"),
  isShowOnlyInternalSystem: yup.bool().required("ห้ามว่าง"),
  isShowProduct: yup.bool().required("ห้ามว่าง"),
  isMedical: yup.bool().required("ห้ามว่าง"),
  isVaccine: yup.bool().required("ห้ามว่าง"),
});

export default function CreateEditProductForm({
  isCreate,
  onCancel,
  onSubmitSuccess,
  languageDefaultValue = LanguageForm.THA,
  productBrandDefaultValue,
  productCategoryDefaultValue,
  productNameDefaultValue,
  productCodeDefaultValue,
  productDoctorNameDefaultValue,
  productOrderNumberDefaultValue,
  productStatusDefaultValue,
  unitCostDefaultValue,
  unitDefaultValue,
  sellingPriceDefaultValue,
  wholesalePriceDefaultValue,
  recommendedDosageDefaultValue,
  petTypeDefaultValue = [],
  productInfoDefaultValue,
  thumbnailPhotoDefaultValue,
  photoSetDefaultValue,
  isSellOnlineDefaultValue,
  isAlertStockEmptyDefaultValue,
  isShowOnlyInternalSystemDefaultValue,
  isShowProductDefaultValue,
  isMedicalDefaultValue,
  isVaccineDefaultValue,
  id,
  productCategoryOptionData,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const [unitSelect, setUnitSelect] = React.useState([]);
  const [petTypeSelect, setPetTypeSelect] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [isDeleteThumbnail, setIsDeleteThumbnail] = React.useState(false);
  const [isDeletePhotoSet, setIsDeletePhotoSet] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();

  const [previewImage, setPreviewImage] = React.useState(
    thumbnailPhotoDefaultValue
  );

  const columnsData = photoSetColumn;

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const prepareUnitListForSelect = React.useCallback(
    (datas) => {
      let listsUnit = [];
      for (const [idx, data] of datas.entries()) {
        let tempUnit = {
          id: idx,
          label: data.name,
          value: data.id,
        };
        listsUnit.push(tempUnit);
        if (data.id === unitDefaultValue) {
          setValue("unit", tempUnit, { shouldValidate: true });
        }
      }
      return listsUnit;
    },
    [unitDefaultValue, setValue]
  );

  const loadUnitSelect = React.useCallback(async () => {
    const { success, data, error } = await getUnitProduct({
      params: { limit: 1000 },
    });
    if (success) {
      setUnitSelect(prepareUnitListForSelect(data.records));
    } else {
      ShowMessageAndExpire(
        "Load Unit Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [prepareUnitListForSelect]);

  const preparePetTypeForSelect = React.useCallback(
    (datas) => {
      let petTypes = [];
      let tempCurrentValue = [];
      for (const [idx, petType] of datas.entries()) {
        petTypes.push({
          id: idx,
          label: petType.name,
          value: petType.id,
        });
        if (petTypeDefaultValue.includes(petType?.id)) {
          tempCurrentValue.push(petType.id.toString());
        }
      }
      if (tempCurrentValue) {
        setValue("petType", tempCurrentValue);
      }
      return petTypes;
      // if put petTypeDefaultValue in depend will infinity loop
    },
    [setValue]
  );

  const loadPetTypeSelect = React.useCallback(async () => {
    const { success, data, error } = await getPetTypeProduct({
      params: { limit: 1000 },
    });
    if (success) {
      setPetTypeSelect(preparePetTypeForSelect(data.records));
    } else {
      ShowMessageAndExpire(
        "Load Pet Type Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [preparePetTypeForSelect]);

  const preparePhotoSetForTable = React.useCallback((datas, onDelete) => {
    let photoSet = [];
    for (const [idx, data] of datas.entries()) {
      photoSet.push({
        id: idx + 1,
        image: (
          <img
            src={data.picture_path}
            alt="Preview"
            style={{
              width: "150px",
            }}
          />
        ),
        info: data.description,
        order: data.product_order,
        deleteButton: (
          <DeleteButton
            onClick={() => {
              onDelete({ id: data.id });
            }}
          />
        ),
      });
    }
    return photoSet;
  }, []);

  const loadPhotoSet = React.useCallback(async () => {
    const { success, data, error } = await getPhotoSetProduct({
      params: { limit: 1000, "filter.product_id": id },
    });
    if (success) {
      setTableData(
        preparePhotoSetForTable(data.records, handleOpenPhotoSetDeleteConfirm)
      );
    } else {
      ShowMessageAndExpire(
        "Load Photo Set Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [id, preparePhotoSetForTable]);

  const handleOpenDeleteThumbnailConfirm = (data) => {
    setIsDeleteThumbnail(true);
    setDeleteData(data);
  };

  const handleOpenPhotoSetDeleteConfirm = (data) => {
    setIsDeletePhotoSet(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDeleteThumbnail(false);
    setIsDeletePhotoSet(false);
    setDeleteData(null);
  };

  const handleDeletePhotoSet = React.useCallback(
    async (id) => {
      const { success, error } = await deletePhotoSetProduct({
        pathParams: id,
      });
      if (success) {
        ShowMessageAndExpire("Data deleted successfully.", "", "success");
        handleCloseDeleteConfirm();
        await loadPhotoSet();
      } else {
        handleCloseDeleteConfirm();
        ShowMessageAndExpire(
          "Failed to delete data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [loadPhotoSet]
  );

  const handleDeleteThumbnailPhoto = React.useCallback(async (id) => {
    const { success, error } = await deleteImage({
      data: {
        res_model: "product",
        res_id: id,
        res_field: "picture_path",
      },
    });

    if (success) {
      ShowMessageAndExpire("Data deleted successfully.", "", "success");
      handleCloseDeleteConfirm();
      setPreviewImage(null);
    } else {
      handleCloseDeleteConfirm();
      ShowMessageAndExpire(
        "Failed to delete data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, []);

  React.useEffect(() => {
    setPreviewImage(thumbnailPhotoDefaultValue);
    loadUnitSelect();
    loadPetTypeSelect();
    if (!isCreate) {
      loadPhotoSet();
    }
  }, [
    thumbnailPhotoDefaultValue,
    isCreate,
    loadUnitSelect,
    loadPetTypeSelect,
    loadPhotoSet,
  ]);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
    if (!isCreate) {
      let errorMessage = await submitImage(file, id);
      if (!errorMessage) {
        ShowMessageAndExpire("Update Thumbnail Photo Success", "", "success");
      } else {
        ShowMessageAndExpire(
          "Update Thumbnail Photo Failed",
          JSON.stringify(errorMessage?.message),
          "error"
        );
      }
    }
  };

  const handleAddPhotoSet = React.useCallback(() => {
    setShowModal(false);
    loadPhotoSet();
  }, [loadPhotoSet]);

  const submitImage = async (imageFile, productId) => {
    let bodyFormData = new FormData();
    bodyFormData.append("image", imageFile, imageFile?.name);
    bodyFormData.append("res_model", "product");
    bodyFormData.append("res_id", productId);
    bodyFormData.append("res_field", "picture_path");
    const { success, error } = await uploadImage({
      bodyFormData: bodyFormData,
    });
    if (success) {
      return;
    } else {
      return error;
    }
  };

  const onSubmit = async (inputData) => {
    const thumbnailPhotoFile = inputData.thumbnailPhoto[0];
    if (isCreate) {
      const { success, data, error } = await createProduct({
        body: {
          language: inputData?.language?.value,
          status: inputData?.productStatus?.value,
          brandname: inputData?.productBrand,
          product_cat_id: inputData?.productCategory?.value,
          product_name: inputData?.productName,
          code: inputData?.productCode,
          product_name2: inputData?.productDoctorName,
          product_order: inputData?.productOrderNumber,
          product_cost: inputData?.unitCost,
          m_unit_id: inputData?.unit?.value,
          price1: inputData?.sellingPrice,
          price2: inputData?.wholesalePrice,
          qty_recomment: inputData?.recommendedDosage,
          description: inputData?.productInfo,
          f_sale_online: +inputData?.isSellOnline,
          f_low_stock: +inputData?.isAlertStockEmpty,
          f_show_insite: +inputData?.isShowOnlyInternalSystem,
          f_show_stock: +inputData?.isShowProduct,
          f_medical: +inputData?.isMedical,
          f_vaccinc: +inputData?.isVaccine,
          m_pet_type_ids: inputData?.petType,
          // product_type: inputData?.petType,
        },
      });
      if (success && thumbnailPhotoFile) {
        let errorMessage = await submitImage(thumbnailPhotoFile, data.data?.id);
        if (!errorMessage) {
          onSubmitSuccess();
          ShowMessageAndExpire("Data created successfully.", "", "success");
        } else {
          ShowMessageAndExpire(
            "Create Thumbnail Photo Failed",
            JSON.stringify(errorMessage?.message),
            "error"
          );
        }
      } else if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data created successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Failed to create data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    } else {
      const { success, error } = await editProduct({
        pathParams: id,
        body: {
          language: inputData?.language?.value,
          status: inputData?.productStatus?.value,
          brandname: inputData?.productBrand,
          product_cat_id: inputData?.productCategory?.value,
          product_name: inputData?.productName,
          code: inputData?.productCode,
          product_name2: inputData?.productDoctorName,
          product_order: inputData?.productOrderNumber,
          product_cost: inputData?.unitCost,
          m_unit_id: inputData?.unit?.value,
          price1: inputData?.sellingPrice,
          price2: inputData?.wholesalePrice,
          qty_recomment: inputData?.recommendedDosage,
          description: inputData?.productInfo,
          f_sale_online: +inputData?.isSellOnline,
          f_low_stock: +inputData?.isAlertStockEmpty,
          f_show_insite: +inputData?.isShowOnlyInternalSystem,
          f_show_stock: +inputData?.isShowProduct,
          f_medical: +inputData?.isMedical,
          f_vaccinc: +inputData?.isVaccine,
          m_pet_type_ids: inputData?.petType,
          // product_type: inputData?.petType,
        },
      });
      if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data updated successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    }
  };

  return (
    <>
      <AddProductPhotoSetModal
        show={showModal}
        setShow={() => {
          setShowModal();
        }}
        onSave={handleAddPhotoSet}
        productId={id}
      />
      <DeleteConfirmModal
        show={isDeletePhotoSet}
        onClose={handleCloseDeleteConfirm}
        onComfirm={handleDeletePhotoSet}
        deleteRecord={deleteData?.id}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these record?"}</p>
            <p>{`id = ${deleteData?.id}`}</p>
          </>
        }
      />
      <DeleteConfirmModal
        show={isDeleteThumbnail}
        onClose={handleCloseDeleteConfirm}
        onComfirm={handleDeleteThumbnailPhoto}
        deleteRecord={deleteData?.id}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these record?"}</p>
            <p>{`id = ${deleteData?.id}`}</p>
          </>
        }
      />
      <Row className="mb-3">
        <Col sm="1" style={{ minWidth: "10rem" }}>
          <GenericSelectInput
            control={control}
            name="language"
            label="Language"
            placeholder="Language"
            defaultValue={languageDefaultValue}
            required
            options={languageOptions}
          />
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4>รายละเอียดสินค้า</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="productBrand"
              label="ยี่ห้อสินค้า"
              placeholder="ยี่ห้อสินค้า"
              defaultValue={productBrandDefaultValue}
              required
              error={errors.productBrand}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="productCategory"
              label="หมวดหมู่สินค้า"
              placeholder="หมวดหมู่สินค้า"
              defaultValue={productCategoryDefaultValue}
              options={productCategoryOptionData}
              required
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="productName"
              label="ชื่อสินค้า"
              placeholder="ชื่อสินค้า"
              defaultValue={productNameDefaultValue}
              required
              error={errors.productName}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="productCode"
              label="รหัสสินค้า"
              placeholder="รหัสสินค้า"
              defaultValue={productCodeDefaultValue}
              error={errors.productCode}
              required
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="productDoctorName"
              label="ชื่อสินค้า(แพทย์)"
              placeholder="ชื่อสินค้า(แพทย์)"
              defaultValue={productDoctorNameDefaultValue}
              required
              error={errors.productDoctorName}
            />
          </Form.Group>
          <Col />
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="productOrderNumber"
              label="ลำดับรายการ"
              placeholder="ลำดับรายการ"
              defaultValue={productOrderNumberDefaultValue}
              required
              error={errors.productOrderNumber}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="productStatus"
              label="สถานะ"
              placeholder="สถานะ"
              defaultValue={productStatusDefaultValue}
              options={productStatusOptions}
              required
            />
          </Form.Group>
        </Row>
        <h4>ต้นทุนสินค้า / ราคาสินค้า</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="unitCost"
              label="ต้นทุนต่อหน่วย"
              placeholder="ต้นทุนต่อหน่วย"
              defaultValue={unitCostDefaultValue}
              type="number"
              required
              error={errors.unitCost}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="unit"
              label="หน่วย"
              placeholder="หน่วย"
              defaultValue={unitDefaultValue}
              options={unitSelect}
              required
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="sellingPrice"
              label="ราคาขาย"
              placeholder="ราคาขาย"
              defaultValue={sellingPriceDefaultValue}
              type="number"
              required
              error={errors.sellingPrice}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="wholesalePrice"
              label="ราคาส่ง"
              placeholder="ราคาส่ง"
              defaultValue={wholesalePriceDefaultValue}
              error={errors.wholesalePrice}
              type="number"
              required
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <h4>ปริมาณ</h4>
            <hr />
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="recommendedDosage"
                label="ปริมาณที่แนะนำ"
                placeholder="ปริมาณที่แนะนำ"
                defaultValue={recommendedDosageDefaultValue}
                required
                error={errors.recommendedDosage}
              />
            </Form.Group>
          </Col>
          <Col>
            <h4>
              ประเภทสัตว์ที่เกี่ยวข้อง
              <p style={{ color: "red", display: "inline" }}>{"*"}</p>
            </h4>
            <hr />
            <Form.Group as={Col} controlId="formBasicName">
              <GenericRadioInput
                name="petTypeAll"
                label=""
                placeholder=""
                radios={[{ id: 1, label: "ทั้งหมด", value: true }]}
                type="checkbox"
                onClick={(e) => {
                  if (e.target.checked) {
                    setValue(
                      "petType",
                      petTypeSelect.map((petType) => {
                        return petType.value.toString();
                      }),
                      { shouldValidate: true }
                    );
                  } else {
                    setValue("petType", [], { shouldValidate: true });
                  }
                }}
              />
              <GenericRadioInput
                register={register}
                name="petType"
                label=""
                placeholder=""
                defaultChecked={petTypeDefaultValue}
                error={errors.petType}
                radios={petTypeSelect}
                type="checkbox"
              />
            </Form.Group>
          </Col>
        </Row>
        <h4>รายละเอียดสินค้า</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextAreaInput
              register={register}
              name="productInfo"
              label="รายละเอียดสินค้า"
              placeholder="รายละเอียดสินค้า"
              defaultValue={productInfoDefaultValue}
              required
              error={errors.productInfo}
            />
          </Form.Group>
        </Row>
        <h4>รูปภาพสินค้า</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="thumbnailPhoto"
              label="Thumbnail Photo"
              placeholder="Thumbnail Photo"
              defaultValue={null}
              error={errors.thumbnailPhoto}
              type="file"
              onChange={handleImageChange}
            />
          </Form.Group>
        </Row>
        {previewImage && (
          <Row className="mb-3">
            <Col
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <img
                src={previewImage}
                alt="Preview"
                style={{ width: "200px" }}
              />
            </Col>
            <Col>
              {!isCreate && (
                <DeleteButton
                  onClick={() => {
                    handleOpenDeleteThumbnailConfirm({ id: id });
                  }}
                />
              )}
            </Col>
          </Row>
        )}
        {!isCreate && (
          <>
            <Row>
              <Col>
                Photo Set
                <Button
                  variant="success"
                  onClick={() => setShowModal(true)}
                  className="ms-3"
                >
                  {" "}
                  Upload
                </Button>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="mx-3">
                <ReactTable columns={columnsData} data={tableData} />
              </Col>
            </Row>
          </>
        )}

        <Row className="mb-3">
          <Col>
            <GenericRadioInput
              register={register}
              name="isSellOnline"
              label="สถานะ (ขายในระบบออนไลน์)"
              placeholder="สถานะ (ขายในระบบออนไลน์)"
              defaultChecked={isSellOnlineDefaultValue}
              error={errors.isSellOnline}
              radios={[{ id: 1, label: "", value: true }]}
              type="switch"
            />
          </Col>
          <div className="d-none">
            <GenericRadioInput
              register={register}
              name="isAlertStockEmpty"
              label="แจ้งเตือนสินค้าหมดเมื่อ Stock ขั้นต่ำ 20 ชิ้น"
              placeholder="แจ้งเตือนสินค้าหมดเมื่อ Stock ขั้นต่ำ 20 ชิ้น"
              defaultChecked={isAlertStockEmptyDefaultValue}
              error={errors.isAlertStockEmpty}
              radios={[{ id: 1, label: "", value: true }]}
              type="switch"
            />
          </div>
          <Col>
            <GenericRadioInput
              register={register}
              name="isShowOnlyInternalSystem"
              label="แสดงที่เฉพาะระบบภายใน"
              placeholder="แสดงที่เฉพาะระบบภายใน"
              defaultChecked={isShowOnlyInternalSystemDefaultValue}
              error={errors.isShowOnlyInternalSystem}
              radios={[{ id: 1, label: "", value: true }]}
              type="switch"
            />
            <GenericRadioInput
              register={register}
              name="isShowProduct"
              label="แสดง Stock สินค้า"
              placeholder="ประเภทสัตว์ที่เกี่ยวข้อง"
              defaultChecked={isShowProductDefaultValue}
              error={errors.isShowProduct}
              radios={[{ id: 1, label: "", value: true }]}
              type="switch"
            />
          </Col>
          <Col>
            <GenericRadioInput
              register={register}
              name="isMedical"
              label="ยาเวชภัณฑ์"
              placeholder="ยาเวชภัณฑ์"
              defaultChecked={isMedicalDefaultValue}
              error={errors.isMedical}
              radios={[{ id: 1, label: "", value: true }]}
              type="switch"
            />
            <GenericRadioInput
              register={register}
              name="isVaccine"
              label="วัคซีน"
              placeholder="วัคซีน"
              defaultChecked={isVaccineDefaultValue}
              error={errors.isVaccine}
              radios={[{ id: 1, label: "", value: true }]}
              type="switch"
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
