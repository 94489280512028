import React, { Fragment } from "react"
import { Form, Col } from "react-bootstrap"

export default function GenericTextInput({
  register,
  name,
  label,
  as = "input",
  placeholder,
  defaultValue,
  value,
  required,
  disabled,
  readOnly,
  plaintext,
  error,
  onChange,
  type = "text",
  viewFormat,
  rows = 3,
  labelColSize = 2,
  min,
}) {
  const renderLabelText = () => {
    return (
      <>
        {label}
        <p style={{ color: "red", display: "inline" }}>{required && "*"}</p>
      </>
    )
  }

  const renderInput = () => {
    if (register) {
      return (
        <Form.Control
          as={as}
          rows={rows}
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...register(name, {
            onChange: (e) => {
              if (onChange) {
                onChange(e)
              }
            },
          })}
          isInvalid={error}
          disabled={disabled}
          readOnly={readOnly}
          plaintext={plaintext}
          min={min}
        />
      )
    } else {
      return (
        <Form.Control
          as={as}
          rows={rows}
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          isInvalid={error}
          disabled={disabled}
          readOnly={readOnly}
          plaintext={plaintext}
          onChange={(e) => {
            if (onChange) {
              onChange(e)
            }
          }}
          min={min}
        />
      )
    }
  }

  const renderFeedBack = () => {
    return (
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    )
  }

  return (
    <>
      {viewFormat === "inline" ? (
        <>
          {label && (
            <Form.Label column sm={labelColSize}>
              {renderLabelText()}
            </Form.Label>
          )}
          <Col>
            {renderInput()} {renderFeedBack()}
          </Col>
        </>
      ) : (
        <>
          {label && <Form.Label>{renderLabelText()}</Form.Label>}
          {renderInput()}
          {renderFeedBack()}
        </>
      )}
    </>
  )
}
