import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import {
  createDetailRecord,
  editDetailRecord,
  createImage,
  deleteImage,
  uploadImage,
  createDetailRecordOnlyData
} from "../../services/BannerServices";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import CreateButton from "../../components/Button/CreateButton";
import GenericDateTimePickerInput from "../../components/Input/GenericDateTimePickerInput";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import UploadImage from "../../components/Button/UploadImage";
import { BannerDetailStatusOptions } from "../../data/SelectOptions";
import DeleteButton from "../../components/Button/DeleteButton";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";



export default function CreateEditBannerDetailForm({ onCancel, onSubmitSuccess, id, banner_id, picture_path, status, order, exp_date, url, loadBannerDetailInfo }) {
  const schema = yup.object().shape({
    url: yup.string().required("URL ห้ามว่าง"),
    order: yup.number().integer().min(0,'มากกว่าหรือเท่ากับ 0').typeError('ลำดับต้องเป็นตัวเลข').required("ลำดับห้ามว่าง"),
    image: yup.mixed().required('รูปห้ามว่าง')
      .test('fileType', 'รูปห้ามว่าง', (value) => {
        if (picture_path) return true;
        return value[0] ? true : false;
      })
      .test('fileType', 'ไฟล์ผิดประเภท', (value) => {
        if (picture_path) return true;
        const supportedFormats = ['image/jpeg', 'image/png'];
        return !value[0] ? false : supportedFormats.includes(value[0].type);
      }),
    // status: yup.number().integer().required(""),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // console.log(picture_path);
  // const [statePicPath, setStatePicPath] = React.useState(picture_path);
  const [previewImage, setPreviewImage] = React.useState(picture_path);
  const [isImageChange, setIsImageChange] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();


  const handleOpenDeleteConfirm = () => {
    setIsDelete(true);
    setDeleteData(id);
    // setPreviewImage();

  };

  const onSubmit = async (inputData) => {
    let status = 0;
    if (inputData.status == '1') {
      status = 1;
    }

    if (id) {
      const { success, data, error } = await editDetailRecord({
        id: id,
        banner_order: inputData.order,
        status: status,
        exp_date: new Date(inputData?.exp_date).toLocaleString("sv"),
        url: inputData.url,
      });
      if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data updated successfully.", 'Data Success', "success");
      } else {
        console.log(error);
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    } else {
      const { success, data, error } = await createDetailRecord({
        banner_id: banner_id,
        banner_order: inputData.order,
        status: status,
        exp_date: new Date(inputData?.exp_date).toLocaleString("sv"),
        url: inputData.url,
        image: inputData.image[0]
      });
      if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data created successfully.", 'Data Success', "success");
      } else {
        console.log(error);
        ShowMessageAndExpire(
          "Failed to create data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    }
  };

  const handleImageChange = async (event) => {
    // setIsImageChange(true);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
    if (id) {
      let errorMessage = await submitImage(file, id);
      if (!errorMessage) {
        loadBannerDetailInfo(1, 10);
        ShowMessageAndExpire("Photo updated successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Update Photo Failed",
          JSON.stringify(errorMessage?.message),
          "error"
        );
      }
    }
  };

  const submitImage = async (imageFile, id) => {
    const { success, error } = await uploadImage({
      id:id,
      image:imageFile,
    });
    if (success) {
      return;
    } else {
      return error;
    }
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (dataId) => {
      const { success, data, error } = await deleteImage({
        data: {
          res_model: "banner_picture",
          res_id: dataId,
          res_field: "picture_path",
        },
      });
      if (success) {
        handleCloseDeleteConfirm();
        loadBannerDetailInfo(1, 10);
        setPreviewImage();

      } else {
        console.log(error);
      }
    },
    []
  );

  return (
    <>
      <DeleteConfirmModal
        show={isDelete}
        onClose={handleCloseDeleteConfirm}
        onComfirm={handleDelete}
        deleteRecord={id}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these image?"}</p>
            <p>
              {`ID ${id}`}
            </p>
          </>
        }
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="url"
              label="URL"
              placeholder="URL"
              defaultValue={url}
              required
              error={errors.url}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericRadioInput
              register={register}
              name="status"
              label="สถานะ"
              placeholder="สถานะ"
              defaultChecked={[status]}
              error={errors.status}
              radios={BannerDetailStatusOptions}
              type="switch"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="order"
              label="ลำดับการแสดง"
              placeholder="ลำดับ"
              defaultValue={order}
              type="number"
              required
              error={errors.order}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="exp_date"
              label="วันที่หมดอายุ"
              type="dateTime"
              defaultValue={new Date(exp_date).getDate()
                ? new Date(exp_date)
                : null}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="image"
              label="รูปภาพ"
              placeholder="รูปภาพ"
              defaultValue={null}
              required
              type="file"
              error={errors.image}
              onChange={handleImageChange}
            />

            {previewImage
              && (<>
                {/* <img src={previewImage} alt="Preview" style={{ marginTop: '10px', width: '600px', height: '200px' }} /> */}
                <img src={previewImage} alt="Preview" style={{ marginTop: '10px', height: '200px' }} />
                {/* {id && <DeleteButton style={{ marginLeft: '20px' }} onClick={handleOpenDeleteConfirm} />} */}
              </>
              )
            }
          </Form.Group>
        </Row>
        <Row className="mb-3">
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </form>
    </>
  );
}
