import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getRecord = async ({ page, pageSize, search, status,dateStart, dateEnd }) => {
  try {
    let datefilter;
    if( dateStart && dateEnd ){
      datefilter ='$btw:'+dateStart+' 00:00:00,'+dateEnd+' 23:59:59';
    }
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/faq/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit: pageSize,
          search,
          'filter.status':status,
          'filter.update_date': datefilter
        },
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};



export const createRecord = async ({
  answer,
  faq_order,
  language,
  question,
  status,
}) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/faq",
      { answer, faq_order, language, question, status },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }

}

export const editRecord = async ({
  id,
  answer,
  faq_order,
  language,
  question,
  status,
}) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/faq/" + id,
      { answer, faq_order, language, question, status },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteRecord = async ({ id }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/faq/" + id,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};
