import { Col, Form, Row } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import CancelButton from "../../components/Button/CancelButton";
import * as yup from "yup";
import React from "react";
import { getCommonAPI } from "../../services/commonAPIServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import { calltypeOptions } from "../../data/SelectOptions";

const schema = yup.object().shape({
  call_date: yup.string().required("ห้ามว่าง"),
  doctor_name: yup.string().required("ห้ามว่าง"),
  member_name: yup.string().required("ห้ามว่าง"),
  call_duration: yup.string().required("ห้ามว่าง"),
  call_type: yup.string().required("ห้ามว่าง"),
});
export default function CallSchuldeDetail({ onCancel, report_id, editData }) {
  const {
    register,
    control,
    isCreate,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log("editData", editData);

  const loadScheduleDetail = React.useCallback(async () => {
    let url_api = "/report/call_schedule_log/" + report_id;
    let p_data = {};
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      // setValue("case_id", data.records?.case_id);
      // setValue("call_date", new Date(data.records?.call_date));
      // setValue("doctor_name", data.records?.doctor_name);
      // setValue("member_name", data.records?.member_name);
      // setValue("call_duration", data.records?.call_duration);
      // setValue("call_type", data.records?.call_type);
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  });

  React.useEffect(() => {
    loadScheduleDetail();
  }, [loadScheduleDetail]);

  return (
    <>
      <Form>
      <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="call_date"
              label="วันที่โทร"
              defaultValue={editData?.call_date}
              required
              error={errors.call_date}
              disabled={!isCreate}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="doctor_name"
              label="ชื่อหมอ"
              defaultValue={editData?.doctor_name}
              required
              error={errors.doctor_name}
              disabled={!isCreate}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="member_name"
              label="ชื่อลูกค้า"
              defaultValue={editData?.user_name}
              required
              error={errors.member_name}
              disabled={!isCreate}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="call_duration"
              label="ระยะเวลาการโทร"
              defaultValue={editData?.call_duration}
              required
              error={errors.call_duration}
              disabled={!isCreate}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericRadioInput
              register={register}
              name="call_type"
              defaultChecked={editData?.call_type}
              error={errors.call_type}
              radios={calltypeOptions}
              type="radio"
              disabled={!isCreate}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
        </Row>
      </Form>
    </>
  );
}
