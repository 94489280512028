import React from "react";
import {
  Modal,
  ButtonToolbar,
  ToggleButtonGroup,
  Button,
} from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import CancelButton from "../components/Button/CancelButton";
import CreateButton from "../components/Button/CreateButton";
import GenericButton from "../components/Button/GenericButton";
import RadioButton from "../components/Button/RadioButton";
import { createCommonAPI } from "../services/commonAPIServices";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";

export default function DoctorTimeTableApproved({
  listdays,
  doctorId,
  onSuccess,
  onSuccessPedding,
}) {
  const [clickId, setClickId] = React.useState();
  const [clickDay, setClickDay] = React.useState();
  const [clickNewValue, setClickNewValue] = React.useState();
  const [modalShow, setModalShow] = React.useState(false);

  const testclick = React.useCallback(async (day, id) => {
    setClickDay(day);
    setClickId(id);
    setModalShow(true);
  }, []);

  const handleDetailModalClose = () => {
    setModalShow(false);
  };

  const handleChange = (val) => {
    setClickNewValue(val);
  };

  const updateTimestatus = React.useCallback(async () => {
    console.log(clickId);
    console.log(clickDay);
    console.log(clickNewValue);
    if (clickNewValue === false) {
      ShowMessageAndExpire("", "ต้องเลือกประเภทลงเวลา");
    } else {
      let days = [
        "",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      const { success, data, error } = await createCommonAPI({
        url_api: "/doctor/update_cell_timetale",
        p_data: {
          doctor_info_id: doctorId,
          day_of_week: days.indexOf(clickDay),
          h: clickId,
          type: clickNewValue,
          time_min: 60,
        },
      });
      if (success) {
        setModalShow(false);
        onSuccess();
        onSuccessPedding();
        setClickDay(false);
        setClickId(false);
        setClickNewValue(false);
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
        setClickDay(false);
        setClickId(false);
        setClickNewValue(false);
        setModalShow(false);
      }
    }
  }, [clickDay, clickId, clickNewValue, doctorId]);

  const listcount = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];
  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <b>ประเภทลงเวลา</b>
        </Modal.Header>
        <Modal.Body>
          <center>
            เลือกประเภทลงเวลา : &nbsp;
            <ToggleButtonGroup
              type="radio"
              name="options"
              onChange={handleChange}
            >
              <ToggleButton
                id="tbg-btn-1"
                value={0}
                variant="outline-secondary"
              >
                <font color="#28a745">แพทย์</font>
              </ToggleButton>
              <ToggleButton
                id="tbg-btn-2"
                value={1}
                variant="outline-secondary"
              >
                <font color="#296DEC">โรงพยาบาล</font>
              </ToggleButton>
              <ToggleButton
                id="tbg-btn-3"
                value={-1}
                variant="outline-secondary"
              >
                <font color="black">ว่าง</font>
              </ToggleButton>
            </ToggleButtonGroup>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <CancelButton onClick={handleDetailModalClose} />
          <GenericButton
            variant="success"
            type="submit"
            onClick={updateTimestatus}
          >
            Save
          </GenericButton>
        </Modal.Footer>
      </Modal>

      <ButtonGroup type="checkbox">
        <Button variant="outline-secondary" style={{ width: "28%", whiteSpace: "nowrap"}} disabled className="ttbtn">
          <font color="black">DAY / TIME</font>
        </Button>
        <Button variant="outline-secondary" style={{ width: "36%" , whiteSpace: "nowrap"}} disabled className="ttbtn">
          <font color="black">AM</font>
        </Button>
        <Button variant="outline-secondary" style={{ width: "36%" , whiteSpace: "nowrap"}} disabled className="ttbtn">
          <font color="black">PM</font>
        </Button>
      </ButtonGroup>
      {listdays.map((listdays, idx) => (
        <ButtonGroup type="checkbox" defaultValue={listdays.defaultValue}>
          <Button
            style={{ width: "28%", whiteSpace: "nowrap"}}
            id={`${listdays.name}-Main-${idx}`}
            value={listdays.name}
            variant="outline-secondary"
            disabled
            className="ttbtn"
          >
            <font color="black">{listdays.name}</font>
          </Button>
          {listcount.map((listcount, idx) => (
            <Button
              style={{
                width: "3%",
                whiteSpace: "nowrap",
                backgroundColor:
                  listdays.defaultValue[idx] === 0
                    ? "#28a745"
                    : listdays.defaultValue[idx] === 1
                    ? "#296DEC"
                    : "",
              }}
              id={`${listdays.name}-checkbox-${idx}`}
              value={listcount}
              variant="outline-secondary"
              onClick={() => testclick(listdays.name, idx)}
            >
              <font color="black">{listcount}</font>
            </Button>
          ))}
        </ButtonGroup>
      ))}
    </>
  );
}
