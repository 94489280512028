import jwtDecode from "jwt-decode";
import { store } from "../global_store/store";
import { refreshToken } from "../services/AuthServices";
import {
  setAccessToken,
  setShowSessionExpiredModal,
} from "../global_store/features/AuthenSlice";
import Cookies from "js-cookie";

export function isTokenValid(token) {
  if (!token) {
    return false;
  }
  try {
    const decodedJWT = jwtDecode(token);
    const currentTime = Date.now().valueOf() / 1000;
    return decodedJWT.exp > currentTime;
  } catch (error) {
    return false;
  }
}

export async function renewAccessToken() {
  /* Get Access Token */
  const { success, data } = await refreshToken();
  if (success) {
    store.dispatch(setAccessToken(data.access_token));
  } else {
    store.dispatch(setShowSessionExpiredModal(true));
  }
  return { success: true, data };
}

export async function getDefaultRequestHeaders(includeAuthorization) {
  const header = {
    "Cache-Control": `no-cache`,
    Pragma: `no-cache`,
    Expires: `0`,
    "Access-Control-Allow-Origin": `*`,
    "x-api-key": process.env.REACT_APP_API_KEY,
  };
  if (includeAuthorization) {
    if (!isTokenValid(store.getState().authen.accessToken)) {
      await renewAccessToken();
    }
    return {
      ...header,
      Authorization: `Bearer ${store.getState().authen.accessToken}`,
    };
  }
  return header;
}
