import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getNotification = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/notice/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createNotification = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/notice",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editNotification = async ({ pathParams, body }) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/notice/" + pathParams,
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteNotification = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/notice/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockNotification = async ({
  page,
  pageSize,
  keyword,
  status,
}) => {
  let records = [];
  let total = 104;
  if (keyword) {
    total = Math.round(total / 2);
  }
  if (status === "done") {
    total = Math.round(total / 3);
  }
  if (status === "waiting") {
    total = Math.round(total / 2);
  }
  const startId = page * pageSize - pageSize + 1;
  const endId = page * pageSize;
  for (let i = startId; i <= endId && i <= total; i++) {
    records.push({
      id: i,
      notiType: "Broadcast",
      notiContent: "text",
      notiDate: new Date().toLocaleString(),
      notiStatus: "สำเร็จแล้ว",
      notiSuccessDate: new Date().toLocaleString(),
      totalSend: "21",
    });
  }
  return {
    success: true,
    data: {
      total: total,
      currentPage: page,
      pageSize: pageSize,
      records: records,
    },
    error: null,
  };
};

export const createMockNotification = async () => {
  return {
    success: true,
    data: {},
    error: null,
  };
};
