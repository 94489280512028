import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericDateTimePickerInput from "../../components/Input/GenericDateTimePickerInput";
import SaveButton from "../../components/Button/SaveButton";
import CancelButton from "../../components/Button/CancelButton";
import * as yup from "yup";
import {
  editMockUserMember,
  editUserMember,
} from "../../services/MemberServices";
import {
  editMockUserDoctor,
  getMockDoctorProfession,
} from "../../services/DoctorServices";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import {
  DoctorOnsiteRadio,
  DoctorStatusRadio,
  filterUserStatusOptions,
} from "../../data/SelectOptions";
import UploadImage from "../../components/Button/UploadImage";
import SelectFilter from "../../components/SelectFilter";
import { membersBooking, userDoctorProfession } from "../../data/Columns";
import React from "react";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import ReactDateTimePicker from "../../components/Input/ReactDateTimePicker";
import EditButton from "../../components/Button/EditButton";
import DeleteButton from "../../components/Button/DeleteButton";
import ReactTable from "../../components/ReactTable";
import {
  deleteCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices";
import AddDoctorDocumentSetModal from "../Modal/AddDoctorDocumentSetModal";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";
import EditDoctorDocumentSetModal from "../Modal/EditDoctorDocumentSetModal";
import GenericPagination from "../../components/GenericPagination";

const allUserStatus = "all";

export default function DoctorEditProfessionForm({
  onCancel,
  onSubmitSuccess,
  id,
  editData,
  documentType,
}) {
  const [total, setTotal] = React.useState(1);
  const [tableData, setTableData] = React.useState([]);
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const [keyword, setKeyword] = React.useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedDate, setSelectedDate] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const [showModalEdit, setShowModalEdit] = React.useState(false);
  const [editDataModal, setEditDataModal] = React.useState([]);
  const [showDocPathImageViewModal, setShowDocPathImageViewModal] =
    React.useState(false);
  const [docPathImage, setDocPathImage] = React.useState();
  const columnsData = userDoctorProfession;

  const filterDocumentType = [{ label: "All", value: "all" }, ...documentType];

  const loadDoctorProfession = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, selectedDate) => {
      let filterDate;
      if (selectedDate) {
        filterDate = `$ilike:${selectedDate.toLocaleDateString("sv")}`;
      } else {
        filterDate = null;
      }
      if (userStatus === "all") {
        userStatus = null;
      }
      const url_api = "/doctor_cert/datatable/";
      const page = currentPage;
      const limit = pageSize;
      const p_data = {
        page,
        "filter.doctor_info_id": id,
        "filter.m_doc_type_id": userStatus?.id,
        "filter.update_date": filterDate,
        limit,
      };
      const { success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        // console.log(error);
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  React.useEffect(() => {
    loadDoctorProfession(1, 10, allUserStatus);
  }, [loadDoctorProfession]);

  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadDoctorProfession(1, pageSize, userStatus, keyword);
    },
    [loadDoctorProfession, pageSize, keyword]
  );

  const handleOpenEditForm = (data) => {
    setShowModalEdit(true);
    setEditDataModal(data);
  };

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadDoctorProfession(page, pageSize, userStatus, keyword);
    },
    [loadDoctorProfession, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadDoctorProfession(1, pageSize, userStatus, keyword);
    },
    [loadDoctorProfession, userStatus, keyword]
  );

  const loadPageOnDatetimeChange = React.useCallback(
    async (datetime) => {
      setCurrentPage(1);
      setSelectedDate(datetime);
      await loadDoctorProfession(1, pageSize, userStatus, keyword, datetime);
    },
    [loadDoctorProfession, pageSize, keyword]
  );

  const handleDelete = React.useCallback(
    async (id) => {
      const url_api = "/doctor_cert/" + id;
      const { success, data, error } = await deleteCommonAPI({
        url_api,
      });
      if (success) {
        handleCloseDeleteConfirm();
        loadDoctorProfession();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadDoctorProfession(tempCurrentPage, pageSize, userStatus, keyword);
      } else {
        console.log(error);
      }
    },
    [loadDoctorProfession, total, currentPage, pageSize, userStatus, keyword]
  );

  const handleAddPhotoSet = React.useCallback(() => {
    setShowModal(false);
    setShowModalEdit(false);
    loadDoctorProfession(1, 10);
  }, [loadDoctorProfession]);

  const prepareTableData = (datas, startNo, onEdit, onDelete) => {
    let tableData = [];
    console.log("documentType: ", documentType);
    for (const data of datas) {
      let showAttach = (
        <img
          src={data.doc_path}
          alt="Preview"
          style={{
            width: "150px",
          }}
        />
      );
      const isFileDownload = data.doc_path.includes("download/file");
      const isImagePreview = data.doc_path.includes("download/image_preview");
      if (isFileDownload) {
        showAttach = (
          <a href={data.doc_path} download>
            Download File
          </a>
        );
      } else if (isImagePreview) {
        showAttach = (
          <img
            src={data.doc_path}
            alt="Preview"
            style={{
              width: "150px",
            }}
            onClick={() => {
              setShowDocPathImageViewModal(data.doc_path);
              setDocPathImage(data.doc_path);
            }}
          />
        );
      }
      const tempTableData = {
        no: startNo + tableData.length,
        documentNumber: data.doc_no,
        documentType:
          (data.m_doc_type_id &&
            documentType.find((e) => e.id == data.m_doc_type_id)?.label) ||
          "-",
        dateIssue: data.doc_date,
        documentExpDate: data.doc_expdate,
        documentUploadDate: data.update_date,
        attachmentPicture: showAttach,
        remark: data.remark,
      };
      tableData.push({
        ...tempTableData,
        editDeleteButton: (
          <>
            <EditButton
              onClick={() => {
                onEdit(data);
              }}
            />{" "}
            <DeleteButton
              onClick={() => {
                onDelete(data);
              }}
            />
          </>
        ),
      });
    }

    return tableData;
  };

  return (
    <>
      <AddDoctorDocumentSetModal
        show={showModal}
        setShow={() => {
          setShowModal();
        }}
        onSave={handleAddPhotoSet}
        doctorId={id}
      />
      {showDocPathImageViewModal && (
        <Modal
          show={showDocPathImageViewModal}
          onHide={() => {
            setShowDocPathImageViewModal(false);
            setDocPathImage(null);
          }}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img
              src={docPathImage}
              alt="Preview"
              style={{
                width: "450px",
              }}
            />
          </Modal.Body>
        </Modal>
      )}
      {showModalEdit && (
        <EditDoctorDocumentSetModal
          show={showModalEdit}
          setShow={() => {
            setShowModalEdit();
          }}
          onSave={handleAddPhotoSet}
          editData={editDataModal}
        />
      )}
      <DeleteConfirmModal
        show={isDelete}
        onClose={handleCloseDeleteConfirm}
        onComfirm={handleDelete}
        deleteRecord={deleteData?.id}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these record?"}</p>
            <p>{`Document ID = ${deleteData?.id}, เลขที่เอกสาร = ${deleteData?.doc_no}`}</p>
          </>
        }
      />
      {/* Tab content for Profession */}
      <h4>เอกสาร</h4>
      <hr />
      <Row className="mt-3">
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="success"
            onClick={() => setShowModal(true)}
            className="ms-3"
          >
            {" "}
            Upload
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <SelectFilter
            label="ประเภทเอกสาร"
            placeholder={"ประเภทเอกสาร"}
            defaultValue={filterDocumentType.find(
              (e) => e.value == allUserStatus
            )}
            value={filterDocumentType.find((e) => e.value == userStatus)}
            options={filterDocumentType}
            onChange={loadPageOnUserStatusChange}
          />
          <ReactDateTimePicker
            selectedDate={selectedDate}
            setSelectedDate={loadPageOnDatetimeChange}
            placeholder="วันที่อัพโหลดเอกสาร"
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <ReactTable columns={columnsData} data={tableData} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <GenericPagination
            totalPage={Math.ceil(total / pageSize)}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={loadPageOnPageChange}
            onPageSizeChange={loadPageOnPageSizeChange}
          />
        </Col>
      </Row>
    </>
  );
}
