export const getMockUserDoctor = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 14,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          id: "1",
          Doctorsurename: "นพ.มานพ ศรีเขียว",
          phone: "090-xxx-xxxx",
          status: "Enable",
          email: "Test@gmail.com",
          hospital: "โรงพยาบาลหลักสี่",
          onsiteCompile: "ได้",
          registerDate: "25 Jan 2023 13:21",
        },
        {
          id: "2",
          Doctorsurename: "นพ.สกล หอมขจร",
          phone: "090-xxx-xxxx",
          status: "Disable",
          email: "Test1@gmail.com",
          hospital: "โรงพยาบาลกรุงเทพ",
          onsiteCompile: "ไม่ได้",
          registerDate: "25 Jan 2023 13:21",
        },
        {
          id: "3",
          Doctorsurename: "นพ.ณัฐ หอมขจร",
          phone: "090-xxx-xxxx",
          status: "Ban",
          email: "Test2@gmail.com",
          hospital: "โรงพยาบาลเกษตร",
          onsiteCompile: "ไม่ได้",
          registerDate: "25 Jan 2023 13:21",
        },
      ],
    },
    error: null,
  };
};

export const editMockUserDoctor = async ({ id }) => {
  return {
    success: true,
    data: { id },
    error: null,
  };
};

export const deleteMockUserDoctor = async ({ id }) => {
  return {
    success: true,
    data: { id: id },
    error: null,
  };
};

export const getMockDoctorProfession = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 14,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          no: "1",
          documentNumber: "1125868",
          documentType: "ประเภท 1",
          dateIssue: "25 Nov 2021",
          documentExpDate: "25 Jan 2023",
          documentUploadDate: "25 May 2023",
          attachmentPicture: "",
          remark: "",
        },
        {
          no: "1",
          documentNumber: "1125868",
          documentType: "ประเภท 2",
          dateIssue: "25 Nov 2021",
          documentExpDate: "25 Jan 2023",
          documentUploadDate: "25 May 2023",
          attachmentPicture: "",
          remark: "",
        },
      ],
    },
    error: null,
  };
};