import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import { Form, Row, Col, Card } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import {
  getMockBookingLogsChange,
  getMemberInfo,
  getPetInfoById,
  getOwnerInfo,
  getCarInfoById,
  getSubDistrictInfo,
  getDistrictInfo,
  getProvinceInfo,
  createOnlineBooking,
  getHospitalInfo,
  getHospitalInfoId,
  editOnlineBooking,
  getBookingLogsChange,
  getHeadInfo,
} from "../../services/OnlineBookingServices";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import {
  CustomerTypeRadio,
  ServiceTypeRadio,
  CaseStatusOptions,
  CarTypeRadio,
  ServiceTypeRadio_online,
  ServiceTypeRadioOnline,
  ServiceTypeRadioOnsite,
  fixMinCallRadio,
  chatRadio,
} from "../../data/SelectOptions";
import ReactTable from "../../components/ReactTable";
import { bookingLogsChange } from "../../data/Columns";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import moment from "moment";
import SaveButton from "../../components/Button/SaveButton";

const now = new Date(Date.now());

export default function CreateNewOnlineBookingForm({
  onCancel,
  onCreateSuccess,
  editData,
  bookingType,
}) {
  const [yubSchema, setYubSchema] = React.useState(yup.object().shape());
  const {
    register,
    control,
    handleSubmit,
    resetField,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(yubSchema),
  });

  const onSubmit = async (inputData) => {
    var listData = {};
    if (editData) {
      listData.booking_type = editData.booking_type;
    } else {
      listData.booking_type = bookingType;
    }

    if (inputData.customerType === "0" || editData.customer_type === 0) {
      listData.customer_type = editData ? 0 : parseInt(inputData.customerType);
      listData.member_info_id = parseInt(inputData.customerList.value.id);
      listData.pet_info_id = inputData.petName.value.id;
      listData.name = inputData.firstName + " " + inputData.lastName;
      listData.phone = inputData.customerTelephone;
      listData.email = inputData.customerEmail;
    } else {
      listData.customer_type = editData ? 1 : parseInt(inputData.customerType);
      if (!editData) {
        listData.name = inputData.firstName + " " + inputData.lastName;
        listData.phone = inputData.customerTelephone;
        listData.email = inputData.customerEmail;
        listData.brithday = inputData.birthday;
      }
    }

    if (inputData.serviceType === "0") {
      listData.service_type = parseInt(inputData.serviceType);
      // listData.booking_date = moment(inputData.bookingDate).format(
      //   "YYYY-MM-DD hh:mm:ss"
      // );
      // listData.schedule_date = moment(inputData.scheduleDate).format(
      //   "YYYY-MM-DD hh:mm:ss"
      // );
      // listData.schedule_date_to = moment(inputData.scheduleDateTo).format(
      //   "YYYY-MM-DD hh:mm:ss"
      // );

      if (bookingType === 0 || editData?.booking_type === 0) {
        listData.fix_min_call =
          editData?.status === 71
            ? editData.fix_min_call
            : parseInt(inputData.fix_min_call);
        listData.media_type =
          editData?.status === 71
            ? editData?.rel_chat_media_ids[0]?.media_type
            : parseInt(inputData.media_type);
      }
      listData.booking_date =
        editData?.status === 71
          ? new Date(editData.booking_date)
          : new Date(inputData.bookingDate).toLocaleString("sv");
      listData.schedule_date =
        editData?.status === 71
          ? new Date(editData.schedule_date)
          : new Date(inputData?.scheduleDate).toLocaleString("sv");
      listData.schedule_date_to =
        editData?.status === 71
          ? new Date(editData.schedule_date_to)
          : new Date(inputData?.scheduleDateTo).toLocaleString("sv");
      listData.status =
        editData?.status === 71 ? editData.status : inputData.caseStatus.value;
      listData.doctor_info_id =
        editData?.status === 71
          ? editData.doctor_info_id
          : inputData.owner.value;
      listData.remark = inputData.remark;
    } else if (inputData.serviceType === "1") {
      listData.service_type = parseInt(inputData.serviceType);
      // listData.booking_date = moment(inputData.bookingDate).format(
      //   "YYYY-MM-DD hh:mm:ss"
      // );
      // listData.schedule_date = moment(inputData.scheduleDate).format(
      //   "YYYY-MM-DD hh:mm:ss"
      // );
      // listData.schedule_date_to = moment(inputData.scheduleDateTo).format(
      //   "YYYY-MM-DD hh:mm:ss"
      // );
      listData.booking_date = new Date(inputData.bookingDate).toLocaleString(
        "sv"
      );
      listData.schedule_date = new Date(inputData?.scheduleDate).toLocaleString(
        "sv"
      );
      listData.schedule_date_to = new Date(
        inputData?.scheduleDateTo
      ).toLocaleString("sv");
      listData.status = inputData.caseStatus.value;
      listData.van_type = parseInt(inputData.carType);
      listData.van_info_id = parseInt(inputData.carLicense.value.id);
      listData.addressno = inputData.pickUpAddress;
      listData.street = inputData.pickUpStreet;
      listData.m_subdistrict_id = parseInt(
        inputData.pickUpSubDistrict.value.id
      );
      listData.postcode = inputData.pickUpPostcode;
      listData.latitude = inputData.pickUpLatitude;
      listData.longtitude = inputData.pickUpLongitude;
      listData.hospital_info_id = parseInt(inputData.dropOffHospital.value);
    } else {
      listData.doctor_info_id = inputData.owner.value;
      listData.media_type = parseInt(inputData.media_type);
      listData.booking_date = new Date(inputData.bookingDate).toLocaleString(
        "sv"
      );
      listData.schedule_date = new Date(inputData?.scheduleDate).toLocaleString(
        "sv"
      );
      listData.schedule_date_to = new Date(
        inputData?.scheduleDateTo
      ).toLocaleString("sv");
      listData.status = inputData.caseStatus.value;
      listData.van_type = parseInt(inputData.carType);
      listData.van_info_id = parseInt(inputData.carLicense.value.id);
      listData.addressno = inputData.pickUpAddress;
      listData.street = inputData.pickUpStreet;
      listData.m_subdistrict_id = parseInt(
        inputData.pickUpSubDistrict.value.id
      );
      listData.postcode = inputData.pickUpPostcode;
      listData.latitude = inputData.pickUpLatitude;
      listData.longtitude = inputData.pickUpLongitude;
    }
    if (editData?.booking_type === 1) {
      listData.m_heal_id = inputData.m_heal_id?.value;
      listData.symptom2 = inputData.symptom2;
      listData.symptom = inputData.symptom;
    }

    if (!editData) {
      const { success, data, error } = await createOnlineBooking({
        body: listData,
      });
      if (success) {
        ShowMessageAndExpire("Data updated successfully.", "", "success");
        onCreateSuccess();
      } else {
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    } else {
      const { success, data, error } = await editOnlineBooking({
        id: editData.id,
        body: listData,
      });
      if (success) {
        ShowMessageAndExpire("Data updated successfully.", "", "success");
        onCreateSuccess();
      } else {
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    }
  };

  const [memberList, setMemberList] = React.useState([]);

  const memberInfoList = React.useCallback(async () => {
    const { success, data, error } = await getMemberInfo({});
    if (success) {
      setMemberList(prepareMemberInfoList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareMemberInfoList = (datas) => {
    let listsMember = [];
    for (const data of datas) {
      let memberListData = {
        id: data.id,
        label: data.id + " - " + data.name + " " + data.lname,
        value: {
          id: data.id,
          firstName: data.name,
          lastName: data.lname,
          phone: data.phone,
          email: data.email,
          birthday: data.brithday,
        },
      };
      if (
        editData?.member_info_id === data.id &&
        editData?.customer_type === 0
      ) {
        petInfoList(data.id);
        setValue("customerList", memberListData, { shouldValidate: true });
      }
      listsMember.push(memberListData);
    }
    return listsMember;
  };

  const handleChangeCustomerList = (event) => {
    setValue("firstName", event.value.firstName, { shouldValidate: true });
    setValue("lastName", event.value.lastName, { shouldValidate: true });
    setValue("customerEmail", event.value.email, { shouldValidate: true });
    setValue("customerTelephone", event.value.phone, { shouldValidate: true });
    setValue("birthday", new Date(event.value.birthday), {
      shouldValidate: true,
    });
    resetField("petName");
    petInfoList(event.value.id);
  };

  const [petList, setPetList] = React.useState([]);
  const petInfoList = React.useCallback(async (arg) => {
    const { success, data, error } = await getPetInfoById(arg);
    if (success) {
      setPetList(preparePetInfoList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const preparePetInfoList = (datas) => {
    let listsPet = [];
    for (const data of datas) {
      let petListData = {
        id: data.id,
        label: data.id + " - " + data.name + "(Owner :" + data.ownname + ")",
        value: {
          id: data.id,
          petName: data.name,
        },
      };
      if (editData?.pet_info_id === data.id) {
        setValue("petName", petListData, { shouldValidate: true });
      }
      listsPet.push(petListData);
    }
    return listsPet;
  };

  const [ownerList, setOwnerList] = React.useState([]);

  const ownerInfoList = React.useCallback(async () => {
    const { success, data, error } = await getOwnerInfo({});
    if (success) {
      setOwnerList(prepareOwnerInfoList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareOwnerInfoList = (datas) => {
    let listsOwner = [];
    for (const data of datas) {
      let ownerList = {
        id: editData?.doctor_info_id,
        label:
          data.empno +
          " - " +
          data.name +
          " " +
          data.lname +
          " (" +
          data.hospital_name +
          ")",
        value: data.id,
      };
      if (editData?.doctor_info_id === data.id) {
        setValue("owner", ownerList, { shouldValidate: true });
      }
      listsOwner.push(ownerList);
    }
    return listsOwner;
  };

  const handleCarTypeLoad = React.useCallback(async (id) => {
    const { success, data, error } = await getCarInfoById(id);
    if (success) {
      setCarList(prepareCarInfoList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);
  const loadScreen = React.useCallback(async (editid) => {
    let tempYupObj;
    if (editid) {
      //yup for edit form
      tempYupObj = {
        //customertype
        //customerType: yup.string().required(),
        customerList: yup.object().required("ห้ามว่าง"),
        petName: yup.object().required("ห้ามว่าง"),
        firstName: yup.string().required("ห้ามว่าง"),
        lastName: yup.string().required("ห้ามว่าง"),
        customerTelephone: yup.string().required("ห้ามว่าง"),
        // customerEmail: yup.string().required("ห้ามว่าง"),
        birthday: yup.date().required("ห้ามว่าง"),

        // petName: yup.string().when("customerType", {
        //   is: "0",
        //   then: () => yup.object().required("ห้ามว่าง"),
        // }),
        // firstName: yup.string().when("customerType", {
        //   is: "1",
        //   then: () => yup.string().required("ห้ามว่าง"),
        // }),
        // lastName: yup.string().when("customerType", {
        //   is: "1",
        //   then: () => yup.string().required("ห้ามว่าง"),
        // }),
        // customerTelephone: yup.string().when("customerType", {
        //   is: "1",
        //   then: () => yup.string().required("ห้ามว่าง"),
        // }),
        // customerEmail: yup.string().when("customerType", {
        //   is: "1",
        //   then: () => yup.string().required("ห้ามว่าง"),
        // }),
        // birthday: yup.string().when("customerType", {
        //   is: "1",
        //   then: () => yup.date().required("ห้ามว่าง"),
        // }),

        //servicetype
        serviceType: yup.string().required("ห้ามว่าง"),
        fix_min_call: yup.string().when("serviceType", {
          is: 0,
          then: () => yup.date().required("ห้ามว่าง"),
        }),
        media_type: yup.string().when("serviceType", {
          is: 0,
          then: () => yup.date().required("ห้ามว่าง"),
        }),

        bookingDate: yup.date().required("ห้ามว่าง"),
        scheduleDate: yup.date().required("ห้ามว่าง"),
        scheduleDateTo: yup.date().required("ห้ามว่าง"),

        caseStatus: yup.object().required("ห้ามว่าง"),
        //servicetype 0
        owner: yup.object().when("serviceType", {
          is: "0",
          then: () => yup.object().required("ห้ามว่าง"),
        }),

        //servicetype 1
        carType: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.string().required("ห้ามว่าง"),
        }),
        carLicense: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
        pickUpAddress: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.string().required("ห้ามว่าง"),
        }),
        pickUpStreet: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.string().required("ห้ามว่าง"),
        }),
        pickUpSubProvince: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
        pickUpDistrict: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
        pickUpSubDistrict: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
        // pickUpPostcode: yup.string().when("serviceType", {
        //   is: (serviceType) => serviceType === "1" || serviceType === "2",
        //   then: () => yup.string().required("ห้ามว่าง"),
        // }),

        dropOffHospital: yup.string().when("serviceType", {
          is: "1",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
      };
    } else {
      //yup for create form
      tempYupObj = {
        //customertype
        customerType: yup.string().required(),
        customerList: yup.string().when("customerType", {
          is: "0",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
        petName: yup.string().when("customerType", {
          is: "0",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
        firstName: yup.string().when("customerType", {
          is: "1",
          then: () => yup.string().required("ห้ามว่าง"),
        }),
        lastName: yup.string().when("customerType", {
          is: "1",
          then: () => yup.string().required("ห้ามว่าง"),
        }),
        customerTelephone: yup.string().when("customerType", {
          is: "1",
          then: () => yup.string().required("ห้ามว่าง"),
        }),
        // customerEmail: yup.string().when("customerType", {
        //   is: "1",
        //   then: () => yup.string().required("ห้ามว่าง"),
        // }),
        birthday: yup.string().when("customerType", {
          is: "1",
          then: () => yup.date().required("ห้ามว่าง"),
        }),

        //servicetype
        serviceType: yup.string().required("ห้ามว่าง"),
        fix_min_call: yup.string().when("serviceType", {
          is: 0,
          then: () => yup.date().required("ห้ามว่าง"),
        }),
        media_type: yup.string().when("serviceType", {
          is: 0,
          then: () => yup.date().required("ห้ามว่าง"),
        }),
        bookingDate: yup
          .date()
          .required("ห้ามว่าง")
          .min(now, "วันเวลาที่เลือกต้องไม่น้อยกว่าปัจจุบัน"),
        // scheduleDate: yup.date().required("ห้ามว่าง").min(now, "วันเวลาที่เลือกต้องไม่น้อยกว่าปัจจุบัน"),
        // scheduleDateTo: yup.date().required("ห้ามว่าง").min(now, "วันเวลาที่เลือกต้องไม่น้อยกว่า Schedule Date"),
        scheduleDate: yup
          .date()
          .required("ห้ามว่าง")
          .min(now, "วันเวลาที่เลือกต้องไม่น้อยกว่าปัจจุบัน"),
        scheduleDateTo: yup
          .date()
          .required("ห้ามว่าง")
          .when("scheduleDate", (scheduleDate, schema) =>
            scheduleDate
              ? schema.test({
                  name: "date-range",
                  message: "วันเวลาที่เลือกต้องไม่น้อยกว่า Schedule Date",
                  test: function (scheduleDateTo) {
                    // 'this' refers to the Yup context with the values of the object being validated
                    return (
                      scheduleDateTo &&
                      scheduleDate &&
                      new Date(scheduleDateTo) > new Date(scheduleDate)
                    );
                  },
                })
              : schema
          ),

        caseStatus: yup.object().required("ห้ามว่าง"),
        //servicetype 0
        owner: yup.object().when("serviceType", {
          is: "0",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
        //servicetype 1
        carType: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.string().required("ห้ามว่าง"),
        }),
        carLicense: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
        pickUpAddress: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.string().required("ห้ามว่าง"),
        }),
        pickUpStreet: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.string().required("ห้ามว่าง"),
        }),
        pickUpSubProvince: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
        pickUpDistrict: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
        pickUpSubDistrict: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
        pickUpPostcode: yup.string().when("serviceType", {
          is: (serviceType) => serviceType === "1" || serviceType === "2",
          then: () => yup.string().required("ห้ามว่าง"),
        }),

        dropOffHospital: yup.string().when("serviceType", {
          is: "1",
          then: () => yup.object().required("ห้ามว่าง"),
        }),
      };
    }
    const schema = yup.object().shape(tempYupObj);
    setYubSchema(schema);
  }, []);

  const [carList, setCarList] = React.useState([]);
  const handleCarTypeClick = React.useCallback(async (event) => {
    const vanType = event.target.value;
    resetField("carLicense");
    resetField("driverPhone");
    const { success, data, error } = await getCarInfoById(vanType);
    if (success) {
      setCarList(prepareCarInfoList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareCarInfoList = (datas) => {
    let listsCar = [];
    for (const data of datas) {
      let carListData = {
        id: data.id,
        label: data.license_place + "(id : " + data.id + ")",
        value: {
          id: data.id,
          phone: data.phone,
        },
      };
      if (editData?.van_info_id === data.id) {
        setValue("carLicense", carListData, { shouldValidate: true });
        setValue("driverPhone", carListData.value.phone, {
          shouldValidate: true,
        });
      }
      listsCar.push(carListData);
    }
    return listsCar;
  };

  const handleChangeCarList = (event) => {
    setValue("driverPhone", event.value.phone, { shouldValidate: true });
  };

  const [subDistrictList, setSubDistrictList] = React.useState([]);

  const subDistrictInfoList = React.useCallback(async (districtId) => {
    const { success, data, error } = await getSubDistrictInfo("", {
      "filter.m_district_id": districtId,
    });
    if (success) {
      setSubDistrictList(prepareSubDistrictList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareSubDistrictList = (datas) => {
    let listsSubDistrict = [];
    for (const data of datas) {
      let listsSubDistrictData = {
        id: data.id,
        label: data.name,
        value: {
          id: data.id,
          postCode: data.postcode,
          DistrictId: data.m_district_id,
        },
      };
      if (editData?.m_subdistrict_id === data.id) {
        setValue("pickUpSubDistrict", listsSubDistrictData, {
          shouldValidate: true,
        });

        //districtInfoList(data.id);
      }
      listsSubDistrict.push(listsSubDistrictData);
    }

    return listsSubDistrict;
  };

  const handlepickUpSubDistrictList = (event) => {
    //setValue("pickUpPostcode", event.value.postCode, { shouldValidate: true });
    districtInfoList(event.value.id);
  };

  const [districtList, setDistrictList] = React.useState([]);

  const districtInfoList = React.useCallback(async (provinceId) => {
    const { success, data, error } = await getDistrictInfo("", {
      "filter.m_province_id": provinceId,
    });
    if (success) {
      setDistrictList(prepareDistrictList(data.records));
      // setValue(
      //   "pickUpDistrict",
      //   {
      //     id: data.records.id,
      //     label: data.records.name,
      //     value: data.records.id,
      //   },
      //   { shouldValidate: true }
      // );
      // provinceInfoList(data.records.m_province_id);
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareDistrictList = (datas) => {
    let listsDistrict = [];
    for (const data of datas) {
      let listsDistrictData = {
        id: data.id,
        label: data.name,
        value: data.id,
      };
      listsDistrict.push(listsDistrictData);
      if (editData?.m_district_id === data.id) {
        setValue("pickUpDistrict", listsDistrictData, {
          shouldValidate: true,
        });
        subDistrictInfoList(editData?.m_district_id);
      }
    }
    return listsDistrict;
  };

  const [provinceList, setProvinceList] = React.useState([]);

  const provinceInfoList = React.useCallback(async () => {
    const { success, data, error } = await getProvinceInfo();
    if (success) {
      setProvinceList(prepareProvinceList(data.records));
      // setValue(
      //   "pickUpSubProvince",
      //   {
      //     id: data.records.id,
      //     label: data.records.name,
      //     value: data.records.id,
      //   },
      //   { shouldValidate: true }
      // );
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareProvinceList = (datas) => {
    let listsProvince = [];
    for (const data of datas) {
      let listsProvinceData = {
        id: data.id,
        label: data.name,
        value: data.id,
      };
      if (editData?.m_province_id === data.id) {
        setValue("pickUpSubProvince", listsProvinceData, {
          shouldValidate: true,
        });
        districtInfoList(editData?.m_province_id);
      }
      listsProvince.push(listsProvinceData);
    }
    return listsProvince;
  };

  const [hospitalList, setHospitalList] = React.useState([]);

  const hospitalInfoList = React.useCallback(async () => {
    const { success, data, error } = await getHospitalInfo({});
    if (success) {
      setHospitalList(prepareHospitalList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareHospitalList = (datas) => {
    let listsHospital = [];
    for (const data of datas) {
      let listsHospitalData = {
        id: data.id,
        label: data.name,
        value: data.id,
      };
      listsHospital.push(listsHospitalData);

      if (editData?.hospital_info_id === data.id) {
        setValue("dropOffHospital", listsHospitalData, {
          shouldValidate: true,
        });
        hospitalInfoListId(data.id);
      }
    }

    return listsHospital;
  };

  const handleChangehospital = (event) => {
    setValue("dropOffAddress", null);
    setValue("dropOffStreet", null);
    setValue("dropOffSubDistrict", null);
    setValue("dropOffSubDistrict", null);
    setValue("dropOffSubDistrict", null);
    setValue("dropOffDistrict", null);
    setValue("dropOffSubProvince", null);
    setValue("dropOffPostcode", null);
    setValue("dropOffLatitude", null);
    setValue("dropOffLongitude", null);
    hospitalInfoListId(event.value);
  };

  const hospitalInfoListId = React.useCallback(async (id) => {
    const { success, data, error } = await getHospitalInfoId(id);
    if (success) {
      setValue("dropOffAddress", data.records[0].addressno, {
        shouldValidate: true,
      });
      setValue("dropOffStreet", data.records[0].street, {
        shouldValidate: true,
      });
      setValue(
        "dropOffSubDistrict",
        {
          id: data.records[0].m_subdistrict_id,
          label: data.records[0].rel_m_subdistrict_id.name,
          value: {
            id: data.records[0].m_subdistrict_id,
            postCode: data.records[0].rel_m_subdistrict_id.postcode,
            DistrictId: data.records[0].rel_m_subdistrict_id.m_district_id,
          },
        },
        { shouldValidate: true }
      );
      setValue(
        "dropOffPostcode",
        data.records[0].rel_m_subdistrict_id.postcode,
        {
          shouldValidate: true,
        }
      );
      setValue("dropOffLatitude", data.records[0].latitude, {
        shouldValidate: true,
      });
      setValue("dropOffLongitude", data.records[0].longtitude, {
        shouldValidate: true,
      });
      districtInfoListDropOff(
        data.records[0].rel_m_subdistrict_id.m_district_id
      );
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const districtInfoListDropOff = React.useCallback(async (DistrictId) => {
    const { success, data, error } = await getDistrictInfo(DistrictId);
    if (success) {
      setValue(
        "dropOffDistrict",
        {
          id: data.records.id,
          label: data.records.name,
          value: data.records.id,
        },
        { shouldValidate: true }
      );
      provinceInfoListDropOff(data.records.m_province_id);
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const [ProvinceListDropoff, setProvinceListDropoff] = React.useState([]);
  const provinceInfoListDropOff = React.useCallback(async (ProvinceId) => {
    const { success, data, error } = await getProvinceInfo(ProvinceId);
    if (success) {
      setProvinceListDropoff(prepareProvinceListDropoff(data.records));
      setValue(
        "dropOffSubProvince",
        {
          id: data.records.id,
          label: data.records.name,
          value: data.records.id,
        },
        { shouldValidate: true }
      );
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);
  const prepareProvinceListDropoff = (data) => {
    let listsProvince = [];
    listsProvince.push({
      id: data.id,
      label: data.name,
      value: data.id,
    });
    return listsProvince;
  };

  const [isShownCustomerType, setIsShownCustomerType] = React.useState(false);
  const handleCustomerTypeClick = (event) => {
    clearErrors();
    if (event.target.value === "0") {
      setIsShownCustomerType(false);
      resetField("customerList");
      resetField("petName");
      resetField("firstName");
      resetField("lastName");
      resetField("customerTelephone");
      resetField("customerEmail");
      resetField("birthday");
    } else {
      setIsShownCustomerType(true);
      resetField("customerList");
      resetField("petName");
      resetField("firstName");
      resetField("lastName");
      resetField("customerTelephone");
      resetField("customerEmail");
      resetField("birthday");
    }
  };

  const handleFixMinCallClick = (value) => {
    let TimeCal = value.target.value;
    let dateData = getValues("scheduleDate");
    calScheduleDateTo(dateData, TimeCal);
  };

  const handleScheduleDateChange = (value) => {
    let TimeCal = getValues("fix_min_call");
    let dateData = value;
    calScheduleDateTo(dateData, TimeCal);
  };

  const calScheduleDateTo = (dateData, TimeCal) => {
    resetField("scheduleDateTo");
    let newDateObj;
    if (dateData) {
      if (TimeCal === "20") {
        newDateObj = moment(dateData).add(30, "m").toDate();
      } else {
        newDateObj = moment(dateData).add(60, "m").toDate();
      }
      setValue("scheduleDateTo", newDateObj);
    }
  };

  const [isShownServiceType, setIsShownServiceType] = React.useState("consult");
  const handleServiceTypeClick = (event) => {
    clearErrors();
    if (event.target.value === "0") {
      setIsShownServiceType("consult");
      resetField("carType");
      resetField("carLicense");
      resetField("driverPhone");

      resetField("pickUpAddress");
      resetField("pickUpStreet");
      resetField("pickUpSubDistrict");
      resetField("pickUpDistrict");
      resetField("pickUpSubProvince");
      resetField("pickUpPostcode");
      resetField("pickUpLatitude");
      resetField("pickUpLongitude");

      resetField("dropOffHospital");
      resetField("dropOffAddress");
      resetField("dropOffStreet");
      resetField("dropOffSubDistrict");
      resetField("dropOffDistrict");
      resetField("dropOffSubProvince");
      resetField("dropOffPostcode");
      resetField("dropOffLatitude");
      resetField("dropOffLongitude");
    } else if (event.target.value === "1") {
      setIsShownServiceType("shuttle");
      resetField("owner");
    } else {
      setIsShownServiceType("onsite");
      resetField("dropOffHospital");
      resetField("dropOffAddress");
      resetField("dropOffStreet");
      resetField("dropOffSubDistrict");
      resetField("dropOffDistrict");
      resetField("dropOffSubProvince");
      resetField("dropOffPostcode");
      resetField("dropOffLatitude");
      resetField("dropOffLongitude");
    }
  };

  const allUserStatus = "all";
  const [tableBookingLogsChange, setTableBookingLogsChange] = React.useState(
    []
  );

  const loadBookingLogsChange = React.useCallback(async () => {
    if (editData) {
      const { success, data, error } = await getBookingLogsChange({
        booking_id: editData?.id,
      });
      if (success) {
        setTableBookingLogsChange(prepareBookingLogsChange(data.records));
      } else {
        console.log(error);
      }
    }
  }, []);
  React.useEffect(() => {
    loadBookingLogsChange(1, 10, allUserStatus);
  }, [loadBookingLogsChange]);

  const prepareBookingLogsChange = (datas) => {
    let listsLogs = [];
    for (const data of datas) {
      let lists = {
        changeDatetime: data.create_date,
        ChangeType: data.change_type,
        ChangeFrom: data.change_from,
        ChangeTo: data.change_to,
        remark: data.remark,
        UpdateBy: data.update_by,
      };
      listsLogs.push(lists);
    }

    return listsLogs;
  };

  const columnsBookingLogsChange = bookingLogsChange;

  const [healist, setHealist] = React.useState([]);
  const healInfoList = React.useCallback(async () => {
    const { success, data, error } = await getHeadInfo();
    if (success) {
      setHealist(prepareHealInfoList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareHealInfoList = (datas) => {
    let lists = [];
    for (const data of datas) {
      let ListData = {
        id: data.id,
        label: data.name,
        value: data.id,
      };
      if (editData?.m_heal_id === data.id) {
        setValue("m_heal_id", ListData, { shouldValidate: true });
      }
      lists.push(ListData);
    }
    return lists;
  };

  React.useEffect(() => {
    memberInfoList();
    ownerInfoList();
    //subDistrictInfoList();
    provinceInfoList();
    hospitalInfoList();
    healInfoList();
    handleCarTypeLoad(editData?.van_type);
    loadScreen(editData?.id);
  }, [
    memberInfoList,
    ownerInfoList,
    //subDistrictInfoList,
    provinceInfoList,
    hospitalInfoList,
    healInfoList,
    loadScreen,
  ]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>ข้อมูลลูกค้า</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group
            as={Col}
            controlId="formBasicName"
            onChange={handleCustomerTypeClick}
          >
            <GenericRadioInput
              register={register}
              name="customerType"
              radios={CustomerTypeRadio}
              label={"ประเภทลูกค้า"}
              error={errors.customerType}
              defaultChecked={
                editData ? [String(editData?.customer_type)] : ["0"]
              }
              disabled={editData}
            />
          </Form.Group>
        </Row>
        {getValues("customerType") === "1" || editData?.customer_type === 1 ? (
          <Row className="mb-3">
       

       <Card>
      <Card.Body>
                {" "}
                <p><b> ขั้นตอนการสร้างข้อมูลลูกค้า  </b></p>
                <p>1. สร้างข้อมูลลูกค้าใหม่  <a href="/pages/customer-info-management"  target="_blank">คลิก</a> </p> 
                <p>2. สร้างข้อมูลสัตว์เลี้ยงของลูกค้าใหม่  <a href="/pages/pet-info-management"  target="_blank">คลิก </a></p>
              </Card.Body>
    </Card>
              
  
          </Row>
       ) : null}
        {getValues("customerType") === "0" || editData?.customer_type === 0 ? (
          <>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="customerList"
                  label="รายการลูกค้า"
                  placeholder=""
                  options={memberList}
                  onChange={handleChangeCustomerList}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="firstName"
                  label="ชื่อ"
                  placeholder="ชื่อ"
                  defaultValue={
                    editData ? editData.rel_member_info_id.name : null
                  }
                  type="text"
                  variant=""
                  error={errors.firstName}
                  readOnly={!isShownCustomerType}
                  required={isShownCustomerType}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="lastName"
                  label="นามสกุล"
                  placeholder="นามสกุล"
                  defaultValue={
                    editData ? editData.rel_member_info_id.lname : null
                  }
                  type="text"
                  variant=""
                  error={errors.lastName}
                  readOnly={!isShownCustomerType}
                  required={isShownCustomerType}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              {/* {getValues("customerType") === "0" && ( */}
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="petName"
                  label="สัตว์เลี้ยง"
                  placeholder="สัตว์เลี้ยง"
                  options={petList}
                  required
                  disabled={
                    editData?.status
                      ? editData?.status === 70
                        ? true
                        : false
                      : false
                  }
                />
              </Form.Group>
              {/* )} */}
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="customerTelephone"
                  label="เบอร์โทรศัพท์"
                  placeholder="เบอร์โทรศัพท์"
                  defaultValue={
                    editData ? editData.rel_member_info_id.phone : null
                  }
                  type="text"
                  variant=""
                  error={errors.customerTelephone}
                  readOnly={!isShownCustomerType}
                  required={isShownCustomerType}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="customerEmail"
                  label="อีเมล"
                  placeholder="อีเมล"
                  defaultValue={
                    editData ? editData.rel_member_info_id.email : null
                  }
                  type="text"
                  variant=""
                  error={errors.customerEmail}
                  readOnly={!isShownCustomerType}
                  required={isShownCustomerType}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formBasicName">
                <GenericDateTimePickerInput2
                  control={control}
                  name="birthday"
                  label="วันเกิด"
                  type="date"
                  required={isShownCustomerType}
                  error={errors.birthday}
                  defaultValue={
                    editData && new Date(editData.rel_member_info_id.brithday)
                  }
                  disabled={!isShownCustomerType}
                />
              </Form.Group>
            </Row>
          </>
          ) : null}

        <h4>ข้อมูลการนัดหมาย</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group
            as={Col}
            controlId="formBasicName"
            onChange={handleServiceTypeClick}
          >
            <GenericRadioInput
              register={register}
              name="serviceType"
              radios={
                bookingType === 0 || editData?.booking_type === 0
                  ? ServiceTypeRadioOnline
                  : ServiceTypeRadioOnsite
              }
              label={"ประเภทบริการ"}
              error={errors.serviceType}
              defaultChecked={
                editData
                  ? String(editData.service_type)
                  : bookingType === 0
                  ? "0"
                  : "1"
              }
            />
          </Form.Group>
        </Row>
        {getValues("serviceType") === "0" && (
          <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="formBasicName"
              onChange={handleFixMinCallClick}
            >
              <GenericRadioInput
                register={register}
                name="fix_min_call"
                radios={fixMinCallRadio}
                label={"ช่วงเวลา"}
                error={errors.fix_min_call}
                required
                defaultChecked={
                  editData ? editData.fix_min_call?.toString() : "20"
                }
                disabled={
                  editData?.status
                    ? editData?.status === 71
                      ? true
                      : false
                    : false
                }
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericRadioInput
                register={register}
                name="media_type"
                radios={chatRadio}
                label={"Chat"}
                error={errors.media_type}
                required
                defaultChecked={
                  editData
                    ? editData.rel_chat_media_ids[0]?.media_type?.toString()
                    : "0"
                }
                disabled={
                  editData?.status
                    ? editData?.status === 71
                      ? true
                      : false
                    : false
                }
              />
            </Form.Group>
          </Row>
        )}
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="bookingDate"
              label="Booking Date"
              placeholder="Booking Date"
              type="dateTime"
              required
              error={errors.bookingDate}
              defaultValue={
                editData ? new Date(editData.booking_date) : new Date()
              }
              disabled={
                editData?.status
                  ? editData?.status === 71
                    ? true
                    : false
                  : false
              }
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="scheduleDate"
              label="Schedule Date"
              placeholder="Schedule Date"
              type="dateTime"
              required
              error={errors.scheduleDate}
              defaultValue={editData ? new Date(editData.schedule_date) : null}
              onChange={
                bookingType === 0 || editData?.booking_type === 0
                  ? handleScheduleDateChange
                  : () => {}
              }
              disabled={
                editData?.status
                  ? editData?.status === 71
                    ? true
                    : false
                  : false
              }
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="scheduleDateTo"
              label="To"
              placeholder="Schedule Date To"
              type="dateTime"
              required
              error={errors.scheduleDateTo}
              defaultValue={
                editData ? new Date(editData.schedule_date_to) : null
              }
              disabled={
                bookingType === 0 ||
                editData?.booking_type === 0 ||
                editData?.status === 71
              }
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="caseStatus"
              label="Case Status"
              placeholder="Case Status"
              defaultValue={editData ? editData.status : null}
              options={CaseStatusOptions}
              required
              disabled={
                editData?.status
                  ? editData?.status === 71
                    ? true
                    : false
                  : false
              }
            />
          </Form.Group>

          {(getValues("serviceType") === "0" ||
            getValues("serviceType") === "2") && (
            <Form.Group as={Col} controlId="formBasicName">
              <GenericSelectInput
                control={control}
                name="owner"
                label="Owner"
                placeholder="Owner"
                options={ownerList}
                required
                disabled={
                  editData?.status
                    ? editData?.status === 71
                      ? true
                      : false
                    : false
                }
              />
            </Form.Group>
          )}
        </Row>

        {(getValues("serviceType") === "1" ||
          getValues("serviceType") === "2") && (
          <Row className="mb-3">
            <Form.Group
              as={Col}
              controlId="formBasicName"
              onChange={handleCarTypeClick}
            >
              <GenericRadioInput
                register={register}
                name="carType"
                radios={CarTypeRadio}
                label={"ประเภทรถ"}
                error={errors.carType}
                defaultChecked={editData ? String(editData.van_type) : ""}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericSelectInput
                control={control}
                name="carLicense"
                label="ทะเบียนรถ"
                placeholder=""
                options={carList}
                required
                onChange={handleChangeCarList}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="driverPhone"
                label="เบอร์โทรศัพท์คนขับ"
                placeholder=""
                defaultValue=""
                type="text"
                variant=""
                error={errors.driverPhone}
                disabled
              />
            </Form.Group>
          </Row>
        )}

        {(getValues("serviceType") === "1" ||
          getValues("serviceType") === "2") && (
          <>
            <h4>ข้อมูลสถานที่</h4>
            <hr />
            จุดที่รับ
            <hr />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="pickUpAddress"
                  label="Address"
                  placeholder=""
                  defaultValue={editData ? editData.addressno : null}
                  type="text"
                  variant=""
                  error={errors.pickUpAddress}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="pickUpStreet"
                  label="Street"
                  placeholder=""
                  defaultValue={editData ? editData.street : null}
                  type="text"
                  variant=""
                  error={errors.pickUpStreet}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="pickUpSubProvince"
                  label="Province"
                  placeholder=""
                  defaultValue={null}
                  options={provinceList}
                  required
                  onChange={(e) => {
                    if (e?.value) {
                      districtInfoList(e.value);
                      setValue("pickUpDistrict", null);
                      setSubDistrictList([]);
                      setValue("pickUpSubDistrict", null);
                      setValue("pickUpPostcode", null);
                    } else {
                      setDistrictList([]);
                      setValue("pickUpDistrict", null);
                      setSubDistrictList([]);
                      setValue("pickUpSubDistrict", null);
                      setValue("pickUpPostcode", null);
                    }
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="pickUpDistrict"
                  label="District"
                  placeholder=""
                  defaultValue={null}
                  options={districtList}
                  disabled={districtList.length === 0}
                  required
                  onChange={(e) => {
                    if (e?.value) {
                      subDistrictInfoList(e.value);
                      setSubDistrictList([]);
                      setValue("pickUpSubDistrict", null);
                      setValue("pickUpPostcode", null);
                    } else {
                      setSubDistrictList([]);
                      setValue("pickUpSubDistrict", null);
                      setValue("pickUpPostcode", null);
                    }
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="pickUpSubDistrict"
                  label="Sub-District"
                  options={subDistrictList}
                  disabled={subDistrictList.length === 0}
                  required
                  onChange={(e) => {
                    if (e?.value) {
                      setValue("pickUpPostcode", e.value?.postCode, {
                        shouldValidate: true,
                      });
                    } else {
                      setValue("pickUpPostcode", null);
                    }
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="pickUpPostcode"
                  label="Postcode"
                  placeholder="Postcode"
                  disabled={subDistrictList.length === 0}
                  type="text"
                  variant=""
                  error={errors.pickUpPostcode}
                  required
                  readOnly
                  defaultValue={editData ? editData.postcode : null}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="pickUpLatitude"
                  label="Latitude"
                  placeholder=""
                  defaultValue=""
                  type="text"
                  variant=""
                  error={errors.pickUpLatitude}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="pickUpLongitude"
                  label="Longitude"
                  placeholder=""
                  defaultValue=""
                  type="text"
                  variant=""
                  error={errors.pickUpLongitude}
                  disabled
                />
              </Form.Group>
            </Row>
          </>
        )}
        {getValues("serviceType") === "1" && (
          <>
            จุดที่ส่ง
            <hr />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="dropOffHospital"
                  label="โรงพยาบาล"
                  placeholder=""
                  options={hospitalList}
                  required
                  onChange={handleChangehospital}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName"></Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="dropOffAddress"
                  label="Address"
                  placeholder=""
                  defaultValue=""
                  type="text"
                  variant=""
                  error={errors.dropOffAddress}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="dropOffStreet"
                  label="Street"
                  placeholder=""
                  defaultValue=""
                  type="text"
                  variant=""
                  error={errors.dropOffStreet}
                  disabled
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="dropOffSubDistrict"
                  label="Sub-District"
                  placeholder=""
                  defaultValue={null}
                  options={subDistrictList}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="dropOffDistrict"
                  label="District"
                  placeholder=""
                  defaultValue={null}
                  options={districtList}
                  disabled
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="dropOffSubProvince"
                  label="Province"
                  placeholder=""
                  defaultValue={null}
                  options={ProvinceListDropoff}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="dropOffPostcode"
                  label="Postcode"
                  placeholder="Postcode"
                  defaultValue=""
                  type="text"
                  variant=""
                  error={errors.dropOffPostcode}
                  disabled
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="dropOffLatitude"
                  label="Latitude"
                  placeholder=""
                  defaultValue=""
                  type="text"
                  variant=""
                  error={errors.dropOffLatitude}
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="dropOffLongitude"
                  label="Longitude"
                  placeholder=""
                  defaultValue=""
                  type="text"
                  variant=""
                  error={errors.dropOffLongitude}
                  disabled
                />
              </Form.Group>
            </Row>
          </>
        )}
        {editData?.booking_type === 1 && (
          <>
            <h4>อาการ</h4>
            <hr />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="m_heal_id"
                  label="วิธีการรักษา"
                  placeholder="วิธีการรักษา"
                  options={healist}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextAreaInput
                  register={register}
                  name="symptom2"
                  label="อาการสัตว์เลี้ยง"
                  placeholder="อาการสัตว์เลี้ยง"
                  variant=""
                  error={errors.symptom2}
                  defaultValue={editData ? String(editData?.symptom2) : ""}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="symptom"
                  label="ช่วงเวลาที่แสดงอาการ"
                  placeholder="ช่วงเวลาที่แสดงอาการ"
                  type="text"
                  variant=""
                  defaultValue={editData ? String(editData.symptom) : ""}
                  error={errors.symptom}
                />
              </Form.Group>
            </Row>
          </>
        )}
        {(getValues("serviceType") === "0" ||
          getValues("serviceType") === "2") && (
          <>
            <h4>หมายเหตุ</h4>
            <hr />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextAreaInput
                  register={register}
                  name="remark"
                  label="Remark"
                  variant=""
                  error={errors.remark}
                  defaultValue={editData ? String(editData.remark) : ""}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                {" "}
              </Form.Group>
            </Row>
          </>
        )}

        {(getValues("serviceType") === "1" ||
          getValues("serviceType") === "2") &&
          editData && (
            <>
              <h4>ข้อมูลการเปลี่ยนแปลง</h4>
              <hr />
              <Row className="mb-3">
                <ReactTable
                  columns={columnsBookingLogsChange}
                  data={tableBookingLogsChange}
                />
              </Row>
            </>
          )}

        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </form>
    </>
  );
}
