import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import {
  createRecord,
  editRecord,
} from "../../services/FaqServices";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import UploadImage from "../../components/Button/UploadImage";
import GenericButton from "../../components/Button/GenericButton";
import ReactTable from "../../components/ReactTable";
import DeleteButton from "../../components/Button/DeleteButton";
import EditButton from "../../components/Button/EditButton";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";
import { StatusOptions, LanguageOptions } from "../../data/SelectOptions";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import RichTextArea from "../../components/Input/RichTextArea";

const schema = yup.object().shape({
  language: yup.object().required("ภาษาห้ามว่าง"),
  status: yup.object().required("สถานะห้ามว่าง"),
  faq_order: yup.number().integer().min(0,'มากกว่าหรือเท่ากับ 0').typeError('ลำดับต้องเป็นตัวเลข').required("ลำดับห้ามว่าง"),
  question: yup.string().required("คำถามห้ามว่าง"),
  answer: yup.string().required("คำตอบห้ามว่าง"),

});

export default function CreateEditFaqForm({ onCancel, onSubmitSuccess, id, faq_order, status, question, answer, language }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (inputData) => {
    if (id) {
      const { success, data, error } = await editRecord({
        id: id,
        answer: inputData.answer,
        faq_order: inputData.faq_order,
        language: inputData.language.value,
        question: inputData.question,
        status: inputData.status.value,
      });
      if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data updated successfully.", 'Data Success', "success");
      } else {
        console.log(error);
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        );
      }

    } else {
      // console.log('create');
      const { success, data, error } = await createRecord({
        answer: inputData.answer,
        faq_order: inputData.faq_order,
        language: inputData.language.value,
        question: inputData.question,
        status: inputData.status.value,

      });
      if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data created successfully.", 'Data Success', "success");
      } else {
        console.log(error);
        ShowMessageAndExpire(
          "Failed to create data.",
          JSON.stringify(error?.message),
          "error"
        );
      }

    }

  };

  return (
    <>

      <Container fluid style={{ minHeight: "30rem" }}>
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <Col sm="1" style={{ minWidth: "10rem" }}>
                <GenericSelectInput
                  control={control}
                  name="language"
                  label="Language"
                  placeholder="Language"
                  defaultValue={language}
                  options={LanguageOptions}
                  required
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="status"
                  label="สถานะ"
                  placeholder="สถานะ"
                  defaultValue={status}
                  options={StatusOptions}
                  required
                  error={errors.status}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="faq_order"
                  label="ลำดับการแสดง"
                  placeholder="ลำดับการแสดง"
                  defaultValue={faq_order}
                  type="number"
                  required
                  error={errors.faq_order}
                />
              </Form.Group>
            </Row>
            <Row className="mb-6">
              <Form.Group as={Col} controlId="formBasicName">
                <RichTextArea
                  label="คำถาม"
                  name="question"
                  defaultValue={question}
                  control={control}
                  required
                  error={errors.question}
                />
              </Form.Group>

            </Row>
            <Row className="mb-6">
              <Form.Group as={Col} controlId="formBasicName">
                <RichTextArea
                  label="คำตอบ"
                  name="answer"
                  defaultValue={answer}
                  control={control}
                  required
                  error={errors.answer}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
            </Row>
            <Row className="mb-3">
            </Row>
            <Row className="mb-3">
              <Col>
                <CancelButton onClick={onCancel} />
              </Col>
              <Col style={{ display: "flex", justifyContent: "end" }}>
                {/* <CreateButton /> */}
                <SaveButton />
              </Col>
            </Row>
          </form>
        </>


      </Container>


    </>
  );
}

