import React from "react";
import * as yup from "yup";
import { Col, Form, Row } from "react-bootstrap";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { editStatusExpenseTransaction } from "../../services/ExpenseTransactionServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import { revenueStatusOptions } from "../../data/SelectOptions";

const schema = yup.object().shape({
  revenueStatus: yup.object().required("ห้ามว่าง"),
});

export default function EditExpenseTransactionStatusForm({
  onCancel,
  onSubmitSuccess,
  ids = [],
}) {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (inputData) => {
    let successCount = 0;
    let errorCount = 0;
    let errorMessage = "";
    for (const id of ids) {
      console.log(id);
      const { success, data, error } = await editStatusExpenseTransaction({
        body: {
          id : id,
          status : inputData.revenueStatus?.value,
        },
      });
      if (success) {
        successCount += 1;
      } else {
        errorCount += 1;
        errorMessage = error;
      }
    }
    if (errorCount === 0) {
      onSubmitSuccess();
      ShowMessageAndExpire("Data updated successfully.", "", "success");
    } else if (errorCount > 0 && successCount > 0) {
      onSubmitSuccess();
      ShowMessageAndExpire(
        `Data updated successfully. and Failed`,
        `${successCount} Data Success and ${errorCount} Data Failed`,
        "success"
      );
    } else {
      ShowMessageAndExpire(
        "Failed to update data.",
        JSON.stringify(errorMessage?.message),
        "error"
      );
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Col>
            <h5>{`Total : ${ids.length} รายการ`}</h5>
          </Col>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="revenueStatus"
              label="Change Status To"
              placeholder="Change Status To"
              defaultValue={null}
              options={revenueStatusOptions}
              required
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton
              onClick={() => {
                onCancel();
              }}
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton disabled={ids.length === 0} />
          </Col>
        </Row>
      </Form>
    </>
  );
}
