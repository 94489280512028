import React from "react";
import * as yup from "yup";
import { Col, Form, Modal, Row } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import CancelButton from "../../components/Button/CancelButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SaveButton from "../../components/Button/SaveButton";
import { uploadPhotoSetProduct } from "../../services/ProductManagenentServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import {
  createCommonAPI,
  editCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices";
import { uploadAttachment } from "../../services/SettingServices";
import { f } from "feather-icons";

const schema = yup.object().shape({
  document_number: yup.string().required("ห้ามว่าง"),
  date_issue: yup.string().required("ห้ามว่าง"),
  document_exp_date: yup.string().required("ห้ามว่าง"),
  document_type: yup.object().required("ห้ามว่าง"),
});

export default function EditDoctorDocumentSetModal({
  show,
  setShow,
  onSave,
  editData,
}) {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log("editData: ", editData);
  const [previewImage, setPreviewImage] = React.useState();
  const [documentType, setDocumentType] = React.useState();
  const [showAttach, setShowAttach] = React.useState(false);

  const loadDocumentType = React.useCallback(async () => {
    const url_api = "/m_doc_type";
    const p_data = {
      select: "id,name",
    };
    const { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setDocumentType(prepareDocumentType(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareDocumentType = (datas) => {
    let listsDocument = [];
    console.log("prepareDocumentType: ", datas);
    for (const data of datas) {
      const id = data.id;
      const name = data.name;
      let document = {
        id: id,
        label: name,
        value: {
          id: id,
          name: name,
        },
      };
      listsDocument.push(document);
      if (editData.m_doc_type_id === id) {
        setValue("document_type", document);
      }
    }
    return listsDocument;
  };

  React.useEffect(() => {
    loadDocumentType();
    let Attach = null;
    const isFileDownload = editData.doc_path.includes("download/file");
    if (isFileDownload) {
      // Attach = (
      //   <a href={editData.doc_path} download>
      //     Download File
      //   </a>
      // );
      setShowAttach(true);
    }
    setPreviewImage(editData.doc_path);
  }, [loadDocumentType]);

  const refresh = () => {
    setPreviewImage(null);
    reset();
  };

  const onCancel = async () => {
    setShow(false);
    refresh();
  };

  const onSubmit = async (inputData) => {
    console.log("inputData: ", inputData);
    let url_api = "/doctor_cert/" + editData.id;
    let p_data = {
      doctor_info_id: editData.doctor_info_id,
      doc_no: inputData.document_number,
      m_doc_type_id: inputData.document_type.id,
      doc_date: new Date(inputData.date_issue).toLocaleString("sv"),
      doc_expdate: new Date(inputData.document_exp_date).toLocaleString("sv"),
      remark: inputData.remark,
    };
    let { success, data, error } = await editCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      if (inputData.image[0]) {
        const attachSetFile = inputData.image[0];
        let bodyFormData = new FormData();
        bodyFormData.append("attachment", attachSetFile, attachSetFile?.name);
        bodyFormData.append("res_model", "doctor_cert");
        bodyFormData.append("res_field", "doc_path");
        bodyFormData.append("res_id", data.id);
        ({ success, data, error } = await uploadAttachment({
          bodyFormData: bodyFormData,
        }));
        if (success) {
          ShowMessageAndExpire("บันทึกข้อมูลสำเร็จ", "", "success");
          setShow(false);
          onSave();
          refresh();
        } else {
          ShowMessageAndExpire(
            "บันทึกข้อมูลไม่สำเร็จ",
            error?.message,
            "error"
          );
        }
      } else {
        ShowMessageAndExpire("บันทึกข้อมูลสำเร็จ", "", "success");
        setShow(false);
        onSave();
        refresh();
      }
    }
  };

  const handleImageChange = (event) => {
    setShowAttach(false);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (file.type === "application/pdf") {
        setPreviewImage();
      } else {
        setPreviewImage(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => onCancel()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>แก้ไขเอกสาร</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="document_number"
                label="เลขที่เอกสาร"
                placeholder="เลขที่เอกสาร"
                defaultValue={editData ? editData?.doc_no : null}
                required
                error={errors.document_number}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericSelectInput
                control={control}
                name="document_type"
                label="ประเภทเอกสาร"
                placeholder="ประเภทเอกสาร"
                options={documentType}
                required
                error={errors.document_type}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericDateTimePickerInput2
                control={control}
                name="date_issue"
                label="วันที่ออกเอกสาร"
                placeholder="วันที่ออกเอกสาร"
                type="date"
                defaultValue={editData ? new Date(editData.doc_date) : null}
                required
                error={errors.date_issue}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericDateTimePickerInput2
                control={control}
                name="document_exp_date"
                label="วันที่หมดอายุของเอกสาร"
                placeholder="วันที่หมดอายุของเอกสาร"
                type="date"
                defaultValue={editData ? new Date(editData.doc_expdate) : null}
                required
                error={errors.document_exp_date}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="image"
                label="รูปภาพ"
                placeholder="รูปภาพ"
                defaultValue={null}
                error={errors.image}
                type="file"
                onChange={handleImageChange}
              />
            </Form.Group>
          </Row>
          {previewImage && (
            <Row className="mb-3">
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {showAttach && (
                  <a href={editData.doc_path} download>
                    Download File
                  </a>
                )}
                {!showAttach && (
                  <img
                    src={previewImage}
                    alt="Preview"
                    style={{
                      width: "150px",
                    }}
                  />
                )}
              </Col>
            </Row>
          )}
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextAreaInput
                register={register}
                name="remark"
                label="หมายเหตุจากแอดมิน"
                placeholder="หมายเหตุจากแอดมิน"
                defaultValue={editData ? editData?.remark : null}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Col>
              <CancelButton onClick={() => onCancel()} />
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }}>
              <SaveButton />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
