import React from "react";
import { Form } from "react-bootstrap";
import { ScheduleTimePeriodFilters } from "../../data/Filters";

export default function PeriodTimeFilter({ checked, onChange }) {
  return (
    <>
      <Form>
        <div key={"calendar-radio"} className="mb-3">
          <Form.Check
            inline
            label="All"
            name="radioPeriod"
            type="radio"
            id={"calendar-radio-1"}
            value={ScheduleTimePeriodFilters.All}
            onChange={(e) => onChange(e.target.value)}
            checked={checked === ScheduleTimePeriodFilters.All}
          />
          <Form.Check
            inline
            label="Today"
            name="radioPeriod"
            type="radio"
            id={"calendar-radio-2"}
            value={ScheduleTimePeriodFilters.Today}
            onChange={(e) => onChange(e.target.value)}
            checked={checked === ScheduleTimePeriodFilters.Today}
          />
          <Form.Check
            inline
            label="This Week"
            name="radioPeriod"
            type="radio"
            id={"calendar-radio-3"}
            value={ScheduleTimePeriodFilters.ThisWeek}
            onChange={(e) => onChange(e.target.value)}
            checked={checked === ScheduleTimePeriodFilters.ThisWeek}
          />
          <Form.Check
            inline
            label="Next Week"
            name="radioPeriod"
            type="radio"
            id={"calendar-radio-4"}
            value={ScheduleTimePeriodFilters.NextWeek}
            onChange={(e) => onChange(e.target.value)}
            checked={checked === ScheduleTimePeriodFilters.NextWeek}
          />
          <Form.Check
            inline
            label="This Month"
            name="radioPeriod"
            type="radio"
            id={"calendar-radio-5"}
            value={ScheduleTimePeriodFilters.ThisMonth}
            onChange={(e) => onChange(e.target.value)}
            checked={checked === ScheduleTimePeriodFilters.ThisMonth}
          />
        </div>
      </Form>
    </>
  );
}
