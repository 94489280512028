import { yupResolver } from "@hookform/resolvers/yup"
import React from "react"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import {
  createMockProduct,
  createProductCategory,
  editMockProduct,
  editProductCategory,
} from "../../services/ProductManagenentServices"
import { Col, Form, Row } from "react-bootstrap"
import GenericSelectInput from "../../components/Input/GenericSelectInput"
import GenericTextInput from "../../components/Input/GenericTextInput"
import SaveButton from "../../components/Button/SaveButton"
import CancelButton from "../../components/Button/CancelButton"
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire"
import { languageOptions } from "../../data/SelectOptions"

const schema = yup.object().shape({
  language: yup.object().required("ห้ามว่าง"),
  productCategory: yup.string().required("ห้ามว่าง"),
  //   productBrand: yup.string().required("ห้ามว่าง"),
})

export default function CreateEditProductCategoryForm({
  isCreate,
  onCancel,
  onSubmitSuccess,
  productCategoryDefaultValue,
  productBrandDefaultValue,
  languageDefaultValue,
  id,
}) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async (inputData) => {
    if (isCreate) {
      const { success, error } = await createProductCategory({
        body: {
          language: inputData?.language?.value,
          category_name: inputData.productCategory,
          brand_name: inputData.productBrand,
        },
      })
      if (success) {
        onSubmitSuccess()
        ShowMessageAndExpire("Data created successfully.", "", "success")
      } else {
        ShowMessageAndExpire(
          "Failed to create data.",
          JSON.stringify(error?.message),
          "error"
        )
      }
    } else {
      const { success, data, error } = await editProductCategory({
        pathParams: id,
        body: {
          language: inputData?.language?.value,
          category_name: inputData.productCategory,
          brand_name: inputData.productBrand,
        },
      })
      if (success) {
        onSubmitSuccess()
        ShowMessageAndExpire("Data updated successfully.", "", "success")
      } else {
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        )
      }
    }
  }

  return (
    <>
      <Row className="mb-3">
        <Col sm="1" style={{ minWidth: "10rem" }}>
          <GenericSelectInput
            control={control}
            name="language"
            label="Language"
            placeholder="Language"
            defaultValue={languageDefaultValue}
            required
            options={languageOptions}
          />
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="productCategory"
              label="ชื่อหมวดหมู่สินค้า"
              placeholder="ชื่อหมวดหมู่สินค้า"
              defaultValue={productCategoryDefaultValue}
              required
              error={errors.productCategory}
            />
          </Form.Group>
          {/* <Form.Group as={Col} controlId="formBasicName" className="d-none">
            <GenericTextInput
              register={register}
              name="productBrand"
              label="ยี่ห้อสินค้า"
              placeholder="ยี่ห้อสินค้า"
              defaultValue={productBrandDefaultValue}
              error={errors.productBrand}
            />
          </Form.Group> */}
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  )
}
