import React, { Fragment } from "react";
import { Form, Col } from "react-bootstrap";

export default function GenericTextAreaInput({
  register,
  name,
  label,
  defaultValue,
  placeholder,
  required,
  disabled,
  error,
  viewFormat,
}) {
  return (
    <>
      {viewFormat === "inline" ? (
        <>
          <Form.Label column sm={2}>
            {label}
            <p style={{ color: "red", display: "inline" }}>{required && "*"}</p>
          </Form.Label>
          <Col sm={10}>
          {register ? (
            <Form.Control
              as="textarea"
              rows={3}
              {...register(name)}
              defaultValue={defaultValue}
              placeholder={placeholder}
              isInvalid={error}
              disabled={disabled}
            />
          ) : (
            <Form.Control
              as="textarea"
              rows={3}
              defaultValue={defaultValue}
              placeholder={placeholder}
              isInvalid={error}
              disabled={disabled}
            />
          )}
          </Col>
        </>
      ) : (
        <>
          <Form.Label>
            {label}
            <p style={{ color: "red", display: "inline" }}>{required && "*"}</p>
          </Form.Label>
          {register ? (
            <Form.Control
              as="textarea"
              rows={3}
              {...register(name)}
              defaultValue={defaultValue}
              placeholder={placeholder}
              isInvalid={error}
              disabled={disabled}
            />
          ) : (
            <Form.Control
              as="textarea"
              rows={3}
              defaultValue={defaultValue}
              placeholder={placeholder}
              isInvalid={error}
              disabled={disabled}
            />
          )}
        </>
      )}
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </>
  );
}
