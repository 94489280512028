import React, {  useEffect, useState } from "react";
import Header from "../layouts/Header";
import { getDefaultRequestHeaders } from "../utils/Authen";
import axios from "axios";
import { EaseApp } from "agora-chat-uikit";
import { Badge, Card, Col, Container, Image, ListGroup,  Row } from "react-bootstrap";
import img_avatar from "../assets/img/avatar.png";
import Select from "react-select";
import Avatar from "../components/Avatar";
import { Link } from "react-router-dom";

export default function InboxChatSup() {
  const [menuList, setMenuList] = useState(
    [ {name:"Send a picture", value:"img"}]
  );
  const [showByselfAvatar] = useState(false);
  const [easeInputMenu] = useState('noEmoji');
  let [selectOptions, setSelectOptions] = useState([  ]);
  let [members, setMembers] = useState([]);
  let [peopleSelected, setPeopleSelected] = useState(
    {id: -1, name: 'โปรดเลือกผู้ใช้งาน', photo_path: img_avatar, tags: []}
  );
  let [drawTag, setDrawTag ] = useState([]); 
  const [values, setValues] = React.useState({});
  const [_tags, setTags] = useState([]);
  useEffect(() => {
    fetchAccesstoken();
   },[]);
 
  const fetchAccesstoken = React.useCallback( async () => {

    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/live-chat/loginUser",
      {},
      { headers:  await  getDefaultRequestHeaders(true) }
    );
    
    setValues(res.data);
  });

	const createConversation = (username) => {
		let conversationItem = {
			conversationType: "singleChat",
			conversationId: username,
		};
		EaseApp.addConversationItem(conversationItem);
	};


    const loginSuccessCallback = React.useCallback( async () => {
     
      const WebIM = EaseApp.getSdk();
      const chat_dailog =  document.getElementById("chat-dailog").firstElementChild;
      chat_dailog.style = "height: calc(100%);";
      chat_dailog.firstElementChild.style = "display: flex;height: calc(100%);";
      chat_dailog.firstElementChild.lastElementChild.style = "width: 100%;min-width: auto;";

      WebIM.conn.addEventHandler("Logout", {
        onDisconnected: () => {
          setValues({});
        },
      });

      getTagAll();
      getUserTag("");
      
    });

    const getTagAll = React.useCallback( async () => {
      const resTag = await axios.get(
        process.env.REACT_APP_API_URL + "/webchat/tags",
        {
          headers: await getDefaultRequestHeaders(true)
        }
      );
    
      if(resTag.status === 200){
        const t = [];
        resTag.data.data.forEach(e => {
          t.push( { value: e.id, label: e.name },)
        });
        setSelectOptions(t);
        selectOptions = t;
      }
    });

    const getUserTag = React.useCallback( async (tag) => {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/webchat/members?tags="+tag,
        {   headers: await getDefaultRequestHeaders(true) }
      );
    
      if(res.status === 200){
        setMembers(res.data.data);
        members = res.data.data;
      }
    });
   
    const selectPeople = (people, openChat) => {
      const tags = JSON.parse(JSON.stringify(selectOptions));
      tags.map( (t) => (
        people.tags.forEach( p =>{
          if(t.name === p.tag){
            t.selected = true;
          }
        })
      ));
      setPeopleSelected(people);
      peopleSelected = people;
      setDrawTag(tags);
      drawTag = tags;
      console.log();
      if(openChat === true )
        createConversation(people.username);

    };

   
    const selectTags = (tags) => {
      
      let tagStr = "";
      if( Array.isArray(tags) && tags.length >0  ){
        const arr = [];
        tags.map( (item)=>(
          arr.push( item.value )
        ));
        tagStr = arr.join(",");
      }
      getUserTag(tagStr);
      setTags(tags);
    }; 

    const togleTag = React.useCallback(async ( tag, flag ) => {
      drawTag.forEach( d=>{ if(d.value === tag.value) d.selected = flag});
      setDrawTag([...drawTag]);

      await axios.post(
        process.env.REACT_APP_API_URL + "/webchat/update_tags",
        {
          member_info_id: peopleSelected.id,
          tags: drawTag.filter( f=> f.selected===true).flatMap( f=>{ return f.label })
        },
        {   headers: await getDefaultRequestHeaders(true) }
      );
      selectTags([..._tags]);

    });

    const onConversationClick =  ( chat ) => {
      for(let i = 0; i< members.length ; i++ ){
        if( chat.sessionId === members[i].username){
          selectPeople(members[i], false);
          break;
        }
      }
    };

    return (
    <React.Fragment>
    <Header></Header>
    <div className="main main-app  p-3 ">
        <Container >
          <h5 id="section7" className="main-subtitle">Chat</h5>
  
              <Row className="g-2 h-100vh" style={{backgroundColor: 'white'}}>
                <Col sm="9" id="chat-dailog" className="h-100vh" style={{backgroundColor: 'white'}}>
                       {values.accessToken && (
                        <EaseApp
                        appkey={values.appKey}
                        username={values.username}
                        agoraToken={values.accessToken}
                        successLoginCallback={loginSuccessCallback}
                        showByselfAvatar={showByselfAvatar}
                        menuList={menuList}
                        easeInputMenu={easeInputMenu}
                        onConversationClick= {onConversationClick}
                        />
                        )}  
                </Col>
                <Col sm="3" className="h-100vh">
                  <Card >
                    <Card.Body>
                      <Row className="mt-3">
                        <Col className="text-center" style={{borderRadius: '100%'}}>
                          <Image  style={{width: '100px', borderRadius: 'inherit',  height: '100px'}}  src={peopleSelected.photo_path} fluid={true} alt="..." />
                        </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col  className="text-center">
                            <div className="post-content">
                              <span style={{fontSize: '16px'}}>{peopleSelected.name}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col>
{/* 
                           <br>
                          </br> 
                          {JSON.stringify(peopleSelected)}
                          <br>
                          </br>   */}
                          
                          {drawTag.map( (tag) =>(  
                            tag.selected ? <Badge bg="primary" pill onClick={()=>togleTag(tag, false)}>{tag.label} X</Badge>  : <Badge bg="secondary" pill onClick={()=>togleTag(tag, true)}>{tag.label} +</Badge>
                          ))} 
                          
                          </Col>
                      </Row>
                    </Card.Body>
                    <ListGroup variant="flush">
                    </ListGroup>
                    <Card.Body>
                      {/* {JSON.stringify(members)} */}
                        <Select className="form-control-sm"  options={selectOptions} isSearchable={true} isMulti placeholder="ค้นหาแท็ก" aria-label="Tag" onChange={selectTags}/>
                    </Card.Body>
                    <Card.Body>
                    <ul className="people-group">
                      { members.map((people, index) => (
                        <li className="people-item" key={index}  onClick={() => selectPeople(people, true)}>
                          <Avatar img={people.photo_path} />
                          <div className="people-body">
                            <h6><Link to="">{people.name}</Link></h6>
                          </div>
                        </li>
                      ))}
                    </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}