import { Controller } from 'react-hook-form';
import { Editor } from "@tinymce/tinymce-react";
import { Form } from "react-bootstrap";



const RichTextArea = ({ label, name, defaultValue, control, error, viewFormat, required }) => {

  const renderLabelText = () => {
    return (
      <>
        {label}
        <p style={{ color: "red", display: "inline" }}>{required && "*"}</p>
      </>
    );
  };

  const renderInput = () => {
    if (control) {
      return (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field, fieldState: { error } }) => (
            <Editor
              apiKey={`v2407cbsjnr01d8biop14su6wth2tvvjqxlvad5y72rbc9zn`}
              onInit={(evt, editor) => (field.ref.current = editor)}
              initialValue={defaultValue}
              value={field.value}
              textareaName={name}
              init={{
                height: 500,
                menubar: 'edit insert format table',
                plugins: ['table'],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic forecolor backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                selector: 'textarea',
                init_instance_callback: function (editor) {
                  //hide warning
                  var freeTinyList = document.querySelectorAll('.tox .tox-notification--in');
                  freeTinyList.forEach((element) => {
                    element.style.display = 'none';
                  });

                  //Adjust toolbar and menu bar position
                  var headerBar = editor.getContainer().getElementsByClassName('tox-editor-header')[0];
                  headerBar.style.zIndex = '0';
                  headerBar.style.border = '2px solid #f8f9fc';

                },
                branding: false,
              }}
              onEditorChange={(_, editor) => field.onChange(editor.getContent())}
            />
          )}
        />
      );
    } else {
      return (
        <Controller
          // name={name}
          // control={control}
          defaultValue={defaultValue}
          render={({ field, fieldState: { error } }) => (
            <Editor
              onInit={(evt, editor) => (field.ref.current = editor)}
              initialValue={defaultValue}
              value={field.value}
              textareaName={name}
              init={{
                height: 500,
                menubar: 'edit insert format table',
                plugins: ['table', 'image'],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic forecolor backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | table image',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                selector: 'textarea',
                init_instance_callback: function (editor) {
                  //hide warning
                  var freeTinyList = document.querySelectorAll('.tox .tox-notification--in');
                  freeTinyList.forEach((element) => {
                    element.style.display = 'none';
                  });

                  //Adjust toolbar and menu bar position
                  var headerBar = editor.getContainer().getElementsByClassName('tox-editor-header')[0];
                  headerBar.style.zIndex = '0';
                  headerBar.style.border = '2px solid #f8f9fc';
                },
                branding: false,
              }}
              onEditorChange={(_, editor) => field.onChange(editor.getContent())}
            />
          )}
        />
      );
    }
  };

  return (
    <>
      {label && <Form.Label>{renderLabelText()}</Form.Label>}
      <p style={{ color: "red", display: "inline", marginLeft: "10px" }}>
        {error?.message}
      </p>
      {
        <div style={error && { border: '1px solid red', borderRadius: '10px' }}>
          {renderInput()}
        </div>
      }
      <br></br>
    </>
  );
};

export default RichTextArea;