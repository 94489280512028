import React from "react";
import { Button, Modal } from "react-bootstrap";
import DeleteButton from "../Button/DeleteButton";

export default function DeleteConfirmModal({
  show,
  onClose,
  onComfirm,
  deleteRecord,
  confirmMessage,
}) {
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <DeleteButton onClick={() => onComfirm(deleteRecord)} />
        </Modal.Footer>
      </Modal>
    </>
  );
}
