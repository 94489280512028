import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getAdminUser = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/user_admin/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createAdminUser = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/user_admin",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editAdminUser = async ({ pathParams, body }) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/user_admin/" + pathParams,
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteAdminUser = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/user_admin/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getRoleGroup = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/role_group/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createRoleGroup = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/role_group",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editRoleGroup = async ({ pathParams, body }) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/role_group/" + pathParams,
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteRoleGroup = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/role_group/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getRolePermission = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/role_permission/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createRolePermission = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/role_permission",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editRolePermission = async ({ pathParams, body }) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/role_permission/" + pathParams,
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteRolePermission = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/role_permission/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getScreen = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/m_screen/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockUser = async ({ page, pageSize, keyword, status }) => {
  let records = [];
  let total = 104;
  if (keyword) {
    total = Math.round(total / 2);
  }
  if (status === "enable") {
    total = Math.round(total / 3);
  }
  if (status === "disable") {
    total = Math.round(total / 2);
  }
  const startId = page * pageSize - pageSize + 1;
  const endId = page * pageSize;
  for (let i = startId; i <= endId && i <= total; i++) {
    records.push({
      username: i,
      email: "email",
      role: "role",
      status: "status",
    });
  }
  return {
    success: true,
    data: {
      total: total,
      currentPage: page,
      pageSize: pageSize,
      records: records,
    },
    error: null,
  };
};

export const createUser = async ({
  firstname,
  lastname,
  email,
  telephone,
  role,
  status,
}) => {
  try {
    const res = await axios.post(
      "/api/user",
      { firstname, lastname, email, telephone, role, status },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.log(err.response);
    return { success: false, data: null, error: err.response.data };
  }
};

export const createMockUser = async ({
  firstname,
  lastname,
  email,
  telephone,
  role,
  status,
}) => {
  return {
    success: true,
    data: {},
    error: null,
  };
};

export const editMockUser = async ({
  firstname,
  lastname,
  email,
  telephone,
  role,
  status,
}) => {
  return {
    success: true,
    data: {},
    error: null,
  };
};

export const getMockRolePermission = async ({
  page,
  pageSize,
  keyword,
  status,
}) => {
  let records = [];
  let total = 104;
  if (keyword) {
    total = Math.round(total / 2);
  }
  if (status === "enable") {
    total = Math.round(total / 3);
  }
  if (status === "disable") {
    total = Math.round(total / 2);
  }
  const startId = page * pageSize - pageSize + 1;
  const endId = page * pageSize;
  for (let i = startId; i <= endId && i <= total; i++) {
    records.push({
      id: i,
      role: "Admin",
      createAt: new Date().toLocaleString(),
      roleStatus: "status",
    });
  }
  return {
    success: true,
    data: {
      total: total,
      currentPage: page,
      pageSize: pageSize,
      records: records,
    },
    error: null,
  };
};

export const deleteMockUserRolePermission = async ({ leaveId }) => {
  return {
    success: true,
    data: { leaveId: leaveId },
    error: null,
  };
};
