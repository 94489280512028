import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import { Form, Row, Col } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import CreateButton from "../../components/Button/CreateButton";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import { createCommonAPI } from "../../services/commonAPIServices";

const schema = yup.object().shape({
  firstname: yup.string().required("ชื่อห้ามว่าง"),
  lastname: yup.string().required("นามสกุลห้ามว่าง"),
  // date_birth: yup.string().required("วันเกิดไม่ถูกต้อง"),
  telephone: yup.string().required("เบอร์โทรศัพท์ห้ามว่าง"),
});

export default function CreateNewMembersForm({ onCancel, onCreateSuccess }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (inputData) => {
    const url_api = "/member_info";
    const p_data = {
      name: inputData.firstname,
      lname: inputData.lastname,
      phone: inputData.telephone,
      email: inputData.email,
      brithday: new Date(inputData.date_birth).toLocaleString("sv"),
    };
    const { success, data, error } = await createCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      // onSubmitSuccess();
      onCreateSuccess();
      ShowMessageAndExpire("Data created successfully.", "Data Success", "success");
    } else {
      console.log(error);
      var errorMessages = [];
      if (typeof error.message === "string") {
        errorMessages.push(error.message);
      } else {
        for (var propertyName in error.message) {
          var errorMessage = error.message[propertyName];
          errorMessages.push(errorMessage);
        }
      }
      var concatenatedErrors = errorMessages.join("\n");

      ShowMessageAndExpire("Failed to create data.", concatenatedErrors, "error");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="firstname"
              label="Name"
              placeholder="Name"
              defaultValue=""
              required
              error={errors.firstname}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="lastname"
              label="Lastname"
              placeholder="Lastname"
              defaultValue=""
              required
              error={errors.lastname}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="telephone"
              label="Phone"
              placeholder="Phone"
              defaultValue=""
              required
              error={errors.telephone}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="email"
              label="Email Address"
              placeholder="Email Address"
              defaultValue=""
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="date_birth"
              label="Date of birth"
              placeholder="Date of birth"
              type="date"
              defaultValue={""}
              required
              error={errors.date_birth}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <CreateButton />
          </Col>
        </Row>
      </form>
    </>
  );
}
