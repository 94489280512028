import React from "react";
import { CSVLink } from "react-csv";
import GenericButton from "./GenericButton";

export default function ExportCSVButton({ showBtn, csvData, csvHeader, filename, enclose, isExport, onExportComplete, props }) {
  const csvLinkRef = React.useRef();
  React.useEffect(() => {
    if (isExport) {
      csvLinkRef.current.link.click();
      onExportComplete();
    }
  }, [isExport, onExportComplete]);
  return (
    <>
      {
        showBtn ? (
          <GenericButton
            variant="success"
          >
            <CSVLink
              style={{ color: "white"}}
              data={csvData}
              headers={csvHeader}
              filename={filename}
              enclosingCharacter={enclose}
              // ref={csvLinkRef}
              {...props}
            >
              Export CSV
            </CSVLink>
          </GenericButton>

        ) : (
          <CSVLink
            data={csvData}
            headers={csvHeader}
            filename={filename}
            enclosingCharacter={enclose}
            ref={csvLinkRef}
            {...props}
          />
        )
      }

    </>
  );
}
