import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import userAvatar from "../assets/img/img1.jpg";
import notification from "../data/Notification";
import {
  selectUser,
  setAccessToken,
  setUser,
} from "../global_store/features/AuthenSlice";
import { useAppDispatch, useAppSelector } from "../global_store/hooks";
import feather from "feather-icons/dist/feather";
import Cookies from "js-cookie";

export default function Header({ onSkin }) {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  function NotificationList() {
    const notiList = notification.map((item, key) => {
      return (
        <li className="list-group-item" key={key}>
          <div
            className={item.status === "online" ? "avatar online" : "avatar"}
          >
            {item.avatar}
          </div>
          <div className="list-group-body">
            <p>{item.text}</p>
            <span>{item.date}</span>
          </div>
        </li>
      );
    });

    return <ul className="list-group">{notiList}</ul>;
  }

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  React.useEffect(() => {
    feather.replace();
  }, []);

  const handleLogout = () => {
    dispatch(setAccessToken(null));
    Cookies.remove("Refresh-token");
    dispatch(setUser(null));
  };

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
        <i className="ri-menu-2-fill"></i>
      </Link>

      <div className="me-auto">
        {/* <input type="text" className="form-control" placeholder="Search" />
        <i className="ri-search-line"></i> */}
      </div>

      {/* <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <small>3</small>
          <i className="ri-notification-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f me--10-f">
          <div className="dropdown-menu-header">
            <h6 className="dropdown-menu-title">Notifications</h6>
          </div>
          {NotificationList()}
          <div className="dropdown-menu-footer">
            <Link to="#">Show all Notifications</Link>
          </div>
        </Dropdown.Menu>
      </Dropdown> */}

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {user?.name !== undefined && user?.name}{" "}
            {user?.lname !== undefined && user?.lname}
            <i class="ri-user-line"></i>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <h5 className="mb-1 text-dark fw-semibold">{`${user?.name} ${user?.lname}`}</h5>
            <p className="fs-sm text-secondary">{`${user?.email} `}</p>

            <nav className="nav">
              {/* <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link> */}
            </nav>
            <hr />
            <nav className="nav">
              {/* <Link to=""><i className="ri-question-line"></i> Manual</Link> */}
              <Link to="/signin" onClick={handleLogout}>
                <i className="ri-logout-box-r-line"></i> Log Out
              </Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
