import React, { useState } from "react";
import { Tab, Tabs, Row, Col, Container } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { editMockUserMember } from "../../services/MemberServices";
import MemberEditGeneralForm from "./MemberEditGeneralForm";
import { useForm } from "react-hook-form";
import MemberEditShippingForm from "./MemberEditShippingForm";
import MemberEditBookingForm from "./MemberEditBookingForm";
import MemberEditPurchasedForm from "./MemberEditPurchasedForm";
import MemberEditCreditForm from "./MemberEditCreditForm";
import MemberEditPetsForm from "./MemberEditPetsForm";
import MemberEditPetsInfoForm from "./MemberEditPetsInfoForm";
import OrderManagement from "../../pages/OrderManagement";
import OrderManagementDataTable from "../DataTable/OrderManagementDataTable";

const schema = yup.object().shape({
  permissionStatus: yup.string().required("การอนุมัติการลาห้ามว่าง"),
});

export default function MembersDetailEditForm({
  onCancel,
  onSubmitSuccess,
  memberId,
  memberFirstNameDefaultValue,
  memberLastLameDefaultValue,
  phoneDefaultValue,
  emailDefaultValue,
  birthDateDefaultValue,
  editData,
}) {
  const [keyTab, setKeyTab] = useState("General");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={keyTab}
        onSelect={(k) => setKeyTab(k)}
        className="mb-3"
        justify
      >
        <Tab eventKey="General" title="General">
          {/* Tab content for General */}
          <MemberEditGeneralForm
            onCancel={onCancel}
            onSubmitSuccess={onSubmitSuccess}
            memberId={memberId}
          />
        </Tab>
        <Tab eventKey="shipping" title="Shipping Addr.">
          {/* Tab content for Shipping Addr. */}
          <MemberEditShippingForm editData={editData} />
        </Tab>
        <Tab eventKey="booling" title="Booking">
          {/* Tab content for Booking */}
          <MemberEditBookingForm memberId={memberId} />
        </Tab>
        <Tab eventKey="purchased" title="Purchased">
          {/* Tab content for Purchased */}
          {/* <MemberEditPurchasedForm memberId={memberId} /> */}
          <OrderManagementDataTable memberId={memberId} />
        </Tab>
        <Tab eventKey="credit" title="Credit">
          {/* Tab content for Credit */}
          <MemberEditCreditForm memberId={memberId} />
        </Tab>
        <Tab eventKey="pets" title="Pets">
          {/* Tab content for Pets */}
          <MemberEditPetsInfoForm memberId={memberId} editData={editData} />
        </Tab>
      </Tabs>
    </>
  );
}
