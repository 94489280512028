import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Tab, Tabs, Row, Col, Container } from "react-bootstrap";

import AppealDetailEditGeneralForm from "./AppealDetailEditGeneralForm";


const schema = yup.object().shape({
    subject: yup.string().required("ชื่อเรื่องห้ามว่าง"),
});

export default function AppealDetailEditForm({
    onCancel,
    onSubmitSuccess,
    id,
    m_appeal_cat_id,
    status
    })
    {
        // console.log(subject);
        const {
        register,
        handleSubmit,
        formState: { errors },
        } = useForm({
        resolver: yupResolver(schema),
        });
        return (
            <>
            <AppealDetailEditGeneralForm
            onCancel={onCancel}
            onSubmitSuccess={onSubmitSuccess}
            id={id}
            m_appeal_cat_id={m_appeal_cat_id}
            status={status}
            />
        </>
        );
    }