import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getPaidInfo = async ({ params, authorizationHeader }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/payment/paid-info",
      {
        headers: {
          ...(await getDefaultRequestHeaders(false)),
          Authorization: authorizationHeader,
        },
        params: params,
      }
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const paidPayment = async ({ body, params, authorizationHeader }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/payment/paid",
      body,
      {
        headers: {
          ...(await getDefaultRequestHeaders(false)),
          Authorization: authorizationHeader,
        },
        params: params,
      }
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const checkPaymentStatus = async ({ params, authorizationHeader }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/payment/paid-check-status",
      {
        headers: {
          ...(await getDefaultRequestHeaders(false)),
          Authorization: authorizationHeader,
        },
        params: params,
      }
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getQrCodeImg = async ({ params, authorizationHeader }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/payment/qrcode-img",
      {
        headers: {
          ...(await getDefaultRequestHeaders(false)),
          Authorization: authorizationHeader,
        },
        params: params,
      }
    );
    return {
      success: true,
      data: res.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};
