import React from "react";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import { Card, Container } from "react-bootstrap";
import EditShopPurchaseSettingForm from "../features/Form/EditShopPurchaseSettingForm";
import { getSettingData } from "../services/SettingServices";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";

export default function ShopPurchaseSetting() {
  const [onLoading, setOnLoading] = React.useState(true);
  const [isDiscountCodeSupport, setIsDiscountCodeSupport] = React.useState();
  const [transportationFeePerTime, setTransportationFeePerTime] =
    React.useState();

  const loadSetting = React.useCallback(async () => {
    setOnLoading(true);
    const { success, data, error } = await getSettingData({
      params: {
        "filter.id": 1,
      },
    });
    if (success) {
      const setting = data.records[0];
      if (setting) {
        setIsDiscountCodeSupport([!!setting.s_discount]);
        setTransportationFeePerTime(setting.transport_fee);
      }
    } else {
      ShowMessageAndExpire(
        "Failed to load data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
    setOnLoading(false);
  }, []);

  React.useEffect(() => {
    loadSetting();
  }, [loadSetting]);

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard title={"Setting - Shop / Purchase"} />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {onLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "10vh",
                    }}
                  >
                    {/* <Spinner
                      animation="border"
                      variant="info"
                      size="lg"
                      className="me-3"
                    />
                    <br/><h3>Loading...</h3> */}
                  </div>
                ) : (
                  <EditShopPurchaseSettingForm
                    onCancel={loadSetting}
                    onSubmitSuccess={loadSetting}
                    isDiscountCodeSupportDefaultValue={isDiscountCodeSupport}
                    transportationFeePerTimeDefaultValue={
                      transportationFeePerTime
                    }
                  />
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
