import React from "react";
import { shippingColumn } from "../data/Columns";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import SelectFilter from "../components/SelectFilter";
import {
  filterAdviseCallStatusOptions,
  filterShippingStatusOptions,
} from "../data/SelectOptions";
import {
  getProductOrder,
  getProductShipping,
} from "../services/ProductManagenentServices";
import { deleteMockLeaveInfo } from "../services/LeaveManagement";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";
import { PurchaseStatus } from "../data/Enumerator";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";
import EditOrderStatusForm from "../features/Form/EditOrderStatusForm";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import CreateEditOrderForm from "../features/Form/CreateEditOrderForm";

const allAdviseCallStatus = null;
const allShippingStatus = null;

export default function OrderManagement() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [adviseCallStatus, setAdviseCallStatus] =
    React.useState(allAdviseCallStatus);
  const [shippingStatus, setShippingStatus] = React.useState(allShippingStatus);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [tableData, setTableData] = React.useState([]);
  const columnsData = shippingColumn;

  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isChangeStatus, setIsChangeStatus] = React.useState(false);
  const [selectIds, setSelectIds] = React.useState([]);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();

  const loadOrder = React.useCallback(
    async (
      currentPage,
      pageSize,
      adviseCallStatus,
      shippingStatus,
      keyword,
      startDate,
      endDate
    ) => {
      setTotal(0);
      setTableData([]);
      let dateFilter = null;
      if (startDate && endDate) {
        dateFilter = `$btw:${startDate} 00:00:00, ${endDate} 23:59:59`;
      }
      const { success, data, error } = await getProductShipping({
        params: {
          page: currentPage,
          limit: pageSize,
          search: keyword,
          "filter.advicee": adviseCallStatus,
          "filter.status": shippingStatus,
          "filter.shipping_date": dateFilter,
        },
        page: currentPage,
        pageSize: pageSize,
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        console.log(error);
      }
    },
    []
  );

  React.useEffect(() => {
    loadOrder(1, 10, allAdviseCallStatus, allShippingStatus);
  }, [loadOrder]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadOrder(
        page,
        pageSize,
        adviseCallStatus,
        shippingStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [
      loadOrder,
      pageSize,
      adviseCallStatus,
      shippingStatus,
      keyword,
      startDate,
      endDate,
    ]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadOrder(
        1,
        pageSize,
        adviseCallStatus,
        shippingStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadOrder, adviseCallStatus, shippingStatus, keyword, startDate, endDate]
  );

  const loadPageOnAdviseCallStatusChange = React.useCallback(
    async (adviseCallStatus) => {
      setCurrentPage(1);
      setAdviseCallStatus(adviseCallStatus);
      await loadOrder(
        1,
        pageSize,
        adviseCallStatus,
        shippingStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadOrder, pageSize, shippingStatus, keyword, startDate, endDate]
  );

  const loadPageOnShippingStatusChange = React.useCallback(
    async (shippingStatus) => {
      setCurrentPage(1);
      setShippingStatus(shippingStatus);
      await loadOrder(
        1,
        pageSize,
        adviseCallStatus,
        shippingStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadOrder, pageSize, adviseCallStatus, keyword, startDate, endDate]
  );

  const openForm = () => {
    let tempSelectIds = [];
    for (let row = 0; row < tableData.length; row++) {
      const selectId = document.getElementById(
        `checkbox-select-id-${row}`
      )?.checked;
      if (selectId) {
        tempSelectIds.push(
          +document.getElementById(`checkbox-select-id-${row}`)?.value
        );
      }
    }
    setSelectIds(tempSelectIds);
    setIsOpenForm(true);
    setIsChangeStatus(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setIsChangeStatus(false);
    setEditData(null);
    setIsDelete(false);
    setDeleteData(null);
    setCurrentPage(1);
    setKeyword("");
    setAdviseCallStatus(allAdviseCallStatus);
    setShippingStatus(allShippingStatus);
    loadOrder(1, pageSize, adviseCallStatus, shippingStatus, "");
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadOrder(
        1,
        pageSize,
        adviseCallStatus,
        shippingStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadOrder, pageSize, adviseCallStatus, shippingStatus, startDate, endDate]
  );

  const handleSelectedDate = React.useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate);
      setEndDate(endDate);
      setCurrentPage(1);
      await loadOrder(
        1,
        pageSize,
        adviseCallStatus,
        shippingStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadOrder, pageSize, adviseCallStatus, keyword, shippingStatus]
  );

  const handleOpenEditForm = async (recordData) => {
    const { success, data, error } = await getProductOrder({
      params: {
        "filter._relations": "rel_product_order_detail_ids",
      },
      pathParams: recordData.id,
    });
    if (success) {
      const tempEditData = {
        id: data.records?.id,
        purchaseStatus: data.records?.status,
        shippingFee: data.records?.shipping_free,
        productCode: data.records?.code,
        paymentId: data.records?.payment_id,
        customerId: data.records?.member_info_id,
        name: data.records?.rname,
        phone: data.records?.phone,
        address: data.records?.addressno,
        street: data.records?.street,
        subDistrict: data.records?.m_subdistrict_id,
        district: data.records?.m_district_id,
        province: data.records?.m_province_id,
        postcode: data.records?.postcode,
        carrier: data.records?.delivery_type,
        trackingCode: data.records?.tracking_code,
        datePickup: new Date(data.records?.shipping_date).getDate
          ? new Date(data.records?.shipping_date)
          : null,
        advicee: data.records?.advicee,
        advicer: data.records?.user_admin_id,
        remark: data.records?.remark,
      };
      setIsOpenForm(true);
      setIsChangeStatus(false);
      setEditData(tempEditData);
    } else {
      ShowMessageAndExpire(
        "Load Detail Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  };

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const { success, error } = await deleteMockLeaveInfo({
        pathParams: id,
      });
      if (success) {
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadOrder(
          tempCurrentPage,
          pageSize,
          adviseCallStatus,
          shippingStatus,
          keyword,
          startDate,
          endDate
        );
      } else {
        console.log(error);
      }
    },
    [
      loadOrder,
      total,
      currentPage,
      pageSize,
      adviseCallStatus,
      shippingStatus,
      keyword,
      startDate,
      endDate,
    ]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              !isOpenForm
                ? "Shipping Management"
                : isChangeStatus
                ? "Change Status"
                : "Shipping Detail"
            }
            buttonEnable={!isOpenForm}
            buttonIcon="plus"
            buttonMessage="Options"
            onButtonOnClick={openForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.leaveId}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`id = ${deleteData?.id}, Purchase ID= ${deleteData?.purchaseId}, Product Code= ${deleteData?.productCode} `}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenForm && isChangeStatus ? (
                  <>
                    <Row className="mt-3">
                      <EditOrderStatusForm
                        onCancel={() => {
                          refreshPage();
                        }}
                        onSubmitSuccess={() => {
                          refreshPage();
                        }}
                        ids={selectIds}
                      />
                    </Row>
                  </>
                ) : isOpenForm && !isChangeStatus ? (
                  <>
                    <Row className="mt-3">
                      <CreateEditOrderForm
                        showDeliveryInfo={true}
                        showAddProduct={false}
                        customerIdReadOnly={true}
                        paymentMethodReadOnly={true}
                        onCancel={refreshPage}
                        onSubmitSuccess={refreshPage}
                        purchaseStatusDefaultValue={editData?.purchaseStatus}
                        shippingFeeDefaultValue={editData?.shippingFee}
                        productCodeDefaultValue={editData?.productCode}
                        paymentIdDefaultValue={editData?.paymentId}
                        customerIdDefaultValue={editData?.customerId}
                        nameDefaultValue={editData?.name}
                        phoneDefaultValue={editData?.phone}
                        addressDefaultValue={editData?.address}
                        streetDefaultValue={editData?.street}
                        subDistrictDefaultValue={editData?.subDistrict}
                        districtDefaultValue={editData?.district}
                        provinceDefaultValue={editData?.province}
                        postCodeDefaultValue={editData?.postCode}
                        carrierDefaultValue={editData?.carrier}
                        trackingCodeDefaultValue={editData?.trackingCode}
                        datePickupDefaultValue={editData?.datePickup}
                        adviceeDefaultValue={editData?.advicee}
                        advicerDefaultValue={editData?.advicer}
                        remarkDefaultValue={editData?.remark}
                        id={editData?.id}
                      />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Filter : ชื่อแพทย์, หมายเหตุ"
                          onSearch={handleSearch}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="Advise call"
                          placeholder={"สถานะ"}
                          defaultValue={filterAdviseCallStatusOptions.find(
                            (e) => e.value === allAdviseCallStatus
                          )}
                          value={filterAdviseCallStatusOptions.find(
                            (e) => e.value === adviseCallStatus
                          )}
                          options={filterAdviseCallStatusOptions}
                          onChange={loadPageOnAdviseCallStatusChange}
                        />
                        <SelectFilter
                          label="Shipping Status"
                          placeholder={"สถานะ"}
                          defaultValue={filterShippingStatusOptions.find(
                            (e) => e.value === allShippingStatus
                          )}
                          value={filterShippingStatusOptions.find(
                            (e) => e.value === shippingStatus
                          )}
                          options={filterShippingStatusOptions}
                          onChange={loadPageOnShippingStatusChange}
                        />
                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={startDate ? new Date(startDate) : null}
                          defaultEnd={endDate ? new Date(endDate) : null}
                          placeholder="เลือกวันที่จัดซื้อ"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = [];
  for (const [idx, data] of datas.entries()) {
    let tempShippingStatus;
    if (
      data.status === PurchaseStatus.Inactive ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "INACTIVE")
    ) {
      tempShippingStatus = <span class="badge bg-secondary">Inactive</span>;
    } else if (
      data.status === PurchaseStatus.Active ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "ACTIVE")
    ) {
      tempShippingStatus = <span class="badge bg-primary">Active</span>;
    } else if (
      data.status === PurchaseStatus.Packing ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "PACKING")
    ) {
      tempShippingStatus = (
        <span
          class="badge"
          style={{
            backgroundColor: "DarkSlateGray",
          }}
        >
          Packing
        </span>
      );
    } else if (
      data.status === PurchaseStatus.Waiting ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "WAITING")
    ) {
      tempShippingStatus = (
        <span class="badge bg-secondary">Wait for pickup</span>
      );
    } else if (
      data.status === PurchaseStatus.Shipping ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "SHIPPED")
    ) {
      tempShippingStatus = (
        <span
          class="badge"
          style={{
            backgroundColor: "indigo",
          }}
        >
          Shipping
        </span>
      );
    } else if (
      data.status === PurchaseStatus.Delivered ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "DELIVERED")
    ) {
      tempShippingStatus = (
        <span
          class="badge"
          style={{
            backgroundColor: "Chocolate",
          }}
        >
          Delivered
        </span>
      );
    } else if (
      data.status === PurchaseStatus.Return ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "RETURN")
    ) {
      tempShippingStatus = (
        <span
          class="badge"
          style={{
            backgroundColor: "purple",
          }}
        >
          Return
        </span>
      );
    } else if (
      data.status === PurchaseStatus.Lost ||
      (typeof data.status === "string" && data.status.toUpperCase() === "LOST")
    ) {
      tempShippingStatus = <span class="badge bg-dark">Lost</span>;
    } else if (
      data.status === PurchaseStatus.Pending ||
      (typeof data.status === "string" &&
        data.status.toUpperCase() === "PENDING")
    ) {
      tempShippingStatus = <span class="badge bg-primary">Pending</span>;
    } else {
      tempShippingStatus = (
        <span class="badge bg-secondary">{data.status || "-"}</span>
      );
    }
    const tempTableData = {
      checkbox: (
        <Form.Check
          id={`checkbox-select-id-${idx}`}
          type="checkbox"
          value={data.order_id}
        />
      ),
      no: startNo + idx,
      id: data.order_id,
      purchaseId: data.code,
      shipTo: data.ship_to,
      productCode: data.product_code,
      carrier: data.delivery_type,
      pickupDate: data.shipping_date,
      trackingCode: data.tracking_code,
      shippingStatus: tempShippingStatus,
      adviseCall: data.advicee,
      advise: data.advicee_by,
      remark: data.remark,
    };
    const buttonData = {
      id: data.order_id,
      purchaseId: data.code,
      shipTo: data.ship_to,
      productCode: data.product_code,
      carrier: data.delivery_type,
      pickupDate: data.shipping_date,
      trackingCode: data.tracking_code,
      shippingStatus: data.status,
      adviseCall: data.advicee,
      advise: data.advicee_by,
      remark: data.remark,
    };
    tableData.push({
      ...tempTableData,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(buttonData);
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(buttonData);
            }}
          />
        </>
      ),
    });
  }
  return tableData;
};
