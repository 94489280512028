import React from "react";
import { Button } from "react-bootstrap";
import { checkPermissionButton } from "../../utils/Help";

export default function EditButton(props) {
  
    if(checkPermissionButton(props?.screenId, "f_edit")){
        return (
            <Button {...props}
            variant="warning"
            active
            style={{ color: "white", marginLeft: "10px" }}
            >
            Edit
            </Button>
        );
    }
    return ;
  
}
