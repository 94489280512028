import React from "react";
import { onlineBookingColumn } from "../data/Columns";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import CreateNewOnlineBookingForm from "../features/Form/CreateNewOnlineBookingForm";
import FilterDropdown from "../components/Input/Dropdown/GenericDropdown";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";

import {
  deleteBooking,
  getOnlineBooking,
  orderMedicine,
} from "../services/OnlineBookingServices";
import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import { deleteMockLeaveInfo } from "../services/LeaveManagement";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import { deleteMockUserMember } from "../services/MemberServices";
import EditOnlineBookingDetail from "./EditOnlineBookingDetail";
import SelectFilter from "../components/SelectFilter";
import { CaseStatusOptions } from "../data/SelectOptions";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import ReactMonthYearPicker from "../components/Input/ReactMonthYearPicker";

const allUserStatus = "all";

export default function OnlineBookingManagement() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [selectedDate, setSelectedDate] = React.useState();

  const [tableData, setTableData] = React.useState([]);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const columnsData = onlineBookingColumn;

  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isOpenCraeateForm, setIsOpenCraeateForm] = React.useState(false);

  const [dateStart, setDateStart] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();

  const loadUser = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, dateStart, dateEnd) => {
      const { success, data, error } = await getOnlineBooking({
        page: currentPage,
        pageSize: pageSize,
        search: keyword,
        status: userStatus,
        booking_type: 0,
        dateStart,
        dateEnd,
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          editButton(
            prepareData(
              data.records,
              handleOpenEditForm,
              handleOpenDeleteConfirm
            )
          )
        );
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  const editButton = (data) => {
    for (var i = 0; i < data.length; i++) {
      switch (data[i].status) {
        case 70: //booking
          data[i].status = <span class="badge bg-primary">Booking</span>;
          break;
        case 71: //complete
          data[i].status = <span class="badge bg-success">Finished</span>;
          break;
        case 72: //cancle
          data[i].status = <span class="badge bg-danger">Canceled</span>;
          break;
        case 0: //cancle
          data[i].status = <>InActive</>;
          break;
        case 1: //cancle
          data[i].status = <>Active</>;
          break;
      }
    }
    return data;
  };

  const prepareData = (datas, onEdit, onDelete) => {
    let listsData = [];
    
    for (const data of datas) {
      //console.log(JSON.stringify(data.id));
      let obj = dd.filter(o => o.booking_id === data.id);
      //console.log(JSON.stringify(obj));
      let p = '';
      if (obj !== undefined){
        obj.forEach(element => {
          console.log(element.product_name);
          p = p.concat(element.product_name, ' ');
        });
        p = p.substring(0, p.length - 1)
      }
      
      listsData.push({
        caseid: data.code,
        casetitle:
          (data.rel_member_info_id?.name || "") +
          "-" +
          (data.rel_pet_info_id?.name || "") +
          "-" +
          (data.rel_m_heal_id?.name || ""),
        bookingdate: data.booking_date,
        scheduledate: data.schedule_date,
        status: data.status,
        owner: (data.rel_doctor_info_id?.name || "") + (data.rel_doctor_info_id?.lname ? " " + data.rel_doctor_info_id?.lname : ""),
        createby: data.create_by,
        prescribe: p, 
        editDeleteButton: (
          <>
            <EditButton
              onClick={() => {
                onEdit(data);
              }}
            />{" "}
            <DeleteButton
              onClick={() => {
                onDelete(data);
              }}
            />
          </>
        ),
      });
    }
    return listsData;
  };

  let dd = [];
  const loadOrderMedicine = React.useCallback(
    async () => {
      const { success, data, error } = await orderMedicine();
      if (success) {
        dd = data;
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  React.useEffect(() => {
    loadOrderMedicine();

    loadUser(1, 10, allUserStatus);
  }, [loadOrderMedicine, loadUser]);


  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);

      await loadOrderMedicine();

      await loadUser(page, pageSize, userStatus, keyword, dateStart, dateEnd);

    },
    [loadOrderMedicine, loadUser, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadOrderMedicine();
      await loadUser(1, pageSize, userStatus, keyword, dateStart, dateEnd);
    },
    [loadUser, userStatus, keyword]
  );

  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadOrderMedicine();
      await loadUser(1, pageSize, userStatus, keyword, dateStart, dateEnd);
    },
    [loadUser, pageSize, keyword]
  );
  const openCreateForm = () => {
    setIsOpenCraeateForm(true);
  };

  const refreshPage = async () => {
    setIsOpenForm(false);
    setCurrentPage(1);
    setKeyword("");
    setUserStatus(allUserStatus);
    await loadOrderMedicine();
    await loadUser(1, pageSize, allUserStatus, "");
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadOrderMedicine();
      await loadUser(1, pageSize, userStatus, keyword, dateStart, dateEnd);
    },
    [loadUser, pageSize, userStatus, keyword, dateStart, dateEnd]
  );

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleEdit = React.useCallback(async () => {
    handleCloseEdit();
    loadOrderMedicine();
    loadUser(currentPage, pageSize, userStatus, keyword, dateStart, dateEnd);
  }, [loadUser, currentPage, pageSize, userStatus, keyword]);

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setEditData(data);
  };

  const handleCloseEdit = () => {
    setIsOpenForm(false);
    setIsOpenCraeateForm(false);
    setEditData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const { success, data, error } = await deleteBooking({
        id: id,
      });
      if (success) {
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadOrderMedicine();
        loadUser(
          tempCurrentPage,
          pageSize,
          userStatus,
          keyword,
          dateStart,
          dateEnd
        );
      } else {
        console.log(error);
      }
    },
    [loadUser, total, currentPage, pageSize, userStatus, keyword]
  );
  const loadPageOnStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadOrderMedicine();
      await loadUser(1, pageSize, userStatus, keyword, dateStart, dateEnd);
    },
    [loadUser, pageSize, keyword, dateStart, dateEnd]
  );

  const handleSelectedDate = React.useCallback(
    async (sDate, eDate) => {
      setDateStart(sDate);
      setDateEnd(eDate);
      setCurrentPage(1);
      await loadOrderMedicine();
      await loadUser(1, pageSize, userStatus, keyword, sDate, eDate);
    },
    [loadUser, pageSize, userStatus, keyword]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            // title="Online Booking Management"
            title={
              editData
                ? `Online Booking Management : ${editData?.code}`
                : "Online Booking Management"
            }
            buttonEnable={!isOpenCraeateForm}
            buttonIcon="plus"
            buttonMessage="New Booking"
            onButtonOnClick={openCreateForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>{`id = ${deleteData?.id}`}</p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenCraeateForm ? (
                  <>
                    <CreateNewOnlineBookingForm
                      onCancel={handleCloseEdit}
                      onCreateSuccess={handleEdit}
                      bookingType={0}
                    />
                  </>
                ) : isOpenForm ? (
                  <>
                    <EditOnlineBookingDetail
                      onCancel={handleCloseEdit}
                      onCreateSuccess={handleEdit}
                      editData={editData}
                      bookingType={0}
                    />
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Filter : รหัสเคส, ชื่อสัตว์เลี้ยง, ชื่อแพทย์, หมายเหตุ"
                          onSearch={handleSearch}
                          defaultValue={keyword}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="Status"
                          placeholder={"Status"}
                          defaultValue={CaseStatusOptions.find(
                            (e) => e.value == allUserStatus
                          )}
                          value={CaseStatusOptions.find(
                            (e) => e.value == userStatus
                          )}
                          options={CaseStatusOptions}
                          onChange={loadPageOnStatusChange}
                        />

                        {/* <ReactDateTimePicker
                          selectedDate={selectedDate}
                          setSelectedDate={handleSelectedDate}
                          placeholder="Schedule Date"
                        /> */}

                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={dateStart ? new Date(dateStart) : null}
                          defaultEnd={dateEnd ? new Date(dateEnd) : null}
                          placeholder="Schedule Date"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
