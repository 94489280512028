import React from "react";
import { CallSchuldeLogColumn } from "../data/Columns";
import { Card, Col, Container, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import FilterDropdown from "../components/Input/Dropdown/GenericDropdown";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import ReactDateTimeRangePicker from "../components/Input/ReactDateTimeRangePicker";
import CallScheduleDeatail from "../features/Form/CallScheduleDetail";
import { getCommonAPI } from "../services/commonAPIServices";
import DetailButton from "../components/Button/DetailButton";
import SelectFilter from "../components/SelectFilter";
import { filteCalltypeOptions } from "../data/SelectOptions";
// import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";

const allcall_Type = "All";

export default function CallSchuldeLog() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [serach, setserach] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const [call_type, setcallType] = React.useState(allcall_Type);
  const [isOpenForm, setIsOpenForm] = React.useState(false);

  const [editData, setEditData] = React.useState();
  const [tableData, setTableData] = React.useState([]);

  const loadCallschedulelogReport = React.useCallback(
    async (currentPage, pageSize, call_type, serach) => {
      const page = currentPage;
      const limit = pageSize;
      const url_api = "/report/call_schedule_log";
      const p_data = {
        page,
        limit,
        serach,
      };

      if (call_type.toLowerCase() !== "all") {
        p_data["filter.call_type"] = call_type;
      }

      const { success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          editButton(prepareTableData(data.records, handleOpenEditForm))
        );
      } else {
        // ShowMessageAndExpiredExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  const editButton = (datas) => {
    for (var i = 0; i < datas.length; i++) {
      console.log(datas[i].call_type);
      switch (datas[i].call_type) {
        case "voice call":
          datas[i].call_type = (
            <span class="badge bg-primary" style={{ fontSize: "16px" }}>
              Voice call
            </span>
          );
          break;
        case "video call":
          datas[i].call_type = (
            <span class="badge bg-info" style={{ fontSize: "16px" }}>
              Video call
            </span>
          );
          break;
      }
    }
    return datas;
  };

  React.useEffect(() => {
    loadCallschedulelogReport(1, 10, allcall_Type);
  }, [loadCallschedulelogReport]);

  const columnscallschedulelog = CallSchuldeLogColumn;

  const prepareTableData = (datas, handleOpenEditForm) => {
    let tableData = [];
    for (const data of datas) {
      const tempTableData = {
        report_id: data.report_id,
        call_date: data.call_date,
        doctor_name: data.doctor_name,
        user_name: data.member_name,
        case_id: data.case_id,
        call_duration: data.call_duration,
        call_type: data.call_type,
      };
      const buttonData = {
        report_id: data.report_id,
        call_date: data.call_date,
        doctor_name: data.doctor_name,
        user_name: data.member_name,
        case_id: `${data.case_id}`,
        call_duration: data.call_duration,
        call_type: data.call_type,
      };
      tableData.push({
        ...tempTableData,
        view: (
          <>
            <DetailButton
              onClick={() => {
                handleOpenEditForm(buttonData);
              }}
            />
          </>
        ),
      });
    }
    return tableData;
  };

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadCallschedulelogReport(page, pageSize, call_type, serach);
    },
    [loadCallschedulelogReport, pageSize, call_type, serach]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadCallschedulelogReport(1, pageSize, call_type, serach);
    },
    [loadCallschedulelogReport, call_type, serach]
  );

  const loadPageOncallTypeChange = React.useCallback(
    async (call_type) => {
      setCurrentPage(1);
      setcallType(call_type);
      await loadCallschedulelogReport(1, pageSize, call_type, serach);
    },
    [loadCallschedulelogReport, pageSize, serach]
  );

  const refreshPage = () => {
    setIsOpenForm(false);
    setCurrentPage(1);
    setserach("");
    setcallType(allcall_Type);
    loadCallschedulelogReport(1, pageSize, allcall_Type, "");
  };

  const handleSearch = React.useCallback(
    async (serach) => {
      setCurrentPage(1);
      setserach(serach);
      await loadCallschedulelogReport(1, pageSize, call_type, serach);
    },
    [loadCallschedulelogReport, pageSize, call_type]
  );
  const handleEdit = React.useCallback(async () => {
    handleCloseEdit();
    loadCallschedulelogReport(currentPage, pageSize, call_type, serach);
  }, [loadCallschedulelogReport, currentPage, pageSize, call_type, serach]);

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setEditData(data);
  };

  const handleCloseEdit = () => {
    setIsOpenForm(false);
    setEditData(null);
  };

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              editData ? `ข้อมูล : ${editData?.case_id}` : "Call Schedule Log"
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenForm ? (
                  <>
                    <CallScheduleDeatail
                      onCancel={handleCloseEdit}
                      onSubmitSuccess={handleEdit}
                      report_id={editData?.report_id}
                      calltypeDefaultValue={editData?.call_type}
                      editData={editData}
                    />
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Filter : ชื่อหมอ, ชื่อลูกค้า, CaseID "
                          onSearch={handleSearch}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="ประเภทการโทร"
                          placeholder={"ประเภทการโทร"}
                          defaultValue={filteCalltypeOptions.find(
                            (e) => e.value === allcall_Type
                          )}
                          value={filteCalltypeOptions.find(
                            (e) => e.value === call_type
                          )}
                          options={filteCalltypeOptions}
                          onChange={loadPageOncallTypeChange}
                        />
                        <ReactDateTimeRangePicker />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable
                          columns={columnscallschedulelog}
                          data={tableData}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
