import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";

import pageSvg from "../assets/svg/forgot_password.svg";
import bg1 from "../assets/img/bg1.jpg";
import ResetPasswordForm from "../features/Form/ResetPasswordForm";
import { validateResetPasswordToken } from "../services/AuthServices";

export default function ResetPassword() {
  document.body.classList.remove("sidebar-show");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [titleResultMessage, setTitleResultMessage] = React.useState("");
  const [subtitleResultMessage, setSubtitleResultMessage] = React.useState("");
  const [token, setToken] = React.useState("");
  const [email, setEmail] = React.useState("");

  const handleForgotPasswordSuccess = () => {
    navigate("/signin");
  };

  const verifyToken = React.useCallback(async (token) => {
    const { success, data, error } = await validateResetPasswordToken({
      body: {
        token: token,
      },
    });
    if (success) {
      setEmail(data?.email);
      setTitleResultMessage("");
      setSubtitleResultMessage("");
    } else {
      setTitleResultMessage(`Verify token failed`);
      setSubtitleResultMessage(`${error?.message}`);
    }
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    if (token) {
      setToken(token);
      verifyToken(token);
    } else {
      setTitleResultMessage(`Verify token failed`);
      setSubtitleResultMessage(`Cannot get token`);
    }
    setIsLoading(false);
  }, [verifyToken]);

  return (
    <>
      {isLoading ? (
        <>
          <div className="page-auth">
            <div className="header">
              <Container>
                <Link to="/" className="header-logo">
                  Pet Dreams
                </Link>
              </Container>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Spinner
                animation="border"
                variant="info"
                size="lg"
                className="me-3"
              />
              <br/><h3>Loading...</h3>
            </div>
          </div>
        </>
      ) : (
        <>
          {titleResultMessage ? (
            <>
              <div className="page-auth">
                <div className="header">
                  <Container>
                    <Link to="/" className="header-logo">
                      Pet Dreams
                    </Link>
                  </Container>
                </div>
                <div className="content">
                  <Container>
                    <Card className="card-auth">
                      <Card.Body className="text-center">
                        <div className="mb-5">
                          <object
                            type="image/svg+xml"
                            data={pageSvg}
                            className="w-60"
                            aria-label="svg image"
                          ></object>
                        </div>
                        <Card.Title>{titleResultMessage}</Card.Title>
                        <Card.Text>{subtitleResultMessage}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Container>
                </div>
              </div>
            </>
          ) : (
            <div className="page-sign d-block py-0">
              <Row className="gx-6">
                <Col
                  lg="6"
                  className="d-flex flex-column align-items-center col-wrapper"
                >
                  <Card className="card-sign">
                    <Card.Header className="text-center">
                      <Card.Title className="mb-3">
                        Pet Dreams - Back Office
                      </Card.Title>
                      <div className="header-logo mb-5">Reset Password</div>
                    </Card.Header>
                    <Card.Body>
                      <ResetPasswordForm
                        onSubmitSuccess={handleForgotPasswordSuccess}
                        token={token}
                        email={email}
                      />
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs="6" lg="6" className="mb-5 mb-lg-0 col-wrapper">
                  <img src={bg1} className="auth-img" alt="" />
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
    </>
  );
}
