import React from "react"
import { ButtonGroup, ButtonToolbar, Col, Form, Row } from "react-bootstrap"
import GenericTextInput from "../../components/Input/GenericTextInput"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import SaveButton from "../../components/Button/SaveButton"
import CancelButton from "../../components/Button/CancelButton"
import * as yup from "yup"
import { editMockUserDoctor } from "../../services/DoctorServices"
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput"
import RadioButton from "../../components/Button/RadioButton"
import { CustomerTypeRadio, testaa, testbb } from "../../data/SelectOptions"
import {
  createCommonAPI,
  editCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices"
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire"
import DoctorTimeTableApproved from "../../pages/DoctorTimeTableApproved"
import DoctorTimeTablePending from "../../pages/DoctorTimeTablePending"
import GenericButton from "../../components/Button/GenericButton"
import { getValue } from "@testing-library/user-event/dist/utils"

const schema = yup.object().shape({
  addrHourly_com_online: yup
    .number()
    .typeError("เป็นตัวเลขเท่านั้น")
    .required("ห้ามว่าง"),
  addrHourly_com_outside: yup
    .number()
    .typeError("เป็นตัวเลขเท่านั้น")
    .required("ห้ามว่าง"),
  exp_to_hospital: yup
    .number()
    .typeError("เป็นตัวเลขเท่านั้น")
    .required("ห้ามว่าง"),
})

export default function DoctorEditTimeTableForm({
  onCancel,
  onSubmitSuccess,
  editData,
}) {
  const {
    register,
    control,
    resetField,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const onSubmit = async (inputData) => {
    //console.log(inputData);
    let { success, data, error } = await editCommonAPI({
      url_api: "/doctor_info/" + editData.id,
      p_data: {
        amt_online: parseInt(inputData.addrHourly_com_online),
        amt_onsite: parseInt(inputData.addrHourly_com_outside),
        percent_hospital: parseInt(inputData.exp_to_hospital),
        remark3: inputData?.remark,
      },
    })
    if (success) {
      ShowMessageAndExpire("Data updated successfully.", "", "success")
      onSubmitSuccess()
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error")
    }
  }

  const [timeTable, setTimeTable] = React.useState([])
  const loadDoctorIimeTable = React.useCallback(async () => {
    //console.log(editData.id);
    const { success, data, error } = await getCommonAPI({
      url_api: "/doctor/get_timetale",
      p_data: {
        doctor_info_id: editData.id,
        status: "approved",
      },
    })
    if (success) {
      setValue("hours_per_week", data.records.week_total_hours, {
        shouldValidate: true,
      })
      setTimeTable(prepareData(data.records))
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error")
    }
  }, [])
  const [showPending, setTimeshowPending] = React.useState(false)
  const [timeTablePending, setTimeTablePending] = React.useState([])
  const loadDoctorIimeTablePending = React.useCallback(async () => {
    //console.log(editData.id);
    const { success, data, error } = await getCommonAPI({
      url_api: "/doctor/get_timetale",
      p_data: {
        doctor_info_id: editData.id,
        status: "pending",
      },
    })
    if (success) {
      if (
        prepareData(data.records)[0].defaultValue.length == 0 &&
        prepareData(data.records)[1].defaultValue.length == 0 &&
        prepareData(data.records)[2].defaultValue.length == 0 &&
        prepareData(data.records)[3].defaultValue.length == 0 &&
        prepareData(data.records)[4].defaultValue.length == 0 &&
        prepareData(data.records)[5].defaultValue.length == 0 &&
        prepareData(data.records)[6].defaultValue.length == 0
      ) {
        setTimeshowPending(false)
      } else {
        setTimeshowPending(true)
      }
      setTimeTablePending(prepareData(data.records))
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error")
    }
  }, [])

  const prepareData = (datas) => {
    let listsData = []
    let days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ]

    for (const day in days) {
      listsData.push({
        name: days[day],
        defaultValue: null,
      })
    }

    for (let i = 0; i < 7; i++) {
      let listStatus = []
      let r = i + 1
      let data = datas["day_of_week_" + r]
      if (data) {
        for (let a = 0; a <= 23; a++) {
          listStatus.push(data["h" + a + "_type"])
        }
      }
      listsData[i].defaultValue = listStatus
    }

    return listsData
  }

  const loadDoctorInfo = React.useCallback(async () => {
    const { success, data, error } = await getCommonAPI({
      url_api: "/doctor_info",
      p_data: {
        "filter.id": editData.id,
      },
    })
    if (success) {
      setValue("addrHourly_com_online", data.records[0].amt_online, {
        shouldValidate: true,
      })
      setValue("addrHourly_com_outside", data.records[0].amt_onsite, {
        shouldValidate: true,
      })
      setValue(
        "Cost_to_Platform",
        data.records[0].percent_platform
          ? data.records[0].percent_platform + "%"
          : "",
        { shouldValidate: true }
      )
      setValue("exp_to_hospital", data.records[0].percent_hospital, {
        shouldValidate: true,
      })
      setValue(
        "exp_to_doctors",
        data.records[0].percent_platform
          ? 100 -
              data.records[0].percent_platform -
              data.records[0].percent_hospital +
              "%"
          : "",
        { shouldValidate: true }
      )
      setValue("remark", data.records[0].remark3, { shouldValidate: true })
    } else {
      console.log(error)
    }
  }, [])

  const updateTimeApproved = React.useCallback(async () => {
    console.log("Approved")
    const { success, data, error } = await getCommonAPI({
      url_api: "/doctor/timetale_confirm_approved",
      p_data: {
        doctor_info_id: editData.id,
      },
    })
    if (success) {
      ShowMessageAndExpire("Approved Success", "", "success")
      loadDoctorIimeTable()
      loadDoctorIimeTablePending()
      loadDoctorInfo()
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error")
    }
  }, [])
  const updateTimeReject = React.useCallback(async () => {
    console.log("reject")
    const { success, data, error } = await getCommonAPI({
      url_api: "/doctor/timetale_confirm_reject",
      p_data: {
        doctor_info_id: editData.id,
      },
    })
    if (success) {
      ShowMessageAndExpire("Reject Success", "", "success")
      loadDoctorIimeTable()
      loadDoctorIimeTablePending()
      loadDoctorInfo()
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error")
    }
  }, [])

  const handleChangePercentHospital = React.useCallback(async (event) => {
    const percent_hospital = event.target.value
    resetField("exp_to_doctors")
    const { success, data, error } = await getCommonAPI({
      url_api: "/doctor_info",
      p_data: {
        "filter.id": editData.id,
      },
    })
    if (success) {
      setValue(
        "exp_to_doctors",
        data.records[0].percent_platform
          ? 100 - data.records[0].percent_platform - percent_hospital + "%"
          : "",
        { shouldValidate: true }
      )
    } else {
      console.log(error)
    }
  }, [])

  React.useEffect(() => {
    loadDoctorIimeTable()
    loadDoctorIimeTablePending()
    loadDoctorInfo()
    //prepareData();
  }, [loadDoctorInfo, loadDoctorIimeTable, loadDoctorIimeTablePending])

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Tab content for General */}
        <h4>ข้อมูลเวลา</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="hours_per_week"
              label="จำนวนชั่วโมงทั้งหมด"
              placeholder="Hours"
              readOnly
              disabled
              error={errors.hours_per_week}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <DoctorTimeTableApproved
            listdays={timeTable}
            doctorId={editData.id}
            onSuccess={loadDoctorIimeTable}
            onSuccessPedding={loadDoctorIimeTablePending}
          />
        </Row>
        {showPending && (
          <>
            <h4>ตารางเวลาที่เปลี่ยนแปลง</h4>
            <hr />
            <Row className="mb-3">
              <DoctorTimeTablePending
                listdays={timeTablePending}
                doctorId={editData.id}
                onSuccess={loadDoctorIimeTable}
              />
            </Row>
            <Row>
              <Col>
                <center>
                  <GenericButton variant="danger" onClick={updateTimeReject}>
                    Reject
                  </GenericButton>
                </center>
              </Col>
              <Col>
                {" "}
                <center>
                  <GenericButton variant="success" onClick={updateTimeApproved}>
                    Approve
                  </GenericButton>
                </center>
              </Col>
            </Row>
          </>
        )}
        <h4>ข้อมูลการเงิน</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="addrHourly_com_online"
              label="ค่าตอบแทนรายชั่วโมง เมื่อทำงานออนไลน์"
              placeholder="ค่าตอบแทนรายชั่วโมง เมื่อทำงานออนไลน์"
              defaultValue={""}
              required
              error={errors.addrHourly_com_online}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="addrHourly_com_outside"
              label="ค่าตอบแทนเมื่อทำงานนอกสถานที่ต่อครั้ง(4 ชม.)"
              placeholder="ค่าตอบแทนเมื่อทำงานนอกสถานที่ต่อครั้ง(4 ชม.)"
              defaultValue={""}
              required
              error={errors.addrHourly_com_outside}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="Cost_to_Platform"
              label="ค่าใช้จ่ายให้กับ Platform (มูลค่าการจ้างงานต่อ 1 ชั่วโมง)"
              placeholder="ค่าใช้จ่ายให้กับ Platform (มูลค่าการจ้างงานต่อ 1 ชั่วโมง)"
              readOnly
              disabled
              error={errors.Cost_to_Platform}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="exp_to_hospital"
              label="ค่าใช้จ่ายให้กับ โรงพยาบาล (มูลค่าการจ้างงานต่อ 1 ชั่วโมง) %"
              placeholder="ค่าใช้จ่ายให้กับ โรงพยาบาล (มูลค่าการจ้างงานต่อ 1 ชั่วโมง) %"
              defaultValue={""}
              required
              error={errors.exp_to_hospital}
              onChange={handleChangePercentHospital}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="exp_to_doctors"
              label="ค่าใช้จ่ายให้กับ แพทย์  (มูลค่าการจ้างงานต่อ 1 ชั่วโมง)"
              placeholder="ค่าใช้จ่ายให้กับ แพทย์  (มูลค่าการจ้างงานต่อ 1 ชั่วโมง)"
              required
              disabled
              error={errors.exp_to_doctors}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <h4>หมายเหตุ</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextAreaInput
              register={register}
              name="remark"
              label="หมายเหตุจากแอดมิน"
              placeholder="หมายเหตุจากแอดมิน"
              defaultValue={""}
              error={errors.remark}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  )
}
