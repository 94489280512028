import React from "react";
import { EmsVanWorkLogReportColumn } from "../data/Columns";
import {
  Modal,
  Button,
  Card,
  Col,
  Container,
  Row,
  Form,
} from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import FilterDropdown from "../components/Input/Dropdown/GenericDropdown";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import { getEmsVanWorkLogReport } from "../services/LogServices";
import GenericButton from "../components/Button/GenericButton";
import ReactDateTimeRangePicker from "../components/Input/ReactDateTimeRangePicker";
import { EmsVanWorkLogReportCSVHeader } from "../data/CSVHeader";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";
import ExportCSVButton from "../components/Button/ExportCSVButton";
import CancelButton from "../components/Button/CancelButton";
import { textAlignRight } from "../utils/Help";
import ReactMonthYearPicker from "../components/Input/ReactMonthYearPicker";

export default function EmsVanWorkLogReport() {
  const csvHeader = EmsVanWorkLogReportCSVHeader;
  const csvFilename = "EmsVanWorkLogReport.csv";
  const [isExport, setIsExport] = React.useState(false);

  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");

  const [selectedDate, setSelectedDate] = React.useState();
  const [dateStart, setDateStart] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();
  const allUserStatus = "";
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [tableReport, setTableReport] = React.useState([]);
  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalDesc, setModalDesc] = React.useState(false);
  const [csvData, setCsvData] = React.useState([]);

  const loadReport = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, selectedDate) => {
      const { success, data, error } = await getEmsVanWorkLogReport({
        page: currentPage,
        limit: pageSize,
        search: keyword,
        selectedDate
      });
      if (success) {
        setTotal(data.total);
        setTableReport(prepareTable(data.records, handleShowDetail));
        setCsvData(prepareCSVData(data.records));
      } else {
        console.log(error);
      }
    },
    []
  );
  React.useEffect(() => {
    loadReport(1, 10, allUserStatus);
  }, [loadReport]);

  const columnsReport = EmsVanWorkLogReportColumn;
  const prepareTable = (datas, onShowDetail) => {
    let tableData = [];
    for (const data of datas) {
      tableData.push({
        id: data.report_id,
        transectionID: data.case_id,
        doctorName: data.doctor_name,
        transectionDatetime: data.action_datetime,
        cusName: data.member_name,
        time: textAlignRight(data.working_time_hrs),
        workValue: textAlignRight(data.work_value),
        origin: data.origin_address,
        destination: data.destination_address,
        transectionGroup: data.category,
        status: data.status,
        view: (
          <Button variant="warning" onClick={() => onShowDetail(data)}>
            View
          </Button>
        ),
      });
    }
    return tableData;
  };

  const prepareCSVData = (datas) => {
    let csvData = [];
    for (const data of datas) {
      csvData.push({
        report_id: data.report_id,
        case_id: data.case_id,
        doctor_name: data.doctor_name,
        action_datetime: data.action_datetime,
        member_name: data.member_name,
        working_time_hrs: data.working_time_hrs,
        work_value: data.work_value,
        working_time_hrs: data.working_time_hrs,
        work_value: data.work_value,
        origin_address: data.origin_address,
        category: data.category,
        status: data.status,
      });
    }
    return csvData;
  };

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadReport(page, pageSize, userStatus, keyword, selectedDate);
    },
    [loadReport, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadReport(1, pageSize, userStatus, keyword, selectedDate);
    },
    [loadReport, userStatus, keyword]
  );
  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadReport(1, pageSize, userStatus, keyword, selectedDate);
    },
    [loadReport, pageSize, keyword]
  );

  const refreshPage = () => {
    setIsOpenForm(false);
    setCurrentPage(1);
    setKeyword("");
    setUserStatus(allUserStatus);
    loadReport(1, pageSize, allUserStatus, "");
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadReport(1, pageSize, userStatus, keyword, selectedDate);
    },
    [loadReport, pageSize, userStatus, keyword, selectedDate]
  );

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setEditData(data);
  };
  const handleShowDetail = (data) => {
    let txt = (
      <>
        <Container fluid style={{ minHeight: "20rem" }}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="report_id">report_id :</Form.Label>
              <Form.Control
                type="text"
                id="report_id"
                value={data.report_id}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="case_id">รหัสรายการ : </Form.Label>
              <Form.Control
                type="text"
                id="case_id"
                value={data.case_id}
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="doctor_name">ชื่อแพทย์ : </Form.Label>
              <Form.Control
                type="text"
                id="doctor_name"
                value={data.doctor_name}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="action_datetime">
                วันที่และเวลาทำรายการ :{" "}
              </Form.Label>
              <Form.Control
                type="text"
                id="action_datetime"
                value={data.action_datetime}
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="member_name">ชื่อลูกค้า : </Form.Label>
              <Form.Control
                type="text"
                id="member_name"
                value={data.member_name}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="working_time_hrs">
                ระยะเวลางาน (ชม.) :{" "}
              </Form.Label>
              <Form.Control
                type="text"
                id="working_time_hrs"
                value={data.working_time_hrs}
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="work_value">มูลค่างาน : </Form.Label>
              <Form.Control
                type="text"
                id="work_value"
                value={data.work_value}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="origin_address">ที่อยู่ต้นทาง :</Form.Label>
              <Form.Control
                type="text"
                id="origin_address"
                value={data.origin_address}
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="destination_address">
                {" "}
                ที่อยู่ปลายทาง :{" "}
              </Form.Label>
              <Form.Control
                type="text"
                id="destination_address"
                value={data.destination_address}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="category">กลุ่มรายการ : </Form.Label>
              <Form.Control
                type="text"
                id="category"
                value={data.category}
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="status">สถานะ : </Form.Label>
              <Form.Control
                type="text"
                id="status"
                value={data.status}
                disabled
              />
            </Form.Group>
          </Row>
        </Container>
      </>
    );
    setModalDesc(txt);
    setModalShow(true);
  };
  const handleDetailModalClose = () => {
    setModalShow(false);
    setModalDesc("");
  };
  const handleSelectedDate = React.useCallback(
    async (selectedDate) => {
      setSelectedDate(selectedDate);
      setCurrentPage(1);
      await loadReport(1, pageSize, userStatus, keyword, selectedDate);
    },
    [loadReport, pageSize, userStatus, keyword]
  );
  const handleExportCSV = () => {
    setIsExport(true);
  };
  const handleExportComplete = () => {
    setIsExport(false); // Reset the trigger to false
  };

  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <b>EMS Van Work Report Detail</b>
        </Modal.Header>
        <Modal.Body>{modalDesc}</Modal.Body>
        <Modal.Footer>
          <CancelButton onClick={handleDetailModalClose} />
        </Modal.Footer>
      </Modal>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <ExportCSVButton
            csvData={csvData}
            csvHeader={csvHeader}
            filename={csvFilename}
            enclose=""
            showBtn={false}
            isExport={isExport}
            onExportComplete={handleExportComplete}
          />
          <PageHeaderCard
            title="EMS Van Work Log Report"
            buttonEnable={true}
            buttonMessage="Export CSV"
            onButtonOnClick={handleExportCSV}
          />

          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                <>
                  <Row className="mt-3">
                    <Col xs={6} style={{ display: "flex" }}>
                      <Searching
                        placeholder="Filter :"
                        onSearch={handleSearch}
                        defaultValue={keyword}
                      />
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {/* {" โรงพยาบาล : "}&nbsp;&nbsp;
                      <FilterDropdown currentValue="Enable" />
                      &nbsp;&nbsp;{" สถานะ : "}&nbsp;&nbsp;
                      <FilterDropdown currentValue="Enable" />
                      &nbsp;&nbsp;{" กลุ่มรายการ : "}&nbsp;&nbsp;
                      <FilterDropdown currentValue="Enable" /> */}
                      {/* <ReactDateTimeRangePicker2
                        onSelected={handleSelectedDate}
                        defaultStart={dateStart ? new Date(dateStart) : null}
                        defaultEnd={dateEnd ? new Date(dateEnd) : null}
                      /> */}
                        <ReactMonthYearPicker
                          selectedDate={selectedDate}
                          setSelectedDate={handleSelectedDate}
                        />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <ReactTable columns={columnsReport} data={tableReport} />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <GenericPagination
                        totalPage={Math.ceil(total / pageSize)}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={loadPageOnPageChange}
                        onPageSizeChange={loadPageOnPageSizeChange}
                      />
                    </Col>
                  </Row>
                </>
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
