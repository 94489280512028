import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  createMockProduct,
  createProductStock,
  editMockProduct,
  getProduct,
  getProductStock,
  updateProductStock,
} from "../../services/ProductManagenentServices";
import SaveButton from "../../components/Button/SaveButton";
import CancelButton from "../../components/Button/CancelButton";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { Col, Form, Row } from "react-bootstrap";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";

const schema = yup.object().shape({
  product1: yup.object().required("ห้ามว่าง"),
  oldVolumn1: yup.number(),
  addVolume1: yup.number().required("ห้ามว่าง").typeError("ต้องเป็นตัวเลข"),
  total1: yup.number(),
  product2: yup.object(),
  oldVolumn2: yup.number(),
  addVolume2: yup.number().when("product2", {
    is: (product3) => product3,
    then: () => yup.number().required("ห้ามว่าง").typeError("ต้องเป็นตัวเลข"),
  }),
  total2: yup.number(),
  product3: yup.object(),
  oldVolumn3: yup.number(),
  addVolume3: yup.number().when("product3", {
    is: (product3) => product3,
    then: () => yup.number().required("ห้ามว่าง").typeError("ต้องเป็นตัวเลข"),
  }),
  total3: yup.number(),
  remark: yup.string().required("ห้ามว่าง"),
});

export default function CreateEditStockForm({
  isCreate,
  onCancel,
  onSubmitSuccess,
  product1DefaultValue = {},
  addVolume1DefaultValue,
  total1DefaultValue,
  product2DefaultValue = {},
  addVolume2DefaultValue,
  total2DefaultValue,
  product3DefaultValue = {},
  addVolume3DefaultValue,
  total3DefaultValue,
  remarkDefaultValue,
}) {
  const [productSelect, setProductSelect] = React.useState([]);
  const [newBalance, setNewBalance] = React.useState(0);

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const prepareProductForSelect = React.useCallback((datas) => {
    let listsProduct = [];
    for (const [idx, data] of datas.entries()) {
      let tempSelect = {
        id: idx,
        label: data.product_name || "-",
        value: data.id,
      };
      listsProduct.push(tempSelect);
    }
    return listsProduct;
  }, []);

  const loadProduct = React.useCallback(async () => {
    const { success, data, error } = await getProduct({
      params: { select: "id,product_name" },
    });
    if (success) {
      setProductSelect(prepareProductForSelect(data.records));
    } else {
      ShowMessageAndExpire(
        "Load Product Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [prepareProductForSelect]);

  React.useEffect(() => {
    loadProduct();
  }, [loadProduct]);

  const getOldVolumnProductStock = React.useCallback(
    async (productId) => {
      const { success, data, error } = await getProductStock({
        params: {
          "filter.product_id": productId,
          sortBy: "create_date:DESC",
          limit: 1,
        },
      });
      if (success && data.records.length === 1) {
        return data.records[0].qty_balance;
      } else if (success) {
        return 0;
      } else {
        ShowMessageAndExpire(
          "Load Product Stock Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
        return 0;
      }
    },
    [prepareProductForSelect]
  );

  const handleSelectProduct = React.useCallback(async (e, no) => {
    if (e) {
      const productId = e.value;
      setValue(`oldVolumn${no}`, await getOldVolumnProductStock(productId));
    } else {
      setValue(`oldVolumn${no}`, null);
    }
  }, []);

  const onSubmit = async (inputData) => {
    let successCount = 0;
    let errorCount = 0;
    let success;
    let error;
    ({ success, error } = await updateProductStock({
      body: {
        product_id: inputData.product1?.value,
        qty_new: inputData.addVolume1,
        remark: inputData.remark,
      },
    }));
    if (success) {
      successCount += 1;
    } else {
      errorCount += 1;
    }
    if (inputData.product2?.value) {
      ({ success, error } = await updateProductStock({
        body: {
          product_id: inputData.product2?.value,
          qty_new: inputData.addVolume2,
          remark: inputData.remark,
        },
      }));
      if (success) {
        successCount += 1;
      } else {
        errorCount += 1;
      }
    }
    if (inputData.product3?.value) {
      ({ success, error } = await updateProductStock({
        body: {
          product_id: inputData.product3?.value,
          qty_new: inputData.addVolume3,
          remark: inputData.remark,
        },
      }));
      if (success) {
        successCount += 1;
      } else {
        errorCount += 1;
      }
    }
    if (errorCount === 0) {
      onSubmitSuccess();
      ShowMessageAndExpire("Data created successfully.", "", "success");
    } else {
      ShowMessageAndExpire(
        `Update Product Stock Data Failed (success=${successCount}, failed=${errorCount})`,
        JSON.stringify(error?.message),
        "error"
      );
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="product1"
              label="สินค้า 1"
              placeholder="สินค้า 1"
              defaultValue={product1DefaultValue}
              onChange={(e) => {
                handleSelectProduct(e, 1);
              }}
              options={productSelect}
              required
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="oldVolumn1"
              label="ปริมาณเดิม"
              placeholder="ปริมาณเดิม"
              defaultValue={null}
              readOnly
              disabled
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="addVolume1"
              label="ปรับปริมาณ"
              placeholder="ปรับปริมาณ"
              disabled={!watch("product1")}
              onChange={(e) => {
                setValue("total1", +e.target.value + +getValues("oldVolumn1"));
              }}
              error={errors.addVolume1}
              type="number"
              required
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="total1"
              label="ปริมาณใหม่"
              placeholder="ปริมาณใหม่"
              value={newBalance}
              readOnly
              disabled
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="product2"
              label="สินค้า 2"
              placeholder="สินค้า 2"
              defaultValue={product2DefaultValue}
              onChange={(e) => {
                handleSelectProduct(e, 2);
              }}
              options={productSelect}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="oldVolumn2"
              label="ปริมาณเดิม"
              placeholder="ปริมาณเดิม"
              defaultValue={null}
              readOnly
              disabled
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="addVolume2"
              label="ปรับปริมาณ"
              placeholder="ปรับปริมาณ"
              disabled={!watch("product2")}
              onChange={(e) => {
                setValue("total2", +e.target.value + +getValues("oldVolumn2"));
              }}
              type="number"
              error={errors.addVolume2}
              required={watch("product2")}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="total2"
              label="ปริมาณใหม่"
              placeholder="ปริมาณใหม่"
              value={newBalance}
              readOnly
              disabled
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="product3"
              label="สินค้า 3"
              placeholder="สินค้า 3"
              defaultValue={product3DefaultValue}
              onChange={(e) => {
                handleSelectProduct(e, 3);
              }}
              options={productSelect}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="oldVolumn3"
              label="ปริมาณเดิม"
              placeholder="ปริมาณเดิม"
              defaultValue={null}
              readOnly
              disabled
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="addVolume3"
              label="ปรับปริมาณ"
              placeholder="ปรับปริมาณ"
              disabled={!watch("product3")}
              onChange={(e) => {
                setValue("total3", +e.target.value + +getValues("oldVolumn3"));
              }}
              type="number"
              error={errors.addVolume3}
              required={watch("product3")}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="total3"
              label="ปริมาณใหม่"
              placeholder="ปริมาณใหม่"
              value={newBalance}
              readOnly
              disabled
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextAreaInput
              register={register}
              name="remark"
              label="หมายเหตุ"
              placeholder="หมายเหตุ"
              defaultValue={remarkDefaultValue}
              error={errors.remark}
              required
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
