import { configureStore } from "@reduxjs/toolkit";
import AuthenSlice from "./features/AuthenSlice";
import MessageSlice from "./features/MessageSlice";

export const store = configureStore({
  reducer: {
    authen: AuthenSlice,
    message: MessageSlice,
  },
  devTools: true,
});
