import React from "react";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import { Card, Container, Spinner } from "react-bootstrap";
import EditSystemSettingForm from "../features/Form/EditSystemSettingForm";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import { getSettingData } from "../services/SettingServices";

export default function SystemSetting() {
  const [onLoading, setOnLoading] = React.useState(true);
  const [phone, setPhone] = React.useState();
  const [lineId, setLineId] = React.useState();
  const [address, setAddress] = React.useState();
  const [street, setStreet] = React.useState();
  const [subDistrict, setSubDistrict] = React.useState();
  const [district, setDistrict] = React.useState();
  const [province, setProvince] = React.useState();
  const [postCode, setPostCode] = React.useState();
  const [amountInsiteArea, setAmountInsiteArea] = React.useState();
  const [amountOutsiteArea, setAmountOutsiteArea] = React.useState();

  const loadSetting = React.useCallback(async () => {
    setOnLoading(true);
    const { success, data, error } = await getSettingData({
      params: {
        "filter.id": 1,
        "filter._relations": "rel_m_subdistrict_id",
      },
    });
    if (success) {
      const setting = data.records[0];
      if (setting) {
        setPhone(setting.phone);
        setLineId(setting.acc_line);
        setAddress(setting.addressno);
        setStreet(setting.street);
        setSubDistrict(setting.m_subdistrict_id);
        setDistrict(setting.m_district_id);
        setProvince(setting.m_province_id);
        setPostCode(setting.postcode);
        setAmountInsiteArea(setting.amt_insite_area);
        setAmountOutsiteArea(setting.amt_outsite_area);
      }
    } else {
      ShowMessageAndExpire(
        "Failed to load data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
    setOnLoading(false);
  }, []);

  React.useEffect(() => {
    loadSetting();
  }, [loadSetting]);

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard title={"Setting (System Configuration)"} />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {onLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "10vh",
                    }}
                  >
                    {/* <Spinner
                      animation="border"
                      variant="info"
                      size="lg"
                      className="me-3"
                    />
                    <br/><h3>Loading...</h3> */}
                  </div>
                ) : (
                  <EditSystemSettingForm
                    onCancel={loadSetting}
                    onSubmitSuccess={loadSetting}
                    phoneDefaultValue={phone}
                    lineIdDefaultValue={lineId}
                    addressDefaultValue={address}
                    streetDefaultValue={street}
                    subDistrictDefaultValue={subDistrict}
                    districtDefaultValue={district}
                    provinceDefaultValue={province}
                    postCodeDefaultValue={postCode}
                    amountInsiteAreaDefaultValue={amountInsiteArea}
                    amountOutsiteAreaDefaultValue={amountOutsiteArea}
                  />
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
