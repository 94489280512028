import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import {
  TransactionStatusOptions,
  ExpenseTypeOptions,
} from "../../data/SelectOptions";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import { textFloatFormat } from "../../utils/Help";

export default function CreateEditExpenseTransactionForm({
  onCancel,
  onSubmitSuccess,
  id,
  code,
  action_datetime,
  recipient,
  category,
  remark,
  status,
  editData,
}) {
  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});

  return (
    <>
      <Container fluid style={{ minHeight: "20rem" }}>
        <>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                name="code"
                label="รหัสรายการ"
                defaultValue={code}
                disabled={true}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericDateTimePickerInput2
                control={control}
                name="action_datetime"
                label="วันที่และเวลาทำการ"
                placeholder="วันที่และเวลาทำการ"
                type="dateTime"
                defaultValue={
                  editData.action_datetime ? new Date(editData?.action_datetime) : null
                }
                disabled={true}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                name="amt"
                label="จำนวนเงิน"
                defaultValue={editData ? textFloatFormat(editData?.amt) : null}
                disabled={true}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                name="vat"
                label="ภาษีมูลค่าเพิ่ม"
                defaultValue={editData ? textFloatFormat(editData?.vat) : null}
                disabled={true}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                name="recipient"
                label="ชื่อผู้รับเงิน"
                defaultValue={recipient}
                disabled={true}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                name="category"
                label="กลุ่มรายการ"
                defaultValue={category}
                disabled={true}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextAreaInput
                name="remark"
                label="หมายเหตุ"
                placeholder=""
                defaultValue={remark}
                disabled={true}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                name="status"
                label="สถานะ"
                defaultValue={status}
                disabled={true}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3"></Row>
          <Row className="mb-3"></Row>
          <Row className="mb-3">
            <Col style={{ display: "flex", justifyContent: "end" }}>
              <CancelButton onClick={onCancel} />
            </Col>
          </Row>
        </>
      </Container>
    </>
  );
}
