import Cookies from "js-cookie";

export default function setPlaceholder(value) {
  value = value.join(" / ");
  return "ค้นหา: " + value;
}

export function checkPermissionButton(screenId, permission) {
  // Find the item in the jsonData array that matches the given code
  const jsonString = Cookies.get("permission");
  if (jsonString) {
    const jsonObject = JSON.parse(jsonString);
    const item = jsonObject.find((item) => item.code === screenId);

    // If the item is not found, return true (default)
    if (!item) {
      return true;
    }

    // Check the value of the specified permission
    if (item[permission] === 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function ThaiNumberToText(Number) {
  Number = Number.replace(/๐/gi, "0");
  Number = Number.replace(/๑/gi, "1");
  Number = Number.replace(/๒/gi, "2");
  Number = Number.replace(/๓/gi, "3");
  Number = Number.replace(/๔/gi, "4");
  Number = Number.replace(/๕/gi, "5");
  Number = Number.replace(/๖/gi, "6");
  Number = Number.replace(/๗/gi, "7");
  Number = Number.replace(/๘/gi, "8");
  Number = Number.replace(/๙/gi, "9");
  return ArabicNumberToText(Number);
}

export function ArabicNumberToText(Number) {
  var Number = CheckNumber(Number);
  var NumberArray = new Array(
    "ศูนย์",
    "หนึ่ง",
    "สอง",
    "สาม",
    "สี่",
    "ห้า",
    "หก",
    "เจ็ด",
    "แปด",
    "เก้า",
    "สิบ"
  );
  var DigitArray = new Array("", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน");
  var BahtText = "";
  if (isNaN(Number)) {
    return "ข้อมูลนำเข้าไม่ถูกต้อง";
  } else {
    if (Number - 0 > 9999999.9999) {
      return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้";
    } else {
      Number = Number.split(".");
      if (Number[1].length > 0) {
        Number[1] = Number[1].substring(0, 2);
      }
      var NumberLen = Number[0].length - 0;
      for (var i = 0; i < NumberLen; i++) {
        var tmp = Number[0].substring(i, i + 1) - 0;
        if (tmp != 0) {
          if (i == NumberLen - 1 && tmp == 1) {
            BahtText += "เอ็ด";
          } else if (i == NumberLen - 2 && tmp == 2) {
            BahtText += "ยี่";
          } else if (i == NumberLen - 2 && tmp == 1) {
            BahtText += "";
          } else {
            BahtText += NumberArray[tmp];
          }
          BahtText += DigitArray[NumberLen - i - 1];
        }
      }
      BahtText += "บาท";
      if (Number[1] == "0" || Number[1] == "00") {
        BahtText += "ถ้วน";
      } else {
        const DecimalLen = Number[1].length - 0;
        for (var i = 0; i < DecimalLen; i++) {
          var tmp = Number[1].substring(i, i + 1) - 0;
          if (tmp != 0) {
            if (i == DecimalLen - 1 && tmp == 1) {
              BahtText += "เอ็ด";
            } else if (i == DecimalLen - 2 && tmp == 2) {
              BahtText += "ยี่";
            } else if (i == DecimalLen - 2 && tmp == 1) {
              BahtText += "";
            } else {
              BahtText += NumberArray[tmp];
            }
            BahtText += DigitArray[DecimalLen - i - 1];
          }
        }
        BahtText += "สตางค์";
      }
      return BahtText;
    }
  }
}

export function CheckNumber(Number) {
  var decimal = false;
  Number = Number.toString();
  Number = Number.replace(/ |,|บาท|฿/gi, "");
  for (var i = 0; i < Number.length; i++) {
    if (Number[i] == ".") {
      decimal = true;
    }
  }
  if (decimal == false) {
    Number = Number + ".00";
  }
  return Number;
}

export function sleep(ms) {
  return new Promise((resolveFunc) => setTimeout(resolveFunc, ms));
}

export function textAlignRight(value) {
  value = textFloatFormat(value);
  return <div style={{ textAlign: "right" }}>{value}</div>;
}

export function textFloatFormat(value) {
  value = parseFloat(value);
  value = value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return value;
}
