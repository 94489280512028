import React from 'react'
import CancelButton from "../../components/Button/CancelButton";
import {createUserAppeal, getMemberInfo } from "../../services/AppealServices";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import GenericDateTimePickerInput from "../../components/Input/GenericDateTimePickerInput";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import CreateButton from "../../components/Button/CreateButton";
import SaveButton from "../../components/Button/SaveButton";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import {
  StatusTypeRadio,
} from "../../data/SelectOptions";
import RadioButton from "../../components/Button/RadioButton";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import GenericRadioInput from "../../components/Input/GenericRadioInput";


const schema = yup.object().shape({
  m_appeal_cat_id: yup.object().required("หมวดหมู่ห้ามว่าง"),
  notification_date: yup.string().required("วันที่แจ้งห้ามว่าง"),
  subject: yup.string().required("ชื่อเรื่องห้ามว่าง"),
  details : yup.string().required("รายละเอียดห้ามว่าง"),
  edit_details : yup.string().required("รายละเอียดการแก้ไขห้ามว่าง"),
  edited_by : yup.string().required("แก้ไขโดยห้ามว่าง"),
});

export default function AppealDetail({
    onCancel,
    onSubmitSuccess,
  }) {

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  
  const onSubmit = async (inputData) => {
    let noticeUser;
    if (inputData.status === "40") {
      noticeUser = 1;
    } else if (inputData.status === "41") {
      noticeUser = 2;
    }else if (inputData.status === "42") {
      noticeUser = 3;
    }
    const { success, data, error } = await createUserAppeal({
      m_appeal_cat_id: inputData.m_appeal_cat_id.id,
      subject: inputData.subject,
      description : inputData.details,
      description_fixed : inputData.edit_details,
      status : noticeUser,
      member_info_id: 1
    });
    if (success) {
      onSubmitSuccess();
      ShowMessageAndExpire("Data created successfully.", 'Data Success', "success");

    } else {
      console.log(error);
    }
  };


  const [memberList, setMemberList] = React.useState([]);

  const memberInfoList = React.useCallback(async () => {
    const { success, data, error } = await getMemberInfo({});
    if (success) {
      setMemberList(prepareMemberInfoList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  React.useEffect(() => {
    memberInfoList();
  }, [memberInfoList]);

  const prepareMemberInfoList = (datas) => {
    let listsMember = [];
    for (const data of datas) {
      const id = data.id;
      const name = data.name;
      const phone = data.phone;
      const email = data.email;
      listsMember.push({
        id: id,
        label: name,
        value: {
          id: id,
          firstName: data.name,
          lastName: data.lname,
          phone: phone,
          email: email,
        },
      });
    }
    return listsMember;
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h5>เรื่องที่รับแจ้ง</h5>
        <hr/>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
              <GenericSelectInput
                control={control}
                name="m_appeal_cat_id"
                label="หมวดหมู่"
                placeholder=""
                defaultValue={null}
                options={memberList}
                error={errors.m_appeal_cat_id}
                required
              />
          </Form.Group>
          <Col>
          </Col>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput
              register={register}
              name="notification_date"
              label="วันที่แจ้ง"
              placeholder=""
              defaultValue=""
              required
              error={errors.notification_date}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="subject"
              label="ชื่อเรื่อง"
              placeholder="Text....."
              defaultValue=""
              required
              error={errors.subject}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextAreaInput
              register={register}
              name="details"
              label="รายละเอียด"
              placeholder=""
              defaultValue=""
              required
              error={errors.details}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            สถานะ
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericRadioInput
              register={register}
              name="status"
              error={errors.userGroup}
              radios={[
                { id: 1, label: "รับแจ้ง", value: 40 },
                { id: 2, label: "ระหว่างดำเนินการ", value: 41 },
                { id: 3, label: "แก้ไขแล้ว", value: 42 },
              ]}
              type="radio"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextAreaInput
              register={register}
              name="edit_details"
              label="รายละเอียดการแก้ไข"
              placeholder=""
              defaultValue=""
              required
              error={errors.edit_details}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
        <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput
              register={register}
              name="edited_by"
              label="แก้ไขเมื่อ"
              placeholder=""
              defaultValue=""
              required
              error={errors.edited_by}
            />
          </Form.Group>
          <Col>
          </Col>
          <Col>
          </Col>
          </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton/>
          </Col>
        </Row>
      </Form>
    </>
  )
}
