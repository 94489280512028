import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import CreateNewVoucherForm from "../features/Form/CreateNewVoucherForm";
import VoucherLotForm from "../features/Form/VoucherLotForm";
import { getCommonAPI } from "../services/commonAPIServices";
import { VoucherManagementcolumn } from "../data/Columns";
import ExpireLotButton from "../components/Button/ExpireLotButton";
import DetailButton from "../components/Button/DetailButton";
import VoucherForm from "../features/Form/VoucherForm";

export default function DoctorInfo() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [Search, setSearch] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [editData, setEditData] = React.useState();
  const columnsData = VoucherManagementcolumn;
  const [keyEdit, setKeyEdit] = React.useState();
  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isOpenCraeateForm, setIsOpenCraeateForm] = React.useState(false);

  const loadVoucherInfo = React.useCallback(
    async (currentPage, pageSize, search) => {
      const page = currentPage;
      const limit = pageSize;
      const url_api = "/voucher_lot/datatable";
      const p_data = {
        page,
        limit,
        search,
      };
      const { success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      });
      if (success) {
        setTotal(data.total);
        setTableData(prepareTableData(data.records, handleOpenEditForm));
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  React.useEffect(() => {
    loadVoucherInfo(1, 10);
  }, [loadVoucherInfo]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadVoucherInfo(page, pageSize, Search);
    },
    [loadVoucherInfo, pageSize, Search]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadVoucherInfo(1, pageSize, Search);
    },
    [loadVoucherInfo, Search]
  );

  const openCreateForm = () => {
    setIsOpenCraeateForm(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setCurrentPage(1);
    setSearch("");
    loadVoucherInfo(1, pageSize, "");
  };

  const handleSearch = React.useCallback(
    async (Search) => {
      setCurrentPage(1);
      setSearch(Search);
      await loadVoucherInfo(1, pageSize, Search);
    },
    [loadVoucherInfo, pageSize]
  );

  const handleEdit = React.useCallback(async () => {
    handleCloseEdit();
    loadVoucherInfo(currentPage, pageSize, Search);
  }, [loadVoucherInfo, currentPage, pageSize, Search]);

  const handleOpenEditForm = (data, keyTabs, voucher_lot_id) => {
    setIsOpenForm(true);
    setEditData(data);
    setKeyEdit(keyTabs,voucher_lot_id);
  };

  const handleCloseEdit = () => {
    setIsOpenForm(false);
    setIsOpenCraeateForm(false);
    setEditData(null);
  };

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              editData
                ? `Voucher Lot :${editData?.lot_id}`
                : "Voucher Management"
            }
            buttonEnable={!isOpenForm && !isOpenCraeateForm}
            buttonIcon="plus"
            buttonMessage="New Voucher"
            onButtonOnClick={openCreateForm}
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenCraeateForm ? (
                  <>
                    <CreateNewVoucherForm
                      onCancel={handleCloseEdit}
                      onCreateSuccess={handleEdit}
                    />
                  </>
                ) : isOpenForm ? (
                  <>
                    <VoucherForm
                      onCancel={handleCloseEdit}
                      onSubmitSuccess={handleEdit}
                      id={editData?.id}
                      voucher_lot_id={editData?.id}
                      keyTabs={keyEdit}
                    />
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Search "
                          onSearch={handleSearch}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      ></Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, onEdit, onOpen) => {
  let tableData = [];
  for (const data of datas) {
    const tempTableData = {
      id: data.id,
      lot_id: `${data.lot_id}`,
      qty: data.qty,
      each_amt: data.each_amt,
      create_date: data.create_date,
      create_by: data.create_by,
      exp_date: data.exp_date,
      voucher_lot_id: data.voucher_lot_id,
    };
    const buttonData = {
      id: data.id,
      lot_id: `${data.lot_id}`,
      qty: data.qty,
      each_amt: data.each_amt,
      create_date: data.create_date,
      create_by: data.create_by,
      exp_date: data.exp_date,
    };
    tableData.push({
      ...tempTableData,
      detailexpireButton: (
        <>
          <DetailButton
            onClick={() => {
              onEdit(buttonData, "General");
            }}
          />{" "}
          <ExpireLotButton
            onClick={() => {
              onEdit(buttonData, "Vouchers");
            }}
          />
        </>
      ),
    });
  }
  // console.log(tableData);
  return tableData;
};
