import { Col, Form, Row } from "react-bootstrap"
import GenericTextInput from "../../components/Input/GenericTextInput"
import { yupResolver } from "@hookform/resolvers/yup"
import { set, useForm } from "react-hook-form"
import GenericDateTimePickerInput from "../../components/Input/GenericDateTimePickerInput"
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2"
import SaveButton from "../../components/Button/SaveButton"
import CancelButton from "../../components/Button/CancelButton"
import * as yup from "yup"
import {
  editMockUserMember,
  editUserMember,
} from "../../services/MemberServices"
import { editMockUserDoctor } from "../../services/DoctorServices"
import GenericSelectInput from "../../components/Input/GenericSelectInput"
import GenericRadioInput from "../../components/Input/GenericRadioInput"
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput"
import { DoctorOnsiteRadio, DoctorStatusRadio } from "../../data/SelectOptions"
import React from "react"
import {
  createCommonAPI,
  editCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices"
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire"
import ReactDatePicker from "react-datepicker"
import { get } from "jquery"

const schema = yup.object().shape({
  firstname: yup.string().required("ชื่อห้ามว่าง"),
  lastname: yup.string().required("นามสกุลห้ามว่าง"),
  email: yup.string().email("อีเมลไม่ถูกต้อง").required("อีเมลห้ามว่าง"),
  telephone: yup.string().required("เบอร์โทรศัพท์ห้ามว่าง"),
  brithday: yup.string().required("วันเกิดห้ามว่าง"),
  address: yup.string().required("ที่อยู่ห้ามว่าง"),
  street: yup.string().required("ถนนห้ามว่าง"),
  hospital_name: yup.string().required("ชื่อโรงพยาบาลห้ามว่าง"),
  //   remark: yup.string().required("หมายเหตุห้ามว่าง"),
  experience: yup.string().required("ประสบการณ์ห้ามว่าง"),
  degree: yup.string().required("วุฒิการศึกษาห้ามว่าง"),
  specialized: yup.string().required("ความเชี่ยวชาญห้ามว่าง"),
  //   remark2: yup.string().required("หมายเหตุห้ามว่าง"),
})

const allUserStatus = "all"

export default function DoctorEditGeneralForm({
  onCancel,
  onSubmitSuccess,
  id,
  editData,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const [provinceList, setProvinceList] = React.useState([])
  const [districtList, setDistrictList] = React.useState([])
  const [subdistrict, setSubdistrict] = React.useState([])
  const [partHospital, setPartHospital] = React.useState([])

  const loadDoctorAddress = React.useCallback(async () => {
    let url_api = "/address/datatable"
    let p_data = {
      "filter.any_id": editData.id,
      "filter.user_type": 1,
      "filter._relations": "rel_m_subdistrict_id",
    }
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    })
    if (success) {
      // console.log("address data", data.records[0]);
      setValue("address", data.records[0]?.addressno)
      setValue("street", data.records[0]?.street)
      setValue("m_province_id", data.records[0]?.m_province_id)
      setValue("m_district_id", data.records[0]?.m_district_id)
      setValue("m_subdistrict_id", data.records[0]?.m_subdistrict_id)
      setValue("address_id", data.records[0]?.id)
      provinceInfoList()
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error")
    }
  }, [])

  const provinceInfoList = React.useCallback(async () => {
    let url_api = "/m_province"
    let p_data = {}
    const { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    })
    if (success) {
      setProvinceList(prepareProvinceList(data.records))
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error")
    }
  }, [])

  const prepareProvinceList = (datas) => {
    let listsProvince = []
    for (const data of datas) {
      const id = data.id
      const name = data.name
      const province = {
        id: id,
        label: name,
        value: {
          id: id,
          name: name,
        },
      }
      listsProvince.push(province)
      if (data.id === getValues("m_province_id")) {
        setValue("province", province, { shouldValidate: true })
        districtInfoList(getValues("m_province_id"))
      }
    }

    return listsProvince
  }

  const districtInfoList = React.useCallback(async (provinceId) => {
    let url_api = "/m_district"
    let p_data = {
      "filter.m_province_id": provinceId,
    }
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    })
    if (success) {
      setDistrictList(prepareDistrictList(data.records))
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error")
    }
  }, [])

  const prepareDistrictList = (datas) => {
    let listsDistrict = []
    if (Array.isArray(datas)) {
      for (const data of datas) {
        const id = data.id
        const name = data.name
        const province = data.m_province_id
        const district = {
          id: id,
          label: name,
          value: {
            id: id,
            name: name,
            province: province,
          },
        }
        listsDistrict.push(district)
        if (data.id === getValues("m_district_id")) {
          setValue("district", district, { shouldValidate: true })
          loadSubdistrict(getValues("m_district_id"))
        }
      }
    } else {
      console.log("datas is not iterable.")
    }

    return listsDistrict
  }

  const loadSubdistrict = React.useCallback(async (districtId) => {
    let url_api = "/m_subdistrict"
    let p_data = {
      "filter.m_district_id": districtId,
    }
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    })
    if (success) {
      setSubdistrict(prepareSubdistrict(data.records))
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error")
    }
  }, [])

  const prepareSubdistrict = (datas) => {
    let listSubdistrict = []
    let m_subdistrict_id = getValues("m_subdistrict_id")
    for (const data of datas) {
      const id = data.id
      const name = data.name
      const postcode = data.postcode
      const district = data.m_district_id
      let subdistrict = {
        id: id,
        label: name,
        value: {
          id: id,
          name: name,
          postcode: postcode,
          district: district,
        },
      }
      listSubdistrict.push(subdistrict)
      if (data.id === m_subdistrict_id) {
        setValue("sub_district", subdistrict, { shouldValidate: true })
        setValue("post_code", subdistrict.value.postcode, {
          shouldValidate: true,
        })
      }
    }
    return listSubdistrict
  }

  const partnershipHospital = React.useCallback(async () => {
    const url_api = "/hospital_info"
    const p_data = {
      select: "id,name",
    }
    const { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    })
    if (success) {
      setPartHospital(preparePartHospital(data.records))
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error")
    }
  }, [])

  const preparePartHospital = (datas) => {
    let listsPartner = []
    for (const data of datas) {
      const id = data.id
      const name = data.name
      let partner = {
        id: id,
        label: name,
        value: {
          id: id,
          name: name,
        },
      }
      listsPartner.push(partner)
      if (id === editData?.partner) {
        setValue("partnership", partner, { shouldValidate: true })
      }
    }
    return listsPartner
  }

  const handleChangeProvince = (e) => {
    if (e?.value) {
      districtInfoList(e.value.id)
      setValue("district", null)
      setSubdistrict([])
      setValue("sub_district", null)
      setValue("post_code", null)
    } else {
      setDistrictList([])
      setValue("district", null)
      setSubdistrict([])
      setValue("sub_district", null)
      setValue("post_code", null)
    }
  }

  const handleChangeDisList = (e) => {
    if (e?.value) {
      loadSubdistrict(e.value.id)
      setSubdistrict([])
      setValue("sub_district", null)
      setValue("post_code", null)
    } else {
      setSubdistrict([])
      setValue("sub_district", null)
      setValue("post_code", null)
    }
  }

  const handleChangeSubDisList = (e) => {
    if (e?.value) {
      setValue("post_code", e.value?.postcode, { shouldValidate: true })
    } else {
      setValue("post_code", null)
    }
  }

  const onSubmit = async (inputData) => {
    let address_id = getValues("address_id")
    let url_api = "/doctor_info/" + editData.id
    let p_data = {
      name: inputData.firstname,
      lname: inputData.lastname,
      licenseno: inputData.id_card,
      phone: inputData.telephone,
      brithday: new Date(inputData.brithday).toLocaleString("sv"),
      email: inputData.email,
      status: parseInt(inputData.statusRadio),
      on_site: parseInt(inputData.onsiteRadio),
      partner: inputData.partnership?.id,
      hospital_name: inputData.hospital_name,
      remark: inputData.remark,
      experience: inputData.experience,
      degree: inputData.degree,
      specialized: inputData.specialized,
      remark2: inputData.remark2,
    }
    let { success, data, error } = await editCommonAPI({
      url_api,
      p_data,
    })
    if (success) {
      if (address_id) {
        url_api = "/address/" + address_id
        p_data = {
          addressno: inputData.address,
          street: inputData.street,
          phone: inputData.telephone,
          m_subdistrict_id: inputData.sub_district?.id,
          postcode_id: inputData.post_code,
          any_id: editData.id,
          address_type: 0,
          default: 1,
        }
        ;({ success, data, error } = await editCommonAPI({
          url_api,
          p_data,
        }))
        if (success) {
          console.log("edit address success")
          ShowMessageAndExpire("Data updated successfully.", "", "success")
        } else {
          console.log(error)
          var errorMessages = []
          if (typeof error.message === "string") {
            errorMessages.push(error.message)
          } else {
            for (var propertyName in error.message) {
              var errorMessage = error.message[propertyName]
              errorMessages.push(errorMessage)
            }
          }
          var concatenatedErrors = errorMessages.join("\n")

          ShowMessageAndExpire(
            "Failed to update data.",
            concatenatedErrors,
            "error"
          )
        }
      } else {
        url_api = "/address"
        p_data = {
          addressno: inputData.address,
          street: inputData.street,
          phone: inputData.telephone,
          m_subdistrict_id: inputData.sub_district?.id,
          postcode_id: inputData.post_code,
          user_type: 1,
          any_id: editData.id,
          address_type: 0,
          default: 1,
        }
        ;({ success, data, error } = await createCommonAPI({
          url_api,
          p_data,
        }))
        if (success) {
          console.log("add address success")
        } else {
          console.log(error)
        }
      }
      onSubmitSuccess()
    } else {
      console.log(error)
      var errorMessages = []
      if (typeof error.message === "string") {
        errorMessages.push(error.message)
      } else {
        for (var propertyName in error.message) {
          var errorMessage = error.message[propertyName]
          errorMessages.push(errorMessage)
        }
      }
      var concatenatedErrors = errorMessages.join("\n")

      ShowMessageAndExpire(
        "Failed to update data.",
        concatenatedErrors,
        "error"
      )
    }
  }

  React.useEffect(() => {
    loadDoctorAddress()
    partnershipHospital()
  }, [loadDoctorAddress, partnershipHospital])

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Tab content for General */}
        <h4>ข้อมูลส่วนบุคคล</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="firstname"
              label="Name"
              placeholder="Name"
              required
              defaultValue={editData ? editData?.name : null}
              error={errors.firstname}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="lastname"
              label="Lastname"
              placeholder="Lastname"
              required
              defaultValue={editData ? editData?.lname : null}
              error={errors.lastname}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="id_card"
              label="ID Card"
              placeholder="ID Card"
              // required
              defaultValue={editData ? editData?.licenseno : null}
              error={errors.id_card}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="telephone"
              label="Phone"
              placeholder="Phone"
              required
              defaultValue={editData ? editData?.phone : null}
              error={errors.telephone}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="brithday"
              label="Date of birth"
              placeholder="Date of birth"
              type="date"
              required
              defaultValue={editData ? new Date(editData?.brithday) : null}
              error={errors.brithday}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="email"
              label="Email Address"
              placeholder="Email Address"
              required
              defaultValue={editData ? editData?.email : null}
              error={errors.email}
            />
          </Form.Group>
        </Row>
        <h4>ข้อมูลแพทย์</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="experience"
              label="Experience"
              placeholder="Experience"
              defaultValue={editData ? editData?.experience : null}
              required
              error={errors.experience}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="degree"
              label="Degree"
              placeholder="Degree"
              defaultValue={editData ? editData?.degree : null}
              required
              error={errors.degree}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextAreaInput
              register={register}
              name="specialized"
              label="Specialized"
              placeholder="Specialized"
              defaultValue={editData ? editData?.specialized : null}
              required
              error={errors.specialized}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextAreaInput
              register={register}
              name="remark2"
              label="หมายเหตุจากแอดมิน"
              placeholder="หมายเหตุจากแอดมิน"
              defaultValue={editData ? editData?.remark2 : null}
              error={errors.remark2}
            />
          </Form.Group>
        </Row>
        <h4>ข้อมูลที่อยู่</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="address"
              label="Address"
              placeholder="Address"
              required
              error={errors.address}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="street"
              label="Street"
              placeholder="Street"
              required
              error={errors.street}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="province"
              label="Province"
              placeholder="Province"
              options={provinceList}
              required
              onChange={handleChangeProvince}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="district"
              label="District"
              placeholder="District"
              options={districtList}
              required
              onChange={handleChangeDisList}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="sub_district"
              label="Sub-District"
              placeholder="Sub-District"
              options={subdistrict}
              required
              onChange={handleChangeSubDisList}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="post_code"
              label="Post Code"
              placeholder="Post Code"
              required
              readOnly
              error={errors.post_code}
            />
          </Form.Group>
        </Row>
        <h4>ข้อมูลการทำงาน</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericRadioInput
              register={register}
              name="statusRadio"
              radios={DoctorStatusRadio}
              label={"Status"}
              error={errors.statusRadio}
              defaultChecked={editData ? [editData?.status] : null}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="partnership"
              label="Partnership Hospital (ใช้ตั้งค่าเพื่อแบ่ง % รายได้)"
              placeholder="Partnership Hospital"
              // required
              options={partHospital}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericRadioInput
              register={register}
              name="onsiteRadio"
              radios={DoctorOnsiteRadio}
              label={"On site Complie"}
              defaultChecked={editData ? [editData?.on_site] : null}
              error={errors.onsiteRadio}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="hospital_name"
              label="Hospital Title"
              placeholder="Hospital Title"
              required
              defaultValue={editData ? editData?.hospital_name : null}
              error={errors.hospital_name}
            />
          </Form.Group>
        </Row>
        <h4>หมายเหตุ</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextAreaInput
              register={register}
              name="remark"
              label="หมายเหตุจากแอดมิน"
              placeholder="หมายเหตุจากแอดมิน"
              defaultValue={editData ? editData?.remark : null}
              error={errors.remark}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  )
}
