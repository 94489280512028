import React from "react"
import "../assets/css/style-receipt.css"
import logo from "../assets/img/logo.svg"
import html2pdf from "html2pdf.js"
import { getCommonAPI } from "../services/commonAPIServices"
import { ArabicNumberToText } from "../utils/Help"

export default function ReceiptDeatil({ receiptno }) {
  console.log(receiptno)
  const [detail, setDetail] = React.useState([])

  const loadDetail = React.useCallback(async (currentPage, pageSize) => {
    const { success, data, error } = await getCommonAPI({
      url_api: "/get_receipt_detail",
      p_data: {
        "receipt_no ": receiptno,
      },
    })
    if (success) {
      setDetail(data.records)
    } else {
      console.log(error)
    }
  }, [])
  React.useEffect(() => {
    loadDetail(receiptno)
  }, [loadDetail])

  // const generatePDF = () => {
  //   const content = document.getElementById("contentToConvert");
  //   const options = {
  //     margin: [1,1,1,1],
  //     filename: "generated.pdf",
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
  //   };

  //   html2pdf().from(content).set(options).save();
  // };
  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        {/* <button onClick={generatePDF}>Generate PDF</button>
         */}
        <div id="contentToConvert">
          <div className="page-container hidden-on-narrow">
            <div className="pdf-page size-a4-landscape">
              <div className="inner-page">
                <div className="pdf-header">
                  <img src={logo} width="180px" />
                  <span className="company-logo">
                    <h2 className="margin-top">
                      {/* {detail[0]["company_name"]} */}
                      {detail[0] && detail[0].company_name}
                    </h2>
                    <span>
                      {detail[0] && detail[0].addressno}{" "}
                      {detail[0] && detail[0].street}{" "}
                      {detail[0] && detail[0].subdistrict}{" "}
                      {detail[0] && detail[0].district}{" "}
                      {detail[0] && detail[0].province}{" "}
                      {detail[0] && detail[0].postcode}
                    </span>
                    <span>
                      โทร : {detail[0] && detail[0].phone} แฟกซ์ :{" "}
                      {detail[0] && detail[0].fax}
                    </span>
                  </span>

                  <span className="invoice-number">
                    <h3 className="margin-top">
                      ต้นฉบับใบเสร็จรับเงิน/ใบกำกับภาษี
                    </h3>
                    <span>Original Receipe/Tax Invoice</span>
                    <span>Tax ID : {detail[0] && detail[0].tax_id}</span>
                  </span>
                </div>

                <div className="addresses">
                  <div className="for">
                    <p>
                      ได้รับเงินจาก/Receive From : คุณ 
                      {detail[0] && detail[0].uname}(
                      {detail[0] && detail[0].userid})
                      <br /> <br />
                      ที่อยู่/Address : {detail[0] && detail[0].uaddressno}{" "}
                      {detail[0] && detail[0].ustreet}{" "}
                      {detail[0] && detail[0].usubdistrict}{" "}
                      {detail[0] && detail[0].udistrict}{" "}
                      {detail[0] && detail[0].uprovince}{" "}
                      {detail[0] && detail[0].upostcode}
                      <br /> <br />
                      Tax ID : xxxxxxxxxxxxxxx
                    </p>
                  </div>

                  <div className="from">
                    <p>
                      วันที่/Date : {detail[0] && detail[0].create_date}
                      <br /> <br />
                      เลขที่/No. : {detail[0] && detail[0].receipt_no}
                      <br /> <br />
                      ใบแจ้งหนี้เลขที่/Invoice No. :{" "}
                      {detail[0] && detail[0].inv_no}
                    </p>
                  </div>
                </div>

                <table class="table table-striped t">
                  <thead>
                    <tr>
                      <th scope="col-6">
                        รายการ <br />
                        Description
                      </th>
                      <th scope="col-2" class="text-end">
                        จำนวนเงิน <br />
                        Amount
                      </th>
                      <th scope="col-2" class="text-end">
                        ภาษีมูลค่าเพิ่ม <br />
                        VAT
                      </th>
                      <th scope="col-2" class="text-end">
                        จำนวนเงินรวม (บาท) <br />
                        Total (Bath)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {detail.map((item, index) => (
                      <>
                        <tr>
                          <td>{item.items}</td>
                          <td className="text-end">{item.amt}</td>
                          <td className="text-end">{item.vat}</td>
                          <td className="text-end">{item.amt}</td>
                        </tr>
                      </>
                    ))}

                    <tr>
                      <td className="text-end">ยอดรวมทั้งสิ้น/GRAND TOTAL</td>
                      <td className="text-end">
                        {detail[0] && detail[0].total_amt}
                      </td>
                      <td className="text-end">
                        {detail[0] && detail[0].total_vat}
                      </td>
                      <td className="text-end">
                        {detail[0] && detail[0].grand_total}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" className="text-end">
                        {detail[0] && ArabicNumberToText(detail[0].grand_total)}
                      </td>
                      <td className="text-end">บาท/Bath</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="pdf-footer">
                <div className="row-footer">
                  <div className="pdf-body">
                    <div id="grid" />
                    <p className="signature">
                      ชำระโดย/Paid By
                      <br />
                      <br />
                      <br />
                      <br />
                      __________________________ <br />
                      <br />
                      ผู้รับเงิน/Collector
                    </p>
                  </div>

                  <div className="pdf-body-end">
                    <div id="grid" />
                    <p className="signature">
                      <br />
                      <br />
                      <br />
                      <br />
                      __________________________ <br />
                      <br />
                      ผู้มีอำนาจลงนาม/Authorized Signature
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
