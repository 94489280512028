import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { ArticleColumn } from "../data/Columns";

import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import ArticleForm from "../features/Form/ArticleDetailForm";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";
import SelectFilter from "../components/SelectFilter";
import { filterStatusOptions } from "../data/SelectOptions";
import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import { getArticle, deleteArticle } from "../services/ArticleService";


export default function Article() {
  // for Search Panel
  const [keyword, setKeyword] = React.useState("");
  const [status, setStatus] = React.useState(undefined);
  const [dateStart, setDateStart] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();
  // for datatable
  const columnsData = ArticleColumn;
  const [tableData, setTableData] = React.useState([]);
  const [total, setTotal] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  // for Manage Form
  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isCreateForm, setIsCreateForm] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const [selectedArticle, setSelectedArticle] = React.useState(null);
  const [title, setTitle] = React.useState(null);

  // Load Data
  const loadData = React.useCallback(
    async (
      currentPage,
      pageSize,
      status = null,
      keyword = null,
      dateStart = null,
      dateEnd = null
      // dateStart = '2023-07-13',
      // dateEnd = '2023-07-16'
    ) => {
      const { success, data, error } = await getArticle({
        page: currentPage,
        pageSize,
        status,
        keyword,
        dateStart,
        dateEnd,
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        console.log(error);
      }
    },
    []
  );

  // handle DataTable
  const prepareTableData = (datas, onEdit, onDelete) => {
    // console.log(datas);
    let tableData = [];
    for (const data of datas) {
      tableData.push({
        ...data,
        status: data.status.label,
        editDeleteButton: (
          <>
            <EditButton
              onClick={() => {
                onEdit(data);
              }}
            />{" "}
            <DeleteButton
              onClick={() => {
                onDelete(data);
              }}
            />
          </>
        ),
      });
    }
    return tableData;
  };

  const handleSearchData = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadData(1, pageSize, status, keyword, dateStart, dateEnd);
    },
    [loadData, pageSize, status, dateStart, dateEnd]
  );

  const handleSelectedDate = React.useCallback(
    async (sDate, eDate) => {
      setDateStart(sDate);
      setDateEnd(eDate);
      setCurrentPage(1);
      await loadData(1, pageSize, status, keyword, sDate, eDate);
    },
    [loadData, pageSize, status, keyword]
  );

  const handleChangePage = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadData(page, pageSize, status, keyword, dateStart, dateEnd);
    },
    [loadData, pageSize, status, keyword, dateStart, dateEnd]
  );

  const handleChangePageSize = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadData(1, pageSize, status, keyword, dateStart, dateEnd);
    },
    [loadData, status, keyword, dateStart, dateEnd]
  );

  const handleChangeStatus = React.useCallback(
    async (status) => {
      setCurrentPage(1);
      setStatus(status);
      await loadData(1, pageSize, status, keyword, dateStart, dateEnd);
    },
    [loadData, pageSize, keyword, dateStart, dateEnd]
  );

  // handle Form
  const handleCreateForm = () => {
    setIsOpenForm(true);
    setIsCreateForm(true);
    setSelectedArticle(null);
  };

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setIsCreateForm(false);
    setSelectedArticle(data);
  }

  const handleCloseEditForm = React.useCallback(async (isDirtyData) => {
    setIsOpenForm(false);
    setIsCreateForm(false);
    setSelectedArticle(null);
    if (isDirtyData) {
      loadData(currentPage, pageSize, status, keyword, dateStart, dateEnd);
    }
}, [loadData, currentPage, pageSize, status, keyword, dateStart, dateEnd]);

  const handleSubmitForm = React.useCallback(async (editedData) => {
    setIsOpenForm(false);
    setIsCreateForm(false);
    setSelectedArticle(null);
    loadData(currentPage, pageSize, status, keyword, dateStart, dateEnd);
}, [loadData, currentPage, pageSize, status, keyword, dateStart, dateEnd]);


  // handle Delete
  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setSelectedArticle(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setSelectedArticle(null);
  };

  const handleDeleteData = React.useCallback(
    async (deleteData) => {
      const { success, error } = await deleteArticle(deleteData);
      if (success) {
        handleCloseDeleteConfirm();
        if ((total - pageSize * (currentPage - 1) === 1) & (currentPage !== 1)) {
          setCurrentPage(currentPage - 1);
        }
        loadData(currentPage, pageSize, status, keyword, dateStart, dateEnd);
        console.log(success);
      } else {
        console.log(error);
      }
    }, [loadData, total, pageSize, currentPage, status, keyword, dateStart, dateEnd]
  );

  const setTitleCard = React.useCallback((isOpenForm, isCreateForm, selectedArticle) => {
    let titleText = "";
    if (!isOpenForm && !isCreateForm) {
      titleText = "Article";
    } else if (isOpenForm && isCreateForm) {
      titleText = "Article Detail";
    } else {
      titleText = `Article Detail : ${selectedArticle?.id} ${selectedArticle?.subject}`;
    }
    setTitle(titleText);
  }, [])

  React.useEffect(() => {
    loadData(1, 10);
  }, [loadData]);

  React.useEffect(() => {
    if (isRefresh) {
      console.log("isRefreshisRefreshisRefreshisRefreshisRefreshisRefreshisRefreshisRefreshisRefre")
      setCurrentPage(1);
      setKeyword('');
      setStatus(undefined);
      setDateStart(null);
      setDateEnd(null);
      loadData(1, pageSize);
      console.log("keyword")
      setIsRefresh(false);
    }
  }, [loadData, isRefresh, pageSize]);

  React.useEffect(() => {
    setTitleCard(isOpenForm, isCreateForm, selectedArticle);
  }, [setTitleCard, isOpenForm, isCreateForm, selectedArticle]);

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={title}
            buttonEnable={!isOpenForm}
            buttonIcon="plus"
            buttonMessage="Create New Records"
            onButtonOnClick={handleCreateForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDeleteData}
            deleteRecord={selectedArticle}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`id = ${selectedArticle?.id}, subject= ${selectedArticle?.subject}`}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenForm ? (
                  <>
                    <ArticleForm
                      isCreateForm={isCreateForm}
                      onCancel={handleCloseEditForm}
                      onSubmitSuccess={handleSubmitForm}
                      selectedArticle={selectedArticle}
                    />
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Filter : หัวข้อข่าว, วันทีทำรายการ, วันที่หมดอายุ"
                          onSearch={handleSearchData}
                          defaultValue={keyword}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="สถานะ"
                          placeholder={"สถานะ"}
                          defaultValue={filterStatusOptions.find(
                            (e) => e.value === undefined
                          )}
                          value={filterStatusOptions.find(
                            (e) => e.value === status
                          )}
                          options={filterStatusOptions}
                          onChange={handleChangeStatus}
                        />
                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={dateStart ? new Date(dateStart) : null}
                          defaultEnd={dateEnd ? new Date(dateEnd) : null}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={handleChangePage}
                          onPageSizeChange={handleChangePageSize}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
