import React from "react";
import { emsVanInfoColumn } from "../data/Columns";
import { deleteVanInfo, getVanInfo } from "../services/EmsVanServices";
import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";
import GenericPagination from "../components/GenericPagination";
import { Card, Col, Container, Row } from "react-bootstrap";
import ReactTable from "../components/ReactTable";
import SelectFilter from "../components/SelectFilter";
import Searching from "../components/Searching";
import CreateEditEmsVanForm from "../features/Form/CreateEditEmsVanForm";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import Header from "../layouts/Header";
import {
  filterActiveStatusOptions,
  filterEmsVanTypeOptions,
} from "../data/SelectOptions";
import { EmsVanTypeDbValue, StatusDbValue } from "../data/Enumerator";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";

const allEmsVanType = null;
const allEmsVanStatus = null;

export default function EmsVanManagement() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [emsVanType, setEmsVanType] = React.useState(allEmsVanType);
  const [emsVanStatus, setEmsVanStatus] = React.useState(allEmsVanStatus);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [tableData, setTableData] = React.useState([]);
  const columnsData = emsVanInfoColumn;

  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();

  const loadEmsVan = React.useCallback(
    async (
      currentPage,
      pageSize,
      emsVanType,
      emsVanStatus,
      keyword,
      startDate,
      endDate
    ) => {
      let dateFilter = null;
      if (startDate && endDate) {
        dateFilter = `$btw:${startDate} 00:00:00, ${endDate} 23:59:59`;
      }
      const { success, data, error } = await getVanInfo({
        params: {
          page: currentPage,
          limit: pageSize,
          search: keyword,
          "filter.van_type": emsVanType,
          "filter.status": emsVanStatus,
          "filter.update_date": dateFilter,
        },
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        ShowMessageAndExpire(
          "Failed to load data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    []
  );

  React.useEffect(() => {
    loadEmsVan(1, 10, allEmsVanStatus);
  }, [loadEmsVan]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadEmsVan(
        page,
        pageSize,
        emsVanType,
        emsVanStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [
      loadEmsVan,
      pageSize,
      emsVanType,
      emsVanStatus,
      keyword,
      startDate,
      endDate,
    ]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadEmsVan(
        1,
        pageSize,
        emsVanType,
        emsVanStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadEmsVan, emsVanType, emsVanStatus, keyword, startDate, endDate]
  );

  const loadPageOnEmsVanStatusChange = React.useCallback(
    async (emsVanStatus) => {
      setCurrentPage(1);
      setEmsVanStatus(emsVanStatus);
      await loadEmsVan(
        1,
        pageSize,
        emsVanType,
        emsVanStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadEmsVan, pageSize, emsVanType, keyword, startDate, endDate]
  );

  const loadPageOnEmsVanTypehange = React.useCallback(
    async (emsVanType) => {
      setCurrentPage(1);
      setEmsVanType(emsVanType);
      await loadEmsVan(
        1,
        pageSize,
        emsVanType,
        emsVanStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadEmsVan, pageSize, emsVanStatus, keyword, startDate, endDate]
  );

  const openForm = () => {
    setIsOpenForm(true);
    setIsCreate(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setIsCreate(false);
    setEditData(null);
    setCurrentPage(1);
    setKeyword("");
    setEmsVanStatus(allEmsVanStatus);
    setEmsVanType(allEmsVanType);
    setStartDate(null);
    setStartDate(null);
    loadEmsVan(1, pageSize, allEmsVanStatus, allEmsVanStatus, "", null, null);
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadEmsVan(
        1,
        pageSize,
        emsVanType,
        emsVanStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadEmsVan, pageSize, emsVanType, emsVanStatus, startDate, endDate]
  );

  const handleSelectedDate = React.useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate);
      setEndDate(endDate);
      setCurrentPage(1);
      await loadEmsVan(
        1,
        pageSize,
        emsVanType,
        emsVanStatus,
        keyword,
        startDate,
        endDate
      );
    },
    [loadEmsVan, pageSize, emsVanType, emsVanStatus, keyword]
  );

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setIsCreate(false);
    setEditData(data);
  };

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const { success, error } = await deleteVanInfo({
        pathParams: id,
      });
      if (success) {
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadEmsVan(
          tempCurrentPage,
          pageSize,
          emsVanType,
          emsVanStatus,
          keyword,
          startDate,
          endDate
        );
      } else {
        handleCloseDeleteConfirm();
        ShowMessageAndExpire(
          "Failed to delete data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [
      loadEmsVan,
      total,
      currentPage,
      pageSize,
      emsVanType,
      emsVanStatus,
      keyword,
      startDate,
      endDate,
    ]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              !isOpenForm
                ? "Vehicle"
                : isCreate
                ? "Create Vehicle"
                : "Vehicle Detail"
            }
            buttonEnable={!isOpenForm}
            buttonIcon="plus"
            buttonMessage="New Vehicle"
            onButtonOnClick={openForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`id = ${deleteData?.id}, carType= ${deleteData?.carType}, ทะเบียนรถ= ${deleteData?.licensePlate} `}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenForm ? (
                  <>
                    <Row className="mt-3">
                      <CreateEditEmsVanForm
                        isCreate={isCreate}
                        onCancel={refreshPage}
                        onSubmitSuccess={refreshPage}
                        carTypeDefaultValue={editData?.carType}
                        statusDefaultValue={editData?.status}
                        licensePlateDefaultValue={editData?.licensePlate}
                        affiliationDefaultValue={editData?.affiliation}
                        provinceDefaultValue={editData?.province}
                        districtDefaultValue={editData?.district}
                        infoDefaultValue={editData?.info}
                        emailDefaultValue={editData?.email}
                        phoneDefaultValue={editData?.phone}
                        lineIdDefaultValue={editData?.lineId}
                        equipmentDefaultValue={editData?.equipment}
                        amtinsiteareaDefaultValue={editData?.amt_insite_area}
                        amtoutsiteareaDefaultValue={editData?.amt_outsite_area}
                        id={editData?.id}
                      />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Filter : ประเภทรถ ทะเบียนรถ"
                          onSearch={handleSearch}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* <SelectFilter
                          label="ประเภทรถ"
                          placeholder={"ประเภทรถ"}
                          defaultValue={filterEmsVanTypeOptions.find(
                            (e) => e.value === allEmsVanType
                          )}
                          value={filterEmsVanTypeOptions.find(
                            (e) => e.value === emsVanType
                          )}
                          options={filterEmsVanTypeOptions}
                          onChange={loadPageOnEmsVanTypehange}
                        /> */}
                        <SelectFilter
                          label="สถานะ"
                          placeholder={"สถานะ"}
                          defaultValue={filterActiveStatusOptions.find(
                            (e) => e.value === allEmsVanStatus
                          )}
                          value={filterActiveStatusOptions.find(
                            (e) => e.value === emsVanStatus
                          )}
                          options={filterActiveStatusOptions}
                          onChange={loadPageOnEmsVanStatusChange}
                        />
                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={startDate ? new Date(startDate) : null}
                          defaultEnd={endDate ? new Date(endDate) : null}
                          placeholder="เลือกวันที่อัพเดท"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = [];
  for (const [idx, data] of datas.entries()) {
    const tempTableData = {
      no: startNo + idx,
      id: data.id,
      carType:
        data.van_type === EmsVanTypeDbValue.Ems
          ? "EMS"
          : data.van_type === EmsVanTypeDbValue.Van
          ? "VAN"
          : "-",
      licensePlate: data.license_place,
      status:
        data.status === StatusDbValue.Inactive
          ? "Inactive"
          : data.status === StatusDbValue.Active
          ? "Active"
          : "-",
      update_date: data.update_date,
    };
    const buttonData = {
      id: data.id,
      carType: data.van_type,
      licensePlate: data.license_place,
      affiliation: data.beunder,
      province: data.m_province_id,
      district: data.m_district_id,
      status: data.status,
      info: data.remark,
      email: data.email,
      phone: data.phone,
      lineId: data.acc_line,
      equipment: data.m_van_equipment_ids,
      amt_insite_area: data.amt_insite_area,
      amt_outsite_area: data.amt_outsite_area,
    };
    tableData.push({
      ...tempTableData,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(buttonData);
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(tempTableData);
            }}
          />
        </>
      ),
    });
  }
  return tableData;
};
