import React from "react"
import { productColumn } from "../data/Columns"
import { Card, Col, Container, Row } from "react-bootstrap"
import Header from "../layouts/Header"
import PageHeaderCard from "../components/Card/PageHeaderCard"
import Searching from "../components/Searching"
import ReactTable from "../components/ReactTable"
import GenericPagination from "../components/GenericPagination"
import SelectFilter from "../components/SelectFilter"
import { filterProductStatusOptions } from "../data/SelectOptions"
import CreateEditProductForm from "../features/Form/CreateEditProductForm"
import {
  deleteProduct,
  getProduct,
  getProductCategory,
} from "../services/ProductManagenentServices"
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal"
import EditButton from "../components/Button/EditButton"
import DeleteButton from "../components/Button/DeleteButton"
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire"
import { StatusDbValue } from "../data/Enumerator"
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2"
import { textAlignRight } from "../utils/Help"

const allProductStatus = null

export default function ProductManagement() {
  const [total, setTotal] = React.useState(1)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(10)
  const [keyword, setKeyword] = React.useState("")
  const [productStatus, setProductStatus] = React.useState(allProductStatus)
  const [startDate, setStartDate] = React.useState()
  const [endDate, setEndDate] = React.useState()
  const [tableData, setTableData] = React.useState([])
  const columnsData = productColumn

  const [productCategoryOptionData, setProductCategoryOptionData] =
    React.useState()
  const [isOpenForm, setIsOpenForm] = React.useState(false)
  const [isCreate, setIsCreate] = React.useState(false)
  const [editData, setEditData] = React.useState()
  const [isDelete, setIsDelete] = React.useState(false)
  const [deleteData, setDeleteData] = React.useState()

  const loadProduct = React.useCallback(
    async (
      currentPage,
      pageSize,
      productStatus,
      keyword,
      startDate,
      endDate
    ) => {
      let dateFilter = null
      if (startDate && endDate) {
        dateFilter = `$btw:${startDate} 00:00:00, ${endDate} 23:59:59`
      }
      const { success, data, error } = await getProduct({
        params: {
          page: currentPage,
          limit: pageSize,
          search: keyword,
          "filter.status": productStatus,
          "filter.update_date": dateFilter,
          "filter._relations": "rel_product_cat_id,rel_product_picture_ids",
        },
        pathParams: "datatable",
      })
      if (success) {
        setTotal(data.total)
        setTableData(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        )
      } else {
        ShowMessageAndExpire(
          "Failed to load data.",
          JSON.stringify(error?.message),
          "error"
        )
      }
    },
    []
  )

  const loadProductCategory = React.useCallback(async () => {
    const { success, data, error } = await getProductCategory({
      params: {
        limit: 1000,
        // "filter._relations": "rel_product_cat_id",
      },
    })
    if (success) {
      let productCategoryOption = []
      for (const [idx, productCategory] of data.records.entries()) {
        productCategoryOption.push({
          id: idx,
          label: productCategory?.category_name,
          value: productCategory?.id,
        })
      }
      setProductCategoryOptionData(productCategoryOption)
    } else {
      ShowMessageAndExpire(
        "Load Category Data Failed",
        JSON.stringify(error?.message),
        "error"
      )
    }
  }, [])

  React.useEffect(() => {
    loadProduct(1, 10, allProductStatus)
    loadProductCategory()
  }, [loadProduct, loadProductCategory])

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page)
      await loadProduct(
        page,
        pageSize,
        productStatus,
        keyword,
        startDate,
        endDate
      )
    },
    [loadProduct, pageSize, productStatus, keyword, startDate, endDate]
  )

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1)
      setPageSize(pageSize)
      await loadProduct(1, pageSize, productStatus, keyword, startDate, endDate)
    },
    [loadProduct, productStatus, keyword, startDate, endDate]
  )

  const loadPageOnUserStatusChange = React.useCallback(
    async (productStatus) => {
      setCurrentPage(1)
      setProductStatus(productStatus)
      await loadProduct(1, pageSize, productStatus, keyword, startDate, endDate)
    },
    [loadProduct, pageSize, keyword, startDate, endDate]
  )

  const openForm = () => {
    setIsOpenForm(true)
    setIsCreate(true)
  }

  const refreshPage = () => {
    setIsOpenForm(false)
    setIsCreate(false)
    setEditData(null)
    setIsDelete(false)
    setDeleteData(null)
    setCurrentPage(1)
    setKeyword("")
    setProductStatus(allProductStatus)
    setStartDate(null)
    setStartDate(null)
    loadProduct(1, pageSize, allProductStatus, "", null, null)
  }

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1)
      setKeyword(keyword)
      await loadProduct(1, pageSize, productStatus, keyword, startDate, endDate)
    },
    [loadProduct, pageSize, productStatus, startDate, endDate]
  )

  const handleSelectedDate = React.useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate)
      setEndDate(endDate)
      setCurrentPage(1)
      await loadProduct(1, pageSize, productStatus, keyword, startDate, endDate)
    },
    [loadProduct, pageSize, productStatus, keyword]
  )

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true)
    setIsCreate(false)
    setEditData(data)
  }

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true)
    setDeleteData(data)
  }

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false)
    setDeleteData(null)
  }

  const handleDelete = React.useCallback(
    async (id) => {
      const { success, error } = await deleteProduct({
        pathParams: id,
      })
      if (success) {
        ShowMessageAndExpire("Data deleted successfully.", "", "success")
        handleCloseDeleteConfirm()
        let tempCurrentPage = currentPage
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1)
          tempCurrentPage = tempCurrentPage - 1
        }
        loadProduct(
          tempCurrentPage,
          pageSize,
          productStatus,
          keyword,
          startDate,
          endDate
        )
      } else {
        handleCloseDeleteConfirm()
        ShowMessageAndExpire(
          "Failed to delete data.",
          JSON.stringify(error?.message),
          "error"
        )
      }
    },
    [
      loadProduct,
      total,
      currentPage,
      pageSize,
      productStatus,
      keyword,
      startDate,
      endDate,
    ]
  )

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              !isOpenForm
                ? "Product Management"
                : isCreate
                ? "New Product"
                : "Product Detail"
            }
            buttonEnable={!isOpenForm}
            buttonIcon="plus"
            buttonMessage="New Product"
            onButtonOnClick={openForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`id = ${deleteData?.id}, รหัสสินค้า= ${deleteData?.productCode}, ชื่อสินค้า= ${deleteData?.productName} `}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenForm ? (
                  <>
                    <Row className="mt-3">
                      <CreateEditProductForm
                        isCreate={isCreate}
                        onCancel={refreshPage}
                        onSubmitSuccess={refreshPage}
                        languageDefaultValue={editData?.language}
                        productBrandDefaultValue={editData?.productBrand}
                        productCategoryDefaultValue={
                          editData?.productCategoryId
                        }
                        productNameDefaultValue={editData?.productName}
                        productCodeDefaultValue={editData?.productCode}
                        productDoctorNameDefaultValue={
                          editData?.productDoctorName
                        }
                        productOrderNumberDefaultValue={
                          editData?.productOrderNumber
                        }
                        productStatusDefaultValue={editData?.productStatus}
                        unitCostDefaultValue={editData?.unitCost}
                        unitDefaultValue={editData?.unit}
                        sellingPriceDefaultValue={editData?.sellingPrice}
                        wholesalePriceDefaultValue={editData?.wholesalePrice}
                        recommendedDosageDefaultValue={
                          editData?.recommendedDosage
                        }
                        petTypeDefaultValue={editData?.petType}
                        productInfoDefaultValue={editData?.productInfo}
                        thumbnailPhotoDefaultValue={editData?.thumbnailPhoto}
                        photoSetDefaultValue={editData?.photoSet}
                        isSellOnlineDefaultValue={editData?.isSellOnline}
                        isAlertStockEmptyDefaultValue={editData?.isAlertStock}
                        isShowOnlyInternalSystemDefaultValue={
                          editData?.isShowOnlyInternalSystem
                        }
                        isShowProductDefaultValue={editData?.isShowProduct}
                        isMedicalDefaultValue={editData?.isMedical}
                        isVaccineDefaultValue={editData?.isVaccine}
                        id={editData?.id}
                        productCategoryOptionData={productCategoryOptionData}
                      />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Filter : รหัสสินค้า, ชื่อสินค้า, หมวดหมู่สินค้า, ยี่ห้อสินค้า"
                          onSearch={handleSearch}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="สถานะ"
                          placeholder={"สถานะ"}
                          defaultValue={filterProductStatusOptions.find(
                            (e) => e.value === allProductStatus
                          )}
                          value={filterProductStatusOptions.find(
                            (e) => e.value === productStatus
                          )}
                          options={filterProductStatusOptions}
                          onChange={loadPageOnUserStatusChange}
                        />
                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={startDate ? new Date(startDate) : null}
                          defaultEnd={endDate ? new Date(endDate) : null}
                          placeholder="เลือกวันที่อัพเดท"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = []
  for (const [idx, data] of datas.entries()) {
    const tempTableData = {
      no: startNo + idx,
      id: data.id,
      productCode: data.code,
      productName: data.product_name,
      productCategory: data.rel_product_cat_id?.category_name,
      productBrand: data.brandname,
      productOrderNumber: data.product_order,
      productQty: data.product_stock_qty_balance,
      sellingPrice: textAlignRight(data.price1),
      productStatus:
        data.status === StatusDbValue.Active
          ? "Active"
          : data.status === StatusDbValue.Inactive
          ? "Inactive"
          : "-",
    }
    const editButtonData = {
      id: data.id,
      language: data.language,
      productCode: data.code,
      productName: data.product_name,
      productCategory: data.rel_product_cat_id?.category_name,
      productCategoryId: data.product_cat_id,
      productBrand: data.brandname,
      productOrderNumber: data.product_order,
      productStatus: data.status,
      productDoctorName: data.product_name2,
      unitCost: data.product_cost,
      unit: data.m_unit_id,
      sellingPrice: data.price1,
      wholesalePrice: data.price2,
      recommendedDosage: data.qty_recomment,
      petType: data.m_pet_type_ids,
      productInfo: data.description,
      thumbnailPhoto: data.picture_path,
      photoSet: data.rel_product_picture_ids,
      isSellOnline: [!!data.f_sale_online],
      isAlertStock: [!!data.f_low_stock],
      isShowOnlyInternalSystem: [!!data.f_show_insite],
      isShowProduct: [!!data.f_show_stock],
      isMedical: [!!data.f_medical],
      isVaccine: [!!data.f_vaccinc],
    }
    const deleteButtonData = {
      id: data.id,
      productCode: data.code,
      productName: data.product_name,
    }
    tableData.push({
      ...tempTableData,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(editButtonData)
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(deleteButtonData)
            }}
          />
        </>
      ),
    })
  }
  return tableData
}
