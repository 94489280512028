import React from "react";
import * as yup from "yup";
import { Col, Form, Modal, Row } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import CancelButton from "../../components/Button/CancelButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SaveButton from "../../components/Button/SaveButton";
import { uploadPhotoSetProduct } from "../../services/ProductManagenentServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";

const schema = yup.object().shape({
  description: yup.string().required("ห้ามว่าง"),
  order: yup.number().required("ห้ามว่าง").typeError("โปรดระบุเป็นตัวเลข"),
  image: yup
    .mixed()
    .required("ห้ามว่าง")
    .test("fileType", "ห้ามว่าง", (value) => {
      return value[0] ? true : false;
    })
    .test("fileType", "ไฟล์ผิดประเภท", (value) => {
      if (!value) {
        return false;
      }
      const supportedFormats = ["image/jpeg", "image/png"];
      return supportedFormats.includes(value[0]?.type);
    }),
});

export default function AddProductPhotoSetModal({
  show,
  setShow,
  onSave,
  productId,
}) {
  const [previewImage, setPreviewImage] = React.useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const refresh = () => {
    setPreviewImage(null);
    reset();
  };

  const onCancel = async () => {
    setShow(false);
    refresh();
  };

  const onSubmit = async (inputData) => {
    const photoSetFile = inputData.image[0];
    let bodyFormData = new FormData();
    bodyFormData.append("image", photoSetFile, photoSetFile?.name);
    bodyFormData.append("product_id", productId);
    bodyFormData.append("description", inputData.description);
    bodyFormData.append("product_order", inputData.order);
    const { success, error } = await uploadPhotoSetProduct({
      bodyFormData: bodyFormData,
    });
    if (success) {
      onSave();
      refresh();
    } else {
      ShowMessageAndExpire(
        "Upload Photo Set Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      show={show}
      onHide={() => onCancel()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>เพิ่มรูปภาพ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="description"
                label="รายละเอียด"
                placeholder="รายละเอียด"
                defaultValue={null}
                required
                error={errors.description}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="order"
                label="ลำดับการแสดง"
                placeholder="ลำดับการแสดง"
                defaultValue={""}
                required
                error={errors.order}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="image"
                label="รูปภาพ"
                placeholder="รูปภาพ"
                defaultValue={null}
                error={errors.image}
                type="file"
                required
                onChange={handleImageChange}
              />
            </Form.Group>
          </Row>
          {previewImage && (
            <Row className="mb-3">
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={previewImage}
                  alt="Preview"
                  style={{
                    width: "150px",
                  }}
                />
              </Col>
            </Row>
          )}
          <Row className="mb-3">
            <Col>
              <CancelButton onClick={() => onCancel()} />
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }}>
              <SaveButton />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
