import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getLeaveInfo = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/doctor_leave/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createLeaveInfo = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/doctor_leave",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const approveLeaveInfo = async ({ pathParams }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/doctor_leave/approve/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const notApproveLeaveInfo = async ({ pathParams }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/doctor_leave/reject/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteLeaveInfo = async ({ pathParams }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/doctor_leave/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockLeaveInfo = async ({ page, pageSize, keyword, status }) => {
  let records = [];
  let total = 101;
  if (keyword) {
    total = Math.round(total / 2);
  }
  if (status === "all") {
    total = Math.round(total);
  } else if (status === "approve") {
    total = Math.round(total / 2);
  } else if (status === "disapprove") {
    total = Math.round(total / 3);
  } else if (status === "waiting") {
    total = Math.round(total / 4);
  }
  const startId = page * pageSize - pageSize + 1;
  const endId = page * pageSize;
  for (let i = startId; i <= endId && i <= total; i++) {
    records.push({
      leaveId: i,
      requestDate: new Date(),
      doctorName: `นพ.เอกมัย ดีใจ ${i}`,
      leaveDate: new Date(),
      permissionStatus:
        i % 3 === 1 ? "อนุมัติ" : i % 3 === 2 ? "ไม่อนุมัติ" : "รอดำเนินการ",
      approveBy: "แอดมิน A",
      remark: "remark note",
    });
  }
  return {
    success: true,
    data: {
      total: total,
      currentPage: page,
      pageSize: pageSize,
      records: records,
    },
    error: null,
  };
};

export const editMockLeaveInfo = async ({ leaveId, status }) => {
  return {
    success: true,
    data: { leaveId, status },
    error: null,
  };
};

export const deleteMockLeaveInfo = async ({ leaveId }) => {
  return {
    success: true,
    data: { leaveId: leaveId },
    error: null,
  };
};
