import React from "react";
import { appealManagementColumn } from "../data/Columns";
import { getUser,deleteRecord } from "../services/AppealServices";
import { Card, Col, Container, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import SelectFilter from "../components/SelectFilter";
import { filterStatusTypeOptions } from "../data/SelectOptions";
import AppealDetail from "../features/Form/AppealDetail";
import AppealDetailEditForm from "../features/Form/AppealDetailEditForm";
import DeleteButton from "../components/Button/DeleteButton";
import EditButton from "../components/Button/EditButton";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";
import { getNotification } from "../services/NotificationServices";
import {
  Button,
} from "react-bootstrap";

const allBannerStatus = "all";
const allNotificationType = "all";

export default function AppealManagement() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [userStatus, setUserStatus] = React.useState(allBannerStatus);
  const [notiType, setNotiType] = React.useState(allNotificationType);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [tableData, setTableData] = React.useState([]);
  const [editData, setEditData] = React.useState();
  const [deleteData, setDeleteData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const columnsData = appealManagementColumn;
  const [isEdit, setIsEdit] = React.useState(false);
  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [recordStatus, setRecordStatus] = React.useState();
  const [dateStart, setDateStart] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();
  const [isCreate, setIsCreate] = React.useState(false);

  const loadBannerInfo = React.useCallback(
    async (currentPage, pageSize, recordStatus, keyword, dateStart, dateEnd) => {
        const { success, data, error } = await getUser({
            page: currentPage,
            pageSize: pageSize,
            search: keyword,
            status: recordStatus,
            dateStart,
            dateEnd
        });
        if (success) {
            setTotal(data.total);
            setTableData(
                prepareTableData(
                    data.records,
                    (currentPage - 1) * pageSize + 1,
                    handleOpenEdit,
                    handleOpenDeleteConfirm
                )
            );
        } else {
            ShowMessageAndExpire("Failed to load data.", error?.message, "error");
        }
    },
    []
);

  React.useEffect(() => {
    loadBannerInfo(1, 10);
  }, [loadBannerInfo]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
        setCurrentPage(page);
        await loadBannerInfo(page, pageSize, recordStatus, keyword, dateStart, dateEnd);
    },
    [loadBannerInfo, pageSize, recordStatus, keyword, dateStart, dateEnd]
  );

  const handleOpenDeleteConfirm = (data) => {
    // console.log(data);
    setIsDelete(true);
    setDeleteData(data);
  };
  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };
  const handleCloseEdit = () => {
    setIsEdit(false);
    setIsCreate(false);
    setEditData(null);
  };
  const handleOpenEdit = (data) => {
    setIsEdit(true);
    setEditData(data);
    // console.log(data);
  };
  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadBannerInfo(1, pageSize, userStatus, keyword);
    },
    [loadBannerInfo, userStatus, keyword]
  );
  const handleDelete = React.useCallback(
        async (dataId) => {
            // console.log(dataId);
            const { success, data, error } = await deleteRecord({
                id: dataId,
            });
            if (success) {
                handleCloseDeleteConfirm();
                let tempCurrentPage = currentPage;
                if (
                    (total - pageSize * (currentPage - 1) === 1) &
                    (currentPage !== 1)
                ) {
                    setCurrentPage(currentPage - 1);
                    tempCurrentPage = tempCurrentPage - 1;
                }
                loadBannerInfo(tempCurrentPage, pageSize, recordStatus, keyword, dateStart, dateEnd);
            } else {
                console.log(error);
            }
        },
        [loadBannerInfo, total, currentPage, pageSize, recordStatus, keyword, dateStart, dateEnd]
    );
    const handleOpenCreate = () => {
      setIsCreate(true);
    };
    const handleCloseCreate = () => {
      setIsCreate(false);
    };
  const loadPageOnStatusChange = React.useCallback(
    async (recordStatus) => {
        setCurrentPage(1);
        setRecordStatus(recordStatus);
        await loadBannerInfo(1, pageSize, recordStatus, keyword, dateStart, dateEnd);
    },
    [loadBannerInfo, pageSize, keyword, dateStart, dateEnd]
);

  const openForm = () => {
    setIsOpenForm(true);
  };
  const handleEdit = React.useCallback(async () => {
    handleCloseEdit();
    loadBannerInfo(currentPage, pageSize, recordStatus, keyword, dateStart, dateEnd);
  }, [loadBannerInfo, currentPage, pageSize, recordStatus, keyword, dateStart, dateEnd]);

  const handleSearch = React.useCallback(
    async (keyword) => {
        setCurrentPage(1);
        setKeyword(keyword);
        await loadBannerInfo(1, pageSize, recordStatus, keyword);
    },
    [loadBannerInfo, pageSize, recordStatus]
  );
  const handleSelectedDate = React.useCallback(
    async (sDate, eDate) => {
        setDateStart(sDate);
        setDateEnd(eDate);
        setCurrentPage(1);
        await loadBannerInfo(1, pageSize, recordStatus, keyword, sDate, eDate);
    },
    [loadBannerInfo, pageSize, recordStatus, keyword]
  );
  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
        <PageHeaderCard
            title="Appeal Management"
            buttonEnable={!isEdit && !isCreate}
            buttonIcon="plus"
            buttonMessage="Create New Records"
            onButtonOnClick={handleOpenCreate}
          />
          <DeleteConfirmModal
              show={isDelete}
              onClose={handleCloseDeleteConfirm}
              onComfirm={handleDelete}
              deleteRecord={deleteData?.id}
              confirmMessage={
                  <>
                      <p>{"Do you really want to delete these record?"}</p>
                  </>
              }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                  {isCreate ? (
                    <>
                        <AppealDetail
                            onCancel={handleCloseCreate}
                            onSubmitSuccess  ={handleEdit}
                            id=""
                        />
                    </>
                ) : isEdit ? (
                    <>
                        <AppealDetailEditForm
                            onCancel={handleCloseEdit}
                            onSubmitSuccess  ={handleEdit}
                            id={editData?.id}
                            member_info_id={editData?.member_info_id}
                            subject={editData?.subject}
                            m_appeal_cat_id={editData?.m_appeal_cat_id}
                            create_by={editData?.create_by}
                            status={editData?.status}
                            create_date={editData?.create_date}
                        />
                    </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching placeholder="Filter : ชื่อผู้ใช้, อีเมล, กลุ่มผู้ใช้งาน"
                            onSearch={handleSearch}
                            defaultValue={keyword}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="สถานะ"
                          placeholder={"สถานะ"}
                          defaultValue={filterStatusTypeOptions.find(
                            (e) => e.value == allBannerStatus
                          )}
                          value={filterStatusTypeOptions.find(
                            (e) => e.value == recordStatus
                          )}
                          options={filterStatusTypeOptions}
                          onChange={loadPageOnStatusChange}
                        />
                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={dateStart ? new Date(dateStart) : null}
                          defaultEnd={dateEnd ? new Date(dateEnd) : null}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = [];
  for (const [idx, data] of datas.entries()) {
    let status;
    if (data.status === 1) {
      status = "รับแจ้ง";
    } else if (data.status === 2) {
      status = "ระหว่างดำเนินการ";
    } else if (data.status === 3) {
      status = "แก้ไขแล้ว";
    } else {
      status = "";
    }
      tableData.push({
        member_info_id: startNo + tableData.length,
        id: data.id,
        subject: data.subject,
        m_appeal_cat_id: data.rel_m_appeal_cat_id?.name,
        create_by: data.create_by,
        status: status,
        create_date: data.create_date,
        editDeleteButton: (
          <>
              <EditButton
                  onClick={() => {
                      onEdit(data);
                  }}
              />{" "}
              <DeleteButton
                  onClick={() => {
                      onDelete(data);
                  }}
              />
          </>
        ),
      });
  }
  return tableData;
};
