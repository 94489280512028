import axios from "axios"
import { getDefaultRequestHeaders } from "../utils/Authen"

export const getOnlineBooking = async ({
  page,
  pageSize,
  search,
  status,
  booking_type,
  dateStart,
  dateEnd,
}) => {
  try {
    let datefilter
    if (dateStart && dateEnd) {
      datefilter = "$btw:" + dateStart + " 00:00:00," + dateEnd + " 23:59:59"
    }
    let filterStatus
    if (status === "all") {
      filterStatus = null
    } else {
      filterStatus = status
    }
    let paramList = {}
    if (booking_type === 0) {
      paramList = {
        page,
        size: pageSize,
        "filter.status": filterStatus,
        search: search,
        "filter._relations":
          "rel_member_info_id,rel_pet_info_id,rel_m_heal_id,rel_doctor_info_id,rel_m_subdistrict_id,rel_chat_media_ids",
        "filter.booking_type": booking_type,
        "filter.schedule_date": datefilter,
        "filter.service_type": 0,
      }
    } else {
      paramList = {
        page,
        size: pageSize,
        "filter.status": filterStatus,
        search: search,
        "filter._relations":
          "rel_member_info_id,rel_pet_info_id,rel_m_heal_id,rel_doctor_info_id,rel_m_subdistrict_id,rel_van_info_id",
        "filter.booking_type": booking_type,
        "filter.schedule_date": datefilter,
        "filter.service_type": "$not:0",
      }
    }

    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/booking/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: paramList,
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getOnlineBookingById = async (bookingId) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/booking/" + bookingId,
      {
        headers: await getDefaultRequestHeaders(true),
        params: {},
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getMemberInfo = async ({}) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/member_info",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {},
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getMemberInfoById = async (customerID) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/member_info/" + customerID,
      {
        headers: await getDefaultRequestHeaders(true),
        params: {},
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getPetInfoById = async (customerID) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL +
        "/pet_info/datatable?filter.member_info_id=" +
        customerID +
        "&filter._ignore_rel=1",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {},
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getHeadInfo = async (customerID) => {
  try {
    const res = await axios.get(process.env.REACT_APP_API_URL + "/m_heal", {
      headers: await getDefaultRequestHeaders(true),
      params: {},
    })
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getOwnerInfo = async ({}) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/doctor_info",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {},
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getCarInfoById = async (vanType) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL +
        "/van_info/datatable?filter.van_type=" +
        vanType +
        "&filter._ignore_rel=1&page=1&limit=1000",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {},
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getSubDistrictInfo = async (SubDistrictID = "", params = {}) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/m_subdistrict/" + SubDistrictID,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getDistrictInfo = async (DistrictId = "", params = {}) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/m_district/" + DistrictId,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getProvinceInfo = async (ProvinceId = "", params = {}) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/m_province/" + ProvinceId,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}
export const getHospitalInfo = async () => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/hospital_info",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {},
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getHospitalInfoId = async (id) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL +
        "/address/datatable?filter.any_id=" +
        id +
        "&filter.user_type=2&page=1&filter._relations=rel_m_subdistrict_id",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {},
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const getMockOnlineBooking = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 4,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          caseid: "1",
          casetitle: "ชิโร่-ผ่าตัดด่วน",
          bookingdate: "20230101 13.01",
          scheduledate: "s20230101 13.01",
          status: "booking",
          owner: "70898 นพ.มาพน",
          createby: "@admin A",
        },
        {
          caseid: "2",
          casetitle: "ฉีดวัคซีน",
          bookingdate: "20230101 13.01",
          scheduledate: "s20230101 13.01",
          status: "complete",
          owner: "70898 นพ.มาพน",
          createby: "@admin A",
        },
        {
          caseid: "3",
          casetitle: "อาบน้ำ",
          bookingdate: "20230101 13.01",
          scheduledate: "s20230101 13.01",
          status: "cancel",
          owner: "70898 นพ.มาพน",
          createby: "@admin A",
        },
        {
          caseid: "4",
          casetitle: "ท้องเสีย",
          bookingdate: "20230101 13.01",
          scheduledate: "s20230101 13.01",
          status: "booking",
          owner: "70898 นพ.มาพน",
          createby: "@admin A",
        },
      ],
    },
    error: null,
  }
}

export const createOnlineBooking = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/booking",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    )
    return {
      success: true,
      data: res.data.data,
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}
export const editOnlineBooking = async ({ id, body }) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/booking/" + id,
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    )
    return {
      success: true,
      data: res.data.data,
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const createMockOnlineBooking = async ({ fullname }) => {
  return {
    success: true,
    data: {},
    error: null,
  }
}

export const getMockVaccineList = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 2,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          id: "1",
          vaccineList: "วัคซีนพิษสุนัขบ้า",
          vaccineDatetime: "2023-01-01 13.01",
          vaccineDoctor: "70898 นพ.มาพน",
        },
        {
          id: "2",
          vaccineList: "วัคซีนลิวคีเมีย",
          vaccineDatetime: "2023-01-01 13.01",
          vaccineDoctor: "70898 นพ.มาพน",
        },
      ],
    },
    error: null,
  }
}

export const getMockMedicineList = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 2,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          id: "1",
          meddicineList: "ยาบำรุงหัวใจ",
          meddicineAmount: "1",
          meddicineLocation: "ใช่",
          meddicineDoctor: "70898 นพ.มาพน",
        },
        {
          id: "2",
          meddicineList: "ยาแก้แพ้",
          meddicineAmount: "1",
          meddicineLocation: "ใช่",
          meddicineDoctor: "70898 นพ.มาพน",
        },
      ],
    },
    error: null,
  }
}

export const getMockBookingLogsChange = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 2,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          changeDatetime: "2023-01-01 13.01",
          ChangeType: "A",
          ChangeFrom: "หมอ1",
          ChangeTo: "70898 นพ.มาพน",
          remark: "TEST TEST TEST",
          UpdateBy: "Admin",
        },
        {
          changeDatetime: "2023-01-01 13.01",
          ChangeType: "A",
          ChangeFrom: "หมอ2",
          ChangeTo: "70800 นพ.ปิติ",
          remark: "TEST2",
          UpdateBy: "Admin2",
        },
      ],
    },
    error: null,
  }
}

export const getBookingLogsChange = async ({ page, pageSize, booking_id }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/track_change/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          size: pageSize,
          "filter.booking_id": booking_id,
        },
      }
    )
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const deleteBooking = async ({ id }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/booking/" + id,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    )
    return {
      success: true,
      data: res.data.data,
      error: null,
    }
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    }
  }
}

export const orderMedicine = async () => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/get_all_order_medicine",
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    //console.log(res.data);
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};
