import React from "react";
import * as yup from "yup";
import { emailInvalidMessage, emailRegExp } from "../../data/Config";
import { sendResetPasswordEmail } from "../../services/AuthServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { Link } from "react-router-dom";
import GenericButton from "../../components/Button/GenericButton";

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegExp, emailInvalidMessage)
    .required("ห้ามว่าง"),
});

export default function ForgotPasswordForm({ onSubmitSuccess = () => {} }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async (inputData) => {
    setIsLoading(true);
    const { success, error } = await sendResetPasswordEmail({
      body: {
        email: inputData?.email,
      },
    });
    if (success) {
      ShowMessageAndExpire(
        "Check your email",
        "Verification email sent successfully",
        "success"
      );
      onSubmitSuccess();
    } else {
      ShowMessageAndExpire(
        "Send Email Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
    setIsLoading(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="email"
              label="Please enter your email for password recovery"
              placeholder="อีเมล"
              defaultValue={null}
              error={errors.email}
              disabled={isLoading}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Link
            to={!isLoading && "/signin"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            Sign in
          </Link>
        </Row>
        <Row className="mb-3">
          <Col>
            <GenericButton type="submit" disabled={isLoading}>
              {"Submit"}
              {isLoading && (
                <>
                  &nbsp; &nbsp;
                  <Spinner
                    animation="border"
                    variant="info"
                    size="sm"
                    className="me-3"
                  />
                </>
              )}
            </GenericButton>
          </Col>
        </Row>
      </Form>
    </>
  );
}
