import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getRecord = async ({ page, pageSize, search,dateStart, dateEnd  }) => {
  try {
    let datefilter;
    if( dateStart && dateEnd ){
      datefilter ='$btw:'+dateStart+' 00:00:00,'+dateEnd+' 23:59:59';
    }
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/report/expense_transaction",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit: pageSize,
          search,
          'filter.action_datetime': datefilter
        },
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockExpenseTransactionDatatable = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 2,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          id: "1",
          transectionID: "EXP001",
          transectionDatetime: "2023-04-03 12:00:00",
          expenseType: 0,
          userName: "คุณอมรรัตน์ มั่นคง",
          remark: "",
          status: 1,
        },
        {
          id: "2",
          transectionID: "EXP002",
          transectionDatetime: "2023-04-03 12:00:00",
          expenseType: 1,
          userName: "คุณอาคม ปราบปราม",
          remark: "",
          status: 2,
        },
        {
          id: "3",
          transectionID: "EXP003",
          transectionDatetime: "2023-04-03 12:00:00",
          expenseType: 2,
          userName: "คุณศุภศักดิ์ ประทวน",
          remark: "",
          status: 0,
        },
        {
          id: "4",
          transectionID: "EXP004",
          transectionDatetime: "2023-04-03 12:00:00",
          expenseType: 3,
          userName: "คุณพิกร พรษา",
          remark: "",
          status: 1,
        },
      ],
    },
    error: null,
  };
};

export const editStatusExpenseTransaction = async ({ body }) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/report/expense_transaction/update_status",
      body,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data?.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};
