import React from "react";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { Col, Form, Row, Button, Modal } from "react-bootstrap";
import GenericButton from "../../components/Button/GenericButton";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { getCommonAPI } from "../../services/commonAPIServices";
import {
  PaymentChannelOptions,
  PaymentStatusOptions,
} from "../../data/SelectOptions";
import {
  PaymentProductOrderColumn,
  PaymentProductOrderNoDeleteColumn,
  ProductOrderDetailColumn,
  ProductOrderDetailNoEditColumn,
} from "../../data/Columns";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import { uploadImage } from "../../services/SettingServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import ReactTable from "../../components/ReactTable";
import DeleteButton from "../../components/Button/DeleteButton";
import AddPaymentPhotoModal from "../Modal/AddPaymentPhotoModal";
import {
  deletePaymentImage,
  getPaymentImage,
} from "../../services/ProductManagenentServices";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";

const schema = yup.object().shape({
  // customer_list: yup.string().required("รายการลูกค้าห้าวว่าง"),
  // fullname: yup.string().required("อีเมลห้ามว่าง"),
  // petname: yup.string().required("ชื่อสัตว์เลี้ยงห้ามว่าง"),
  // customer_telephone: yup.string().required("เบอร์โทรศัพท์ห้ามว่าง"),
  // customer_email: yup
  //   .string()
  //   .email("อีเมลไม่ถูกต้อง")
  //   .required("อีเมลห้ามว่าง"),
  // test: yup.string().required("test"),
});

export default function CreateExpenseSummaryForm({
  editData,
  paymentMethodReadOnly,
  showAddProduct,
}) {
  const {
    register,
    control,
    handleSubmit,
    onCreateSuccess,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [paymentTableData, setPaymentTableData] = React.useState([]);

  const [showPaymentModal, setShowPaymentModal] = React.useState(false);
  const [showPaymentImageViewModal, setShowPaymentImageViewModal] =
    React.useState(false);
  const [paymentImage, setPaymentImage] = React.useState();

  const [isDeletePayment, setIsDeletePayment] = React.useState(false);

  const [deleteData, setDeleteData] = React.useState();

  const paymentColumnsData = PaymentProductOrderColumn;
  const paymentNoDeleteColumnsData = PaymentProductOrderNoDeleteColumn;
  const productColumnsData = showAddProduct
    ? ProductOrderDetailColumn
    : ProductOrderDetailNoEditColumn;

  const [previewImage, setPreviewImage] = React.useState();
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const [paymentId, setpaymentId] = React.useState();
  const onSubmit = async (inputData) => {
    console.log(inputData);
    const billingPicFile = inputData.billingPic[0];

    if (billingPicFile) {
      let bodyFormData = new FormData();
      bodyFormData.append("image", billingPicFile, billingPicFile?.name);
      bodyFormData.append("res_model", "payment");
      bodyFormData.append("res_id", paymentId);
      bodyFormData.append("res_field", "slip");
      const { success, error } = await uploadImage({
        bodyFormData: bodyFormData,
      });
      if (success) {
        ShowMessageAndExpire("Data updated successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Update Logo Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    }
  };

  const loadcost = React.useCallback(async (payment_id) => {
    const { success, data, error } = await getCommonAPI({
      url_api: "/get_booking_summary_web",
      p_data: {
        payment_id: payment_id,
        booking_id: editData.id,
      },
    });
    if (success) {
      setValue("doctor_amount", data.records.doctor_amount, {
        shouldValidate: true,
      });
      setValue("ambulanceFee", data.records.van_amount, {
        shouldValidate: true,
      });
      setValue("doctorCarFee", data.records.ems_amount, {
        shouldValidate: true,
      });
      setValue("equipmentFee", data.records.van_equipment_amount, {
        shouldValidate: true,
      });
      setValue("vaccineFee", data.records.vaccine_amount, {
        shouldValidate: true,
      });
      setValue("ambulanceOtherFee", data.records.additional_van_amount, {
        shouldValidate: true,
      });
      setValue("medicineEquipmentFee", data.records.onsite_medicine_medical, {
        shouldValidate: true,
      });
    } else {
      console.log(error);
    }
  }, []);

  const handleAddPayment = React.useCallback(async () => {
    setShowPaymentModal(false);
    loadPaymentBooking();
  }, []);

  const handleOpenDeletePaymentConfirm = (data) => {
    setIsDeletePayment(true);
    setDeleteData(data);
  };

  const handleDeletePayment = React.useCallback(async (id) => {
    const { success, error } = await deletePaymentImage({
      pathParams: id,
    });
    if (success) {
      ShowMessageAndExpire("Data deleted successfully.", "", "success");
      setShowPaymentModal(false);
      setIsDeletePayment(false);
      loadPaymentBooking();
    } else {
      ShowMessageAndExpire(
        "Failed to delete data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, []);

  const loadPaymentImage = React.useCallback(
    async (paymentId) => {
      const { success, data, error } = await getPaymentImage({
        params: {
          payment_id: paymentId,
        },
      });
      if (success) {
        setPaymentTableData(
          preparePaymentImageForTable(
            data.records,
            handleOpenDeletePaymentConfirm
          )
        );
      } else {
        ShowMessageAndExpire(
          "Load Payment Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [setValue]
  );

  const preparePaymentImageForTable = React.useCallback((datas, onDelete) => {
    let paymentImages = [];
    for (const [idx, data] of datas.entries()) {
      paymentImages.push({
        id: idx + 1,
        item: (
          <img
            src={data.picture_path}
            alt="Preview"
            style={{
              width: "150px",
            }}
            onClick={() => {
              setShowPaymentImageViewModal(data.picture_path);
              setPaymentImage(data.picture_path);
            }}
          />
        ),
        deleteButton: (
          <DeleteButton
            onClick={() => {
              onDelete({ id: data.id });
            }}
          />
        ),
      });
    }
    return paymentImages;
  }, []);

  const [paymentDatetime, setPaymentDatetime] = React.useState();
  const loadPaymentBooking = React.useCallback(
    async (currentPage, pageSize) => {
      const { success, data, error } = await getCommonAPI({
        url_api: "/payment_booking/" + editData.id,
      });
      if (success) {
        
        if (data.records.length > 0) {
          setpaymentId(data.records.id);
          //setpaymentId(122);
          setValue("paymentAmount", data.records.amt, { shouldValidate: true });
          setValue("TransNo", data.records.trn_no, { shouldValidate: true });
          setValue("paymentDatetime", new Date(data.records.receipt_date), {
            shouldValidate: true,
          });
          setValue(
            "paymentStatus",
            PaymentStatusOptions.find(
              (option) => option.value === data.records.status
            ),
            { shouldValidate: true }
          );
          setValue(
            "channel",
            PaymentChannelOptions.find(
              (option) => option.value === data.records.payment_type
            ),
            { shouldValidate: true }
          );
          loadPaymentImage(data.records.id);
          loadcost(data.records.id);
        }
      } else {
        console.log(error);
      }
    },
    []
  );

  React.useEffect(() => {
    loadPaymentBooking();
  }, [loadPaymentBooking]);

  return (
    <>
      {showPaymentModal && (
        <AddPaymentPhotoModal
          show={showPaymentModal}
          setShow={() => {
            setShowPaymentModal();
          }}
          onSave={handleAddPayment}
          orderId={editData.id}
          paymentId={paymentId}
          Model="booking"
        />
      )}
      {isDeletePayment && (
        <DeleteConfirmModal
          show={isDeletePayment}
          onClose={() => {
            setIsDeletePayment(false);
            setDeleteData(null);
          }}
          onComfirm={handleDeletePayment}
          deleteRecord={deleteData?.id}
          confirmMessage={
            <>
              <p>{"Do you really want to delete these record?"}</p>
              <p>{`id = ${deleteData?.id}`}</p>
            </>
          }
        />
      )}
      {showPaymentImageViewModal && (
        <Modal
          show={showPaymentImageViewModal}
          onHide={() => {
            setShowPaymentImageViewModal(false);
            setPaymentImage(null);
          }}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img
              src={paymentImage}
              alt="Preview"
              style={{
                width: "450px",
              }}
            />
          </Modal.Body>
        </Modal>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={6} style={{ display: "flex" }}>
            <h4>สรุปค่าใช้จ่าย</h4>
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <GenericButton variant="success" type="submit" size="sm">
              บันทึกค่าใช้จ่าย
            </GenericButton>
          </Col>
        </Row>
        <hr />
        <Row className="mt-3">
          <Form.Group as={Row} className="mb-3" controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="paymentAmount"
              label="ยอดชำระ :"
              placeholder="ยอดชำระ"
              defaultValue=""
              type="text"
              variant=""
              error={errors.paymentAmount}
              viewFormat="inline"
            />
          </Form.Group>
        </Row>

        <Row className="mt-3">
          <Form.Group as={Row} className="mb-3" controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="TransNo"
              label="TransNo :"
              placeholder="TransNo"
              defaultValue=""
              type="text"
              variant=""
              viewFormat="inline"
              error={errors.TransNo}
            />
          </Form.Group>
        </Row>

        <Row className="mt-3">
          <Form.Group as={Row} className="mb-3" controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="paymentStatus"
              label="สถานะการชำระ :"
              placeholder=""
              //defaultValue={editData ? editData.status : null}
              options={PaymentStatusOptions}
              required
            />

            <GenericSelectInput
              control={control}
              name="channel"
              label="ช่องทางชำระเงิน :"
              placeholder=""
              //defaultValue={editData ? editData.status : null}
              options={PaymentChannelOptions}
              required
            />

            <GenericDateTimePickerInput2
              control={control}
              name="paymentDatetime"
              label="วันและเวลาที่ชำระ :"
              placeholder=""
              type="dateTime"
              required
            />
          </Form.Group>
        </Row>
        <Row>
          <Col>
            {/* {tranNo && ( */}
            {/* <Form.Group as={Row} className="mb-3" controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="billingPic"
              label="ใบเสร็จ"
              placeholder=""
              defaultValue={null}
              error={errors.billingPic}
              type="file"
              onChange={handleImageChange}
            />
          </Form.Group> */}
            {/* )} */}
            {paymentId ? 
              <>
                Payment Method :
                <Button
                  variant="success"
                  onClick={() => setShowPaymentModal(true)}
                  className="sm-3"
                >
                  {" "}
                  Upload
                </Button>
              </>
            :""}
          </Col>
        </Row>
        <Row className="mt-3">
          {paymentId && (
            <Row>
              <Col className="mx-3">
                {paymentMethodReadOnly ? (
                  <ReactTable
                    columns={paymentNoDeleteColumnsData}
                    data={paymentTableData}
                  />
                ) : (
                  <ReactTable
                    columns={paymentColumnsData}
                    data={paymentTableData}
                  />
                )}
              </Col>
            </Row>
          )}

          {/*           
          <Col xs={6} style={{ display: "flex" }}>
            อ้างอิงการชำระ :
          </Col>
        </Row>
        <Row className="mt-3" align="center">
          <Col align="center" style={{ backgroundColor: "#F5EFED" }}>
            {previewImage && "รูปภาพสลิปโอนเงิน"}
            {tranNo && "Transection no"}
          </Col>
        </Row>
        <Row className="mt-3" align="center">
          <Col align="center">
            {previewImage && (
              <img
                src={previewImage}
                alt="Preview"
                style={{ width: "300px" }}
              />
            )}
            {tranNo && tranNo}
          </Col> */}
        </Row>
        <Row className="mt-3">
          <h4>สรุปผลการรักษา</h4>
          <hr />
          <Form.Group as={Row} className="mb-3" controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="doctor_amount"
              label="ค่าปรึกษาแพทย์ :"
              placeholder=""
              defaultValue=""
              type="text"
              variant=""
              error={errors.DoctorFee}
              viewFormat="inline"
            />
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="ambulanceFee"
              label="ค่ารถพยาบาล :"
              placeholder=""
              defaultValue=""
              type="text"
              variant=""
              // error={errors.ambulanceFee}
              viewFormat="inline"
            />
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="doctorCarFee"
              label="ค่ารถแพทย์นอกสถานที่ :"
              placeholder=""
              defaultValue=""
              type="text"
              variant=""
              error={errors.doctorCarFee}
              viewFormat="inline"
            />
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="equipmentFee"
              label="อุปกรณ์แพทย์ :"
              placeholder=""
              defaultValue=""
              type="text"
              variant=""
              error={errors.equipmentFee}
              viewFormat="inline"
            />
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="vaccineFee"
              label="วัคซีน :"
              placeholder=""
              defaultValue=""
              type="text"
              variant=""
              error={errors.vaccineFee}
              viewFormat="inline"
            />
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="ambulanceOtherFee"
              label="ค่าใช้จ่ายรถพยาบาลเพิ่มเติม :"
              placeholder=""
              defaultValue=""
              type="text"
              variant=""
              error={errors.ambulanceOtherFee}
              viewFormat="inline"
            />
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="medicineEquipmentFee"
              label="ค่ายาและเวชภัณฑ์นอกสถานที่ :"
              placeholder=""
              defaultValue=""
              type="text"
              variant=""
              error={errors.medicineEquipmentFee}
              viewFormat="inline"
            />
          </Form.Group>
          <Form.Group as={Row} controlId="formBasicName">
            <GenericTextAreaInput
              register={register}
              name="adminRemark"
              label="หมายเหตุจากแอดมิน :"
              variant=""
              error={errors.adminRemark}
              viewFormat="inline"
            />
          </Form.Group>
        </Row>
      </form>
    </>
  );
}
