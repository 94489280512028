import React from "react";
import ReactDatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import { CaretDownFill } from "react-bootstrap-icons";
import { dateFormat, dateTimeFormat } from "../../data/Config";
import { Form, Col } from "react-bootstrap";

export default function ReactDateTimeRangePicker({
  selectedDate,
  setSelectedDate,
}) {
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <>
      <Form.Control onClick={onClick} value={value} className="reactDateTimeRangePicker"/>
    </>
  ));
  return (
    <div>
      <ReactDatePicker
        customInput={<ExampleCustomInput />}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        isClearable={true}
        dateFormat={dateFormat}
      />
    </div>
  );
}
