import React from "react";
import { userPermissionColumn } from "../data/Columns";
import {
  deleteAdminUser,
  getAdminUser,
  getRoleGroup,
} from "../services/UserServices";
import { Card, Col, Container, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import SelectFilter from "../components/SelectFilter";
import { filterUserStatusOptions } from "../data/SelectOptions";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import DeleteButton from "../components/Button/DeleteButton";
import { UserStatus } from "../data/Enumerator";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import CreateEditNewUserForm from "../features/Form/CreateEditNewUserForm";
import EditButton from "../components/Button/EditButton";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";

const allUserStatus = null;

export default function UserManagement() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [tableData, setTableData] = React.useState([]);
  const columnsData = userPermissionColumn;

  const [roleGroupOptonsData, setRoleGroupOptonsData] = React.useState();
  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();

  const loadUser = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, startDate, endDate) => {
      let success;
      let data;
      let error;
      let roleGroupOption = [];
      ({ success, data, error } = await getRoleGroup({
        params: {
          limit: 1000,
        },
      }));
      if (success) {
        for (const [idx, roleGroup] of data.records.entries()) {
          roleGroupOption.push({
            id: idx,
            label: roleGroup?.name,
            value: roleGroup?.id,
          });
        }
        setRoleGroupOptonsData(roleGroupOption);
      } else {
        ShowMessageAndExpire(
          "Failed to load data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
      let dateFilter = null;
      if (startDate && endDate) {
        dateFilter = `$btw:${startDate} 00:00:00, ${endDate} 23:59:59`;
      }
      ({ success, data, error } = await getAdminUser({
        pathParams: "datatable",
        params: {
          page: currentPage,
          limit: pageSize,
          search: keyword,
          "filter.status": userStatus,
          "filter.update_date": dateFilter,
          "filter._relations": "rel_role_group_id",
        },
      }));
      if (success) {
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            roleGroupOption,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        ShowMessageAndExpire(
          "Failed to load data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    []
  );

  React.useEffect(() => {
    loadUser(1, 10, allUserStatus);
  }, [loadUser]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadUser(page, pageSize, userStatus, keyword, startDate, endDate);
    },
    [loadUser, pageSize, userStatus, keyword, startDate, endDate]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadUser(1, pageSize, userStatus, keyword, startDate, endDate);
    },
    [loadUser, userStatus, keyword, startDate, endDate]
  );

  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadUser(1, pageSize, userStatus, keyword, startDate, endDate);
    },
    [loadUser, pageSize, keyword, startDate, endDate]
  );

  const openForm = () => {
    setIsOpenForm(true);
    setIsCreate(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setIsCreate(false);
    setEditData(null);
    setIsDelete(false);
    setDeleteData(null);
    setCurrentPage(1);
    setKeyword("");
    setStartDate(null);
    setStartDate(null);
    setUserStatus(allUserStatus);
    loadUser(1, pageSize, allUserStatus, "", null, null);
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadUser(1, pageSize, userStatus, keyword, startDate, endDate);
    },
    [loadUser, pageSize, userStatus, startDate, endDate]
  );

  const handleSelectedDate = React.useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate);
      setEndDate(endDate);
      setCurrentPage(1);
      await loadUser(1, pageSize, userStatus, keyword, startDate, endDate);
    },
    [loadUser, pageSize, userStatus, keyword]
  );

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setIsCreate(false);
    setEditData(data);
  };

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const { success, error } = await deleteAdminUser({
        pathParams: id,
      });
      if (success) {
        ShowMessageAndExpire("Data deleted successfully.", "", "success");
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        await loadUser(
          tempCurrentPage,
          pageSize,
          userStatus,
          keyword,
          startDate,
          endDate
        );
      } else {
        handleCloseDeleteConfirm();
        ShowMessageAndExpire(
          "Failed to delete data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [
      loadUser,
      total,
      currentPage,
      pageSize,
      userStatus,
      keyword,
      startDate,
      endDate,
    ]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              !isOpenForm
                ? "User Management"
                : isCreate
                ? "Create User"
                : "User Detail"
            }
            buttonEnable={!isOpenForm}
            buttonIcon="plus"
            buttonMessage="Create new records"
            onButtonOnClick={openForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`id = ${deleteData?.id}, ชื่อผู้ใช้= ${deleteData?.username}, กลุ่มผู้ใช้= ${deleteData?.role} `}
                </p>
              </>
            }
          />

          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenForm ? (
                  <>
                    <Row className="mt-3">
                      <CreateEditNewUserForm
                        isCreate={isCreate}
                        onCancel={refreshPage}
                        onSubmitSuccess={refreshPage}
                        firstnameDefaultValue={editData?.firstname}
                        lastnameDefaultValue={editData?.lastname}
                        emailDefaultValue={editData?.email}
                        telephoneDefaultValue={editData?.telephone}
                        userRoleDefaultValue={editData?.role}
                        userStatusDefaultValue={editData?.status}
                        id={editData?.id}
                        roleGroupOptionsData={roleGroupOptonsData}
                      />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Filter : ชื่อผู้ใช้, อีเมล, กลุ่มผู้ใช้งาน"
                          onSearch={handleSearch}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="สถานะ"
                          placeholder={"สถานะ"}
                          defaultValue={filterUserStatusOptions.find(
                            (e) => e.value === allUserStatus
                          )}
                          value={filterUserStatusOptions.find(
                            (e) => e.value === userStatus
                          )}
                          options={filterUserStatusOptions}
                          onChange={loadPageOnUserStatusChange}
                        />
                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={startDate ? new Date(startDate) : null}
                          defaultEnd={endDate ? new Date(endDate) : null}
                          placeholder="เลือกวันที่อัพเดท"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (
  datas,
  roleGroupOption,
  startNo,
  onEdit,
  onDelete
) => {
  let tableData = [];
  for (const [idx, data] of datas.entries()) {
    let tempStatus;
    if (data.status === UserStatus.Inactive) {
      tempStatus = "Inactive";
    } else if (data.status === UserStatus.Active) {
      tempStatus = "Active";
    } else if (data.status === UserStatus.Waiting) {
      tempStatus = "Waiting Verify";
    } else if (data.status === UserStatus.Expired) {
      tempStatus = "Expired";
    } else {
      tempStatus = "-";
    }
    let tempRole;
    for (const roleGroup of roleGroupOption) {
      if (data.role_group_id === roleGroup.value) {
        tempRole = roleGroup;
      }
    }
    const tempTableData = {
      no: startNo + idx,
      username: `${data.name} ${data.lname}`,
      email: data.email,
      role: tempRole?.label,
      status: tempStatus,
    };
    const buttonData = {
      id: data.id,
      no: startNo + idx,
      username: `${data.name} ${data.lname}`,
      firstname: data.name,
      lastname: data.lname,
      email: data.email,
      telephone: data.phone,
      role: tempRole?.value,
      status: data.status,
    };
    tableData.push({
      ...tempTableData,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(buttonData);
            }}
          />
          <DeleteButton
            onClick={() => {
              onDelete(buttonData);
            }}
          />
        </>
      ),
    });
  }
  return tableData;
};
