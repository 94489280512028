import React from "react"
import { useState } from "react"
import CancelButton from "../components/Button/CancelButton"
import { Form, Row, Col, Container, Image } from "react-bootstrap"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import GenericTextInput from "../components/Input/GenericTextInput"
import RadioButton from "../components/Button/RadioButton"
import GenericTextAreaInput from "../components/Input/GenericTextAreaInput"
import CreateButton from "../components/Button/CreateButton"
import GenericDateTimePickerInput from "../components/Input/GenericDateTimePickerInput"
import {
  getMockVaccineList,
  getMockMedicineList,
} from "../services/OnlineBookingServices"
import { createMockOnlineBooking } from "../services/OnlineBookingServices"
import GenericSelectInput from "../components/Input/GenericSelectInput"
import {
  CustomerTypeRadio,
  ServiceTypeRadio,
  CaseStatusOptions,
  OwnerOptions,
} from "../data/SelectOptions"
import ReactTable from "../components/ReactTable"
import { listVaccineColum, listMeddicineColum } from "../data/Columns"
import GenericButton from "../components/Button/GenericButton"
import GenericRadioInput from "../components/Input/GenericRadioInput"
import CreateNewOnlineBookingForm from "../features/Form/CreateNewOnlineBookingForm"
import CreateExpenseSummaryForm from "../features/Form/CreateExpenseSummaryForm"
import { getCommonAPI } from "../services/commonAPIServices"

const allUserStatus = "all"

export default function EditOnlineBookingDetail({
  onCancel,
  onCreateSuccess,
  editData,
}) {
  const [keyTab, setKeyTab] = useState("General")
  const [tableDataVaccine, setTableDataVaccine] = React.useState([])
  const [tableDataMeddicine, setTableDataMeddicine] = React.useState([])

  const columnsDataListVaccine = listVaccineColum
  const columnsDataListMeddicine = listMeddicineColum

  const loadMeddicine = React.useCallback(async (currentPage, pageSize) => {
    const { success, data, error } = await getCommonAPI({
      url_api: "/get_order_medicine",
      p_data: {
        booking_id: editData.id,
      },
    })
    if (success && data.records) {
      setTableDataMeddicine(prepareMeddicineList(data.records, editData))
    } else {
      console.log(error)
    }
  }, [])
  const prepareMeddicineList = (datas, editData) => {
    let lists = []
    for (const data of datas) {
      let ListData = {
        id: data.no,
        meddicineList: data.product_name,
        meddicineAmount: data.dosage,
        meddicineRecommend: data.med_recommend,
        meddicineDoctor: data.doctor_name,
        recommend: data.recommend,
      }
      lists.push(ListData)
    }
    return lists
  }

  const loadVaccine = React.useCallback(async (currentPage, pageSize) => {
    const { success, data, error } = await getCommonAPI({
      url_api: "/pet_vaccine",
      p_data: {
        page: currentPage,
        limit: pageSize,
        "filter.booking_id": editData.id,
        "filter.pet_info_id": editData.pet_info_id,
      },
    })
    if (success) {
      setTableDataVaccine(prepareVaccineList(data.records, editData))
    } else {
      console.log(error)
    }
  }, [])
  const prepareVaccineList = (datas, editData) => {
    let doctor = editData?.rel_doctor_info_id.name
    let lists = []
    for (const data of datas) {
      let ListData = {
        id: data.id,
        vaccineList: data.vaccine,
        vaccineDatetime: data.vaccine_date,
        vaccineDoctor: doctor,
      }
      lists.push(ListData)
    }
    return lists
  }

  const [note, setNote] = React.useState()
  const [noteDate, setNoteDate] = React.useState()
  const loadPetHeal = React.useCallback(async (currentPage, pageSize) => {
    const { success, data, error } = await getCommonAPI({
      url_api: "/pet_heal/datatable",
      p_data: {
        page: currentPage,
        limit: pageSize,
        "filter.booking_id": editData.id,
      },
    })
    if (
      success &&
      data.records &&
      data.records.length > 0 &&
      data.records[0].note
    ) {
      setNote(data.records[0]?.note)
      setNoteDate(data.records[0]?.note_date)
    } else {
      console.log(error)
    }
  }, [])
  React.useEffect(() => {
    loadVaccine()
    loadMeddicine()
    loadPetHeal()
  }, [loadVaccine, loadMeddicine, loadPetHeal])

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={keyTab}
        onSelect={(k) => setKeyTab(k)}
        className="mb-3"
      >
        <Tab eventKey="General" title="General">
          <CreateNewOnlineBookingForm
            editData={editData}
            onCancel={onCancel}
            onCreateSuccess={onCreateSuccess}
          />
        </Tab>
        <Tab eventKey="treatment" title="สรุปผลการรักษา">
          <h4>สรุปผลการรักษา </h4>
          <hr />
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextAreaInput
                name="treatmentDetail"
                label={
                  noteDate ? "สรุป (บันทึกเมื่อ " + noteDate + ")" : "สรุป"
                }
                variant=""
                defaultValue={note}
                disabled
              />
            </Form.Group>
          </Row>
          <h4>การทำวัคซีน</h4>
          <hr />
          <Row className="mb-3">
            <ReactTable
              columns={columnsDataListVaccine}
              data={tableDataVaccine}
            />
          </Row>
        </Tab>
        <Tab eventKey="listMedicine" title="สั่งยา">
          <h4>รายการสั่งยาที่เบิก</h4>
          <hr />
          <Row className="mb-3">
            <ReactTable
              columns={columnsDataListMeddicine}
              data={tableDataMeddicine}
            />
          </Row>
        </Tab>
        {/* <Tab eventKey="costSummary" title="สรุปค่าใช้จ่าย">
          <CreateExpenseSummaryForm 
           editData={editData}
          />
        </Tab> */}
      </Tabs>
    </>
  )
}
