import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Searching from "../../components/Searching";
import GenericButton from "../../components/Button/GenericButton";
import ReactTable from "../../components/ReactTable";
import { membersShippings } from "../../data/Columns";
import { getMockShipping, getShipping } from "../../services/MemberServices";
import EditButton from "../../components/Button/EditButton";
import DeleteButton from "../../components/Button/DeleteButton";
import GenericPagination from "../../components/GenericPagination";
import {
  deleteCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices";
import AddShippingSetModal from "../Modal/AddShippingSetModal";
import EditShippingSetModal from "../Modal/EditShippingSetModal";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";
import setPlaceholder from "../../utils/Help";

const allUserStatus = "all";
const schema = yup.object().shape({
  firstname: yup.string().required("ชื่อห้ามว่าง"),
  lastname: yup.string().required("นามสกุลห้ามว่าง"),
  email: yup.string().email("อีเมลไม่ถูกต้อง").required("อีเมลห้ามว่าง"),
  telephone: yup.string().required("เบอร์โทรศัพท์ห้ามว่าง"),
});

export default function MemberEditShippingForm({
  onCancel,
  onSubmitSuccess,
  editData,
}) {
  const [total, setTotal] = React.useState(1);
  const [tableDataShippings, setTableDataShippings] = React.useState([]);
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [editDataModal, setEditDataModal] = React.useState([]);
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const [keyword, setKeyword] = React.useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalEdit, setShowModalEdit] = React.useState(false);
  const columnsDataShippings = membersShippings;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loadMemberShipp = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword) => {
      const page = currentPage;
      const limit = pageSize;
      const search = keyword;
      const url_api = "/address/datatable";
      // const search = keyword;
      const p_data = {
        "filter.any_id": editData.id,
        "filter._relations": "rel_m_subdistrict_id",
        "filter.user_type": 0,
        status: userStatus,
        page,
        limit,
        search,
      };
      const { success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      });
      if (success) {
        console.log("loadMemberShipp: ", data.records);
        setTotal(data.total);
        setTableDataShippings(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        console.log(error);
      }
    },
    []
  );

  React.useEffect(() => {
    loadMemberShipp(1, 10, allUserStatus);
  }, [loadMemberShipp]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadMemberShipp(page, pageSize, userStatus, keyword);
    },
    [loadMemberShipp, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadMemberShipp(1, pageSize, userStatus, keyword);
    },
    [loadMemberShipp, userStatus, keyword]
  );

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadMemberShipp(1, pageSize, userStatus, keyword);
    },
    [loadMemberShipp, pageSize, userStatus]
  );

  const handleOpenEditForm = (data) => {
    setShowModalEdit(true);
    setEditDataModal(data);
  };

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleAddModalSet = React.useCallback(() => {
    setShowModal(false);
    setShowModalEdit(false);
    loadMemberShipp(1, 10);
  }, [loadMemberShipp]);

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const url_api = "/address/" + id;
      const { success, data, error } = await deleteCommonAPI({
        url_api,
      });
      if (success) {
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadMemberShipp(tempCurrentPage, pageSize, userStatus, keyword);
      } else {
        console.log(error);
      }
    },
    [loadMemberShipp, total, currentPage, pageSize, userStatus, keyword]
  );

  return (
    <>
      {showModal && (
        <AddShippingSetModal
          show={showModal}
          setShow={() => {
            setShowModal();
          }}
          onSave={handleAddModalSet}
          editData={editData}
        />
      )}
      {showModalEdit && (
        <EditShippingSetModal
          show={showModalEdit}
          setShow={() => {
            setShowModalEdit();
          }}
          onSave={handleAddModalSet}
          editData={editDataModal}
        />
      )}
      <DeleteConfirmModal
        show={isDelete}
        onClose={handleCloseDeleteConfirm}
        onComfirm={handleDelete}
        deleteRecord={deleteData?.id}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these record?"}</p>
            <p>
              {`ShippingAddress ID = ${deleteData?.id}, ชื่อ = ${deleteData?.rname}`}
            </p>
          </>
        }
      />
      {/* Tab content for Shipping */}
      <Row className="mt-3">
        <Col xs={6} style={{ display: "flex" }}>
          <Searching
            placeholder={setPlaceholder(["ชื่อ-นามสกุล ผู้รับ"])}
            onSearch={handleSearch}
            defaultValue={keyword}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="success"
            onClick={() => setShowModal(true)}
            className="ms-3"
          >
            {" "}
            New Address
          </Button>
        </Col>
      </Row>
      <Container fluid style={{ minHeight: "30rem" }}>
        <Row className="mt-3">
          <Col>
            <ReactTable
              columns={columnsDataShippings}
              data={tableDataShippings}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <GenericPagination
              totalPage={Math.ceil(total / pageSize)}
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={loadPageOnPageChange}
              onPageSizeChange={loadPageOnPageSizeChange}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableDataShippings = [];
  for (var i = 0; i < datas.length; i++) {
    switch (datas[i].default) {
      case 0:
        datas[i].default = <>ที่อยู่รอง</>;
        break;
      case 1:
        datas[i].default = <>ที่อยู่หลัก</>;
        break;
    }
  }
  for (const data of datas) {
    tableDataShippings.push({
      no: startNo + tableDataShippings.length,
      name_surename: data.rname,
      phone: data.phone,
      address: `${data.addressno} ${data.street} ${data.rel_m_subdistrict_id?.name} ${data.rel_m_subdistrict_id?.postcode}`,
      is_promary: data.default,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(data);
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(data);
            }}
          />
        </>
      ),
    });
  }
  return tableDataShippings;
};
