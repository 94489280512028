import React from "react";
import { Button } from "react-bootstrap";

export default function ChangeButton(props) {
  return (
    <Button {...props}
      variant="danger"
      active
      style={{ color: "white", marginLeft: "10px" }}
    >
      Change
    </Button>
  );
}
