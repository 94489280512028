import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";


export const getMockHospitalInfo = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 14,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          no: "1",
          hospitalName: "Hospital 1",
          subdistrict: "Bang Kruai",
          status: "Draft",
        },
        {
          no: "2",
          hospitalName: "Hospital 1",
          subdistrict: "Bang Kruai",
          status: "Draft",
        },
      ],
    },
    error: null,
  };
};

export const editMockHospital = async ({ id }) => {
  return {
    success: true,
    data: { id },
    error: null,
  };
};

export const deleteMockHospital = async ({ id }) => {
  return {
    success: true,
    data: { id: id },
    error: null,
  };
};

export const uploadHospitalInfoImage = async ({ id, imageFile }) => {
  // console.log("uploadImage",bodyFormData)
   try {
    let bodyFormData = new FormData();
    bodyFormData.append("image", imageFile, imageFile?.name);
    bodyFormData.append("res_model", "hospital_info");
    bodyFormData.append("res_id", id);
    bodyFormData.append("res_field", "picture");

     const res = await axios.post(
       process.env.REACT_APP_API_URL + "/upload/image",
       bodyFormData,
       {
         headers: await getDefaultRequestHeaders(true),
       }
     );
     return {
       success: true,
       data: res.data?.data,
       error: null,
     };
   } catch (err) {
     return {
       success: false,
       data: null,
       error: err.response?.data?.error || err,
     };
   }
 };

 export const deleteHospitalInfoImage = async ({ id }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/upload/image",
      {
        headers: await getDefaultRequestHeaders(true),
        data: {
          res_model: "hospital_info",
          res_id: id,
          res_field: "picture",
        }
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};


