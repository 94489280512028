import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericDateTimePickerInput from "../../components/Input/GenericDateTimePickerInput";
import SaveButton from "../../components/Button/SaveButton";
import CancelButton from "../../components/Button/CancelButton";
import * as yup from "yup";
import {
  editMockUserMember,
  editUserMember,
} from "../../services/MemberServices";
import { editMockUserDoctor } from "../../services/DoctorServices";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import {
  DoctorOnsiteRadio,
  DoctorStatusRadio,
  userStatusOptions,
  userStatusOptionsAct,
} from "../../data/SelectOptions";
import { editMockPets } from "../../services/PetServices";
import { deleteHospitalInfoImage, editMockHospital, uploadHospitalInfoImage } from "../../services/HospitalServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import {
  createCommonAPI,
  editCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices";

import DeleteButton from "../../components/Button/DeleteButton";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";

const schema = yup.object().shape({
  hospital_code: yup.string().required("ห้ามว่าง"),
  hospital_name: yup.string().required("ห้ามว่าง"),
  status: yup.object().required("ห้ามว่าง"),
  number: yup.string().required("ห้ามว่าง"),
  street: yup.string().required("ห้ามว่าง"),
  province: yup.object().required("ห้ามว่าง"),
  district: yup.object().required("ห้ามว่าง"),
  sub_district: yup.object().required("ห้ามว่าง"),
  post_code: yup.string().required("ห้ามว่าง"),
  phone: yup.string().required("ห้ามว่าง"),
  email: yup.string().email("อีเมลไม่ถูกต้อง").required("อีเมลห้ามว่าง"),
  line: yup.string().required("ห้ามว่าง"),
  contact_name: yup.string().default('').nullable(),
  contact_phone: yup.string().default('').nullable(),
  remark: yup.string().default('').nullable()
});

export default function HospitalDetailEditForm({
  onCancel,
  onSubmitSuccess,
  editData,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log("editData: ", editData);

  const hospitalId = editData?.id;
  const [previewImage, setPreviewImage] = React.useState(editData?.picture);
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const [isDirtyData, setisDirtyData] = React.useState(false);

  const [provinceList, setProvinceList] = React.useState([]);
  const [districtList, setDistrictList] = React.useState([]);
  const [subdistrict, setSubdistrict] = React.useState([]);

  const loadhospitalAddress = React.useCallback(async () => {
    let url_api = "/address/datatable";
    let p_data = {
      "filter.any_id": editData.id,
      "filter.user_type": 2,
      "filter._relations": "rel_m_subdistrict_id",
    };
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      // console.log("address data", data.records[0]);
      setValue("address", data.records[0]?.addressno);
      setValue("street", data.records[0]?.street);
      setValue("m_province_id", data.records[0]?.m_province_id);
      setValue("m_district_id", data.records[0]?.m_district_id);
      setValue("m_subdistrict_id", data.records[0]?.m_subdistrict_id);
      setValue("address_id", data.records[0]?.id);
      provinceInfoList();
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const provinceInfoList = React.useCallback(async () => {
    let url_api = "/m_province";
    let p_data = {};
    const { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setProvinceList(prepareProvinceList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareProvinceList = (datas) => {
    let listsProvince = [];
    for (const data of datas) {
      const id = data.id;
      const name = data.name;
      const province = {
        id: id,
        label: name,
        value: {
          id: id,
          name: name,
        },
      };
      listsProvince.push(province);
      if (data.id === getValues("m_province_id")) {
        setValue("province", province, { shouldValidate: true });
        districtInfoList(getValues("m_province_id"));
      }
    }

    return listsProvince;
  };

  const districtInfoList = React.useCallback(async (provinceId) => {
    let url_api = "/m_district";
    let p_data = {
      "filter.m_province_id": provinceId,
    };
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setDistrictList(prepareDistrictList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareDistrictList = (datas) => {
    let listsDistrict = [];
    if (Array.isArray(datas)) {
      for (const data of datas) {
        const id = data.id;
        const name = data.name;
        const province = data.m_province_id;
        const district = {
          id: id,
          label: name,
          value: {
            id: id,
            name: name,
            province: province,
          },
        };
        listsDistrict.push(district);
        if (data.id === getValues("m_district_id")) {
          setValue("district", district, { shouldValidate: true });
          loadSubdistrict(getValues("m_district_id"));
        }
      }
    } else {
      console.log("datas is not iterable.");
    }

    return listsDistrict;
  };

  const loadSubdistrict = React.useCallback(async (districtId) => {
    let url_api = "/m_subdistrict";
    let p_data = {
      "filter.m_district_id": districtId,
    };
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setSubdistrict(prepareSubdistrict(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareSubdistrict = (datas) => {
    let listSubdistrict = [];
    let m_subdistrict_id = getValues("m_subdistrict_id");
    for (const data of datas) {
      const id = data.id;
      const name = data.name;
      const postcode = data.postcode;
      const district = data.m_district_id;
      let subdistrict = {
        id: id,
        label: name,
        value: {
          id: id,
          name: name,
          postcode: postcode,
          district: district,
        },
      };
      listSubdistrict.push(subdistrict);
      if (data.id === m_subdistrict_id) {
        setValue("sub_district", subdistrict, { shouldValidate: true });
        setValue("post_code", subdistrict.value.postcode, {
          shouldValidate: true,
        });
      }
    }
    return listSubdistrict;
  };

  const handleChangeProvince = (e) => {
    if (e?.value) {
      districtInfoList(e.value.id);
      setValue("district", null);
      setSubdistrict([]);
      setValue("sub_district", null);
      setValue("post_code", null);
    } else {
      setDistrictList([]);
      setValue("district", null);
      setSubdistrict([]);
      setValue("sub_district", null);
      setValue("post_code", null);
    }
  };

  const handleChangeDisList = (e) => {
    if (e?.value) {
      loadSubdistrict(e.value.id);
      setSubdistrict([]);
      setValue("sub_district", null);
      setValue("post_code", null);
    } else {
      setSubdistrict([]);
      setValue("sub_district", null);
      setValue("post_code", null);
    }
  };

  const handleChangeSubDisList = (e) => {
    if (e?.value) {
      setValue("post_code", e.value?.postcode, { shouldValidate: true });
    } else {
      setValue("post_code", null);
    }
  };

  const onSubmit = async (inputData) => {
    let address_id = getValues("address_id");
    let url_api = "/hospital_info/" + editData.id;
    let p_data = {
      code: inputData.hospital_code,
      name: inputData.hospital_name,
      status: inputData.status?.id,
      phone: inputData.phone,
      email: inputData.email,
      acc_line: inputData.line,
      partner: 0,
      contact_name: inputData.contact_name,
      remark: inputData.remark,
      contact_phone: inputData.contact_phone
    };
    let { success, data, error } = await editCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      // add image to db
      if (inputData.image[0] && data.id) {
        let errorMessage = await submitImage(inputData.image[0], data.id);
        if (!errorMessage) {
          //ShowMessageAndExpire("Photo updated successfully.", "", "success");
        } else {
          ShowMessageAndExpire(
            "Update Photo Failed",
            JSON.stringify(errorMessage?.message),
            "error"
          );
        }
      }
      if (address_id) {
        url_api = "/address/" + address_id;
        p_data = {
          addressno: inputData.number,
          street: inputData.street,
          phone: inputData.phone,
          m_subdistrict_id: inputData.sub_district?.id,
          postcode_id: inputData.post_code,
          any_id: editData.id,
          address_type: 0,
          default: 1,
        };
        ({ success, data, error } = await editCommonAPI({
          url_api,
          p_data,
        }));
        if (success) {
          console.log("edit address success");
          ShowMessageAndExpire("Data updated successfully.", "", "success");
        } else {
          console.log(error);
          var errorMessages = [];
          if (typeof error.message === "string") {
            errorMessages.push(error.message);
          } else {
            for (var propertyName in error.message) {
              var errorMessage = error.message[propertyName];
              errorMessages.push(errorMessage);
            }
          }
          var concatenatedErrors = errorMessages.join("\n");

          ShowMessageAndExpire(
            "Failed to update data.",
            concatenatedErrors,
            "error"
          );
        }
      } else {
        url_api = "/address";
        p_data = {
          addressno: inputData.number,
          street: inputData.street,
          phone: inputData.phone,
          m_subdistrict_id: inputData.sub_district?.id,
          postcode_id: inputData.post_code,
          user_type: 2,
          any_id: editData.id,
          address_type: 0,
          default: 1,
        };
        ({ success, data, error } = await createCommonAPI({
          url_api,
          p_data,
        }));
        if (success) {
          console.log("add address success");
        } else {
          console.log(error);
        }
      }
      onSubmitSuccess();
    } else {
      console.log(error);
      var errorMessages = [];
      if (typeof error.message === "string") {
        errorMessages.push(error.message);
      } else {
        for (var propertyName in error.message) {
          var errorMessage = error.message[propertyName];
          errorMessages.push(errorMessage);
        }
      }
      var concatenatedErrors = errorMessages.join("\n");

      ShowMessageAndExpire("Failed to update data.", concatenatedErrors, "error");
    }
  };

  React.useEffect(() => {
    loadhospitalAddress();
  }, [loadhospitalAddress]);

  const handleOpenDeleteConfirm = () => {
    setIsDelete(true);
    setDeleteData(hospitalId);
  };

  const handleImageChange = async (event) => {
    console.log("handleImageChange");
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    await reader.readAsDataURL(file);
    // if (hospitalId && file) {
    //   let errorMessage = await submitImage(file, hospitalId);
    //   if (!errorMessage) {
    //     ShowMessageAndExpire("Photo updated successfully.", "", "success");
    //     setisDirtyData(true);
    //   } else {
    //     ShowMessageAndExpire(
    //       "Update Photo Failed",
    //       JSON.stringify(errorMessage?.message),
    //       "error"
    //     );
    //   }
    // }
  };

  const submitImage = async (imageFile, id) => {
    const { success, error } = await uploadHospitalInfoImage({
      id,
      imageFile
    });
    if (success) {
      return;
    } else {
      return error;
    }
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (dataId) => {
      const { success, data, error } = await deleteHospitalInfoImage({ id: dataId });
      if (success) {
        handleCloseDeleteConfirm();
        setPreviewImage();
        setisDirtyData(true);
      } else {
        handleCloseDeleteConfirm();
        ShowMessageAndExpire(
          "Delete Photo Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    []
  );


  const handleOnCancel = () => {
    onCancel(isDirtyData);
  }

  return (
    <>
      <DeleteConfirmModal
        show={isDelete}
        onClose={handleCloseDeleteConfirm}
        onComfirm={handleDelete}
        deleteRecord={hospitalId}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these image?"}</p>
            <p>
              {`ID ${hospitalId}`}
            </p>
          </>
        }
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4>ข้อมูลทั่วไป</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="hospital_code"
              label="รหัสโรงพยาบาล"
              placeholder="รหัสโรงพยาบาล"
              defaultValue={editData ? editData?.code : null}
              required
              error={errors.hospital_code}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="hospital_name"
              label="ชื่อโรงพยาบาล"
              placeholder="ชื่อโรงพยาบาล"
              defaultValue={editData ? editData?.name : null}
              required
              error={errors.hospital_name}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="status"
              label="สถานะ"
              placeholder="สถานะ"
              defaultValue={editData ? editData?.status : null}
              required
              options={userStatusOptionsAct}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="image"
              label="รูปภาพ"
              placeholder="รูปภาพ"
              defaultValue={null}
              type="file"
              error={errors.image}
              onChange={handleImageChange}
            />
            {previewImage
              && (
                <>
                  <img src={previewImage} alt="Preview" style={{ marginTop: '10px', width: '400px' }} />
                  {editData?.picture && <DeleteButton style={{ marginLeft: '20px' }} onClick={handleOpenDeleteConfirm} />}
                </>
              )
            }
          </Form.Group>
          <Col>

          </Col>
        </Row>
        <h4>ข้อมูลที่อยู่</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="number"
              label="เลขที่"
              placeholder="เลขที่"
              defaultValue={editData ? editData?.addressno : null}
              required
              error={errors.number}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="street"
              label="ถนน"
              placeholder="ถนน"
              defaultValue={editData ? editData?.street : null}
              required
              error={errors.street}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="province"
              label="จังหวัด"
              placeholder="จังหวัด"
              options={provinceList}
              required
              onChange={handleChangeProvince}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="district"
              label="อำเภอ / เขต"
              placeholder="อำเภอ / เขต"
              options={districtList}
              required
              onChange={handleChangeDisList}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="sub_district"
              label="ตำบล / แขวง"
              placeholder="ตำบล / แขวง"
              options={subdistrict}
              required
              onChange={handleChangeSubDisList}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="post_code"
              label="รหัสไปรษณีย์"
              placeholder="รหัสไปรษณีย์"
              defaultValue=""
              required
              error={errors.post_code}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <h4>ข้อมูลติดต่อ</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="email"
              label="อีเมล"
              placeholder="อีเมล"
              defaultValue={editData ? editData?.email : null}
              required
              error={errors.email}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="phone"
              label="เบอร์ติดต่อ"
              placeholder="เบอร์ติดต่อ"
              defaultValue={editData ? editData?.phone : null}
              required
              error={errors.phone}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="line"
              label="Line"
              placeholder="Line"
              defaultValue={editData ? editData?.acc_line : null}
              required
              error={errors.line}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="contact_name"
              label="ชื่อผู้ติดต่อ"
              placeholder="ชื่อผู้ติดต่อ"
              defaultValue={editData ? editData?.contact_name : null}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="contact_phone"
              label="เบอร์ผู้ติดต่อ"
              placeholder="เบอร์ผู้ติดต่อ"
              defaultValue={editData ? editData?.contact_phone : null}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="remark"
              label="หมายเหตุ"
              placeholder="หมายเหตุ"
              defaultValue={editData ? editData?.remark : null}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
