import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import Searching from "../../components/Searching";
import GenericButton from "../../components/Button/GenericButton";
import ReactTable from "../../components/ReactTable";
import { membersBooking } from "../../data/Columns";
import { getBooking, getMockBooking } from "../../services/MemberServices";
import GenericPagination from "../../components/GenericPagination";
import {
  CaseStatusOptions,
  filterUserStatusOptions,
} from "../../data/SelectOptions";
import SelectFilter from "../../components/SelectFilter";
import ReactDateTimePicker from "../../components/Input/ReactDateTimePicker";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import EditButton from "../../components/Button/EditButton";
import {
  deleteCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices";
import setPlaceholder from "../../utils/Help";
import CreateNewOnlineBookingForm from "./CreateNewOnlineBookingForm";
import DeleteButton from "../../components/Button/DeleteButton";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";
import EditOnsiteBookingDetail from "../../pages/EditOnsiteBookingDetail";

const allUserStatus = "all";
const schema = yup.object().shape({
  firstname: yup.string().required("ชื่อห้ามว่าง"),
  lastname: yup.string().required("นามสกุลห้ามว่าง"),
  email: yup.string().email("อีเมลไม่ถูกต้อง").required("อีเมลห้ามว่าง"),
  telephone: yup.string().required("เบอร์โทรศัพท์ห้ามว่าง"),
});

export default function MemberEditBookingForm({ memberId }) {
  const [total, setTotal] = React.useState(1);
  const [tableDataBooking, setTableDataBooking] = React.useState([]);
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const [keyword, setKeyword] = React.useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedDate, setSelectedDate] = React.useState();
  const [isOpenCraeateOnsiteForm, setIsOpenCraeateOnsiteForm] =
    React.useState(false);
  const [isOpenCraeateOnlineForm, setIsOpenCraeateOnlineForm] =
    React.useState(false);
  const columnsDataBooking = membersBooking;

  const filterStatus = [{ label: "All", value: "all" }, ...CaseStatusOptions];

  const loadMemberBooking = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, selectedDate) => {
      let filterDate;
      if (selectedDate) {
        filterDate = `$ilike:${selectedDate.toLocaleDateString("sv")}`;
      } else {
        filterDate = null;
      }
      if (userStatus === "all") {
        userStatus = null;
      }
      const page = currentPage;
      const limit = pageSize;
      const search = keyword;
      const url_api = "/booking/datatable";
      const p_data = {
        "filter.member_info_id": memberId,
        "filter.status": userStatus,
        // "filter._relations": "rel_m_heal_id,rel_doctor_info_id",
        "filter._relations":
          "rel_member_info_id,rel_pet_info_id,rel_m_heal_id,rel_doctor_info_id,rel_m_subdistrict_id,rel_chat_media_ids",
        "filter.schedule_date": filterDate,
        page,
        search,
        limit,
      };
      const { success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      });
      if (success) {
        setTotal(data.total);
        setTableDataBooking(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        // console.log(error);
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  React.useEffect(() => {
    loadMemberBooking(1, 10, allUserStatus, "");
  }, [loadMemberBooking]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);

      await loadMemberBooking(page, pageSize, userStatus, keyword);
    },
    [loadMemberBooking, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadMemberBooking(1, pageSize, userStatus, keyword);
    },
    [loadMemberBooking, userStatus, keyword]
  );

  const loadPageOnDatetimeChange = React.useCallback(
    async (datetime) => {
      setCurrentPage(1);
      setSelectedDate(datetime);
      await loadMemberBooking(1, pageSize, userStatus, keyword, datetime);
    },
    [loadMemberBooking, pageSize, keyword]
  );

  const loadPageOnStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadMemberBooking(1, pageSize, userStatus, keyword, selectedDate);
    },
    [loadMemberBooking, pageSize, keyword, selectedDate]
  );

  const refreshPage = () => {
    setIsOpenForm(false);
    setCurrentPage(1);
    setKeyword("");
    setUserStatus(allUserStatus);
    loadMemberBooking(1, pageSize, allUserStatus, "");
  };

  const openCreateOnsiteForm = () => {
    setIsOpenCraeateOnsiteForm(true);
  };

  const openCreateOnlineForm = () => {
    setIsOpenCraeateOnlineForm(true);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const url_api = "/booking/" + id;
      const { success, data, error } = await deleteCommonAPI({
        url_api,
      });
      if (success) {
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadMemberBooking(tempCurrentPage, pageSize, userStatus, keyword);
      } else {
        console.log(error);
      }
    },
    [loadMemberBooking, total, currentPage, pageSize, userStatus, keyword]
  );

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadMemberBooking(1, pageSize, userStatus, keyword);
    },
    [loadMemberBooking, pageSize, userStatus]
  );

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleEdit = React.useCallback(async () => {
    handleCloseOnsiteEdit();
    handleCloseOnlineEdit();
    loadMemberBooking(currentPage, pageSize, userStatus, keyword);
  }, [loadMemberBooking, currentPage, pageSize, userStatus, keyword]);

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setEditData(data);
    console.log("Edit Data: ", data);
  };

  const handleCloseOnsiteEdit = () => {
    setIsOpenCraeateOnsiteForm(false);
    setEditData(null);
  };

  const handleCloseOnlineEdit = () => {
    setIsOpenCraeateOnlineForm(false);
    setEditData(null);
  };

  const handleCloseEdit = () => {
    setIsOpenForm(false);
    setIsOpenCraeateOnsiteForm(false);
    setIsOpenCraeateOnlineForm(false);
    setEditData(null);
  };

  return (
    <>
      {/* Tab content for Booking */}
      <DeleteConfirmModal
        show={isDelete}
        onClose={handleCloseDeleteConfirm}
        onComfirm={handleDelete}
        deleteRecord={deleteData?.id}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these record?"}</p>
            <p>{`id = ${deleteData?.id}`}</p>
          </>
        }
      />
      <Container fluid style={{ minHeight: "30rem" }}>
        {isOpenCraeateOnsiteForm ? (
          <>
            <CreateNewOnlineBookingForm
              onCancel={handleCloseOnsiteEdit}
              onCreateSuccess={handleEdit}
              bookingType={1}
            />
          </>
        ) : isOpenCraeateOnlineForm ? (
          <>
            <CreateNewOnlineBookingForm
              onCancel={handleCloseOnlineEdit}
              onCreateSuccess={handleEdit}
              bookingType={0}
            />
          </>
        ) : isOpenForm ? (
          <>
            <EditOnsiteBookingDetail
              onCancel={handleCloseEdit}
              onCreateSuccess={handleEdit}
              editData={editData}
              bookingType={editData?.booking_type}
            />
          </>
        ) : (
          <>
            <Row className="mt-3">
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <GenericButton
                  className="me-3"
                  variant="success"
                  type="submit"
                  onClick={openCreateOnsiteForm}
                >
                  <i data-feather="plus" />
                  New Onsite Booking
                </GenericButton>
                <GenericButton
                  variant="success"
                  type="submit"
                  onClick={openCreateOnlineForm}
                >
                  <i data-feather="plus" />
                  New Online Booking
                </GenericButton>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={6} style={{ display: "flex" }}>
                <Searching
                  placeholder={setPlaceholder([
                    "เคสไอดี",
                    "ชื่อสัตว์เลี้ยง",
                    "ชื่อแพทย์",
                    "หมายเหตุ",
                  ])}
                  onSearch={handleSearch}
                  defaultValue={keyword}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <SelectFilter
                  label="Status"
                  placeholder={"Status"}
                  defaultValue={filterStatus.find(
                    (e) => e.value == allUserStatus
                  )}
                  value={filterStatus.find((e) => e.value == userStatus)}
                  options={filterStatus}
                  onChange={loadPageOnStatusChange}
                />
                <ReactDateTimePicker
                  selectedDate={selectedDate}
                  setSelectedDate={loadPageOnDatetimeChange}
                  placeholder="Schedule Date"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <ReactTable
                  columns={columnsDataBooking}
                  data={tableDataBooking}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <GenericPagination
                  totalPage={Math.ceil(total / pageSize)}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  onPageChange={loadPageOnPageChange}
                  onPageSizeChange={loadPageOnPageSizeChange}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableDataBooking = [];
  for (var i = 0; i < datas.length; i++) {
    console.log(datas[i].status);
    switch (datas[i].status) {
      case 70:
        datas[i].status = <span class="badge bg-primary">Booking</span>;
        break;
      case 71:
        datas[i].status = <span class="badge bg-success">Success</span>;
        break;
      case 72:
        datas[i].status = <span class="badge bg-danger">Cancle</span>;
        break;
      case 0:
        datas[i].status = <span class="badge bg-dark">InActive</span>;
        break;
      case 1:
        datas[i].status = <span class="badge bg-info">Active</span>;
        break;
    }
  }
  for (const data of datas) {
    console.log(data);
    tableDataBooking.push({
      no: startNo + tableDataBooking.length,
      case_id: data.code,
      case_title:
        (data.rel_member_info_id?.name || "") +
        "-" +
        (data.rel_pet_info_id?.name || "") +
        "-" +
        (data.rel_m_heal_id?.name || ""),
      booking_date: data.booking_date,
      schedule_date: data.schedule_date,
      status: data.status,
      owner: data.rel_doctor_info_id?.name,
      create_by: data.create_by,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(data);
            }}
          />
          <DeleteButton
            onClick={() => {
              onDelete(data);
            }}
          />
        </>
      ),
    });
  }
  return tableDataBooking;
};
