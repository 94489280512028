import React from "react";
import { productStockColumn } from "../data/Columns";
import { Card, Col, Container, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import { getProductStock } from "../services/ProductManagenentServices";
import { deleteMockLeaveInfo } from "../services/LeaveManagement";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import CreateEditStockForm from "../features/Form/CreateEditStockForm";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import { textAlignRight } from "../utils/Help";

export default function ProductStockManagement() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [tableData, setTableData] = React.useState([]);
  const columnsData = productStockColumn;

  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();

  const loadProductStock = React.useCallback(
    async (currentPage, pageSize, keyword, startDate, endDate) => {
      let dateFilter = null;
      if (startDate && endDate) {
        dateFilter = `$btw:${startDate} 00:00:00, ${endDate} 23:59:59`;
      }
      const { success, data, error } = await getProductStock({
        params: {
          page: currentPage,
          limit: pageSize,
          search: keyword,
          "filter.create_date": dateFilter,
          "filter._relations": "rel_product_id",
        },
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        ShowMessageAndExpire(
          "Load Stock Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    []
  );

  React.useEffect(() => {
    loadProductStock(1, 10);
  }, [loadProductStock]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadProductStock(page, pageSize, keyword, startDate, endDate);
    },
    [loadProductStock, pageSize, keyword, startDate, endDate]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadProductStock(1, pageSize, keyword, startDate, endDate);
    },
    [loadProductStock, keyword, startDate, endDate]
  );

  const openForm = () => {
    setIsOpenForm(true);
    setIsCreate(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setIsCreate(false);
    setIsDelete(false);
    setDeleteData(null);
    setCurrentPage(1);
    setKeyword("");
    loadProductStock(1, pageSize, "");
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadProductStock(1, pageSize, keyword, startDate, endDate);
    },
    [loadProductStock, pageSize, startDate, endDate]
  );

  const handleSelectedDate = React.useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate);
      setEndDate(endDate);
      setCurrentPage(1);
      await loadProductStock(1, pageSize, keyword, startDate, endDate);
    },
    [loadProductStock, pageSize, keyword]
  );

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setIsCreate(false);
  };

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const { success, error } = await deleteMockLeaveInfo({
        pathParams: id,
      });
      if (success) {
        ShowMessageAndExpire("Data deleted successfully.", "", "success");
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadProductStock(
          tempCurrentPage,
          pageSize,
          keyword,
          startDate,
          endDate
        );
      } else {
        handleCloseDeleteConfirm();
        ShowMessageAndExpire(
          "Failed to delete data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [
      loadProductStock,
      total,
      currentPage,
      pageSize,
      keyword,
      startDate,
      endDate,
    ]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              !isOpenForm
                ? "Product Stock Management"
                : isCreate
                ? "Create Product Stock"
                : "Product Stock Detail"
            }
            buttonEnable={!isOpenForm}
            buttonIcon="plus"
            buttonMessage="Create Product Stock"
            onButtonOnClick={openForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`id = ${deleteData?.id}, รหัสสินค้า= ${deleteData?.productCode}, ชื่อสินค้า= ${deleteData?.productName} `}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenForm ? (
                  <>
                    <Row className="mt-3">
                      <CreateEditStockForm
                        isCreate={isCreate}
                        onCancel={refreshPage}
                        onSubmitSuccess={refreshPage}
                      />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Filter : รหัสสินค้า, ชื่อสินค้า, จัดทำโดย"
                          onSearch={handleSearch}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={startDate ? new Date(startDate) : null}
                          defaultEnd={endDate ? new Date(endDate) : null}
                          placeholder="เลือกวันที่อัพเดท"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = [];
  for (const [idx, data] of datas.entries()) {
    const mapData = {
      no: startNo + idx,
      id: data.id,
      productCode: data?.rel_product_id?.code,
      productName: data?.rel_product_id?.product_name,
      transactionDatetime: data.create_date,
      createBy: data.create_by,
      newAdd: textAlignRight(data.qty_new),
      total: textAlignRight(data.qty_balance),
      remark: data.remark,
    };
    tableData.push({
      ...mapData,
    });
  }
  return tableData;
};
