import React from "react"
import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap"
import Header from "../layouts/Header"
import PageHeaderCard from "../components/Card/PageHeaderCard"
import GenericButton from "../components/Button/GenericButton"
import ScheduleTypeFilter from "../features/Filter/ScheduleTypeFilter"
import { ScheduleTypeFilters } from "../data/Filters"
import { useNavigate } from "react-router"
import {
  getAvailableTimeSchedule,
  getBookingTimeSchedule,
  getLeaveTimeSchedule,
} from "../services/DoctorTimeScheduleServices"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"
import CancelButton from "../components/Button/CancelButton"
import DoctorTimeScheduleFilter from "../features/Filter/DoctorTimeScheduleFilter"

const allBookingStatusOption = null

export default function DoctorTimeScheduleCalendar() {
  const navigate = useNavigate()
  const [keyword, setKeyword] = React.useState("")
  const [selectScheduleType, setSelectScheduleType] = React.useState([
    ScheduleTypeFilters.Booking,
    ScheduleTypeFilters.Leave,
    ScheduleTypeFilters.Schedules,
  ])
  const [startDate, setStartDate] = React.useState()
  const [endDate, setEndDate] = React.useState()
  const [hospitalListFilter, setHospitalListFilter] = React.useState([])
  const [doctorListFilter, setDoctorListFilter] = React.useState([])
  const [bookingStatus, setBookingStatus] = React.useState(
    allBookingStatusOption
  )
  const [calendarData, setCalendarData] = React.useState([])
  const [modalShow, setModalShow] = React.useState(false)
  const [modalDesc, setModalDesc] = React.useState(false)

  const handleShowDetail = ({
    scheduleType,
    start,
    end,
    doctorName,
    description,
    status,
  }) => {
    let txt = (
      <>
        ประเภทเวลา : {scheduleType}
        <br />
        ชื่อหมอ : {doctorName}
        <br />
        Start : {start?.toLocaleString("sv")}
        <br />
        End :{" "}
        {end ? (
          <>{end?.toLocaleString("sv")}</>
        ) : (
          <>{start?.toLocaleString("sv")}</>
        )}
        <br />
        {/* Description : {description} */}
        สถานะ : {status}
      </>
    )
    setModalDesc(txt)
    setModalShow(true)
  }

  const loadDoctorTimeSchedule = React.useCallback(
    async (
      selectScheduleType,
      keyword,
      hospitalListFilter,
      doctorListFilter,
      bookingStatus,
      startDate,
      endDate
    ) => {
      let calendarData = []
      if (selectScheduleType.includes(ScheduleTypeFilters.Booking)) {
        const { success, data, error } = await getBookingTimeSchedule({
          params: {
            page: 1,
            pageSize: 1000,
            search: keyword,
            hospital_ids: hospitalListFilter.join(","),
            doctor_ids: doctorListFilter.join(","),
            "filter.status": bookingStatus,
            // date_from: startDate && `${startDate} 00:00:00`,
            // date_to: endDate && `${endDate} 23:59:59`,
          },
        })
        if (success) {
          calendarData = calendarData.concat(
            prepareCalendarData(data.records, ScheduleTypeFilters.Booking)
          )
        } else {
          console.log(error)
        }
      }
      if (selectScheduleType.includes(ScheduleTypeFilters.Leave)) {
        const { success, data, error } = await getLeaveTimeSchedule({
          params: {
            page: 1,
            pageSize: 1000,
            search: keyword,
            hospital_ids: hospitalListFilter.join(","),
            doctor_ids: doctorListFilter.join(","),
            "filter.status": bookingStatus,
            // date_from: startDate && `${startDate} 00:00:00`,
            // date_to: endDate && `${endDate} 23:59:59`,
          },
        })
        if (success) {
          calendarData = calendarData.concat(
            prepareCalendarData(data.records, ScheduleTypeFilters.Leave)
          )
        } else {
          console.log(error)
        }
      }
      if (selectScheduleType.includes(ScheduleTypeFilters.Schedules)) {
        const { success, data, error } = await getAvailableTimeSchedule({
          params: {
            page: 1,
            pageSize: 1000,
            search: keyword,
            hospital_ids: hospitalListFilter.join(","),
            doctor_ids: doctorListFilter.join(","),
            "filter.status": bookingStatus,
            // date_from: startDate && `${startDate} 00:00:00`,
            // date_to: endDate && `${endDate} 23:59:59`,
          },
        })
        if (success) {
          calendarData = calendarData.concat(
            prepareCalendarData(data.records, ScheduleTypeFilters.Schedules)
          )
        } else {
          console.log(error)
        }
      }
      setCalendarData(calendarData)
    },
    []
  )

  React.useEffect(() => {
    loadDoctorTimeSchedule(
      [
        ScheduleTypeFilters.Booking,
        ScheduleTypeFilters.Leave,
        ScheduleTypeFilters.Schedules,
      ],
      "",
      [],
      [],
      allBookingStatusOption
    )
  }, [loadDoctorTimeSchedule])

  const loadPageOnUserStatusChange = React.useCallback(
    async (bookingStatus) => {
      setBookingStatus(bookingStatus)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        keyword,
        hospitalListFilter,
        doctorListFilter,
        bookingStatus,
        startDate,
        endDate
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      keyword,
      hospitalListFilter,
      doctorListFilter,
      startDate,
      endDate,
    ]
  )

  const handleSearch = React.useCallback(
    async (keyword) => {
      setKeyword(keyword)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        keyword,
        hospitalListFilter,
        doctorListFilter,
        bookingStatus,
        startDate,
        endDate
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      hospitalListFilter,
      doctorListFilter,
      bookingStatus,
      startDate,
      endDate,
    ]
  )

  const loadPageOnDoctorListFilterChange = React.useCallback(
    async (doctorListFilter) => {
      const listId = doctorListFilter.map((value) => value.value)
      setDoctorListFilter(listId)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        keyword,
        hospitalListFilter,
        listId,
        bookingStatus,
        startDate,
        endDate
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      keyword,
      hospitalListFilter,
      bookingStatus,
      startDate,
      endDate,
    ]
  )

  const loadPageOnHospitalListFilterChange = React.useCallback(
    async (hospitalListFilter) => {
      const listId = hospitalListFilter.map((value) => value.value)
      setHospitalListFilter(listId)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        keyword,
        listId,
        doctorListFilter,
        bookingStatus,
        startDate,
        endDate
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      keyword,
      doctorListFilter,
      bookingStatus,
      startDate,
      endDate,
    ]
  )

  const handleSelectedDate = React.useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate)
      setEndDate(endDate)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        keyword,
        hospitalListFilter,
        doctorListFilter,
        bookingStatus,
        startDate,
        endDate
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      keyword,
      hospitalListFilter,
      doctorListFilter,
      bookingStatus,
    ]
  )

  const handleScheduleTypeChange = React.useCallback(
    async (event) => {
      var isChecked = event.target.checked
      var item = event.target.value
      const newSelectScheduleType = selectScheduleType
      if (isChecked) {
        newSelectScheduleType.push(item)
      } else {
        const index = newSelectScheduleType.indexOf(item)
        if (index > -1) {
          newSelectScheduleType.splice(index, 1)
        }
      }
      setSelectScheduleType(newSelectScheduleType)
      await loadDoctorTimeSchedule(
        newSelectScheduleType,
        keyword,
        hospitalListFilter,
        doctorListFilter,
        bookingStatus,
        startDate,
        endDate
      )
    },
    [
      loadDoctorTimeSchedule,
      keyword,
      hospitalListFilter,
      doctorListFilter,
      bookingStatus,
      startDate,
      endDate,
    ]
  )

  const handleDetailModalClose = () => {
    setModalShow(false)
    setModalDesc("")
  }

  return (
    <>
      <Modal
        show={modalShow}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>{modalDesc}</Modal.Body>
        <Modal.Footer>
          <CancelButton onClick={handleDetailModalClose} />
        </Modal.Footer>
      </Modal>
      <Container fluid style={{ minHeight: "30rem" }}>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <div className="p-3 p-lg-4">
            <PageHeaderCard title="Doctor Time Schedule" buttonEnable={false} />
            <Card>
              <Card.Body>
                <ScheduleTypeFilter
                  checked={selectScheduleType}
                  onChange={handleScheduleTypeChange}
                  type="checkbox"
                />
                <>
                  <GenericButton
                    onClick={() => {
                      navigate("/pages/doctor-time-schedule")
                    }}
                  >
                    {"Calendar View"}
                  </GenericButton>
                  <DoctorTimeScheduleFilter
                    onSearch={handleSearch}
                    onSelectHospital={loadPageOnHospitalListFilterChange}
                    onSelectDoctor={loadPageOnDoctorListFilterChange}
                    onSelectBookingStatus={loadPageOnUserStatusChange}
                    bookingStatusSelect={bookingStatus}
                    onSelectDateTimeRange={handleSelectedDate}
                    disableBookingStatusFilter={
                      !selectScheduleType.includes(ScheduleTypeFilters.Booking)
                    }
                  />
                  <Row className="mt-3">
                    <Col>
                      <div className="calendar-body">
                        <FullCalendar
                          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                          eventClick={function (arg) {
                            handleShowDetail({
                              scheduleType: arg.event.extendedProps?.type,
                              start: arg.event.start,
                              end: arg.event.end,
                              doctorName: arg.event.extendedProps?.doctorName,
                              description: arg.event.extendedProps?.description,
                              status: arg.event.extendedProps?.status,
                            })
                          }}
                          eventDidMount={function (arg) {
                            var dotEl =
                              arg.el.getElementsByClassName(
                                "fc-list-event-dot"
                              )[0]
                            if (dotEl) {
                              if (
                                arg.event.extendedProps.type ===
                                ScheduleTypeFilters.Booking
                              ) {
                                dotEl.style.borderColor = "#0d6efd"
                              }
                              if (
                                arg.event.extendedProps.type ===
                                ScheduleTypeFilters.Leave
                              ) {
                                dotEl.style.borderColor = "#dc3545"
                              }
                              if (
                                arg.event.extendedProps.type ===
                                ScheduleTypeFilters.Schedules
                              ) {
                                dotEl.style.borderColor = "#198754"
                              }
                            }
                          }}
                          initialView="dayGridMonth"
                          headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,listWeek",
                          }}
                          weekends={true}
                          // events={events}
                          eventSources={calendarData}
                          eventContent={renderEventContent}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </>
  )
}

const prepareCalendarData = (events, scheduleType) => {
  let calendarData = []
  let bookingEvent = []
  let leaveEvent = []
  let scheduleEvent = []
  let i = 1
  for (const event of events) {
    if (scheduleType === ScheduleTypeFilters.Booking) {
      bookingEvent.push({
        id: i,
        type: ScheduleTypeFilters.Booking,
        scheduleType: event.type,
        start: event.date_from && new Date(event.date_from),
        end: event.date_to && new Date(event.date_to),
        doctorName: event.doctor_fullname,
        description: (
          <>
            <ul>
              <li>{event.remark}</li>
            </ul>
          </>
        ),
        status: event.status,
      })
    } else if (scheduleType === ScheduleTypeFilters.Leave) {
      leaveEvent.push({
        id: i,
        type: ScheduleTypeFilters.Leave,
        scheduleType: event.type,
        start: event.date_from && new Date(event.date_from),
        end: event.date_to && new Date(event.date_to),
        doctorName: event.doctor_fullname,
        description: (
          <>
            <ul>
              <li>{event.remark}</li>
            </ul>
          </>
        ),
        status: event.status,
      })
    } else if (scheduleType === ScheduleTypeFilters.Schedules) {
      scheduleEvent.push({
        id: i,
        type: ScheduleTypeFilters.Schedules,
        scheduleType: event.type,
        start: event.date_from && new Date(event.date_from),
        end: event.date_to && new Date(event.date_to),
        title: event.doctorName,
        doctorName: event.doctor_fullname,
        description: event.description,
        status: event.status,
      })
    }
    i += 1
  }

  calendarData.push(
    {
      id: 1,
      backgroundColor: "#d9e8ff",
      borderColor: "#d9e8ff",
      events: bookingEvent,
    },
    {
      id: 2,
      backgroundColor: "#c3edd5",
      borderColor: "#10b759",
      events: leaveEvent,
    },
    {
      id: 3,
      backgroundColor: "#fcbfdc",
      borderColor: "#f10075",
      events: scheduleEvent,
    }
  )

  return calendarData
}

const renderEventContent = (eventInfo) => {
  const type = eventInfo.event.extendedProps.type
  return (
    <>
      <div
        class="container"
        className={
          type === "booking"
            ? "background-blue"
            : type === "leave"
            ? "background-red"
            : "background-green"
        }
      >
        ประเภทเวลา : {eventInfo.event.extendedProps?.type}
        <br />
        ชื่อหมอ : {eventInfo.event.extendedProps?.doctorName}
        <br />
        เวลา : {eventInfo.event.start?.toLocaleString("sv")}
        <br />
        ถึง{" "}
        {eventInfo.event.end ? (
          <>{eventInfo.event.end?.toLocaleString("sv")}</>
        ) : (
          <>{eventInfo.event.start?.toLocaleString("sv")}</>
        )}
        <br />
        {/* Description : {eventInfo.event.extendedProps?.description} */}
        สถานะ : {eventInfo.event.extendedProps?.status}
      </div>
    </>
  )
}
