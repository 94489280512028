import React from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";

export default function GenericSelectInput({
  control,
  name,
  label,
  placeholder,
  defaultValue,
  value,
  options,
  disabled,
  required,
  isMulti,
  onChange,
}) {
  const customStyles = {
    control: (base, { selectProps: { isValid, isFocused } }) => ({
      ...base,
      borderColor: isFocused ? "#ddd" : isValid ? "#ddd" : "red",
      "&:hover": {
        borderColor: isFocused ? "#ddd" : isValid ? "#ddd" : "red",
      },
    }),
  };
  return (
    <>
      {control ? (
        <>
          <Controller
            name={name}
            control={control}
            rules={{ required: required }}
            defaultValue={() => {
              if (!defaultValue && defaultValue !== 0) {
                return null;
              }
              let defaultOption;
              for (const option of options) {
                if (option["value"] === defaultValue) {
                  defaultOption = option;
                }
              }
              return defaultOption;
            }}
            render={({ field, fieldState }) => (
              <>
                <Form.Label>
                  {label}
                  <p style={{ color: "red", display: "inline" }}>
                    {required && "*"}
                  </p>
                </Form.Label>
                <Select
                  isValid={!fieldState.error}
                  styles={customStyles}
                  {...field}
                  placeholder={placeholder}
                  isDisabled={disabled}
                  isClearable={field.value}
                  isSearchable
                  options={options}
                  isMulti={isMulti}
                  onChange={(value) => {
                    field.onChange(value);
                    if (onChange) {
                      onChange(value);
                    }
                  }}
                />
                <p
                  style={{
                    color: "#dc3545",
                    display: "inline",
                    fontSize: "0.875em",
                    marginTop: "0.25rem",
                  }}
                >
                  {fieldState.error?.message}
                </p>
              </>
            )}
          />
        </>
      ) : (
        <>
          <Form.Label>
            {label}
            <p style={{ color: "red", display: "inline" }}>{required && "*"}</p>
          </Form.Label>
          <Select
            isValid={true}
            styles={customStyles}
            placeholder={placeholder}
            defaultValue={() => {
              if (!defaultValue && defaultValue !== 0) {
                return null;
              }
              let defaultOption;
              for (const option of options) {
                if (option["value"] === defaultValue) {
                  defaultOption = option;
                }
              }
              return defaultOption;
            }}
            value={value}
            isDisabled={disabled}
            isSearchable
            options={options}
            isMulti={isMulti}
            onChange={(value) => {
              onChange(value);
            }}
          />
        </>
      )}
    </>
  );
}
