import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";
import { CmsLanguageOptions, StatusOptions } from "../data/SelectOptions";
/*
ID
LANGUAGE 0 = TH,1 = EN
MSGTYPE 0 = ข่าว,1 = บทความ
SUBJECT หัวข้อ
EXP_DATE วันที่หมดอายุ
NEWS_PICTURE Path file รูป
DESCRIPTION รายละเอียด
STATUS สถานะรายการ0 = InActive,1 = Active
CREATE_DATE วันที่สร้างข้อมูล
CREATE_BY ID ผู้สร้างข้อมูล
UPDATE_DATE วันที่ปรับปรุงข้อมูล
UPDATE_BY ID ผู้ปรับปรุงข้อมูล*/

const msgtype = 0;

export const getLanguage = (valueFromDB) => {
  return CmsLanguageOptions.find((e) => e.value === valueFromDB);
}
export const getStatus = (valueFromDB) => {
  return StatusOptions.find((e) => e.value === valueFromDB);
}

export const reformatData = (record) => {
  return {
    ...record,
    language: getLanguage(record.language),
    msgtype: 0 ? 'ข่าว' : 'บทความ',
    status: getStatus(record.status)
  }
}

export const getNews = async ({ page, pageSize, keyword, status, dateStart, dateEnd }) => {
  try {
    let datefilter;
    if( dateStart && dateEnd ){
      datefilter ='$btw:'+dateStart+' 00:00:00,'+dateEnd+' 23:59:59';
    }
    // console.log(datefilter);
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/news/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit: pageSize,
          'filter.msgtype': msgtype,
          search: keyword,
          'filter.status': status,
          'filter.update_date': datefilter
        },
      }
    );
    let reformatRecords = await res.data.data.map((rec) => reformatData(rec));
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: reformatRecords,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createNews = async (
  { status, language, subject, exp_date, news_picture, description }
) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/news",
      {
        msgtype,
        status,
        language,
        subject,
        exp_date,
        news_picture,
        description,
      },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editNews = async (
  { id, status, language, subject, exp_date, news_picture, description }
) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/news/" + id,
      {
        status: status,
        language: getLanguage(language).value,
        subject,
        exp_date,
        news_picture,
        description,
      },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteNews = async ({ id }) => {
  try {
    //console.log("delete id", id)
    await axios.delete(
      process.env.REACT_APP_API_URL + "/news/" + id,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: null,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getNewsInfoById = async (id) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/news/" + id,
      {
        headers: await getDefaultRequestHeaders(true),
        params: {},
      }
    );

    return {
      success: true,
      data: await reformatData(res.data.data),
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const uploadNewsImage = async ({ id, imageFile }) => {
  // console.log("uploadImage",bodyFormData)
   try {
    let bodyFormData = new FormData();
    bodyFormData.append("image", imageFile, imageFile?.name);
    bodyFormData.append("res_model", "news");
    bodyFormData.append("res_id", id);
    bodyFormData.append("res_field", "news_picture");

     const res = await axios.post(
       process.env.REACT_APP_API_URL + "/upload/image",
       bodyFormData,
       {
         headers: await getDefaultRequestHeaders(true),
       }
     );
     return {
       success: true,
       data: res.data?.data,
       error: null,
     };
   } catch (err) {
     return {
       success: false,
       data: null,
       error: err.response?.data?.error || err,
     };
   }
 };

 export const deleteNewsImage = async ({ id }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/upload/image",
      {
        headers: await getDefaultRequestHeaders(true),
        data: {
          res_model: "news",
          res_id: id,
          res_field: "news_picture",
        }
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockNews = async () => {
  return {
    "success": true,
    "data": [
      {
        "create_by": 6,
        "create_date": "2023-07-02 07:53:28",
        "description": "pppppppppppppp",
        "exp_date": null,
        "id": 28,
        "language": 1,
        "msgtype": 0,
        "news_picture": null,
        "status": 1,
        "subject": "lllllll",
        "update_by": 6,
        "update_date": "2023-07-02 07:53:28"
      },
      {
        "create_by": 6,
        "create_date": "2023-07-01 13:20:13",
        "description": "7777777",
        "exp_date": "2023-07-01 20:31:00",
        "id": 27,
        "language": 1,
        "msgtype": 0,
        "news_picture": null,
        "status": 1,
        "subject": "test oom777777",
        "update_by": 6,
        "update_date": "2023-07-01 13:31:43"
      },
    ],
    "meta": {
      "items_per_page": 10,
      "total_items": 25,
      "current_page": 1,
      "total_pages": 3,
      "relations": []
    }
  };
}