import React from "react"
import { productCategoryColumn } from "../data/Columns"
import { Card, Col, Container, Row } from "react-bootstrap"
import Header from "../layouts/Header"
import PageHeaderCard from "../components/Card/PageHeaderCard"
import Searching from "../components/Searching"
import ReactTable from "../components/ReactTable"
import GenericPagination from "../components/GenericPagination"
import SelectFilter from "../components/SelectFilter"
import { filterProductCategoryStatusOptions } from "../data/SelectOptions"
import {
  deleteProductCategory,
  getProductCategory,
} from "../services/ProductManagenentServices"
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal"
import EditButton from "../components/Button/EditButton"
import DeleteButton from "../components/Button/DeleteButton"
import CreateEditProductCategoryForm from "../features/Form/CreateEditProductCategoryForm"
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2"

const allCategoryStatus = null

export default function ProductManagement() {
  const [total, setTotal] = React.useState(1)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(10)
  const [keyword, setKeyword] = React.useState("")
  const [productCategoryStatus, setProductCategoryStatus] =
    React.useState(allCategoryStatus)
  const [startDate, setStartDate] = React.useState()
  const [endDate, setEndDate] = React.useState()
  const [tableData, setTableData] = React.useState([])
  const columnsData = productCategoryColumn

  const [isOpenForm, setIsOpenForm] = React.useState(false)
  const [isCreate, setIsCreate] = React.useState(false)
  const [editData, setEditData] = React.useState()
  const [isDelete, setIsDelete] = React.useState(false)
  const [deleteData, setDeleteData] = React.useState()

  const loadProductCategory = React.useCallback(
    async (
      currentPage,
      pageSize,
      productCategoryStatus,
      keyword,
      startDate,
      endDate
    ) => {
      let dateFilter = null
      if (startDate && endDate) {
        dateFilter = `$btw:${startDate} 00:00:00, ${endDate} 23:59:59`
      }
      const { success, data, error } = await getProductCategory({
        params: {
          page: currentPage,
          limit: pageSize,
          search: keyword,
          "filter.status": productCategoryStatus,
          "filter.update_date": dateFilter,
        },
      })
      if (success) {
        setTotal(data.total)
        setTableData(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        )
      } else {
        console.log(error)
      }
    },
    []
  )

  React.useEffect(() => {
    loadProductCategory(1, 10, allCategoryStatus)
  }, [loadProductCategory])

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page)
      await loadProductCategory(
        page,
        pageSize,
        productCategoryStatus,
        keyword,
        startDate,
        endDate
      )
    },
    [
      loadProductCategory,
      pageSize,
      productCategoryStatus,
      keyword,
      startDate,
      endDate,
    ]
  )

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1)
      setPageSize(pageSize)
      await loadProductCategory(
        1,
        pageSize,
        productCategoryStatus,
        keyword,
        startDate,
        endDate
      )
    },
    [loadProductCategory, productCategoryStatus, keyword, startDate, endDate]
  )

  const loadPageOnUserStatusChange = React.useCallback(
    async (productCategoryStatus) => {
      setCurrentPage(1)
      setProductCategoryStatus(productCategoryStatus)
      await loadProductCategory(
        1,
        pageSize,
        productCategoryStatus,
        keyword,
        startDate,
        endDate
      )
    },
    [loadProductCategory, pageSize, keyword, startDate, endDate]
  )

  const openForm = () => {
    setIsOpenForm(true)
    setIsCreate(true)
  }

  const refreshPage = () => {
    setIsOpenForm(false)
    setIsCreate(false)
    setEditData(null)
    setIsDelete(false)
    setDeleteData(null)
    setCurrentPage(1)
    setKeyword("")
    setProductCategoryStatus(allCategoryStatus, startDate, endDate)
    loadProductCategory(1, pageSize, allCategoryStatus, "", startDate, endDate)
  }

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1)
      setKeyword(keyword)
      await loadProductCategory(
        1,
        pageSize,
        productCategoryStatus,
        keyword,
        startDate,
        endDate
      )
    },
    [loadProductCategory, pageSize, productCategoryStatus, startDate, endDate]
  )

  const handleSelectedDate = React.useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate)
      setEndDate(endDate)
      setCurrentPage(1)
      await loadProductCategory(
        1,
        pageSize,
        productCategoryStatus,
        keyword,
        startDate,
        endDate
      )
    },
    [loadProductCategory, pageSize, productCategoryStatus, keyword]
  )

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true)
    setIsCreate(false)
    setEditData(data)
  }

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true)
    setDeleteData(data)
  }

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false)
    setDeleteData(null)
  }

  const handleDelete = React.useCallback(
    async (id) => {
      const { success, error } = await deleteProductCategory({
        pathParams: id,
      })
      if (success) {
        handleCloseDeleteConfirm()
        let tempCurrentPage = currentPage
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1)
          tempCurrentPage = tempCurrentPage - 1
        }
        loadProductCategory(
          tempCurrentPage,
          pageSize,
          productCategoryStatus,
          keyword
        )
      } else {
        console.log(error)
      }
    },
    [
      loadProductCategory,
      total,
      currentPage,
      pageSize,
      productCategoryStatus,
      keyword,
    ]
  )

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              !isOpenForm
                ? "Product Category Management"
                : isCreate
                ? "Create Product Category"
                : "Product Category Detail"
            }
            buttonEnable={!isOpenForm}
            buttonIcon="plus"
            buttonMessage="Create Product Category"
            onButtonOnClick={openForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`id = ${deleteData?.id}, ชื่อหมวดหมู่สินค้า= ${deleteData?.productCategory}, ยี่ห้อ= ${deleteData?.productBrand} `}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenForm ? (
                  <>
                    <Row className="mt-3">
                      <CreateEditProductCategoryForm
                        isCreate={isCreate}
                        onCancel={refreshPage}
                        onSubmitSuccess={refreshPage}
                        languageDefaultValue={editData?.language | 0}
                        productCategoryDefaultValue={editData?.productCategory}
                        productBrandDefaultValue={editData?.productBrand}
                        id={editData?.id}
                      />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Filter : ชื่อหมวดหมู่สินค้า"
                          onSearch={handleSearch}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="สถานะ"
                          placeholder={"สถานะ"}
                          defaultValue={filterProductCategoryStatusOptions.find(
                            (e) => e.value === allCategoryStatus
                          )}
                          value={filterProductCategoryStatusOptions.find(
                            (e) => e.value === productCategoryStatus
                          )}
                          options={filterProductCategoryStatusOptions}
                          onChange={loadPageOnUserStatusChange}
                        />
                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={startDate ? new Date(startDate) : null}
                          defaultEnd={endDate ? new Date(endDate) : null}
                          placeholder="เลือกวันที่อัพเดท"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = []
  for (const [idx, data] of datas.entries()) {
    const tempTableData = {
      no: startNo + idx,
      id: data.id,
      productCategory: data.category_name,
      productBrand: data.brand_name,
      transactionDatetime: data.update_date,
      productCategoryStatus:
        data.status === 1 ? "Active" : data.status === 0 ? "Inactive" : "-",
    }
    const buttonData = {
      id: data.id,
      language: data.language,
      productCategory: data.category_name,
      productBrand: data.brand_name,
      transactionDatetime: data.update_date,
      productCategoryStatus: data.status,
    }
    tableData.push({
      ...tempTableData,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(buttonData)
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(buttonData)
            }}
          />
        </>
      ),
    })
  }
  return tableData
}
