import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Searching from "../../components/Searching";
import GenericButton from "../../components/Button/GenericButton";
import ReactTable from "../../components/ReactTable";
import EditButton from "../../components/Button/EditButton";
import DeleteButton from "../../components/Button/DeleteButton";
import GenericPagination from "../../components/GenericPagination";
import { filterUserStatusOptions } from "../../data/SelectOptions";
import SelectFilter from "../../components/SelectFilter";
import ReactDateTimePicker from "../../components/Input/ReactDateTimePicker";
import { membersPets } from "../../data/Columns";
import { getMockPets } from "../../services/MemberServices";

const allUserStatus = "all";
const schema = yup.object().shape({
  firstname: yup.string().required("ชื่อห้ามว่าง"),
  lastname: yup.string().required("นามสกุลห้ามว่าง"),
  email: yup.string().email("อีเมลไม่ถูกต้อง").required("อีเมลห้ามว่าง"),
  telephone: yup.string().required("เบอร์โทรศัพท์ห้ามว่าง"),
});

export default function MemberEditPetsForm({
  onCancel,
  onSubmitSuccess,
  memberFirstNameDefaultValue,
  memberLastLameDefaultValue,
  phoneDefaultValue,
  emailDefaultValue,
  birthDateDefaultValue,
}) {
  const [total, setTotal] = React.useState(1);
  const [tableDataPets, setTableDataPets] = React.useState([]);
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const [keyword, setKeyword] = React.useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const columnsDataPets = membersPets;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loadMemberInfo = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword) => {
      const { success, data, error } = await getMockPets({
        page: currentPage,
        pageSize: pageSize,
        keyword: keyword,
        status: userStatus,
      });
      if (success) {
        setTotal(data.total);
        setTableDataPets(
          prepareTableData(
            data.records,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        console.log(error);
      }
    },
    []
  );

  const editButton = (data) => {
    for (var i = 0; i < data.length; i++) {
      switch (data[i].status) {
        case "Pets":
          data[i].status = (
            <Button
              variant="primary"
              active
              style={{ borderRadius: "50px", color: "white" }}
            >
              {data[i].status}
            </Button>
          );
          break;
        case "complete":
          data[i].status = (
            <Button
              variant="success"
              active
              style={{ borderRadius: "50px", color: "white" }}
            >
              {data[i].status}
            </Button>
          );
          break;
        case "cancel":
          data[i].status = (
            <Button
              variant="danger"
              active
              style={{ borderRadius: "50px", color: "white" }}
            >
              {data[i].status}
            </Button>
          );
          break;
      }
    }
    return data;
  };

  React.useEffect(() => {
    loadMemberInfo(1, 10, allUserStatus);
  }, [loadMemberInfo]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadMemberInfo(page, pageSize, userStatus, keyword);
    },
    [loadMemberInfo, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadMemberInfo(1, pageSize, userStatus, keyword);
    },
    [loadMemberInfo, userStatus, keyword]
  );

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setEditData(data);
  };

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const onSubmit = async (inputData) => {
    const { success, data, error } = await getMockPets({
      status: inputData.permissionStatus,
    });
    if (success) {
      onSubmitSuccess();
    } else {
      console.log(error);
    }
  };

  const loadUser = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword) => {
      const { success, data, error } = await getMockPets({
        page: currentPage,
        pageSize: pageSize,
        keyword: keyword,
        status: userStatus,
      });
      if (success) {
        setTotal(data.total);
        setTableDataPets(editButton(data.records));
      } else {
        console.log(error);
      }
    },
    []
  );

  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadUser(1, pageSize, userStatus, keyword);
    },
    [loadUser, pageSize, keyword]
  );

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Tab content for Pets */}
        <Row className="mt-3">
          <Col xs={6} style={{ display: "flex" }}>
            <Searching placeholder="ค้นหา รหัสสินค้า , หมายเหตุ" />
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <SelectFilter
              label="สถานะ"
              placeholder={"สถานะ"}
              defaultValue={filterUserStatusOptions.find(
                (e) => e.value == allUserStatus
              )}
              value={filterUserStatusOptions.find((e) => e.value == userStatus)}
              options={filterUserStatusOptions}
              onChange={loadPageOnUserStatusChange}
            />
            <ReactDateTimePicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </Col>
        </Row>
        <Container fluid style={{ minHeight: "30rem" }}>
          <Row className="mt-3">
            <Col>
              <ReactTable
                columns={columnsDataPets}
                data={tableDataPets}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <GenericPagination
                totalPage={Math.ceil(total / pageSize)}
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={loadPageOnPageChange}
                onPageSizeChange={loadPageOnPageSizeChange}
              />
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
}

const prepareTableData = (datas, onEdit, onDelete) => {
  let tableDataPets = [];
  for (const data of datas) {
    tableDataPets.push({
      ...data,
      editDeleteButton: (
        <>
          <GenericButton variant="warning">Detail</GenericButton>
        </>
      ),
    });
  }
  return tableDataPets;
};
