import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function MessageModal({
  show,
  onClose,
  titleMessage = "Error",
  bodyMessage = "",
  type,
}) {
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body style={{ textAlign: "center" }}>
          {type === "error" ? (
            <h1 className="text-danger">{titleMessage}</h1>
          ) : (
            <>
              {type === "success" ? (
                <h1 className="text-success">{titleMessage}</h1>
              ) : (
                <h1>{titleMessage}</h1>
              )}
            </>
          )}
          <p>{bodyMessage}</p>
          <br />
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
