import React from "react";
import { userMembers } from "../data/Columns";
import { Card, Col, Container, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";
import CreateNewMembersForm from "../features/Form/CreateNewMembersForm";
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import MembersDetailEditForm from "../features/Form/MembersDetailEditForm";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import { deleteCommonAPI, getCommonAPI } from "../services/commonAPIServices";
import ReactDateTimeRangePicker from "../components/Input/ReactDateTimeRangePicker";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";
import setPlaceholder from "../utils/Help";

const allUserStatus = "all";

export default function MemberInfo() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [tableData, setTableData] = React.useState([]);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const [dateStart, setDateStart] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();
  const columnsData = userMembers;

  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isOpenCraeateForm, setIsOpenCraeateForm] = React.useState(false);

  const loadMemberInfo = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, dateStart, dateEnd) => {
      let datefilter;
      if (dateStart && dateEnd) {
        datefilter = "$btw:" + dateStart + " 00:00:00," + dateEnd + " 23:59:59";
      }
      const page = currentPage;
      const limit = pageSize;
      const search = keyword;
      const url_api = "/member_info/datatable";
      // const search = keyword;
      const p_data = {
        page,
        "filter._ignore_rel": 1,
        "filter.update_date": datefilter,
        limit,
        search,
      };
      const { success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm,
            handleOpenDeleteConfirm
          )
        );
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  React.useEffect(() => {
    loadMemberInfo(1, 10, allUserStatus);
  }, [loadMemberInfo]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadMemberInfo(page, pageSize, userStatus, keyword);
    },
    [loadMemberInfo, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadMemberInfo(1, pageSize, userStatus, keyword);
    },
    [loadMemberInfo, userStatus, keyword]
  );

  const loadTimeRangePicker = React.useCallback(
    async (sDate, eDate) => {
      setDateStart(sDate);
      setDateEnd(eDate);
      setCurrentPage(1);
      await loadMemberInfo(1, pageSize, userStatus, keyword, sDate, eDate);
    },
    [loadMemberInfo, pageSize, userStatus, keyword]
  );

  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadMemberInfo(1, pageSize, userStatus, keyword);
    },
    [loadMemberInfo, pageSize, keyword]
  );

  const openCreateForm = () => {
    setIsOpenCraeateForm(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setCurrentPage(1);
    setKeyword("");
    setUserStatus(allUserStatus);
    loadMemberInfo(1, pageSize, allUserStatus, "");
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadMemberInfo(1, pageSize, userStatus, keyword);
    },
    [loadMemberInfo, pageSize, userStatus]
  );

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleEdit = React.useCallback(async () => {
    handleCloseEdit();
    loadMemberInfo(currentPage, pageSize, userStatus, keyword);
  }, [loadMemberInfo, currentPage, pageSize, userStatus, keyword]);

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setEditData(data);
  };

  const handleCloseEdit = () => {
    setIsOpenForm(false);
    setIsOpenCraeateForm(false);
    setEditData(null);
  };

  const handleDelete = React.useCallback(
    async (memberId) => {
      const url_api = "/member_info/" + memberId;
      const { success, data, error } = await deleteCommonAPI({
        url_api,
      });
      if (success) {
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadMemberInfo(tempCurrentPage, pageSize, userStatus, keyword);
      } else {
        console.log(error);
      }
    },
    [loadMemberInfo, total, currentPage, pageSize, userStatus, keyword]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              editData
                ? `Customer Info : ${editData?.name} ${editData?.lname} - ${editData?.phone}`
                : "Customer Info Management"
            }
            buttonEnable={!isOpenForm && !isOpenCraeateForm}
            buttonIcon="plus"
            buttonMessage="New Customer"
            onButtonOnClick={openCreateForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`Member ID = ${deleteData?.id}, ชื่อ = ${deleteData?.name} ${deleteData?.lname}`}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenCraeateForm ? (
                  <>
                    <CreateNewMembersForm
                      onCancel={handleCloseEdit}
                      onCreateSuccess={handleEdit}
                    />
                  </>
                ) : isOpenForm ? (
                  <>
                    <MembersDetailEditForm
                      onCancel={handleCloseEdit}
                      onSubmitSuccess={handleEdit}
                      memberId={editData?.id}
                      memberFirstNameDefaultValue={editData?.name}
                      memberLastLameDefaultValue={editData?.lname}
                      phoneDefaultValue={editData?.phone}
                      emailDefaultValue={editData?.email}
                      birthDateDefaultValue={editData?.brithday}
                      editData={editData}
                    />
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder={setPlaceholder([
                            "Name",
                            "Surname",
                            "Phone No.",
                          ])}
                          onSearch={handleSearch}
                          defaultValue={keyword}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactDateTimeRangePicker2
                          onSelected={loadTimeRangePicker}
                          defaultStart={dateStart ? new Date(dateStart) : null}
                          defaultEnd={dateEnd ? new Date(dateEnd) : null}
                          placeholder="Last Service"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = [];
  for (const data of datas) {
    tableData.push({
      memberId: startNo + tableData.length,
      membersurename: `${data.name} ${data.lname}`,
      phone: data.phone,
      register_data: data.regdate,
      last_service_date: data.update_date,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(data);
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(data);
            }}
          />
        </>
      ),
    });
  }
  // console.log(tableData);
  return tableData;
};
