import React from "react";
import {
  deleteMockMasterData,
  deletePetTypeMasterData,
  getMockPetTypeMasterData,
  getPetTypeMasterData,
} from "../services/SettingServices";
import { petTypeMasterDataColumn } from "../data/Columns";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import { Card, Col, Container, Row } from "react-bootstrap";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import DeleteButton from "../components/Button/DeleteButton";
import EditButton from "../components/Button/EditButton";
import CreateEditPetTypeMasterData from "../features/Form/CreateEditPetTypeMasterData";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";

export default function SpecializedMasterDataSetting() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const columnsData = petTypeMasterDataColumn;

  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();

  const loadData = React.useCallback(async (currentPage, pageSize, keyword) => {
    const { success, data, error } = await getPetTypeMasterData({
      params: {
        page: currentPage,
        limit: pageSize,
        search: keyword,
      },
    });
    if (success) {
      setTotal(data.total);
      setTableData(
        prepareTableData(
          data.records,
          (currentPage - 1) * pageSize + 1,
          handleOpenEditForm,
          handleOpenDeleteConfirm
        )
      );
    } else {
      console.log(error);
      ShowMessageAndExpire(
        "Failed to load data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, []);

  React.useEffect(() => {
    loadData(1, 10);
  }, [loadData]);

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadData(page, pageSize, keyword);
    },
    [loadData, pageSize, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadData(1, pageSize, keyword);
    },
    [loadData, keyword]
  );

  const openForm = () => {
    setIsOpenForm(true);
    setIsCreate(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setCurrentPage(1);
    setIsCreate(false);
    setEditData(null);
    setDeleteData(null);
    setKeyword("");
    loadData(1, pageSize, "");
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadData(1, pageSize, keyword);
    },
    [loadData, pageSize]
  );

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setIsCreate(false);
    setEditData(data);
  };

  const handleCloseEdit = () => {
    setIsOpenForm(false);
    setEditData(null);
  };

  const handleEdit = React.useCallback(async () => {
    handleCloseEdit();
    loadData(currentPage, pageSize, keyword);
  }, [loadData, currentPage, pageSize, keyword]);

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const { success, data, error } = await deletePetTypeMasterData({
        pathParams: id,
      });
      if (success) {
        ShowMessageAndExpire("Data deleted successfully.", "", "success");
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadData(tempCurrentPage, pageSize, keyword);
      } else {
        handleCloseDeleteConfirm();
        ShowMessageAndExpire(
          "Failed to delete data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [loadData, total, currentPage, pageSize, keyword]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              !isOpenForm
                ? "Settings - Master Data ประเภทสัตว์"
                : isCreate
                ? "Create Master Data ประเภทสัตว์"
                : "Master Data ประเภทสัตว์ Detail"
            }
            buttonEnable={!isOpenForm}
            buttonIcon="plus"
            buttonMessage="Create new records"
            onButtonOnClick={openForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`id = ${deleteData?.id}, ประเภทสัตว์= ${deleteData?.petType}, วันที่สร้างรายการ= ${deleteData?.createAt} `}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenForm ? (
                  <>
                    <Row className="mt-3">
                      <CreateEditPetTypeMasterData
                        isCreate={isCreate}
                        onCancel={refreshPage}
                        onSubmitSuccess={refreshPage}
                        petTypeDefaultValue={editData?.petType}
                        isEnableDefaultValue={[editData?.status]}
                        createAtDefaultValue={editData?.createAt}
                        id={editData?.id}
                      />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="Filter"
                          onSearch={handleSearch}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      ></Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = [];
  for (const [idx, data] of datas.entries()) {
    const tempTableData = {
      no: startNo + idx,
      id: data.id,
      petType: data.name,
      createAt: data.create_date,
      status: data.status === 1 ? "Enable" : data.status === 0 ? "Diable" : "-",
    };
    const buttonData = {
      id: data.id,
      petType: data.name,
      createAt: data.create_date,
      status: !!+data?.status,
    };
    tableData.push({
      ...tempTableData,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(buttonData);
            }}
          />
          {/* {" "}
          <DeleteButton
            onClick={() => {
              onDelete(buttonData);
            }}
          /> */}
        </>
      ),
    });
  }
  return tableData;
};
