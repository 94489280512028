import React from "react"
import * as yup from "yup"
import CancelButton from "../../components/Button/CancelButton"
import SaveButton from "../../components/Button/SaveButton"
import GenericTextInput from "../../components/Input/GenericTextInput"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { Col, Form, Row } from "react-bootstrap"
import GenericSelectInput from "../../components/Input/GenericSelectInput"
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire"
import {
  getDistrictInfo,
  getProvinceInfo,
  getSubDistrictInfo,
} from "../../services/OnlineBookingServices"
import { editSettingData } from "../../services/SettingServices"

const schema = yup.object().shape({
  phone: yup.string().required("ห้ามว่าง"),
  lineId: yup.string().required("ห้ามว่าง"),
  address: yup.string().required("ห้ามว่าง"),
  street: yup.string().required("ห้ามว่าง"),
  subDistrict: yup.object().required("ห้ามว่าง"),
  district: yup.object().required("ห้ามว่าง"),
  province: yup.object().required("ห้ามว่าง"),
  postcode: yup.object().required("ห้ามว่าง"),
  //   amountInsiteArea: yup.number().required("ห้ามว่าง"),
  // amountOutsiteArea: yup.number().required("ห้ามว่าง"),
})

export default function EditSystemSettingForm({
  onCancel,
  onSubmitSuccess,
  phoneDefaultValue,
  lineIdDefaultValue,
  addressDefaultValue,
  streetDefaultValue,
  subDistrictDefaultValue,
  districtDefaultValue,
  provinceDefaultValue,
  postCodeDefaultValue,
  amountInsiteAreaDefaultValue,
  amountOutsiteAreaDefaultValue,
}) {
  const [firstLoad, setFirstLoad] = React.useState(true)
  const [provinceSelect, setProvinceSelect] = React.useState([])
  const [districtSelect, setDistrictSelect] = React.useState([])
  const [subDistrictSelect, setSubDistrictSelect] = React.useState([])
  const [postcodeSelect, setPostcodeSelect] = React.useState([])

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const prepareProvinceListForSelect = React.useCallback(
    (datas) => {
      let listsProvince = []
      for (const [idx, data] of datas.entries()) {
        let tempProvince = {
          id: idx,
          label: data.name,
          value: data.id,
        }
        listsProvince.push(tempProvince)
        if (firstLoad && data.id === provinceDefaultValue) {
          setValue("province", tempProvince, { shouldValidate: true })
        }
      }
      return listsProvince
    },
    [firstLoad, provinceDefaultValue, setValue]
  )

  const prepareDistrictListForSelect = React.useCallback(
    (datas) => {
      let listsDistrict = []
      for (const [idx, data] of datas.entries()) {
        let tempDistrict = {
          id: idx,
          label: data.name,
          value: data.id,
        }
        listsDistrict.push(tempDistrict)
        if (firstLoad && data.id === districtDefaultValue) {
          setValue("district", tempDistrict, { shouldValidate: true })
        }
      }
      return listsDistrict
    },
    [firstLoad, districtDefaultValue, setValue]
  )

  const prepareSubDistrictListForSelect = React.useCallback(
    (datas) => {
      let listsSubDistrict = []
      for (const [idx, data] of datas.entries()) {
        let tempSubDistrict = {
          id: idx,
          label: data.name,
          value: { id: data.id, postcode: data.postcode },
        }
        listsSubDistrict.push(tempSubDistrict)
        if (firstLoad && data.id === subDistrictDefaultValue) {
          setValue("subDistrict", tempSubDistrict, { shouldValidate: true })
          setValue(
            "postcode",
            {
              id: 1,
              label: data.postcode,
              value: data.postcode,
            },
            { shouldValidate: true }
          )
          setPostcodeSelect([
            {
              id: 1,
              label: data.postcode,
              value: data.postcode,
            },
          ])
        }
      }
      return listsSubDistrict
    },
    [firstLoad, subDistrictDefaultValue, setValue]
  )

  const loadProvince = React.useCallback(async () => {
    const { success, data, error } = await getProvinceInfo()
    if (success) {
      setProvinceSelect(prepareProvinceListForSelect(data.records))
    } else {
      ShowMessageAndExpire(
        "Load Province Data Failed",
        JSON.stringify(error?.message),
        "error"
      )
    }
  }, [prepareProvinceListForSelect])

  const loadDistrictSelect = React.useCallback(
    async (provinceId) => {
      const { success, data, error } = await getDistrictInfo("", {
        "filter.m_province_id": provinceId,
      })
      if (success) {
        setDistrictSelect(prepareDistrictListForSelect(data.records))
      } else {
        ShowMessageAndExpire(
          "Load District Data Failed",
          JSON.stringify(error?.message),
          "error"
        )
      }
    },
    [prepareDistrictListForSelect]
  )

  const loadSubDistrictSelect = React.useCallback(
    async (districtId) => {
      const { success, data, error } = await getSubDistrictInfo("", {
        "filter.m_district_id": districtId,
      })
      if (success) {
        setSubDistrictSelect(prepareSubDistrictListForSelect(data.records))
      } else {
        ShowMessageAndExpire(
          "Load Sub-District Data Failed",
          JSON.stringify(error?.message),
          "error"
        )
      }
    },
    [prepareSubDistrictListForSelect]
  )

  React.useEffect(() => {
    loadProvince()
    loadDistrictSelect(provinceDefaultValue)
    loadSubDistrictSelect(districtDefaultValue)
    setFirstLoad(false)
  }, [
    provinceDefaultValue,
    districtDefaultValue,
    loadProvince,
    loadDistrictSelect,
    loadSubDistrictSelect,
  ])

  const onSubmit = async (inputData) => {
    const { success, error } = await editSettingData({
      pathParams: 1,
      body: {
        phone: inputData.phone,
        acc_line: inputData.lineId,
        addressno: inputData.address,
        street: inputData.street,
        m_subdistrict_id: inputData.subDistrict?.value?.id,
        // amt_insite_area: inputData.amountInsiteArea,
      },
    })
    if (success) {
      onSubmitSuccess()
      ShowMessageAndExpire("Data updated successfully.", "", "success")
    } else {
      ShowMessageAndExpire(
        "Failed to update data.",
        JSON.stringify(error?.message),
        "error"
      )
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4>การตั้งค่าระบบ</h4>
        <hr />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="phone"
              label="การติดต่อ โทร."
              placeholder="การติดต่อ โทร."
              defaultValue={phoneDefaultValue}
              required
              error={errors.phone}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="lineId"
              label="การติดต่อ ไลน์"
              placeholder="การติดต่อ ไลน์"
              defaultValue={lineIdDefaultValue}
              required
              error={errors.lineId}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Col sm={2}>การติดต่อ ที่อยู่</Col>
          <Col sm={10}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="address"
                  label="Address"
                  placeholder="Address"
                  defaultValue={addressDefaultValue}
                  required
                  error={errors.address}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericTextInput
                  register={register}
                  name="street"
                  label="Street"
                  placeholder="Street"
                  defaultValue={streetDefaultValue}
                  required
                  error={errors.street}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="province"
                  label="Province"
                  placeholder="Province"
                  defaultValue={null}
                  required
                  options={provinceSelect}
                  onChange={(e) => {
                    if (e?.value) {
                      loadDistrictSelect(e.value)
                      setValue("postcode", null)
                    } else {
                      setDistrictSelect([])
                      setValue("district", null)
                      setSubDistrictSelect([])
                      setValue("subDistrict", null)
                      setPostcodeSelect([])
                      setValue("postcode", null)
                    }
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="district"
                  label="District"
                  placeholder="District"
                  defaultValue={null}
                  required
                  disabled={districtSelect.length === 0}
                  options={districtSelect}
                  onChange={(e) => {
                    if (e?.value) {
                      loadSubDistrictSelect(e.value)
                      setPostcodeSelect([])
                      setValue("postcode", null)
                    } else {
                      setSubDistrictSelect([])
                      setValue("subDistrict", null)
                      setPostcodeSelect([])
                      setValue("postcode", null)
                    }
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="subDistrict"
                  label="Sub-District"
                  placeholder="Sub-District"
                  defaultValue={null}
                  required
                  disabled={subDistrictSelect.length === 0}
                  options={subDistrictSelect}
                  onChange={(e) => {
                    if (e?.value) {
                      setValue(
                        "postcode",
                        {
                          id: 1,
                          label: e.value?.postcode,
                          value: e.value?.postcode,
                        },
                        { shouldValidate: true }
                      )
                      setPostcodeSelect([
                        {
                          id: 1,
                          label: e.value?.postcode,
                          value: e.value?.postcode,
                        },
                      ])
                    } else {
                      setPostcodeSelect([])
                      setValue("postcode", null)
                    }
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formBasicName">
                <GenericSelectInput
                  control={control}
                  name="postcode"
                  label="Postcode"
                  placeholder="Postcode"
                  required
                  disabled={postcodeSelect.length === 0}
                  options={postcodeSelect}
                />
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          {/* <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="amountInsiteArea"
              label="ราคาค่าบริการเขตเดียวกัน"
              placeholder="ราคาค่าบริการเขตเดียวกัน"
              defaultValue={amountInsiteAreaDefaultValue}
              required
              type="number"
              error={errors.amountInsiteArea}
            />
          </Form.Group> */}
          {/* <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="amountOutsiteArea"
              label="ราคาค่าบริการข้ามเขต"
              placeholder="ราคาค่าบริการข้ามเขต"
              defaultValue={amountOutsiteAreaDefaultValue}
              required
              type="number"
              error={errors.amountOutsiteArea}
            />
          </Form.Group> */}
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  )
}
