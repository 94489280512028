import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import GenericTextInput from "../../components/Input/GenericTextInput";
import SaveButton from "../../components/Button/SaveButton";
import CancelButton from "../../components/Button/CancelButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createMockUser, editMockUser } from "../../services/UserServices";
import { editSettingData } from "../../services/SettingServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";

const schema = yup.object().shape({
  startMinuteCallRate: yup.number().required("ห้ามว่าง"),
  overMinuteCallOverRate: yup.number().required("ห้ามว่าง"),
});

export default function EditCallServiceForm({
  onCancel,
  onSubmitSuccess,
  startMinuteCallRateDefaultValue,
  overMinuteCallOverRateDefaultValue,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (inputData) => {
    const { success, data, error } = await editSettingData({
      pathParams: 1,
      body: {
        start_mincall: inputData.startMinuteCallRate,
        extend_mincall: inputData.overMinuteCallOverRate,
      },
    });
    if (success) {
      onSubmitSuccess();
      ShowMessageAndExpire("Data updated successfully.", "", "success");
    } else {
      ShowMessageAndExpire(
        "Failed to update data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4>การตั้งค่าระบบโทร</h4>
        <hr />
        <Form.Group as={Row} className="mb-3" controlId="formBasicName">
          <GenericTextInput
            register={register}
            name="startMinuteCallRate"
            label="นาทีการโทรเริ่มต้น"
            placeholder="นาทีการโทรเริ่มต้น"
            defaultValue={startMinuteCallRateDefaultValue}
            required
            error={errors.startMinuteCallRate}
            viewFormat="inline"
          />
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formBasicName">
          <GenericTextInput
            register={register}
            name="overMinuteCallOverRate"
            label="นาทีการโทรเพิ่มเติมหากหมดเวลา"
            placeholder="นาทีการโทรเพิ่มเติมหากหมดเวลา"
            defaultValue={overMinuteCallOverRateDefaultValue}
            required
            error={errors.overMinuteCallOverRate}
            viewFormat="inline"
          />
        </Form.Group>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
