import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { set, useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { createUserMember } from "../../services/MemberServices";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import CreateButton from "../../components/Button/CreateButton";
import GenericDateTimePickerInput from "../../components/Input/GenericDateTimePickerInput";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import {
  createCommonAPI,
  getCommonAPI,
} from "../../services/commonAPIServices";

const schema = yup.object().shape({
  pet_name: yup.string().required("Pet Name is required"),
  owner_name: yup.object().required("Owner Name is required"),
  // owner_name: yup.string().when("owner_name", {
  //   is: "0",
  //   then: () => yup.object().required("Owner Name is required"),
  // }),
  owner_phone: yup.string().required("Owner Phone is required"),
  pet_category: yup.object().required("Pet Category is required"),
  date_birth: yup.string().required("Date of birth is required"),
  // last_service_date: yup.string().required("Last Service Date is required"),
  // remark: yup.string().required("Remark is required"),
});

export default function CreateNewPetForm({ onCancel, onCreateSuccess }) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [memberList, setMemberList] = React.useState([]);
  const [petCategoryList, setPetCategoryList] = React.useState([]);

  const handleChangeCustomerList = (event) => {
    setValue("owner_name", event, { shouldValidate: true });
    setValue("owner_phone", event.value.phone, { shouldValidate: true });
  };

  const handleChangePetsCategory = (event) => {
    setValue("pet_category", event, { shouldValidate: true });
  };

  const memberInfoList = React.useCallback(async () => {
    const url_api = "/member_info";
    const p_data = {};
    const { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setMemberList(prepareMemberInfoList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const petsCategoryList = React.useCallback(async () => {
    const url_api = "/m_pet_type/datatable";
    const p_data = {
      // "filter.id": "$in:1,2,3",
      "filter.status": '$not:0',
      
    };
    const { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setPetCategoryList(preparePetCategoryList(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  React.useEffect(() => {
    memberInfoList();
    petsCategoryList();
  }, [memberInfoList]);

  const prepareMemberInfoList = (datas) => {
    let listsMember = [];
    for (const data of datas) {
      const id = data.id;
      const name = data.name + " " + data.lname;
      const phone = data.phone;
      const email = data.email;
      let member = {
        id: id,
        label: id + " - " + name,
        value: {
          id: id,
          firstName: data.name,
          lastName: data.lname,
          phone: phone,
          email: email,
        },
      };
      listsMember.push(member);
    }
    return listsMember;
  };

  const preparePetCategoryList = (datas) => {
    let categoryList = [];
    for (const data of datas) {
      const id = data.id;
      const category = data.name;
      categoryList.push({
        id: id,
        label: id + " - " + category,
        value: {
          id: id,
          name: category,
        },
      });
    }
    return categoryList;
  };

  const onSubmit = async (inputData) => {
    // console.log("inputData ", inputData);
    const url_api = "/pet_info";
    const p_data = {
      name: inputData.pet_name,
      ownname:
        inputData.owner_name.value.firstName +
        " " +
        inputData.owner_name.value.lastName,
      member_info_id: inputData.owner_name.value.id,
      phone: inputData.owner_phone,
      m_pet_type_id: inputData.pet_category.id,
      brithday: new Date(inputData.date_birth).toLocaleString("sv"),
      last_service: new Date(inputData.last_service_date).toLocaleString("sv"),
      remark: inputData.remark,
    };
    console.log("p_data ", p_data);
    const { success, data, error } = await createCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      // onSubmitSuccess();
      onCreateSuccess();
      ShowMessageAndExpire("Data created successfully.", "Data Success", "success");
    } else {
      console.log(error);
      var errorMessages = [];
      if (typeof error.message === "string") {
        errorMessages.push(error.message);
      } else {
        for (var propertyName in error.message) {
          var errorMessage = error.message[propertyName];
          errorMessages.push(errorMessage);
        }
      }
      var concatenatedErrors = errorMessages.join("\n");

      ShowMessageAndExpire("Failed to create data.", concatenatedErrors, "error");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="pet_name"
              label="Pet Name"
              placeholder="Pet Name"
              defaultValue=""
              required
              error={errors.pet_name}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="owner_name"
              label="Owner Name"
              placeholder="Owner Name"
              // defaultValue={null}
              options={memberList}
              onChange={handleChangeCustomerList}
              required
              error={errors.owner_name}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="owner_phone"
              label="Owner Phone"
              placeholder="Owner Phone"
              defaultValue=""
              type="text"
              required
              error={errors.owner_phone}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="pet_category"
              label="Pet Category"
              placeholder="Pet Category"
              // defaultValue={null}
              options={petCategoryList}
              onChange={handleChangePetsCategory}
              required
              error={errors.pet_category}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="date_birth"
              label="Date of birth"
              placeholder="Date of birth"
              type="date"
              defaultValue={""}
              required
              error={errors.date_birth}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="last_service_date"
              label="Last Service Date"
              placeholder="Last Service Date"
              type="dateTime"
              defaultValue={""}
              // error={errors.last_service_date}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextAreaInput
              register={register}
              name="remark"
              label="Remark"
              placeholder="Remark"
              defaultValue={""}
              // error={errors.remark}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <CreateButton />
          </Col>
        </Row>
      </form>
    </>
  );
}
