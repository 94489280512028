import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getBookingTimeSchedule = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/get_booking_time/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    console.log(err.response);
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getLeaveTimeSchedule = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/get_leave_time/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    console.log(err.response);
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getAvailableTimeSchedule = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/get_available_time/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    console.log(err.response);
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getHospitalInfo = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/hospital_info/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    console.log(err.response);
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getDoctorInfo = async ({ params, pathParams = "" }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/doctor_info/" + pathParams,
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    console.log(err.response);
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockDoctorTimeSchedule = async ({
  page,
  pageSize,
  keyword,
  status,
  period,
  scheduleType,
}) => {
  let records = [];
  let total = 104;
  if (keyword) {
    total = Math.round(total / 2);
  }
  if (status === "booking") {
    total = Math.round(total / 3);
  }
  if (status === "cancel") {
    total = Math.round(total / 2);
  }
  if (period) {
    total = Math.round(total / 5);
  }
  if (scheduleType.includes("booking")) {
    total = Math.round(total / 7);
  }
  if (scheduleType.includes("leave")) {
    total = Math.round(total / 2);
  }
  if (scheduleType.includes("schedule")) {
    total = Math.round(total / 4);
  }

  const startId = page * pageSize - pageSize + 1;
  const endId = page * pageSize;
  let date = new Date();
  const month = [-1, 0, 1];
  for (let i = startId; i <= endId && i <= total; i++) {
    let randomMonth = date.getMonth() + month[Math.floor(Math.random() * 3)];
    let randomDateCalendar = new Date(
      date.getFullYear(),
      randomMonth,
      Math.random() * 28
    );
    records.push({
      dateTime: new Date(date.getFullYear(), randomMonth, Math.random() * 28),
      scheduleType:
        i % 3 === 1 ? "booking" : i % 3 === 2 ? "leave" : "schedules",
      doctorName: i + "doctorName",
      description: "dec test",
      status: "status",
      start: new Date(randomDateCalendar.setHours(3)),
      end: new Date(randomDateCalendar.setHours(5)),
    });
  }
  return {
    success: true,
    data: {
      total: total,
      currentPage: page,
      pageSize: pageSize,
      records: records,
    },
    error: null,
  };
};
