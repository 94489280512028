import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { set, useForm } from "react-hook-form";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import SaveButton from "../../components/Button/SaveButton";
import CancelButton from "../../components/Button/CancelButton";
import * as yup from "yup";
import { editCommonAPI, getCommonAPI } from "../../services/commonAPIServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";

const schema = yup.object().shape({
  firstname: yup.string().required("ชื่อห้ามว่าง"),
  lastname: yup.string().required("นามสกุลห้ามว่าง"),
  email: yup.string().email("อีเมลไม่ถูกต้อง").required("อีเมลห้ามว่าง"),
  telephone: yup.string().required("เบอร์โทรศัพท์ห้ามว่าง"),
});

export default function MemberEditGeneralForm({
  onCancel,
  onSubmitSuccess,
  memberId,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const loadMemberDetail = React.useCallback(async () => {
    let url_api = "/member_info/" + memberId;
    let p_data = {};
    let { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      console.log("loadMemberDetail", data.records);
      setValue("firstname", data.records.name);
      setValue("lastname", data.records.lname);
      setValue("telephone", data.records.phone);
      setValue("email", data.records.email);
      setValue("brithday", new Date(data.records?.brithday));
    } else {
      console.log(error);
    }
  }, [memberId, setValue]);

  React.useEffect(() => {
    loadMemberDetail();
  }, [loadMemberDetail]);

  const onSubmit = async (inputData) => {
    const url_api = "/member_info/" + memberId;
    const p_data = {
      name: inputData.firstname,
      lname: inputData.lastname,
      phone: inputData.telephone,
      email: inputData.email,
      brithday: inputData.brithday,
    };
    const { success, data, error } = await editCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      onSubmitSuccess();
      ShowMessageAndExpire("Data updated successfully.", "", "success");
    } else {
      console.log(error);
      var errorMessages = [];
      if (typeof error.message === "string") {
        errorMessages.push(error.message);
      } else {
        for (var propertyName in error.message) {
          var errorMessage = error.message[propertyName];
          errorMessages.push(errorMessage);
        }
      }
      var concatenatedErrors = errorMessages.join("\n");

      ShowMessageAndExpire("Failed to update data.", concatenatedErrors, "error");
    }
  };
  // console.log(birthDateDefaultValue);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Tab content for General */}
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="firstname"
              label="Name"
              placeholder="Name"
              required
              error={errors.firstname}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="lastname"
              label="Lastname"
              placeholder="Lastname"
              required
              error={errors.lastname}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="telephone"
              label="Phone"
              placeholder="Phone"
              required
              error={errors.telephone}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="email"
              label="Email Address"
              placeholder="Email Address"
              required
              error={errors.email}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="brithday"
              label="Date of birth"
              placeholder="Date of birth"
              type="date"
              required
              error={errors.brithday}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName"></Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
