import React from "react"
import { getNotification } from "../services/NotificationServices"
import { notificationColumn } from "../data/Columns"
import {
  Card,
  Col,
  Container,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap"
import Searching from "../components/Searching"
import SelectFilter from "../components/SelectFilter"
import {
  filterNotificationStatusOptions,
  filterNotificationTypeStatusOptions,
} from "../data/SelectOptions"
import GenericPagination from "../components/GenericPagination"
import Header from "../layouts/Header"
import PageHeaderCard from "../components/Card/PageHeaderCard"
import ReactTable from "../components/ReactTable"
import DetailButton from "../components/Button/DetailButton"
import CreateEditBroadcastNotificationForm from "../features/Form/CreateEditBroadcastNotificationForm"
import CreateEditSpecificNotificationForm from "../features/Form/CreateEditSpecificNotificationForm"
import { NotificationTypeFilter } from "../data/Filters"
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire"
import { NotificationStatus, NotificationType } from "../data/Enumerator"
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2"

const allNotificationStatus = null
const allNotificationType = null

export default function Notification() {
  const [total, setTotal] = React.useState(1)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(10)
  const [keyword, setKeyword] = React.useState("")
  const [notiType, setNotiType] = React.useState(allNotificationType)
  const [notiStatus, setNotiStatus] = React.useState(allNotificationStatus)
  const [startDate, setStartDate] = React.useState()
  const [endDate, setEndDate] = React.useState()
  const [tableData, setTableData] = React.useState([])
  const columnsData = notificationColumn

  const [isOpenForm, setIsOpenForm] = React.useState(false)
  const [isCreate, setIsCreate] = React.useState(false)
  const [notiTypeCreate, setNotiTypeCreate] = React.useState(
    NotificationTypeFilter.Broadcast
  )
  const [editData, setEditData] = React.useState()

  const loadNotification = React.useCallback(
    async (
      currentPage,
      pageSize,
      notiType,
      notiStatus,
      keyword,
      startDate,
      endDate
    ) => {
      let datefilter = null
      if (startDate && endDate) {
        datefilter = `$btw:${startDate} 00:00:00, ${endDate} 23:59:59`
      }
      const { success, data, error } = await getNotification({
        params: {
          page: currentPage,
          limit: pageSize,
          search: keyword,
          "filter.notice_user": notiType,
          "filter.status": notiStatus,
          "filter.schtime": datefilter,
        },
      })
      if (success) {
        setTotal(data.total)
        setTableData(
          prepareTableData(
            data.records,
            (currentPage - 1) * pageSize + 1,
            handleOpenEditForm
          )
        )
      } else {
        ShowMessageAndExpire(
          "Failed to load data.",
          JSON.stringify(error?.message),
          "error"
        )
      }
    },
    []
  )

  React.useEffect(() => {
    loadNotification(1, 10, allNotificationStatus, allNotificationType)
  }, [loadNotification])

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page)
      await loadNotification(
        page,
        pageSize,
        notiType,
        notiStatus,
        keyword,
        startDate,
        endDate
      )
    },
    [
      loadNotification,
      pageSize,
      notiType,
      notiStatus,
      keyword,
      startDate,
      endDate,
    ]
  )

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1)
      setPageSize(pageSize)
      await loadNotification(
        1,
        pageSize,
        notiType,
        notiStatus,
        keyword,
        startDate,
        endDate
      )
    },
    [loadNotification, notiType, notiStatus, keyword, startDate, endDate]
  )

  const loadPageOnNotiTypeChange = React.useCallback(
    async (notiType) => {
      setCurrentPage(1)
      setNotiType(notiType)
      await loadNotification(
        1,
        pageSize,
        notiType,
        notiStatus,
        keyword,
        startDate,
        endDate
      )
    },
    [loadNotification, pageSize, notiStatus, keyword, startDate, endDate]
  )

  const loadPageOnNotiStatusChange = React.useCallback(
    async (notiStatus) => {
      setCurrentPage(1)
      setNotiStatus(notiStatus)
      await loadNotification(
        1,
        pageSize,
        notiType,
        notiStatus,
        keyword,
        startDate,
        endDate
      )
    },
    [loadNotification, pageSize, notiType, keyword, startDate, endDate]
  )

  const openForm = () => {
    setIsOpenForm(true)
    setIsCreate(true)
    setNotiTypeCreate(NotificationTypeFilter.Broadcast)
  }

  const refreshPage = () => {
    setIsOpenForm(false)
    setIsCreate(false)
    setEditData(null)
    setCurrentPage(1)
    setKeyword("")
    setNotiTypeCreate(NotificationTypeFilter.Broadcast)
    setStartDate(null)
    setStartDate(null)
    setNotiStatus(allNotificationStatus)
    setNotiType(allNotificationType)
    loadNotification(
      1,
      pageSize,
      allNotificationType,
      allNotificationStatus,
      ""
    )
  }

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1)
      setKeyword(keyword)
      await loadNotification(
        1,
        pageSize,
        notiType,
        notiStatus,
        keyword,
        startDate,
        endDate
      )
    },
    [loadNotification, pageSize, notiType, notiStatus, startDate, endDate]
  )

  const handleSelectedDate = React.useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate)
      setEndDate(endDate)
      setCurrentPage(1)
      await loadNotification(
        1,
        pageSize,
        notiType,
        notiStatus,
        keyword,
        startDate,
        endDate
      )
    },
    [loadNotification, pageSize, notiType, notiStatus, keyword]
  )

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true)
    setIsCreate(false)
    setEditData(data)
    if (data?.notiType === "specific") {
      setNotiTypeCreate(NotificationTypeFilter.Specific)
    } else {
      setNotiTypeCreate(NotificationTypeFilter.Broadcast)
    }
  }

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              !isOpenForm
                ? "Notification"
                : isCreate
                ? "Create Notification"
                : "Notification Detail"
            }
            buttonEnable={!isOpenForm}
            buttonIcon="plus"
            buttonMessage="Create new Notification"
            onButtonOnClick={openForm}
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenForm ? (
                  <>
                    <Row className="mt-3">
                      <Col sm="3">
                        <ToggleButtonGroup
                          type="radio"
                          name="options"
                          defaultValue={
                            !isCreate &&
                            editData?.notiType ===
                              NotificationTypeFilter.Specific
                              ? NotificationTypeFilter.Specific
                              : NotificationTypeFilter.Broadcast
                          }
                          onChange={(notiType) => {
                            setNotiTypeCreate(notiType)
                          }}
                        >
                          <ToggleButton
                            id="tbg-radio-1"
                            value={NotificationTypeFilter.Broadcast}
                            variant="outline-primary"
                            disabled={!isCreate}
                            className="btn-fix-color"
                          >
                            Broadcast
                          </ToggleButton>
                          <ToggleButton
                            id="tbg-radio-2"
                            value={NotificationTypeFilter.Specific}
                            variant="outline-primary"
                            disabled={!isCreate}
                            className="btn-fix-color"
                          >
                            Specific
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      {notiTypeCreate === NotificationTypeFilter.Broadcast ? (
                        <CreateEditBroadcastNotificationForm
                          isCreate={isCreate}
                          onCancel={() => {
                            setIsOpenForm(false)
                            setEditData(null)
                          }}
                          onSubmitSuccess={refreshPage}
                          userGroupDefaultValue={
                            isCreate ? ["all"] : [editData?.notiType]
                          }
                          sendDatetimeDefaultValue={
                            !isCreate && new Date(editData?.notiDate).getDate()
                              ? new Date(editData?.notiDate)
                              : null
                          }
                          notiContentDefaultValue={editData?.notiContent}
                          recordDateDefaultValue={editData?.notiSuccessDate}
                        />
                      ) : (
                        <>
                          {notiTypeCreate ===
                          NotificationTypeFilter.Specific ? (
                            <CreateEditSpecificNotificationForm
                              isCreate={isCreate}
                              onCancel={() => {
                                setIsOpenForm(false)
                                setEditData(null)
                              }}
                              onSubmitSuccess={refreshPage}
                              namesDefaultValue={editData?.userIds}
                              sendDatetimeDefaultValue={
                                !isCreate &&
                                new Date(editData?.notiDate).getDate()
                                  ? new Date(editData?.notiDate)
                                  : null
                              }
                              notiContentDefaultValue={editData?.notiContent}
                              recordDateDefaultValue={editData?.notiSuccessDate}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder="ค้นหา ข้อความที่ส่ง"
                          onSearch={handleSearch}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="Type"
                          placeholder={"Type"}
                          defaultValue={filterNotificationTypeStatusOptions.find(
                            (e) => e.value === allNotificationType
                          )}
                          value={filterNotificationTypeStatusOptions.find(
                            (e) => e.value === notiType
                          )}
                          options={filterNotificationTypeStatusOptions}
                          onChange={loadPageOnNotiTypeChange}
                        />
                        <SelectFilter
                          label="Status"
                          placeholder={"Status"}
                          defaultValue={filterNotificationStatusOptions.find(
                            (e) => e.value === allNotificationStatus
                          )}
                          value={filterNotificationStatusOptions.find(
                            (e) => e.value === notiStatus
                          )}
                          options={filterNotificationStatusOptions}
                          onChange={loadPageOnNotiStatusChange}
                        />
                        <ReactDateTimeRangePicker2
                          onSelected={handleSelectedDate}
                          defaultStart={startDate ? new Date(startDate) : null}
                          defaultEnd={endDate ? new Date(endDate) : null}
                          placeholder="เลือกวันที่ส่ง"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}

const prepareTableData = (datas, startNo, onEdit) => {
  let tableData = []
  for (const [idx, data] of datas.entries()) {
    let tempNotiType
    let tempNotiTypeButton
    if (data.notice_user === NotificationType.AllUser) {
      tempNotiType = "Broadcast - ส่งทุกคน"
      tempNotiTypeButton = "all"
    } else if (data.notice_user === NotificationType.LastActiveUser) {
      tempNotiType = "Broadcast - เฉพาะผู้ใช้งานอยู่ (90 วันล่าสุด)"
      tempNotiTypeButton = "lastActiveUser"
    } else if (data.notice_user === NotificationType.SpecificUser) {
      tempNotiType = "Specific - เลือกส่งรายบุคคล"
      tempNotiTypeButton = "specific"
    } else {
      tempNotiType = "-"
      tempNotiTypeButton = ""
    }
    let tempStatus
    if (data.status === NotificationStatus.Inactive) {
      tempStatus = "Inactive"
    } else if (data.status === NotificationStatus.Active) {
      tempStatus = "Active"
    } else if (data.status === NotificationStatus.Pending) {
      tempStatus = "Pending"
    } else if (data.status === NotificationStatus.Complete) {
      tempStatus = "Send Complete"
    } else if (data.status === NotificationStatus.Cancel) {
      tempStatus = "Cancel"
    } else {
      tempStatus = "-"
    }
    const tempTableData = {
      no: startNo + idx,
      id: data.id,
      notiType: tempNotiType,
      notiContent: data.message,
      notiDate: data.schtime,
      notiStatus: tempStatus,
      notiSuccessDate: data.create_date,
      totalSend: data.temp,
    }
    const buttonData = {
      id: data.id,
      notiType: tempNotiTypeButton,
      notiContent: data.message,
      notiDate: data.schtime,
      notiStatus: tempStatus,
      notiSuccessDate: data.create_date,
      totalSend: data.temp,
      userIds: data.user_ids,
    }
    tableData.push({
      ...tempTableData,
      detailButton: (
        <>
          <DetailButton
            onClick={() => {
              onEdit(buttonData)
            }}
          />
        </>
      ),
    })
  }
  return tableData
}
