import React from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function PaymentResult() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [titleResultMessage, setTitleResultMessage] = React.useState("");
  const [subtitleResultMessage, setSubtitleResultMessage] = React.useState("");

  React.useEffect(() => {
    setIsLoading(true);
    const searchParams = new URLSearchParams(window.location.search);
    const status = searchParams.get("status");
    if (status === "successful") {
      setTitleResultMessage(`Payment success`);
    } else if (status) {
      setTitleResultMessage(`Payment failed`);
      setSubtitleResultMessage(`status : ${status}`);
    } else {
      setTitleResultMessage(`Payment failed`);
      setSubtitleResultMessage(`Cannot get payment status`);
    }
    setIsLoading(false);
  }, []);

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo">
            Pet Dreams
          </Link>
        </Container>
      </div>

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner
            animation="border"
            variant="info"
            size="lg"
            className="me-3"
          />
          <br/><h3>Loading...</h3>
        </div>
      ) : (
        <>
          <div className="content">
            <Container>
              <Card className="card-auth">
                <Card.Body className="text-center">
                  {titleResultMessage && (
                    <>
                      <Card.Title>{titleResultMessage}</Card.Title>
                      <Card.Text>{subtitleResultMessage}</Card.Text>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Container>
          </div>
        </>
      )}
    </div>
  );
}
