import React from "react";
import * as yup from "yup";
import { Col, Form, Row } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import {
  createMockNotification,
  createNotification,
} from "../../services/NotificationServices";
import GenericTextAreaInput from "../../components/Input/GenericTextAreaInput";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import { getAdminUser } from "../../services/UserServices";

const schema = yup.object().shape({
  names: yup.array().required("ห้ามว่าง"),
  sendDatetime: yup.string().required("ห้ามว่าง"),
  notiContent: yup.string().required("ห้ามว่าง"),
});

export default function CreateEditSpecificNotificationForm({
  isCreate,
  onCancel,
  onSubmitSuccess,
  namesDefaultValue = [],
  sendDatetimeDefaultValue,
  notiContentDefaultValue,
  recordDateDefaultValue,
}) {
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [adminUserSelect, setAdminUserSelect] = React.useState([]);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (inputData) => {
    const { success, data, error } = await createNotification({
      body: {
        notice_user: 2,
        schtime: new Date(inputData?.sendDatetime).toLocaleString("sv"),
        message: inputData?.notiContent,
        user_ids: inputData?.names.map((e) => e.value),
      },
    });
    if (success) {
      onSubmitSuccess();
      ShowMessageAndExpire("Data created successfully.", "", "success");
    } else {
      ShowMessageAndExpire(
        "Failed to create data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
  };

  const prepareAdminUserForSelect = React.useCallback(
    (datas, firstLoad = false) => {
      let listAdminUser = [];
      let tempNamesValue = [];
      for (const [idx, data] of datas.entries()) {
        let tempAdminUser = {
          id: idx,
          label: `${data.name} ${data.lname}`,
          value: data.id,
        };
        listAdminUser.push(tempAdminUser);
        if (firstLoad && namesDefaultValue.includes(data.id)) {
          tempNamesValue.push(tempAdminUser);
        }
      }
      if (firstLoad) {
        setValue("names", tempNamesValue, { shouldValidate: true });
      }
      return listAdminUser;
    },
    [namesDefaultValue, setValue]
  );

  const loadAdminUserSelect = React.useCallback(
    async (firstLoad = false) => {
      const { success, data, error } = await getAdminUser({
        params: {
          "filter.user_type": "$in:1,2",
          select: "name,lname,user_type",
        },
      });
      if (success) {
        setAdminUserSelect(prepareAdminUserForSelect(data.records, firstLoad));
      } else {
        ShowMessageAndExpire(
          "Load Admin User Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [prepareAdminUserForSelect]
  );

  React.useEffect(() => {
    loadAdminUserSelect(firstLoad);
    setFirstLoad(false);
  }, []);

  return (
    <>
      <h4>Specific</h4>
      <hr />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="names"
              label="ชื่อ-นามสกุล"
              placeholder="ชื่อ-นามสกุล"
              defaultValue={namesDefaultValue}
              required
              options={adminUserSelect}
              isMulti
              disabled={!isCreate}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="sendDatetime"
              label="กำหนดเวลาส่ง"
              placeholder="กำหนดเวลาส่ง"
              type="dateTime"
              defaultValue={sendDatetimeDefaultValue}
              disabled={!isCreate}
              required
              error={errors.sendDatetime}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextAreaInput
              register={register}
              name="notiContent"
              label="Message"
              placeholder="Message"
              defaultValue={notiContentDefaultValue}
              required
              error={errors.notiContent}
              disabled={!isCreate}
            />
          </Form.Group>
        </Row>
        {!isCreate && (
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                name="datetime"
                label="Record Date"
                placeholder="-"
                defaultValue={recordDateDefaultValue}
                readOnly
                plaintext
              />
            </Form.Group>
          </Row>
        )}
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          {isCreate && (
            <Col style={{ display: "flex", justifyContent: "end" }}>
              <SaveButton />
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
}
