import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  createVanInfo,
  deletePhotoSetEmsVan,
  editVanInfo,
  getHospitalInfo,
  getPhotoSetEmsVan,
} from "../../services/EmsVanServices";
import { Button, Col, Form, Row } from "react-bootstrap";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import {
  activeStatusOptions,
  emsVanTypeOptions,
} from "../../data/SelectOptions";
import GenericTextInput from "../../components/Input/GenericTextInput";
import DeleteButton from "../../components/Button/DeleteButton";
import { photoSetColumn } from "../../data/Columns";

import ReactTable from "../../components/ReactTable";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";
import { getCarTypeMasterData } from "../../services/SettingServices";
import AddEmsVanPhotoSetModal from "../Modal/AddEmsVanPhotoSetModal";
import EditButton from "../../components/Button/EditButton";
import {
  emailInvalidMessage,
  emailRegExp,
  phoneInvalidMessage,
  phoneRegExp,
} from "../../data/Config";
import {
  getDistrictInfo,
  getProvinceInfo,
} from "../../services/OnlineBookingServices";

const schema = yup.object().shape({
  carType: yup.object().required("ห้ามว่าง"),
  status: yup.object().required("ห้ามว่าง"),
  licensePlate: yup.string().required("ห้ามว่าง"),
  affiliation: yup.object().required("ห้ามว่าง"),
  district: yup.object().required("ห้ามว่าง"),
  info: yup.string().required("ห้ามว่าง"),
  email: yup
    .string()
    .matches(emailRegExp, emailInvalidMessage)
    .required("ห้ามว่าง"),
  phone: yup
    .string()
    .matches(phoneRegExp, phoneInvalidMessage)
    .required("ห้ามว่าง"),
  lineId: yup.string().required("ห้ามว่าง"),
  amtInsiteArea: yup.number().required("ห้ามว่าง").typeError("ต้องเป็นตัวเลข"),
  amtOutsiteArea: yup.number().required("ห้ามว่าง").typeError("ต้องเป็นตัวเลข"),
  equipment: yup.array().min(1, "ห้ามว่าง").required("ห้ามว่าง"),
  // image: yup
  //   .mixed()
  //   .test("fileType", "ห้ามว่าง", (value) => {
  //     return value[0] ? true : false;
  //   })
  //   .test("fileType", "ไฟล์ผิดประเภท", (value) => {
  //     if (!value) {
  //       return false;
  //     }
  //     const supportedFormats = ["image/jpeg", "image/png"];
  //     return s
});

export default function CreateEditEmsVanForm({
  isCreate,
  onCancel,
  onSubmitSuccess,
  carTypeDefaultValue,
  statusDefaultValue,
  licensePlateDefaultValue,
  affiliationDefaultValue,
  provinceDefaultValue,
  districtDefaultValue,
  infoDefaultValue,
  emailDefaultValue,
  phoneDefaultValue,
  lineIdDefaultValue,
  equipmentDefaultValue = [],
  imageDefaultValue,
  amtinsiteareaDefaultValue,
  amtoutsiteareaDefaultValue,
  id,
}) {
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [isCreatePhotoSet, setIsCreatePhotoSet] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();

  const [tableData, setTableData] = React.useState([]);
  const [hospitalSelect, setHospitalSelect] = React.useState([]);
  const [equipmentSelect, setEquipmentSelect] = React.useState([]);
  const [provinceSelect, setProvinceSelect] = React.useState([]);
  const [districtSelect, setDistrictSelect] = React.useState([]);

  const [amtInsiteArea, setAmtInsiteArea] = React.useState([]);
  const [amtOutsiteArea, setAmtOutsiteArea] = React.useState([]);

  const columnsData = photoSetColumn;

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const preparePhotoSetForTable = React.useCallback(
    (datas, onEdit, onDelete) => {
      let photoSet = [];
      for (const [idx, data] of datas.entries()) {
        photoSet.push({
          id: idx + 1,
          image: (
            <img
              src={data.van_picture_path}
              alt="Preview"
              style={{
                width: "150px",
              }}
            />
          ),
          description: data.description,
          order: data.order,
          deleteButton: (
            <>
              <EditButton
                onClick={() => {
                  onEdit({
                    id: data.id,
                    image: data.van_picture_path,
                    description: data.description,
                    order: data.order,
                  });
                }}
              />
              <DeleteButton
                onClick={() => {
                  onDelete({ id: data.id });
                }}
              />
            </>
          ),
        });
      }
      return photoSet;
    },
    []
  );

  const loadPhotoSet = React.useCallback(async () => {
    const { success, data, error } = await getPhotoSetEmsVan({
      pathParams: "",
      params: { "filter.van_info_id": id },
    });
    if (success) {
      setTableData(
        preparePhotoSetForTable(
          data.records,
          handleOpenEditForm,
          handleOpenDeleteConfirm
        )
      );
    } else {
      ShowMessageAndExpire(
        "Load Photo Set Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [id, preparePhotoSetForTable]);

  const handleOpenAddForm = () => {
    setIsCreatePhotoSet(true);
    setShowModal(true);
  };

  const handleOpenEditForm = (data) => {
    setIsCreatePhotoSet(false);
    setEditData(data);
    setShowModal(true);
  };

  const handleOpenDeleteConfirm = (data) => {
    setIsCreatePhotoSet(true);
    setIsDelete(true);
    setDeleteData(data);
  };

  const refresh = () => {
    setShowModal(false);
    setEditData(null);
    loadPhotoSet();
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDeletePhotoSet = React.useCallback(
    async (id) => {
      const { success, error } = await deletePhotoSetEmsVan({
        pathParams: id,
      });
      if (success) {
        ShowMessageAndExpire("Data deleted successfully.", "", "success");
        handleCloseDeleteConfirm();
        await loadPhotoSet();
      } else {
        handleCloseDeleteConfirm();
        ShowMessageAndExpire(
          "Failed to delete data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [loadPhotoSet]
  );

  const onSubmit = async (inputData) => {
    if (isCreate) {
      const { success, error } = await createVanInfo({
        body: {
          van_type: inputData?.carType?.value,
          status: inputData?.status?.value,
          license_place: inputData?.licensePlate,
          beunder: inputData?.affiliation?.value,
          m_district_id: inputData?.district?.value,
          remark: inputData?.info,
          email: inputData?.email,
          phone: inputData?.phone,
          acc_line: inputData?.lineId,
          m_van_equipment_ids: inputData?.equipment,
          amt_insite_area: parseFloat(inputData?.amtInsiteArea),
          amt_outsite_area: parseFloat(inputData?.amtOutsiteArea),
        },
      });
      if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data created successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Failed to create data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    } else {
      const { success, error } = await editVanInfo({
        pathParams: id,
        body: {
          van_type: inputData?.carType?.value,
          status: inputData?.status?.value,
          license_place: inputData?.licensePlate,
          beunder: inputData?.affiliation?.value,
          m_district_id: inputData?.district?.value,
          remark: inputData?.info,
          email: inputData?.email,
          phone: inputData?.phone,
          acc_line: inputData?.lineId,
          m_van_equipment_ids: inputData?.equipment,
          amt_insite_area: parseFloat(inputData?.amtInsiteArea),
          amt_outsite_area: parseFloat(inputData?.amtOutsiteArea),
        },
      });
      if (success) {
        onSubmitSuccess();
        ShowMessageAndExpire("Data updated successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    }
  };

  const prepareHospitalListForSelect = React.useCallback(
    (datas) => {
      const defaultPrivate = {
        id: 0,
        label: "Private",
        value: 0,
      };
      let hospitals = [defaultPrivate];
      if (affiliationDefaultValue === 0) {
        setValue("affiliation", defaultPrivate, { shouldValidate: true });
      }
      for (const [idx, data] of datas.entries()) {
        let tempHospital = {
          id: idx + 1,
          label: data.name,
          value: data.id,
        };
        hospitals.push(tempHospital);
        if (data.id === affiliationDefaultValue) {
          setValue("affiliation", tempHospital, { shouldValidate: true });
        }
      }
      return hospitals;
    },
    [affiliationDefaultValue, setValue]
  );

  const loadHospitalSelect = React.useCallback(async () => {
    const { success, data, error } = await getHospitalInfo({
      pathParams: "",
      params: { select: "id,name" },
    });
    if (success) {
      setHospitalSelect(prepareHospitalListForSelect(data.records));
    } else {
      ShowMessageAndExpire(
        "Load Hospital Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [prepareHospitalListForSelect]);

  const prepareVanEquipmentForSelect = React.useCallback(
    (datas) => {
      let equipments = [];
      let tempCurrentValue = [];
      for (const [idx, equipment] of datas.entries()) {
        if (equipment.status !== 0) {
          equipments.push({
            id: idx,
            label: equipment.name,
            value: equipment.id,
          });
          if (equipmentDefaultValue.includes(equipment?.id)) {
            tempCurrentValue.push(equipment.id.toString());
          }
        }
      }
      if (tempCurrentValue) {
        setValue("equipment", tempCurrentValue);
      }
      return equipments;
      // if put equipmentDefaultValue in depend will infinity loop
    },
    [setValue]
  );

  const loadVanEquipmentSelect = React.useCallback(async () => {
    const { success, data, error } = await getCarTypeMasterData({
      pathParams: "",
      params: { select: "id,name,status" },
    });
    if (success) {
      setEquipmentSelect(prepareVanEquipmentForSelect(data.records));
    } else {
      ShowMessageAndExpire(
        "Load Equipment Data Failed",
        JSON.stringify(error?.message),
        "error"
      );
    }
  }, [prepareVanEquipmentForSelect]);

  const prepareProvinceListForSelect = React.useCallback(
    (datas, provinceDefault) => {
      let listsProvince = [];
      for (const [idx, data] of datas.entries()) {
        let tempProvince = {
          id: idx,
          label: data.name,
          value: data.id,
        };
        listsProvince.push(tempProvince);
        if (
          (firstLoad && data.id === provinceDefaultValue) ||
          data.id === provinceDefault
        ) {
          setValue("province", tempProvince, { shouldValidate: true });
        }
      }
      return listsProvince;
    },
    [firstLoad, provinceDefaultValue, setValue]
  );

  const prepareDistrictListForSelect = React.useCallback(
    (datas) => {
      let listsDistrict = [];
      for (const [idx, data] of datas.entries()) {
        let tempDistrict = {
          id: idx,
          label: data.name,
          value: data.id,
        };
        listsDistrict.push(tempDistrict);
        if (firstLoad && data.id === districtDefaultValue) {
          setValue("district", tempDistrict, { shouldValidate: true });
        }
      }
      return listsDistrict;
    },
    [firstLoad, districtDefaultValue, setValue]
  );

  const loadDistrictSelect = React.useCallback(
    async (provinceId) => {
      const { success, data, error } = await getDistrictInfo("", {
        "filter.m_province_id": provinceId,
      });
      if (success) {
        setDistrictSelect(prepareDistrictListForSelect(data.records));
      } else {
        ShowMessageAndExpire(
          "Load District Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [prepareDistrictListForSelect]
  );

  const loadProvince = React.useCallback(
    async (provinceDefault) => {
      const { success, data, error } = await getProvinceInfo();
      if (success) {
        setProvinceSelect(
          prepareProvinceListForSelect(data.records, provinceDefault)
        );
      } else {
        ShowMessageAndExpire(
          "Load Province Data Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    [prepareProvinceListForSelect]
  );

  React.useEffect(() => {
    loadHospitalSelect();
    loadVanEquipmentSelect();
    loadProvince();
    loadDistrictSelect(provinceDefaultValue);
    if (!isCreate) {
      loadPhotoSet();
    }
    setFirstLoad(false);
  }, [
    isCreate,
    isCreate,
    loadHospitalSelect,
    loadVanEquipmentSelect,
    loadProvince,
    loadDistrictSelect,
    loadPhotoSet,
  ]);

  return (
    <>
      {showModal && (
        <AddEmsVanPhotoSetModal
          isCreate={isCreatePhotoSet}
          show={showModal}
          onClose={refresh}
          onSave={refresh}
          emsVanId={id}
          vanPictureId={editData?.id}
          descriptionDefaultValue={editData?.description}
          orderDefaultValue={editData?.order}
          imageDefaultValue={editData?.image}
        />
      )}
      <DeleteConfirmModal
        show={isDelete}
        onClose={handleCloseDeleteConfirm}
        onComfirm={handleDeletePhotoSet}
        deleteRecord={deleteData?.id}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these record?"}</p>
            <p>{`id = ${deleteData?.id}`}</p>
          </>
        }
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="carType"
              label="ประเภทรถ"
              placeholder="ประเภทรถ"
              defaultValue={carTypeDefaultValue}
              required
              options={emsVanTypeOptions}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="status"
              label="สถานะ"
              placeholder="สถานะ"
              defaultValue={statusDefaultValue}
              required
              options={activeStatusOptions}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="licensePlate"
              label="ทะเบียนรถ"
              placeholder="ทะเบียนรถ"
              defaultValue={licensePlateDefaultValue}
              required
              error={errors.licensePlate}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="affiliation"
              label="สังกัด"
              placeholder="สังกัด"
              defaultValue={affiliationDefaultValue}
              required
              options={hospitalSelect}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="province"
              label="Province"
              placeholder="Province"
              defaultValue={null}
              required
              options={provinceSelect}
              onChange={(e) => {
                if (e?.value) {
                  loadDistrictSelect(e.value);
                  setValue("district", null);
                } else {
                  setDistrictSelect([]);
                  setValue("district", null);
                }
              }}
              disabled={provinceSelect.length === 0}
              readOnly
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="district"
              label="District"
              placeholder="District"
              defaultValue={null}
              required
              disabled={districtSelect.length === 0}
              options={districtSelect}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="info"
              label="รายละเอียด"
              placeholder="รายละเอียด"
              as="textarea"
              defaultValue={infoDefaultValue}
              error={errors.info}
              required
            />
          </Form.Group>
          <Col></Col>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="email"
              label="อีเมล"
              placeholder="อีเมล"
              defaultValue={emailDefaultValue}
              // required
              error={errors.email}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="phone"
              label="เบอร์ติดต่อ"
              placeholder="เบอร์ติดต่อ"
              defaultValue={phoneDefaultValue}
              required
              error={errors.phone}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="lineId"
              label="Line"
              placeholder="Line"
              defaultValue={lineIdDefaultValue}
              required
              error={errors.lineId}
            />
          </Form.Group>
          <Col></Col>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="amtInsiteArea"
              label="ค่าบริการเขตเดียวกัน"
              placeholder="ค่าบริการเขตเดียวกัน"
              defaultValue={amtinsiteareaDefaultValue}
              required
              error={errors.amtInsiteArea}
            />
          </Form.Group>
          <Col></Col>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="amtOutsiteArea"
              label="ค่าบริการข้ามเขต"
              placeholder="ค่าบริการข้ามเขต"
              defaultValue={amtoutsiteareaDefaultValue}
              required
              error={errors.amtOutsiteArea}
            />
          </Form.Group>
          <Col></Col>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericRadioInput
              register={register}
              name="equipment"
              label="ชื่อเครื่องมือ"
              placeholder="ชื่อเครื่องมือ"
              defaultChecked={equipmentDefaultValue}
              error={errors.equipment}
              radios={equipmentSelect}
              type="checkbox"
            />
          </Form.Group>
        </Row>
        {!isCreate && (
          <>
            <Row>
              <Col>
                รายการรูปภาพ
                <Button
                  variant="success"
                  onClick={() => handleOpenAddForm()}
                  className="ms-3"
                >
                  {" "}
                  Upload
                </Button>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="mx-3">
                <ReactTable columns={columnsData} data={tableData} />
              </Col>
            </Row>
          </>
        )}
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
