import React from "react";
import Select from "react-select";

export default function SelectFilter({
  label,
  placeholder,
  defaultValue,
  value,
  options,
  disabled,
  onChange,
  isMulti,
  width = 10,
}) {
  return (
    <>
      <div className="mx-3" style={{ display: "flex", alignItems: "center" }}>
        <div className="me-2">{label}</div>
        <div style={{ width: `${width}rem` }}>
          <Select
            placeholder={placeholder}
            defaultValue={defaultValue}
            isDisabled={disabled}
            value={value}
            options={options}
            onChange={(e) => {
              if (onChange) {
                if (isMulti) {
                  onChange(e);
                } else {
                  onChange(e.value);
                }
              }
            }}
            isMulti={isMulti}
          />
        </div>
      </div>
    </>
  );
}
