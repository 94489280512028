import React from "react";
import feather from "feather-icons/dist/feather";
import GenericButton from "./GenericButton";
import { checkPermissionButton } from "../../utils/Help";

export default function CreateButton(props) {
  React.useEffect(() => {
    feather.replace();
  }, []);
    if(checkPermissionButton(props?.screenId, "f_add")){
        return (
            <GenericButton variant="primary" type="submit" {...props}>
            <i data-feather="plus" />
            Create
            </GenericButton>
        );
    }
    return ;
}
