import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getUserMember = async ({ page, pageSize, keyword }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/member_info/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          "filter._ignore_rel": 1,
          limit: pageSize,
          keyword,
        },
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const createUserMember = async ({
  name,
  lname,
  phone,
  email,
  brithday,
}) => {
  try {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + "/member_info",
      { name, lname, phone, email, brithday },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const editUserMember = async ({
  id,
  name,
  lname,
  phone,
  email,
  brithday,
}) => {
  try {
    const res = await axios.put(
      process.env.REACT_APP_API_URL + "/member_info/" + id,
      { name, lname, phone, email, brithday },
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const deleteUserMember = async ({ id }) => {
  try {
    const res = await axios.delete(
      process.env.REACT_APP_API_URL + "/member_info/" + id,
      {
        headers: await getDefaultRequestHeaders(true),
      }
    );
    return {
      success: true,
      data: res.data.data,
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockUserMember = async ({ page, pageSize, keyword }) => {
  let records = [];
  let total = 20;
  if (keyword) {
    total = Math.round(total / 2);
  }
  const startId = page * pageSize - pageSize + 1;
  const endId = page * pageSize;
  for (let i = startId; i <= endId && i <= total; i++) {
    records.push({
      memberId: i,
      memberFirstName: `นายสมใจ`,
      memberLastLame: `สบายดี ${i}`,
      phone: `0869383331${i}`,
      register_data: new Date(),
      last_service_date: new Date(),
      memberEmail: `No.${i}@gmail.com`,
      memberbirthDate: new Date(),
    });
  }
  return {
    success: true,
    data: {
      total: total,
      currentPage: page,
      pageSize: pageSize,
      records: records,
    },
    error: null,
  };
};

export const editMockUserMember = async ({ memberId }) => {
  return {
    success: true,
    data: { memberId },
    error: null,
  };
};

export const deleteMockUserMember = async ({ memberId }) => {
  return {
    success: true,
    data: { memberId: memberId },
    error: null,
  };
};

export const getShipping = async ({ page, pageSize, keyword, memberId }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/address/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          "filter.any_id": memberId,
          "filter._relations": "rel_m_subdistrict_id",
          "filter.user_type": 0,
          page,
          keyword,
        },
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockShipping = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 14,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          no: "1",
          name_surename: "นายสมใจ สบายดี",
          phone: "08693833311",
          address: "123/4 งามวงศ์วาน 3 บางเขน เมืองนนทบุรี นนทบุรี 11000",
          is_promary: "Primary",
        },
        {
          no: "2",
          name_surename: "นางสาวประภา สะสมทรัพย์",
          phone: "08693833312",
          address:
            "555/17 พระราม 9 แขวง ห้วยขวาง เขต ห้วยขวาง กรุงเทพมหานคร 10310",
          is_promary: "-",
        },
      ],
    },
    error: null,
  };
};

export const createMockShipping = async ({
  name_surename,
  phone,
  address,
  is_promary,
}) => {
  return {
    success: true,
    data: {},
    error: null,
  };
};

export const getBooking = async ({ page, pageSize, keyword, memberId }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/booking/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          "filter.member_info_id": memberId,
          "filter._relations": "rel_m_heal_id,rel_doctor_info_id",
          page,
          keyword,
        },
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockBooking = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 14,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          no: "1",
          case_id: "23030093",
          case_title: "ชิโร - ผ่าตัดเร่งด่วน",
          booking_date: "25 Jan 2023 13:21",
          schedule_date: "25 May 2023 13:21",
          status: "Booking",
          owner: "70898 นพ.มานพ โรงพยาบาล หลักสี่",
          create_by: "@แอดมิน A",
        },
        {
          no: "2",
          case_id: "23030093",
          case_title: "ฮาจิ",
          booking_date: "25 Jan 2023 13:21",
          schedule_date: "25 May 2023 13:21",
          status: "Complete",
          owner: "70898 นพ.มานพ โรงพยาบาล หลักสี่",
          create_by: "@แอดมิน A",
        },
        {
          no: "3",
          case_id: "23030093",
          case_title: "ปุ้มปุ้ย",
          booking_date: "25 Jan 2023 13:21",
          schedule_date: "25 May 2023 13:21",
          status: "Canceled",
          owner: "70898 นพ.มานพ โรงพยาบาล หลักสี่",
          create_by: "@แอดมิน A",
        },
      ],
    },
    error: null,
  };
};

export const createMockBooking = async ({
  case_id,
  case_title,
  booking_date,
  schedule_date,
  status,
  owner,
  create_by,
}) => {
  return {
    success: true,
    data: {},
    error: null,
  };
};

export const getPurchased = async ({ page, pageSize, keyword, memberId }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/booking/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          "filter.member_info_id": memberId,
          page,
          keyword,
        },
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockPurchased = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 14,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          no: "DEMO 1",
          purchase_id: "11520004D",
          ship_to:
            "นาย มานพ เทส 123/4 งามวงศ์วาน 3 บางเขน เมืองนนทบุรี นนทบุรี 11000 090-xxx-xxxx",
          product_code: "PT2:3|DRG05:1",
          value: "300 ฿",
          shipping_fee: "50 ฿",
          purchase_status: "Pending",
          remark: "",
        },
        {
          no: "DEMO 2",
          purchase_id: "11520004D",
          ship_to:
            "นาย มานพ เทส 123/4 งามวงศ์วาน 3 บางเขน เมืองนนทบุรี นนทบุรี 11000 090-xxx-xxxx",
          product_code: "PT2:3|DRG05:1",
          value: "300 ฿",
          shipping_fee: "50 ฿",
          purchase_status: "Paid",
          remark: "",
        },
        {
          no: "DEMO 3",
          purchase_id: "11520004D",
          ship_to:
            "นาย มานพ เทส 123/4 งามวงศ์วาน 3 บางเขน เมืองนนทบุรี นนทบุรี 11000 090-xxx-xxxx",
          product_code: "PT2:3|DRG05:1",
          value: "300 ฿",
          shipping_fee: "50 ฿",
          purchase_status: "Canceled",
          remark: "",
        },
        {
          no: "DEMO 4",
          purchase_id: "11520004D",
          ship_to:
            "นาย มานพ เทส 123/4 งามวงศ์วาน 3 บางเขน เมืองนนทบุรี นนทบุรี 11000 090-xxx-xxxx",
          product_code: "PT2:3|DRG05:1",
          value: "300 ฿",
          shipping_fee: "50 ฿",
          purchase_status: "Shipping",
          remark: "",
        },
        {
          no: "DEMO 5",
          purchase_id: "11520004D",
          ship_to:
            "นาย มานพ เทส 123/4 งามวงศ์วาน 3 บางเขน เมืองนนทบุรี นนทบุรี 11000 090-xxx-xxxx",
          product_code: "PT2:3|DRG05:1",
          value: "300 ฿",
          shipping_fee: "50 ฿",
          purchase_status: "Complete",
          remark: "",
        },
      ],
    },
    error: null,
  };
};

export const createMockPurchased = async ({
  purchase_id,
  ship_to,
  product_code,
  value,
  shipping_fee,
  purchase_status,
  remark,
}) => {
  return {
    success: true,
    data: {},
    error: null,
  };
};

export const getCredit = async ({ params }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/credit_balance/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: params,
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockCredit = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 14,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          no: "1",
          transaction_date: "25 Jan 2023 13:21",
          type: "Topup",
          value: "+300 ฿",
          balance: "700 ฿",
          ref: "1152578365481",
          create_by: "@แอดมิน A",
        },
        {
          no: "2",
          transaction_date: "25 Jan 2023 13:21",
          type: "Topup",
          value: "-2000 ฿",
          balance: "800 ฿",
          ref: "ปรึกษาแพทย์ : 1152578365481",
          create_by: "62785 - นาย บรรจง",
        },
        {
          no: "3",
          transaction_date: "25 Jan 2023 13:21",
          type: "Topup",
          value: "+300 ฿",
          balance: "700 ฿",
          ref: "รักษานอกสถานที่ : 1152578365481",
          create_by: "@แอดมิน A",
        },
        {
          no: "4",
          transaction_date: "25 Jan 2023 13:21",
          type: "Topup",
          value: "-2000 ฿",
          balance: "800 ฿",
          ref: "ซื้อสินค้า : 1152578365481",
          create_by: "62785 - นาย บรรจง",
        },
      ],
    },
    error: null,
  };
};

export const createMockCredit = async ({
  transaction_date,
  type,
  value,
  balance,
  ref,
  create_by,
}) => {
  return {
    success: true,
    data: {},
    error: null,
  };
};

export const getPetsInfo = async ({ page, pageSize, keyword, memberId }) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/pet_info/datatable",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          "filter.member_info_id": memberId,
          "filter._relations": "rel_m_pet_type_id",
          limit: pageSize,
          page,
          keyword,
        },
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};

export const getMockPets = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 14,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          no: "1",
          pet_name: "ฮาจิ",
          pet_category: "Exotic",
          date_of_birth: "25 Jan 2022",
          last_service_date: "25 Feb 2023 13:21",
          remark: "",
        },
        {
          no: "2",
          pet_name: "มังคุด",
          pet_category: "Dog",
          date_of_birth: "25 Jan 2022",
          last_service_date: "25 Feb 2023 13:21",
          remark: "",
        },
      ],
    },
    error: null,
  };
};

export const createMockPets = async ({
  pet_name,
  pet_category,
  date_of_birth,
  last_service_date,
  remark,
}) => {
  return {
    success: true,
    data: {},
    error: null,
  };
};

export const createMockPetsInfo = async ({
  pet_name,
  pet_category,
  date_of_birth,
  last_service_date,
  remark,
}) => {
  return {
    success: true,
    data: {},
    error: null,
  };
};
