import React from "react";

// homeMenu
import UserManagement from "../pages/UserManagement";
import MemberInfo from "../pages/MemberInfo";
import LeaveManagement from "../pages/LeaveManagement";

// settingsMenu

// reportsMenu

// Dashboard
import Dashboard from "../dashboard/Dashboard";
import EventManagement from "../dashboard/EventManagement";
import SalesMonitoring from "../dashboard/SalesMonitoring";
import WebsiteAnalytics from "../dashboard/WebsiteAnalytics";
import FinanceMonitoring from "../dashboard/FinanceMonitoring";
import Cryptocurrency from "../dashboard/Cryptocurrency";
import HelpdeskService from "../dashboard/HelpdeskService";
import StorageManagement from "../dashboard/StorageManagement";
import ProductManagementTheam from "../dashboard/ProductManagementTheam";

import AppealManagement from "../pages/AppealManagement";

// Apps
import GalleryMusic from "../apps/GalleryMusic";
import GalleryVideo from "../apps/GalleryVideo";
import Tasks from "../apps/Tasks";
import Contacts from "../apps/Contacts";
import Chat from "../apps/Chat";
import AppCalendar from "../apps/AppCalendar";
import Email from "../apps/Email";
import FileManager from "../apps/FileManager";

// Pages
import Pricing from "../pages/Pricing";
import Faq from "../pages/Faq";
import Profile from "../pages/Profile";
import People from "../pages/People";
import Activity from "../pages/Activity";
import Events from "../pages/Events";
import Settings from "../pages/Settings";
import News from "../pages/News";
import Banner from "../pages/BannerManagement";
import Article from "../pages/Article";
import FaqManagement from "../pages/FaqManagement";

// UI Elements
import LayoutColumns from "../docs/LayoutColumns";
import LayoutGrid from "../docs/LayoutGrid";
import LayoutGutters from "../docs/LayoutGutters";
import Accordions from "../docs/Accordions";
import Alerts from "../docs/Alerts";
import Avatars from "../docs/Avatars";
import Badges from "../docs/Badges";
import Breadcrumbs from "../docs/Breadcrumbs";
import Buttons from "../docs/Buttons";
import Cards from "../docs/Cards";
import Carousels from "../docs/Carousels";
import Dropdowns from "../docs/Dropdowns";
import Images from "../docs/Images";
import Listgroup from "../docs/Listgroup";
import Markers from "../docs/Markers";
import Modals from "../docs/Modals";
import NavTabs from "../docs/NavTabs";
import OffCanvas from "../docs/OffCanvas";
import Paginations from "../docs/Paginations";
import Placeholders from "../docs/Placeholders";
import Popovers from "../docs/Popovers";
import Progress from "../docs/Progress";
import Spinners from "../docs/Spinners";
import Toasts from "../docs/Toasts";
import Tooltips from "../docs/Tooltips";
import Tables from "../docs/Tables";
import FormElements from "../docs/FormElements";
import FormSelects from "../docs/FormSelects";
import FormChecksRadios from "../docs/FormChecksRadios";
import FormRange from "../docs/FormRange";
import FormPickers from "../docs/FormPickers";
import FormLayouts from "../docs/FormLayouts";
import UtilBackground from "../docs/UtilBackground";
import UtilBorder from "../docs/UtilBorder";
import UtilColors from "../docs/UtilColors";
import UtilDivider from "../docs/UtilDivider";
import UtilFlex from "../docs/UtilFlex";
import UtilSizing from "../docs/UtilSizing";
import UtilSpacing from "../docs/UtilSpacing";
import UtilOpacity from "../docs/UtilOpacity";
import UtilPosition from "../docs/UtilPosition";
import UtilTypography from "../docs/UtilTypography";
import UtilShadows from "../docs/UtilShadows";
import UtilExtras from "../docs/UtilExtras";
import ApexCharts from "../docs/ApexCharts";
import ChartJs from "../docs/ChartJs";
import MapLeaflet from "../docs/MapLeaflet";
import MapVector from "../docs/MapVector";
import IconRemix from "../docs/IconRemix";
import IconFeather from "../docs/IconFeather";
import OnlineBookingManagement from "../pages/OnlineBookingManagement";
import OnlineBookingTransferLog from "../pages/OnlineBookingTransferLog";
import OnsiteBookingManagement from "../pages/OnsiteBookingManagement";
import DoctorTimeScheduleCalendar from "../pages/DoctorTimeScheduleCalendar";
import DoctorTimeScheduleTable from "../pages/DoctorTimeScheduleTable";
import ProductCategoryManagement from "../pages/ProductCategoryManagement";
import ProductStockManagement from "../pages/ProductStockManagement";
import OrderManagement from "../pages/OrderManagement";
import ShippingManagement from "../pages/ShippingManagement";
import ProductManagement from "../pages/ProductManagement";
import Notification from "../pages/Notification";
import RolePermissionManagement from "../pages/RolePermissionManagement";
import SystemSetting from "../pages/SystemSetting";
import BillingSetting from "../pages/BillingSetting";
import CallServiceSetting from "../pages/CallServiceSetting";
import ShopPurchaseSetting from "../pages/ShopPurchaseSetting";
import ServicesSetting from "../pages/ServicesSetting";
import SpecializedMasterDataSetting from "../pages/SpecializedMasterDataSetting";
import PetTypeMasterDataSetting from "../pages/PetTypeMasterDataSetting";
import CarTypeMasterDataSetting from "../pages/CarTypeMasterDataSetting";
import OnlineBookingCancelationLog from "../pages/OnlineBookingCancelationLog";
import OnsiteBookingTransferLog from "../pages/OnsiteBookingTransferLog";
import OnsiteBookingCancelationLog from "../pages/OnsiteBookingCancelationLog";
import AuditLog from "../pages/AuditLog";
import RevenueReport from "../pages/RevenueReport";
import ExpenseReport from "../pages/ExpenseReport";
import InventoryStockReport from "../pages/InventoryStockReport";
import BookingCancellationReport from "../pages/BookingCancellationReport";
import DoctorWorkLogReport from "../pages/DoctorWorkLogReport";
import EmsVanWorkLogReport from "../pages/EmsVanWorkLogReport";
import DoctorInfo from "../pages/DoctorInfo";
import PetInfo from "../pages/PetInfo";
import HospitalInfo from "../pages/HospitalInfo";
import EmsVanManagement from "../pages/EmsVanManagement";
import CallSechuldeLog from "../pages/CallScheduleLog";
import VoucherManagement from "../pages/VoucherManagement";
import IncomeTransactionReport from "../pages/IncomeTransactionReport";
import CreditTransactionReport from "../pages/CreditTransactionReport";
import ExpenseTransactionReport from "../pages/ExpenseTransactionReport";
import WorkLogTimeSheetReport from "../pages/WorkLogTimeSheetReport";
import AddressInfo from "../features/Form/AddressInfo";
import Receipt from "../pages/Receipt";
import ReceiptDeatil from "../pages/ReceiptDeatil";
import InboxChat from "../pages/InboxChat";
import InboxChatSup from "../pages/InboxChatSup";

const protectedRoutes = [
  //{ path: "", element: <Dashboard /> },
  { path: "pages/users-permission", element: <UserManagement /> },
  {
    path: "pages/role-permission-management",
    element: <RolePermissionManagement />,
  },
  { path: "pages/system-configuration", element: <SystemSetting /> },
  { path: "pages/shop-purchase", element: <ShopPurchaseSetting /> },
  { path: "pages/call-service", element: <CallServiceSetting /> },
  { path: "pages/billing-info", element: <BillingSetting /> },
  { path: "pages/services", element: <ServicesSetting /> },
  {
    path: "pages/specialized-master-data",
    element: <SpecializedMasterDataSetting />,
  },
  { path: "pages/pet-type-master-data", element: <PetTypeMasterDataSetting /> },
  { path: "pages/car-type-master-data", element: <CarTypeMasterDataSetting /> },
  {
    path: "pages/online-booking-management",
    element: <OnlineBookingManagement />,
  },
  {
    path: "pages/online-booking-transfer-log",
    element: <OnlineBookingTransferLog />,
  },
  {
    path: "pages/online-booking-cancelation-log",
    element: <OnlineBookingCancelationLog />,
  },
  {
    path: "pages/onsite-booking-management",
    element: <OnsiteBookingManagement />,
  },
  {
    path: "pages/onsite-booking-transfer-log",
    element: <OnsiteBookingTransferLog />,
  },
  {
    path: "pages/onsite-booking-cancelation-log",
    element: <OnsiteBookingCancelationLog />,
  },
  { path: "pages/doctor-info-management", element: <DoctorInfo /> },
  { path: "pages/customer-info-management", element: <MemberInfo /> },
  { path: "pages/pet-info-management", element: <PetInfo /> },
  { path: "pages/hospital-info", element: <HospitalInfo /> },
  { path: "pages/doctor-time-schedule", element: <DoctorTimeScheduleTable /> },
  // { path: "pages/address-info-Management", element: <AddressInfo /> },
  { path: "pages/inbox-chat-sup", element: <InboxChatSup /> },

  {
    path: "pages/doctor-time-schedule-calendar",
    element: <DoctorTimeScheduleCalendar />,
  },
  { path: "pages/leave-management", element: <LeaveManagement /> },
  { path: "pages/product-management", element: <ProductManagement /> },
  {
    path: "pages/product-category-management",
    element: <ProductCategoryManagement />,
  },
  {
    path: "pages/product-stock-management",
    element: <ProductStockManagement />,
  },
  { path: "pages/order-management", element: <OrderManagement /> },
  { path: "pages/shipping-management", element: <ShippingManagement /> },
  { path: "pages/notification", element: <Notification /> },
  {
    path: "pages/auditlog",
    element: <AuditLog />,
  },
  {
    path: "pages/revenue-report",
    element: <RevenueReport />,
  },
  {
    path: "pages/call-schedule-log",
    element: <CallSechuldeLog />,
  },

  {
    path: "pages/voucher-management",
    element: <VoucherManagement />,
  },

  {
    path: "pages/expense-report",
    element: <ExpenseReport />,
  },
  {
    path: "pages/inventory-stock-report",
    element: <InventoryStockReport />,
  },
  {
    path: "pages/booking-cancellation-report",
    element: <BookingCancellationReport />,
  },
  {
    path: "pages/doctor-work-log-report",
    element: <DoctorWorkLogReport />,
  },
  {
    path: "pages/ems-van-work-report",
    element: <EmsVanWorkLogReport />,
  },
  {
    path: "pages/ems-van-management",
    element: <EmsVanManagement />,
  },
  {
    path: "pages/receipt",
    element: <Receipt />,
  },
  {
    path: "pages/receipt-detail",
    element: <ReceiptDeatil />,
  },
  {path: "/news",element: <News /> },
  {path: "/banner",element: <Banner /> },
  {path: "/article",element: <Article /> },
  {path: "/faq",element: <FaqManagement /> },
  {path: "pages/income-transaction",element: <IncomeTransactionReport /> },
  {path: "pages/credit-transaction",element: <CreditTransactionReport /> },
  {path: "pages/expense-transaction",element: <ExpenseTransactionReport /> },
  {path: "pages/work-log-timesheet",element: <WorkLogTimeSheetReport /> },


  { path: "dashboard", element: <Dashboard /> },
  { path: "appeal-management", element: <AppealManagement /> },
  { path: "dashboard/finance", element: <FinanceMonitoring /> },
  { path: "dashboard/events", element: <EventManagement /> },
  { path: "dashboard/sales", element: <SalesMonitoring /> },
  { path: "dashboard/analytics", element: <WebsiteAnalytics /> },
  { path: "dashboard/crypto", element: <Cryptocurrency /> },
  { path: "dashboard/helpdesk", element: <HelpdeskService /> },
  { path: "dashboard/storage", element: <StorageManagement /> },
  { path: "dashboard/product", element: <ProductManagementTheam /> },
  { path: "apps/gallery-music", element: <GalleryMusic /> },
  { path: "apps/gallery-video", element: <GalleryVideo /> },
  { path: "apps/tasks", element: <Tasks /> },
  { path: "apps/contacts", element: <Contacts /> },
  { path: "apps/chat", element: <Chat /> },
  { path: "apps/calendar", element: <AppCalendar /> },
  { path: "apps/email", element: <Email /> },
  { path: "apps/file-manager", element: <FileManager /> },
  { path: "pages/pricing", element: <Pricing /> },
  { path: "pages/faq", element: <Faq /> },
  { path: "pages/profile", element: <Profile /> },
  { path: "pages/people", element: <People /> },
  { path: "pages/activity", element: <Activity /> },
  { path: "pages/events", element: <Events /> },
  { path: "pages/settings", element: <Settings /> },
  { path: "docs/layout/grid", element: <LayoutGrid /> },
  { path: "docs/layout/columns", element: <LayoutColumns /> },
  { path: "docs/layout/gutters", element: <LayoutGutters /> },
  { path: "docs/com/accordions", element: <Accordions /> },
  { path: "docs/com/alerts", element: <Alerts /> },
  { path: "docs/com/avatars", element: <Avatars /> },
  { path: "docs/com/badge", element: <Badges /> },
  { path: "docs/com/breadcrumbs", element: <Breadcrumbs /> },
  { path: "docs/com/buttons", element: <Buttons /> },
  { path: "docs/com/cards", element: <Cards /> },
  { path: "docs/com/carousel", element: <Carousels /> },
  { path: "docs/com/dropdown", element: <Dropdowns /> },
  { path: "docs/com/images", element: <Images /> },
  { path: "docs/com/listgroup", element: <Listgroup /> },
  { path: "docs/com/markers", element: <Markers /> },
  { path: "docs/com/modal", element: <Modals /> },
  { path: "docs/com/navtabs", element: <NavTabs /> },
  { path: "docs/com/offcanvas", element: <OffCanvas /> },
  { path: "docs/com/pagination", element: <Paginations /> },
  { path: "docs/com/placeholders", element: <Placeholders /> },
  { path: "docs/com/popovers", element: <Popovers /> },
  { path: "docs/com/progress", element: <Progress /> },
  { path: "docs/com/spinners", element: <Spinners /> },
  { path: "docs/com/toasts", element: <Toasts /> },
  { path: "docs/com/tooltips", element: <Tooltips /> },
  { path: "docs/com/tables", element: <Tables /> },
  { path: "docs/form/elements", element: <FormElements /> },
  { path: "docs/form/selects", element: <FormSelects /> },
  { path: "docs/form/checksradios", element: <FormChecksRadios /> },
  { path: "docs/form/range", element: <FormRange /> },
  { path: "docs/form/pickers", element: <FormPickers /> },
  { path: "docs/form/layouts", element: <FormLayouts /> },
  { path: "docs/chart/apex", element: <ApexCharts /> },
  { path: "docs/chart/chartjs", element: <ChartJs /> },
  { path: "docs/map/leaflet", element: <MapLeaflet /> },
  { path: "docs/map/vector", element: <MapVector /> },
  { path: "docs/icon/remix", element: <IconRemix /> },
  { path: "docs/icon/feather", element: <IconFeather /> },
  { path: "docs/util/background", element: <UtilBackground /> },
  { path: "docs/util/border", element: <UtilBorder /> },
  { path: "docs/util/colors", element: <UtilColors /> },
  { path: "docs/util/divider", element: <UtilDivider /> },
  { path: "docs/util/flex", element: <UtilFlex /> },
  { path: "docs/util/sizing", element: <UtilSizing /> },
  { path: "docs/util/spacing", element: <UtilSpacing /> },
  { path: "docs/util/opacity", element: <UtilOpacity /> },
  { path: "docs/util/position", element: <UtilPosition /> },
  { path: "docs/util/typography", element: <UtilTypography /> },
  { path: "docs/util/shadows", element: <UtilShadows /> },
  { path: "docs/util/extras", element: <UtilExtras /> },
];

export default protectedRoutes;
