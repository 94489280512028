import React from "react";
import feather from "feather-icons/dist/feather";
import GenericButton from "./GenericButton";

export default function CancelButton(props) {
  React.useEffect(() => {
    feather.replace();
  }, []);
  return (
    <GenericButton {...props} variant="secondary">
      <i data-feather="x" className="me-1" />
      Cancel
    </GenericButton>
  );
}
