import React from "react";
import { ExpenseReportColumn } from "../data/Columns";
import {
  Modal,
  Button,
  Card,
  Col,
  Container,
  Row,
  Form,
} from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import FilterDropdown from "../components/Input/Dropdown/GenericDropdown";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import {
  getExpenseReport,
  getMockExpenseReport,
  getMockRevenueReport,
} from "../services/LogServices";
import GenericButton from "../components/Button/GenericButton";
import ReactDateTimeRangePicker from "../components/Input/ReactDateTimeRangePicker";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";
import { ExpenseReportCSVHeader } from "../data/CSVHeader";
import ExportCSVButton from "../components/Button/ExportCSVButton";
import CancelButton from "../components/Button/CancelButton";
import { textAlignRight } from "../utils/Help";

export default function ExpenseReport() {
  const csvHeader = ExpenseReportCSVHeader;
  const csvFilename = "ExpenseReport.csv";
  const [isExport, setIsExport] = React.useState(false);

  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");

  const [dateStart, setDateStart] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();
  const allUserStatus = "";
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [tableRevenueReport, setTableRevenueReport] = React.useState([]);
  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalDesc, setModalDesc] = React.useState(false);
  const [csvData, setCsvData] = React.useState([]);

  const loadExpenseReport = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, dateStart, dateEnd) => {
      const { success, data, error } = await getExpenseReport({
        page: currentPage,
        limit: pageSize,
        search: keyword,
        dateStart,
        dateEnd,
      });
      if (success) {
        setTotal(data.total);
        setTableRevenueReport(prepareTable(data.records, handleShowDetail));
        setCsvData(prepareCSVData(data.records));
      } else {
        console.log(error);
      }
    },
    []
  );
  React.useEffect(() => {
    loadExpenseReport(1, 10, allUserStatus);
  }, [loadExpenseReport]);

  const columnsExpenseReport = ExpenseReportColumn;
  const prepareTable = (datas, onShowDetail) => {
    let tableData = [];
    for (const data of datas) {
      tableData.push({
        id: data.report_id,
        running: data.code,
        dateTime: data.action_datetime,
        role: data.category,
        recipient: data.recipient,
        vat : textAlignRight(data.vat),
        amount: textAlignRight(data.amount),
        remark: data.remark,
        status: data.status,
        view: (
          <Button variant="warning" onClick={() => onShowDetail({ data })}>
            View
          </Button>
        ),
      });
    }
    return tableData;
  };

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadExpenseReport(page, pageSize, userStatus, keyword);
    },
    [loadExpenseReport, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadExpenseReport(1, pageSize, userStatus, keyword);
    },
    [loadExpenseReport, userStatus, keyword]
  );
  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadExpenseReport(1, pageSize, userStatus, keyword);
    },
    [loadExpenseReport, pageSize, keyword]
  );

  const refreshPage = () => {
    setIsOpenForm(false);
    setCurrentPage(1);
    setKeyword("");
    setUserStatus(allUserStatus);
    loadExpenseReport(1, pageSize, allUserStatus, "");
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadExpenseReport(
        1,
        pageSize,
        userStatus,
        keyword,
        dateStart,
        dateEnd
      );
    },
    [loadExpenseReport, pageSize, userStatus, keyword, dateStart, dateEnd]
  );

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setEditData(data);
  };
  const handleShowDetail = ({ data }) => {
    let txt = (
      <>
        <Container fluid style={{ minHeight: "20rem" }}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="report_id">id : </Form.Label>
              <Form.Control
                type="text"
                id="report_id"
                value={data.report_id}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="code">รหัสรายการ : </Form.Label>
              <Form.Control type="text" id="code" value={data.code} disabled />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="action_datetime">
                วันที่และเวลาทำการ :{" "}
              </Form.Label>
              <Form.Control
                type="text"
                id="action_datetime"
                value={data.action_datetime}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="recipient"> ชื่อผู้รับเงิน : </Form.Label>
              <Form.Control
                type="text"
                id="recipient"
                value={data.recipient}
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="category"> กลุ่มรายการ : </Form.Label>
              <Form.Control
                type="text"
                id="category"
                value={data.category}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="amount"> มูลค่า : </Form.Label>
              <Form.Control
                type="text"
                id="amount"
                value={data.amount}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="amount"> Vat : </Form.Label>
              <Form.Control
                type="text"
                id="vat"
                value={data.vat}
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label htmlFor="status">สถานะ :</Form.Label>
              <Form.Control
                type="text"
                id="status"
                value={data.status}
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <Form.Label>หมายเหตุ:</Form.Label>
              <Form.Control as="textarea" rows={3} value={data.remark} />
            </Form.Group>
          </Row>
        </Container>
      </>
    );
    setModalDesc(txt);
    setModalShow(true);
  };
  const handleDetailModalClose = () => {
    setModalShow(false);
    setModalDesc("");
  };
  const handleSelectedDate = React.useCallback(
    async (sDate, eDate) => {
      setDateStart(sDate);
      setDateEnd(eDate);
      setCurrentPage(1);
      await loadExpenseReport(1, pageSize, userStatus, keyword, sDate, eDate);
    },
    [loadExpenseReport, pageSize, userStatus, keyword]
  );
  const handleExportCSV = () => {
    setIsExport(true);
  };
  const handleExportComplete = () => {
    setIsExport(false); // Reset the trigger to false
  };
  const prepareCSVData = (datas) => {
    let csvData = [];
    for (const data of datas) {
      csvData.push({
        report_id: data.report_id,
        code: data.code,
        action_datetime: data.action_datetime,
        recipient: data.recipient,
        category: data.category,
        amount: data.amount,
        vat: data.vat,
        remark: data.remark,
        status: data.status,
      });
    }
    return csvData;
  };

  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <b>Expense Report Detail</b>
        </Modal.Header>
        <Modal.Body>{modalDesc}</Modal.Body>
        <Modal.Footer>
          <CancelButton onClick={handleDetailModalClose} />
        </Modal.Footer>
      </Modal>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <ExportCSVButton
            csvData={csvData}
            csvHeader={csvHeader}
            filename={csvFilename}
            enclose=""
            showBtn={false}
            isExport={isExport}
            onExportComplete={handleExportComplete}
          />
          <PageHeaderCard
            title="Expense Report"
            buttonEnable={true}
            buttonMessage="Export CSV"
            onButtonOnClick={handleExportCSV}
          />

          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                <>
                  <Row className="mt-3">
                    <Col xs={6} style={{ display: "flex" }}>
                      <Searching
                        placeholder="Filter :"
                        onSearch={handleSearch}
                        defaultValue={keyword}
                      />
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {/* {" กลุ่มรายการ : "}&nbsp;&nbsp;
                      <FilterDropdown currentValue="Enable" />
                      &nbsp;&nbsp;{" สถานะ : "}&nbsp;&nbsp;
                      <FilterDropdown currentValue="Enable" /> */}
                      {/* <ReactDateTimePicker
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                      /> */}
                      <ReactDateTimeRangePicker2
                        onSelected={handleSelectedDate}
                        defaultStart={dateStart ? new Date(dateStart) : null}
                        defaultEnd={dateEnd ? new Date(dateEnd) : null}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <ReactTable
                        columns={columnsExpenseReport}
                        data={tableRevenueReport}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <GenericPagination
                        totalPage={Math.ceil(total / pageSize)}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={loadPageOnPageChange}
                        onPageSizeChange={loadPageOnPageSizeChange}
                      />
                    </Col>
                  </Row>
                </>
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
