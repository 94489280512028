export const userPermissionColumn = [
  {
    Header: "#",
    accessor: "no",
  },
  {
    Header: "ชื่อผู้ใช้",
    accessor: "username",
  },
  {
    Header: "email",
    accessor: "email",
  },
  {
    Header: "กลุ่มผู้ใช้",
    accessor: "role",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const rolePermissionColumn = [
  {
    Header: "#",
    accessor: "no",
  },
  {
    Header: "ชื่อกลุ่มผู้ใช้",
    accessor: "roleName",
  },
  {
    Header: "วันที่สร้างรายการ",
    accessor: "createAt",
  },
  {
    Header: "สถานะ",
    accessor: "roleStatus",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const appealManagementColumn = [
  {
    Header: "#",
    accessor: "member_info_id",
  },
  {
    Header: "Appeal ID",
    accessor: "id",
  },
  {
    Header: "เรื่อง",
    accessor: "subject",
  },
  {
    Header: "หมวดหมู่",
    accessor: "m_appeal_cat_id",
  },
  {
    Header: "ผู้แจ้ง",
    accessor: "create_by",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "วันที่แจ้ง",
    accessor: "create_date",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const onlineBookingColumn = [
  {
    Header: "Case ID",
    accessor: "caseid",
  },
  {
    Header: "Case Title",
    accessor: "casetitle",
  },
  {
    Header: "Booking Date",
    accessor: "bookingdate",
  },
  {
    Header: "Schedule Date",
    accessor: "scheduledate",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Prescribe",
    accessor: "prescribe",
  },
  {
    Header: "Owner",
    accessor: "owner",
  },
  {
    Header: "Create By",
    accessor: "createby",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const doctorTimeScheduleColumn = [
  {
    Header: "Booking Date",
    accessor: "bookingDate",
  },
  {
    Header: "ประเภทเวลา",
    accessor: "scheduleType",
  },
  {
    Header: "ชื่อหมอ",
    accessor: "doctorName",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "btn",
  },
]

export const LeaveManagementInfoColumn = [
  {
    Header: "#",
    accessor: "no",
  },
  {
    Header: "วันที่ Request",
    accessor: "requestDate",
  },
  {
    Header: "ชื่อหมอ",
    accessor: "doctorName",
  },
  {
    Header: "วันที่ลา",
    accessor: "leaveDate",
  },
  {
    Header: "สถานะการอนุมัติ",
    accessor: "approveStatus",
  },
  {
    Header: "อนุมัติโดย",
    accessor: "approveBy",
  },
  {
    Header: "Remark",
    accessor: "remark",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const userMembers = [
  {
    Header: "No",
    accessor: "memberId",
  },
  {
    Header: "Name - Sure Name",
    accessor: "membersurename",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Register Date",
    accessor: "register_data",
  },
  {
    Header: "Last Service Date",
    accessor: "last_service_date",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const membersShippings = [
  {
    Header: "No",
    accessor: "no",
  },
  {
    Header: "ชื่อ-นามสกุล ผู้รับ",
    accessor: "name_surename",
  },
  {
    Header: "หมายเลขโทรศัพท์",
    accessor: "phone",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "is Promary",
    accessor: "is_promary",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const membersBooking = [
  {
    Header: "No",
    accessor: "no",
  },
  {
    Header: "Case ID",
    accessor: "case_id",
  },
  {
    Header: "Case Title",
    accessor: "case_title",
  },
  {
    Header: "Booking Date",
    accessor: "booking_date",
  },
  {
    Header: "Schedule Date",
    accessor: "schedule_date",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Owner",
    accessor: "owner",
  },
  {
    Header: "Create By",
    accessor: "create_by",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const bookingLogsChange = [
  {
    Header: "Change Date Time",
    accessor: "changeDatetime",
  },
  {
    Header: "Change Type",
    accessor: "ChangeType",
  },
  {
    Header: "Change From",
    accessor: "ChangeFrom",
  },
  {
    Header: "Change To",
    accessor: "ChangeTo",
  },
  {
    Header: "Remark",
    accessor: "remark",
  },
  {
    Header: "Update By",
    accessor: "UpdateBy",
  },
]

export const membersPurchased = [
  {
    Header: "No",
    accessor: "no",
  },
  {
    Header: "Purchase ID",
    accessor: "purchase_id",
  },
  {
    Header: "Ship To",
    accessor: "ship_to",
  },
  {
    Header: "Product Code",
    accessor: "product_code",
  },
  {
    Header: "Value",
    accessor: "value",
  },
  {
    Header: "Shipping Fee",
    accessor: "shipping_fee",
  },
  {
    Header: "Purchase Status",
    accessor: "purchase_status",
  },
  {
    Header: "Remark",
    accessor: "remark",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const membersCredit = [
  {
    Header: "No",
    accessor: "no",
  },
  {
    Header: "Transaction Date",
    accessor: "transactionDate",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Value",
    accessor: "value",
  },
  {
    Header: "Balance",
    accessor: "balance",
  },
  {
    Header: "Ref",
    accessor: "ref",
  },
  {
    Header: "Create by",
    accessor: "createBy",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const membersPets = [
  {
    Header: "No",
    accessor: "no",
  },
  {
    Header: "Pet Name",
    accessor: "pet_name",
  },
  {
    Header: "Pet Category",
    accessor: "pet_category",
  },
  {
    Header: "Date of birth",
    accessor: "date_of_birth",
  },
  {
    Header: "Last Service Date",
    accessor: "last_service_date",
  },
  {
    Header: "Remark",
    accessor: "remark",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const membersPetsInfo = [
  {
    Header: "No",
    accessor: "no",
  },
  {
    Header: "Pet Name",
    accessor: "pet_name",
  },
  {
    Header: "Owner Name",
    accessor: "owner_name",
  },
  {
    Header: "Owner Phone",
    accessor: "owner_phone",
  },
  {
    Header: "Pet Category",
    accessor: "pet_category",
  },
  {
    Header: "Date of birth",
    accessor: "date_of_birth",
  },
  {
    Header: "Last Service Date",
    accessor: "last_service_date",
  },
  {
    Header: "Register Date",
    accessor: "register_date",
  },
  {
    Header: "Remark",
    accessor: "remark",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const listVaccineColum = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "รายการวัคซีน",
    accessor: "vaccineList",
  },
  {
    Header: "วันที่",
    accessor: "vaccineDatetime",
  },
  {
    Header: "แพทย์",
    accessor: "vaccineDoctor",
  },
]

export const listMeddicineColum = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "รายการ",
    accessor: "meddicineList",
  },
  {
    Header: "จำนวน",
    accessor: "meddicineAmount",
  },

  {
    Header: "คำแนะนำ",
    accessor: "recommend",
  },
  {
    Header: "แพทย์ผู้สั่งยา",
    accessor: "meddicineDoctor",
  },
]

export const productColumn = [
  {
    Header: "#",
    accessor: "no",
  },
  // {
  //   Header: "id",
  //   accessor: "id",
  // },
  {
    Header: "รหัสสินค้า",
    accessor: "productCode",
  },
  {
    Header: "ลำดับรายการ",
    accessor: "productOrderNumber",
  },
  {
    Header: "ชื่อสินค้า",
    accessor: "productName",
  },
  {
    Header: "หมวดหมู่สินค้า",
    accessor: "productCategory",
  },
  {
    Header: "ยี่ห้อสินค้า",
    accessor: "productBrand",
  },
  {
    Header: "ราคา",
    accessor: "sellingPrice",
  },
  {
    Header: "คงเหลือ",
    accessor: "productQty",
  },
  {
    Header: "สถานะ",
    accessor: "productStatus",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const productCategoryColumn = [
  {
    Header: "#",
    accessor: "no",
  },
  // {
  //   Header: "id",
  //   accessor: "id",
  // },
  {
    Header: "ชื่อหมวดหมู่สินค้า",
    accessor: "productCategory",
  },
  //   {
  //     Header: "ยี่ห้อสินค้า",
  //     accessor: "productBrand",
  //   },
  {
    Header: "วันที่และเวลาทำรายการ",
    accessor: "transactionDatetime",
  },
  {
    Header: "สถานะ",
    accessor: "productCategoryStatus",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const productStockColumn = [
  {
    Header: "#",
    accessor: "no",
  },
  // {
  //   Header: "id",
  //   accessor: "id",
  // },
  {
    Header: "รหัสสินค้า",
    accessor: "productCode",
  },
  {
    Header: "ชื่อสินค้า",
    accessor: "productName",
  },
  {
    Header: "วันที่และเวลาทำรายการ",
    accessor: "transactionDatetime",
  },
  {
    Header: "จัดทำโดย",
    accessor: "createBy",
  },
  {
    Header: "จำนวนที่เพิ่ม",
    accessor: "newAdd",
  },
  {
    Header: "จำนวนทั้งหมด",
    accessor: "total",
  },
  {
    Header: "หมายเหตุ",
    accessor: "remark",
  },
]

export const orderColumn = [
  {
    Header: "",
    accessor: "checkbox",
  },
  {
    Header: "#",
    accessor: "no",
  },
  // {
  //   Header: "id",
  //   accessor: "id",
  // },
  {
    Header: "Purchase ID",
    accessor: "purchaseId",
  },
  {
    Header: "ชื่อสินค้า",
    accessor: "productName",
  },

  {
    Header: "Ship To",
    accessor: "shipTo",
  },
  {
    Header: "Purchase Date",
    accessor: "purchaseDate",
  },
  {
    Header: "Product Code",
    accessor: "productCode",
  },
  {
    Header: "Value",
    accessor: "value",
  },
  {
    Header: "Shipping fee",
    accessor: "shippingFee",
  },
  {
    Header: "Purchase Status",
    accessor: "purchaseStatus",
  },
  {
    Header: "Remark",
    accessor: "remark",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const shippingColumn = [
  {
    Header: "",
    accessor: "checkbox",
  },
  {
    Header: "#",
    accessor: "no",
  },
  {
    Header: "Purchase ID",
    accessor: "purchaseId",
  },
  {
    Header: "Ship To",
    accessor: "shipTo",
  },
  {
    Header: "Product Code",
    accessor: "productCode",
  },
  {
    Header: "Carrier",
    accessor: "carrier",
  },
  {
    Header: "Pickup Date",
    accessor: "pickupDate",
  },
  {
    Header: "Tracking code",
    accessor: "trackingCode",
  },
  {
    Header: "Shipping Status",
    accessor: "shippingStatus",
  },
  {
    Header: "Advise call",
    accessor: "adviseCall",
  },
  {
    Header: "Advise",
    accessor: "advise",
  },
  {
    Header: "Remark",
    accessor: "remark",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const specializedMasterDataColumn = [
  {
    Header: "#",
    accessor: "no",
  },
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "ความถนัดเฉพาะทาง",
    accessor: "specialized",
  },
  {
    Header: "วันที่สร้างรายการ",
    accessor: "createAt",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const petTypeMasterDataColumn = [
  {
    Header: "#",
    accessor: "no",
  },
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "ประเภทสัตว์",
    accessor: "petType",
  },
  {
    Header: "วันที่สร้างรายการ",
    accessor: "createAt",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const carTypeMasterDataColumn = [
  {
    Header: "#",
    accessor: "no",
  },
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "ประเภทอุปกรณ์ประจำรถ",
    accessor: "carType",
  },
  {
    Header: "วันที่สร้างรายการ",
    accessor: "createAt",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const notificationColumn = [
  {
    Header: "#",
    accessor: "no",
  },
  // {
  //   Header: "id",
  //   accessor: "id",
  // },
  {
    Header: "Type",
    accessor: "notiType",
  },
  {
    Header: "ข้อความที่ส่ง",
    accessor: "notiContent",
  },
  {
    Header: "วันที่ส่ง",
    accessor: "notiDate",
  },
  {
    Header: "Status",
    accessor: "notiStatus",
  },
  {
    Header: "วันที่ส่งสำเร็จ",
    accessor: "notiSuccessDate",
  },
  {
    Header: "Total Send",
    accessor: "totalSend",
  },
  {
    Header: "",
    accessor: "detailButton",
  },
]

export const auditLogColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "วันที่และเวลา",
    accessor: "dateTime",
  },
  {
    Header: "ชื่อผู้ใช้",
    accessor: "name",
  },
  {
    Header: "กลุ่มผู้ใช้",
    accessor: "role",
  },
  {
    Header: "Path",
    accessor: "path",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "Reference",
    accessor: "reference",
  },
  {
    Header: "Reference ID",
    accessor: "referId",
  },
  {
    Header: "IP adress",
    accessor: "ip",
  },
  {
    Header: "Text note",
    accessor: "note",
  },
]

export const RevenueReportColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "รหัสรายการ",
    accessor: "running",
  },
  {
    Header: "เลขที่ใบเสร็จ",
    accessor: "receipt_no",
  },

  {
    Header: "วันที่และเวลาทำการ",
    accessor: "dateTime",
  },
  {
    Header: "กลุ่มรายการ",
    accessor: "role",
  },
  {
    Header: "ชื่อและรหัสลูกค้า",
    accessor: "cus_name",
  },
  {
    Header: "หมายเหตุ",
    accessor: "remark",
  },
  {
    Header: "รายการอ้างอิง",
    accessor: "refer",
  },
  {
    Header: "มูลค่า",
    accessor: "amt",
  },
  {
    Header: "VAT",
    accessor: "vat",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "view",
  },
]
export const CallSchuldeLogColumn = [
  {
    Header: "#",
    accessor: "report_id",
  },
  {
    Header: "วันที่โทร",
    accessor: "call_date",
  },
  {
    Header: "ชื่อหมอ",
    accessor: "doctor_name",
  },
  {
    Header: "ชื่อลูกค้า",
    accessor: "user_name",
  },
  {
    Header: "เคสไอดี",
    accessor: "case_id",
  },
  {
    Header: "ระยะเวลาการโทร",
    accessor: "call_duration",
  },
  {
    Header: "ประเภทการโทร",
    accessor: "call_type",
  },
  {
    Header: "",
    accessor: "view",
  },
]

export const ExpenseReportColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "รหัสรายการ",
    accessor: "running",
  },
  {
    Header: "วันที่และเวลาทำการ",
    accessor: "dateTime",
  },
  {
    Header: "กลุ่มรายการ",
    accessor: "role",
  },
  {
    Header: "ชื่อผู้รับเงิน",
    accessor: "recipient",
  },
  {
    Header: "มูลค่า",
    accessor: "amount",
  },
  {
    Header: "Vat",
    accessor: "vat",
  },
  {
    Header: "หมายเหตุ",
    accessor: "remark",
  },

  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "view",
  },
]

export const InventoryStockReportColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "รหัสรายการ",
    accessor: "runningProduct",
  },
  {
    Header: "ชื่อสินค้า",
    accessor: "nameProduct",
  },
  {
    Header: "หมวดหมู่สินค้า",
    accessor: "groupProduct",
  },
  {
    Header: "วันที่ทำรายการ",
    accessor: "dateTime",
  },
  {
    Header: "ประเภท",
    accessor: "typeProduct",
  },
  {
    Header: "Qty",
    accessor: "qty",
  },

  {
    Header: "Balance",
    accessor: "balance",
  },
  {
    Header: "รายการอ้างอิง",
    accessor: "refer",
  },
  {
    Header: "Remark",
    accessor: "remark",
  },
]

export const BookingCancellationReportColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "รหัสเคส",
    accessor: "caseID",
  },
  {
    Header: "ชื่อเคส",
    accessor: "caseName",
  },
  {
    Header: "วันที่ทำรายการจอง",
    accessor: "bookingDatetime",
  },
  {
    Header: "วันที่นัดหมาย",
    accessor: "appointmentDatetime",
  },
  {
    Header: "ประเภทการจอง",
    accessor: "bookingType",
  },
  {
    Header: "ผู้รับเคส",
    accessor: "caseOwner",
  },
  {
    Header: "สถานะเคส",
    accessor: "caseStatus",
  },
  {
    Header: "หมายเหตุ",
    accessor: "remark",
  },
  {
    Header: "",
    accessor: "view",
  },
]

export const DoctorWorkLogColumn = [
  {
    Header: "#",
    accessor: "worklog_transaction_id",
  },
  {
    Header: "รหัสรายการ",
    accessor: "code",
  },
  {
    Header: "ชื่อแพทย์",
    accessor: "doctor_name",
  },
  {
    Header: "วันที่และเวลาทำรายการ",
    accessor: "action_datetime",
  },

  {
    Header: "ชื่อลูกค้า",
    accessor: "member_name",
  },
  {
    Header: "ระยะเวลางาน (ชม.)",
    accessor: "working_time_hrs",
  },
  {
    Header: "มูลค่างาน",
    accessor: "work_value",
  },
  {
    Header: "ค่าใช้จ่าย Platform",
    accessor: "platform_cost",
  },
  {
    Header: "ค่าใช้จ่ายโรงพยาบาล",
    accessor: "hospital_expenses",
  },
  {
    Header: "ค่าใช้จ่ายแพทย์",
    accessor: "doctor_expenses",
  },
  {
    Header: "กลุ่มรายการ",
    accessor: "category",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "view",
  },
]
export const EmsVanWorkLogReportColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "รหัสรายการ",
    accessor: "transectionID",
  },
  {
    Header: "ชื่อแพทย์",
    accessor: "doctorName",
  },
  {
    Header: "วันเวลาที่ทำรายการ",
    accessor: "transectionDatetime",
  },
  {
    Header: "ชื่อลูกค้า",
    accessor: "cusName",
  },
  {
    Header: "ระยะเวลา (ชม.)",
    accessor: "time",
  },
  {
    Header: "มูลค่างาน",
    accessor: "workValue",
  },
  {
    Header: "ที่อยู่ต้นทาง",
    accessor: "origin",
  },
  {
    Header: "ที่อยู่ปลายทาง",
    accessor: "destination",
  },
  {
    Header: "กลุ่มรายการ",
    accessor: "transectionGroup",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "view",
  },
]

export const emsVanInfoColumn = [
  {
    Header: "#",
    accessor: "no",
  },
  {
    Header: "ประเภทรถ",
    accessor: "carType",
  },
  {
    Header: "ทะเบียนรถ",
    accessor: "licensePlate",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "วันที่ปรับปรุง",
    accessor: "update_date",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const photoSetColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "รายการรูปภาพ",
    accessor: "image",
  },
  {
    Header: "รายละเอียด",
    accessor: "description",
  },
  {
    Header: "ลำดับรายการ",
    accessor: "order",
  },
  {
    Header: "",
    accessor: "deleteButton",
  },
]

export const userDoctor = [
  {
    Header: "No",
    accessor: "id",
  },
  {
    Header: "Name-Lastname",
    accessor: "Doctorsurename",
  },
  {
    Header: "Phone No.",
    accessor: "phone",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Hospital",
    accessor: "hospital",
  },
  {
    Header: "On site Compile",
    accessor: "onsiteCompile",
  },
  {
    Header: "Register Date",
    accessor: "registerDate",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const userDoctorProfession = [
  {
    Header: "ลำดับ",
    accessor: "no",
  },
  {
    Header: "เลขที่เอกสาร",
    accessor: "documentNumber",
  },
  {
    Header: "ประเภทเอกสาร",
    accessor: "documentType",
  },
  {
    Header: "วันที่ออกเอกสาร",
    accessor: "dateIssue",
  },
  {
    Header: "วันที่หมดอายุของเอกสาร",
    accessor: "documentExpDate",
  },
  {
    Header: "วันที่อัพโหลดเอกสาร",
    accessor: "documentUploadDate",
  },
  {
    Header: "ภาพไฟล์แนบ",
    accessor: "attachmentPicture",
  },
  {
    Header: "หมายเหตุจากแอดมิน",
    accessor: "remark",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const userHospitalInfo = [
  {
    Header: "#",
    accessor: "no",
  },
  {
    Header: "ชื่อโรงพยาบาล",
    accessor: "hospitalName",
  },
  {
    Header: "ตำบล / แขวง",
    accessor: "subdistrict",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "วันที่ปรับปรุง",
    accessor: "update_date",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const bannerColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "หัวข้อแบนเนอร์",
    accessor: "subject",
  },
  {
    Header: "วันที่และเวลาทำรายการ",
    accessor: "update_date",
  },
  {
    Header: "ประเภทแบนเนอร์",
    accessor: "m_banner_type_id",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const bannerDetailColumn = [
  {
    Header: "ลำดับการแสดง",
    accessor: "banner_order",
  },
  {
    Header: "รูปภาพ",
    accessor: "picture_path",
  },
  {
    Header: "วันที่ทำรายการ",
    accessor: "update_date",
  },
  {
    Header: "วันที่หมดอายุ",
    accessor: "exp_date",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const faqColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "หัวข้อ FAQ",
    accessor: "question",
  },
  {
    Header: "วันที่และเวลาทำรายการ",
    accessor: "update_date",
  },
  {
    Header: "ลำดับการแสดง",
    accessor: "faq_order",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "จำนวนครั้งที่ดู",
    accessor: "count_view",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const ArticleColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "หัวข้อบทความ",
    accessor: "subject",
  },
  {
    Header: "วันที่และเวลาทำรายการ",
    accessor: "update_date",
  },
  {
    Header: "วันที่หมดอายุ",
    accessor: "exp_date",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const NewsColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "หัวข้อข่าว",
    accessor: "subject",
  },
  {
    Header: "วันที่และเวลาทำรายการ",
    accessor: "update_date",
  },
  {
    Header: "วันที่หมดอายุ",
    accessor: "exp_date",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const IncomeTransactionColumn = [
  {
    Header: "#",
    accessor: "income_transaction_id",
  },
  {
    Header: "รหัสรายการ",
    accessor: "code",
  },
  {
    Header: "วันที่และเวลาทำรายการ",
    accessor: "action_datetime",
  },
  {
    Header: "กลุ่มรายการ",
    accessor: "category",
  },
  {
    Header: "จำนวนเงิน",
    accessor: "amt",
  },
  {
    Header: "ภาษีมูลค่าเพิ่ม",
    accessor: "vat",
  },
  {
    Header: "ชื่อและรหัสลูกค้า",
    accessor: "member_name",
  },
  {
    Header: "หมายเหตุ",
    accessor: "remark",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const CreditTransactionColumn = [
  {
    Header: "#",
    accessor: "credit_transaction_id",
  },
  {
    Header: "รหัสรายการ",
    accessor: "code",
  },
  {
    Header: "วันที่และเวลาทำรายการ",
    accessor: "action_datetime",
  },
  {
    Header: "กลุ่มรายการ",
    accessor: "category",
  },
  {
    Header: "จำนวนเงิน",
    accessor: "amt",
  },
  {
    Header: "ภาษีมูลค่าเพิ่ม",
    accessor: "vat",
  },
  {
    Header: "ชื่อและรหัสลูกค้า",
    accessor: "member_name",
  },
  {
    Header: "หมายเหตุ",
    accessor: "remark",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const ExpenseTransactionColumn = [
  {
    Header: "",
    accessor: "checkbox",
  },
  {
    Header: "#",
    accessor: "expense_transaction_id",
  },
  {
    Header: "รหัสรายการ",
    accessor: "code",
  },
  {
    Header: "วันที่และเวลาทำรายการ",
    accessor: "action_datetime",
  },
  {
    Header: "กลุ่มรายการ",
    accessor: "category",
  },
  {
    Header: "จำนวนเงิน",
    accessor: "amt",
  },
  {
    Header: "ภาษีมูลค่าเพิ่ม",
    accessor: "vat",
  },
  {
    Header: "ชื่อผู้รับเงิน",
    accessor: "recipient",
  },
  {
    Header: "หมายเหตุ",
    accessor: "remark",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const WorkLogTimeSheetColumn = [
  {
    Header: "#",
    accessor: "worklog_transaction_id",
  },
  {
    Header: "รหัสรายการ",
    accessor: "code",
  },
  {
    Header: "ชื่อแพทย์",
    accessor: "doctor_name",
  },
  {
    Header: "วันที่และเวลาทำรายการ",
    accessor: "action_datetime",
  },
  {
    Header: "ระยะเวลางาน(ชม.)",
    accessor: "working_time_hrs",
  },
  {
    Header: "มูลค่างาน",
    accessor: "work_value",
  },
  {
    Header: "กลุ่มรายการ",
    accessor: "category",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const AddressColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Street",
    accessor: "street",
  },
  {
    Header: "Subdistrict",
    accessor: "subdistrict",
  },
  {
    Header: "Post Code",
    accessor: "postcode",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const ReceiptColumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "เลขที่ใบเสร็จ",
    accessor: "receipt_no",
  },
  {
    Header: "วันและเวลาทำรายการ",
    accessor: "action_datetime",
  },
  {
    Header: "กลุ่มรายการ",
    accessor: "group",
  },
  {
    Header: "ชื่อและรหัสลูกค้า",
    accessor: "cusname",
  },
  {
    Header: "มูลค่า",
    accessor: "value",
  },
  {
    Header: "รายการอ้างอิง",
    accessor: "ref",
  },
  {
    Header: "หมายเหตุ",
    accessor: "remark",
  },
  {
    Header: "สถานะ",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "print",
  },
  {
    Header: "",
    accessor: "view",
  },
]

export const ProductOrderDetailColumn = [
  // {
  //   Header: "#",
  //   accessor: "no",
  // },
  {
    Header: "รายการ",
    accessor: "item",
  },
  {
    Header: "ราคา",
    accessor: "price",
  },
  {
    Header: "จำนวน",
    accessor: "quantity",
  },
  {
    Header: "สรุปราคา",
    accessor: "totalPrice",
  },
  {
    Header: "",
    accessor: "editDeleteButton",
  },
]

export const ProductOrderDetailNoEditColumn = [
  // {
  //   Header: "#",
  //   accessor: "no",
  // },
  {
    Header: "รายการ",
    accessor: "item",
  },
  {
    Header: "ราคา",
    accessor: "price",
  },
  {
    Header: "จำนวน",
    accessor: "quantity",
  },
  {
    Header: "สรุปราคา",
    accessor: "totalPrice",
  },
]

export const PaymentProductOrderColumn = [
  {
    Header: "รายการ",
    accessor: "item",
  },
  {
    Header: "",
    accessor: "deleteButton",
  },
]

export const PaymentProductOrderNoDeleteColumn = [
  {
    Header: "รายการ",
    accessor: "item",
  },
]

export const VoucherManagementcolumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Voucher Lot",
    accessor: "lot_id",
  },
  {
    Header: "Total Item",
    accessor: "qty",
  },
  {
    Header: "Total Used",
    accessor: "each_amt",
  },
  {
    Header: "Create Date",
    accessor: "create_date",
  },
  {
    Header: "Create By",
    accessor: "create_by",
  },
  {
    Header: "Expire Date",
    accessor: "exp_date",
  },
  {
    Header: "",
    accessor: "detailexpireButton",
  },
]

export const Vouchercolumn = [
  {
    Header: "#",
    accessor: "id",
  },
  {
    Header: "Voucher Code",
    accessor: "code",
  },
  {
    Header: "Used By",
    accessor: "used",
  },
  {
    Header: "Value",
    accessor: "each_amt",
  },
  {
    Header: "Used/Voide Date",
    accessor: "create_date",
  },
  {
    Header: "Create By",
    accessor: "create_by",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Remark",
    accessor: "remark",
  },
  {
    Header: "Expire Date",
    accessor: "exp_date",
  },
  {
    Header: "",
    accessor: "VoidButton",
  },
]
