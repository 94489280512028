import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "react-bootstrap";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import GenericTextInput from "../../components/Input/GenericTextInput";
import { CmsLanguageOptions, StatusOptions } from "../../data/SelectOptions";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";
import DeleteButton from "../../components/Button/DeleteButton";
import {
  createArticle,
  editArticle,
  deleteArticleImage,
  uploadArticleImage
} from "../../services/ArticleService";
import RichTextArea from "../../components/Input/RichTextArea";

const schema = yup.object().shape({
  language: yup.object().required("ภาษาห้ามว่าง"),
  subject: yup.string().required("หัวข้อบทความห้ามว่าง"),
  status: yup.object().required("สถานะห้ามว่าง"),
  description: yup.string().required("รายละเอียดบทความห้ามว่าง"),
});

export default function ArticleInfo({
  isCreateForm,
  onCancel,
  onSubmitSuccess,
  selectedArticle,
}) {
  const articleId = selectedArticle?.id;
  //const [newsDetail, setnewsDetail] = React.useState(null);
  const [previewImage, setPreviewImage] = React.useState(selectedArticle?.news_picture);
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const [isDirtyData, setisDirtyData] = React.useState();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Load Data
  /*const loadArticleDetail = React.useCallback(async () => {
    const { success, data, error } = await getArticleInfoById(articleId);
    if (success) {
      console.log(success);
      console.log(data);
      setnewsDetail(data);
    } else {
      console.log(error);
      setnewsDetail(null);
    }
  }, [articleId]);*/

  const handleOpenDeleteConfirm = () => {
    setIsDelete(true);
    setDeleteData(articleId);
  };

  const onSubmit = async(inputData) => {
    if (isCreateForm) {            
      const { success, data, error } = await createArticle({
        ...inputData,
        exp_date:new Date(inputData?.exp_date).toLocaleString("sv"),
        status:inputData.status.value,
        language:inputData.language.value
      });
      if (success) {
          console.log("new data", data);
          if (inputData.image[0] && data.id) {
            let errorMessage = await submitImage(inputData.image[0], data.id);
            if (!errorMessage) {
              ShowMessageAndExpire("Photo updated successfully.", "", "success");
            } else {
              ShowMessageAndExpire(
                "Update Photo Failed",
                JSON.stringify(errorMessage?.message),
                "error"
              );
            }
          }
        ShowMessageAndExpire("Data created successfully.", data.subject, "success");
        onSubmitSuccess(data);
      } else {
        console.log(error);
      }
    } else {
      const { success, data, error } = await editArticle({
        ...inputData,
        id:articleId,
        exp_date:new Date(inputData?.exp_date).toLocaleString("sv"),
        status:inputData.status.value,
        language:inputData.language.value
      });
      if (success) {
        ShowMessageAndExpire("Data updated successfully.", data.subject, "success");
        onSubmitSuccess(data);
      } else {
        console.log(error);
      }
    }
  };

  const handleImageChange = async (event) => {
    console.log("handleImageChange");
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    await reader.readAsDataURL(file);
    if (articleId && file) {
      let errorMessage = await submitImage(file, articleId);
      if (!errorMessage) {
        ShowMessageAndExpire("Photo updated successfully.", "", "success");
        setisDirtyData(true);
      } else {
        ShowMessageAndExpire(
          "Update Photo Failed",
          JSON.stringify(errorMessage?.message),
          "error"
        );
      }
    }
  };

  const submitImage = async (imageFile, id) => {
    const { success, error } = await uploadArticleImage({
      id,
      imageFile
    });
    if (success) {
      return;
    } else {
      return error;
    }
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleDelete = React.useCallback(
    async (dataId) => {
      const { success, data, error } = await deleteArticleImage({id : dataId});
      if (success) {
        handleCloseDeleteConfirm();
        setPreviewImage();
        setisDirtyData(true);
      } else {
        handleCloseDeleteConfirm();
        ShowMessageAndExpire(
          "Delete Photo Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    },
    []
  );


  const handleOnCancel = ()=>{
    onCancel(isDirtyData);
  }
  
  return (
    <>
      <DeleteConfirmModal
        show={isDelete}
        onClose={handleCloseDeleteConfirm}
        onComfirm={handleDelete}
        deleteRecord={articleId}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these image?"}</p>
            <p>
              {`ID ${articleId}`}
            </p>
          </>
        }
      />
      <Form >
        <Row className="mb-3">
          <Col sm="1" style={{ minWidth: "10rem" }}>
            <GenericSelectInput
              control={control}
              name="language"
              label="Language"
              placeholder="Language"
              options={CmsLanguageOptions}
              defaultValue={selectedArticle? selectedArticle.language.value : 0}
              required
            />
          </Col>
        </Row>
        <Row className="mx-3" style={{ paddingTop: 20 }}>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="subject"
              label="หัวข้อบทความ"
              placeholder="หัวข้อบทความ"
              defaultValue={selectedArticle?.subject}
              required
              error={errors.subject}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericSelectInput
              control={control}
              name="status"
              label="สถานะ"
              placeholder="status"
              options={StatusOptions}
              defaultValue={selectedArticle?.status.value}
              required
            />
          </Form.Group>
        </Row>
        <Row className="mx-3" style={{ paddingTop: 20 }}>
          <Form.Group as={Col} controlId="formBasicName">
            <GenericTextInput
              register={register}
              name="image"
              label="รูปภาพ"
              placeholder="รูปภาพ"
              defaultValue={null}
              type="file"
              error={errors.image}
              onChange={handleImageChange}
            />
            {previewImage
              && (
              <>
                <img src={previewImage} alt="Preview" style={{ marginTop: '10px', width: '400px'  }} />
                {selectedArticle?.news_picture && <DeleteButton style={{ marginLeft: '20px' }} onClick={handleOpenDeleteConfirm} />}
              </>
              )
            }
          </Form.Group>
          <Col>         
            <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="exp_date"
              label="วันหมดอายุ"
              placeholder="วันหมดอายุ"
              type="dateTime"
              defaultValue={!isCreateForm && new Date(selectedArticle?.exp_date).getDate()
                  ? new Date(selectedArticle?.exp_date)
                  : null}
            />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mx-3" style={{ paddingTop: 30 }}>
          <Form.Group as={Col} controlId="formBasicName">
            <RichTextArea
              label="รายละเอียดบทความ"
              name="description"
              defaultValue={selectedArticle?.description}
              control={control}
              required
              error={errors.description}
            />
          </Form.Group>
        </Row>
        <Row style={{ paddingTop: 40, paddingBottom: 10 }}>
          <Col>
            <CancelButton onClick={handleOnCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton onClick={handleSubmit(onSubmit)}/>
          </Col>
        </Row>
      </Form>
    </>
  );
}
