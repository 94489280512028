import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import GenericTextInput from "../../components/Input/GenericTextInput";
import {
  createRecord,
  editRecord,
  getRecordDetail,
  deleteDetailRecord
} from "../../services/BannerServices";
import GenericSelectInput from "../../components/Input/GenericSelectInput";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import UploadImage from "../../components/Button/UploadImage";
import GenericButton from "../../components/Button/GenericButton";
import { bannerDetailColumn } from "../../data/Columns";
import ReactTable from "../../components/ReactTable";
import DeleteButton from "../../components/Button/DeleteButton";
import EditButton from "../../components/Button/EditButton";
import CreateEditBannerDetailForm from "./CreateEditBannerDetailForm";
import DeleteConfirmModal from "../../components/Modal/DeleteConfirmModal";
import { BannerDetailStatusOptions, StatusOptions, BannerTypeOptions } from "../../data/SelectOptions";
import GenericPagination from "../../components/GenericPagination";

const schema = yup.object().shape({
  banner_type: yup.object().required("ประเภทห้ามว่าง"),
  status: yup.object().required("สถานะห้ามว่าง"),
  subject: yup.string().required("หัวข้อห้ามว่าง"),
  duration: yup.number().integer().min(0,'มากกว่าหรือเท่ากับ 0').typeError('เวลาต้องเป็นตัวเลข').required("เวลาห้ามว่าง"),

});



export default function CreateEditBannerForm({ onCancel, onSubmitSuccess, id, banner_type, status, subject, duration }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // console.log(subject);
  const columnsData = bannerDetailColumn;

  const [tableData, setTableData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [total, setTotal] = React.useState(1);


  const [isCreate, setIsCreate] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);

  const [deleteData, setDeleteData] = React.useState();
  const [editData, setEditData] = React.useState();



  const loadBannerDetailInfo = React.useCallback(
    async (currentPage, pageSize) => {
      const { success, data, error } = await getRecordDetail({
        id: id,
        page: currentPage,
        pageSize: pageSize,
      });
      if (success) {
        // console.log(data.records);
        setTotal(data.total);
        setTableData(
          prepareTableData(
            data.records,
            handleOpenEdit,
            handleOpenDeleteConfirm
          )
        );
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadBannerDetailInfo(page, pageSize,);
    },
    [loadBannerDetailInfo, pageSize]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadBannerDetailInfo(1, pageSize,);
    },
    [loadBannerDetailInfo]
  );

  const handleOpenCreate = () => {
    setIsCreate(true);
  };
  const handleCloseCreate = () => {
    setIsCreate(false);
    setIsEdit(false);
    setEditData(null);
  };

  const handleOpenEdit = (data) => {
    // console.log(data);
    setIsEdit(true);
    setEditData(data);
  };
  const handleCloseEdit = () => {
    setIsEdit(false);
    setIsCreate(false);
    setEditData(null);
  };

  const handleOpenDeleteConfirm = (data) => {

    // console.log(data);
    setIsDelete(true);
    setDeleteData(data);
  };
  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleEdit = React.useCallback(async () => {
    handleCloseEdit();
    loadBannerDetailInfo(currentPage, pageSize);
  }, [loadBannerDetailInfo, currentPage, pageSize]);


  const handleDelete = React.useCallback(
    async (dataId) => {
      // console.log(dataId);
      const { success, data, error } = await deleteDetailRecord({
        id: dataId,
      });
      if (success) {
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadBannerDetailInfo(tempCurrentPage, pageSize);
      } else {
        console.log(error);
      }
    },
    [loadBannerDetailInfo, total, currentPage, pageSize]
  );



  const onSubmit = async (inputData) => {
    // console.log(inputData);
    if (id) {
      const { success, data, error } = await editRecord({
        id: id,
        m_banner_type_id: inputData.banner_type.value,
        status: inputData.status.value,
        subject: inputData.subject,
        dptime: inputData.duration,

      });
      if (success) {
        onSubmitSuccess();
        // onCreateSuccess();
        ShowMessageAndExpire("Data updated successfully.", 'Data Success', "success");
      } else {
        console.log(error);
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        );
      }

    } else {
      const { success, data, error } = await createRecord({
        m_banner_type_id: inputData.banner_type.value,
        status: inputData.status.value,
        subject: inputData.subject,
        dptime: inputData.duration,

      });
      if (success) {
        onSubmitSuccess();
        // onCreateSuccess();
        ShowMessageAndExpire("Data created successfully.", 'Data Success', "success");
      } else {
        console.log(error);
        ShowMessageAndExpire(
          "Failed to create data.",
          JSON.stringify(error?.message),
          "error"
        );
      }

    }

  };

  React.useEffect(() => {
    loadBannerDetailInfo(1, 10);
  }, [loadBannerDetailInfo]);

  return (
    <>
      <DeleteConfirmModal
        show={isDelete}
        onClose={handleCloseDeleteConfirm}
        onComfirm={handleDelete}
        deleteRecord={deleteData?.id}
        confirmMessage={
          <>
            <p>{"Do you really want to delete these record?"}</p>
            <p>
              {`Banner ID ${deleteData?.id} ลำดับ ${deleteData?.banner_order}`}
            </p>
          </>
        }
      />
      <Container fluid style={{ minHeight: "30rem" }}>
        {isCreate ? (
          <>
            <CreateEditBannerDetailForm
              onCancel={handleCloseCreate}
              onSubmitSuccess={handleEdit}
              banner_id={id}
            />
          </>

        ) : isEdit ? (
          <>
            <CreateEditBannerDetailForm
              onCancel={handleCloseCreate}
              onSubmitSuccess={handleEdit}
              id={editData?.id}
              picture_path={editData?.picture_path}
              url={editData?.url}
              status={editData?.status}
              order={editData?.banner_order}
              exp_date={editData?.exp_date}
              loadBannerDetailInfo={loadBannerDetailInfo}
            />
          </>

        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formBasicName">
                  <GenericSelectInput
                    control={control}
                    name="banner_type"
                    label="ประเภทแบนเนอร์"
                    placeholder="ประเภทแบนเนอร์"
                    defaultValue={banner_type}
                    options={BannerTypeOptions}
                    required
                    error={errors.banner_type}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formBasicName">
                  <GenericSelectInput
                    control={control}
                    name="status"
                    label="สถานะ"
                    placeholder="สถานะ"
                    defaultValue={status}
                    options={StatusOptions}
                    required
                    error={errors.status}
                  />
                </Form.Group>

              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formBasicName">
                  <GenericTextInput
                    register={register}
                    name="subject"
                    label="หัวข้อแบนเนอร์"
                    placeholder="หัวข้อ"
                    defaultValue={subject}
                    required
                    error={errors.subject}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formBasicName">
                  <GenericTextInput
                    register={register}
                    name="duration"
                    label="เวลา(วินาที)"
                    placeholder="เวลา"
                    defaultValue={duration}
                    type="number"
                    required
                    error={errors.duration}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
              </Row>
              {
                id && (
                  <GenericButton
                    variant="primary"
                    onClick={() => handleOpenCreate()}
                    disabled={!id}
                  >
                    <i data-feather="plus" />
                    {"Add banner"}
                  </GenericButton>
                )
              }

              <Row className="mb-3">
              </Row>

              <Row className="mt-3">
                <Col>
                  <ReactTable columns={columnsData} data={tableData} />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <GenericPagination
                    totalPage={Math.ceil(total / pageSize)}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onPageChange={loadPageOnPageChange}
                    onPageSizeChange={loadPageOnPageSizeChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <CancelButton onClick={onCancel} />
                </Col>
                <Col style={{ display: "flex", justifyContent: "end" }}>
                  <SaveButton />
                </Col>
              </Row>
            </form>
          </>
        )}
      </Container>


    </>
  );
}

const prepareTableData = (datas, onEdit, onDelete) => {

  let tableData = [];
  for (const data of datas) {

    tableData.push({
      banner_order: data.banner_order,
      picture_path: (
        // <img src={data.picture_path} alt="Preview" style={{ width: '450px', height: '150px' }} />
        <img src={data.picture_path} alt="Preview" style={{ height: '150px' }} />
      ),
      update_date: data.update_date,
      exp_date: data.exp_date,
      status: <GenericRadioInput
        checked={[data.status]}
        radios={BannerDetailStatusOptions}
        disabled={true}
        type="switch" />,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(data);
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(data);
            }}
          />
        </>
      ),
    });
  }
  return tableData;
};