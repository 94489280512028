import React from "react";
import { Col, Pagination, Row } from "react-bootstrap";
import Select from "react-select";
import { pageSizeOptions } from "../data/SelectOptions";

export default function GenericPagination({
  totalPage,
  currentPage,
  pageSize,
  onPageChange,
  onPageSizeChange,
}) {
  const renderPageItem = () => {
    let pageItem = [];
    const rangePageShow = 1;
    if (currentPage - rangePageShow > 1) {
      pageItem.push(
        <Pagination.Item
          onClick={() => {
            goToPage(1);
          }}
          key={1}
        >
          {1}
        </Pagination.Item>
      );
    }
    if (currentPage - rangePageShow > 2) {
      pageItem.push(<Pagination.Ellipsis disabled key="startEllipsis" />);
    }

    for (let i = currentPage - rangePageShow; i < currentPage; i++) {
      if (i > 0) {
        pageItem.push(
          <Pagination.Item
            onClick={() => {
              goToPage(i);
            }}
            key={i}
          >
            {i}
          </Pagination.Item>
        );
      }
    }

    pageItem.push(
      <Pagination.Item
        onClick={() => {
          goToPage(currentPage);
        }}
        active
        key={currentPage}
      >
        {currentPage}
      </Pagination.Item>
    );

    for (
      let i = currentPage + 1;
      i <= currentPage + rangePageShow && i <= totalPage;
      i++
    ) {
      pageItem.push(
        <Pagination.Item
          onClick={() => {
            goToPage(i);
          }}
          key={i}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (currentPage + rangePageShow < totalPage - 1) {
      pageItem.push(<Pagination.Ellipsis disabled key="endEllipsis" />);
    }
    if (currentPage + rangePageShow < totalPage) {
      pageItem.push(
        <Pagination.Item
          onClick={() => {
            goToPage(totalPage);
          }}
          key={totalPage}
        >
          {totalPage}
        </Pagination.Item>
      );
    }
    return pageItem;
  };

  const goToPage = async (page) => {
    await onPageChange(page);
  };

  const changePageSize = async (newPageSize) => {
    await onPageSizeChange(newPageSize);
  };

  const goToPreviousPage = () => {
    if (currentPage === 1) {
      return;
    }
    goToPage(currentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage === totalPage) {
      return;
    }
    goToPage(currentPage + 1);
  };

  const onChangePageSize = (newPageSize) => {
    if (newPageSize === pageSize) {
      return;
    }
    changePageSize(newPageSize);
  };

  return (
    <>
      <Row>
        <Col>
          <div style={{ width: "10rem" }}>
            <Select
              placeholder="จำนวนไอเทมต่อหน้า"
              defaultValue={pageSizeOptions.find((e) => e.value === 10)}
              value={pageSizeOptions.find((e) => e.value === pageSize)}
              options={pageSizeOptions}
              onChange={(e) => {
                onChangePageSize(e.value);
              }}
            />
          </div>
        </Col>
        <Col style={{ display: "flex", justifyContent: "end" }}>
          <Pagination>
            <Pagination.First
              onClick={() => {
                goToPage(1);
              }}
            />
            <Pagination.Prev onClick={goToPreviousPage} />
            {renderPageItem()}
            <Pagination.Next onClick={goToNextPage} />
            <Pagination.Last
              onClick={() => {
                goToPage(totalPage);
              }}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}
