import React from "react";
import ReactDatePicker from "react-datepicker";
import { Form } from "react-bootstrap";
import { dateFormat, dateTimeFormat } from "../../data/Config";

export default function GenericDateTimePickerInput({ register, name, label, type}) {
  const [startDate, setStartDate] = React.useState(new Date());
  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        onClick={onClick}
        type="text"
        ref={ref}
        value={value}
        {...register(name)}
      />
    </>
  ));
  return (
    <div>
      <ReactDatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        customInput={<ExampleCustomInput />}
        dateFormat={type == 'dateTime' ? dateTimeFormat:dateFormat}
        showTimeSelect={type === 'dateTime'}
      />
    </div>
  );
}
