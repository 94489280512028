import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { useForm } from "react-hook-form";
import { createMockUser, editMockUser } from "../../services/UserServices";
import { Col, Form, Row } from "react-bootstrap";
import GenericRadioInput from "../../components/Input/GenericRadioInput";
import GenericTextInput from "../../components/Input/GenericTextInput";
import SaveButton from "../../components/Button/SaveButton";
import CancelButton from "../../components/Button/CancelButton";
import { editSettingData } from "../../services/SettingServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";

const schema = yup.object().shape({
  isDiscountCodeSupport: yup.bool().required("ห้ามว่าง"),
  transportationFeePerTime: yup
    .number()
    .required("ห้ามว่าง")
    .typeError("ต้องเป็นตัวเลข"),
});

export default function EditShopPurchaseSettingForm({
  onCancel,
  onSubmitSuccess,
  isDiscountCodeSupportDefaultValue,
  transportationFeePerTimeDefaultValue,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (inputData) => {
    const { success, data, error } = await editSettingData({
      pathParams: 1,
      body: {
        s_discount: +inputData.isDiscountCodeSupport,
        transport_fee: inputData.transportationFeePerTime,
      },
    });
    if (success) {
      onSubmitSuccess();
      ShowMessageAndExpire("Data updated successfully.", "", "success");
    } else {
      ShowMessageAndExpire(
        "Failed to update data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h4>ระบบซื้อขายสินค้า</h4>
        <hr />
        <Form.Group as={Row} className="mb-3" controlId="formBasicName">
          <GenericRadioInput
            register={register}
            name="isDiscountCodeSupport"
            label="รองรับการใช้รหัสส่วนลด"
            placeholder="รองรับการใช้รหัสส่วนลด"
            defaultChecked={isDiscountCodeSupportDefaultValue}
            error={errors.isDiscountCodeSupport}
            radios={[{ id: 1, label: "", value: true }]}
            type="switch"
            viewFormat="inline"
            required
          />
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formBasicName">
          <GenericTextInput
            register={register}
            name="transportationFeePerTime"
            label="ค่าขนส่งต่อครั้งแบบเหมาจ่าย"
            placeholder="ค่าขนส่งต่อครั้งแบบเหมาจ่าย"
            defaultValue={transportationFeePerTimeDefaultValue}
            type="number"
            required
            error={errors.transportationFeePerTime}
            viewFormat="inline"
          />
        </Form.Group>
        <Row className="mb-3">
          <Col>
            <CancelButton onClick={onCancel} />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <SaveButton />
          </Col>
        </Row>
      </Form>
    </>
  );
}
