import React from "react";
import { Button } from "react-bootstrap";

export default function DetailButton(props) {
  return (
    <Button
      {...props}
      variant="warning"
      active
      style={{ color: "white", marginLeft: "10px" }}
    >
      Detail
    </Button>
  );
}
