import React from "react";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import { Card, Container } from "react-bootstrap";
import EditBillingForm from "../features/Form/EditBillingForm";
import { getBillingInfo } from "../services/SettingServices";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";

export default function BillingSetting() {
  const [onLoading, setOnLoading] = React.useState(true);
  const [companyName, setCompanyName] = React.useState();
  const [address, setAddress] = React.useState();
  const [street, setStreet] = React.useState();
  const [subDistrict, setSubDistrict] = React.useState();
  const [district, setDistrict] = React.useState();
  const [province, setProvince] = React.useState();
  const [postcode, setPostcode] = React.useState();
  const [companyId, setCompanyId] = React.useState();
  const [logo, setLogo] = React.useState();
  const [billingInfoId, setBillingInfoId] = React.useState();

  const loadSetting = React.useCallback(async () => {
    setOnLoading(true);
    const { success, data, error } = await getBillingInfo({
      params: {
        "filter.id": 1,
        "filter._relations": "rel_m_subdistrict_id",
      },
    });
    if (success) {
      const setting = data.records[0];
      if (setting) {
        setCompanyName(setting.name);
        setAddress(setting.addressno);
        setStreet(setting.street);
        setSubDistrict(setting.m_subdistrict_id);
        setDistrict(setting.m_district_id);
        setProvince(setting.m_province_id);
        setPostcode(setting.postcode);
        setCompanyId(setting.tax_id);
        setLogo(`${setting.logo}&datetime:${new Date().toISOString()}`);
        setBillingInfoId(setting.id);
      }
    } else {
      ShowMessageAndExpire(
        "Failed to load data.",
        JSON.stringify(error?.message),
        "error"
      );
    }
    setOnLoading(false);
  }, []);

  React.useEffect(() => {
    loadSetting();
  }, [loadSetting]);

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard title={"Setting - Billing Info"} />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {onLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "10vh",
                    }}
                  >
                    {/* <Spinner
                      animation="border"
                      variant="info"
                      size="lg"
                      className="me-3"
                    />
                    <br/><h3>Loading...</h3> */}
                  </div>
                ) : (
                  <EditBillingForm
                    onCancel={loadSetting}
                    onSubmitSuccess={loadSetting}
                    companyNameDefaultValue={companyName}
                    addressDefaultValue={address}
                    streetDefaultValue={street}
                    subDistrictDefaultValue={subDistrict}
                    districtDefaultValue={district}
                    provinceDefaultValue={province}
                    postCodeDefaultValue={postcode}
                    companyIdDefaultValue={companyId}
                    companyLogoDefaultValue={logo}
                    id={billingInfoId}
                  />
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
