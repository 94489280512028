import React from "react";
import { bannerColumn } from "../data/Columns";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import Header from "../layouts/Header";
import GenericPagination from "../components/GenericPagination";
import { Card, Col, Container, Row } from "react-bootstrap";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import {
    getRecord,
    deleteRecord,
} from "../services/BannerServices";

import { StatusOptions, BannerTypeOptions, filterStatusOptions } from "../data/SelectOptions";
import SelectFilter from "../components/SelectFilter";

import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";

import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";

import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import CreateEditForm from "../features/Form/CreateEditBannerForm";

import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";

const allBannerStatus = "all";

export default function BannerManagement() {
    const columnsData = bannerColumn;

    const [tableData, setTableData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [total, setTotal] = React.useState(1);

    const [keyword, setKeyword] = React.useState("");
    const [recordStatus, setRecordStatus] = React.useState();
    const [dateStart, setDateStart] = React.useState();
    const [dateEnd, setDateEnd] = React.useState();

    const [isCreate, setIsCreate] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [isDelete, setIsDelete] = React.useState(false);

    const [deleteData, setDeleteData] = React.useState();
    const [editData, setEditData] = React.useState();

    const loadBannerInfo = React.useCallback(
        async (currentPage, pageSize, recordStatus, keyword, dateStart, dateEnd) => {
            const { success, data, error } = await getRecord({
                page: currentPage,
                pageSize: pageSize,
                search: keyword,
                status: recordStatus,
                dateStart,
                dateEnd
            });
            if (success) {
                // console.log(data.records);
                setTotal(data.total);
                setTableData(
                    prepareTableData(
                        data.records,
                        handleOpenEdit,
                        handleOpenDeleteConfirm
                    )
                );
            } else {
                ShowMessageAndExpire("Failed to load data.", error?.message, "error");
            }
        },
        []
    );

    React.useEffect(() => {
        loadBannerInfo(1, 10);
    }, [loadBannerInfo]);

    const handleOpenCreate = () => {
        setIsCreate(true);
    };
    const handleCloseCreate = () => {
        setIsCreate(false);
    };
    const handleCloseEdit = () => {
        setIsEdit(false);
        setIsCreate(false);
        setEditData(null);
    };
    const handleOpenEdit = (data) => {
        setIsEdit(true);
        setEditData(data);
    };
    

    const handleOpenDeleteConfirm = (data) => {
        console.log(data);
        setIsDelete(true);
        setDeleteData(data);
    };
    const handleCloseDeleteConfirm = () => {
        setIsDelete(false);
        setDeleteData(null);
    };

    const handleEdit = React.useCallback(async () => {
        handleCloseEdit();
        loadBannerInfo(currentPage, pageSize, recordStatus, keyword, dateStart, dateEnd);
    }, [loadBannerInfo, currentPage, pageSize, recordStatus, keyword, dateStart, dateEnd]);


    const handleDelete = React.useCallback(
        async (dataId) => {
            const { success, data, error } = await deleteRecord({
                id: dataId,
            });
            if (success) {
                handleCloseDeleteConfirm();
                let tempCurrentPage = currentPage;
                if (
                    (total - pageSize * (currentPage - 1) === 1) &
                    (currentPage !== 1)
                ) {
                    setCurrentPage(currentPage - 1);
                    tempCurrentPage = tempCurrentPage - 1;
                }
                loadBannerInfo(tempCurrentPage, pageSize, recordStatus, keyword, dateStart, dateEnd);
            } else {
                console.log(error);
            }
        },
        [loadBannerInfo, total, currentPage, pageSize, recordStatus, keyword, dateStart, dateEnd]
    );

    const handleSearch = React.useCallback(
        async (keyword) => {
            console.log(keyword);
            console.log(pageSize);
            setCurrentPage(1);
            setKeyword(keyword);
            await loadBannerInfo(1, pageSize, recordStatus, keyword, dateStart, dateEnd);
        },
        [loadBannerInfo, pageSize, recordStatus, dateStart, dateEnd]
    );
    const handleSelectedDate = React.useCallback(
        async (sDate, eDate) => {
            setDateStart(sDate);
            setDateEnd(eDate);
            setCurrentPage(1);
            await loadBannerInfo(1, pageSize, recordStatus, keyword, sDate, eDate);
        },
        [loadBannerInfo, pageSize, recordStatus, keyword]
    );


    const loadPageOnPageChange = React.useCallback(
        async (page) => {
            setCurrentPage(page);
            await loadBannerInfo(page, pageSize, recordStatus, keyword, dateStart, dateEnd);
        },
        [loadBannerInfo, pageSize, recordStatus, keyword, dateStart, dateEnd]
    );

    const loadPageOnPageSizeChange = React.useCallback(
        async (pageSize) => {
            setCurrentPage(1);
            setPageSize(pageSize);
            await loadBannerInfo(1, pageSize, recordStatus, keyword, dateStart, dateEnd);
        },
        [loadBannerInfo, recordStatus, keyword, dateStart, dateEnd]
    );

    const loadPageOnStatusChange = React.useCallback(
        async (recordStatus) => {
            setCurrentPage(1);
            setRecordStatus(recordStatus);
            await loadBannerInfo(1, pageSize, recordStatus, keyword, dateStart, dateEnd);
        },
        [loadBannerInfo, pageSize, keyword, dateStart, dateEnd]
    );

    return (
        <>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="p-3 p-lg-4">
                    <PageHeaderCard
                        title={
                            isEdit
                                ? `Banner Info : ID ${editData?.id} หัวข้อ ${editData?.subject} ประเภท ${ BannerTypeOptions.find((e) => e.value == editData.m_banner_type_id)?.label ?? editData.m_banner_type_id }`
                                : isCreate ? "Banner Detail"
                                    : "Banner"

                        }
                        buttonEnable={!isEdit && !isCreate}
                        buttonIcon="plus"
                        buttonMessage="Create New Banner"
                        onButtonOnClick={handleOpenCreate}
                    />
                    <DeleteConfirmModal
                        show={isDelete}
                        onClose={handleCloseDeleteConfirm}
                        onComfirm={handleDelete}
                        deleteRecord={deleteData?.id}
                        confirmMessage={
                            <>
                                <p>{"Do you really want to delete these record?"}</p>
                                <p>
                                    {`Banner ID ${deleteData?.id}, หัวข้อ ${deleteData?.subject}, ประเภท  ${deleteData && (BannerTypeOptions.find((e) => e.value == deleteData.m_banner_type_id).label)} `}
                                </p>
                            </>
                        }
                    />
                    <Card>
                        <Card.Body>
                            <Container fluid style={{ minHeight: "30rem" }}>
                                {isCreate ? (
                                    <>
                                        <CreateEditForm
                                            onCancel={handleCloseCreate}
                                            onSubmitSuccess={handleEdit}
                                            id=""
                                            status={2}
                                        />
                                    </>
                                ) : isEdit ? (
                                    <>
                                        <CreateEditForm
                                            onCancel={handleCloseEdit}
                                            onSubmitSuccess={handleEdit}
                                            id={editData?.id}
                                            banner_type={editData?.m_banner_type_id}
                                            status={editData?.status}
                                            subject={editData?.subject}
                                            duration={editData?.dptime}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Row className="mt-3">
                                            <Col xs={6} style={{ display: "flex" }}>
                                                <Searching placeholder="Filter : หัวข้อแบนเนอร์ , วันที่ทำรายการ , ประเภทแบนเนอร์"
                                                    onSearch={handleSearch}
                                                    defaultValue={keyword}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <SelectFilter
                                                    label="สถานะ"
                                                    placeholder={"สถานะ"}
                                                    defaultValue={filterStatusOptions.find(
                                                        (e) => e.value == allBannerStatus
                                                    )}
                                                    value={filterStatusOptions.find(
                                                        (e) => e.value == recordStatus
                                                    )}
                                                    options={filterStatusOptions}
                                                    onChange={loadPageOnStatusChange}
                                                />
                                                <ReactDateTimeRangePicker2
                                                    onSelected={handleSelectedDate}
                                                    defaultStart={dateStart ? new Date(dateStart) : null}
                                                    defaultEnd={dateEnd ? new Date(dateEnd) : null}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <ReactTable columns={columnsData} data={tableData} />
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <GenericPagination
                                                    totalPage={Math.ceil(total / pageSize)}
                                                    currentPage={currentPage}
                                                    pageSize={pageSize}
                                                    onPageChange={loadPageOnPageChange}
                                                    onPageSizeChange={loadPageOnPageSizeChange}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Container>
                        </Card.Body>
                    </Card>
                </div>
            </div>


        </>

    );
}

const prepareTableData = (datas, onEdit, onDelete) => {
    let tableData = [];
    for (const data of datas) {
        tableData.push({
            id: data.id,
            subject: data.subject,
            update_date: data.update_date,
            m_banner_type_id: BannerTypeOptions.find((e) => e.value == data.m_banner_type_id)?.label ?? data.m_banner_type_id ,
            status: StatusOptions.find((e) => e.value == data.status).label ?? data.status,
            editDeleteButton: (
                <>
                    <EditButton
                        onClick={() => {
                            onEdit(data);
                        }}
                    />
                    {/* {" "}
                    <DeleteButton
                        onClick={() => {
                            onDelete(data);
                        }}
                    /> */}
                </>
            ),
        });
    }
    //console.log(tableData);
    return tableData;
}; 