import React from "react";
import { Form } from "react-bootstrap";
import { ScheduleTypeFilters } from "../../data/Filters";

export default function ScheduleTypeFilter({ checked, onChange, type }) {
  return (
    <>
      <Form>
        <div key={`type-filter-radio`} className="mb-3">
          <Form.Check
            inline
            label="Booking"
            name="checkScheduleType"
            type={type}
            id={`type-filter-radio-1`}
            onChange={(e) => {
              if (type === "radio") {
                onChange(e.target.value);
              } else {
                onChange(e);
              }
            }}
            value={ScheduleTypeFilters.Booking}
            checked={
              type === "radio"
                ? checked === ScheduleTypeFilters.Booking
                : checked.includes(ScheduleTypeFilters.Booking)
            }
          />
          <Form.Check
            inline
            label="Leave"
            name="checkScheduleType"
            type={type}
            id={`type-filter-radio-2`}
            onChange={(e) => {
              if (type === "radio") {
                onChange(e.target.value);
              } else {
                onChange(e);
              }
            }}
            value={ScheduleTypeFilters.Leave}
            checked={
              type === "radio"
                ? checked === ScheduleTypeFilters.Leave
                : checked.includes(ScheduleTypeFilters.Leave)
            }
          />
          <Form.Check
            inline
            label="Time Table"
            name="checkScheduleType"
            type={type}
            id={`type-filter-radio-3`}
            onChange={(e) => {
              if (type === "radio") {
                onChange(e.target.value);
              } else {
                onChange(e);
              }
            }}
            value={ScheduleTypeFilters.Schedules}
            checked={
              type === "radio"
                ? checked === ScheduleTypeFilters.Schedules
                : checked.includes(ScheduleTypeFilters.Schedules)
            }
          />
        </div>
      </Form>
    </>
  );
}
