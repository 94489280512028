import React from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

export default function GenericRadioInput({
  register,
  radios = [],
  label,
  name,
  required,
  disabled,
  error,
  type = "radio",
  defaultChecked = [],
  checked = [],
  onClick,
  viewFormat,
  labelColSize = 3,
}) {
  const renderInput = () => {
    const input = [];
    for (const [idx, radio] of radios.entries()) {
      if (register) {
        input.push(
          <Form.Check
            key={`${label}-${type}-${idx}`}
            {...register(name)}
            name={name}
            type={type}
            label={radio.label}
            value={radio.value}
            inline
            isInvalid={error}
            id={`${label}-${type}-${idx}`}
            disabled={disabled}
            defaultChecked={defaultChecked.includes(radio.value)}
            onClick={(e) => {
              if (onClick) {
                onClick(e);
              }
            }}
          />
        );
      } else if (disabled) {
        input.push(
          <Form.Check
            key={`${label}-${type}-${idx}`}
            type={type}
            label={radio.label}
            value={radio.value}
            inline
            isInvalid={error}
            id={`${label}-${type}-${idx}`}
            disabled={disabled}
            checked={checked.includes(radio.value)}
            onClick={(e) => {
              if (onClick) {
                onClick(e);
              }
            }}
          />
        );
      } else {
        input.push(
          <Form.Check
            key={`${label}-${type}-${idx}`}
            type={type}
            label={radio.label}
            value={radio.value}
            inline
            isInvalid={error}
            id={`${label}-${type}-${idx}`}
            disabled={disabled}
            defaultChecked={defaultChecked.includes(radio.value)}
            // checked={checked.includes(radio.value)}
            onClick={(e) => {
              if (onClick) {
                onClick(e);
              }
            }}
          />
        );
      }
    }
    return input;
  };

  const renderLabelText = () => {
    return (
      <>
        {label}
        <p style={{ color: "red", display: "inline" }}>{required && "*"}</p>
      </>
    );
  };

  return (
    <>
      {label && (
        <>
          {viewFormat === "inline" ? (
            <Form.Label column sm={labelColSize}>
              {renderLabelText()}
            </Form.Label>
          ) : (
            <Form.Label>{renderLabelText()}</Form.Label>
          )}

          <br />
        </>
      )}
      {viewFormat === "inline" ? (
        <>
          <Col>
            {renderInput()}
            {error?.message && (
              <p
                style={{
                  color: "#dc3545",
                  fontSize: "0.875em",
                  marginTop: "0.25rem",
                }}
              >
                {error?.message}
              </p>
            )}
          </Col>
        </>
      ) : (
        <div>
          {renderInput()}
          {error?.message && (
            <p
              style={{
                color: "#dc3545",
                fontSize: "0.875em",
                marginTop: "0.25rem",
              }}
            >
              {error?.message}
            </p>
          )}
        </div>
      )}
    </>
  );
}
