import React from "react"
import PageHeaderCard from "../components/Card/PageHeaderCard"
import Header from "../layouts/Header"
import { Button, Card, Col, Container, Row, Modal } from "react-bootstrap"
import GenericPagination from "../components/GenericPagination"
import ReactTable from "../components/ReactTable"
import { doctorTimeScheduleColumn } from "../data/Columns"
import {
  getAvailableTimeSchedule,
  getBookingTimeSchedule,
  getLeaveTimeSchedule,
} from "../services/DoctorTimeScheduleServices"
import GenericButton from "../components/Button/GenericButton"
import { useNavigate } from "react-router"
import ScheduleTypeFilter from "../features/Filter/ScheduleTypeFilter"
import PeriodTimeFilter from "../features/Filter/PeriodTimeFilter"
import { ScheduleTimePeriodFilters, ScheduleTypeFilters } from "../data/Filters"
import DoctorTimeScheduleFilter from "../features/Filter/DoctorTimeScheduleFilter"
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire"

const allBookingStatusOption = null

export default function DoctorTimeScheduleTable() {
  const navigate = useNavigate()
  const [total, setTotal] = React.useState(1)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(10)
  const [keyword, setKeyword] = React.useState("")
  const [selectScheduleType, setSelectScheduleType] = React.useState(
    ScheduleTypeFilters.Booking
  )
  const [selectTimePeriod, setSelectTimePeriod] = React.useState(
    ScheduleTimePeriodFilters.All
  )
  const [startDate, setStartDate] = React.useState()
  const [endDate, setEndDate] = React.useState()
  const [hospitalListFilter, setHospitalListFilter] = React.useState([])
  const [doctorListFilter, setDoctorListFilter] = React.useState([])
  const [bookingStatus, setBookingStatus] = React.useState(
    allBookingStatusOption
  )
  const [tableData, setTableData] = React.useState([])
  let columnsData = doctorTimeScheduleColumn
  const [modalShow, setModalShow] = React.useState(false)
  const [modalDesc, setModalDesc] = React.useState(false)

  const handleShowDetail = ({
    scheduleType,
    start,
    end,
    doctorName,
    description,
    status,
  }) => {
    let txt = (
      <>
        Type : {scheduleType}
        <br />
        ชื่อหมอ : {doctorName}
        <br />
        Start : {start}
        <br />
        End : {end}
        <br />
        Description : {description}
        สถานะ : {status}
      </>
    )
    setModalDesc(txt)
    setModalShow(true)
  }

  const loadDoctorTimeSchedule = React.useCallback(
    async (
      selectScheduleType,
      currentPage,
      pageSize,
      keyword,
      hospitalListFilter,
      doctorListFilter,
      bookingStatus,
      startDate,
      endDate,
      selectTimePeriod
    ) => {
      setTotal(0)
      setTableData([])
      if (selectScheduleType === ScheduleTypeFilters.Booking) {
        const { success, data, error } = await getBookingTimeSchedule({
          params: {
            page: currentPage,
            limit: pageSize,
            search: keyword,
            hospital_ids: hospitalListFilter.join(","),
            doctor_ids: doctorListFilter.join(","),
            "filter.status": bookingStatus,
            date_from: startDate && `${startDate} 00:00:00`,
            date_to: endDate && `${endDate} 23:59:59`,
            period: selectTimePeriod,
          },
        })
        if (success) {
          setTotal(data.total)
          setTableData(prepareTableBookingData(data.records, handleShowDetail))
        } else {
          ShowMessageAndExpire(
            "Failed to load data.",
            JSON.stringify(error?.message),
            "error"
          )
        }
      } else if (selectScheduleType === ScheduleTypeFilters.Leave) {
        const { success, data, error } = await getLeaveTimeSchedule({
          params: {
            page: currentPage,
            limit: pageSize,
            search: keyword,
            hospital_ids: hospitalListFilter.join(","),
            doctor_ids: doctorListFilter.join(","),
            "filter.status": bookingStatus,
            date_from: startDate && `${startDate} 00:00:00`,
            date_to: endDate && `${endDate} 23:59:59`,
            period: selectTimePeriod,
          },
        })
        if (success) {
          setTotal(data.total)
          setTableData(prepareTableLeaveData(data.records, handleShowDetail))
        } else {
          ShowMessageAndExpire(
            "Failed to load data.",
            JSON.stringify(error?.message),
            "error"
          )
        }
      } else if (selectScheduleType === ScheduleTypeFilters.Schedules) {
        const { success, data, error } = await getAvailableTimeSchedule({
          params: {
            page: currentPage,
            limit: pageSize,
            search: keyword,
            hospital_ids: hospitalListFilter.join(","),
            doctor_ids: doctorListFilter.join(","),
            "filter.status": bookingStatus,
            date_from: startDate && `${startDate} 00:00:00`,
            date_to: endDate && `${endDate} 23:59:59`,
            period: selectTimePeriod,
          },
        })
        if (success) {
          setTotal(data.total)
          setTableData(
            prepareTableAvailableData(data.records, handleShowDetail)
          )
        } else {
          ShowMessageAndExpire(
            "Failed to load data.",
            JSON.stringify(error?.message),
            "error"
          )
        }
      }
    },
    []
  )

  React.useEffect(() => {
    loadDoctorTimeSchedule(
      ScheduleTypeFilters.Booking,
      1,
      10,
      "",
      [],
      [],
      allBookingStatusOption,
      null,
      null,
      ScheduleTimePeriodFilters.All
    )
  }, [loadDoctorTimeSchedule])

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        page,
        pageSize,
        keyword,
        hospitalListFilter,
        doctorListFilter,
        bookingStatus,
        startDate,
        endDate,
        selectTimePeriod
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      pageSize,
      keyword,
      hospitalListFilter,
      doctorListFilter,
      bookingStatus,
      startDate,
      endDate,
      selectTimePeriod,
    ]
  )

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1)
      setPageSize(pageSize)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        1,
        pageSize,
        keyword,
        hospitalListFilter,
        doctorListFilter,
        bookingStatus,
        startDate,
        endDate,
        selectTimePeriod
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      keyword,
      hospitalListFilter,
      doctorListFilter,
      bookingStatus,
      startDate,
      endDate,
      selectTimePeriod,
    ]
  )

  const loadPageOnUserStatusChange = React.useCallback(
    async (bookingStatus) => {
      setCurrentPage(1)
      setBookingStatus(bookingStatus)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        1,
        pageSize,
        keyword,
        hospitalListFilter,
        doctorListFilter,
        bookingStatus,
        startDate,
        endDate,
        selectTimePeriod
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      pageSize,
      keyword,
      hospitalListFilter,
      doctorListFilter,
      startDate,
      endDate,
      selectTimePeriod,
    ]
  )

  const loadPageOnDoctorListFilterChange = React.useCallback(
    async (doctorListFilter) => {
      const listId = doctorListFilter.map((value) => value.value)
      setCurrentPage(1)
      setDoctorListFilter(listId)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        1,
        pageSize,
        keyword,
        hospitalListFilter,
        listId,
        bookingStatus,
        startDate,
        endDate,
        selectTimePeriod
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      pageSize,
      keyword,
      hospitalListFilter,
      bookingStatus,
      startDate,
      endDate,
      selectTimePeriod,
    ]
  )

  const loadPageOnHospitalListFilterChange = React.useCallback(
    async (hospitalListFilter) => {
      const listId = hospitalListFilter.map((value) => value.value)
      setCurrentPage(1)
      setHospitalListFilter(listId)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        1,
        pageSize,
        keyword,
        listId,
        doctorListFilter,
        bookingStatus,
        startDate,
        endDate,
        selectTimePeriod
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      pageSize,
      keyword,
      doctorListFilter,
      bookingStatus,
      startDate,
      endDate,
      selectTimePeriod,
    ]
  )

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1)
      setKeyword(keyword)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        1,
        pageSize,
        keyword,
        hospitalListFilter,
        doctorListFilter,
        bookingStatus,
        startDate,
        endDate,
        selectTimePeriod
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      pageSize,
      hospitalListFilter,
      doctorListFilter,
      bookingStatus,
      startDate,
      endDate,
      selectTimePeriod,
    ]
  )

  const handleSelectedDate = React.useCallback(
    async (startDate, endDate) => {
      setStartDate(startDate)
      setEndDate(endDate)
      setCurrentPage(1)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        1,
        pageSize,
        keyword,
        hospitalListFilter,
        doctorListFilter,
        bookingStatus,
        startDate,
        endDate,
        selectTimePeriod
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      pageSize,
      keyword,
      hospitalListFilter,
      doctorListFilter,
      bookingStatus,
      selectTimePeriod,
    ]
  )

  const handleScheduleTypeChange = React.useCallback(
    async (scheduleType) => {
      setCurrentPage(1)
      setBookingStatus(allBookingStatusOption)
      setSelectScheduleType(scheduleType)
      await loadDoctorTimeSchedule(
        scheduleType,
        1,
        pageSize,
        keyword,
        hospitalListFilter,
        doctorListFilter,
        allBookingStatusOption,
        startDate,
        endDate,
        selectTimePeriod
      )
    },
    [
      loadDoctorTimeSchedule,
      pageSize,
      keyword,
      hospitalListFilter,
      doctorListFilter,
      startDate,
      endDate,
      selectTimePeriod,
    ]
  )

  const handleTimePeriodChange = React.useCallback(
    async (timePeriod) => {
      setCurrentPage(1)
      setSelectTimePeriod(timePeriod)
      await loadDoctorTimeSchedule(
        selectScheduleType,
        1,
        pageSize,
        keyword,
        hospitalListFilter,
        doctorListFilter,
        bookingStatus,
        startDate,
        endDate,
        timePeriod
      )
    },
    [
      loadDoctorTimeSchedule,
      selectScheduleType,
      pageSize,
      keyword,
      hospitalListFilter,
      doctorListFilter,
      bookingStatus,
      startDate,
      endDate,
    ]
  )

  const handleDetailModalClose = () => {
    setModalShow(false)
    setModalDesc("")
  }

  columnsData = doctorTimeScheduleColumn.map((column) => {
    if (
      selectScheduleType === ScheduleTypeFilters.Schedules &&
      column.accessor === "bookingDate"
    ) {
      return {
        ...column,
        Header: "Schedules Date",
      }
    } else if (
      selectScheduleType === ScheduleTypeFilters.Leave &&
      column.accessor === "bookingDate"
    ) {
      return {
        ...column,
        Header: "Leave Date",
      }
    } else if (
      selectScheduleType === ScheduleTypeFilters.Booking &&
      column.accessor === "bookingDate"
    ) {
      return {
        ...column,
        Header: "Booking Date",
      }
    }
    return column // Return the original column if no condition matches
  })

  return (
    <>
      <Modal
        show={modalShow}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>{modalDesc}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDetailModalClose}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Container fluid style={{ minHeight: "30rem" }}>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <div className="p-3 p-lg-4">
            <PageHeaderCard title="Doctor Time Schedule" buttonEnable={false} />
            <Card>
              <Card.Body>
                <PeriodTimeFilter
                  checked={selectTimePeriod}
                  onChange={handleTimePeriodChange}
                />
                <ScheduleTypeFilter
                  checked={selectScheduleType}
                  onChange={handleScheduleTypeChange}
                  type="radio"
                />
                <>
                  <GenericButton
                    onClick={() => {
                      navigate("/pages/doctor-time-schedule-calendar")
                    }}
                  >
                    {"Calendar View"}
                  </GenericButton>
                  <DoctorTimeScheduleFilter
                    onSearch={handleSearch}
                    onSelectHospital={loadPageOnHospitalListFilterChange}
                    onSelectDoctor={loadPageOnDoctorListFilterChange}
                    onSelectBookingStatus={loadPageOnUserStatusChange}
                    bookingStatusSelect={bookingStatus}
                    onSelectDateTimeRange={handleSelectedDate}
                    disableBookingStatusFilter={
                      selectScheduleType !== ScheduleTypeFilters.Booking
                    }
                  />
                  <Row className="mt-3">
                    <Col>
                      <ReactTable columns={columnsData} data={tableData} />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <GenericPagination
                        totalPage={Math.ceil(total / pageSize)}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={loadPageOnPageChange}
                        onPageSizeChange={loadPageOnPageSizeChange}
                      />
                    </Col>
                  </Row>
                </>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </>
  )
}

const prepareTableBookingData = (datas, onShowDetail) => {
  let tableData = []
  for (const data of datas) {
    tableData.push({
      bookingDate: (
        <>
          {data.create_date
            ? new Date(data.create_date).toLocaleString("sv")
            : ""}
        </>
      ),
      scheduleType: data.type,
      doctorName: data.doctor_fullname,
      description: (
        <>
          <li>
            {data.date_to !== "0000-00-00 00:00:00"
              ? `${new Date(data.date_from).toLocaleString(
                  "sv"
                )} ถึง ${new Date(data.date_to).toLocaleString("sv")}`
              : `${new Date(data.date_from).toLocaleString("sv")}`}
          </li>
        </>
      ),
      status: data.status || "",
      btn: (
        <Button
          variant="warning"
          onClick={() =>
            onShowDetail({
              scheduleType: data.type,
              start: new Date(data.date_from).toLocaleString("sv") || "",
              end: new Date(data.date_to).toLocaleString("sv") || "",
              doctorName: data.doctor_fullname,
              description: (
                <>
                  <ul>
                    <li>
                      {data.date_to !== "0000-00-00 00:00:00"
                        ? `${new Date(data.date_from).toLocaleString(
                            "sv"
                          )} ถึง ${new Date(data.date_to).toLocaleString("sv")}`
                        : `${new Date(data.date_from).toLocaleString("sv")}`}
                    </li>
                  </ul>
                </>
              ),
              status: data.status || "",
            })
          }
        >
          Detail
        </Button>
      ),
    })
  }
  return tableData
}

const prepareTableLeaveData = (datas, onShowDetail) => {
  let tableData = []
  for (const data of datas) {
    tableData.push({
      bookingDate: (
        <>
          {data.create_date
            ? new Date(data.create_date).toLocaleString("sv")
            : ""}
        </>
      ),
      scheduleType: data.type,
      doctorName: data.doctor_fullname,
      description: (
        <>
          <ul>
            <li>
              {data.date_to !== "0000-00-00 00:00:00"
                ? `${new Date(data.date_from).toLocaleString(
                    "sv"
                  )} ถึง ${new Date(data.date_to).toLocaleString("sv")}`
                : `${new Date(data.date_from).toLocaleString("sv")}`}
            </li>
          </ul>
        </>
      ),
      status: data.status || "",
      btn: (
        <Button
          variant="warning"
          onClick={() =>
            onShowDetail({
              scheduleType: data.type,
              start: new Date(data.date_from).toLocaleString("sv") || "",
              end: new Date(data.date_to).toLocaleString("sv") || "",
              doctorName: data.doctor_fullname,
              description: (
                <>
                  <ul>
                    <li>
                      {data.date_to !== "0000-00-00 00:00:00"
                        ? `${new Date(data.date_from).toLocaleString(
                            "sv"
                          )} ถึง ${new Date(data.date_to).toLocaleString("sv")}`
                        : `${new Date(data.date_from).toLocaleString("sv")}`}
                    </li>
                  </ul>
                </>
              ),
              status: data.status || "",
            })
          }
        >
          Detail
        </Button>
      ),
    })
  }
  return tableData
}

const prepareTableAvailableData = (datas, onShowDetail) => {
  let tableData = []
  for (const data of datas) {
    tableData.push({
      bookingDate: (
        <>
          {data.create_date
            ? new Date(data.create_date).toLocaleString("sv")
            : ""}
        </>
      ),
      scheduleType: data.type,
      doctorName: data.doctor_fullname,
      description: (
        <>
          <ul>
            <li>
              {data.date_to !== "0000-00-00 00:00:00"
                ? `${new Date(data.date_from).toLocaleString(
                    "sv"
                  )} ถึง ${new Date(data.date_to).toLocaleString("sv")}`
                : `${new Date(data.date_from).toLocaleString("sv")}`}
            </li>
          </ul>
        </>
      ),
      status: data.status || "",
      btn: (
        <Button
          variant="warning"
          onClick={() =>
            onShowDetail({
              scheduleType: data.type,
              start: new Date(data.date_from).toLocaleString("sv") || "",
              end: new Date(data.date_to).toLocaleString("sv") || "",
              doctorName: data.doctor_fullname,
              description: (
                <>
                  <ul>
                    <li>
                      {data.date_to !== "0000-00-00 00:00:00"
                        ? `${new Date(data.date_from).toLocaleString(
                            "sv"
                          )} ถึง ${new Date(data.date_to).toLocaleString("sv")}`
                        : `${new Date(data.date_from).toLocaleString("sv")}`}
                    </li>
                  </ul>
                </>
              ),
              status: data.status || "",
            })
          }
        >
          Detail
        </Button>
      ),
    })
  }
  return tableData
}
