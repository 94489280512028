import React from "react";
import { auditLogColumn } from "../data/Columns";
import { auditLogCSVHeader } from "../data/CSVHeader";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import FilterDropdown from "../components/Input/Dropdown/GenericDropdown";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import { getAuditLog, getMockAuditLog } from "../services/LogServices";
import ReactDateTimeRangePicker from "../components/Input/ReactDateTimeRangePicker";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";
import ExportCSVButton from "../components/Button/ExportCSVButton";

export default function AuditLog() {
  const csvHeader = auditLogCSVHeader;
  const csvFilename = "AuditLog.csv";
  const [isExport, setIsExport] = React.useState(false);

  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");

  const [dateStart, setDateStart] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();
  const allUserStatus = "";
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [tableAuditLogChange, setTableAuditLogChange] = React.useState([]);
  const [csvData, setCsvData] = React.useState([]);

  const loadAuditLog = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, dateStart, dateEnd) => {
      const { success, data, error } = await getAuditLog({
        page: currentPage,
        limit: pageSize,
        search: keyword,
        dateStart,
        dateEnd,
      });
      if (success) {
        setTotal(data.total);
        setTableAuditLogChange(prepareData(data.records));
        setCsvData(prepareCSVData(data.records));
      } else {
        console.log(error);
      }
    },
    []
  );
  React.useEffect(() => {
    loadAuditLog(1, 10, allUserStatus);
  }, [loadAuditLog]);

  const columnsAuditLogsChange = auditLogColumn;

  const prepareData = (datas, onEdit, onDelete) => {
    let listsData = [];
    for (const data of datas) {
      listsData.push({
        id: data.report_id,
        dateTime: data.action_datetime,
        name: data.username,
        role: data.user_group,
        path: data.path,
        action: data.action,
        reference: data.reference,
        referId: data.reference_id,
        ip: data.ip_address,
        note: data.remark,
      });
    }
    return listsData;
  };

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadAuditLog(page, pageSize, userStatus, keyword);
    },
    [loadAuditLog, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadAuditLog(1, pageSize, userStatus, keyword);
    },
    [loadAuditLog, userStatus, keyword]
  );
  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadAuditLog(1, pageSize, userStatus, keyword);
    },
    [loadAuditLog, pageSize, keyword]
  );

  const refreshPage = () => {
    setCurrentPage(1);
    setKeyword("");
    setUserStatus(allUserStatus);
    loadAuditLog(1, pageSize, allUserStatus, "");
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadAuditLog(1, pageSize, userStatus, keyword, dateStart, dateEnd);
    },
    [loadAuditLog, pageSize, userStatus, keyword, dateStart, dateEnd]
  );
  const handleSelectedDate = React.useCallback(
    async (sDate, eDate) => {
      setDateStart(sDate);
      setDateEnd(eDate);
      setCurrentPage(1);
      await loadAuditLog(1, pageSize, userStatus, keyword, sDate, eDate);
    },
    [loadAuditLog, pageSize, userStatus, keyword]
  );
  const handleExportCSV = () => {
    setIsExport(true);
  };
  const handleExportComplete = () => {
    setIsExport(false); // Reset the trigger to false
  };

  const prepareCSVData = (datas) => {
    let csvData = [];
    for (const data of datas) {
      csvData.push({
        report_id: data.report_id,
        action_datetime: data.action_datetime,
        username: data.username,
        user_group: data.user_group,
        path: data.path,
        action: data.action,
        reference: data.reference,
        reference_id: data.reference_id,
        ip_address: data.ip_address,
        remark: data.remark,
      });
    }
    return csvData;
  };

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
        <ExportCSVButton
              csvData={csvData}
              csvHeader={csvHeader}
              filename={csvFilename}
              enclose=""
              showBtn={false}
              isExport={isExport}
              onExportComplete={handleExportComplete}
          />
          <PageHeaderCard
            title="Audit Log"
            buttonEnable={true}
            buttonMessage="Export CSV"
            onButtonOnClick={handleExportCSV}
          />

          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                <>
                  <Row className="mt-3">
                    <Col xs={6} style={{ display: "flex" }}>
                      <Searching
                        placeholder="Filter :"
                        onSearch={handleSearch}
                        defaultValue={keyword}
                      />
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <ReactDateTimeRangePicker2
                        onSelected={handleSelectedDate}
                        defaultStart={dateStart ? new Date(dateStart) : null}
                        defaultEnd={dateEnd ? new Date(dateEnd) : null}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <ReactTable
                        columns={columnsAuditLogsChange}
                        data={tableAuditLogChange}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <GenericPagination
                        totalPage={Math.ceil(total / pageSize)}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={loadPageOnPageChange}
                        onPageSizeChange={loadPageOnPageSizeChange}
                      />
                    </Col>
                  </Row>
                </>
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
