import React from "react";
import feather from "feather-icons/dist/feather";
import GenericButton from "./GenericButton";

export default function SaveButton(props) {
  React.useEffect(() => {
    feather.replace();
  }, []);
  return (
    <GenericButton {...props} variant="primary" type="submit">
      <i data-feather="save" className="me-1" />
      Save
    </GenericButton>
  );
}
