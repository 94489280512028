import React from "react";
import * as yup from "yup";
import { Col, Form, Modal, Row } from "react-bootstrap";
import GenericTextInput from "../../components/Input/GenericTextInput";
import CancelButton from "../../components/Button/CancelButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SaveButton from "../../components/Button/SaveButton";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";
import {
  editPhotoSetEmsVanDetail,
  uploadPhotoSetEmsVan,
} from "../../services/EmsVanServices";
import { uploadImage } from "../../services/SettingServices";

export default function AddEmsVanPhotoSetModal({
  isCreate,
  show,
  onClose,
  onSave,
  emsVanId,
  vanPictureId,
  descriptionDefaultValue,
  orderDefaultValue,
  imageDefaultValue,
}) {
  const [previewImage, setPreviewImage] = React.useState();
  const [yubSchema, setYubSchema] = React.useState(yup.object().shape());

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yubSchema),
  });

  const onCancel = async () => {
    setPreviewImage(null);

    reset();
    onClose();
  };

  const onSubmit = async (inputData) => {
    const photoSetFile = inputData.image[0];
    let bodyFormData = new FormData();
    let success, error;
    if (isCreate) {
      bodyFormData.append("image", photoSetFile, photoSetFile?.name);
      bodyFormData.append("van_info_id", emsVanId);
      bodyFormData.append("description", inputData.description);
      bodyFormData.append("order", inputData.order);
      ({ success, error } = await uploadPhotoSetEmsVan({
        bodyFormData: bodyFormData,
      }));
      if (success) {
        onSave();
        ShowMessageAndExpire("Upload Data Success", "", "success");
      } else {
        ShowMessageAndExpire(
          "Upload Photo Set Failed",
          JSON.stringify(error?.message),
          "error"
        );
      }
    } else {
      ({ success, error } = await editPhotoSetEmsVanDetail({
        pathParams: vanPictureId,
        body: {
          // van_info_id: emsVanId,
          description: inputData.description,
          order: inputData.order,
        },
      }));
      if (photoSetFile) {
        bodyFormData.append("image", photoSetFile, photoSetFile?.name);
        bodyFormData.append("res_model", "van_picture");
        bodyFormData.append("res_id", vanPictureId);
        bodyFormData.append("res_field", "van_picture_path");
        ({ success, error } = await uploadImage({
          bodyFormData: bodyFormData,
        }));
        if (error) {
          ShowMessageAndExpire(
            "Upload Photo Set Failed",
            JSON.stringify(error?.message),
            "error"
          );
        }
      }
      if (success) {
        onSave();
        ShowMessageAndExpire("Data updated successfully.", "", "success");
      } else {
        ShowMessageAndExpire(
          "Failed to update data.",
          JSON.stringify(error?.message),
          "error"
        );
      }
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  React.useEffect(() => {
    setPreviewImage(imageDefaultValue);
    let tempYupObj = {
      description: yup.string().required("ห้ามว่าง"),
      order: yup.number().required("ห้ามว่าง").typeError("โปรดระบุเป็นตัวเลข"),
    };
    if (isCreate) {
      tempYupObj["image"] = yup
        .mixed()
        .required("ห้ามว่าง")
        .test("fileType", "ห้ามว่าง", (value) => {
          return value[0] ? true : false;
        })
        .test("fileType", "ไฟล์ผิดประเภท", (value) => {
          if (!value) {
            return false;
          }
          const supportedFormats = ["image/jpeg", "image/png"];
          return supportedFormats.includes(value[0]?.type);
        });
    } else {
      tempYupObj["image"] = yup
        .mixed()
        .test("fileType", "ไฟล์ผิดประเภท", (value) => {
          //  not check if null (optional field when edit)
          if (!value[0]) {
            return true;
          }
          const supportedFormats = ["image/jpeg", "image/png"];
          return supportedFormats.includes(value[0]?.type);
        });
    }
    const schema = yup.object().shape(tempYupObj);
    setYubSchema(schema);
  }, [imageDefaultValue]);

  return (
    <Modal
      show={show}
      onHide={() => onCancel()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{isCreate ? "เพิ่มรูปภาพ" : "แก้ไขรูปภาพ"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="description"
                label="รายละเอียด"
                placeholder="รายละเอียด"
                defaultValue={descriptionDefaultValue}
                required
                error={errors.description}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="order"
                label="ลำดับการแสดง"
                placeholder="ลำดับการแสดง"
                defaultValue={orderDefaultValue}
                required
                error={errors.order}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formBasicName">
              <GenericTextInput
                register={register}
                name="image"
                label="รูปภาพ"
                placeholder="รูปภาพ"
                defaultValue={null}
                error={errors.image}
                type="file"
                required={isCreate}
                onChange={handleImageChange}
              />
            </Form.Group>
          </Row>
          {previewImage && (
            <Row className="mb-3">
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={previewImage}
                  alt="Preview"
                  style={{
                    width: "150px",
                  }}
                />
              </Col>
            </Row>
          )}
          <Row className="mb-3">
            <Col>
              <CancelButton onClick={() => onCancel()} />
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" }}>
              <SaveButton />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
