import React from "react";
import { userDoctor } from "../data/Columns";
import { Card, Col, Container, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";
import GenericPagination from "../components/GenericPagination";
import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";
import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import {
  deleteMockUserDoctor,
  getMockUserDoctor,
} from "../services/DoctorServices";
import SelectFilter from "../components/SelectFilter";
import { filterDoctorStatusRadio } from "../data/SelectOptions";
import CreateNewDoctorsForm from "../features/Form/CreateNewDoctorsForm";
import DoctorsDetailEditForm from "../features/Form/DoctorsDetailEditForm";
import { deleteCommonAPI, getCommonAPI } from "../services/commonAPIServices";
import ReactDateTimeRangePicker from "../components/Input/ReactDateTimeRangePicker";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";
import setPlaceholder from "../utils/Help";

const allUserStatus = "all";

export default function DoctorInfo() {
  const [total, setTotal] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [keyword, setKeyword] = React.useState("");
  const [userStatus, setUserStatus] = React.useState(allUserStatus);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [tableData, setTableData] = React.useState([]);
  const [editData, setEditData] = React.useState();
  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState();
  const [dateStart, setDateStart] = React.useState();
  const [dateEnd, setDateEnd] = React.useState();
  const [documentType, setDocumentType] = React.useState();
  const columnsData = userDoctor;

  const [isOpenForm, setIsOpenForm] = React.useState(false);
  const [isOpenCraeateForm, setIsOpenCraeateForm] = React.useState(false);

  const loadDoctorInfo = React.useCallback(
    async (currentPage, pageSize, userStatus, keyword, dateStart, dateEnd) => {
      let datefilter;
      if (dateStart && dateEnd) {
        datefilter = "$btw:" + dateStart + " 00:00:00," + dateEnd + " 23:59:59";
      }
      if (userStatus === "all") {
        userStatus = null;
      }
      const page = currentPage;
      const limit = pageSize;
      const search = keyword;
      const url_api = "/doctor_info/datatable";
      const p_data = {
        page,
        "filter.regdate": datefilter,
        "filter.status": userStatus,
        limit,
        search,
      };
      const { success, data, error } = await getCommonAPI({
        url_api,
        p_data,
      });
      if (success) {
        setTotal(data.total);
        setTableData(
          editButton(
            prepareTableData(
              data.records,
              (currentPage - 1) * pageSize + 1,
              handleOpenEditForm,
              handleOpenDeleteConfirm
            )
          )
        );
      } else {
        ShowMessageAndExpire("Failed to load data.", error?.message, "error");
      }
    },
    []
  );

  const loadDocumentType = React.useCallback(async () => {
    const url_api = "/m_doc_type";
    const p_data = {
      select: "id,name",
    };
    const { success, data, error } = await getCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      setDocumentType(prepareDocumentType(data.records));
    } else {
      ShowMessageAndExpire("Failed to load data.", error?.message, "error");
    }
  }, []);

  const prepareDocumentType = (datas) => {
    let listsDocument = [];
    console.log("prepareDocumentType: ", datas);
    for (const data of datas) {
      const id = data.id;
      const name = data.name;
      let document = {
        id: id,
        label: name,
        value: {
          id: id,
          name: name,
        },
      };
      listsDocument.push(document);
    }
    return listsDocument;
  };

  React.useEffect(() => {
    loadDoctorInfo(1, 10, allUserStatus);
    loadDocumentType();
  }, [loadDoctorInfo, loadDocumentType]);

  const editButton = (data) => {
    for (var i = 0; i < data.length; i++) {
      switch (data[i].onsiteCompile) {
        // On site Compile
        case 0:
          data[i].onsiteCompile = <>ได้</>;
          break;
        case 1:
          data[i].onsiteCompile = <>ไม่ได้</>;
          break;
      }
      switch (data[i].status) {
        // status
        case 10:
          data[i].status = <>UnAuthrized</>;
          break;
        case 11:
          data[i].status = <>Authrized</>;
          break;
        case 12:
          data[i].status = <>Ban</>;
          break;
      }
    }
    return data;
  };

  const loadPageOnPageChange = React.useCallback(
    async (page) => {
      setCurrentPage(page);
      await loadDoctorInfo(page, pageSize, userStatus, keyword);
    },
    [loadDoctorInfo, pageSize, userStatus, keyword]
  );

  const loadPageOnPageSizeChange = React.useCallback(
    async (pageSize) => {
      setCurrentPage(1);
      setPageSize(pageSize);
      await loadDoctorInfo(1, pageSize, userStatus, keyword);
    },
    [loadDoctorInfo, userStatus, keyword]
  );

  const loadPageOnUserStatusChange = React.useCallback(
    async (userStatus) => {
      setCurrentPage(1);
      setUserStatus(userStatus);
      await loadDoctorInfo(1, pageSize, userStatus, keyword);
    },
    [loadDoctorInfo, pageSize, keyword]
  );

  const loadTimeRangePicker = React.useCallback(
    async (sDate, eDate) => {
      setDateStart(sDate);
      setDateEnd(eDate);
      setCurrentPage(1);
      await loadDoctorInfo(1, pageSize, userStatus, keyword, sDate, eDate);
    },
    [loadDoctorInfo, pageSize, userStatus, keyword]
  );

  const openCreateForm = () => {
    setIsOpenCraeateForm(true);
  };

  const refreshPage = () => {
    setIsOpenForm(false);
    setCurrentPage(1);
    setKeyword("");
    setUserStatus(allUserStatus);
    loadDoctorInfo(1, pageSize, allUserStatus, "");
  };

  const handleSearch = React.useCallback(
    async (keyword) => {
      setCurrentPage(1);
      setKeyword(keyword);
      await loadDoctorInfo(1, pageSize, userStatus, keyword);
    },
    [loadDoctorInfo, pageSize, userStatus]
  );

  const handleOpenDeleteConfirm = (data) => {
    setIsDelete(true);
    setDeleteData(data);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDelete(false);
    setDeleteData(null);
  };

  const handleEdit = React.useCallback(async () => {
    handleCloseEdit();
    loadDoctorInfo(currentPage, pageSize, userStatus, keyword);
  }, [loadDoctorInfo, currentPage, pageSize, userStatus, keyword]);

  const handleOpenEditForm = (data) => {
    setIsOpenForm(true);
    setEditData(data);
  };

  const handleCloseEdit = () => {
    setIsOpenForm(false);
    setIsOpenCraeateForm(false);
    setEditData(null);
  };

  const handleDelete = React.useCallback(
    async (id) => {
      const url_api = "/doctor_info/" + id;
      const { success, data, error } = await deleteCommonAPI({
        url_api,
      });
      if (success) {
        handleCloseDeleteConfirm();
        let tempCurrentPage = currentPage;
        if (
          (total - pageSize * (currentPage - 1) === 1) &
          (currentPage !== 1)
        ) {
          setCurrentPage(currentPage - 1);
          tempCurrentPage = tempCurrentPage - 1;
        }
        loadDoctorInfo(tempCurrentPage, pageSize, userStatus, keyword);
      } else {
        console.log(error);
      }
    },
    [loadDoctorInfo, total, currentPage, pageSize, userStatus, keyword]
  );

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="p-3 p-lg-4">
          <PageHeaderCard
            title={
              editData
                ? `Doctor Info : ${editData?.name} ${editData?.lname} - ${editData?.phone}`
                : "Doctor Info Management"
            }
            buttonEnable={!isOpenForm && !isOpenCraeateForm}
            buttonIcon="plus"
            buttonMessage="New Doctor"
            onButtonOnClick={openCreateForm}
          />
          <DeleteConfirmModal
            show={isDelete}
            onClose={handleCloseDeleteConfirm}
            onComfirm={handleDelete}
            deleteRecord={deleteData?.id}
            confirmMessage={
              <>
                <p>{"Do you really want to delete these record?"}</p>
                <p>
                  {`Doctor ID = ${deleteData?.id}, ชื่อ = ${deleteData?.name} ${deleteData?.lname}`}
                </p>
              </>
            }
          />
          <Card>
            <Card.Body>
              <Container fluid style={{ minHeight: "30rem" }}>
                {isOpenCraeateForm ? (
                  <>
                    <CreateNewDoctorsForm
                      onCancel={handleCloseEdit}
                      onCreateSuccess={handleEdit}
                    />
                  </>
                ) : isOpenForm ? (
                  <>
                    <DoctorsDetailEditForm
                      onCancel={handleCloseEdit}
                      onSubmitSuccess={handleEdit}
                      id={editData?.id}
                      editData={editData}
                      documentType={documentType}
                    />
                  </>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col xs={6} style={{ display: "flex" }}>
                        <Searching
                          placeholder={setPlaceholder([
                            "Name",
                            "Lastname",
                            "Phone",
                          ])}
                          onSearch={handleSearch}
                          defaultValue={keyword}
                        />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <SelectFilter
                          label="สถานะ"
                          placeholder={"สถานะ"}
                          defaultValue={filterDoctorStatusRadio.find(
                            (e) => e.value == allUserStatus
                          )}
                          value={filterDoctorStatusRadio.find(
                            (e) => e.value == userStatus
                          )}
                          options={filterDoctorStatusRadio}
                          onChange={loadPageOnUserStatusChange}
                        />
                        <ReactDateTimeRangePicker2
                          onSelected={loadTimeRangePicker}
                          defaultStart={dateStart ? new Date(dateStart) : null}
                          defaultEnd={dateEnd ? new Date(dateEnd) : null}
                          placeholder="Register Date"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <ReactTable columns={columnsData} data={tableData} />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <GenericPagination
                          totalPage={Math.ceil(total / pageSize)}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          onPageChange={loadPageOnPageChange}
                          onPageSizeChange={loadPageOnPageSizeChange}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}

const prepareTableData = (datas, startNo, onEdit, onDelete) => {
  let tableData = [];
  for (const data of datas) {
    tableData.push({
      id: startNo + tableData.length,
      Doctorsurename: `${data.name} ${data.lname}`,
      phone: data.phone,
      status: data.status,
      email: data.email,
      hospital: data.hospital_name,
      onsiteCompile: data.on_site,
      registerDate: data.regdate,
      editDeleteButton: (
        <>
          <EditButton
            onClick={() => {
              onEdit(data);
            }}
          />{" "}
          <DeleteButton
            onClick={() => {
              onDelete(data);
            }}
          />
        </>
      ),
    });
  }
  // console.log(tableData);
  return tableData;
};
