export const EmsVanTypeDbValue = {
  Ems: 0,
  Van: 1,
};

export const StatusDbValue = {
  Inactive: 0,
  Active: 1,
};

export const EnableStatusDbValue = {
  Disable: 0,
  Enable: 1,
};

export const LanguageForm = {
  ENG: 1,
  THA: 0,
};

export const NotificationType = {
  AllUser: 0,
  LastActiveUser: 1,
  SpecificUser: 2,
};

export const NotificationStatus = {
  Inactive: 0,
  Active: 1,
  Pending: 50,
  Complete: 51,
  Cancel: 52,
};

export const PurchaseStatus = {
  Inactive: 0,
  Active: 1,
  Pending: 20,
  Paid: 21,
  Shipping: 22,
  Complete: 23,
  Cancel: 24,
  // Delivered: "DELIVERED",
  // Packing: "PACKING",
  // Waiting: "WAITING",
  // Return: "RETURN",
  // Lost: "LOST",
};

export const RevenueStatus = {
  Pending : 110,
  Paid : 111,
  Cancel : 112
};

export const CallStatus = {
  Waiting: 0,
  Called: 1,
};

export const carrierType = {
  OnVisit: 0,
  OnDelivery: 1,
};

export const DoctorLeaveApproveStatus = {
  Inactive: 0,
  Waiting: 1,
  NotApprove: 60,
  Approve: 61,
};

export const UserStatus = {
  Inactive: 0,
  Active: 1,
  Waiting: 2,
  Expired: 3,
};

export const BookingStatus = {
  Canceled: "Canceled",
  Complete: "Complete",
  Booking: "Booking",
};

export const PaymentType = {
  ProductOrder: 0,
  Booking: 1,
  CreditAppTopup: 3,
};
