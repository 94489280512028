import React from "react";
import { Card, Container, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import pageSvg from "../assets/svg/mailbox.svg";
import { verifyEmailUser } from "../services/AuthServices";

export default function VerifyEmail() {
  document.body.classList.remove("sidebar-show");

  const [isVerify, setisVerify] = React.useState(false);
  const [titleResultMessage, setTitleResultMessage] = React.useState("");
  const [subtitleResultMessage, setSubtitleResultMessage] = React.useState("");

  const verifyEmail = React.useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("token")) {
      const { success, error } = await verifyEmailUser({
        body: {
          token: searchParams.get("token"),
        },
      });
      if (success) {
        setTitleResultMessage("Verify your email success");
        setSubtitleResultMessage("");
      } else {
        setTitleResultMessage(`Verify your email failed`);
        setSubtitleResultMessage(`${error?.message}`);
      }
    } else {
      setTitleResultMessage(`Verify your email failed`);
      setSubtitleResultMessage(`Cannot get token`);
    }
    setisVerify(true);
  }, []);

  React.useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo">
            Pet Dreams
          </Link>
        </Container>
      </div>
      {isVerify ? (
        <div className="content">
          <Container>
            <Card className="card-auth">
              <Card.Body className="text-center">
                <div className="mb-5">
                  <object
                    type="image/svg+xml"
                    data={pageSvg}
                    className="w-50"
                    aria-label="svg image"
                  ></object>
                </div>
                <Card.Title>{titleResultMessage}</Card.Title>
                <Card.Text>{subtitleResultMessage}</Card.Text>
              </Card.Body>
            </Card>
          </Container>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner
              animation="border"
              variant="info"
              size="lg"
              className="me-3"
            />
            <h1>Verifying Email...</h1>
          </div>
        </>
      )}
    </div>
  );
}
