export const IncomeTransactionCSVHeader = [
    { label: "ลำดับ", key: "income_transaction_id" },
    { label: "รหัสรายการ", key: "code" },
    { label: "วันที่และเวลาทำรายการ", key: "action_datetime" },
    { label: "กลุ่มรายการ", key: "category" },
    { label: "จำนวนเงิน", key: "amt" },
    { label: "ภาษีมูลค่าเพิ่ม", key: "vat" },
    { label: "ชื่อและรหัสลูกค้า", key: "member_name" },
    { label: "หมายเหตุ", key: "remark" },
    { label: "สถานะ", key: "status" },
];

export const CreditTransactionCSVHeader = [
    { label: "ลำดับ", key: "credit_transaction_id" },
    { label: "รหัสรายการ", key: "code" },
    { label: "วันที่และเวลาทำรายการ", key: "action_datetime" },
    { label: "กลุ่มรายการ", key: "category" },
    { label: "จำนวนเงิน", key: "amt" },
    { label: "ภาษีมูลค่าเพิ่ม", key: "vat" },
    { label: "ชื่อและรหัสลูกค้า", key: "member_name" },
    { label: "หมายเหตุ", key: "remark" },
    { label: "สถานะ", key: "status" },
];

export const ExpenseTransactionCSVHeader = [
    { label: "ลำดับ", key: "expense_transaction_id" },
    { label: "รหัสรายการ", key: "code" },
    { label: "วันที่และเวลาทำรายการ", key: "action_datetime" },
    { label: "กลุ่มรายการ", key: "category" },
    { label: "จำนวนเงิน", key: "amt" },
    { label: "ภาษีมูลค่าเพิ่ม", key: "vat" },
    { label: "ชื่อผู้รับเงิน", key: "recipient" },
    { label: "หมายเหตุ", key: "remark" },
    { label: "สถานะ", key: "status" },
];

export const WorkLogTimeSheetCSVHeader = [
    { label: "ลำดับ", key: "worklog_transaction_id" },
    { label: "รหัสรายการ", key: "code" },
    { label: "ชื่อแพทย์", key: "doctor_name" },
    { label: "วันที่และเวลาทำรายการ", key: "action_datetime" },
    { label: "ระยะเวลางาน(ชม.)", key: "working_time_hrs" },
    { label: "มูลค่างาน", key: "work_value" },
    { label: "กลุ่มรายการ", key: "category" },
    { label: "สถานะ", key: "status" },
];
export const auditLogCSVHeader = [
    { label: "report_id", key: "report_id" },
    { label: "วันที่และเวลา", key: "action_datetime" },
    { label: "ชื่อผู้ใช้", key: "username" },
    { label: "กลุ่มผู้ใช้", key: "user_group" },
    { label: "path", key: "path" },
    { label: "action", key: "action" },
    { label: "reference", key: "reference" },
    { label: "reference_id", key: "reference_id" },
    { label: "ip_address", key: "ip_address" },
    { label: "remark", key: "remark" },
];

export const RevenueReportCSVHeader = [
    { label: "report_id", key: "report_id" },
    { label: "รหัสรายการ", key: "code" },
    { label: "เลขที่ใบเสร็จ", key: "receipt_no" },
    { label: "วันที่และเวลาทำการ", key: "action_datetime" },
    { label: "กลุ่มรายการ", key: "category" },
    { label: "ชื่อและรหัสลูกค้า", key: "member_name" },
    { label: "หมายเหตุ", key: "remark" },
    { label: "action", key: "action" },
    { label: "มูลค่า", key: "amt" },
    { label: "VAT", key: "vat" },
    { label: "รายการอ้างอิง", key: "reference" },
    { label: "สถานะ", key: "status" },

];

export const ExpenseReportCSVHeader = [
    { label: "report_id", key: "report_id" },
    { label: "รหัสรายการ", key: "code" },
    { label: "วันที่และเวลาทำการ", key: "action_datetime" },
    { label: "กลุ่มรายการ", key: "category" },
    { label: "ชื่อผู้รับเงิน", key: "recipient" },
    { label: "มูลค่า", key: "amount" },
    { label: "Vat", key: "vat" },
    { label: "หมายเหตุ", key: "remark" },
    { label: "สถานะ", key: "status" },

];

export const InventoryStockReportCSVHeader = [
    { label: "report_id", key: "report_id" },
    { label: "รหัสรายการ", key: "product_code" },
    { label: "ชื่อสินค้า", key: "product_name" },
    { label: "หมวดหมู่สินค้า", key: "product_category" },
    { label: "วันที่ทำรายการ", key: "action_datetime" },
    { label: "ประเภท", key: "type" },
    { label: "QTY", key: "quantity" },
    { label: "Balance", key: "balance" },
    { label: "รายการอ้างอิง", key: "reference" },
    { label: "Remark", key: "remark" },

];

export const BookingCancellationReportCSVHeader = [
    { label: "report_id", key: "report_id" },
    { label: "รหัสเคส", key: "case_id" },
    { label: "ชื่อเคส", key: "case_name" },
    { label: "วันที่ทำรายการจอง", key: "booking_date" },
    { label: "วันที่นัดหมาย", key: "schedule_date" },
    { label: "ประเภทการจอง", key: "booking_type" },
    { label: "ผู้รับเคส", key: "case_recipient" },
    { label: "สถานะเคส", key: "case_status" },
    { label: "หมายเหตุ", key: "remark" },

];
export const DoctorWorkLogReportCSVHeader = [
    { label: "worklog_transaction_id", key: "worklog_transaction_id" },
    { label: "รหัสรายการ", key: "code" },
    { label: "ชื่อแพทย์", key: "doctor_name" },
    { label: "วันที่และเวลาทำรายการ", key: "action_datetime" },
    { label: "ชื่อลูกค้า", key: "member_name" },
    { label: "ระยะเวลางาน (ชม.)", key: "working_time_hrs" },
    { label: "มูลค่างาน", key: "work_value" },
    { label: "ค่าใช้จ่าย Platform", key: "platform_cost" },
    { label: "ค่าใช้จ่ายโรงพยาบาล", key: "hospital_expenses" },
    { label: "ค่าใช้จ่ายแพทย์", key: "doctor_expenses" },
    { label: "กลุ่มรายการ", key: "category" },
    { label: "สถานะ", key: "status" },

];
export const EmsVanWorkLogReportCSVHeader = [
    { label: "id", key: "report_id" },
    { label: "รหัสรายการ", key: "case_id" },
    { label: "ชื่อแพทย์", key: "doctor_name" },
    { label: "วันที่และเวลาทำรายการ", key: "action_datetime" },
    { label: "ชื่อลูกค้า", key: "member_name" },
    { label: "ระยะเวลางาน (ชม.)", key: "working_time_hrs" },
    { label: "มูลค่างาน", key: "work_value" },
    { label: "ที่อยู่ต้นทาง", key: "origin_address" },
    { label: "ที่อยู่ปลายทาง", key: "destination_address" },
    { label: "กลุ่มรายการ", key: "category" },
    { label: "สถานะ", key: "status" },

];
export const receiptReportCSVHeader = [
    { label: "id", key: "id" },
    { label: "เลขที่ใบเสร็จ", key: "receipt_no" },
    { label: "วันและเวลาทำรายการ", key: "action_datetime" },
    { label: "กลุ่มรายการ", key: "group" },
    { label: "ชื่อและรหัสลูกค้า", key: "cusname" },
    { label: "มูลค่า", key: "value" },
    { label: "รายการอ้างอิง", key: "ref" },
    { label: "หมายเหตุ", key: "remark" },
    { label: "สถานะ", key: "status" },
];