import React from "react";
import CancelButton from "../../components/Button/CancelButton";
import SaveButton from "../../components/Button/SaveButton";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import GenericDateTimePickerInput2 from "../../components/Input/GenericDateTimePickerInput2";
import { min } from "moment";
import { editCommonAPI } from "../../services/commonAPIServices";
import ShowMessageAndExpire from "../../utils/ShowMessageAndExpire";

export default function CreateEditExpenseTransactionPaidForm({
  onCancel,
  onSubmitSuccess,
  editData,
}) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});

  const onSubmit = async (inputData) => {
    const url_api = "/report/expense_transaction_paid/" + editData.expense_transaction_id;
    const p_data = {
      paid_date: inputData.action_datetime,
    };
    const { success, data, error } = await editCommonAPI({
      url_api,
      p_data,
    });
    if (success) {
      ShowMessageAndExpire("Data updated successfully.", "", "success");
      onSubmitSuccess();
    } else {
      var errorMessages = [];
      if (typeof error.message === "string") {
        errorMessages.push(error.message);
      } else {
        for (var propertyName in error.message) {
          var errorMessage = error.message[propertyName];
          errorMessages.push(errorMessage);
        }
      }
      var concatenatedErrors = errorMessages.join("\n");

      ShowMessageAndExpire(
        "Failed to update data.",
        concatenatedErrors,
        "error"
      );
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formBasicName">
            <GenericDateTimePickerInput2
              control={control}
              name="action_datetime"
              label="วันที่และเวลาทำการ"
              placeholder="วันที่และเวลาทำการ"
              type="date"
              defaultValue={new Date()}
              required={true}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Col style={{ display: "flex", justifyContent: "start" }}>
            <SaveButton />
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <CancelButton onClick={onCancel} />
          </Col>
        </Row>
      </Form>
    </>
  );
}
