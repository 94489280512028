import React, { useRef } from "react";
import { WorkLogTimeSheetColumn } from "../data/Columns";
import { WorkLogTimeSheetCSVHeader } from "../data/CSVHeader";

import ShowMessageAndExpire from "../utils/ShowMessageAndExpire";
import Header from "../layouts/Header";
import GenericPagination from "../components/GenericPagination";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import PageHeaderCard from "../components/Card/PageHeaderCard";
import {
    getMockWorkLogTimeSheetDatatable,
    getRecord
} from "../services/WorkLogTimeSheetServices";

import { IncomeTypeOptions, TransactionStatusOptions, filterIncomeTypeOptions, filterTransactionStatusOptions } from "../data/SelectOptions";
import SelectFilter from "../components/SelectFilter";

import Searching from "../components/Searching";
import ReactTable from "../components/ReactTable";

import EditButton from "../components/Button/EditButton";
import DeleteButton from "../components/Button/DeleteButton";

import ReactDateTimePicker from "../components/Input/ReactDateTimePicker";

import CreateEditForm from "../features/Form/CreateEditWorkLogTimeSheetForm";
import DeleteConfirmModal from "../components/Modal/DeleteConfirmModal";
import GenericButton from "../components/Button/GenericButton";
import SaveButton from "../components/Button/SaveButton";
import CancelButton from "../components/Button/CancelButton";
import GenericSelectInput from "../components/Input/GenericSelectInput";
import ExportCSVButton from "../components/Button/ExportCSVButton";
import ReactDateTimeRangePicker2 from "../components/Input/ReactDateTimeRangePicker2";
import { textAlignRight } from "../utils/Help";

export default function WorkLogTimeSheetReport() {
    const columnsData = WorkLogTimeSheetColumn;
    const csvHeader = WorkLogTimeSheetCSVHeader;
    const csvFilename = "WorkLogTimesheet.csv";

    const [isExport, setIsExport] = React.useState(false);

    const [tableData, setTableData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [total, setTotal] = React.useState(1);

    const [keyword, setKeyword] = React.useState("");
    const [dateStart, setDateStart] = React.useState();
    const [dateEnd, setDateEnd] = React.useState();

    const [isCreate, setIsCreate] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);

    const [editData, setEditData] = React.useState();
    const [csvData, setCsvData] = React.useState([]);


    const loadInfo = React.useCallback(
        async (currentPage, pageSize, keyword, dateStart, dateEnd) => {
            const { success, data, error } = await getRecord({
                page: currentPage,
                pageSize: pageSize,
                search: keyword,
                dateStart,
                dateEnd
            });
            if (success) {
                // console.log(data.records);
                setTotal(data.total);
                setTableData(
                    prepareTableData(
                        data.records,
                        handleOpenEdit,
                    )
                );
                setCsvData(prepareCSVData(data.records));
            } else {
                ShowMessageAndExpire("Failed to load data.", error?.message, "error");
            }
        },
        []
    );

    React.useEffect(() => {
        loadInfo(1, 10);
    }, [loadInfo]);

    const handleExportCSV = () => {
        setIsExport(true);
    };
    const handleExportComplete = () => {
        setIsExport(false); // Reset the trigger to false
    };

    const handleOpenEdit = (data) => {
        setIsEdit(true);
        setEditData(data);
    };
    const handleCloseEdit = () => {
        setIsEdit(false);
        setEditData(null);
    };

    const handleSearch = React.useCallback(
        async (keyword) => {
            setCurrentPage(1);
            setKeyword(keyword);
            await loadInfo(1, pageSize, keyword, dateStart, dateEnd);
        },
        [loadInfo, pageSize]
    );

    const handleSelectedDate = React.useCallback(
        async (sDate, eDate) => {
            setDateStart(sDate);
            setDateEnd(eDate);
            setCurrentPage(1);
            await loadInfo(1, pageSize, keyword, sDate, eDate);
        },
        [loadInfo, pageSize, keyword]
    );

    const loadPageOnPageChange = React.useCallback(
        async (page) => {
            setCurrentPage(page);
            await loadInfo(page, pageSize, keyword, dateStart, dateEnd);
        },
        [loadInfo, pageSize, keyword]
    );

    const loadPageOnPageSizeChange = React.useCallback(
        async (pageSize) => {
            setCurrentPage(1);
            setPageSize(pageSize);
            await loadInfo(1, pageSize, keyword, dateStart, dateEnd);
        },
        [loadInfo, keyword]
    );
    return (
        <>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="p-3 p-lg-4">
                    <PageHeaderCard
                        title="WorkLog Timesheet"
                        buttonEnable={!isEdit && !isCreate}
                        buttonMessage="Export CSV"
                        onButtonOnClick={handleExportCSV}
                    />
                    <ExportCSVButton
                        csvData={csvData}
                        csvHeader={csvHeader}
                        filename={csvFilename}
                        enclose=""
                        showBtn={false}
                        isExport={isExport}
                        onExportComplete={handleExportComplete}
                    />
                    <Card>
                        <Card.Body>
                            <Container fluid style={{ minHeight: "30rem" }}>
                                {isEdit ? (
                                    <>
                                        <Modal
                                            show={isEdit}
                                            size="lg"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                        >
                                            <Modal.Header>Work Log - Timesheet Detail</Modal.Header>
                                            <Modal.Body>
                                                <CreateEditForm
                                                    onCancel={handleCloseEdit}
                                                    id={editData?.worklog_transaction_id}
                                                    code={editData?.code}
                                                    doctor_name={editData?.doctor_name}
                                                    action_datetime={new Date(editData?.action_datetime)}
                                                    working_time_hrs={editData?.working_time_hrs}
                                                    work_value={editData?.work_value}
                                                    category={editData?.category}
                                                    status={editData?.status}
                                                    member_name={editData?.member_name}
                                                    platform_cost={editData?.platform_cost}
                                                    hospital_expenses={editData?.hospital_expenses}
                                                    doctor_expenses={editData?.doctor_expenses}
                                                />
                                            </Modal.Body>
                                        </Modal>
                                    </>
                                ) : (
                                    <>
                                        <Row className="mt-3">
                                            <Col xs={5} style={{ display: "flex" }}>
                                                <Searching placeholder="Filter : รหัสรายการ , ชื่อแพทย์ , วันที่และเวลาทำรายการ , มูลค่า , กลุ่มรายการ"
                                                    onSearch={handleSearch}
                                                    defaultValue={keyword}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <ReactDateTimeRangePicker2
                                                    onSelected={handleSelectedDate}
                                                    defaultStart={dateStart ? new Date(dateStart) : null}
                                                    defaultEnd={dateEnd ? new Date(dateEnd) : null}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <ReactTable columns={columnsData} data={tableData} />
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col>
                                                <GenericPagination
                                                    totalPage={Math.ceil(total / pageSize)}
                                                    currentPage={currentPage}
                                                    pageSize={pageSize}
                                                    onPageChange={loadPageOnPageChange}
                                                    onPageSizeChange={loadPageOnPageSizeChange}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Container>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
}



const prepareTableData = (datas, onEdit) => {
    let tableData = [];
    for (const data of datas) {
        tableData.push({
            worklog_transaction_id: data.worklog_transaction_id,
            code: data.code,
            doctor_name: data.doctor_name,
            action_datetime: data.action_datetime,
            working_time_hrs: textAlignRight(data.working_time_hrs),
            work_value: textAlignRight(data.work_value),
            category: data.category,
            status: data.status,
            editDeleteButton: (
                <>
                    <Button
                        variant="warning"
                        onClick={() => {
                            onEdit(data);
                        }}
                    >
                        View
                    </Button>
                </>
            ),
        });
    }
    //console.log(tableData);
    return tableData;
};

const prepareCSVData = (datas) => {
    let csvData = [];
    for (const data of datas) {
        csvData.push({
            worklog_transaction_id: data.worklog_transaction_id,
            code: data.code,
            doctor_name: data.doctor_name,
            action_datetime: data.action_datetime,
            working_time_hrs: data.working_time_hrs,
            work_value: data.work_value,
            category: data.category,
            status: data.status,
        });
    }
    return csvData;
};