import axios from "axios";
import { getDefaultRequestHeaders } from "../utils/Authen";

export const getRecord = async ({ page, pageSize, search,dateStart, dateEnd  }) => {
  try {
    let datefilter;
    if( dateStart && dateEnd ){
      datefilter ='$btw:'+dateStart+' 00:00:00,'+dateEnd+' 23:59:59';
    }
    const res = await axios.get(
      process.env.REACT_APP_API_URL + "/report/credit_transaction",
      {
        headers: await getDefaultRequestHeaders(true),
        params: {
          page,
          limit: pageSize,
          search,
          'filter.action_datetime': datefilter
        },
      }
    );
    return {
      success: true,
      data: {
        total: res.data.meta?.total_items,
        currentPage: res.data.meta?.current_page,
        pageSize: res.data.meta?.items_per_page,
        records: res.data.data,
      },
      error: null,
    };
  } catch (err) {
    return {
      success: false,
      data: null,
      error: err.response?.data?.error || err,
    };
  }
};


export const getMockCreditTransactionDatatable = async ({ page, pageSize, keyword }) => {
  return {
    success: true,
    data: {
      total: 2,
      currentPage: 1,
      pageSize: 10,
      records: [
        {
          id: "1",
          transectionID: "CRE001",
          transectionDatetime: "2023-04-03 12:00:00",
          incomeType: 0,
          cusName: "คุณธัญรัตน์ มั่งมี",
          remark: "",
          status: 1,
        },
        {
          id: "2",
          transectionID: "CRE002",
          transectionDatetime: "2023-04-03 12:00:00",
          incomeType: 1,
          cusName: "คุณมั่นคง ใจเย็น",
          remark: "",
          status: 2,
        },
        {
          id: "3",
          transectionID: "CRE003",
          transectionDatetime: "2023-04-03 12:00:00",
          incomeType: 2,
          cusName: "คุณรัตนา สายใจ",
          remark: "",
          status: 0,
        },

      ],
    },
    error: null,
  };
};
